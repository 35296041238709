import { LOYALTY_CREDIT_NAME } from "../constants/planConstants";
import { getMultiLangValue } from "../helpers/multiLangHelper";
import { getMessage } from "../messages";
import planService from "../services/planService";

export const POINTS_PLACEHOLDER = "{{points_name}}";
export const CASHBACK_PLACEHOLDER = "{{cashback_name}}";

export const formatPointsNameText = ({ text, namesObj, lang, pointsValue }) => {
  if (!namesObj || !text) {
    return "";
  }

  let pointsName = getMultiLangValue({ value: namesObj.pointsName, valueML: namesObj.pointsNameML, lang });
  let singlePointName = getMultiLangValue({
    value: namesObj.singlePointName,
    valueML: namesObj.singlePointNameML,
    lang
  });

  let name = pointsName;
  if (pointsValue && Math.abs(pointsValue) === 1) {
    name = singlePointName;
  }

  const doCapitalize = text.indexOf(POINTS_PLACEHOLDER) === 0;

  text = text.replace(new RegExp(POINTS_PLACEHOLDER, "g"), name);

  if (doCapitalize) {
    text = text.charAt(0).toUpperCase() + text.slice(1);
  }

  return text;
};

export const formatCashbackNameText = ({ text, namesObj, lang, cashbackValue }) => {
  if (!namesObj || !text || !namesObj.cashbackName) {
    return "";
  }

  let cashbackName = getMultiLangValue({ value: namesObj.cashbackName, valueML: namesObj.cashbackNameML, lang });

  let name = cashbackName;

  const doCapitalize = text.indexOf(CASHBACK_PLACEHOLDER) === 0;

  text = text.replace(new RegExp(CASHBACK_PLACEHOLDER, "g"), name);

  if (doCapitalize) {
    text = text.charAt(0).toUpperCase() + text.slice(1);
  }

  return text;
};

export const pointNameText = (plan) => {
  if (planService.isSpendPlan(plan)) return getMessage("loyaltyCredit");
  else return getMessage("point");
};
