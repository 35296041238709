export const memberProfileEnLegacy = {
  //  memberGroupProfile.removeFromGroup.deleteDialog.content.status
  // memberGroupProfile.removeFromGroup.deleteDialog.content.loyality
  "memberGroupProfile.removeFromGroup.deleteDialog.content":
    "After you remove this member, the accumulated {pointType} are removed from the group status.",

  //memberGroupProfile.updatePointsBtn.label.points
  //memberGroupProfile.updatePointsBtn.label.balance
  "memberGroupProfile.updatePointsBtn.label": "Update {isPoints,select,true {Points} other {Balance}}",

  //memberProfile.updatePointsBtn.label.points
  //memberProfile.updatePointsBtn.label.balance
  "memberProfile.updatePointsBtn.label": "Update {isPoints,select,true {Points} other {Balance}}",

  "activityType.customer_support.cashbackRemoved.name": "Removed Cashback ",
  "activityType.customer_support.pointsRemoved.name": "Removed {{points_name}}",
  "activityType.points_expiration.toSpend.name": "Balance {{points_name}} Expired",
  "memberProfileUpdatePointsPopup.confirmed.label":
    "I understand that these updates will affect the {text} member's Loyalty status."
};
