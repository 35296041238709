import * as TYPES from "./TYPES";
import currentPlanVersionApi from "../api/currentPlanVersionApi";
import { invalidateJwt } from "./sessionActions";
import sessionHelper from "../helpers/sessionHelper";

export const getCurrentPlanVersion = () => {
  return (dispatch) => {
    dispatch({
      type: TYPES.GET_CURRENT_PLAN_REQUEST
    });
    return currentPlanVersionApi
      .getCurrentPlanVersion()
      .then((data) => {
        dispatch({
          type: TYPES.GET_CURRENT_PLAN_SUCCESS,
          payload: {
            currentPlanVersion: data
          }
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.GET_CURRENT_PLAN_FAILURE
        });
      });
  };
};

export const switchPlanEditMode = (showCurrentVersion) => {
  sessionHelper.setShowCurrentVersion(showCurrentVersion);
  return function (dispatch) {
    dispatch({
      type: TYPES.CHANGE_PLAN_EDIT_MODE,
      payload: {
        showCurrentVersion
      }
    });
  };
};

export const setPlanLaunchInProgress = (isInProgress) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.SET_PLAN_LAUNCH_IN_PROGRESS,
      payload: {
        isInProgress
      }
    });
  };
};
