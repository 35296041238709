import axios from "axios";
import sessionHelper from "../helpers/sessionHelper";

export default class rewardApi {
  static async getAllRewards() {
    const planId = sessionHelper.getPlanId();
    const response = await axios.get(`/plan/${planId}/reward`);
    return response.data;
  }

  static async createNewFromExistingReward(data, id) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.post(`/plan/${planId}/reward/${id}/swap`, data);
    return response.data;
  }

  static async createReward(data) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.post(`/plan/${planId}/reward`, data);
    return response.data;
  }

  static async updateReward(data, id) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.put(`/plan/${planId}/reward/${id}`, data);
    return response.data;
  }

  static async deleteReward(id) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.delete(`/plan/${planId}/reward/${id}`);
    return response;
  }

  static async addUploadToRewardPool(id, upload) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.post(`/plan/${planId}/reward/${id}/upload`, upload);
    return response.data;
  }

  static async deleteRewardPool(id) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.delete(`/plan/${planId}/reward/${id}/upload`);
    return response.data;
  }

  static async uploadVouchersFile(rewardId, data, onUploadProgress) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.post(`/plan/${planId}/reward/${rewardId}/upload-vouchers-file`, data, {
      headers: { "content-type": "text/plain" },
      onUploadProgress
    });

    return response;
  }
}
