import React, { Fragment } from "react";
import PropTypes from "prop-types";

import InputField from "../../components/InputField";
import InputFieldWithTokens from "../../components/InputFieldWithTokens";

import { getMultiLangFieldLabel } from "../../helpers/multiLangHelper";

import { REDEMPTION_VALUE_TOKEN } from "../../constants/rewardConstants";
import { EXCLUSIVE_ACCESS_OFFERS_ENUM } from "../../constants/exclusiveAccessConstants";
import rewardService from "../../services/rewardService";

const MultiLangRedemptionMessageFields = ({ onChange, lang, translations, masterReward, path, disabled }) => {
  let isForFree = false;
  if (path === undefined) {
    path = "redemption";
    isForFree = rewardService.isRewardForFree(masterReward);
  } else {
    isForFree = path === EXCLUSIVE_ACCESS_OFFERS_ENUM.FOR_FREE;
  }

  if (!translations[`${path}.message.textML`][lang]) {
    translations[`${path}.message.textML`][lang] = isForFree ? "" : REDEMPTION_VALUE_TOKEN.token;
  }

  const onTextInputChange = (event, value) => {
    onChange(event, lang, value);
  };

  return (
    <Fragment>
      <InputField
        label={getMultiLangFieldLabel({ label: "Title", lang })}
        type="text"
        name={`${path}.message.titleML`}
        value={translations[`${path}.message.titleML`][lang]}
        onChange={(event) => onChange(event, lang)}
        disabled={disabled}
      />

      <InputFieldWithTokens
        label={getMultiLangFieldLabel({ label: "Text", lang })}
        type="text"
        name={`${path}.message.textML`}
        value={translations[`${path}.message.textML`][lang] || ""}
        tokens={isForFree ? null : [REDEMPTION_VALUE_TOKEN]}
        onChange={onTextInputChange}
        disabled={disabled}
      />
    </Fragment>
  );
};

MultiLangRedemptionMessageFields.propTypes = {
  onChange: PropTypes.func,
  lang: PropTypes.string,
  translations: PropTypes.object,
  masterReward: PropTypes.string,
  disabled: PropTypes.bool
};

export default MultiLangRedemptionMessageFields;
