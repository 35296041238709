import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";

import { selectIsBeforeFirstPlanLaunch, selectShowCurrentPlan, selectPlanType } from "../reducers";

import { switchPlanEditMode } from "../actions/currentPlanVersionActions";

import MultiPlanSelect from "../components/MultiPlanSelect";

import { TAB_ENUM, MANAGEMENT_TAB_ENUM } from "../constants/navigationConstants";
import planService from "../services/planService";

import "../assets/styles/Navigation.scss";
import { getMessage } from "../messages";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPageRoute: window.location.pathname
    };
  }

  onTabPageChange(routeName) {
    this.setState({
      currentPageRoute: routeName
    });
  }

  getActiveClassName(pageRoute) {
    if (this.state.currentPageRoute === "/") {
      return "/dashboard" === pageRoute ? "e-tabs__title-active" : "";
    }

    if (pageRoute === "/actions" && this.state.currentPageRoute === "/actions/purchases") {
      return "e-tabs__title-active";
    }

    if (pageRoute === "/rewards" && this.state.currentPageRoute === "/rewards/vouchersForRewards") {
      return "e-tabs__title-active";
    }

    if (pageRoute === "/vouchers" && this.state.currentPageRoute === "/vouchers/vouchersForRewards") {
      return "e-tabs__title-active";
    }

    if (pageRoute === "/exclusive-access" && this.state.currentPageRoute === "/exclusive-access/permanent") {
      return "e-tabs__title-active";
    }

    return this.state.currentPageRoute === pageRoute ? "e-tabs__title-active" : "";
  }

  isTabDisabled(tab) {
    if (tab.id === TAB_ENUM.MEMBER_PROFILES && !this.props.showCurrentVersion && !this.props.isBeforeFirstPlanLaunch) {
      return true;
    }

    return tab.disabled;
  }

  renderSingleTab(tab) {
    const tabDisabled = this.isTabDisabled(tab);
    const title = tab.titleMessage ? getMessage(tab.titleMessage) : tab.title;
    if (tab.id === MANAGEMENT_TAB_ENUM.CASHBACK_SETTINGS && !planService.isSpendPlanType(this.props.planType))
      return null;

    const tabElm = tabDisabled ? (
      <span className="e-tabs__separator"> {title}</span>
    ) : (
      <NavLink className="e-tabs__separator" exact to={tab.route} onClick={() => this.onTabPageChange(tab.route)}>
        {title}
      </NavLink>
    );

    const disabledTabClass = tabDisabled ? "e-tabs__title-disabled" : "";

    return (
      <div key={`tab_${tab.id}`} className={`e-tabs__title ${disabledTabClass} ${this.getActiveClassName(tab.route)}`}>
        {tabElm}
      </div>
    );
  }

  render() {
    const { title, tabsConfig } = this.props;
    const tabs = tabsConfig.map((tab) => {
      return this.renderSingleTab(tab);
    });

    return (
      <Fragment>
        <header className="e-layout__header">
          <h1 className="e-layout__title">{title}</h1>
          <div className="e-layout__actions">
            <MultiPlanSelect />
          </div>
        </header>
        <main className="e-layout__content">
          <section className="e-layout__section e-layout__section-with_legacy_tabs">
            <div className="e-tabs">{tabs}</div>
            <div className="e-tabs-panel">{this.props.children}</div>
          </section>
        </main>
      </Fragment>
    );
  }
}

Navigation.propTypes = {
  title: PropTypes.string,
  tabsConfig: PropTypes.array,
  showCurrentVersion: PropTypes.bool,
  isBeforeFirstPlanLaunch: PropTypes.bool,
  switchPlanEditMode: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const isBeforeFirstPlanLaunch = selectIsBeforeFirstPlanLaunch(state);
  const showCurrentVersion = selectShowCurrentPlan(state);

  return {
    showCurrentVersion: showCurrentVersion,
    isBeforeFirstPlanLaunch: isBeforeFirstPlanLaunch,
    planType: selectPlanType(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  switchPlanEditMode: (showCurrentVersion) => {
    dispatch(switchPlanEditMode(showCurrentVersion));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
