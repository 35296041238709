import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

class TemplateHtmlPreview extends Component {
  constructor() {
    super();

    this.state = {
      texts: {
        h1: "Header2",
        button1: "<b>Click me 1</b>",
        button2: '<span style="color: red">Click me 2</span>'
      },
      links: {
        button1: {
          title: "title1",
          href: "https://google.com"
        },
        button2: {
          title: "title2",
          href: "https://ynet.co.il"
        }
      }
    };
  }

  componentDidMount() {
    this.initPreviewElementListeners();
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.isMobile &&
      (prevProps.isMobile !== this.props.isMobile || prevProps.isMember !== this.props.isMember)
    ) {
      this.initPreviewElementListeners();
    }
  }

  initPreviewElementListeners() {
    if (this.props.isViewOnly) {
      return;
    }

    this.previewElm.querySelectorAll("vce-plugin-editable-text").forEach((editor) => {
      editor.addEventListener("change", (event) => {
        const { editableId, data } = event.detail;
        this.props.onContentTextChange(editableId, data);
      });
    });
  }

  renderPreview(textSelector, buttonsSelector) {
    return (
      <vce-preview
        ref={(elem) => (this.previewElm = elem)}
        content={this.props.content}
        id="preview"
        iframe-width="100%"
        extra-class="e-contentblocks-preview__iframe e-contentblocks-preview__iframe-desktop"
      >
        {!this.props.isViewOnly && <vce-plugin-borderer />}
        <vce-plugin-prevent-link-click />
        <vce-plugin-editable-text
          editable-selector={textSelector}
          toolbar='["forecolor | bold italic underline strikethrough", "fontselect | fontsizeselect"]'
        ></vce-plugin-editable-text>
        <vce-plugin-editable-text
          editable-selector={buttonsSelector}
          //font-sizes='["6px 7px","11px 12px"]'
          toolbar='["forecolor | bold italic underline strikethrough", "fontselect | fontsizeselect | linkEditor"]'
        >
          <vce-plugin-text-inner-link-editor external-plugin>
            <vce-link-editor-dialog
              translations='{"headline":"Link Editor","urlLabel":"Url","urlPlaceholder":"https://your.url","titleLabel":"Title","titlePlaceholder":"Your title","cancelLabel":"Cancel","applyLabel":"Apply","removeLabel":"Remove"}'
              link-editor-dialog
            ></vce-link-editor-dialog>
          </vce-plugin-text-inner-link-editor>
        </vce-plugin-editable-text>
      </vce-preview>
    );
  }

  render() {
    if (this.props.isViewOnly) {
      return this.renderPreview("", "");
    }

    if (!this.props.isMobile) {
      return this.renderPreview("a[e-editable]", "[e-editable]:not(a)");
    }
    return (
      <div className="text-align-center">
        <e-device type="" class="e-device e-device-iphone_6">
          {this.renderPreview("", "")}
        </e-device>
      </div>
    );
  }
}

TemplateHtmlPreview.propTypes = {
  content: PropTypes.string.isRequired,
  links: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isMember: PropTypes.bool.isRequired,
  onContentTextChange: PropTypes.func.isRequired,
  isViewOnly: PropTypes.bool
};

export default TemplateHtmlPreview;
