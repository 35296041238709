import React, { Fragment } from "react";
import PropTypes from "prop-types";

import InputField from "../../components/InputField";
import InputNumberField from "../../components/InputNumberField";
import MultiLangPopupOpener from "../../components/MultiLangPopupOpener";
import RadioButtonList from "../../components/RadioButtonList";

import { getErrorMessage } from "../../validators";

import { CURRENCY_IDENTIFIER_SIGN_OPTIONS } from "../../constants/planConstants";
import { pointNameText } from "../../helpers/pointsHelper";
import planService from "../../services/planService";
import { getMessage } from "../../messages";

const PlanSettingsGeneral = (props) => {
  const {
    plan,
    onInputChange,
    errors,
    disabled,
    onShowPlanSettingsMultiLang,
    hasMultiLanguage,
    onPlanTypeChange,
    planTypeOptions,
    onPointsNameChange
  } = props;

  return (
    <Fragment>
      <h2>{getMessage("planSettingsGeneral.title")}</h2>

      <InputField
        label={getMessage("planSettingsGeneral.name.label")}
        tooltip={getMessage("planSettingsGeneral.name.tooltip")}
        type="text"
        name="planSettings.name"
        value={plan.planSettings.name}
        onChange={onInputChange}
        errorMsg={getErrorMessage(errors, "planSettings.name") || getErrorMessage(errors, "planSettings.nameML")}
        disabled={disabled}
        postfixButtons={hasMultiLanguage && <MultiLangPopupOpener onClick={onShowPlanSettingsMultiLang} />}
        isRequired={true}
      />

      {!planService.isSpendPlan(plan) && (
        <RadioButtonList
          label={getMessage("planSettingsGeneral.planType.label")}
          name={`planType`}
          value={plan.planType}
          options={planTypeOptions}
          onChange={onPlanTypeChange}
          errorMsg={getErrorMessage(errors, "planType")}
          disabled={disabled}
          tooltip={getMessage("planSettingsGeneral.planType.tooltip")}
        />
      )}
      {!planService.isSpendPlan(plan) && (
        <div className="e-row">
          <div className="e-col-6">
            <InputField
              label={getMessage("planSettingsGeneral.pointsName")}
              type="text"
              name="planSettings.pointsName"
              value={plan.planSettings.pointsName}
              onChange={onInputChange}
              errorMsg={
                getErrorMessage(errors, "planSettings.pointsName") ||
                getErrorMessage(errors, "planSettings.pointsNameML")
              }
              disabled={disabled}
              tooltip={getMessage("planSettingsGeneral.pointsName.tooltip")}
              isRequired={true}
            />
          </div>

          <div className="e-col-6">
            <InputField
              label={getMessage("planSettingsGeneral.singlePointName")}
              type="text"
              name="planSettings.singlePointName"
              value={plan.planSettings.singlePointName}
              onChange={onInputChange}
              errorMsg={
                getErrorMessage(errors, "planSettings.singlePointName") ||
                getErrorMessage(errors, "planSettings.singlePointNameML")
              }
              disabled={disabled}
              postfixButtons={hasMultiLanguage && <MultiLangPopupOpener onClick={onShowPlanSettingsMultiLang} />}
              isRequired={true}
            />
          </div>
        </div>
      )}

      {planService.isSpendPlan(plan) && (
        <div>
          <div className="e-row">
            <div className="e-col-12">
              <InputField
                label={getMessage("planSettingsGeneral.loyaltyCreditName")}
                name="planSettings.pointsName"
                value={plan.planSettings.pointsName}
                onChange={onPointsNameChange}
                errorMsg={
                  getErrorMessage(errors, "planSettings.pointsName") ||
                  getErrorMessage(errors, "planSettings.pointsNameML")
                }
                disabled={disabled}
                tooltip={getMessage("planSettingsGeneral.pointsName.spend.tooltip")}
                postfixButtons={hasMultiLanguage && <MultiLangPopupOpener onClick={onShowPlanSettingsMultiLang} />}
                placeholder={getMessage("planSettingsGeneral.pointsName.spend.placeholder")}
                isRequired={true}
              />
            </div>
          </div>

          <div className="e-row">
            <RadioButtonList
              label={getMessage("planSettingsGeneral.currencyIdentifierSign.label")}
              className="e-col-12"
              name="planSettings.currencyIdentifierSign"
              value={plan.planSettings.currencyIdentifierSign}
              options={CURRENCY_IDENTIFIER_SIGN_OPTIONS}
              onChange={onInputChange}
              errorMsg={getErrorMessage(errors, "planSettings.currencyIdentifierSign")}
              isHorizontal={true}
              disabled={disabled}
              tooltip={getMessage("planSettingsGeneral.currencyIdentifierSign.tooltip")}
            />
          </div>
        </div>
      )}

      <InputNumberField
        label={getMessage("planSettingsGeneral.rewardsAllocationDelay.duration.label")}
        tooltip={getMessage("planSettingsGeneral.rewardsAllocationDelay.duration.tooltip")}
        placeholder={getMessage("planSettingsGeneral.rewardsAllocationDelay.duration.placeholder")}
        name="planSettings.rules.rewardsAllocationDelay.duration"
        value={plan.planSettings.rules.rewardsAllocationDelay.duration}
        onChange={onInputChange}
        postfixLabel="days"
        errorMsg={getErrorMessage(props.errors, "planSettings.rules.rewardsAllocationDelay.duration")}
        labelClassName="no-margin"
        disabled={disabled}
        isRequired={true}
      />
    </Fragment>
  );
};

PlanSettingsGeneral.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
  onShowPlanSettingsMultiLang: PropTypes.func,
  onPlanTypeChange: PropTypes.func,
  hasMultiLanguage: PropTypes.bool
};

export default PlanSettingsGeneral;
