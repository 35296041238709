import React, { Fragment } from "react";
import PropTypes from "prop-types";

import InputField from "../../components/InputField";
import InputFieldWithTokens from "../../components/InputFieldWithTokens";
import Alert from "../../components/Alert";

import { getMultiLangFieldLabel } from "../../helpers/multiLangHelper";

import { getErrorMessage, convertPathStrName } from "../../validators";
import { ALERT_STATUS } from "../../constants/alertContants";
import { getMessage } from "../../messages";

const MultiLangPurchaseBenefitFields = ({
  onChange,
  lang,
  translations,
  propPrefix,
  disabled,
  errors,
  index,
  tokens
}) => {
  const titleInputName = `${propPrefix}.titleML`;
  const textInputName = `${propPrefix}.textML`;

  const onTextInputChange = (event, value) => {
    onChange(event, lang, value);
  };

  return (
    <Fragment>
      {index === 0 && !!errors && (
        <Alert message={getMessage("multiLangPurchaseBenefitFields.alert")} status={ALERT_STATUS.ERROR} />
      )}
      <InputField
        label={getMultiLangFieldLabel({
          label: getMessage("tierSetupPurchaseBenefits.benefitMessageTitle.label"),
          lang
        })}
        placeholder={getMessage("tierSetupPurchaseBenefits.benefitMessageTitle.placeholder")}
        name={titleInputName}
        value={translations[titleInputName][lang] || ""}
        onChange={(event) => onChange(event, lang)}
        disabled={disabled}
        errorMsg={getErrorMessage(errors, `${convertPathStrName(titleInputName)}.${lang}`)}
      />

      <InputFieldWithTokens
        label={getMultiLangFieldLabel({
          label: getMessage("tierSetupPurchaseBenefits.benefitMessageText.label"),
          lang
        })}
        placeholder={getMessage("tierSetupPurchaseBenefits.benefitMessageText.placeholder")}
        name={textInputName}
        value={translations[textInputName][lang] || ""}
        tokens={tokens}
        onChange={onTextInputChange}
        disabled={disabled}
        errorMsg={getErrorMessage(errors, `${convertPathStrName(textInputName)}.${lang}`)}
      />
    </Fragment>
  );
};

MultiLangPurchaseBenefitFields.propTypes = {
  onChange: PropTypes.func,
  lang: PropTypes.string,
  translations: PropTypes.object,
  propPrefix: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.object
};

export default MultiLangPurchaseBenefitFields;
