import validate from "validate.js";
import { TIER_DOWNGRADE_TYPE_ENUM, TIER_UPDATE_TYPE } from "../constants/memberProfileConstants";
import { TIER_TYPE_ENUM } from "../constants/tierConstants";
import { getMessage } from "../messages";
import tierService from "../services/tierService";

export default class memberTierUpdateValidator {
  constructor() {}

  createConstraints(fields) {
    let constraints = {
      confirmed: {
        isTrue: {
          message: getMessage("memberPointsUpdateValidator.confirmed.isTrue")
        }
      },
      reason: {
        presence: {
          allowEmpty: false,
          message: getMessage("memberPointsUpdateValidator.reason.presence")
        }
      }
    };

    const { contactTier, tierUpdateType, tierDowngradeType } = { ...fields };
    if (tierUpdateType === TIER_UPDATE_TYPE.DOWNGRADE_TIER && tierService.isInviteTier(contactTier)) {
      constraints.tierDowngradeType = {
        presence: {
          allowEmpty: false,
          message: getMessage("memberTierUpdateValidator.tierDowngradeType")
        }
      };
    }

    if (tierDowngradeType != TIER_DOWNGRADE_TYPE_ENUM.STATUS_POINTS) {
      constraints.selectedTierId = {
        presence: {
          allowEmpty: false,
          message: getMessage("memberTierUpdateValidator.selectedTierId")
        }
      };
    }

    return constraints;
  }

  validate = (fields) => {
    return validate(fields, this.createConstraints(fields));
  };
}
