import React from "react";
import PropTypes from "prop-types";
import { getMessage } from "../../messages";

const DashboardEmptyState = (props) => {
  return (
    <div className="e-emptystate e-emptystate-horizontal">
      <div className="e-emptystate__icon">
        <svg
          width="300px"
          height="300px"
          viewBox="0 0 300 300"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlnsSketch="http://www.bohemiancoding.com/sketch/ns"
        >
          <defs>
            <linearGradient x1="70.4580341%" y1="69.3540176%" x2="35.9599489%" y2="35.4269783%" id="linearGradient-1">
              <stop stopColor="#EBEDEC" offset="0%"></stop>
              <stop stopColor="#E4E4E4" offset="100%"></stop>
            </linearGradient>
          </defs>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" sketchType="MSPage">
            <g id="Growth-widget-icon" sketchType="MSArtboardGroup">
              <g id="Icon-BG" sketchType="MSLayerGroup" transform="translate(5.000000, 5.000000)">
                <circle fill="#EBEDEC" sketchType="MSShapeGroup" cx="145" cy="145" r="145"></circle>
                <path
                  d="M289.968949,141.968949 L215.89697,67.8969696 L69,214.793939 L144.203921,289.997861 C144.469113,289.999286 144.734473,290 145,290 C225.081289,290 290,225.081289 290,145 C290,143.987191 289.989616,142.976807 289.968949,141.968949 L289.968949,141.968949 Z"
                  id="Shadow"
                  fill="url(#linearGradient-1)"
                  sketchType="MSShapeGroup"
                ></path>
              </g>
              <g id="Icon" sketchType="MSLayerGroup" transform="translate(70.000000, 70.000000)">
                <rect
                  id="Icon-BG"
                  stroke="#F6F6F6"
                  strokeWidth="10"
                  fill="#FAFAFA"
                  sketchType="MSShapeGroup"
                  x="0"
                  y="0"
                  width="160"
                  height="160"
                  rx="20"
                ></rect>
                <rect
                  id="Title"
                  opacity="0.4"
                  fill="#DEDEDE"
                  sketchType="MSShapeGroup"
                  x="30"
                  y="30"
                  width="60"
                  height="10"
                ></rect>
                <path
                  d="M30,95 L60.1111779,76.9777644 L95.5521334,83.8895733 L130,65 L130,130 L30,130 L30,95 Z"
                  id="Graph"
                  fill="#32D17E"
                  sketchType="MSShapeGroup"
                ></path>
                <path
                  d="M32.8945312,95.1523438 L60.0820312,78.9960938 L96.3789062,86.984375 L127.128906,67.5742188"
                  id="Line"
                  stroke="#27B66C"
                  strokeWidth="8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  sketchType="MSShapeGroup"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div className="e-emptystate__content">
        <div className="e-emptystate__title">{getMessage("dashboardEmptyState.title")}</div>
        <div className="e-emptystate__lead">{getMessage("dashboardEmptyState.lead")}</div>
      </div>
    </div>
  );
};

DashboardEmptyState.propTypes = {};

export default DashboardEmptyState;
