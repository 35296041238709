export const memberProfileDeLegacy = {
  "memberGroupProfile.removeFromGroup.deleteDialog.content":
    "Nachdem Sie dieses Mitglied entfernt haben, werden die kumulierten {pointType} aus dem Gruppenstatus entfernt.",
  "memberProfile.updatePointsBtn.label": "Aktualisieren {isPoints,select,true {Points} other {Balance}}",
  "activityType.customer_support.cashbackRemoved.name": "Cashback entfernt ",
  "activityType.customer_support.pointsRemoved.name": "{{points_name}} entfernt",
  "activityType.points_expiration.toSpend.name": "Punktestand {{points_name}} verfallen",
  "memberProfileUpdatePointsPopup.confirmed.label":
    "Mir ist bewusst, dass sich diese Aktualisierungen auf den Treuestatus von  {text} auswirken"
};
