import React from "react";
import ReactDOM from "react-dom";
import ConfirmNavDialog from "../components/ConfrimNavDialog";
import { configureStore } from "../storeConfig";

export const setNavigationCanceled = (isCanceled) => {
  window.ls_nav_canceled = isCanceled;
};

export const getNavigationCanceled = () => {
  if (window.ls_nav_canceled) {
    window.ls_nav_canceled = false;
    return true;
  }

  return false;
};

export const renderConfirmNavDialog = (onConfirm, onCancel, message) => {
  const confirmNav = document.getElementById("confirmNavDialog");
  ReactDOM.unmountComponentAtNode(confirmNav);
  ReactDOM.render(<ConfirmNavDialog onConfirm={onConfirm} onCancel={onCancel} message={message} />, confirmNav);
};

export const navigateToMemberProfile = ({ history, supportMode, contactId, externalId }) => {
  externalId = externalId || contactId;
  sessionStorage.setItem("memberProfileDisplayId", externalId);
  sessionStorage.setItem("isSupportMode", !!supportMode);
  history.push(`/member-profile/${contactId}`);
};

export const navigateToGroupProfile = ({ history, supportMode, groupId, groupName }) => {
  sessionStorage.setItem("groupProfileDisplayId", groupName);
  sessionStorage.setItem("isSupportMode", !!supportMode);

  history.push(`/member-group-profile/${groupId}`);
};

export const getNavConfirmation = (message, callback) => {
  const onConfirm = () => {
    setNavigationCanceled(false);
    callback(true);
  };
  const onCancel = () => {
    setNavigationCanceled(true);
    callback(false);
  };
  renderConfirmNavDialog(onConfirm, onCancel, message);
};
