import axios from "axios";

import { isEmsSuite } from "../helpers/emsHelper";

export default class customerApi {
  static async getCustomer() {
    const response = await axios.get();
    return response.data;
  }

  static async getTranslations() {
    const response = await axios.get("/translations");
    return response.data;
  }

  static async getEmsAccountsMap() {
    if (await isEmsSuite()) {
      const response = await axios.get("/ems-accounts-map");
      return response.data;
    }
    return {};
  }

  static async getEmsAccountPermissions() {
    const response = await axios.get("/ems-account-permissions");
    return response.data;
  }

  static async getHasSalesData() {
    const response = await axios.get("/has-sales-data");
    return response.data;
  }

  static async getHasEmsSiData() {
    const response = await axios.get("/has-ems-si-data");
    return response.data;
  }

  static async isSuperAdmin() {
    const response = await axios.get("/is-super-admin");
    return response.data;
  }

  static async getSalesDataConfiguration() {
    const response = await axios.get("/sales-data-configuration");
    return response.data;
  }

  static async updateSalesDataConfiguration(data) {
    const response = await axios.put("/sales-data-configuration", data);
    return response.data;
  }

  static async getProductCatalogConfiguration() {
    const response = await axios.get("/product-catalog-configuration");
    return response.data;
  }

  static async updateProductCatalogConfiguration(data) {
    const response = await axios.put("/product-catalog-configuration", data);
    return response.data;
  }

  static async getTasksStats(startDate, endDate) {
    const response = await axios.get("/tasks-stats", {
      params: {
        startDate,
        endDate
      }
    });
    return response.data;
  }
}
