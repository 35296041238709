import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const LegendWithSelection = ({ visible, onChange, items, disableClick }) => {
  let style = {};
  if (!visible) {
    style.visibility = "hidden";
  }

  return (
    <div className="l-legend-selector text-align-center" style={style}>
      {items.map((item) => {
        const { id, isSelected, displayName, color } = { ...item };

        const symbolStyle = {
          color
        };
        return (
          <span
            key={`legend_item_${id}`}
            className={classNames("e-margin-2xs", {
              "e-clickable": !disableClick && !!onChange,
              "l-legend-item_selected": isSelected
            })}
            onClick={() => !disableClick && onChange && onChange(id)}
          >
            <span style={symbolStyle} dangerouslySetInnerHTML={{ __html: "\u25CF" }}></span>
            {displayName}
          </span>
        );
      })}
    </div>
  );
};

LegendWithSelection.propTypes = {
  visible: PropTypes.bool,
  onChange: PropTypes.func,
  disableClick: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      displayName: PropTypes.string,
      color: PropTypes.string,
      isSelected: PropTypes.bool
    })
  )
};

export default LegendWithSelection;
