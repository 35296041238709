import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import LoaderOverlay from "../../components/LoaderOverlay";
import customerApi from "../../api/customerApi";

import SecurityPageGrid from "./SecurityPageGrid";
import SecurityPageSetup from "./SecurityPageSetup";
import ReAuthPopup, { handleAuthError } from "./ReAuthPopup";

import { showErrorAlert, showWarningAlert } from "../../actions/alertActions";
import { selectCustomerIsDoubleHashSet } from "../../reducers";
import { SMART_INSIGHTS_HELP_LINK } from "../../constants/linkConstants";

import { getMessage } from "../../messages";

export default function SecurityPage({ pageType, loadApi, createApi, updateApi, deleteApi, maxItems }) {
  const [itemList, setItemList] = useState([]);
  const [emsAccountMap, setEmsAccountMap] = useState({});
  const [loading, setLoading] = useState(true);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showCreateReAuthPopup, setShowCreateReAuthPopup] = useState(false);
  const [showDeleteReAuthPopup, setShowDeleteReAuthPopup] = useState(false);
  const [authError, setAuthError] = useState("");
  const [showReAuthPopupLoading, setShowReAuthPopupLoading] = useState(false);
  const [hasEmsSiData, setHasEmsSiData] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const dispatch = useDispatch();

  const customerIsDoubleHashSet = useSelector((state) => selectCustomerIsDoubleHashSet(state));
  const messageKeyPrefix = `securitySettings.${pageType}`;

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      await loadEmsAccountMap();

      try {
        const data = await customerApi.isSuperAdmin();
        setIsSuperAdmin(data.isSuperAdmin);
      } catch (error) {
        console.error(error);
      }

      try {
        if (customerIsDoubleHashSet) {
          setHasEmsSiData(true);
        } else {
          await customerApi.getHasEmsSiData();
          setHasEmsSiData(true);
        }
      } catch (error) {
        const link = `<a target="_blank" href="${SMART_INSIGHTS_HELP_LINK}">${getMessage(
          "securitySettings.warning.smartInsightsLinkText"
        )}</a>`;

        const alertMessage = getMessage("securitySettings.warning.smartInsights", { link });
        dispatch(showWarningAlert(alertMessage, null, true));
      }

      try {
        await loadItems();
      } catch (error) {
        dispatch(showErrorAlert("An error occurred"));
        console.error(error);
      }
      setLoading(false);
    };
    init();
  }, []);

  async function refresh() {
    setIsEditMode(false);
    setLoading(true);
    await loadItems();
    setLoading(false);
    setAuthError("");
  }

  async function loadEmsAccountMap() {
    try {
      const data = await customerApi.getEmsAccountsMap();
      setEmsAccountMap(data);
    } catch (error) {
      console.error(error);
    }
  }

  async function loadItems() {
    try {
      const data = await loadApi();
      setItemList(data || []);
    } catch (error) {
      console.error(error);
    }
  }

  function getItemById(id) {
    return itemList.find((item) => item.id === id);
  }

  function onCreateItem() {
    setShowCreateReAuthPopup(true);
  }

  function createItem(password) {
    setShowReAuthPopupLoading(true);
    createApi(password)
      .then((itemResult) => {
        setItemToEdit(itemResult);
        setShowCreateReAuthPopup(false);
      })
      .catch((error) => {
        setAuthError(handleAuthError(error));
      })
      .finally(() => {
        setShowReAuthPopupLoading(false);
      });
  }

  function onDeleteItem(itemId) {
    setItemIdToDelete(itemId);
    setShowDeleteReAuthPopup(true);
  }

  function deleteItem(password) {
    setShowReAuthPopupLoading(true);
    deleteApi(itemIdToDelete, password)
      .then(() => {
        refresh();
        setShowDeleteReAuthPopup(false);
      })
      .catch((error) => {
        setAuthError(handleAuthError(error));
      })
      .finally(() => {
        setShowReAuthPopupLoading(false);
      });
  }

  function onEditItem(itemId) {
    const item = getItemById(itemId);
    setIsEditMode(true);
    setItemToEdit(item);
  }

  function onSave({ id, desc, active, isContactFullDelete }) {
    if (
      desc === itemToEdit.desc &&
      active === itemToEdit.active &&
      isContactFullDelete === itemToEdit.isContactFullDelete
    ) {
      setItemToEdit(null);
      return;
    }

    updateApi({ id, desc, active, isContactFullDelete }).finally(() => {
      setItemToEdit(null);
      refresh();
    });
  }

  function onCancelEdit() {
    setItemToEdit(null);
  }

  function getDisabledCreateMessage() {
    if (!hasEmsSiData) {
      return getMessage("securitySettings.disabledCreateButton.noSiData");
    }

    if (!isSuperAdmin) {
      return getMessage(`${messageKeyPrefix}.createButton.disabledTooltip.notAccountOwner`);
    }

    if (!!maxItems && itemList.length >= maxItems) {
      const maxItemsMessage = getMessage(`${messageKeyPrefix}.createButton.disabledTooltip.maxItems`, {
        maxItems
      });
      return maxItemsMessage;
    }
  }

  return (
    <div className="box-padding">
      {!itemToEdit ? (
        <Fragment>
          <SecurityPageGrid
            pageType={pageType}
            data={itemList}
            emsAccountMap={emsAccountMap}
            onEdit={onEditItem}
            onCreate={onCreateItem}
            onDelete={onDeleteItem}
            maxItems={maxItems}
            disableDelete={!hasEmsSiData || !isSuperAdmin}
            disableEdit={!hasEmsSiData}
            disabledCreateMessage={getDisabledCreateMessage()}
          />
          <ReAuthPopup
            id="createReAuthPopup"
            show={showCreateReAuthPopup}
            title={getMessage(`${messageKeyPrefix}.authPopup.create.title`)}
            subTitle={getMessage(`${messageKeyPrefix}.authPopup.create.content`)}
            onClose={() => {
              setShowCreateReAuthPopup(false);
              setAuthError("");
            }}
            onConfirm={createItem}
            authError={authError}
            loading={showReAuthPopupLoading}
          />
          <ReAuthPopup
            id="deleteReAuthPopup"
            show={showDeleteReAuthPopup}
            title={getMessage(`${messageKeyPrefix}.authPopup.delete.title`)}
            subTitle={getMessage(`${messageKeyPrefix}.authPopup.delete.content`)}
            onClose={() => {
              setShowDeleteReAuthPopup(false);
              setAuthError("");
            }}
            onConfirm={deleteItem}
            authError={authError}
            loading={showReAuthPopupLoading}
          />
        </Fragment>
      ) : (
        <SecurityPageSetup
          pageType={pageType}
          onSave={onSave}
          onCancel={onCancelEdit}
          id={itemToEdit.id}
          secret={itemToEdit.secret}
          initialDesc={itemToEdit.desc}
          initialActive={itemToEdit.active}
          initialIsContactFullDelete={itemToEdit.isContactFullDelete}
          isEditMode={isEditMode}
          viewMode={!isSuperAdmin}
        />
      )}
      <LoaderOverlay show={loading} />
    </div>
  );
}

SecurityPage.propTypes = {
  pageType: PropTypes.string,
  loadApi: PropTypes.func.isRequired,
  createApi: PropTypes.func.isRequired,
  updateApi: PropTypes.func.isRequired,
  deleteApi: PropTypes.func.isRequired,
  maxItems: PropTypes.number
};
