import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import RewardSetupValueForTiers from "./RewardSetupValueForTiers";

import InputField from "../components/InputField";
import MultiLangPopupOpener from "../components/MultiLangPopupOpener";
import { DateTimeRangeField } from "../components/DateFields";
import AccordionItem from "../components/AccordionItem";
import InputNumberField from "../components/InputNumberField";
import RadioButtonList from "../components/RadioButtonList";
import CheckboxField from "../components/CheckboxField";
import Alert from "../components/Alert";

import ValidityPeriodSetup from "./Shared/ValidityPeriodSetup";
import { REDEMPTION_VALUE_TOKEN, VALIDITY_TYPE_ENUM } from "../constants/rewardConstants.js";

import {
  EXCLUSIVE_ACCESS_STATUS_LABEL,
  CREATE_OR_MODIFY_OPTIONS,
  EXCLUSIVE_ACCESS_OFFERS_ELEMENTS_ENUM,
  EXCLUSIVE_ACCESS_OFFERS_ENUM,
  EXCLUSIVE_ACCESS_STATUS_ENUM,
  MASTER_EXCLUSIVE_ACCESS_ENUM,
  EXCLUSIVE_ACCESS_OFFERS_OPTIONS
} from "../constants/exclusiveAccessConstants";

import RedemptionVoucherMessageImg from "../assets/images/reward/redemption_voucher_message.png";

import { getErrorMessage } from "../validators";

import { rewardType } from "../types";

import exclusiveAccessService from "../services/exclusiveAccessService";
import InputFieldWithTokens from "../components/InputFieldWithTokens";
import { MULTI_CURRENCY_PROPS_MAP } from "../constants/actionConstants";
import { ALERT_STATUS } from "../constants/alertContants";
import { getMessage } from "../messages";
import Time from "../components/Time";

const ExclusiveAccessAndOffer = (props) => {
  const {
    exclusiveAccess,
    onInputChange,
    onRedemptionValueChange,
    tiers,
    tiersForFree,
    errors,
    onShowRedemptionMessageMultiLang,
    hasMultiLanguage,
    onAccordionStateChange,
    onValidityTypeChange,
    updateState,
    exclusiveAccessStatus,
    isNewOffer,
    isNewOfferChange,
    isPointsPlan,
    connectedFixedBenefitNames,
    connectedActionNames,
    connectedAdditionalBenefitsNames,
    exclusiveAccessForStatus,
    isViewOnly
  } = props;

  //const filteredTiers = tiers.filter(tier => !(hideUniformSettings && tier.tierId === SPECIAL_TIER_ENUM.ALL));
  const filteredTiers = tiers;
  const filteredTiersForFree = tiersForFree;

  const redemptionMessageTooltip = (path) => {
    if (path === EXCLUSIVE_ACCESS_OFFERS_ENUM.FOR_FREE) {
      return getMessage("exclusiveAccessAndOffer.redemptionMessageTooltip.free");
    } else return getMessage("exclusiveAccessAndOffer.redemptionMessageTooltip.points");
  };

  const renderOfferMessage = (path) => {
    return (
      <Fragment>
        <br />
        <label className="e-field__label">
          {getMessage("exclusiveAccessAndOffer.offerMessage.header")}
          <e-tooltip content={redemptionMessageTooltip(path)} type="helper" />
        </label>
        <InputField
          label={getMessage("exclusiveAccessAndOffer.offerMessage.title.label")}
          type="text"
          name={`${path}.message.title`}
          value={exclusiveAccess[path].message.title}
          disabled={isViewOnly}
          onChange={onInputChange}
          errorMsg={getErrorMessage(props.errors, `${path}.message.title`)}
          postfixButtons={
            hasMultiLanguage && <MultiLangPopupOpener onClick={() => onShowRedemptionMessageMultiLang(path)} />
          }
        />

        <InputFieldWithTokens
          label={getMessage("exclusiveAccessAndOffer.offerMessage.text.label")}
          type="text"
          name={`${path}.message.text`}
          value={exclusiveAccess[path].message.text}
          disabled={isViewOnly}
          tokens={path === EXCLUSIVE_ACCESS_OFFERS_ENUM.FOR_FREE ? null : REDEMPTION_VALUE_TOKEN}
          onChange={onInputChange}
          errorMsg={getErrorMessage(props.errors, `${path}.message.text`)}
          postfixButtons={
            hasMultiLanguage && <MultiLangPopupOpener onClick={() => onShowRedemptionMessageMultiLang(path)} />
          }
        />
      </Fragment>
    );
  };

  const renderOfferRestriction = (path) => {
    return (
      <Fragment>
        <label className="e-field__label e-field__label-required">
          {getMessage("exclusiveAccessAndOffer.offerRestriction.title")}
          <e-tooltip content={getMessage("exclusiveAccessAndOffer.offerRestriction.tooltip")} type="helper"></e-tooltip>
        </label>

        <InputNumberField
          label={getMessage("exclusiveAccessAndOffer.offerRestriction.limitValue.label")}
          type="number"
          name={`${path}.limitValue`}
          value={exclusiveAccess[path].limitValue}
          disabled={isViewOnly}
          onChange={onInputChange}
          errorMsg={getErrorMessage(props.errors, `${path}.limitValue`)}
        />
      </Fragment>
    );
  };

  const showNewOfferInput = (path) => {
    return (
      exclusiveAccessService.isPermanentExclusiveAccess(exclusiveAccess.masterExclusiveAccess) &&
      exclusiveAccessStatus === EXCLUSIVE_ACCESS_STATUS_ENUM.ACTIVE &&
      exclusiveAccessService.hasEnabledTier(exclusiveAccess[path])
    );
  };

  const renderOfferStatusAnbValidity = (path) => {
    let attr = {};
    if (exclusiveAccess.validity.accessValidity[path]?.accessDateRange?.end != null) {
      attr["maxDate"] = new Date(exclusiveAccess.validity.accessValidity[path].accessDateRange.end);
    }

    let attr2 = {};

    attr2["minDate"] = new Date();

    return (
      <div>
        <div>
          {showNewOfferInput(path) && (
            <RadioButtonList
              label={getMessage("exclusiveAccessAndOffer.offerStatusAnbValidity.isNewOfferRb.label")}
              name={`isNewOffer.${path}`}
              value={isNewOffer[path].toString()}
              disabled={isViewOnly}
              options={CREATE_OR_MODIFY_OPTIONS}
              onChange={(event) => isNewOfferChange(event, path)}
              tooltip={getMessage("exclusiveAccessAndOffer.offerStatusAnbValidity.isNewOfferRb.tooltip")}
              isHorizontal={true}
              isRequired={true}
            />
          )}
          <DateTimeRangeField
            label={
              showNewOfferInput(path)
                ? ""
                : getMessage("exclusiveAccessAndOffer.offerStatusAnbValidity.isNewOfferRb.label")
            }
            name={`${path}.offerValidity`}
            value={exclusiveAccess[path].offerValidity}
            disabled={isViewOnly}
            onChange={onInputChange}
            errorMsg={getErrorMessage(errors, `${path}.offerValidity`)}
            tooltip={
              showNewOfferInput(path)
                ? ""
                : getMessage("exclusiveAccessAndOffer.offerStatusAnbValidity.isNewOfferRb.tooltip")
            }
            {...attr}
            {...attr2}
            isRequired={!showNewOfferInput(path)}
          />
        </div>
      </div>
    );
  };

  const renderAccordionItem = (offer) => {
    const item = EXCLUSIVE_ACCESS_OFFERS_OPTIONS.filter((option) => option.id === offer)[0];
    const status =
      EXCLUSIVE_ACCESS_STATUS_LABEL[
        exclusiveAccessService.getExclusiveAccessOfferTypeStatus(
          exclusiveAccessForStatus,
          offer,
          connectedActionNames,
          connectedFixedBenefitNames,
          connectedAdditionalBenefitsNames
        )
      ];

    return (
      <AccordionItem
        title={
          <div className="e-grid e-grid-between">
            <div className="e-cell">{item.title}</div>
            <div className={`e-cell e-label ${status.className}`}>{status.label}</div>
          </div>
        }
        name={item.elementname}
        id={`validity.${offer}`}
        key={item.elementkey}
        open={item.accordionOpenState}
        onStateChange={(id, newState) => {
          onAccordionStateChange(id, newState);
        }}
      >
        <div className="e-row">
          <div className="e-col-6">
            {item.elements.includes(EXCLUSIVE_ACCESS_OFFERS_ELEMENTS_ENUM.TIERS) && (
              <Fragment>
                {item.path === EXCLUSIVE_ACCESS_OFFERS_ENUM.FOR_POINTS ? (
                  <label className="e-field__label">
                    {getMessage("exclusiveAccessAndOffer.offerTiersAndValue.points.label")}
                    <e-tooltip
                      content={getMessage("exclusiveAccessAndOffer.offerTiersAndValue.points.tooltip")}
                      type="helper"
                      style={{ marginTop: "2px", marginLeft: "6px" }}
                    />
                  </label>
                ) : (
                  getMessage("exclusiveAccessAndOffer.offerTiersAndValue.other.label")
                )}
                <RewardSetupValueForTiers
                  fieldNamePrefix={`${item.path}.value`}
                  tiers={item.path === EXCLUSIVE_ACCESS_OFFERS_ENUM.FOR_POINTS ? filteredTiers : filteredTiersForFree}
                  tierValueProp="promotion"
                  onRewardInputChange={onRedemptionValueChange}
                  errors={errors}
                  renderEnableSwitch={true}
                  onAccordionStateChange={props.onAccordionStateChange}
                  isRewardForFree={offer === EXCLUSIVE_ACCESS_OFFERS_ENUM.FOR_FREE}
                  postfixLable="Points"
                  disabled={isViewOnly}
                />
              </Fragment>
            )}

            {item.elements.includes(EXCLUSIVE_ACCESS_OFFERS_ELEMENTS_ENUM.MESSAGE) && renderOfferMessage(item.path)}
          </div>
          <div className="e-col-6">
            {item.elements.includes(EXCLUSIVE_ACCESS_OFFERS_ELEMENTS_ENUM.VALIDITY) &&
              exclusiveAccess.masterExclusiveAccess != MASTER_EXCLUSIVE_ACCESS_ENUM.LIMITED && (
                <ValidityPeriodSetup
                  dataObject={exclusiveAccess}
                  fieldNamePrefix={offer}
                  mainLabel={getMessage("exclusiveAccessAndOffer.accessValidity.mainLabel")}
                  mainTooltip={exclusiveAccessService.getAccessValidityTooltip(
                    offer,
                    connectedFixedBenefitNames,
                    connectedActionNames,
                    connectedAdditionalBenefitsNames
                  )}
                  validityDurationTooltip={getMessage("exclusiveAccessAndOffer.accessValidity.validityDurationTooltip")}
                  validityTypeOptions={exclusiveAccessService.getAccessValidityTypeOptions(
                    offer,
                    connectedFixedBenefitNames,
                    connectedActionNames,
                    connectedAdditionalBenefitsNames
                  )}
                  onValidityTypeChange={onValidityTypeChange}
                  updateState={updateState}
                  onInputChange={onInputChange}
                  errors={errors}
                  disabled={isViewOnly}
                />
              )}

            {exclusiveAccess.masterExclusiveAccess === MASTER_EXCLUSIVE_ACCESS_ENUM.LIMITED &&
              offer === EXCLUSIVE_ACCESS_OFFERS_ENUM.AS_REWARD && (
                <CheckboxField
                  label={getMessage("exclusiveAccessAndOffer.accessValidity.action.showOnActionCb.label")}
                  checked={exclusiveAccess.validity.accessValidity.action.showOnAction}
                  name={"validity.accessValidity.action.showOnAction"}
                  onChange={onInputChange}
                  disabled={connectedActionNames.length > 0 || isViewOnly}
                  tooltip={
                    !!connectedActionNames.length > 0
                      ? getMessage("exclusiveAccessAndOffer.accessValidity.action.showOnActionCb.tooltip", {
                          names: connectedActionNames.join(",")
                        })
                      : ""
                  }
                />
              )}

            {item.elements.includes(EXCLUSIVE_ACCESS_OFFERS_ELEMENTS_ENUM.OFFER_VALIDITY) &&
              renderOfferStatusAnbValidity(item.path)}

            {item.elements.includes(EXCLUSIVE_ACCESS_OFFERS_ELEMENTS_ENUM.RESTRICTION) &&
              !!exclusiveAccess.validity.accessValidity[offer].accessValidityType &&
              exclusiveAccess.validity.accessValidity[offer].accessValidityType != VALIDITY_TYPE_ENUM.UNLIMITED &&
              renderOfferRestriction(item.path)}
          </div>
        </div>
      </AccordionItem>
    );
  };

  const renderAccessOptions = () => {
    const AccordionItems = [];

    if (exclusiveAccess.masterExclusiveAccess === MASTER_EXCLUSIVE_ACCESS_ENUM.PERMANENT) {
      AccordionItems.push(renderAccordionItem(EXCLUSIVE_ACCESS_OFFERS_ENUM.AS_FIXEDBENEFIT));
      AccordionItems.push(renderAccordionItem(EXCLUSIVE_ACCESS_OFFERS_ENUM.AS_ADDITIONALBENEFITS));
    }

    AccordionItems.push(renderAccordionItem(EXCLUSIVE_ACCESS_OFFERS_ENUM.AS_REWARD));

    if (isPointsPlan) {
      AccordionItems.push(renderAccordionItem(EXCLUSIVE_ACCESS_OFFERS_ENUM.FOR_POINTS));
    }

    AccordionItems.push(renderAccordionItem(EXCLUSIVE_ACCESS_OFFERS_ENUM.FOR_FREE));
    return <div className="l-reward-setup-value e-accordion">{AccordionItems}</div>;
  };

  const renderAlert = () => {
    if (!exclusiveAccessService.isLimitedExclusiveAccess(exclusiveAccess.masterExclusiveAccess)) {
      return null;
    }

    const { blockingValidity } = { ...exclusiveAccess.validity };
    if (blockingValidity.start && blockingValidity.end) {
      // return <Alert status={ALERT_STATUS.INFO} message={`Exclusivity and access are defined as valid between 22.02.2022 - 25.02.2022`} />
      return (
        <e-notification type={ALERT_STATUS.INFO} class="e-margin-bottom-m">
          <e-notification-content>
            {getMessage("exclusiveAccessAndOffer.alert")} <Time value={blockingValidity.start} /> - {""}
            <Time value={blockingValidity.end} />
          </e-notification-content>
        </e-notification>
      );
    }

    return null;
  };

  const renderDescription = () => {
    let description = "";
    if (exclusiveAccessService.isLimitedExclusiveAccess(exclusiveAccess.masterExclusiveAccess)) {
      description = getMessage("exclusiveAccessAndOffer.desctiption.limited");
    } else {
      description = getMessage("exclusiveAccessAndOffer.desctiption.permanent");
    }
    return <p>{description}</p>;
  };

  return (
    <div className="e-box">
      <div className="e-row">
        <div className="e-col-12">
          <h2>{getMessage("exclusiveAccessAndOffer.title")}</h2>
          {renderAlert()}
          {renderDescription()}
          {renderAccessOptions()}
        </div>
      </div>
    </div>
  );
};

ExclusiveAccessAndOffer.propTypes = {
  onRedemptionValueChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  exclusiveAccess: rewardType.isRequired,
  tiers: PropTypes.array.isRequired,
  onAccordionStateChange: PropTypes.func,
  onShowRedemptionMessageMultiLang: PropTypes.func,
  updateState: PropTypes.func.isRequired,
  notificationElem: PropTypes.element,
  hasMultiLanguage: PropTypes.bool,
  errors: PropTypes.object,
  isViewOnly: PropTypes.bool
};

export default ExclusiveAccessAndOffer;
