import * as TYPES from "../actions/TYPES";
import sessionHelper from "../helpers/sessionHelper";
import planService from "../services/planService";

const initialState = {
  plan: {},
  loading: false,
  dataLoaded: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.GET_PLAN_REQUEST:
    case TYPES.UPDATE_PLAN_REQUEST:
    case TYPES.LAUNCH_PLAN_REQUEST:
    case TYPES.UPDATE_PLAN_LOCALE_REQUEST:
    case TYPES.UPDATE_ACCOUNT_SETTINGS_PLANS_REQUEST:
    case TYPES.DELETE_PLAN_REQUEST:
    case TYPES.ACTIVATE_PLAN_REQUEST:
    case TYPES.UPDATE_PLAN_DISTRIBUTION_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case TYPES.GET_PLAN_SUCCESS: {
      return {
        ...state,
        plan: action.payload,
        loading: false,
        dataLoaded: true
      };
    }

    case TYPES.UPDATE_ACCOUNT_SETTINGS_PLANS_SUCCESS: {
      const planIds = action.payload.planIds;
      sessionHelper.setPlanId(planIds[planIds.length - 1]);
      return {
        ...state,
        loading: true
      };
    }

    case TYPES.UPDATE_PLAN_SUCCESS: {
      const plan = action.payload.plan;

      return {
        ...state,
        plan,
        loading: false
      };
    }

    case TYPES.DELETE_PLAN_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case TYPES.LAUNCH_PLAN_SUCCESS:
    case TYPES.UPDATE_PLAN_LOCALE_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case TYPES.UPDATE_PLAN_DISTRIBUTION_SUCCESS: {
      const plan = { ...state.plan };
      plan.distribution = action.payload.distribution;

      return {
        ...state,
        plan,
        loading: false
      };
    }

    case TYPES.ACTIVATE_PLAN_SUCCESS: {
      const plan = { ...state.plan };
      plan.activated = true;

      return {
        ...state,
        plan,
        loading: false
      };
    }

    case TYPES.GET_PLAN_FAILURE:
    case TYPES.UPDATE_PLAN_FAILURE:
    case TYPES.LAUNCH_PLAN_FAILURE:
    case TYPES.UPDATE_PLAN_LOCALE_FAILURE:
    case TYPES.UPDATE_ACCOUNT_SETTINGS_PLANS_FAILURE:
    case TYPES.UPDATE_PLAN_DISTRIBUTION_FAILURE:
    case TYPES.DELETE_PLAN_FAILURE:
    case TYPES.ACTIVATE_PLAN_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    default:
      return state;
  }
}

export const isDataLoaded = (state) => {
  return state.dataLoaded;
};

export const isLoading = (state) => {
  return state.loading;
};

export const selectPlan = (state) => {
  return { ...state.plan };
};

export const selectPlanSettings = (state) => {
  return { ...state.plan.planSettings };
};

export const selectPlanType = (state) => {
  return state.plan.planType;
};

export const selectPlanCurrencyIdentifierSign = (state) => {
  return state.plan.planSettings.currencyIdentifierSign;
};

export const selectPlanLaunchedAt = (state) => {
  const { planLaunch } = state.plan;
  return planLaunch ? planLaunch.launchedAt : "";
};

export const selectPlanDistribution = (state) => {
  return { ...state.plan.distribution };
};

export const selectIsPlanActivated = (state) => {
  return state.plan.activated;
};

export const selectOptInValue = (state) => {
  return state.plan.planSettings.rules.optIn;
};

export const selectRewardsAllocationDelay = (state) => {
  return state.plan.planSettings.rules.rewardsAllocationDelay;
};

export const selectPlanContactDistribution = (state) => state.plan.planSettings.rules.contactDistribution;

export const selectPlanPointsNames = (state) => {
  const plan = state.plan.planSettings;
  return {
    pointsName: plan.pointsName,
    pointsNameML: plan.pointsNameML,
    singlePointName: plan.singlePointName,
    singlePointNameML: plan.singlePointNameML
  };
};

export const isBeforeFirstPlanLaunch = (state) => {
  return !state.plan.planLaunch;
};

export const selectLanguageSettings = (state) => state.plan.language;

export const selectStoreUrlSettings = (state) => state.plan.storeUrl;

export const selectCurrencySettings = (state) => state.plan.currency;

export const selectMarketSettings = (state) => state.plan.market;

export const selectLanguages = (state) => {
  const languageSettings = selectLanguageSettings(state);
  return [languageSettings.primary, ...(languageSettings.additional || [])];
};
export const selectPrimaryLanguage = (state) => selectLanguageSettings(state).primary;

export const selectStoreUrls = (state) => {
  const storeUrlSettings = selectStoreUrlSettings(state);
  return [storeUrlSettings.primary, ...(storeUrlSettings.additional || [])];
};
export const selectPrimaryStoreUrl = (state) => selectStoreUrlSettings(state).primary;

export const selectStoreUrlLangMap = (state) => {
  const languages = selectLanguages(state);
  const storeUrls = selectStoreUrls(state);

  let map = {};
  languages.forEach((lang, index) => {
    map[lang] = storeUrls[index];
  });

  return map;
};

export const selectCurrencies = (state) => {
  const currencySettings = selectCurrencySettings(state);
  return [currencySettings.primary, ...(currencySettings.additional || [])];
};
export const selectPrimaryCurrency = (state) => selectCurrencySettings(state).primary;

export const selectMarkets = (state) => {
  const marketSettings = selectMarketSettings(state);
  if (marketSettings) {
    return [marketSettings.primary, ...(marketSettings.additional || [])];
  }
};
export const selectPrimaryMarket = (state) => {
  const marketSettings = selectMarketSettings(state);
  if (marketSettings) {
    return marketSettings.market;
  }
};
