import React, { Component } from "react";
import { connect } from "react-redux";

import FormStepsContainer from "../components/FormStepsContainer";
import Alert from "../components/Alert";
import Notification from "../components/Notification";

import OnBoardingSelectPlan from "../views/OnBoardingSelectPlan";
import AccountSettings from "../pages/AccountSettings";
import LanguageAndCurrency from "../pages/LanguageAndCurrency";
import PlanSettings from "../pages/PlanSettings";
import MembershipSettings from "../pages/MembershipSettings";
import Distribution from "../pages/Distribution";
import OnBoardingActivation from "../views/OnBoardingActivation";

import {
  selectCustomerPlans,
  selectPlanDistribution,
  selectPlanSettings,
  selectPlanLanguageSettings,
  selectPlanCurrencySettings,
  selectPlanType
} from "../reducers";

import { activatePlan } from "../actions/planActions";

import { FORM_STEPS, FORM_STEPS_SPEND, FORM_STEP_ENUM } from "../constants/onBoardingConstants";
import { ALERT_STATUS } from "../constants/alertContants";
import { CONTACT_DISTRIBUTION_ENUM } from "../constants/planConstants";

import { isEmptyObject } from "../helpers/objectHelper";
import { isDemoMode } from "../helpers/envHelper";

import "../assets/styles/OnBoarding.scss";
import planService from "../services/planService";
import CashbackSettings from "./CashbackSettings";
import { getMessage } from "../messages";

class OnBoarding extends Component {
  constructor(props) {
    super(props);
    this.submitFunc = null;
    this.validateFunc = null;

    this.state = {
      triggerSubmit: false,
      currentStepId: FORM_STEP_ENUM.ACCOUNT_SETTINGS,
      currentContactDistributionType: isEmptyObject(props.planSettings)
        ? null
        : props.planSettings.rules.contactDistribution,
      currentLaunchType: isEmptyObject(props.planSettings) ? null : props.planSettings.rules.launchType
    };
  }

  getFormSteps() {
    const { props } = this;
    const formSteps = planService.isSpendPlanType(this.props.planType) ? [...FORM_STEPS_SPEND] : [...FORM_STEPS];
    const steps = formSteps.map((step) => {
      step.disabled = false;
      if (!props.hasPlans && step.id != FORM_STEP_ENUM.ACCOUNT_SETTINGS) {
        step.disabled = true;
      }

      if (
        (step.id === FORM_STEP_ENUM.PLAN_SETTINGS ||
          step.id === FORM_STEP_ENUM.ACTIVATION ||
          step.id === FORM_STEP_ENUM.MEMBERSHIP_SETTINGS ||
          step.id === FORM_STEP_ENUM.CASHBACK) &&
        (!props.hasPrimaryLanguage || !props.hasPrimaryCurrency)
      ) {
        step.disabled = true;
      }

      if (
        (step.id === FORM_STEP_ENUM.ACTIVATION ||
          step.id === FORM_STEP_ENUM.MEMBERSHIP_SETTINGS ||
          step.id === FORM_STEP_ENUM.CASHBACK) &&
        ((!!props.planSettings?.pointsName?.length && !props.planSettings?.pointsName?.length > 0) ||
          !props.planSettings?.rules?.rewardsAllocationDelay?.duration)
      ) {
        step.disabled = true;
      }
      return step;
    });

    return steps;
  }

  //Todo: fix this validation
  isActivationValid() {
    return !isEmptyObject(this.props.planSettings);
  }

  registerSubmitFunc(func) {
    this.submitFunc = func;
  }

  registerValidateFunc(func) {
    this.validateFunc = func;
  }

  onSubmit() {
    if (this.submitFunc) {
      this.submitFunc();
    }
  }

  onValidate() {
    if (this.validateFunc) {
      return this.validateFunc();
    }

    return true;
  }

  onStepChange(step) {
    this.setState({
      currentStepId: step.id,
      triggerSubmit: false
    });
  }

  onActivateLoyalty() {
    this.props.activatePlan();
  }

  onPlanSelect() {
    this.setState({
      triggerSubmit: true
    });
  }

  getAlertElement() {
    const { currentStepId } = this.state;

    if (currentStepId === FORM_STEP_ENUM.ACTIVATION && this.isActivationValid()) {
      return <Alert message={getMessage("onboarding.activationAlert")} status={ALERT_STATUS.SUCCESS} />;
    }

    return null;
  }

  renderFormStepsComponents(steps) {
    let result = [];
    steps.forEach((step) => {
      if (step.id === FORM_STEP_ENUM.ACCOUNT_SETTINGS) {
        result.push(
          <main className="e-layout__content">
            <section className="e-layout__section e-layout__section-with_legacy_tabs">
              <AccountSettings
                fromOnboarding={true}
                registerSubmitFunc={this.registerSubmitFunc.bind(this)}
                registerValidateFunc={this.registerValidateFunc.bind(this)}
              />
            </section>
          </main>
        );
      }
      if (step.id === FORM_STEP_ENUM.LANGUAGE_AND_CURRENCY) {
        result.push(
          <main className="e-layout__content">
            <section className="e-layout__section e-layout__section-with_legacy_tabs">
              <LanguageAndCurrency
                fromOnboarding={true}
                registerSubmitFunc={this.registerSubmitFunc.bind(this)}
                registerValidateFunc={this.registerValidateFunc.bind(this)}
              />
            </section>
          </main>
        );
      }
      if (step.id === FORM_STEP_ENUM.PLAN_SETTINGS) {
        result.push(
          <main className="e-layout__content">
            <section className="e-layout__section e-layout__section-with_legacy_tabs">
              <PlanSettings
                fromOnboarding={true}
                registerSubmitFunc={this.registerSubmitFunc.bind(this)}
                registerValidateFunc={this.registerValidateFunc.bind(this)}
              />
            </section>
          </main>
        );
      }
      if (step.id === FORM_STEP_ENUM.CASHBACK) {
        result.push(
          <main className="e-layout__content">
            <section className="e-layout__section e-layout__section-with_legacy_tabs">
              <CashbackSettings
                fromOnboarding={true}
                registerSubmitFunc={this.registerSubmitFunc.bind(this)}
                registerValidateFunc={this.registerValidateFunc.bind(this)}
              />
            </section>
          </main>
        );
      }
      if (step.id === FORM_STEP_ENUM.MEMBERSHIP_SETTINGS) {
        result.push(
          <main className="e-layout__content">
            <section className="e-layout__section e-layout__section-with_legacy_tabs">
              <MembershipSettings
                fromOnboarding={true}
                registerSubmitFunc={this.registerSubmitFunc.bind(this)}
                registerValidateFunc={this.registerValidateFunc.bind(this)}
              />
            </section>
          </main>
        );
      }
      if (step.id === FORM_STEP_ENUM.ACTIVATION) {
        result.push(<OnBoardingActivation onActivateLoyalty={this.onActivateLoyalty.bind(this)} />);
      }
    });

    return result;
  }

  render() {
    const steps = this.getFormSteps();
    return (
      <FormStepsContainer
        title={getMessage("onboarding.title")}
        steps={steps}
        nextStepOnSubmit={true}
        applySubmitOnNextStep={true}
        overviewLink=""
        className="onboarding-setup-form p-b-75"
        onSubmit={this.onSubmit.bind(this)}
        onStepChange={this.onStepChange.bind(this)}
        hideSubmitInSteps={[steps.length - 1]}
        triggerSubmit={this.state.triggerSubmit}
        validateStepFunc={this.onValidate.bind(this)}
        alertElement={this.getAlertElement()}
        showMultiPlanSelect={this.state.currentStepId !== FORM_STEP_ENUM.ACCOUNT_SETTINGS}
        startStepId={this.props.hasPlans ? FORM_STEP_ENUM.LANGUAGE_AND_CURRENCY : FORM_STEP_ENUM.ACCOUNT_SETTINGS}
      >
        {this.renderFormStepsComponents(steps)}
      </FormStepsContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const languageSettings = selectPlanLanguageSettings(state);
  const currencySettings = selectPlanCurrencySettings(state);
  return {
    hasPlans: !!selectCustomerPlans(state),
    distribution: selectPlanDistribution(state),
    planSettings: selectPlanSettings(state),
    planType: selectPlanType(state),
    hasPrimaryLanguage: languageSettings && languageSettings.primary,
    hasPrimaryCurrency: currencySettings && currencySettings.primary
  };
};

const mapDispatchToProps = (dispatch) => ({
  activatePlan: () => {
    dispatch(activatePlan());
  }
});

OnBoarding.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(OnBoarding);
