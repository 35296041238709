import axios from "axios";
import sessionHelper from "../helpers/sessionHelper";

export default class planApi {
  static async getAllPlans() {
    const response = await axios.get(`/plan`);
    return response.data;
  }

  static async getPlan(id) {
    const planId = id || sessionHelper.getPlanId();
    const response = await axios.get(`/plan/${planId}`);
    return response.data;
  }

  static async updatePlan(id, planData) {
    const planId = id || sessionHelper.getPlanId();
    const response = await axios.put(`/plan/${planId}`, planData);
    return response.data;
  }

  static async updateAccountSettingsPlans(data) {
    const response = await axios.put(`/account-settings-plans`, data);
    return response.data;
  }

  static async deletePlan(planId) {
    const response = await axios.delete(`/plan/${planId}`);
    return response;
  }

  static async launchPlan() {
    const planId = sessionHelper.getPlanId();
    const response = await axios.post(`/plan/${planId}/launch-plan`);
    return response.data;
  }

  static async getDistributionPreview(data) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.post(`/plan/${planId}/distribution-preview`, data);
    return response.data;
  }

  static async updateDistribution(data) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.put(`/plan/${planId}/distribution`, data);
    return response.data;
  }

  static async activatePlan() {
    const planId = sessionHelper.getPlanId();
    const response = await axios.post(`/plan/${planId}/activate`);
    return response.data;
  }

  static async updateLocale(data) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.put(`/plan/${planId}/locale`, data);
    return response.data;
  }
}
