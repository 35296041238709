import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import SelectField from "../../components/SelectFieldV2";
import InputNumberField from "../../components/InputNumberField";
import CheckboxField from "../../components/CheckboxField";

import { getErrorMessage } from "../../validators";
import planService from "../../services/planService";
import { getMessage } from "../../messages";
import actionService from "../../services/actionService";

const ActionSetupTierFields = (props) => {
  const getFieldName = (tierId, propName) => {
    return `reward.value.${tierId}.${propName}`;
  };

  const {
    onInputChange,
    onEnableInputChange,
    showPoints,
    showMultiplyPoints,
    showVoucher,
    showExclusiveAccess,
    showTierUpgrade,
    errors,
    viewMode,
    disableMultiplyPoints,
    plan,
    action,
    multiplyPointsEnabledTooltip
  } = props;

  let spendTooltip = {};

  const renderMultiplyPointsField = (props) => {
    const { tierRewards, tierId, multiplyPointsOptions } = props;

    if (!showMultiplyPoints) {
      return null;
    }

    return (
      <div className="e-cell e-cell-auto e-cell-small">
        <div className="e-field l-field__input-checkbox">
          <CheckboxField
            label={actionService.getMultiplyPointsLabel(plan, action)}
            renderOnlyCheckbox={true}
            checked={tierRewards.multiplyPointsEnabled}
            name={getFieldName(tierId, "multiplyPointsEnabled")}
            onChange={(event) => onEnableInputChange(event, "multiplyPoints", "")}
            disabled={disableMultiplyPoints || viewMode}
            tooltip={multiplyPointsEnabledTooltip}
          />

          <div className="m-t-5">
            <SelectField
              name={getFieldName(tierId, "multiplyPoints")}
              value={tierRewards.multiplyPointsEnabled ? tierRewards.multiplyPoints : ""}
              options={multiplyPointsOptions}
              placeholder={getMessage("actionSetupTierFields.multiplyPoints.placeholder")}
              onChange={onInputChange}
              disabled={!tierRewards.multiplyPointsEnabled || viewMode}
              errorMsg={getErrorMessage(errors, getFieldName(tierId, "multiplyPoints"))}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderPointsField = (props) => {
    if (!showPoints) {
      return null;
    }

    const { tierRewards, tierId, onInputChange } = props;

    if (planService.isSpendPlan(plan))
      spendTooltip = { tooltip: getMessage("actionSetupTierFields.pointsEnabled.tooltip.spend") };

    return (
      <div className="e-cell e-cell-auto e-cell-small">
        <div className="e-field l-field__input-checkbox">
          <CheckboxField
            label={actionService.getBonusPointsLabel(plan, action)}
            renderOnlyCheckbox={true}
            checked={tierRewards.pointsEnabled}
            name={getFieldName(tierId, "pointsEnabled")}
            onChange={(event) => onEnableInputChange(event, "points", undefined)}
            disabled={viewMode}
            {...spendTooltip}
          />
          <div className="m-t-5">
            <InputNumberField
              value={tierRewards.pointsEnabled ? tierRewards.points : undefined}
              name={getFieldName(tierId, "points")}
              placeholder={
                !planService.isSpendPlan(plan)
                  ? getMessage("actionSetupTierFields.points.placeholder.points")
                  : getMessage("actionSetupTierFields.points.placeholder.spend")
              }
              onChange={onInputChange}
              disabled={!tierRewards.pointsEnabled || viewMode}
              errorMsg={getErrorMessage(errors, getFieldName(tierId, "points"))}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderVoucherField = (props) => {
    if (!showVoucher) {
      return null;
    }

    const { tierRewards, tierId, onInputChange, rewardVoucherOptions } = props;
    const className = classNames("e-cell e-cell-auto e-cell-small");
    return (
      <div className={className}>
        <div className="e-field l-field__input-checkbox">
          <CheckboxField
            label={getMessage("actionSetupTierFields.rewardPoolEnabledCb.label")}
            renderOnlyCheckbox={true}
            checked={tierRewards.rewardPoolEnabled}
            name={getFieldName(tierId, "rewardPoolEnabled")}
            onChange={(event) => onEnableInputChange(event, "rewardPool", "")}
            disabled={viewMode}
          />
          <div className="m-t-5">
            <SelectField
              name={getFieldName(tierId, "rewardPool")}
              value={tierRewards.rewardPoolEnabled ? tierRewards.rewardPool : ""}
              options={rewardVoucherOptions}
              onChange={onInputChange}
              placeholder={getMessage("actionSetupTierFields.rewardPool.placeholder")}
              disabled={!tierRewards.rewardPoolEnabled || viewMode}
              errorMsg={getErrorMessage(errors, getFieldName(tierId, "rewardPool"))}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderExclusiveAccessField = (props) => {
    if (!showExclusiveAccess) {
      return null;
    }

    const { tierRewards, tierId, onInputChange, rewardExclusiveAccessOptions } = props;

    const className = classNames("e-cell e-cell-auto e-cell-small", {
      "l-overlay-disabled": !props.isExclusiveAccessEnabled
    });

    return (
      <div className={className}>
        <div className="e-field l-field__input-checkbox">
          <CheckboxField
            label={getMessage("actionSetupTierFields.exclusiveAccessEnabledCb.label")}
            renderOnlyCheckbox={true}
            checked={tierRewards.exclusiveAccessEnabled}
            name={getFieldName(tierId, "exclusiveAccessEnabled")}
            onChange={(event) => onEnableInputChange(event, "exclusiveAccess", "")}
            disabled={viewMode}
          />
          <div className="m-t-5">
            <SelectField
              name={getFieldName(tierId, "exclusiveAccess")}
              value={tierRewards.exclusiveAccessEnabled ? tierRewards.exclusiveAccess : ""}
              options={rewardExclusiveAccessOptions}
              onChange={onInputChange}
              placeholder={getMessage("actionSetupTierFields.exclusiveAccess.placeholder")}
              disabled={!tierRewards.exclusiveAccessEnabled || viewMode}
              errorMsg={getErrorMessage(errors, getFieldName(tierId, "exclusiveAccess"))}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderTierUpField = (props) => {
    if (!showTierUpgrade) {
      return null;
    }

    const { tierRewards, tierId, onInputChange, statusUpgradeOptions } = props;

    const className = classNames("e-cell e-cell-auto e-cell-small");

    return (
      <div className={className}>
        <div className="e-field l-field__input-checkbox">
          <CheckboxField
            label={getMessage("actionSetupTierFields.statusUpgradeEnabledCb.label")}
            renderOnlyCheckbox={true}
            checked={tierRewards.statusUpgradeEnabled}
            name={getFieldName(tierId, "statusUpgradeEnabled")}
            onChange={(event) => onEnableInputChange(event, "statusUpgrade", "")}
            disabled={viewMode || statusUpgradeOptions.length === 0}
          />

          <div className="m-t-5">
            <SelectField
              name={getFieldName(tierId, "statusUpgrade")}
              value={tierRewards.statusUpgradeEnabled ? tierRewards.statusUpgrade : ""}
              options={statusUpgradeOptions}
              placeholder={getMessage("actionSetupTierFields.statusUpgrade.placeholder")}
              onChange={onInputChange}
              disabled={!tierRewards.statusUpgradeEnabled || viewMode}
              errorMsg={getErrorMessage(errors, getFieldName(tierId, "statusUpgrade"))}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="e-grid e-grid-between">
      {renderMultiplyPointsField(props)}

      {renderPointsField(props)}

      {renderVoucherField(props)}

      {renderExclusiveAccessField(props)}

      {renderTierUpField(props)}
    </div>
  );
};

ActionSetupTierFields.propTypes = {
  tierRewards: PropTypes.object,
  tierId: PropTypes.string,
  index: PropTypes.number,
  onInputChange: PropTypes.func,
  onEnableInputChange: PropTypes.func,
  rewardVoucherOptions: PropTypes.array,
  rewardExclusiveAccessOptions: PropTypes.array,
  multiplyPointsOptions: PropTypes.array,
  statusUpgradeOptions: PropTypes.array,
  showPoints: PropTypes.bool,
  showMultiplyPoints: PropTypes.bool,
  disableMultiplyPoints: PropTypes.bool,
  showVoucher: PropTypes.bool,
  showExclusiveAccess: PropTypes.bool,
  showTierUpgrade: PropTypes.bool,
  errors: PropTypes.object,
  viewMode: PropTypes.bool,
  isExclusiveAccessEnabled: PropTypes.bool
};

export default ActionSetupTierFields;
