import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import PopupContainer from "./PopupContainer";

import { EDITOR_TYPE_ENUM } from "../constants/rewardConstants";
import "../assets/styles/EditorTypePopup.scss";
import { getMessage } from "../messages";

const options = [
  {
    textKey: "editorTypePopup.options.html.text",
    icon: "https://assets.emarsys.net/ui/static/images/custom_html_logo.png",
    queryParams: {
      editorType: EDITOR_TYPE_ENUM.HTML
    }
  },
  {
    textKey: "editorTypePopup.options.template.text",
    icon: "https://assets.emarsys.net/ui/static/images/content_blocks_logo.png",
    queryParams: {
      editorType: EDITOR_TYPE_ENUM.TEMPLATE
    }
  }
];

const OptionBox = ({ icon, text, url, queryParams }) => {
  const { editorType } = queryParams;
  const urlWithParams = url + `?editor=${editorType}`;
  return (
    <NavLink className="link e-btn e-btn-fullwidth" to={urlWithParams}>
      <img id="option_box_img" src={icon} alt={text} width="80" height="80" />
      <div>{text}</div>
    </NavLink>
  );
};

const EditorTypePopup = ({ show, addNewCardLink, onClose }) => (
  <PopupContainer id="EditorTypePopup" title={getMessage("editorTypePopup.title")} show={show} onClose={onClose}>
    <React.Fragment>
      <p>{getMessage("editorTypePopup.text")}</p>
      <div className="option_box_container">
        {options.map(({ icon, queryParams, textKey }) => (
          <OptionBox
            key={getMessage(textKey)}
            icon={icon}
            text={getMessage(textKey)}
            url={addNewCardLink}
            queryParams={queryParams}
          />
        ))}
      </div>
    </React.Fragment>
  </PopupContainer>
);

EditorTypePopup.propTypes = {
  show: PropTypes.bool,
  addNewCardLink: PropTypes.string,
  onClose: PropTypes.func
};

export default EditorTypePopup;
