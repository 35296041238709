import React, { Fragment } from "react";
import PropTypes from "prop-types";

import InputNumberField from "../../components/InputNumberField";
import AccordionItem from "../../components/AccordionItem";

import { getCurrencySymbol, getCurrencyTitle } from "../../helpers/currencyHelper";
import { getErrorMessage } from "../../validators";

import "../../assets/styles/PointsRatesFields.scss";
import tierService from "../../services/tierService";
import planService from "../../services/planService";
import { getMessage } from "../../messages";

const PointsRatesFields = ({ benefit, currencies, onInputChange, propNamePrefix, errors, disabled, plan, tier }) => {
  const { pointsRates } = { ...benefit };
  const hasZeroPoints = tierService.hasZeroPointsRate(benefit, currencies);
  const hasPointsValue = tierService.hasPointsRateValue(benefit, currencies);

  const renderPointsRateField = (currency) => {
    const pointsName = `${propNamePrefix}.pointsRates.${currency}.points`;
    const spentName = `${propNamePrefix}.pointsRates.${currency}.spent`;
    const pointsValue = pointsRates && pointsRates[currency] ? pointsRates[currency].points : "";
    const spentValue = pointsRates && pointsRates[currency] ? pointsRates[currency].spent : "";
    const pointsDisabled = disabled || (hasZeroPoints && !hasPointsValue && pointsValue != 0);
    const spentDisabled = disabled || (hasZeroPoints && !hasPointsValue);

    return (
      <div className="points-rates-fields l-table l-table-top">
        <div className="l-table-row">
          {planService.isSpendPlan(plan) && <div className="earned-per-text l-table-cell">Award</div>}
          <div className="l-table-cell">
            <InputNumberField
              name={pointsName}
              value={pointsValue}
              onChange={onInputChange}
              postfixLabel={tierService.getPointsPostfixLabel(plan, tier)}
              errorMsg={pointsDisabled ? "" : getErrorMessage(errors, pointsName)}
              min={1}
              noMargin={true}
              labelClassName="no-margin"
              disabled={pointsDisabled}
              allowDecimal={planService.isSpendPlan(plan) ? true : false}
            />
          </div>
          <div className="earned-per-text l-table-cell">
            {!planService.isSpendPlan(plan)
              ? getMessage("pointsRatesFields.perDescriptor.points")
              : getMessage("pointsRatesFields.perDescriptor.spend")}
          </div>
          <div className="l-table-cell">
            <InputNumberField
              //label="Tier value in points"
              name={spentName}
              value={spentValue}
              onChange={onInputChange}
              postfixLabel={getMessage("tierSetupGeneral.tierPoints.postfixLabel.spend", {
                value: getCurrencySymbol(currency)
              })}
              errorMsg={spentDisabled ? "" : getErrorMessage(errors, spentName)}
              min={1}
              noMargin={true}
              labelClassName="no-margin"
              disabled={spentDisabled}
              allowDecimal={planService.isSpendPlan(plan) ? true : false}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderAccordion = () => {
    const accordionItems = currencies.map((currency, index) => {
      const id = `accordion-checkbox_${propNamePrefix}_${index}`;
      return (
        <AccordionItem title={getCurrencyTitle(currency)} name="accordion-tier" id={id} key={id} open={index === 0}>
          {renderPointsRateField(currency)}
        </AccordionItem>
      );
    });
    return <div class="e-accordion">{accordionItems}</div>;
  };

  if (currencies.length === 1) {
    return renderPointsRateField(currencies[0]);
  }
  return renderAccordion();
};

PointsRatesFields.propTypes = {
  benefit: PropTypes.object,
  currencies: PropTypes.array,
  onInputChange: PropTypes.func,
  propNamePrefix: PropTypes.string,
  errors: PropTypes.object,
  disabled: PropTypes.bool
};

export default PointsRatesFields;
