import React, { Component, Fragment } from "react";
import { renderToString } from "react-dom/server";
import { connect } from "react-redux";
import classNames from "classnames";
import { uniqBy } from "lodash";

import CardsContainer from "../components/CardsContainer";
import EditorTypePopup from "../components/EditorTypePopup";
import Notification from "../components/Notification";
import Navigation from "../components/Navigation";
import Time from "../components/Time";

import {
  selectActions,
  selectFixedBenefits,
  selectExclusives,
  selectIsBeforeFirstPlanLaunch,
  selectShowCurrentPlan,
  selectAdditionalBenefits,
  selectPlan,
  isAccountViewOnly
} from "../reducers";
import { deleteExclusiveAccess } from "../actions/exclusiveAccessActions";

import { openDeleteDialog } from "../helpers/dialogHelper";

import {
  MASTER_EXCLUSIVE_ACCESS_ENUM,
  EXCLUSIVE_ACCESS_STATUS_ENUM,
  EXCLUSIVE_ACCESS_STATUS_LABEL
} from "../constants/exclusiveAccessConstants";

import exclusiveAccessService from "../services/exclusiveAccessService";

import { EXCLUSIVE_ACCESS_TABS } from "../constants/navigationConstants";
import { CONFIG_OBJ_ENUM } from "../constants/configConstants";
import { getMessage, getPluralCardinalMessage } from "../messages";
import { VALIDITY_TYPE_ENUM } from "../constants/rewardConstants";
import { EXCLUSIVE_ACCESS_SETUP_LINK } from "../constants/linkConstants";

class ExclusiveAccessOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMasterExclusiveAccess: props.match.params.masterExclusiveAccess || MASTER_EXCLUSIVE_ACCESS_ENUM.PERMANENT,
      showDialog: false
    };
  }

  onShowDialog() {
    this.setState({
      showDialog: true
    });
  }

  onPopupClose() {
    this.setState({
      showDialog: false
    });
  }

  getItemLinkUrl(id, isDup) {
    if (!id) {
      return `/exclusive-access-edit-new/${this.state.currentMasterExclusiveAccess}`;
    } else if (isDup) {
      return `/exclusive-access-edit-new/${this.state.currentMasterExclusiveAccess}/${id}`;
    } else {
      return `/exclusive-access-edit/${id}`;
    }
  }

  getConnectedTooltipText(statusObj) {
    if (
      !statusObj.connectedActionNames &&
      !statusObj.connectedFixedBenefitNames &&
      !statusObj.connectedAdditionalBenefitsNames &&
      !statusObj.redemptionOn
    ) {
      return "";
    }

    const connected = [
      ...statusObj.connectedFixedBenefitNames,
      ...statusObj.connectedAdditionalBenefitsNames,
      ...statusObj.connectedActionNames
    ];

    if (connected.length === 0 && !statusObj.redemptionOn && !statusObj.redemptionForFreeOn) {
      return "";
    }

    const tooltipBenefitsContent =
      statusObj.connectedFixedBenefitNames.length > 0 || statusObj.connectedAdditionalBenefitsNames.length > 0
        ? [...statusObj.connectedFixedBenefitNames, ...statusObj.connectedAdditionalBenefitsNames]
            .map((name) => {
              return `- Benefit / ${name} <br/>`;
            })
            .join("")
        : "";

    const connectedActionNames =
      statusObj.connectedActionNames.length > 0
        ? statusObj.connectedActionNames
            .map((name) => {
              return `- Actions / ${name} <br/>`;
            })
            .join("")
        : "";

    const connectedToWallet =
      statusObj.redemptionOn || statusObj.redemptionForFreeOn
        ? `- ${getMessage("exclusiveOverviewPage.card.walletInTooltip")} <br/>`
        : "";

    return `${connectedToWallet} ${tooltipBenefitsContent} ${connectedActionNames}`;
  }

  getStatusLabel(statusObj, exclusiveAccess) {
    const statusLabel = EXCLUSIVE_ACCESS_STATUS_LABEL[statusObj.status];

    const label = getMessage(statusLabel.labelId);
    let tooltip = "";

    if (statusObj.status === EXCLUSIVE_ACCESS_STATUS_ENUM.ACTIVE) {
      const connected = [
        ...statusObj.connectedFixedBenefitNames,
        ...statusObj.connectedAdditionalBenefitsNames,
        ...statusObj.connectedActionNames
      ];

      if (connected.length > 0 || statusObj.redemptionOn) {
        tooltip = getMessage("exclusiveOverviewPage.card.activeAndConnectedTooltip", {
          text: "<br />" + this.getConnectedTooltipText(statusObj)
        });
      } else {
        tooltip = getMessage("exclusiveOverviewPage.card.activeReadyToConnectTooltip");
      }
    } else if (statusObj.status === EXCLUSIVE_ACCESS_STATUS_ENUM.DEACTIVATED) {
      tooltip = getMessage("exclusiveOverviewPage.card.deactivatedTooltip");
    } else if (statusObj.status === EXCLUSIVE_ACCESS_STATUS_ENUM.INACTIVE) {
      tooltip = getMessage("exclusiveOverviewPage.card.inactiveTooltip");
    } else if (statusObj.status === EXCLUSIVE_ACCESS_STATUS_ENUM.EXPIRED) {
      tooltip = getMessage("exclusiveOverviewPage.card.expiredTooltip");
    } else if (statusObj.status === EXCLUSIVE_ACCESS_STATUS_ENUM.SCHEDULED) {
      tooltip = getMessage("exclusiveOverviewPage.card.scheduledTooltip", {
        text: renderToString(<Time value={exclusiveAccess.validity.blockingValidity.start} />)
      });
    }

    return {
      label,
      className: statusLabel.className,
      tooltip
    };
  }

  getDeleteIcon(statusObj) {
    let disabled = false;
    let tooltip = "";

    if (statusObj.status === EXCLUSIVE_ACCESS_STATUS_ENUM.ACTIVE) {
      disabled = true;
    } else {
      const connectedText = this.getConnectedTooltipText(statusObj);
      const connectedToText = getMessage("exclusiveOverviewPage.card.connectedTo");
      if (!!connectedText) {
        disabled = true;
        tooltip = `${connectedToText}: <br/>${connectedText}`;
      }
    }

    if (this.props.isAccountViewOnly) {
      disabled = true;
    }

    return {
      onClick: this.onDeleteRewardClick.bind(this),
      disabled,
      tooltip
    };
  }

  getEditIcon(statusObj, exclusiveAccessId) {
    return {
      link: this.getItemLinkUrl(exclusiveAccessId, false)
    };
  }

  getCurrentCards() {
    const currentMasterExclusiveAccess = this.state.currentMasterExclusiveAccess;
    const badgeTextId = EXCLUSIVE_ACCESS_TABS.filter((x) => x.id === currentMasterExclusiveAccess)[0].titleMessage;
    const { exclusives } = this.props;
    let cards = [];

    for (let i = 0; i < exclusives.length; i++) {
      const exclusiveAccess = exclusives[i];

      const statusObj = exclusiveAccessService.getExclusiveAccessStatus(
        exclusiveAccess,
        this.props.actions,
        this.props.fixedBenefits,
        this.props.additionalBenefits
      );

      if (currentMasterExclusiveAccess === exclusiveAccess.masterExclusiveAccess) {
        const wrapperClassName = classNames({
          "l-overlay-disabled": !this.props.showCurrentVersion && !this.props.isBeforeFirstPlanLaunch
        });

        const statusLabel = this.getStatusLabel(statusObj, exclusiveAccess);

        const card = {
          id: exclusiveAccess.id,
          title: exclusiveAccessService.getExclusiveAccessTitle(exclusiveAccess),
          subTitle: this.renderCardSubtitle(exclusiveAccess, statusObj),
          badge: getMessage(badgeTextId),
          className: exclusiveAccess.masterExclusiveAccess,
          wrapperClassName,
          statusLabel,
          deleteIcon: this.getDeleteIcon(statusObj),
          editIcon: this.getEditIcon(statusObj, exclusiveAccess.id),
          duplicateIcon: {
            link: this.getItemLinkUrl(exclusiveAccess.id, true),
            disabled: this.props.isAccountViewOnly
          },
          filters: {
            statusLabel: statusLabel.label
          }
        };
        cards.push(card);
      }
    }

    return cards;
  }

  getValidityValueElement(validity) {
    if (validity.accessValidityType === VALIDITY_TYPE_ENUM.UNLIMITED) {
      return getMessage("validity.unlimited");
    }

    if (validity.accessValidityType === VALIDITY_TYPE_ENUM.DATE_RANGE) {
      return (
        <Fragment>
          <Time value={validity.accessDateRange.start} onlyDate={true} /> -{" "}
          <Time value={validity.accessDateRange.end} onlyDate={true} />
        </Fragment>
      );
    }

    if (validity.accessValidityType === VALIDITY_TYPE_ENUM.LIMITED_FROM_EARN) {
      const duration = validity.accessValidity.duration;
      return getPluralCardinalMessage(`validity.limitedFromEarn.value.${validity.accessValidity.type}`, duration, {
        number: duration
      });
    }

    return null;
  }

  renderCardSubtitle(exclusiveAccess, statusObj) {
    if (statusObj.status === EXCLUSIVE_ACCESS_STATUS_ENUM.INACTIVE) {
      return "";
    }

    let elms = [];

    if (exclusiveAccessService.isLimitedExclusiveAccess(exclusiveAccess.masterExclusiveAccess)) {
      const { blockingValidity } = { ...exclusiveAccess.validity };
      elms.push(
        <Fragment>
          <Time value={blockingValidity.start} onlyDate={true} /> -{" "}
          <Time value={blockingValidity.end} onlyDate={true} />
        </Fragment>
      );
    } else {
      const { accessValidity } = { ...exclusiveAccess.validity };
      const fixedBenefitElm = this.getValidityValueElement(accessValidity.fixedBenefit);
      if (fixedBenefitElm) {
        const messageId = "exclusiveOverviewPage.card.info.accessAsFixedBenefit";
        elms.push(
          <div key={`subtitle_accessAsFixedBenefit_${exclusiveAccess.id}`}>
            {getMessage(messageId)}: <span>{fixedBenefitElm}</span>
          </div>
        );
      }
      const additionalBenefitsElm = this.getValidityValueElement(accessValidity.additionalBenefits);
      if (additionalBenefitsElm) {
        const messageId = "exclusiveOverviewPage.card.info.accessAsAdditionalBenefits";
        elms.push(
          <div key={`subtitle_accessAsAdditionalBenefits_${exclusiveAccess.id}`}>
            {getMessage(messageId)}: <span>{additionalBenefitsElm}</span>
          </div>
        );
      }
      const actionElm = this.getValidityValueElement(accessValidity.action);
      if (actionElm) {
        const messageId = "exclusiveOverviewPage.card.info.accessAsReward";
        elms.push(
          <div key={`subtitle_accessAsReward_${exclusiveAccess.id}`}>
            {getMessage(messageId)}: <span>{actionElm}</span>
          </div>
        );
      }
      const redemptionForPoints = this.getValidityValueElement(accessValidity.redemption);
      if (statusObj.redemptionOn && redemptionForPoints) {
        const messageId = "exclusiveOverviewPage.card.info.accessForPoints";
        elms.push(
          <div key={`subtitle_accessForPoints_${exclusiveAccess.id}`}>
            {getMessage(messageId)}: <span>{redemptionForPoints}</span>
          </div>
        );
      }
      const redemptionForFree = this.getValidityValueElement(accessValidity.redemptionForFree);
      if (statusObj.redemptionForFreeOn && redemptionForFree) {
        const messageId = "exclusiveOverviewPage.card.info.accessForFree";
        elms.push(
          <div key={`subtitle_accessForFree_${exclusiveAccess.id}`}>
            {getMessage(messageId)}: <span>{redemptionForFree}</span>
          </div>
        );
      }
    }

    const messageId = "exclusiveOverviewPage.card.info.offersAndValidity";
    return elms.length > 0 ? (
      <div>
        {getMessage(messageId)}: <br /> {elms}
      </div>
    ) : null;
  }

  onDeleteRewardClick(exclusiveAccessId) {
    const onDeleteExclusiveAccess = () => {
      this.props.deleteExclusiveAccess(exclusiveAccessId);
    };

    openDeleteDialog(
      getMessage("exclusiveOverviewPage.card.deleteDialog.headline"),
      getMessage("exclusiveOverviewPage.card.deleteDialog.content"),
      onDeleteExclusiveAccess
    );
  }

  getNavigationInfo() {
    const { isExclusiveAccessConfigEnabled } = { ...this.props };

    if (!isExclusiveAccessConfigEnabled) {
      tabs.forEach((tab) => {
        tab.disabled = true;
      });
    }

    return {
      navigationTabs: EXCLUSIVE_ACCESS_TABS,
      navigationTitle: getMessage("exclusiveOverviewPage.title")
    };
  }

  getFilters(cards) {
    let filters = [
      {
        label: getMessage("exclusiveOverviewPage.filters.status.label"),
        key: "statusLabel",
        options: uniqBy(cards, (card) => card.filters.statusLabel).map((card) => card.filters.statusLabel)
      }
    ];

    return filters;
  }

  render() {
    const { currentMasterExclusiveAccess } = { ...this.state };
    const newCardText = getMessage("exclusiveOverviewPage.card.newCardText", {
      value: exclusiveAccessService.getNewExclusiveAccessText(this.state.currentMasterExclusiveAccess)
    });

    const { navigationTabs, navigationTitle } = this.getNavigationInfo();
    const currentCards = this.getCurrentCards();

    return (
      <Navigation title={navigationTitle} tabsConfig={navigationTabs}>
        <div className="box-padding">
          <Notification
            content={getMessage(`exclusiveOverviewPage.notification.${currentMasterExclusiveAccess}`, {
              learnMoreLink: `<a target="_blank" href="${EXCLUSIVE_ACCESS_SETUP_LINK}">${getMessage(
                `exclusiveOverviewPage.notification.${currentMasterExclusiveAccess}.learnMoreLinkText`
              )}</a>`
            })}
          />
          <EditorTypePopup
            show={this.state.showDialog}
            addNewCardLink={this.getItemLinkUrl()}
            onClose={this.onPopupClose.bind(this)}
          />
          <div>
            <CardsContainer
              cards={currentCards}
              addNewCardText={newCardText}
              showDialog={this.onShowDialog.bind(this)}
              disableAddNewCard={this.props.isAccountViewOnly}
              filters={this.getFilters(currentCards)}
              searchPlaceholderText={getMessage("exclusiveOverviewPage.filters.search.label")}
            />
          </div>
        </div>
      </Navigation>
    );
  }
}

const mapStateToProps = (state) => {
  const showCurrentVersion = selectShowCurrentPlan(state);
  return {
    plan: selectPlan(state),
    exclusives: selectExclusives(state),
    actions: selectActions(state),
    fixedBenefits: selectFixedBenefits(state),
    additionalBenefits: selectAdditionalBenefits(state),
    showCurrentVersion: showCurrentVersion,
    isBeforeFirstPlanLaunch: selectIsBeforeFirstPlanLaunch(state),
    isExclusiveAccessConfigEnabled: true,
    isAccountViewOnly: isAccountViewOnly(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteExclusiveAccess: (id) => {
      dispatch(deleteExclusiveAccess(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExclusiveAccessOverview);
