import * as TYPES from "../actions/TYPES";
import { isSpecificFeatureEnabled, isDisableFeature } from "../helpers/envHelper";
import { CONFIG_OBJ_ENUM } from "../constants/configConstants";
import sessionHelper from "../helpers/sessionHelper";

const initialState = {
  customer: {},
  featuresConfig: {},
  loading: false,
  requested: false,
  emsConfig: {},
  emsAccountPermissions: {},
  customerLoaded: false,
  emsConfigLoaded: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.GET_CUSTOMER_REQUEST:
    case TYPES.UPDATE_ACCOUNT_SETTINGS_PLANS_REQUEST:
    case TYPES.GET_EMS_CONFIG_REQUEST:
    case TYPES.GET_EMS_ACCOUNT_PERMISSIONS_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case TYPES.GET_CUSTOMER_SUCCESS: {
      const customer = action.payload;
      sessionHelper.setCustomerId(customer.id);
      let planId = sessionHelper.getPlanId();
      if (!planId) {
        planId = !!customer.plans ? customer.plans[0] : undefined;
        if (planId) {
          sessionHelper.setPlanId(planId);
        }
      }

      let featuresConfig;
      if (!!customer.config) {
        const { enableMemberGroup, enableReferralAction, disablePointSplit } = customer.config;

        featuresConfig = {
          [CONFIG_OBJ_ENUM.ENABLE_MEMBER_GROUP]: enableMemberGroup,
          [CONFIG_OBJ_ENUM.DISABLE_POINT_SPLIT]: disablePointSplit
        };
      } else {
        featuresConfig = {};
      }
      return {
        ...state,
        featuresConfig,
        customer: customer,
        loading: false,
        customerLoaded: true
      };
    }

    case TYPES.GET_EMS_CONFIG_SUCCESS: {
      return {
        ...state,
        emsConfig: action.payload,
        loading: false,
        emsConfigLoaded: true
      };
    }

    case TYPES.GET_EMS_ACCOUNT_PERMISSIONS_SUCCESS: {
      return { ...state, emsAccountPermissions: action.payload, loading: false };
    }

    case TYPES.GET_CUSTOMER_FAILURE:
    case TYPES.GET_EMS_CONFIG_FAILURE:
    case TYPES.GET_EMS_ACCOUNT_PERMISSIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        customerLoaded: true
      };
    }
    default:
      return state;
  }
}

export const selectCustomerId = (state) => {
  return state.customer.id;
};

export const selectCustomerPlans = (state) => {
  return state.customer.plans;
};

export const isLoading = (state) => {
  return state.loading;
};

export const isCustomerLoaded = (state) => {
  return state.customerLoaded;
};

export const isEmsConfigLoaded = (state) => state.emsConfigLoaded;

export const selectCustomerFeaturesConfig = (state) => state.featuresConfig || {};

export const selectDashboardUrl = (state) => {
  return !!state.customer.config && state.customer.config.dashboardUrl;
};

export const isAccountViewOnly = (state) => !state.emsAccountPermissions.edit;

export const isAccountViewOnlySupport = (state) =>
  !state.emsAccountPermissions.edit && !state.emsAccountPermissions.editSupport;

export const selectIsDoubleHashSet = (state) => !!state.customer.isDoubleHashSet;

export const selectEmsAdminId = (state) => state.emsConfig.adminId;

export const selectEmsAdminTimezone = (state) => state.emsConfig.timezone;
