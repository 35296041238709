import * as TYPES from "../actions/TYPES";

const initialState = {
  jwtToken: "",
  loading: false,
  dataLoaded: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.GET_JWT_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case TYPES.GET_JWT_SUCCESS:
    case TYPES.GET_JWT_FAILED: {
      return {
        ...state,
        jwtToken: action.payload,
        loading: false,
        dataLoaded: true
      };
    }
    case TYPES.INVALIDATE_JWT: {
      return {
        ...state,
        jwtToken: ""
      };
    }
    default:
      return state;
  }
}

export const GetJwtToken = (state) => {
  return state.jwtToken;
};

export const isDataLoaded = (state) => {
  return state.dataLoaded;
};

export const isLoading = (state) => {
  return state.loading;
};
