import React, { Fragment } from "react";
import PropTypes from "prop-types";

import InputField from "../../components/InputField";
import TextareaField from "../../components/TextareaField";

import { getMultiLangFieldLabel } from "../../helpers/multiLangHelper";
import { getMessage } from "../../messages";

const MultiLangActionContentFields = ({
  onChange,
  lang,
  translations,
  propPrefix,
  rewardToken,
  primaryBlockMessage,
  disabled,
  disableCtaButton
}) => {
  const onBlockMessageInputChange = (event, value) => {
    onChange(event, lang, value);
  };

  const tokenObj = primaryBlockMessage.indexOf(rewardToken.token) > -1 ? rewardToken : null;
  if (!translations[`${propPrefix}.blockMessageML`][lang] && !!tokenObj) {
    translations[`${propPrefix}.blockMessageML`][lang] = tokenObj.token;
  }

  return (
    <Fragment>
      <div className="e-col-12">
        {/* Block Message */}
        <TextareaField
          label={getMultiLangFieldLabel({ label: getMessage("message"), lang })}
          name={`${propPrefix}.blockMessageML`}
          value={translations[`${propPrefix}.blockMessageML`][lang] || ""}
          onChange={onBlockMessageInputChange}
          tokenObj={tokenObj}
          height="70px"
          disabled={disabled}
        />
      </div>
      <div className="e-col-6">
        {/* CTA Button */}
        <InputField
          label={getMultiLangFieldLabel({ label: getMessage("ctaText"), lang })}
          type="text"
          name={`${propPrefix}.ctaButtonML`}
          value={translations[`${propPrefix}.ctaButtonML`][lang] || ""}
          maxLength="255"
          onChange={(event) => onChange(event, lang)}
          disabled={disabled || disableCtaButton}
        />
      </div>
      <div className="e-col-6">
        <InputField
          label={getMultiLangFieldLabel({ label: getMessage("ctaUrl"), lang })}
          type="text"
          name={`${propPrefix}.ctaUrlML`}
          value={translations[`${propPrefix}.ctaUrlML`][lang] || "http://"}
          maxLength="255"
          onChange={(event) => onChange(event, lang)}
          disabled={disabled}
        />
      </div>
    </Fragment>
  );
};

MultiLangActionContentFields.propTypes = {
  onChange: PropTypes.func,
  lang: PropTypes.string,
  translations: PropTypes.object,
  propPrefix: PropTypes.string,
  rewardToken: PropTypes.string,
  primaryBlockMessage: PropTypes.string,
  disabled: PropTypes.bool,
  disableCtaButton: PropTypes.bool
};

export default MultiLangActionContentFields;
