import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class PopupContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.openOnLoad) {
      this.openPopup();
    }

    const { onClose } = this.props;
    if (onClose) {
      this.dialogElm.addEventListener("dialog.close", onClose);
    }
  }

  componentWillUnmount() {
    this.closePopup();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.show === this.props.show) {
      return;
    }

    this.props.show ? this.openPopup() : this.closePopup();
  }

  openPopup() {
    document.querySelector(`#${this.props.id}`).open();
  }

  closePopup() {
    document.querySelector(`#${this.props.id}`).close();
  }

  renderFooter() {
    const { buttons, footerElement } = this.props;

    if (!buttons) {
      return null;
    }
    return (
      <div className="e-dialog__footer">
        <div className="e-grid e-grid-small">
          {buttons.map((button, index) => {
            let attr = {};
            if (button.isClose) {
              attr["data-action"] = "close";
            }

            if (button.onClick) {
              attr["onClick"] = button.onClick;
            }

            if (button.disabled) {
              attr["disabled"] = true;
            }

            const buttonclassNames = classNames("e-btn", button.className);
            return (
              <div key={`popup${this.props.id}button${index}`} className="e-cell e-cell-small">
                <button className={buttonclassNames} {...attr}>
                  {button.text}
                </button>
              </div>
            );
          })}
        </div>
        <div>{footerElement}</div>
      </div>
    );
  }

  renderCustomHeader() {
    if (!this.props.customHeader) {
      return null;
    }

    return <e-dialog-header>{this.props.customHeader}</e-dialog-header>;
  }
  render() {
    return (
      <e-dialog
        id={this.props.id}
        no-close={(this.props.noClose || false).toString()}
        width={this.props.width}
        height={this.props.height}
        headline={this.props.title}
        ref={(elem) => (this.dialogElm = elem)}
        class={this.props.className}
      >
        {this.renderCustomHeader()}
        <div>{this.props.children}</div>
        {this.renderFooter()}
      </e-dialog>
    );
  }
}

PopupContainer.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
  noClose: PropTypes.bool,
  openOnLoad: PropTypes.bool,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      className: PropTypes.string,
      isClose: PropTypes.bool
    })
  ),
  customHeader: PropTypes.element,
  className: PropTypes.string,
  footerElement: PropTypes.object
};

export default PopupContainer;
