import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import pluralize from "pluralize";
import { uniqBy } from "lodash";

import CardsContainer from "../components/CardsContainer";
import Notification from "../components/Notification";
import Navigation from "../components/Navigation";

import {
  selectRewards,
  selectActions,
  selectFixedBenefits,
  selectIsBeforeFirstPlanLaunch,
  selectShowCurrentPlan,
  selectAdditionalBenefits,
  selectPlan,
  selectTiers,
  isAccountViewOnly
} from "../reducers";

import { deleteReward } from "../actions/rewardActions";

import { openDeleteDialog } from "../helpers/dialogHelper";
import { getMessage } from "../messages";

import {
  MASTER_REWARDS,
  REWARD_STATUS_ENUM,
  VALIDITY_TYPE_ENUM,
  MASTER_REWARD_ENUM
} from "../constants/rewardConstants";

import rewardService from "../services/rewardService";
import planService from "../services/planService";

import { REWARDS_TAB_ENUM, REWARDS_VOUCHER_TABS } from "../constants/navigationConstants";
import Time from "../components/Time";
import { getConnectedTooltipText, getRewardStatusLabel } from "../helpers/statusHelper";
import {
  VOUCHERS_AS_CASHBACK_SETUP_LINK,
  VOUCHERS_FOR_FREE_SETUP_LINK,
  VOUCHERS_FOR_POINTS_SETUP_LINK,
  VOUCHERS_FOR_REWARD_SETUP_LINK
} from "../constants/linkConstants";

class Rewards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMasterReward: props.match.params.masterReward || MASTER_REWARD_ENUM.VOUCHERS_FOR_REWARDS,
      showDialog: false
    };
  }

  onShowDialog() {
    this.setState({
      showDialog: true
    });
  }

  onPopupClose() {
    this.setState({
      showDialog: false
    });
  }

  getItemLinkUrl(id, isDup) {
    if (!id) {
      return `/reward-new/${this.state.currentMasterReward}`;
    } else if (isDup) {
      return `/reward-new/${this.state.currentMasterReward}/${id}`;
    } else {
      return `/reward/${id}`;
    }
  }

  getDeleteIcon(statusObj) {
    let disabled = false;
    let tooltip = "";

    if (statusObj.status === REWARD_STATUS_ENUM.ACTIVE) {
      disabled = true;
    } else {
      const connectedText = getConnectedTooltipText(statusObj);
      const connectedToText = getMessage("voucherOverviewPage.card.connectedTo");
      if (!!connectedText) {
        disabled = true;
        tooltip = `<b>${connectedToText}:</b> <br/>${connectedText}`;
      }
    }

    if (this.props.isAccountViewOnly) {
      disabled = true;
    }

    return {
      onClick: this.onDeleteRewardClick.bind(this),
      disabled,
      tooltip
    };
  }

  getEditIcon(statusObj, rewardId) {
    return {
      link: this.getItemLinkUrl(rewardId, false)
    };
  }

  getReportsIcon(reward) {
    // let tooltip = getMessage("actions.reportsIcon.tooltip");
    return {
      link: `/reward-dashboard/${reward.id}`,
      tooltip: "",
      icon: "bar-chart"
    };

    return null;
  }

  getCurrentCardRewards() {
    const currentMasterReward = this.state.currentMasterReward;
    const badgeText = getMessage(MASTER_REWARDS[currentMasterReward].badgeMessage);
    const { rewards } = this.props;
    let cards = [];

    for (let i = 0; i < rewards.length; i++) {
      const reward = rewards[i];

      const statusObj = rewardService.getRewardStatus(
        reward,
        this.props.actions,
        this.props.fixedBenefits,
        this.props.additionalBenefits,
        this.props.tiers
      );

      const statusLabel = getRewardStatusLabel(statusObj, reward);
      const extraLabels = rewardService.getCardExtraLabels(reward);

      if (MASTER_REWARDS[currentMasterReward].masterRewardsOfSection.includes(reward.masterReward)) {
        const wrapperClassName = classNames({
          "l-overlay-disabled": !this.props.showCurrentVersion && !this.props.isBeforeFirstPlanLaunch
        });

        const card = {
          id: reward.id,
          title: rewardService.getRewardTitle(reward),
          subTitle: this.renderVoucherCardSubtitle(reward),
          badge: badgeText,
          className: reward.masterReward,
          wrapperClassName,
          statusLabel,
          extraLabels,
          deleteIcon: this.getDeleteIcon(statusObj),
          editIcon: this.getEditIcon(statusObj, reward.id),
          topRightIcon: this.getReportsIcon(reward),
          filters: {
            statusLabel: statusLabel.label,
            voucherType: reward.voucherType
          }
        };
        cards.push(card);
      }
    }

    return cards;
  }

  getValidityValueText(reward) {
    let validityTypeName = "voucherValidityType";
    let validityName = "voucherValidity";
    let expiryDateName = "voucherExpiryDate";
    let validityObj = reward.validity;

    if (!validityObj || !validityObj[validityTypeName]) {
      return null;
    }

    switch (validityObj[validityTypeName]) {
      case VALIDITY_TYPE_ENUM.LIMITED_FROM_EARN:
        return pluralize(validityObj[validityName].type, validityObj[validityName].duration, true);
      case VALIDITY_TYPE_ENUM.FIXED_DATE:
        return (
          <span>
            <Time value={validityObj[expiryDateName]} onlyDate={true} />
          </span>
        );
      case VALIDITY_TYPE_ENUM.UNLIMITED:
        return getMessage("voucherOverviewPage.card.subTitle.voucherValidity.unlimited");
      default:
        return null;
    }
  }

  renderVoucherCardSubtitle(reward) {
    const couponsText =
      reward.pool && Array.isArray(reward.pool.uploads) && reward.pool.uploads.length > 0 ? (
        <div>
          {reward.pool.availableCodes}{" "}
          {getMessage("voucherOverviewPage.card.subTitle.availableCoupons", {
            text: pluralize(
              getMessage("voucherOverviewPage.card.subTitle.voucherSingle"),
              reward.pool.availableCodes,
              false
            )
          })}
        </div>
      ) : (
        ""
      );

    let poolExpText = "";
    let validityText = "";
    if (reward.validity && reward.validity.deactivationDate) {
      poolExpText = (
        <div>
          <span>{getMessage("voucherOverviewPage.card.subTitle.poolExpration")} </span>{" "}
          <Time value={reward.validity.deactivationDate} onlyDate={true} />
        </div>
      );
      validityText = (
        <div>
          {" "}
          {getMessage("voucherOverviewPage.card.subTitle.voucherValidity")} {this.getValidityValueText(reward)}
        </div>
      );
    }

    return (
      <div>
        {couponsText} {poolExpText} {validityText}
      </div>
    );
  }

  onDeleteRewardClick(rewardId) {
    const onDeleteReward = () => {
      this.props.deleteReward(rewardId);
    };

    openDeleteDialog(
      getMessage("voucherOverviewPage.card.deleteDialog.headline"),
      getMessage("voucherOverviewPage.card.deleteDialog.content"),
      onDeleteReward
    );
  }

  getNavigationInfo() {
    const { plan } = { ...this.props };
    let tabs = REWARDS_VOUCHER_TABS;
    let title = getMessage("voucherOverviewPage.title");

    tabs = tabs.filter((tab) => {
      if (!planService.hasRedemption(plan) && tab.id === REWARDS_TAB_ENUM.VOUCHERS_FOR_POINTS) {
        return false;
      }
      if (
        (!planService.isSpendPlan(plan) || !planService.isCashbackEnabled(plan)) &&
        tab.id === REWARDS_TAB_ENUM.VOUCHERS_AS_CASHBACK
      ) {
        return false;
      }
      return true;
    });

    return {
      navigationTabs: tabs,
      navigationTitle: title
    };
  }

  getNotificationContent() {
    const getLink = (messageId, link) => {
      return `<a target="_blank" href="${link}">${getMessage(messageId)}</a>`;
    };

    let link;
    switch (this.state.currentMasterReward) {
      case MASTER_REWARD_ENUM.VOUCHERS_FOR_REWARDS: {
        link = VOUCHERS_FOR_REWARD_SETUP_LINK;
        break;
      }
      case MASTER_REWARD_ENUM.VOUCHERS_FOR_POINTS: {
        link = VOUCHERS_FOR_POINTS_SETUP_LINK;
        break;
      }
      case MASTER_REWARD_ENUM.VOUCHERS_FOR_FREE: {
        link = VOUCHERS_FOR_FREE_SETUP_LINK;
        break;
      }
      case MASTER_REWARD_ENUM.VOUCHERS_AS_CASHBACK: {
        link = VOUCHERS_AS_CASHBACK_SETUP_LINK;
        break;
      }
    }

    return getMessage(`voucherOverviewPage.notification.${this.state.currentMasterReward}`, {
      learnMoreLink: getLink(
        `voucherOverviewPage.notification.${this.state.currentMasterReward}.learnMoreLinkText`,
        link
      )
    });
  }

  getFilters(cards) {
    let filters = [
      {
        label: getMessage("voucherOverviewPage.filters.status.label"),
        key: "statusLabel",
        options: uniqBy(cards, (card) => card.filters.statusLabel).map((card) => card.filters.statusLabel)
      }
    ];

    return filters;
  }

  hideAddNewCard() {
    return (
      this.props.rewards.filter((reward) => rewardService.isCashback(reward.masterReward)).length > 0 &&
      planService.isSpendPlan(this.props.plan) &&
      rewardService.isCashback(this.state.currentMasterReward)
    );
  }

  render() {
    const newCardText = rewardService.getNewRewardText(this.state.currentMasterReward);
    const { navigationTabs, navigationTitle } = this.getNavigationInfo();
    const currentCards = this.getCurrentCardRewards();

    return (
      <Navigation title={navigationTitle} tabsConfig={navigationTabs}>
        <div className="box-padding">
          <Notification content={this.getNotificationContent()} />
          <div>
            <CardsContainer
              cards={this.getCurrentCardRewards()}
              addNewCardLink={this.getItemLinkUrl()}
              addNewCardText={newCardText}
              hideAddNewCard={this.hideAddNewCard()}
              disableAddNewCard={this.props.isAccountViewOnly}
              filters={this.getFilters(currentCards)}
              searchPlaceholderText={getMessage("voucherOverviewPage.filters.search.label")}
            />
          </div>
        </div>
      </Navigation>
    );
  }
}

const mapStateToProps = (state) => {
  const showCurrentVersion = selectShowCurrentPlan(state);
  return {
    plan: selectPlan(state),
    rewards: selectRewards(state),
    actions: selectActions(state),
    fixedBenefits: selectFixedBenefits(state),
    additionalBenefits: selectAdditionalBenefits(state),
    tiers: selectTiers(state),
    showCurrentVersion: showCurrentVersion,
    isBeforeFirstPlanLaunch: selectIsBeforeFirstPlanLaunch(state),
    isAccountViewOnly: isAccountViewOnly(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteReward: (id) => {
      dispatch(deleteReward(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rewards);
