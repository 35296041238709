import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { formatIsoTime } from "../helpers/timeHelper";

const Time = ({ value, onlyDate, showUtc }) => {
  if (!value) {
    return null;
  }

  let attr = {};
  if (onlyDate) {
    attr["format"] = "date";
  }

  return <e-time datetime={formatIsoTime(value)} {...attr} />;
};

Time.propTypes = {
  value: PropTypes.oneOfType([PropTypes.time, PropTypes.string, PropTypes.number]),
  onlyDate: PropTypes.bool
};

export default Time;
