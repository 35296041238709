import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { DateRangeField } from "./DateFields";
import SelectField from "./SelectField";

import { VIEW_RANGE_OPTIONS } from "../constants/dashboardConstants";
import { isDemoMode } from "../helpers/envHelper";
import { formatInputDate } from "../views/Dashboard/dashboardHelper";
import { getMessage } from "../messages";

const DashboardOptions = ({
  dateRange,
  dateAgg,
  onDatesChange,
  onDateAggChange,
  startedAtElement,
  onDownloadCsv,
  csvDownloadLoading,
  csvDownloadButtonText,
  csvDownloadButtonTooltip
}) => {
  const onDateRangeChange = (event, dates) => {
    onDatesChange({
      start: formatInputDate(dates.start),
      end: formatInputDate(dates.end)
    });
  };

  const onViewOptionChange = (event) => {
    onDateAggChange(event.target.value);
  };

  const CsvDownloadButton = () => {
    return (
      <button className="e-btn" onClick={onDownloadCsv} disabled={csvDownloadLoading}>
        <div className={classNames("e-btn__loading", { "e-btn__loading-active": csvDownloadLoading })}>
          <e-spinner data-size="small"></e-spinner>
          <e-icon icon="e-download"></e-icon>
        </div>
        {csvDownloadButtonText}
      </button>
    );
  };

  return (
    <Fragment>
      <div className="l-dashboard">
        <div className="e-grid e-grid-between e-grid-baseline">
          <div className="e-cell-7">
            <div className="e-grid e-grid-between e-grid-baseline">
              <div className="e-cell">
                <span>{startedAtElement}</span>
              </div>
              <div className="e-cell">
                <div className="e-grid e-grid-between e-grid-baseline">
                  <div className="e-cell e-cell-small">{getMessage("dashboardOptions.currentlyShowingLabel")}</div>
                  <div className="e-cell">
                    <DateRangeField
                      maxDate={new Date()}
                      value={dateRange}
                      name="reportDateRange"
                      onChange={onDateRangeChange}
                      noClear={true}
                      disabled={isDemoMode()}
                      updateOnClose={true}
                    />
                  </div>
                </div>
              </div>
              <div className="e-cell">
                <div className="e-grid e-grid-vertical_center e-grid-between">
                  <div className="e-cell">{getMessage("dashboardOptions.viewLabel")}</div>
                  <SelectField
                    options={VIEW_RANGE_OPTIONS}
                    value={dateAgg}
                    onChange={onViewOptionChange}
                    extraClassName="e-cell aligned"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="e-cell">
            {csvDownloadButtonTooltip ? (
              <e-tooltip content={csvDownloadButtonTooltip} block>
                <CsvDownloadButton />
              </e-tooltip>
            ) : (
              <CsvDownloadButton />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

DashboardOptions.propTypes = {
  onDatesChange: PropTypes.func.isRequired,
  onDateAggChange: PropTypes.func.isRequired,
  dateRange: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string
  }),
  dateAgg: PropTypes.string,
  onDownloadCsv: PropTypes.func,
  csvDownloadLoading: PropTypes.bool,
  csvDownloadButtonText: PropTypes.string,
  csvDownloadButtonTooltip: PropTypes.string,
  startedAtElement: PropTypes.array
};

export default DashboardOptions;
