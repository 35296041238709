import React, { Fragment } from "react";
import PropTypes from "prop-types";

import InputField from "../../components/InputField";

import { getMultiLangFieldLabel } from "../../helpers/multiLangHelper";
import { getErrorMessage } from "../../validators";

const MultiLangExactAccessUrlField = ({ onChange, lang, translations, errors, disabled }) => {
  return (
    <Fragment>
      <InputField
        label={getMultiLangFieldLabel({ label: "Give exclusive access only to this page", lang })}
        type="text"
        name="blocking.exactAccessUrlML"
        value={translations["blocking.exactAccessUrlML"][lang] || "http://"}
        onChange={(event) => onChange(event, lang)}
        errorMsg={getErrorMessage(errors, `blocking\\.exactAccessUrlML.${lang}`)}
        disabled={disabled}
      />
    </Fragment>
  );
};

MultiLangExactAccessUrlField.propTypes = {
  onChange: PropTypes.func,
  lang: PropTypes.string,
  translations: PropTypes.object
};

export default MultiLangExactAccessUrlField;
