import React, { Fragment } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";

import SelectField from "../components/SelectFieldV2";

import { openDestructiveDialog } from "../helpers/dialogHelper";

import { rewardType } from "../types";
import { getMessage } from "../messages";

const ExclusiveAccessValidity = ({ exclusiveAccess, exclusiveAccessActivationOptions, onInputChange, isViewOnly }) => {
  const onDeactivateClick = (event, currentValue) => {
    //if switching from deactivate to activate don't show popup
    if (currentValue !== true) {
      onInputChange(event);
      return;
    }

    //event.persist();
    const onConfirm = () => {
      onInputChange(event, false);
    };

    openDestructiveDialog({
      headline: getMessage("exclusiveAccessValidity.deactivateDialog.headline"),
      content: getMessage("exclusiveAccessValidity.deactivateDialog.content"),
      confirmLabel: getMessage("exclusiveAccessValidity.deactivateDialog.confirmLabel"),
      confirmFunc: onConfirm
    });

    return false;
  };

  return (
    <div className="e-box">
      <div className="e-row">
        <div className="e-col-12">
          <h2>{getMessage("exclusiveAccessValidity.title")}</h2>
          <br />
          <div className="e-notice">{getMessage("exclusiveAccessValidity.notice")}</div>
        </div>
      </div>
      <div className="e-row">
        <div className="e-col-6">
          <SelectField
            label=""
            name="validity.isActive"
            type="bool"
            value={exclusiveAccess.validity.isActive}
            disabled={isViewOnly}
            onChange={(event) => onDeactivateClick(event, exclusiveAccess.validity.isActive)}
            options={exclusiveAccessActivationOptions}
          />
        </div>
      </div>
    </div>
  );
};

ExclusiveAccessValidity.propTypes = {
  exclusiveAccess: rewardType.isRequired,
  viewMode: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  exclusiveAccessActivationOptions: PropTypes.array.isRequired,
  isViewOnly: PropTypes.bool
};

export default ExclusiveAccessValidity;
