import * as TYPES from "../actions/TYPES";
import find from "lodash/find";
import sessionHelper from "../helpers/sessionHelper";
import tierService from "../services/tierService";

const initialState = {
  currentPlanVersion: {},
  showCurrentVersion:
    sessionHelper.getShowCurrentVersion() === null ? true : sessionHelper.getShowCurrentVersion() === "true",
  loading: false,
  dataLoaded: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.UPDATE_CURRENT_PLAN_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case TYPES.GET_CURRENT_PLAN_REQUEST: {
      return {
        ...state,
        loading: true,
        dataLoaded: false
      };
    }

    case TYPES.GET_CURRENT_PLAN_SUCCESS: {
      const currentPlanVersion = action.payload.currentPlanVersion;
      return {
        ...state,
        currentPlanVersion,
        loading: false,
        dataLoaded: true
      };
    }

    case TYPES.LAUNCH_PLAN_SUCCESS: {
      const currentPlanVersion = action.payload.currentPlanVersion;
      return {
        ...state,
        currentPlanVersion
      };
    }

    case TYPES.UPDATE_CURRENT_PLAN_SUCCESS: {
      const currentPlanVersion = action.payload.data;
      return {
        ...state,
        currentPlanVersion,
        loading: false
      };
    }
    case TYPES.GET_CUSTOMER_FAILURE:
    case TYPES.UPDATE_CURRENT_PLAN_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    case TYPES.CHANGE_PLAN_EDIT_MODE: {
      const { showCurrentVersion } = action.payload;
      return {
        ...state,
        showCurrentVersion
      };
    }

    case TYPES.SET_PLAN_LAUNCH_IN_PROGRESS: {
      const { isInProgress } = action.payload;
      const { currentPlanVersion } = { ...state };

      currentPlanVersion.isLaunchInProgress = isInProgress;

      return {
        ...state,
        currentPlanVersion
      };
    }

    default:
      return state;
  }
}

export const isDataLoaded = (state) => {
  return state.dataLoaded;
};

export const selectCurrentPlanVersion = (state) => {
  return { ...state.currentPlanVersion };
};

export const selectCurrentVersionSettings = (state) => {
  return { ...state.currentPlanVersion.planSettings };
};

export const selectCurrentVersionTierById = (state, id) => {
  const { tiers } = state.currentPlanVersion;
  return find(tiers, { id: id });
};

export const selectIsCurrentPlanVersionExists = (state) => {
  return !!state.currentPlanVersion;
};

export const selectTierExistsInCurrentVersion = (state) => (id) => {
  if (!state.currentPlanVersion) {
    return false;
  }

  const { tiers } = state.currentPlanVersion;
  return !!find(tiers, { id: id });
};

export const selectIsPlanLaunchInProgress = (state) => {
  return !!state.currentPlanVersion && state.currentPlanVersion.isLaunchInProgress;
};

export const selectCurrentPlanVersionId = (state) => {
  return !!state.currentPlanVersion && state.currentPlanVersion.versionId;
};

export const selectTiersMapById = (state) => {
  if (!state.currentPlanVersion) {
    return {};
  }
  const tiers = state.currentPlanVersion.tiers;
  let mapTiers = {};

  tierService.sortTiers(tiers).forEach((tier) => {
    mapTiers[tier.id] = tier;
  });

  return mapTiers;
};

export const selectShowCurrentVersion = (state) => {
  //return state.showCurrentVersion;
  return sessionHelper.getShowCurrentVersion() === null ? true : sessionHelper.getShowCurrentVersion() === "true";
};

export const selectPointsNames = (state) => {
  const settings = state.currentPlanVersion.planSettings;
  return {
    pointsName: settings.pointsName,
    pointsNameML: settings.pointsNameML,
    singlePointName: settings.singlePointName,
    singlePointNameML: settings.singlePointNameML
  };
};
