import React from "react";
import PropTypes from "prop-types";

import "../assets/styles/EmptyState.scss";

const EmptyState = (props) => {
  return (
    <div className="e-emptystate">
      <div className="no-results-logo">
        <e-icon className="e-emptystate__icon" icon={props.icon} size="middle" color="inherit" />
      </div>
      <div className="e-emptystate__content m-t-15">
        <div className="e-emptystate__title">{props.title}</div>
        <div className="e-emptystate__lead">{props.text}</div>
      </div>
    </div>
  );
};

EmptyState.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string
};

export default EmptyState;
