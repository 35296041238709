import { useState } from "react";

const usePagination = (initialItemsPerPage) => {
  const [allItems, setItemList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);

  //get item list after pagination
  const getItemList = () => {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return allItems.slice(begin, end);
  };

  return {
    setItemList,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    setItemsPerPage,
    getItemList,
    totalItems: allItems.length
  };
};

export default usePagination;
