import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import SelectField from "./SelectFieldV2";
import Overlay from "./Overlay";

import { getAppPlanData } from "../actions";
import { getPlan } from "../actions/planActions";

import { selectCustomerPlans } from "../reducers";

import sessionHelper from "../helpers/sessionHelper";
import { getMessage } from "../messages";

class MultiPlanSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      planId: sessionHelper.getPlanId(),
      loading: false
    };
  }

  onChange(event) {
    const planId = event.target.value;
    sessionHelper.setPlanId(planId);
    this.setState({ planId });

    this.props.getPlan();
    this.props.getAppPlanData();
  }

  render() {
    const { planOptions } = this.props;
    if (!planOptions || planOptions.length < 2) {
      return null;
    }
    return (
      <Fragment>
        <label className="m-r-5">{getMessage("multiPlanSelect.label")}</label>
        <SelectField
          options={this.props.planOptions}
          value={this.state.planId}
          onChange={this.onChange.bind(this)}
          extraClassName="no-margin"
        />
        <Overlay loading={this.state.loading} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  planOptions: selectCustomerPlans(state)
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAppPlanData: () => {
      dispatch(getAppPlanData());
    },
    getPlan: () => {
      dispatch(getPlan());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiPlanSelect);
