import { getPlan } from "./planActions";
import { getAllTiers } from "./tierActions";
import { getAllActions } from "./actionActions";
import { getAllRewards } from "./rewardActions";
import { getFixedBenefits } from "./benefitActions";
import { getAdditionalBenefits } from "./benefitActions";
import { getAllExternalEvents } from "./externalEventsActions";
import { getCurrentPlanVersion } from "./currentPlanVersionActions";
import { getAllExclusives } from "./exclusiveAccessActions";

export const getAppPlanData = () => {
  return function (dispatch) {
    dispatch(getAllTiers());
    dispatch(getFixedBenefits());
    dispatch(getAdditionalBenefits());
    dispatch(getAllExternalEvents());
    dispatch(getCurrentPlanVersion());
    dispatch(getAllActions());
    dispatch(getAllRewards());
    dispatch(getAllExclusives());
  };
};
