import * as TYPES from "./TYPES";
import externalEventsApi from "../api/externalEventsApi";
import { invalidateJwt } from "./sessionActions";

export const getAllExternalEvents = () => {
  return (dispatch) => {
    dispatch({
      type: TYPES.GET_ALL_EXTERNAL_EVENTS_REQUEST
    });
    return externalEventsApi
      .getAllExternalEvents()
      .then((data) => {
        dispatch({
          type: TYPES.GET_ALL_EXTERNAL_EVENTS_SUCCESS,
          payload: data
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.GET_ALL_EXTERNAL_EVENTS_FAILURE
        });
      });
  };
};

export const updateExternalEvent = (externalEvent, id) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.POST_EXTERNAL_EVENTS_REQUEST
    });

    const isNew = !id;
    const setMethod = isNew ? externalEventsApi.createExternalEvent : externalEventsApi.updateExternalEvent;

    return setMethod(externalEvent, id)
      .then((data) => {
        dispatch({
          type: TYPES.POST_EXTERNAL_EVENTS_SUCCESS,
          payload: data
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.POST_EXTERNAL_EVENTS_FAILURE
        });
      });
  };
};

export const deleteExternalEvent = (id) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.DELETE_EXTERNAL_EVENTS_REQUEST
    });

    return externalEventsApi
      .deleteExternalEvent(id)
      .then(() => {
        dispatch({
          type: TYPES.DELETE_EXTERNAL_EVENTS_SUCCESS,
          payload: {
            id
          }
        });
        dispatch(getAllExternalEvents());
        // dispatch(getCustomer());
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.DELETE_EXTERNAL_EVENTS_FAILURE
        });
      });
  };
};
