import React from "react";
import PropTypes from "prop-types";
import { getMessage } from "../../messages";

const WidgetHelpLink = ({ linkUrl }) => {
  return (
    <a className="e-btn e-btn-borderless e-widget__footer_action" target={"_blank"} href={linkUrl}>
      <e-icon icon="e-helperlink"></e-icon>
      {getMessage("widgetHelpLink.tooltip")}
    </a>
  );
};

WidgetHelpLink.PropTypes = {
  linkUrl: PropTypes.string
};

export default WidgetHelpLink;
