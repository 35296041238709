import React from "react";
import PropTypes from "prop-types";

import InputField from "../../components/InputField";

import { getMultiLangFieldLabel } from "../../helpers/multiLangHelper";
import { getMessage } from "../../messages";

const MultiLangAccessNameField = ({ onChange, lang, translations, disabled }) => {
  return (
    <InputField
      label={getMultiLangFieldLabel({ label: getMessage("exclusiveAccessSetupSettings.accessName.label"), lang })}
      value={translations.accessNameML[lang]}
      name={"accessNameML"}
      onChange={(event) => onChange(event, lang)}
      disabled={disabled}
    />
  );
};

MultiLangAccessNameField.propTypes = {
  onChange: PropTypes.func,
  lang: PropTypes.string,
  translations: PropTypes.object,
  disabled: PropTypes.bool
};

export default MultiLangAccessNameField;
