import * as TYPES from "../actions/TYPES";

const initialState = {
  fixedBenefits: [],
  additionalBenefits: [],
  loading: false,
  dataLoaded: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.UPDATE_FIXED_BENEFITS_REQUEST:
    case TYPES.UPDATE_ADDITIONAL_BENEFITS_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case TYPES.GET_FIXED_BENEFITS_REQUEST:
    case TYPES.GET_ADDITIONAL_BENEFITS_REQUEST: {
      return {
        ...state,
        loading: true,
        dataLoaded: false
      };
    }

    case TYPES.GET_FIXED_BENEFITS_SUCCESS: {
      const fixedBenefits = action.payload.data;
      return {
        ...state,
        fixedBenefits,
        loading: false,
        dataLoaded: true
      };
    }

    case TYPES.GET_ADDITIONAL_BENEFITS_SUCCESS: {
      const additionalBenefits = action.payload.data;
      return {
        ...state,
        additionalBenefits,
        loading: false,
        dataLoaded: true
      };
    }

    case TYPES.UPDATE_FIXED_BENEFITS_SUCCESS: {
      const fixedBenefits = action.payload.data;
      return {
        ...state,
        fixedBenefits,
        loading: false
      };
    }

    case TYPES.UPDATE_ADDITIONAL_BENEFITS_SUCCESS: {
      const additionalBenefits = action.payload.data;
      return {
        ...state,
        additionalBenefits,
        loading: false
      };
    }

    case TYPES.GET_FIXED_BENEFITS_FAILURE:
    case TYPES.UPDATE_FIXED_BENEFITS_FAILURE:
    case TYPES.GET_ADDITIONAL_BENEFITS_FAILURE:
    case TYPES.UPDATE_ADDITIONAL_BENEFITS_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    default:
      return state;
  }
}

export const isDataLoaded = (state) => {
  return state.dataLoaded;
};

export const selectFixedBenefits = (state) => {
  return [...state.fixedBenefits];
};

export const selectAdditionalBenefits = (state) => {
  return [...state.additionalBenefits];
};
