import classNames from "classnames";
import React, { Fragment, useEffect, useRef } from "react";

import "../assets/styles/TimeFields.scss";

const Instance = ({ name, label, value, errorMsg, onChange, tooltip, isRequired, disabled, showOnlyHour }) => {
  const element = useRef(null);

  useEffect(() => {
    element.current.addEventListener("update", onValueChange);
  }, []);

  function onValueChange(event) {
    event.preventDefault();
    let timeStr = event.detail.time["24h"];
    onChange(event, timeStr);
  }

  return (
    <Fragment>
      <div className="e-field">
        <label
          className={`e-field__label${
            !!isRequired ? " e-field__label-required" : ""
          } e-margin-right-m e-field__label-inline`}
        >
          {label}
          {tooltip ? <e-tooltip content={tooltip} type="helper" /> : null}
        </label>
        <e-timepicker
          ref={element}
          value={value}
          name={name}
          error={!!errorMsg}
          // disabled={disabled} //currently not supported in Emarsys - implemented it using css
          class={classNames("e-field__label-inline", {
            "show-only-hour": showOnlyHour,
            "l-overlay-disabled": disabled
          })}
        ></e-timepicker>
        {errorMsg ? <span className="e-field__message e-field__message-error">{errorMsg}</span> : null}
      </div>
    </Fragment>
  );
};

export const TimeField = (props) => {
  return <Instance {...props} />;
};
