import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import sanitizeHtml from "sanitize-html";

const RadioButtonList = (props) => {
  const renderOptions = () => {
    const { options, disabled } = props;
    const inputClassName = classNames("e-radio", {
      "e-input-error": !!props.errorMsg
    });

    return options.map((opt) => {
      const id = `${props.name}_${opt.value}`;

      return (
        <li className="e-list__item" key={id}>
          <div className="e-grid">
            <div className="e-cell">
              <input
                className={inputClassName}
                type="radio"
                name={props.name}
                value={opt.value}
                id={id}
                checked={props.value === opt.value}
                onChange={props.onChange}
                disabled={disabled || opt.disabled}
              />
              <label htmlFor={id}>
                <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(opt.name) }} />
                {opt.tooltip ? (
                  <e-tooltip content={opt.tooltip} type="helper" has-action={opt.hoverableTooltip} />
                ) : null}
              </label>
            </div>
            {/* <div className="e-cell" className="m-n-l-10">
              <span className={spanClassName} dangerouslySetInnerHTML={{ __html: sanitizeHtml(opt.name) }} />
            </div> */}
          </div>
          {opt.extraElement}
        </li>
      );
    });
  };

  const wrapperClassName = classNames("e-field", props.className);
  const labelClassNames = classNames("e-field__label", { "e-field__label-required": props.isRequired });

  let tooltipAttr = {};
  if (props.tooltipHasAction) {
    tooltipAttr["has-action"] = true;
  }

  return (
    <div className={wrapperClassName}>
      <label className={labelClassNames}>
        {props.label} {props.tooltip ? <e-tooltip content={props.tooltip} type="helper" {...tooltipAttr} /> : null}
      </label>
      <ul className={classNames("e-list", { "e-list-horizontal": props.isHorizontal })}>{renderOptions()}</ul>
    </div>
  );
};

RadioButtonList.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  tooltipHasAction: PropTypes.bool,
  disabled: PropTypes.bool,
  isHorizontal: PropTypes.bool,
  errorMsg: PropTypes.string,
  isRequired: PropTypes.bool
};

export default RadioButtonList;
