import * as TYPES from "./TYPES";
import iconsApi from "../api/iconsApi";

export const loadIcons = (loadPromise, loadCustomerPromise, iconType) => {
  return (dispatch) => {
    dispatch({
      type: TYPES.ICONS_LOAD_REQUEST
    });

    return Promise.all([loadPromise(), loadCustomerPromise(iconType)])
      .then((allIcons) => {
        let customerIcons = allIcons[1]?.length > 0 ? allIcons[1] : [];
        dispatch({
          type: TYPES.ICONS_LOAD_SUCCESS,
          icons: allIcons[0],
          customerIcons: customerIcons
        });
      })
      .finally(() => {
        dispatch({
          type: TYPES.ICONS_LOAD_FINISHED
        });
      });
  };
};

export const uploadIconFile = (file, iconType) => {
  return (dispatch) => {
    dispatch({
      type: TYPES.ICON_ADD_REQUEST,
      payload: file
    });

    let isError = false;

    const loadImage = (src) =>
      new Promise((resolve, reject) => {
        let img = new Image();
        img.src = URL.createObjectURL(src);
        img.onload = () => {
          resolve(img);
        };
        img.onerror = reject;
      });

    loadImage(file)
      .then((image) => {
        if (image.width > 200 || image.height > 200) {
          isError = true;
          file.reason = "Height or Width>200px";
          dispatch({
            type: TYPES.ICON_ADD_FAILURE,
            payload: file
          });
        } else {
          if (file.size / 1024 > 100) {
            file.reason = "File size>100k";
            dispatch({
              type: TYPES.ICON_ADD_FAILURE,
              payload: file
            });
          } else {
            if (file.name.slice(-4) == ".png") {
              let data = new FormData();
              let fileNameNoExt = file.name.slice(0, -4);
              data.append("file", file, file.name);
              data.append("fileType", file.type);
              return iconsApi
                .uploadCustomerIconFile(iconType, data)
                .then((fileData) => {
                  dispatch({
                    type: TYPES.ICON_ADD_SUCCESS,
                    payload: fileData
                  });
                })
                .catch((err) => {
                  dispatch({
                    type: TYPES.ICON_ADD_FAILURE,
                    payload: file
                  });
                });
            } else {
              const canvas = document.createElement("canvas");
              canvas.width = image.width;
              canvas.height = image.height;
              const context = canvas.getContext("2d");
              context.drawImage(image, 0, 0, image.width, image.height);
              canvas.toBlob((blob) => {
                let data = new FormData();
                let fileNameNoExt = file.name.slice(0, -4);
                data.append("file", file, fileNameNoExt);
                data.append("fileType", file.type);
                data.append("png_image", blob);
                return iconsApi
                  .uploadCustomerIconFile(iconType, data)
                  .then((fileData) => {
                    dispatch({
                      type: TYPES.ICON_ADD_SUCCESS,
                      payload: fileData
                    });
                  })
                  .catch((err) => {
                    dispatch({
                      type: TYPES.ICON_ADD_FAILURE,
                      payload: file
                    });
                  });
              }, "image/png");
            }
          }
        }
      })
      .catch(() => {
        file.reason = "File is not image";
        dispatch({
          type: TYPES.ICON_ADD_FAILURE,
          payload: file
        });
      });
  };
};

export const deleteIcon = (fileName, iconType) => {
  return (dispatch) => {
    dispatch({ type: TYPES.ICON_DELETE_REQUEST, payload: fileName });

    return iconsApi.deleteCustomerIcon(fileName, iconType).then((response) => {
      dispatch({ type: TYPES.ICON_DELETE_SUCCESS, payload: fileName });
    });
  };
};

export const clearIconErrors = () => {
  return (dispatch) => {
    dispatch({ type: TYPES.ICON_CLEAR_ERRORS });
  };
};

export const setEditMode = (name) => {
  return (dispatch) => {
    dispatch({ type: TYPES.ICON_EDIT_MODE, payload: name });
  };
};

export const setEditName = (name, newName) => {
  return (dispatch) => {
    dispatch({ type: TYPES.ICON_EDIT_NAME, payload: name, newName });
  };
};

export const updateEditedIconName = (name, newName, iconType) => {
  return (dispatch) => {
    dispatch({ type: TYPES.ICON_EDIT_REQUEST, payload: newName });

    return iconsApi
      .updateCustomerIconName(name, newName, iconType)
      .then((response) => {
        dispatch({ type: TYPES.ICON_EDIT_SUCCESS, payload: name });
      })
      .catch(() => {
        dispatch({ type: TYPES.ICON_EDIT_FAILURE, payload: name });
      });
  };
};

export const cancelEditedIconName = (name) => {
  return (dispatch) => {
    dispatch({ type: TYPES.ICON_EDIT_FAILURE, payload: name });
  };
};
