import React, { Component, Fragment } from "react";

import { renderToString } from "react-dom/server";
import PropTypes from "prop-types";

import Widget from "../../components/Widget";
import Time from "../../components/Time";
import WidgetDateRange from "../../components/WidgetDateRange";
import DashboardEmptyState from "./DashboardEmptyState";
import WidgetHelpLink from "./WidgetHelpLink";
import LegendWithSelection from "./LegendWithSelection";
import WidgetTotalsItem from "./WidgetTotalsItem";

import { TIER_COLORS_ARRAY, TOTAL_LOYALTY_MEMBERS_HELP_LINK } from "../../constants/dashboardConstants";
import reportApi from "../../api/reportApi";

import { convertDateForChart, formatTrendText, transformDataDateForDemo, getDaysDiff } from "./dashboardHelper";
import { getMessage } from "../../messages";

const CHART_HEIGHT = 164;

const CHART_COLORS = {
  TOTAL: "#424E88"
};

class NumOfMembersWidget extends Component {
  constructor(props) {
    super(props);

    let legendSelection = {};
    this.chartMetaData = [];

    props.tiers.forEach((tier, index) => {
      this.chartMetaData.push({ id: tier.id, displayName: tier.name, color: TIER_COLORS_ARRAY[index] });
      legendSelection[tier.id] = true;
    });

    const chartSeriesArr = this.chartMetaData.map((d) => {
      return {
        id: d.id,
        name: d.displayName,
        color: d.color,
        data: []
      };
    });

    this.state = {
      loading: true,
      selectedDaysRange: getDaysDiff(props.initialStartDate, props.initialEndDate),
      dateRange: {
        startDateStr: props.initialStartDate,
        endDateStr: props.initialEndDate
      },
      chartSeriesArr: chartSeriesArr,
      totals: {},
      chartCustomTooltip: [],
      legendSelection,
      showEmptyState: false
    };
  }

  componentDidMount() {
    this.loadData(this.state.dateRange);
  }

  loadData(dateRange) {
    const promises = [reportApi.getNumberOfMembersData(dateRange), reportApi.getNumberOfMembersTotals(dateRange)];

    this.setState({ loading: true, showEmptyState: false });

    Promise.all(promises)
      .then((response) => {
        this.setChartSeriesArrState(response[0]);
        this.setTotalsState(response[1]);
      })
      .catch((error) => {
        this.setState({ showEmptyState: true });
        throw error;
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  getNumberValueAttr() {
    return { humanize: "auto", precision: "2", "trim-fraction-zeros": "true" };
  }

  getPctValueAttr() {
    return { humanize: "auto", precision: "1", type: "percent", "trim-fraction-zeros": "true" };
  }

  getTrendText(value, daysRange) {
    return formatTrendText`${value} than previous ${daysRange} days`;
  }

  getChartTooltipContent(date, { total, tiers }) {
    let content = [];

    const dateElm = renderToString(<Time value={date} onlyDate={true} />);

    content.push({ label: dateElm, type: "header" });

    content.push({
      value: renderToString(
        <Fragment>
          <e-numeric value={total || 0}></e-numeric>
        </Fragment>
      ),
      label: getMessage("numOfMembersWidget.chartTooltipContent.label"),
      color: CHART_COLORS.TOTAL
    });

    this.chartMetaData.forEach((metaData) => {
      const { num, pct } = { ...tiers[metaData.id] };
      content.push({
        value: renderToString(
          <Fragment>
            <e-numeric value={num || 0}></e-numeric> (<e-numeric value={pct || 0} type="percent"></e-numeric>)
          </Fragment>
        ),
        label: metaData.displayName,
        color: metaData.color
      });
    });

    return content;
  }

  getItemsForLegend() {
    return this.chartMetaData.map((item) => {
      return {
        id: item.id,
        displayName: item.displayName,
        color: item.color,
        isSelected: this.state.legendSelection[item.id]
      };
    });
  }

  setChartSeriesArrState(dataRows) {
    if (!dataRows) {
      this.setState({ showEmptyState: true });
      return;
    }

    dataRows = transformDataDateForDemo(dataRows, this.state.selectedDaysRange);

    let { chartSeriesArr } = { ...this.state };
    let chartCustomTooltip = {};

    //reset data on each series
    chartSeriesArr.forEach((series) => {
      series.data = [];
    });

    dataRows.forEach((row) => {
      const date = convertDateForChart(row.date);

      chartSeriesArr.forEach((series) => {
        let num = row.tiers[series.id] ? row.tiers[series.id].num || 0 : 0;
        series.data.push({
          x: date,
          y: num
        });
      });

      chartCustomTooltip[date] = { rows: this.getChartTooltipContent(date, row) };
    });

    this.setState({ chartSeriesArr, chartCustomTooltip });
  }

  setTotalsState({ total, totalPrevDiffPct }) {
    let totals = {
      total,
      totalPrevDiffPct
    };

    this.setState({ totals });
  }

  onTimeRangeChange(value) {
    const { start, end } = value;
    let dateRange = {
      startDateStr: start,
      endDateStr: end
    };
    this.setState({ dateRange, selectedDaysRange: getDaysDiff(start, end) });
    this.loadData(dateRange);
  }

  renderTotals() {
    const daysRange = this.state.selectedDaysRange;
    const { total, totalPrevDiffPct } = { ...this.state.totals };
    return (
      <div className="e-grid">
        <div className="e-cell e-cell-4">
          <WidgetTotalsItem
            title={getMessage("numOfMembersWidget.total.title")}
            value={total}
            valueNumericAttr={this.getNumberValueAttr()}
            valueColor={CHART_COLORS.TOTAL}
            trendValue={totalPrevDiffPct}
            trendText={this.getTrendText(totalPrevDiffPct, daysRange)}
          />
        </div>
      </div>
    );
  }

  renderChart() {
    const { chartSeriesArr, chartCustomTooltip, legendSelection } = { ...this.state };

    return (
      <ec-chart height={CHART_HEIGHT} domain-type="time" custom-tooltip={JSON.stringify(chartCustomTooltip)}>
        <ec-series-stack>
          {chartSeriesArr.map((series, index) => {
            return (
              <ec-series-stack-area
                key={series.id}
                order={index}
                name={series.name}
                data={JSON.stringify(series.data)}
                color={series.color}
              ></ec-series-stack-area>
            );
          })}
        </ec-series-stack>
      </ec-chart>
    );
  }

  renderContent() {
    const { showEmptyState } = { ...this.state };

    if (showEmptyState) {
      return <DashboardEmptyState />;
    }

    return (
      <Fragment>
        <div>{this.renderTotals()}</div>
        <div className="e-margin-top-m">{this.renderChart()}</div>
        <div className="e-margin-top-m">
          <LegendWithSelection items={this.getItemsForLegend()} visible={true} />
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <Widget
        title={getMessage("numOfMembersWidget.title")}
        className="num-of-members-widget"
        headerActions={
          <WidgetDateRange
            onChange={this.onTimeRangeChange.bind(this)}
            isDemoMode={false}
            dateRange={{ start: this.state.dateRange.startDateStr, end: this.state.dateRange.endDateStr }}
          />
        }
        footerActions={<WidgetHelpLink linkUrl={TOTAL_LOYALTY_MEMBERS_HELP_LINK} />}
        content={this.renderContent()}
        loading={this.state.loading}
        isEmptyState={this.state.showEmptyState}
      />
    );
  }
}

NumOfMembersWidget.propTypes = {
  tiers: PropTypes.array,
  initialStartDate: PropTypes.string,
  initialEndDate: PropTypes.string
};

export default NumOfMembersWidget;
