import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { selectPlanHasMultiMarket } from "../reducers";

import InputField from "../components/InputField";
import RadioButtonList from "../components/RadioButtonList";
import IconSelectInput from "../components/IconSelectInput";
import MultiLangPopupOpener from "../components/MultiLangPopupOpener";
import MultiMarketSelect from "../components/MultiMarketSelect";
import Time from "../components/Time";

import { DateTimeField, DateTimeRangeField } from "../components/DateFields";

import { getErrorMessage } from "../validators";

import { rewardType } from "../types";

import { getGeneralIconsUrl } from "../helpers/envHelper";

import iconsApi from "../api/iconsApi";

import { ACCESS_URL_TYPE_OPTIONS, ACCESS_URL_TYPE_ENUM } from "../constants/rewardConstants";
import exclusiveAccessService from "../services/exclusiveAccessService";
import moment from "moment";
import { EXCLUSIVE_ACCESS_STATUS_ENUM } from "../constants/exclusiveAccessConstants";
import { getMessage } from "../messages";

const ExclusiveAccessSetupSettings = ({
  exclusiveAccess,
  onInputChange,
  onIconInputChange,
  errors,
  isStatusActive,
  onShowAccessNameMultiLang,
  onShowExactAccessUrlMultiLang,
  onShowStartsWithAccessUrlMultiLang,
  hasMultiLanguage,
  status,
  onClearUrlValues,
  isViewOnly
}) => {
  const hasMultiMarket = useSelector((state) => selectPlanHasMultiMarket(state));

  const onChangeRadioButton = (e) => {
    const target = e.target.value;
    const customValue = target === ACCESS_URL_TYPE_ENUM.STARTS_WITH_URL ? true : false;
    onInputChange(e, customValue);
    onClearUrlValues();
  };

  const UrlInputField = (accessUrlType, disabled, onShowMultiLang) => (
    <InputField
      type="text"
      name={`blocking.${accessUrlType}`}
      value={exclusiveAccess.blocking[accessUrlType] || "http://"}
      onChange={onInputChange}
      disabled={isStatusActive || isViewOnly || disabled}
      errorMsg={!disabled ? getErrorMessage(errors, `blocking.${accessUrlType}`) : ""}
      postfixButtons={
        hasMultiLanguage && <MultiLangPopupOpener onClick={onShowMultiLang} disabled={isStatusActive || disabled} />
      }
    />
  );

  const renderExclusivityValidity = () => {
    if (!exclusiveAccessService.isLimitedExclusiveAccess(exclusiveAccess.masterExclusiveAccess)) {
      return null;
    }

    const { blockingValidity } = { ...exclusiveAccess.validity };

    if (
      status !== EXCLUSIVE_ACCESS_STATUS_ENUM.INACTIVE &&
      blockingValidity?.start &&
      moment() > moment(blockingValidity.start) &&
      moment() < moment(blockingValidity.end)
    ) {
      return (
        <DateTimeField
          label={getMessage("exclusiveAccessSetupSettings.validity.blockingValidity.end.label")}
          name="validity.blockingValidity.end"
          value={exclusiveAccess.validity.blockingValidity.end}
          disabled={isViewOnly}
          onChange={onInputChange}
          errorMsg={getErrorMessage(errors, "validity.blockingValidity.end")}
          minDate={new Date()}
          tooltip={getMessage("exclusiveAccessSetupSettings.validity.blockingValidity.end.tooltip")}
          message={
            //TBD
            <Fragment>
              This exclusivity is activated from <Time value={blockingValidity.start} onlyDate={true} /> can be
              modified.
            </Fragment>
          }
          isRequired={true}
        />
      );
    }

    return (
      <DateTimeRangeField
        label={getMessage("excluxiveAccessSetupSettings.blockingValidity.title")}
        name="validity.blockingValidity"
        value={exclusiveAccess.validity.blockingValidity}
        onChange={onInputChange}
        errorMsg={getErrorMessage(errors, "validity.blockingValidity")}
        minDate={new Date()}
        tooltip={getMessage("excluxiveAccessSetupSettings.blockingValidity.tooltip")}
        disabled={status === EXCLUSIVE_ACCESS_STATUS_ENUM.EXPIRED || isViewOnly}
        isRequired={true}
      />
    );
  };

  const buttonListOptions = [
    {
      ...ACCESS_URL_TYPE_OPTIONS[0],
      extraElement: UrlInputField(
        "exactAccessUrl",
        exclusiveAccess.blocking.isStartsWith,
        onShowExactAccessUrlMultiLang
      )
    },
    {
      ...ACCESS_URL_TYPE_OPTIONS[1],
      extraElement: UrlInputField(
        "startsWithAccessUrl",
        !exclusiveAccess.blocking.isStartsWith,
        onShowStartsWithAccessUrlMultiLang
      )
    }
  ];

  return (
    <div className="e-box">
      {hasMultiMarket && (
        <div className="e-row">
          <div className="e-notice">
            <p>{getMessage("excluxiveAccessSetupSettings.notice.multimarket")}</p>
          </div>
        </div>
      )}

      <div className="e-row">
        <div className="e-col-6">
          <h2>{getMessage("excluxiveAccessSetupSettings.generalSettings.title")}</h2>

          {/* Pool Name */}
          <InputField
            label={getMessage("excluxiveAccessSetupSettings.exclusiveAccess.name.label")}
            type="text"
            name="name"
            value={exclusiveAccess.name}
            disabled={isViewOnly}
            onChange={onInputChange}
            errorMsg={getErrorMessage(errors, "name")}
            tooltip={getMessage("excluxiveAccessSetupSettings.exclusiveAccess.name.tooltip")}
            isRequired={true}
          />
          {renderExclusivityValidity()}
          {/* Access Name */}
          <InputField
            label={getMessage("exclusiveAccessSetupSettings.accessName.label")}
            type="text"
            name="accessName"
            value={exclusiveAccess.accessName}
            disabled={isViewOnly}
            onChange={onInputChange}
            errorMsg={getErrorMessage(errors, "accessName")}
            tooltip={getMessage("exclusiveAccessSetupSettings.accessName.tooltip")}
            postfixButtons={hasMultiLanguage && <MultiLangPopupOpener onClick={onShowAccessNameMultiLang} />}
            isRequired={true}
          />

          {/* TODO: check displayName, fileName, selectIconText  */}
          <IconSelectInput
            label={getMessage("exclusiveAccessSetupSettings.accessIcon.label")}
            name="icon"
            displayName={exclusiveAccess.icon && exclusiveAccess.icon.displayName}
            fileName={exclusiveAccess.icon && exclusiveAccess.icon.fileName}
            iconPrefixUrl={getGeneralIconsUrl()}
            selectIconText={getMessage("exclusiveAccessSetupSettings.accessIcon.selectIconText")}
            onChange={onIconInputChange}
            loadPromise={iconsApi.getGeneralIcons}
            loadCustomerPromise={iconsApi.getCustomerIcons}
            iconType="general"
          />
        </div>

        <div className="e-col-6">
          <h2>
            {getMessage("excluxiveAccessSetupSettings.pageSettings.title")}
            <e-tooltip
              content={getMessage("excluxiveAccessSetupSettings.pageSettings.tooltip")}
              type="helper"
              style={{ marginTop: "2px", marginLeft: "6px" }}
            />
          </h2>
          <MultiMarketSelect
            label={getMessage("excluxiveAccessSetupSettings.market.label")}
            name="market"
            value={exclusiveAccess.market}
            onChange={onInputChange}
            errorMsg={getErrorMessage(errors, "market")}
            disabled={isStatusActive || isViewOnly}
            tooltip={getMessage("excluxiveAccessSetupSettings.market.tooltip")}
            isRequired={hasMultiMarket}
          />
          {/* Page Url */}
          <RadioButtonList
            label={getMessage("excluxiveAccessSetupSettings.blocking.isStartsWith.label")}
            name="blocking.isStartsWith"
            options={buttonListOptions}
            value={
              exclusiveAccess.blocking.isStartsWith
                ? ACCESS_URL_TYPE_ENUM.STARTS_WITH_URL
                : ACCESS_URL_TYPE_ENUM.EXACT_URL
            }
            onChange={onChangeRadioButton}
            className="no-margin"
            disabled={isStatusActive || isViewOnly}
            isRequired={true}
          />

          {/* Access URL */}
          {exclusiveAccess.blocking.isStartsWith && (
            <InputField
              label={getMessage("excluxiveAccessSetupSettings.blocking.landingPageUrl.label")}
              type="text"
              name="blocking.landingPageUrl"
              value={exclusiveAccess.blocking.landingPageUrl || "http://"}
              onChange={onInputChange}
              errorMsg={getErrorMessage(errors, "blocking.landingPageUrl")}
              disabled={isStatusActive || isViewOnly}
              tooltip={getMessage("excluxiveAccessSetupSettings.blocking.landingPageUrl.tooltip")}
              postfixButtons={
                hasMultiLanguage && (
                  <MultiLangPopupOpener onClick={onShowStartsWithAccessUrlMultiLang} disabled={isStatusActive} />
                )
              }
            />
          )}
        </div>
        <div className="e-col e-col__separator" />
      </div>
    </div>
  );
};

ExclusiveAccessSetupSettings.propTypes = {
  exclusiveAccess: rewardType.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onIconInputChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  isStatusActive: PropTypes.bool,
  onShowAccessNameMultiLang: PropTypes.func,
  onShowExactAccessUrlMultiLang: PropTypes.func,
  onShowStartsWithAccessUrlMultiLang: PropTypes.func,
  hasMultiLanguage: PropTypes.bool,
  status: PropTypes.string,
  isViewOnly: PropTypes.object
};

export default ExclusiveAccessSetupSettings;
