import queryString from "query-string";

export const isDemoMode = () => {
  return !!process.env.REACT_APP_DEMO_MODE && process.env.REACT_APP_DEMO_MODE === "true";
};

export const isDisableFeature = () => {
  return !!process.env.REACT_APP_DISABLE_MODE && process.env.REACT_APP_DISABLE_MODE === "true" && !isDemoMode();
};

export const isSpecificFeatureEnabled = (specificFeatureEnabled) => {
  return !isDisableFeature() || specificFeatureEnabled;
};

export const getImagesUrl = () => {
  return process.env.REACT_APP_LOYALTY_IMAGES_HOST;
};

export const getTierIconsUrl = () => {
  return `${getImagesUrl()}/icons/-1/tier`;
};

export const getGeneralIconsUrl = () => {
  return `${getImagesUrl()}/icons/-1/general`;
};

export const getEmarsysSupportAnchor = (text) => {
  return `<a target="_blank" href="https://help.emarsys.com/hc/en-us">${text}</a>`;
};

export const getApiBaseUrl = (customerId) => `${process.env.REACT_APP_LOYALTY_API}/customer/${customerId}`;

export const isDev = !!process.env.REACT_APP_IS_DEV && process.env.REACT_APP_IS_DEV === "true";

export const getAdminLanguage = () => {
  //take override language from session storage - for testing purposes
  const overrideLanguage = sessionStorage.getItem("lang");
  if (overrideLanguage) {
    return overrideLanguage.toLowerCase().replace("_", "-");
  }

  //get the language from the url generated by Emarsys - take only the first part of the local
  const queryParams = queryString.parse(document.location.search);
  if (queryParams.language) {
    return queryParams.language.split("_")[0];
  }

  return "en";
};
