import React from "react";
import PropTypes from "prop-types";

import InputField from "../../components/InputField";

import { getMultiLangFieldLabel } from "../../helpers/multiLangHelper";

const MultiLangVoucherNameField = ({ onChange, lang, translations, disabled }) => {
  return (
    <InputField
      label={getMultiLangFieldLabel({ label: "Voucher name", lang })}
      value={translations.voucherNameML[lang]}
      name={"voucherNameML"}
      onChange={(event) => onChange(event, lang)}
      disabled={disabled}
    />
  );
};

MultiLangVoucherNameField.propTypes = {
  onChange: PropTypes.func,
  lang: PropTypes.string,
  translations: PropTypes.object,
  disabled: PropTypes.bool
};

export default MultiLangVoucherNameField;
