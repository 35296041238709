import * as TYPES from "../actions/TYPES";

const initialState = {
  translations: {},
  loading: false,
  dataLoaded: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.GET_TRANSLATIONS_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case TYPES.GET_TRANSLATIONS_SUCCESS: {
      const translations = action.payload.translations;
      return {
        ...state,
        translations,
        loading: false,
        dataLoaded: true
      };
    }

    case TYPES.GET_TRANSLATIONS_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    default:
      return state;
  }
}

export const isDataLoaded = (state) => state.dataLoaded;
