import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LoaderOverlay from "../components/LoaderOverlay";
import MembersReportDownloadButton from "../views/MemberProfile/MembersReportDownloadButton";
import CreateGroupPopup from "../views/MemberProfile/CreateGroupPopup";
import DeleteContactPopup from "../views/MemberProfile/DeleteContactPopup";
import CreateContactPopup from "../views/MemberProfile/CreateContactPopup";
import Time from "../components/Time";
import InputSearchField from "../components/InputSearchField";

import { openDeleteDialog } from "../helpers/dialogHelper";
import { navigateToMemberProfile, navigateToGroupProfile } from "../helpers/navHelper";

import memberProfileApi from "../api/memberProfileApi";

import { showErrorAlert, showSuccessAlert } from "../actions/alertActions";

import { selectCustomerHasMultiPlan, selectCustomerFeaturesConfigByName, isAccountViewOnlySupport } from "../reducers";
import { CONFIG_OBJ_ENUM } from "../constants/configConstants";
import { getMessage } from "../messages";

const MemberProfilesOverview = (props) => {
  const dispatch = useDispatch();
  const [contactData, setContactData] = useState(null);
  const [groupData, setGroupData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [showGroupPopup, setShowGroupPopup] = useState(false);
  const [showCreateContactPopup, setShowCreateContactPopup] = useState(false);
  const [showDeleteContactPopup, setShowDeleteContactPopup] = useState(false);

  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const isGroupEnabled = useSelector((state) =>
    selectCustomerFeaturesConfigByName(state, CONFIG_OBJ_ENUM.ENABLE_MEMBER_GROUP)
  );
  const hasMultiPlan = useSelector((state) => selectCustomerHasMultiPlan(state));
  const isViewOnly = useSelector((state) => isAccountViewOnlySupport(state));

  const GroupProfileButton = ({ groupId, groupName }) => {
    return (
      <e-tooltip valign="baseline" content={getMessage("memberProfileOverview.groupProfileBtn.tooltip")}>
        <a className="e-svgclickfix" onClick={() => onGroupProfileClick(groupId, groupName)}>
          <e-icon icon="users" type="table"></e-icon>
        </a>
      </e-tooltip>
    );
  };

  async function onSearchChange(event) {
    const value = event.target.value;
    setSearchValue(value);

    if (!value) {
      setContactData(null);
      setGroupData(null);
      return;
    }

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const search = async () => {
      setSearchLoading(true);
      try {
        setContactData();
        setGroupData();
        const result = await memberProfileApi.searchMemberProfilesOverview({
          searchValue: value,
          searchGroup: isGroupEnabled
        });
        if (result?.contactId) {
          setContactData(result);
        } else if (result?.groupId) {
          setGroupData(result);
        }
      } catch (error) {
        dispatch(showErrorAlert(getMessage("memberProfileOverview.search.error")));
        throw error;
      } finally {
        setSearchLoading(false);
      }
    };

    const t = setTimeout(search, 250);

    setSearchTimeout(t);
  }

  function onMemberProfileClick(contactId, externalId) {
    const { history, supportMode } = { ...props };
    navigateToMemberProfile({ history, supportMode, contactId, externalId });
  }

  function onMemberDeleteClick(contactId) {
    setShowDeleteContactPopup(true);
  }

  async function deleteMember(contactId, isFullDelete) {
    setLoading(true);
    try {
      await memberProfileApi.deleteMember(contactId, isFullDelete);
      setSearchValue("");
      setContactData();
    } catch (error) {
      dispatch(showErrorAlert(getMessage("memberProfileOverview.deleteMember.error")));
      throw error;
    } finally {
      setLoading(false);
      setShowDeleteContactPopup(false);
    }
  }

  function onGroupProfileClick(groupId, groupName) {
    const { history, supportMode } = { ...props };
    navigateToGroupProfile({ history, supportMode, groupId, groupName });
  }

  function onGroupDeleteClick(groupId) {
    openDeleteDialog(
      getMessage("memberProfileOverview.deleteGroupDialog.headline"),
      getMessage("memberProfileOverview.deleteGroupDialog.content"),
      () => deleteGroup(groupId)
    );
  }

  async function deleteGroup(groupId) {
    setLoading(true);
    try {
      await memberProfileApi.deleteGroup(groupId);
      setSearchValue("");
      setGroupData(null);
      dispatch(showSuccessAlert(getMessage("memberProfileOverview.deleteGroup.success")));
    } catch (error) {
      dispatch(showErrorAlert(getMessage("memberProfileOverview.deleteGroup.error")));
      throw error;
    } finally {
      setLoading(false);
    }
  }

  function onCreateGroup(group) {
    const { history, supportMode } = { ...props };
    navigateToGroupProfile({ history, supportMode, groupId: group.groupId, groupName: group.name });
  }

  function onCreateContact({ contactId, externalId }) {
    const { history, supportMode } = { ...props };
    navigateToMemberProfile({ history, supportMode, contactId, externalId });
  }

  function renderEmptyState() {
    if (!!contactData) {
      return null;
    }

    let icon, title, lead;
    if (!searchValue) {
      icon = "table";
      title = getMessage("memberProfileOverview.search.noItems.title");
    } else {
      icon = "search";
      title = getMessage("memberProfileOverview.search.noResults.title");
      lead = getMessage("memberProfileOverview.search.noResults.lead");
    }

    return (
      <Fragment>
        <div className="e-emptystate" style={{ position: "relative" }}>
          <e-icon class="e-emptystate__icon" icon={icon}></e-icon>
          <div className="e-emptystate__content">
            <div className="e-emptystate__title">{title}</div>
            <div className="e-emptystate__lead">{lead}</div>
          </div>

          <LoaderOverlay show={searchLoading} noOpacity={true} />
        </div>
      </Fragment>
    );
  }

  function renderMemberTableHeader() {
    return (
      <Fragment>
        <td className="e-table__col e-table__col e-table__col-medium">
          {getMessage("memberProfileOverview.tableHeader.memberID")}
        </td>
        <td className="e-table__col e-table__col e-table__col-medium">
          {getMessage("memberProfileOverview.tableHeader.tier")}
        </td>
        <td className="e-table__col e-table__col e-table__col-medium">
          {contactData?.planType != "spend_and_benefits" ? getMessage("statusPoints") : getMessage("loyaltyCredit")}
        </td>
        {contactData?.planType != "spend_and_benefits" && (
          <td className="e-table__col e-table__col e-table__col-medium">
            {getMessage("memberProfileOverview.tableHeader.pointsToSpend")}
          </td>
        )}
        <td className="e-table__col e-table__col e-table__col-medium">
          {getMessage("memberProfileOverview.tableHeader.joinDate")}
        </td>
        <td className="e-table__col e-table__col e-table__col-medium"></td>
      </Fragment>
    );
  }

  function renderMemberTableBody() {
    return (
      <Fragment>
        <td>{contactData.externalId}</td>
        <td>{contactData.tier}</td>
        <td>{contactData.statusPoints}</td>
        {contactData.planType != "spend_and_benefits" && <td>{contactData.pointsToSpend}</td>}
        <td>
          <Time value={contactData.joinDate} onlyDate={true} />
        </td>
        <td className="e-table__col e-table__col-actions">
          <e-tooltip valign="baseline" content={getMessage("memberProfile.title")}>
            <a
              className="e-svgclickfix"
              onClick={() => onMemberProfileClick(contactData.contactId, contactData.externalId)}
            >
              <e-icon icon="e-profile" type="table"></e-icon>
            </a>
          </e-tooltip>
          {contactData.groupId && (
            <GroupProfileButton groupId={contactData.groupId} groupName={contactData.groupName} />
          )}
          <e-tooltip valign="baseline" content={getMessage("memberProfileOverview.deleteMemberIcon.tooltip")}>
            <a className="e-svgclickfix" onClick={() => !isViewOnly && onMemberDeleteClick(contactData.contactId)}>
              <e-icon icon="trash-o" type="table" disabled={isViewOnly}></e-icon>
            </a>
          </e-tooltip>
        </td>
      </Fragment>
    );
  }

  function renderGroupTableHeader() {
    return (
      <Fragment>
        <td className="e-table__col e-table__col e-table__col-medium">
          {getMessage("memberProfileOverview.groupTableHeader.groupId")}
        </td>
        <td className="e-table__col e-table__col e-table__col-medium">
          {getMessage("memberProfileOverview.groupTableHeader.tier")}
        </td>
        <td className="e-table__col e-table__col e-table__col-medium">
          {getMessage("memberProfileOverview.groupTableHeader.points")}
        </td>
        <td className="e-table__col e-table__col e-table__col-medium">
          {getMessage("memberProfileOverview.groupTableHeader.members")}
        </td>
        <td className="e-table__col e-table__col e-table__col-medium">
          {getMessage("memberProfileOverview.groupTableHeader.created")}
        </td>
        <td className="e-table__col e-table__col e-table__col-medium"></td>
      </Fragment>
    );
  }

  function renderGroupTableBody() {
    return (
      <Fragment>
        <td>{groupData.groupName}</td>
        <td>{groupData.tier}</td>
        <td>{groupData.statusPoints}</td>
        <td>{groupData.numOfMembers}</td>
        <td>
          <Time value={groupData.createdAt} onlyDate={true} />
        </td>
        <td className="e-table__col e-table__col-actions">
          <GroupProfileButton groupId={groupData.groupId} groupName={groupData.groupName} />
          <e-tooltip valign="baseline" content={getMessage("memberProfileOverveiw.groupTable.delete.label")}>
            <a className="e-svgclickfix" onClick={() => onGroupDeleteClick(groupData.groupId)}>
              <e-icon icon="trash-o" type="table"></e-icon>
            </a>
          </e-tooltip>
        </td>
      </Fragment>
    );
  }

  function renderGrid() {
    const colspan = 6;
    return (
      <Fragment>
        <table className="e-table e-table-verticalcentered" data-e-version="2">
          <thead>
            <tr>
              <td className="e-table__col e-table__col-filter" colSpan={colspan}>
                <div className="e-grid e-grid-between">
                  <div className="e-cell">
                    <e-tooltip
                      content={
                        isGroupEnabled
                          ? getMessage("memberProfileOverveiw.grid.search.tooltip.groupEnabled")
                          : getMessage("memberProfileOverveiw.grid.search.tooltip")
                      }
                      placement="right"
                    >
                      <InputSearchField
                        label={getMessage("memberProfileOverveiw.grid.search.label")}
                        onChange={onSearchChange}
                        value={searchValue}
                        inline={true}
                        noMargin={true}
                        size={"large"}
                      />
                    </e-tooltip>
                  </div>
                  <div className="e-cell">
                    <button
                      className="e-btn e-btn-primary"
                      onClick={() => setShowCreateContactPopup(true)}
                      disabled={isViewOnly}
                    >
                      {getMessage("memberProfileOverveiw.grid.btnCreateNewMember.label")}
                    </button>
                    {isGroupEnabled && (
                      <button
                        className="e-btn e-btn-primary e-margin-left-m"
                        disabled={isViewOnly}
                        onClick={() => setShowGroupPopup(true)}
                      >
                        {getMessage("memberProfileOverveiw.grid.btnCreateNewGroup.label")}
                      </button>
                    )}
                  </div>
                </div>
              </td>
            </tr>
            <tr>{!!groupData ? renderGroupTableHeader() : renderMemberTableHeader()}</tr>
          </thead>
          <tbody>
            <tr>
              {contactData && renderMemberTableBody()}
              {groupData && renderGroupTableBody()}
              {!contactData && !groupData && searchValue && <td colSpan={colspan}>{renderEmptyState()}</td>}
            </tr>
          </tbody>
        </table>
        <CreateGroupPopup
          show={showGroupPopup}
          onPopupClose={() => setShowGroupPopup(false)}
          hasMultiPlan={hasMultiPlan}
          onSave={onCreateGroup}
          planType={contactData?.planType}
        />
        <CreateContactPopup
          show={showCreateContactPopup}
          onPopupClose={() => setShowCreateContactPopup(false)}
          hasMultiPlan={hasMultiPlan}
          onSave={onCreateContact}
        />
        <DeleteContactPopup
          show={showDeleteContactPopup}
          onPopupClose={() => setShowDeleteContactPopup(false)}
          onDelete={deleteMember}
          externalId={contactData?.externalId}
          contactId={contactData?.contactId}
        />
      </Fragment>
    );
  }

  if (props.supportMode) {
    return (
      <div className="e-layout e-layout-without_navigation">
        <header className="e-layout__header">
          <h1 className="e-layout__title">{getMessage("memberProfileOverveiw.grid.support.title")}</h1>
          <div className="e-layout__action">
            <MembersReportDownloadButton />
          </div>
        </header>
        <main className="e-layout__content">
          <section className="e-layout__section">{renderGrid()}</section>
        </main>
      </div>
    );
  }

  return (
    <Fragment>
      <header className="e-layout__header">
        <h1 className="e-layout__title">{getMessage("memberProfileOverveiw.grid.title")}</h1>
        <div className="e-layout__action">
          <MembersReportDownloadButton />
        </div>
      </header>
      <main className="e-layout__content">
        <section className="e-layout__section" style={{ position: "relative" }}>
          {renderGrid()} <LoaderOverlay show={loading} />
        </section>
      </main>
    </Fragment>
  );
};

export default MemberProfilesOverview;
