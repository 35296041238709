import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import PopupContainer from "../components/PopupContainer";
import LoaderOverlay from "../components/LoaderOverlay";
import InputField from "../components/InputField";
import EmptyState from "../components/EmptyState";
import FileInputField from "./FileInputField";

import "../assets/styles/IconsGallery.scss";
import FileUploader from "./FileUploader";
import { openDeleteDialog } from "../helpers/dialogHelper";
import { getValueByType } from "../helpers/formHelper";
import { ALERT_STATUS } from "../constants/alertContants";
import Alert from "./Alert";
import {
  deleteIcon,
  uploadIconFile,
  loadIcons,
  clearIconErrors,
  setEditMode,
  setEditName,
  updateEditedIconName,
  cancelEditedIconName
} from "../actions/iconActions";
import {
  selectCustomerIcons,
  selectErrorFiles,
  selectIcons,
  selectShowUploadErrorDialog,
  selectEditedIcon
} from "../reducers";
import Card from "./Card";

import { getMessage } from "../messages";
import { getIconName } from "../helpers/iconHelper";

class IconsGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      searchValue: "",
      totalUploads: 0,
      currentlyEditedValue: "",
      showedIcons: []
    };

    this.iconsLoaded = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show && !this.iconsLoaded) {
      this.loadIcons();
    }
  }

  loadIcons() {
    if (this.props.loadPromise) {
      this.props.loadIcons(this.props.loadPromise, this.props.loadCustomerPromise, this.props.iconType);
    }
  }

  renderCard(icon) {
    const iconUrl = `${this.props.iconPrefixUrl}/${getIconName(icon.file, ".svg")}`;
    return (
      <Fragment>
        {icon.showActions && (
          <div class="e-card__buttons">
            <div class="e-buttongroup e-buttongroup-sticky">
              <e-tooltip content="Edit">
                <button
                  class="e-btn e-btn-onlyicon e-svgclickfix"
                  type="button"
                  onClick={() => {
                    this.onFileEdit(icon.name);
                  }}
                >
                  <e-icon icon="pencil"></e-icon>
                </button>
              </e-tooltip>
              <e-tooltip content="Delete">
                <button
                  class="e-btn e-btn-onlyicon e-svgclickfix"
                  type="button"
                  onClick={() => {
                    this.onFileDeleted(icon.name);
                  }}
                >
                  <e-icon icon="trash-o"></e-icon>
                </button>
              </e-tooltip>
            </div>
          </div>
        )}

        <div className="e-card__content">
          <img className="e-card__preview p-10" src={iconUrl} />
        </div>
        <div className="e-card__action">
          <button className="e-btn e-btn-primary" onClick={() => this.props.onSelectIcon(icon)}>
            <e-icon icon="plus" type="table" />
            {this.props.selectIconText}
          </button>
        </div>
        <div className="e-card__lead">
          <div className="e-card__caption">
            <div className="e-card__title">
              {icon.editMode && (
                <InputField
                  value={icon.name}
                  onChange={(event) => this.onIconNameChanged(event, icon.name)}
                  onBlur={this.onFinishEdit.bind(this)}
                ></InputField>
              )}
              {!icon.editMode && icon.name}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  onSearchChange(event) {
    this.props.clearIconErrors();
    const searchValue = event.target.value;
    this.setState({
      searchValue
    });
  }

  onIconNameChanged = (event, name) => {
    const target = event.target;
    let value = getValueByType(target);
    this.props.setEditName(name, value);
  };

  onFinishEdit = () => {
    let editedIcon = this.props.editedIcon;
    const showedIcons = this.getAllIcons();

    if (
      editedIcon.name == "" ||
      editedIcon.name == editedIcon.originalName ||
      showedIcons.filter((icon) => icon.name == editedIcon.name).length > 1
    ) {
      this.props.cancelEditedIconName(editedIcon.originalName);
    } else {
      this.props.updateEditedIconName(editedIcon.originalName, editedIcon.name, this.props.iconType);
    }
  };

  onFileDeleted(fileName) {
    const iconType = this.props.iconType;

    const onDeleteApproved = () => {
      this.props.deleteIcon(fileName, iconType);
    };

    openDeleteDialog(
      getMessage("iconGallery.deleteDialog.headline"),
      getMessage("iconGallery.deleteDialog.content"),
      onDeleteApproved
    );
  }

  onFileEdit(fileName) {
    this.props.setEditMode(fileName);
  }

  onFileSelected(files) {
    this.props.clearIconErrors();
    this.setState({
      totalUploads: files.length
    });
    for (let i = 0; i < files.length; i++) {
      const iconType = this.props.iconType;
      var file = files[i];
      this.props.uploadIcon(file, iconType);
    }
  }

  onClose = () => {
    this.props.clearIconErrors();
    this.props.onPopupClose();
  };

  fileUploadMessage = () => {
    if (this.props.showUploadErrorDialog) {
      let failObj = {
        showFileList: this.state.totalUploads > 1,
        failed: this.props.errors.length,
        total: this.state.totalUploads,
        failedDetails: this.props.errors.map((e) => e.name.concat(!!e.reason ? ` (${e.reason})` : "")).join(", ")
      };

      return getMessage("iconGallery.uploadResult.failMessage", failObj);
    } else {
      return getMessage("iconGallery.uploadResult.successMessage");
    }
  };

  renderSearchInput() {
    return (
      <div className="l-icons-gallery-search">
        {!this.props.showUploadErrorDialog && (
          <InputField
            value={this.state.searchValue}
            onChange={this.onSearchChange.bind(this)}
            placeholder={getMessage("iconGallery.searchInput.placeholder")}
            fixedWidth={true}
            postfixButtons={
              <Fragment>
                <a className="e-btn e-btn-onlyicon">
                  <e-icon icon="search" />
                </a>
                {this.renderFileUploader()}
              </Fragment>
            }
          />
        )}
        {this.props.showUploadErrorDialog && (
          <Fragment>
            <div className="l-alert-container" style={{ width: "645px", left: "0%", zIndex: "1" }}>
              <Alert message={this.fileUploadMessage()} status={ALERT_STATUS.ERROR} />
            </div>
            <div className="icon-upload ">{this.renderFileUploader()}</div>
          </Fragment>
        )}
      </div>
    );
  }

  renderFileUploader() {
    return (
      <FileUploader
        tooltip={getMessage("iconGallery.fileUploader.tooltip")}
        multiple={true}
        accept="image/png,image/svg+xml"
        onFileSelected={this.onFileSelected.bind(this)}
      ></FileUploader>
    );
  }

  getAllIcons() {
    let allIcons = this.props.customerIcons.map((icon) => {
      return { ...icon, showActions: true };
    });
    allIcons = allIcons.concat(
      this.props.icons.map((icon) => {
        return { ...icon, showActions: false };
      })
    );
    return allIcons;
  }

  renderIconsContent() {
    const showedIcons = this.getAllIcons();
    const icons =
      !this.state.searchValue || this.state.searchValue.length < 3
        ? showedIcons
        : showedIcons.filter((icon) => {
            return icon.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1;
          });

    if (!icons || icons.length === 0) {
      return (
        <EmptyState
          icon="search"
          title={getMessage("noResults.title")}
          text={getMessage("noResults.text", { text: this.state.searchValue })}
        />
      );
    }

    return icons.map((icon, index) => {
      return (
        <div id={index} key={index} className="e-card e-card-small e-card-has_overlay m-10">
          {this.renderCard(icon)}
        </div>
      );
    });
  }

  render() {
    const id = this.props.id || "iconsGalleryPopup";
    return (
      <PopupContainer
        id={id}
        title={getMessage("iconGallery.title")}
        show={this.props.show}
        width={"1080px"}
        height={"515px"}
        onClose={this.onClose.bind(this)}
        customHeader={this.renderSearchInput()}
        className="l-icons-gallery"
      >
        <div className="l-icons-gallery-content">
          <LoaderOverlay show={this.state.loading} />
          {this.renderIconsContent()}
        </div>
      </PopupContainer>
    );
  }
}

IconsGallery.propTypes = {
  loadPromise: PropTypes.func,
  id: PropTypes.string,
  show: PropTypes.bool.isRequired,
  iconPrefixUrl: PropTypes.string.isRequired,
  selectIconText: PropTypes.string,
  onSelectIcon: PropTypes.func.isRequired,
  onPopupClose: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
  return {
    icons: selectIcons(state),
    customerIcons: selectCustomerIcons(state),
    showUploadErrorDialog: selectShowUploadErrorDialog(state),
    errors: selectErrorFiles(state),
    editedIcon: selectEditedIcon(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadIcons: (loadPromis, customerLoadPromis, iconType) =>
      dispatch(loadIcons(loadPromis, customerLoadPromis, iconType)),
    uploadIcon: (file, iconType) => {
      dispatch(uploadIconFile(file, iconType));
    },
    deleteIcon: (file, iconType) => {
      dispatch(deleteIcon(file, iconType));
    },
    clearIconErrors: () => {
      dispatch(clearIconErrors());
    },
    setEditMode: (name) => {
      dispatch(setEditMode(name));
    },
    setEditName: (name, newName) => {
      dispatch(setEditName(name, newName));
    },
    updateEditedIconName: (name, newName, iconType) => {
      dispatch(updateEditedIconName(name, newName, iconType));
    },
    cancelEditedIconName: (name) => {
      dispatch(cancelEditedIconName(name));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IconsGallery);
