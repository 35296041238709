import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEqual from "lodash.isequal";
import map from "lodash/map";
import omit from "lodash/omit";

import MultiPlanSelect from "../components/MultiPlanSelect";

import ExternalEventSetup from "../views/ExternalEventSetup";
import { openDeleteDialog } from "../helpers/dialogHelper";

import { updateExternalEvent, deleteExternalEvent } from "../actions/externalEventsActions";

import { selectExternalEvents, selectActions, isAccountViewOnly } from "../reducers";

import actionService from "../services/actionService";

import "../assets/styles/ExternalLoyaltlyEvents.scss";
import { formatIsoTime } from "../helpers/timeHelper";

class ExternalLoyaltlyEvents extends Component {
  constructor() {
    super();
    this.state = {
      externalEventsList: {},
      externalEventToSetup: null,
      externalEventIdToDelete: null,
      isNewExternalEvent: true,
      showCreateOrEditPopup: false
    };
  }

  componentDidMount() {
    const self = this;
    this.setState({
      externalEventsList: this.props.externalEventsList
    });
    this.editActionElm.addEventListener("trigger", self.handleEditActionTrigger.bind(self));
    this.deleteActionElm.addEventListener("trigger", self.handleDeleteActionTrigger.bind(self));
  }

  componentDidUpdate(prevProps) {
    const { externalEventsList } = this.props;
    if (!isEqual(externalEventsList, prevProps.externalEventsList)) {
      this.setState({
        ...this.state,
        externalEventsList
      });
    }
  }

  getConnectedActionsNames(eventId) {
    const connectedActions = this.props.actions.filter(
      (action) => actionService.isSupportApiTriggers(action) && action.apiTriggers.includes(eventId)
    );
    return connectedActions.map((action) => action.name);
  }

  convertExternalLoyaltyEvents = () => {
    return map(this.state.externalEventsList, (externalEvent, id) => {
      const connectedActionsNames = this.getConnectedActionsNames(id);
      const isInUse = connectedActionsNames.length > 0;
      const tooltip =
        externalEvent.description.length > 0
          ? `<e-tooltip content="${externalEvent.description}" type="helper"></e-tooltip>`
          : "";

      return {
        id,
        name: `<span>${externalEvent.name}${tooltip}</span>`,
        status: externalEvent.status,
        created: formatIsoTime(externalEvent.createdAt),
        status: isInUse
          ? `<span>connected<e-tooltip content="<b>Connected to:</b> <br> ${connectedActionsNames.join(
              "<br>"
            )}" type="helper"></e-tooltip></span>`
          : "unconnected",
        disable: isInUse || this.props.isAccountViewOnly
      };
    });
  };

  handleEditActionTrigger(event) {
    this.onEditExternalEventClick(event.detail.data.id);
  }

  handleDeleteActionTrigger(event) {
    const externalEventIdToDelete = event.detail.data.id;

    this.setState({
      externalEventIdToDelete
    });
    this.renderDeletePopup(externalEventIdToDelete);
  }

  onAddNewExternalEventClick() {
    this.setState({
      showCreateOrEditPopup: true,
      isNewExternalEvent: true
    });
  }

  onEditExternalEventClick(externalEventId) {
    let { externalEventsList } = this.state;

    this.setState({
      showCreateOrEditPopup: true,
      externalEventToSetup: externalEventsList[externalEventId],
      isNewExternalEvent: false
    });
  }

  onUpdateExternalEvent(id, externalEvent) {
    let externalEventsList = this.state.externalEventsList;

    if (id) {
      externalEventsList[id] = externalEvent;
      this.setState({
        externalEventToSetup: null,
        externalEventsList: {
          ...externalEventsList
        }
      });
    }

    this.props.updateExternalEvent(externalEvent, id);
  }

  onCloseCreateOrEditPopup() {
    this.setState({
      externalEventToSetup: null,
      showCreateOrEditPopup: false
    });
  }

  onCloseDeletePopup() {
    const { externalEventIdToDelete, externalEventsList } = this.state;
    const newExternalEventsList = omit(externalEventsList, externalEventIdToDelete);

    this.setState({
      externalEventIdToDelete: null,
      externalEventsList: newExternalEventsList
    });

    this.props.deleteExternalEvent(externalEventIdToDelete);
  }

  renderHeader = () => (
    <header className="e-layout__header">
      <h1 class="e-layout__title">External Loyalty Events</h1>
      <div className="e-layout__actions">
        <MultiPlanSelect />
      </div>
    </header>
  );

  renderEboxHeader = () => (
    <div className="e-header">
      <div className="e-header__title">
        <div className="e-notice e-notice-withicon">
          <span className="e-notice__icon">
            <e-icon icon="e-info-circle" />
          </span>
          Here you create external loyalty events which can be linked to external triggers. You create the external
          event by simply giving it a name and description.
        </div>
      </div>
    </div>
  );

  renderGridSettings = () => (
    <React.Fragment>
      <e-datagrid-column head="External event ID" content-key="id" />
      <e-datagrid-column head="External event name" content-key="name" render-html />
      <e-datagrid-column head="Status" content-key="status" render-html />
      <e-datagrid-column-time head="Created" content-key="created" format="date" />

      <e-datagrid-item-action
        icon="pencil"
        tooltip="Edit"
        ref={(elem) => (this.editActionElm = elem)}
        disabled-key="disable"
        disabled-tooltip="You cannot edit this event because it is being used by a program."
      />
      <e-datagrid-item-action
        icon="trash-o"
        tooltip="Delete"
        ref={(elem) => (this.deleteActionElm = elem)}
        disabled-key="disable"
        disabled-tooltip="You cannot delete this event because it is being used by a program."
      />
    </React.Fragment>
  );

  renderDataGrid = (externalEventsList) => {
    const data = JSON.stringify(externalEventsList);
    return (
      <div className="e-datagrid-container">
        <e-datagrid data={data}>{this.renderGridSettings()}</e-datagrid>
        <button
          className="e-btn create-btn"
          onClick={this.onAddNewExternalEventClick.bind(this)}
          disabled={this.props.isAccountViewOnly}
        >
          Create External Events
        </button>
      </div>
    );
  };

  renderDeletePopup() {
    const { externalEventsList, externalEventIdToDelete } = this.state;
    const { name } = externalEventsList[externalEventIdToDelete];
    return openDeleteDialog(
      `Are you sure you want to delete this external event?`,
      `You are about to delete "${name}"`,
      this.onCloseDeletePopup.bind(this)
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderHeader()}
        <main class="e-layout__content">
          <section class="e-layout__section box-padding">
            {this.renderEboxHeader()}
            {this.renderDataGrid(this.convertExternalLoyaltyEvents())}
            <ExternalEventSetup
              externalEvent={this.state.externalEventToSetup}
              isShow={this.state.showCreateOrEditPopup}
              isNew={this.state.isNewExternalEvent}
              onUpdate={this.onUpdateExternalEvent.bind(this)}
              onClose={this.onCloseCreateOrEditPopup.bind(this)}
            />
          </section>
        </main>
      </Fragment>
    );
  }
}

ExternalLoyaltlyEvents.propTypes = {
  externalEventsList: PropTypes.shape({
    id: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      status: PropTypes.bool,
      created: PropTypes.instanceOf(Date)
    })
  }),
  updateExternalEvent: PropTypes.func,
  deleteExternalEvent: PropTypes.func
};

const mapStateToProps = (state) => ({
  externalEventsList: selectExternalEvents(state),
  actions: selectActions(state),
  isAccountViewOnly: isAccountViewOnly(state)
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateExternalEvent: (externalEvent, id) => {
      dispatch(updateExternalEvent(externalEvent, id));
    },
    deleteExternalEvent: (id) => {
      dispatch(deleteExternalEvent(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalLoyaltlyEvents);
