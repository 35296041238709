import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "../assets/styles/MultiSelectField.scss";

class MultiSelectField extends Component {
  componentDidMount() {
    const self = this;
    this.multiSelectElm.addEventListener("change", self.handleSelectChange.bind(self));
  }

  handleSelectChange(event) {
    const selectedOptions = event.detail.selectedOptions.map((option) => {
      return option.value;
    });

    this.props.onChange(event, selectedOptions);
  }

  render() {
    const { props } = this;

    let attr = {};
    if (props.disabled) {
      attr["disabled"] = true;
    }

    if (props.errorMsg) {
      attr["invalid"] = true;
    }

    return (
      <div className="e-field">
        <label className={`e-field__label ${props.inline && "e-field__label-inline"}`}>
          {props.label}

          {props.tooltip ? <e-tooltip content={props.tooltip} type="helper" /> : null}
        </label>
        <e-multiselect
          class={classNames({ "disable-remove-value": props.disableRemoveValue })}
          name={props.name}
          ref={(elem) => (this.multiSelectElm = elem)}
          placeholder={props.placeholder}
          {...attr}
        >
          {props.options.map((opt) => {
            let name,
              value = "";
            if (typeof opt === "object") {
              value = opt.value;
              name = opt.name;
            } else {
              value = name = opt;
            }

            let attr = {};
            if (props.value && props.value.includes(value)) {
              attr["selected"] = true;
            }

            if (props.disabledValues && props.disabledValues.includes(value)) {
              attr["disabled"] = true;
            }

            return (
              <e-select-option key={value} value={value} {...attr}>
                {name}
              </e-select-option>
            );
          })}
        </e-multiselect>
        {props.errorMsg ? <span className="e-field__message e-field__message-error">{props.errorMsg}</span> : null}
      </div>
    );
  }
}

MultiSelectField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.array,
  disabledValues: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  inline: PropTypes.bool,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  errorMsg: PropTypes.string,
  disableRemoveValue: PropTypes.bool
};

export default MultiSelectField;
