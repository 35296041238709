import some from "lodash/some";
import cloneDeep from "lodash/cloneDeep";

import tierService from "./tierService";
import actionService from "./actionService";
import benefitService from "./benefitService";

import { convertObjectToOptionsArray } from "../helpers/formHelper";

import {
  BLOCKING_EDITOR_MODE_ENUM,
  EXCLUSIVE_ACCESS_EXCLUSIVITY_TYPE_ENUM,
  EDITOR_CONTENT_TEXTS_DEFAULT_VALUES,
  EDITOR_CONTENT_TEXTS_DEFAULT_VALUES_MOBILE,
  EDITOR_LINKS_DEFAULT_VALUES,
  VALIDITY_TYPE_ENUM
} from "../constants/rewardConstants";

import {
  MASTER_EXCLUSIVE_ACCESS_ENUM,
  EXCLUSIVE_ACCESS_OFFERS_ENUM,
  ACCESS_VALIDITY_TYPE_OPTIONS,
  EXCLUSIVE_ACCESS_STATUS_ENUM
} from "../constants/exclusiveAccessConstants";
import moment from "moment";
import { getMessage } from "../messages";

export default class exclusiveAccessService {
  static getExclusiveAccessTitle = (exclusiveAccess) => {
    return `${exclusiveAccess.name} / ${exclusiveAccess.accessName}`;
  };

  static getAccessName = (exclusiveAccess) => (exclusiveAccess ? exclusiveAccess.accessName : "");

  static getOptionsForSelect = (rewardsById, filterFunc) => {
    let validRewards = {};
    Object.keys(rewardsById)
      .filter((id) => rewardsById[id].isValid)
      .filter(filterFunc)
      .forEach((id) => (validRewards[id] = rewardsById));

    return convertObjectToOptionsArray(validRewards, (rewardId) => {
      const reward = rewardsById[rewardId];
      return exclusiveAccessService.getExclusiveAccessTitle(reward);
    });
  };

  static getExclusiveAccessBenefitsOptions = (rewardsById, targetMarket, offerType) => {
    const rewardFilter = (rewardId) => {
      const reward = rewardsById[rewardId];
      if (
        (targetMarket && reward.market !== targetMarket) ||
        reward.masterExclusiveAccess === EXCLUSIVE_ACCESS_EXCLUSIVITY_TYPE_ENUM.LIMITED ||
        !reward.validity.accessValidity[offerType].hasOwnProperty("accessValidityType") ||
        reward.validity.accessValidity[offerType].accessValidityType === VALIDITY_TYPE_ENUM.DISABLED ||
        !reward.validity.isActive ||
        (reward.validity.accessValidity[offerType].accessValidityType === VALIDITY_TYPE_ENUM.DATE_RANGE &&
          moment().isAfter(reward.validity.accessValidity[offerType].accessDateRange.end))
      ) {
        return false;
      }
      return reward.isValid;
    };
    return exclusiveAccessService.getOptionsForSelect(rewardsById, rewardFilter);
  };

  static getExclusiveAccessRewardOptions = (rewardsById, targetMarket, offerType) => {
    const rewardFilter = (rewardId) => {
      const reward = rewardsById[rewardId];
      if (
        (targetMarket && reward.market !== targetMarket) ||
        (reward.masterExclusiveAccess === EXCLUSIVE_ACCESS_EXCLUSIVITY_TYPE_ENUM.LIMITED &&
          !reward.validity.accessValidity[offerType].showOnAction) ||
        (reward.masterExclusiveAccess != EXCLUSIVE_ACCESS_EXCLUSIVITY_TYPE_ENUM.LIMITED &&
          (!reward.validity.accessValidity[offerType].hasOwnProperty("accessValidityType") ||
            reward.validity.accessValidity[offerType].accessValidityType === VALIDITY_TYPE_ENUM.DISABLED ||
            !reward.validity.isActive ||
            (reward.validity.accessValidity[offerType].accessValidityType === VALIDITY_TYPE_ENUM.DATE_RANGE &&
              moment().isAfter(reward.validity.accessValidity[offerType].accessDateRange.end))))
      ) {
        return false;
      }
      return reward.isValid;
    };
    return exclusiveAccessService.getOptionsForSelect(rewardsById, rewardFilter);
  };
  /* 
validity: {
        accessValidity: {
          action: {
            accessValidityType: "",
            accessValidity: {
              type: "days",
              duration: undefined
            },
            accessDateRange: {
              start: null,
              end: null
            },
            isEnabled
*/

  static getExclusiveAccessStatus = (exclusiveAccess, actions, fixedBenefits, additionalBenefits) => {
    const connectedFixedBenefitNames = tierService.getBenefitsConnectedToReward(exclusiveAccess.id, fixedBenefits);
    const connectedActionNames = actionService.getActionsConnectedToReward(exclusiveAccess.id, actions);
    const connectedAdditionalBenefitsNames = benefitService.getAdditionalBenefitsConnectedToReward(
      exclusiveAccess.id,
      additionalBenefits
    );

    const redemptionOn =
      exclusiveAccess.redemption && exclusiveAccessService.isRedemptionOn(exclusiveAccess.redemption.value);

    const redemptionForFreeOn =
      exclusiveAccess.redemptionForFree &&
      exclusiveAccessService.isRedemptionOn(exclusiveAccess.redemptionForFree.value);

    if (!exclusiveAccess.isValid) {
      if (
        exclusiveAccessService.isLimitedExclusiveAccess(exclusiveAccess.masterExclusiveAccess) &&
        exclusiveAccess.validity?.blockingValidity
      ) {
        if (moment(exclusiveAccess.validity.blockingValidity.end) < moment()) {
          return {
            status: EXCLUSIVE_ACCESS_STATUS_ENUM.EXPIRED,
            redemptionOn,
            connectedActionNames,
            connectedFixedBenefitNames,
            connectedAdditionalBenefitsNames,
            redemptionForFreeOn
          };
        }
      }

      return {
        status: EXCLUSIVE_ACCESS_STATUS_ENUM.INACTIVE,
        redemptionOn,
        connectedActionNames,
        connectedFixedBenefitNames,
        connectedAdditionalBenefitsNames,
        redemptionForFreeOn
      };
    }

    if (!exclusiveAccess.validity.isActive) {
      return {
        status: EXCLUSIVE_ACCESS_STATUS_ENUM.DEACTIVATED,
        redemptionOn,
        connectedActionNames,
        connectedFixedBenefitNames,
        connectedAdditionalBenefitsNames,
        redemptionForFreeOn
      };
    } else {
      if (moment(exclusiveAccess.validity.blockingValidity.start) > moment()) {
        return {
          status: EXCLUSIVE_ACCESS_STATUS_ENUM.SCHEDULED,
          redemptionOn,
          connectedActionNames,
          connectedFixedBenefitNames,
          connectedAdditionalBenefitsNames,
          redemptionForFreeOn
        };
      }

      return {
        status: EXCLUSIVE_ACCESS_STATUS_ENUM.ACTIVE,
        redemptionOn,
        connectedActionNames,
        connectedFixedBenefitNames,
        connectedAdditionalBenefitsNames,
        redemptionForFreeOn
      };
    }
  };

  static getExclusiveAccessOfferTypeStatus = (
    exclusiveAccess,
    offerType,
    connectedActionNames,
    connectedFixedBenefitNames,
    connectedAdditionalBenefitsNames
  ) => {
    const redemptionOn =
      exclusiveAccess.hasOwnProperty(offerType) &&
      exclusiveAccessService.isRedemptionOn(exclusiveAccess[offerType].value);

    const validityTypeSelected =
      !exclusiveAccessService.isLimitedExclusiveAccess(exclusiveAccess.masterExclusiveAccess) &&
      !!exclusiveAccess.validity?.accessValidity[offerType]?.accessValidityType &&
      exclusiveAccess.validity.accessValidity[offerType].accessValidityType != VALIDITY_TYPE_ENUM.DISABLED;
    const ShowOnAction =
      offerType === EXCLUSIVE_ACCESS_OFFERS_ENUM.AS_REWARD &&
      exclusiveAccessService.isLimitedExclusiveAccess(exclusiveAccess.masterExclusiveAccess) &&
      exclusiveAccess.validity?.accessValidity?.action?.showOnAction;

    if (validityTypeSelected || redemptionOn || ShowOnAction) {
      if (
        exclusiveAccessService.isLimitedExclusiveAccess(exclusiveAccess.masterExclusiveAccess) &&
        exclusiveAccess.validity?.blockingValidity
      ) {
        if (moment(exclusiveAccess.validity.blockingValidity.end) < moment()) {
          return EXCLUSIVE_ACCESS_STATUS_ENUM.EXPIRED;
        }
      }

      if (
        exclusiveAccess.validity.accessValidity[offerType].accessDateRange?.end &&
        moment(exclusiveAccess.validity.accessValidity[offerType].accessDateRange.end) < moment()
      ) {
        return EXCLUSIVE_ACCESS_STATUS_ENUM.EXPIRED;
      } else {
        if (offerType === EXCLUSIVE_ACCESS_OFFERS_ENUM.AS_REWARD) {
          if (connectedActionNames.length > 0) {
            return EXCLUSIVE_ACCESS_STATUS_ENUM.ACTIVE;
          } else return EXCLUSIVE_ACCESS_STATUS_ENUM.READYTOUSE;
        }

        if (offerType === EXCLUSIVE_ACCESS_OFFERS_ENUM.AS_FIXEDBENEFIT) {
          if (connectedFixedBenefitNames.length > 0) {
            return EXCLUSIVE_ACCESS_STATUS_ENUM.ACTIVE;
          } else return EXCLUSIVE_ACCESS_STATUS_ENUM.READYTOUSE;
        }

        if (offerType === EXCLUSIVE_ACCESS_OFFERS_ENUM.AS_ADDITIONALBENEFITS) {
          if (connectedAdditionalBenefitsNames.length > 0) {
            return EXCLUSIVE_ACCESS_STATUS_ENUM.ACTIVE;
          } else return EXCLUSIVE_ACCESS_STATUS_ENUM.READYTOUSE;
        }

        if (!redemptionOn) return EXCLUSIVE_ACCESS_STATUS_ENUM.INACTIVE;

        return EXCLUSIVE_ACCESS_STATUS_ENUM.ACTIVE;
      }
    } else return EXCLUSIVE_ACCESS_STATUS_ENUM.INACTIVE;
  };

  static getDefaultIcon = () => {
    return { displayName: "Online store colors", fileName: "online-store-colors" };
  };

  static getCardExtraLabels = (reward) => {
    let extraLabels = [];

    if (reward.exclusiveAccessValidity && reward.exclusiveAccessValidity.exclusivityType) {
      if (reward.masterExclusiveAccess === EXCLUSIVE_ACCESS_EXCLUSIVITY_TYPE_ENUM.PERMANENT) {
        extraLabels.push({
          type: "poolType",
          text: getMessage("exclusiveAccess.cardLabels.permanent")
        });
      }
      if (reward.masterExclusiveAccess === EXCLUSIVE_ACCESS_EXCLUSIVITY_TYPE_ENUM.LIMITED) {
        extraLabels.push({
          type: "poolType",
          text: getMessage("exclusiveAccess.cardLabels.limtedTerm")
        });
      }
    }

    return extraLabels;
  };

  static isLimitedExclusiveAccess = (masterReward) => {
    return masterReward === MASTER_EXCLUSIVE_ACCESS_ENUM.LIMITED;
  };

  static isPermanentExclusiveAccess = (masterReward) => {
    return masterReward === MASTER_EXCLUSIVE_ACCESS_ENUM.PERMANENT;
  };

  static isRedemptionOn = (redemptionValues) => {
    return some(redemptionValues, { pointsEnabled: true });
  };

  static isMemberMode = (mode) => {
    return mode === BLOCKING_EDITOR_MODE_ENUM.MEMBER_MOBILE || mode === BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER_MOBILE;
  };

  static hexToRgb = (hex) => {
    "use strict";
    if (hex.charAt(0) === "#") {
      hex = hex.substr(1);
    }
    if (hex.length < 2 || hex.length > 6) {
      return false;
    }
    var values = hex.split(""),
      r,
      g,
      b;

    if (hex.length === 2) {
      r = parseInt(values[0].toString() + values[1].toString(), 16);
      g = r;
      b = r;
    } else if (hex.length === 3) {
      r = parseInt(values[0].toString() + values[0].toString(), 16);
      g = parseInt(values[1].toString() + values[1].toString(), 16);
      b = parseInt(values[2].toString() + values[2].toString(), 16);
    } else if (hex.length === 6) {
      r = parseInt(values[0].toString() + values[1].toString(), 16);
      g = parseInt(values[2].toString() + values[3].toString(), 16);
      b = parseInt(values[4].toString() + values[5].toString(), 16);
    } else {
      return false;
    }
    return [r, g, b];
  };

  static getOptionsWithSelected = ({ allOptions, validOptions, selectedValue }) => {
    const exists = validOptions.some((r) => r.value === selectedValue);
    if (!exists) {
      const index = allOptions.findIndex((r) => r.value === selectedValue);
      if (index > -1) {
        let option = allOptions[index];
        option.disabled = true;
        validOptions.unshift(option);
      }
    }
    return validOptions;
  };

  static hasRedemptionValue(reward) {
    const redemptionValue = reward.redemption.value;
    if (!!redemptionValue) {
      return Object.keys(redemptionValue).some((tierId) => !!redemptionValue[tierId].pointsEnabled);
    }

    return false;
  }

  static hasEnabledTier(exclusiveAccessRedemtion) {
    if (!!exclusiveAccessRedemtion.value) {
      //return exclusiveAccessRedemtion.value.reduce((acc,next)=>{if(acc===true||next.pointsEnabled===true) return true; return false;})
      return Object.keys(exclusiveAccessRedemtion.value).some(
        (tierId) => !!exclusiveAccessRedemtion.value[tierId].pointsEnabled
      );
    }
    return false;
  }

  static getDefaultExclusiveContentText = () => {
    return {
      [BLOCKING_EDITOR_MODE_ENUM.GENERAL]: {
        texts: { ...EDITOR_CONTENT_TEXTS_DEFAULT_VALUES }
      },
      [BLOCKING_EDITOR_MODE_ENUM.GENERAL_MOBILE]: {
        texts: { ...EDITOR_CONTENT_TEXTS_DEFAULT_VALUES }
      },
      [BLOCKING_EDITOR_MODE_ENUM.MEMBER]: {
        texts: { ...EDITOR_CONTENT_TEXTS_DEFAULT_VALUES }
      },
      [BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER]: {
        texts: { ...EDITOR_CONTENT_TEXTS_DEFAULT_VALUES }
      },
      [BLOCKING_EDITOR_MODE_ENUM.MEMBER_MOBILE]: {
        texts: { ...EDITOR_CONTENT_TEXTS_DEFAULT_VALUES_MOBILE }
      },
      [BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER_MOBILE]: {
        texts: { ...EDITOR_CONTENT_TEXTS_DEFAULT_VALUES_MOBILE }
      }
    };
  };

  static getDefaultExclusiveContentLinks = () => {
    return {
      [BLOCKING_EDITOR_MODE_ENUM.GENERAL]: cloneDeep(EDITOR_LINKS_DEFAULT_VALUES),
      [BLOCKING_EDITOR_MODE_ENUM.GENERAL_MOBILE]: cloneDeep(EDITOR_LINKS_DEFAULT_VALUES),
      [BLOCKING_EDITOR_MODE_ENUM.MEMBER]: cloneDeep(EDITOR_LINKS_DEFAULT_VALUES),
      [BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER]: cloneDeep(EDITOR_LINKS_DEFAULT_VALUES),
      [BLOCKING_EDITOR_MODE_ENUM.MEMBER_MOBILE]: cloneDeep(EDITOR_LINKS_DEFAULT_VALUES),
      [BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER_MOBILE]: cloneDeep(EDITOR_LINKS_DEFAULT_VALUES)
    };
  };

  static getNewExclusiveAccessText = (masterExclusiveAccess) => {
    if (masterExclusiveAccess === MASTER_EXCLUSIVE_ACCESS_ENUM.PERMANENT)
      return getMessage("exclusiveAccessExclusivityType.options.name.fixed");
    if (masterExclusiveAccess === MASTER_EXCLUSIVE_ACCESS_ENUM.LIMITED)
      return getMessage("exclusiveAccessExclusivityType.options.name.limited");
  };

  static getDefaultBlockingData = () => {
    return {
      exactAccessUrl: "",
      startsWithAccessUrl: "",
      isStartsWith: false,
      landingPageUrl: ""
    };
  };

  static getDefaultHtmlFilesObject = () => {
    return {
      member: "",
      nonMember: "",
      memberMobile: "",
      nonMemberMobile: ""
    };
  };

  static getAccessValidityTypeOptions(
    offer,
    connectedFixedBenefitNames,
    connectedActionNames,
    connectedAdditionalBenefitsNames
  ) {
    let result = [...ACCESS_VALIDITY_TYPE_OPTIONS];

    if (offer === EXCLUSIVE_ACCESS_OFFERS_ENUM.AS_FIXEDBENEFIT && connectedFixedBenefitNames.length === 0) {
      result.push({
        name: getMessage("accessValidityType.options.disabled.fixedBenefit.name"),
        value: VALIDITY_TYPE_ENUM.DISABLED
      });
    } else if (
      offer === EXCLUSIVE_ACCESS_OFFERS_ENUM.AS_ADDITIONALBENEFITS &&
      connectedAdditionalBenefitsNames.length === 0
    ) {
      result.push({
        name: getMessage("accessValidityType.options.disabled.additionalBenefit.name"),
        value: VALIDITY_TYPE_ENUM.DISABLED
      });
    } else if (offer === EXCLUSIVE_ACCESS_OFFERS_ENUM.AS_REWARD && connectedActionNames.length === 0) {
      result.push({
        name: getMessage("accessValidityType.options.disabled.reward.name"),
        value: VALIDITY_TYPE_ENUM.DISABLED
      });
    }
    return result;
  }

  static getAccessValidityTooltip(
    offer,
    connectedFixedBenefitNames,
    connectedActionNames,
    connectedAdditionalBenefitsNames
  ) {
    if (
      (offer === EXCLUSIVE_ACCESS_OFFERS_ENUM.AS_FIXEDBENEFIT && connectedFixedBenefitNames.length > 0) ||
      (offer === EXCLUSIVE_ACCESS_OFFERS_ENUM.AS_ADDITIONALBENEFITS && connectedAdditionalBenefitsNames.length > 0) ||
      (offer === EXCLUSIVE_ACCESS_OFFERS_ENUM.AS_REWARD && connectedActionNames.length > 0)
    ) {
      return getMessage("exclusiveAccessAndOffer.accessValidity.tooltip.connected");
    }
    return getMessage("exclusiveAccessAndOffer.accessValidity.tooltip");
  }
}
