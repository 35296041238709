import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import deepEqual from "deep-equal";
import classNames from "classnames";
import cloneDeep from "lodash/cloneDeep";

import Notification from "../components/Notification";
import SelectField from "../components/SelectFieldV2";
import InputField from "../components/InputField";

import { CURRENCY_OPTIONS } from "../constants/currencyConstants";
import { LANGUAGE_OPTIONS } from "../constants/languageConstants";
import { COUNTRY_OPTIONS } from "../constants/marketConstants";

import { openDeleteDialog } from "../helpers/dialogHelper";

import { updateLocaleSettings } from "../actions/planActions";
import { hideErrorAlert } from "../actions/alertActions";

import LanguageAndCurrencyValidator from "../validators/languageAndCurrencyValidator";

import planService from "../services/planService";

import {
  selectPlanLanguageSettings,
  selectPlanStoreUrlSettings,
  selectPlanCurrencySettings,
  selectPlanMarketSettings,
  selectShowCurrentPlan,
  selectPlan,
  selectCurrentPlanVersion,
  isAccountViewOnly
} from "../reducers";

import "../assets/styles/LanguageAndCurrency.scss";
import customerService from "../services/customerService";
import { getMessage } from "../messages";
import { LANGUAGE_AND_CURRENCY_HELP_LINK, MARKETS_HELP_LINK } from "../constants/linkConstants";

class LanguageAndCurrency extends Component {
  constructor(props) {
    super(props);

    this.validator = new LanguageAndCurrencyValidator();

    this.statePropTextMap = {
      language: "language",
      currency: "currency",
      market: "market"
    };

    this.state = {
      language: {
        primary: "",
        additional: []
      },
      storeUrl: {
        primary: "",
        additional: []
      },
      currency: {
        primary: "",
        additional: []
      },
      market: {
        primary: "",
        additional: []
      },
      plan: cloneDeep(props.plan),
      errors: {
        language: { primary: "", additional: [] },
        storeUrl: { primary: "", additional: [] },
        currency: { primary: "", additional: [] },
        market: { primary: "", additional: [] }
      }
    };
  }

  get viewMode() {
    return this.props.showCurrentVersion || this.props.isAccountViewOnly;
  }

  get hideAdditionalCurrencies() {
    return planService.isSpendPlan(this.props.plan);
  }

  componentDidMount() {
    this.props.registerSubmitFunc && this.props.registerSubmitFunc(this.onSubmit.bind(this));
    this.props.registerValidateFunc && this.props.registerValidateFunc(this.validate.bind(this));
    this.setInitialState();
  }

  componentDidUpdate(prevProps) {
    if (
      !deepEqual(prevProps.languageSettings, this.props.languageSettings) ||
      !deepEqual(prevProps.storeUrlSettings, this.props.storeUrlSettings) ||
      !deepEqual(prevProps.currencySettings, this.props.currencySettings) ||
      !deepEqual(prevProps.marketSettings, this.props.marketSettings)
    ) {
      this.setInitialState();
    }
  }

  componentWillUnmount() {
    this.props.registerSubmitFunc && this.props.registerSubmitFunc(null);
    this.props.registerValidateFunc && this.props.registerValidateFunc(null);
  }

  setInitialState() {
    let language = { ...this.props.languageSettings };
    let storeUrl = { ...this.props.storeUrlSettings };
    let currency = { ...this.props.currencySettings };
    let market = { ...this.props.marketSettings };

    language.additional = language.additional || [];
    storeUrl.additional = storeUrl.additional || [];
    currency.additional = currency.additional || [];
    market.additional = market.additional || [];

    this.setState({
      language,
      storeUrl,
      currency,
      market
    });
  }

  onAdd({ stateProp, stateObj }) {
    const additional = [...stateObj.additional];
    additional.push("");
    this.setState({
      [stateProp]: { ...stateObj, additional }
    });
  }

  onDelete({ stateProp, stateProp2, stateObj, stateObj2, index }) {
    const currentValue = stateObj.additional[index];
    const doDelete = () => {
      let additional = [...stateObj.additional];
      additional.splice(index, 1);
      this.setState({
        [stateProp]: { ...stateObj, additional }
      });

      if (stateProp2 && stateObj2) {
        additional = [...stateObj2.additional];
        additional.splice(index, 1);
        this.setState({
          [stateProp2]: { ...stateObj2, additional }
        });
      }
    };

    if (!currentValue) {
      doDelete();
      return;
    }

    const entityName = this.statePropTextMap[stateProp];
    const title = getMessage("languageAndCurrency.deleteDialog.header." + entityName);
    const text = getMessage("languageAndCurrency.deleteDialog.content." + entityName);
    openDeleteDialog(title, text, doDelete);
  }

  onPrimaryChange({ event, stateProp, stateObj }) {
    const value = event.target.value;
    stateObj.primary = value;

    let { errors } = { ...this.state };

    //validate on change if currently there's an error
    if (errors[stateProp].primary) {
      errors[stateProp].primary = this.validateSelect({ stateProp, value });
    }

    this.setState({
      [stateProp]: { ...stateObj },
      errors
    });

    this.validateErrorState(errors);
  }

  onAdditionalChange({ event, stateProp, stateObj, index }) {
    const value = event.target.value;
    const additional = [...stateObj.additional];
    additional[index] = value;

    let { errors } = { ...this.state };
    const error = this.validateSelect({ stateProp, value });

    //validate on change if currently there's an error
    if (error && errors[stateProp].additional[index]) {
      errors[stateProp].additional[index] = error;
    } else {
      delete errors[stateProp].additional[index];
    }

    this.setState({
      [stateProp]: { ...stateObj, additional },
      errors
    });

    this.validateErrorState(errors);
  }

  validate() {
    const { language, storeUrl, currency, market } = { ...this.state };

    let errors = {
      language: { primary: "", additional: [] },
      storeUrl: { primary: "", additional: [] },
      currency: { primary: "", additional: [] },
      market: { primary: "", additional: [] }
    };

    errors.language.primary = this.validateSelect({ stateProp: "language", value: language.primary });
    errors.storeUrl.primary = this.validateSelect({ stateProp: "storeUrl", value: storeUrl.primary });
    errors.currency.primary = this.validateSelect({ stateProp: "currency", value: currency.primary });

    language.additional.forEach((value, index) => {
      const error = this.validateSelect({ stateProp: "language", value });
      if (error) {
        errors["language"].additional[index] = error;
      }
    });
    storeUrl.additional.forEach((value, index) => {
      const error = this.validateSelect({ stateProp: "storeUrl", value });
      if (error) {
        errors["storeUrl"].additional[index] = error;
      }
    });
    currency.additional.forEach((value, index) => {
      const error = this.validateSelect({ stateProp: "currency", value });
      if (error) {
        errors["currency"].additional[index] = error;
      }
    });
    market.additional.forEach((value, index) => {
      const error = this.validateSelect({ stateProp: "market", value });
      if (error) {
        errors["market"].additional[index] = error;
      }
    });

    this.setState({
      errors
    });

    const isValid = this.validateErrorState(errors);
    return isValid;
  }

  validateErrorState(errors) {
    const isValid = Object.keys(errors).every((key) => {
      return !errors[key].primary && errors[key].additional.length === 0;
    });

    if (!isValid) {
      this.props.hideErrorAlert();
    }
    return isValid;
  }

  onSubmit() {
    let { language, storeUrl, currency, market } = { ...this.state };

    if (this.validate()) {
      language.additional = language.additional.filter((value) => !!value);
      storeUrl.additional = storeUrl.additional.filter((value) => !!value);
      currency.additional = currency.additional.filter((value) => !!value);
      market.additional = market.additional.filter((value) => !!value);
      this.props.updateLocaleSettings({
        language,
        storeUrl,
        currency,
        market,
        refreshTiers: !this.props.fromOnboarding
      });
    }
  }

  onCancel() {
    this.setInitialState();
  }

  validateSelect({ stateProp, value }) {
    const errors = this.validator.validateField({ [stateProp]: value || null }, stateProp); //pass null as value in order to allow optional URL
    return errors ? errors[stateProp][0] : undefined;
  }

  renderSectionHeader(text) {
    return <h2>{text}</h2>;
  }

  renderSelect({ value, label, options, placeholder, onChange, onDelete, errorMsg, disabled, isRequired }) {
    return (
      <SelectField
        label={label || null}
        options={options}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        errorMsg={errorMsg}
        disabled={this.viewMode || disabled}
        postfixButtons={
          !!onDelete &&
          !this.viewMode && (
            <a className="e-btn e-btn-onlyicon no-border" onClick={onDelete}>
              <e-icon icon="trash-o" size="small" />
            </a>
          )
        }
        isRequired={!!isRequired}
      />
    );
  }

  renderInputField({ value, label, placeholder, onChange, onDelete, errorMsg, disabled }) {
    return (
      <InputField
        key={label}
        label={label || null}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        errorMsg={errorMsg}
        disabled={this.viewMode || disabled}
        postfixButtons={
          !!onDelete &&
          !this.viewMode && (
            <a className="e-btn e-btn-onlyicon no-border" onClick={onDelete}>
              <e-icon icon="trash-o" size="small" />
            </a>
          )
        }
      />
    );
  }

  filterSelectOptions({ value, options, primary, additional }) {
    return options.filter((opt) => {
      return (primary !== opt.value && !additional.includes(opt.value)) || opt.value === value;
    });
  }

  renderLanguageSelect({ value, value2, onChange, onChange2, onDelete, showLabel, errorMsg, errorMsg2, key }) {
    const { primary, additional } = { ...this.state.language };
    // const { primary, additional } = { ...this.state.storeUrl };
    const options = this.filterSelectOptions({ value, options: LANGUAGE_OPTIONS, primary, additional });

    const languageSelect = this.renderSelect({
      value,
      label: showLabel ? getMessage("languageAndCurrency.languageSelect.label") : "",
      options,
      placeholder: getMessage("languageAndCurrency.languageSelect.placeholder"),
      onChange,
      errorMsg,
      isRequired: true
    });

    return (
      <div className="e-grid e-grid-small" key={key}>
        <div className="e-cell e-cell-small">{languageSelect}</div>
        <div className="e-cell e-cell-small store-url-field">
          <InputField
            label={showLabel ? getMessage("languageAndCurrency.storeUrl.label") : ""}
            value={value2}
            placeholder={getMessage("languageAndCurrency.storeUrl.placeholder")}
            onChange={onChange2}
            errorMsg={errorMsg2}
            tooltip={getMessage("languageAndCurrency.storeUrl.tooltip")}
            postfixButtons={
              !!onDelete &&
              !this.viewMode && (
                <a className="e-btn e-btn-onlyicon no-border" onClick={onDelete}>
                  <e-icon icon="trash-o" size="small" />
                </a>
              )
            }
          />
        </div>
      </div>
    );
  }

  renderCurrencySelect({ value, onChange, onDelete, showLabel, errorMsg, disabled }) {
    const { primary, additional } = { ...this.state.currency };
    const options = this.filterSelectOptions({ value, options: CURRENCY_OPTIONS, primary, additional });

    return this.renderSelect({
      value,
      label: showLabel ? getMessage("languageAndCurrency.currencySelect.label") : "",
      options,
      placeholder: getMessage("languageAndCurrency.currencySelect.placeholder"),
      onChange,
      onDelete,
      errorMsg,
      disabled,
      isRequired: true
    });
  }

  renderMarketSelect({ value, onChange, onDelete, showLabel, errorMsg }) {
    const { primary, additional } = { ...this.state.market };
    // const options = this.filterSelectOptions({ value, options: COUNTRY_OPTIONS, primary, additional });

    return this.renderInputField({
      value,
      label: showLabel ? getMessage("languageAndCurrency.marketSelect.label") : "",
      placeholder: getMessage("languageAndCurrency.marketSelect.placeholder"),
      onChange,
      onDelete,
      errorMsg
    });
  }

  renderAdditionalSelects({ stateProp, stateProp2, stateObj, stateObj2, renderSelect }) {
    return stateObj.additional.map((v, index) => {
      return renderSelect({
        value: v,
        value2: stateObj2 ? stateObj2.additional[index] : "",
        onChange: (event) => this.onAdditionalChange({ event, stateProp, stateObj, index }),
        onChange2: (event) => this.onAdditionalChange({ event, stateProp: stateProp2, stateObj: stateObj2, index }),
        showLabel: index === 0,
        onDelete: () => this.onDelete({ stateProp, stateProp2, stateObj, stateObj2, index }),
        errorMsg: this.state.errors[stateProp].additional[index] || "",
        errorMsg2: this.state.errors[stateProp2]?.additional[index] || ""
      });
    });
  }

  renderAddButton(stateProp, stateObj, addButtonText) {
    let attr = {};
    if (this.viewMode) {
      attr["disabled"] = true;
    }

    return (
      <button
        type="button"
        onClick={() => this.onAdd({ stateProp, stateObj })}
        className="e-btn e-btn-fullwidth add-new-button"
        {...attr}
      >
        {addButtonText}
      </button>
    );
  }

  renderFooterButtons() {
    let attr = {};
    if (this.viewMode) {
      attr["disabled"] = true;
    }

    return (
      <div className="e-buttongroup">
        <button type="button" className="e-btn" onClick={this.onCancel.bind(this)} {...attr}>
          {getMessage("cancelBtn.label")}
        </button>
        <button className="e-btn e-btn-primary" type="submit" onClick={this.onSubmit.bind(this)} {...attr}>
          {getMessage("saveBtn.label")}
        </button>
      </div>
    );
  }

  renderSection({
    primaryHeader,
    additionalHeader,
    stateProp,
    stateObj,
    renderSelect,
    addButtonText,
    stateProp2,
    stateObj2,
    disablePrimary,
    hideAdditional,
    key
  }) {
    return (
      <Fragment>
        <div className="primary-section" key="primary">
          {this.renderSectionHeader(primaryHeader)}
          {renderSelect({
            value: stateObj.primary,
            value2: stateObj2?.primary,
            onChange: (event) => this.onPrimaryChange({ event, stateProp, stateObj }),
            onChange2: (event) => this.onPrimaryChange({ event, stateProp: stateProp2, stateObj: stateObj2 }),
            showLabel: true,
            errorMsg: this.state.errors[stateProp].primary,
            errorMsg2: stateProp2 ? this.state.errors[stateProp2].primary : undefined,
            disabled: disablePrimary,
            key
          })}
        </div>
        {!hideAdditional && (
          <div className={`additional-section additional-section_${stateProp} p-t-10 p-b-30`}>
            {this.renderSectionHeader(additionalHeader)}
            {this.renderAdditionalSelects({ stateProp, stateProp2, stateObj, stateObj2, renderSelect })}
            {this.renderAddButton(stateProp, stateObj, addButtonText)}
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    const className = classNames("language-and-currency box-padding", {
      "view-mode": this.viewMode
    });

    return (
      <div className={className}>
        <Notification
          content={getMessage("languageAndCurrency.notification", {
            learnMoreLink: (
              <a target={"_blank"} href={LANGUAGE_AND_CURRENCY_HELP_LINK}>
                {getMessage("languageAndCurrency.notification.learnMoreLinkText")}
              </a>
            ),
            marketsLink: (
              <a target={"_blank"} href={MARKETS_HELP_LINK}>
                {getMessage("languageAndCurrency.notification.marketsLinkText")}
              </a>
            )
          })}
        />
        <div className="e-row">
          <div className="e-col-6 section section_language">
            {this.renderSection({
              primaryHeader: (
                <Fragment>
                  {" "}
                  {getMessage("languageAndCurrency.primaryLanguage.title")}
                  <e-tooltip content={getMessage("languageAndCurrency.primaryLanguage.tooltip")} type="helper" />
                </Fragment>
              ),
              additionalHeader: getMessage("languageAndCurrency.additionalLanguage.title"),
              stateProp: "language",
              stateObj: this.state.language,
              renderSelect: this.renderLanguageSelect.bind(this),
              addButtonText: getMessage("languageAndCurrency.additionalLanguage.addButtonText"),
              stateProp2: "storeUrl",
              stateObj2: this.state.storeUrl,
              key: "section_language"
            })}
          </div>
          <div className="e-col-3 section section_currency">
            {this.renderSection({
              primaryHeader: (
                <Fragment>
                  {" "}
                  {getMessage("languageAndCurrency.primaryCurrency.title")}
                  <e-tooltip content={getMessage("languageAndCurrency.primaryCurrency.tooltip")} type="helper" />
                </Fragment>
              ),
              additionalHeader: getMessage("languageAndCurrency.additionalCurrency.title"),
              stateProp: "currency",
              stateObj: this.state.currency,
              renderSelect: this.renderCurrencySelect.bind(this),
              addButtonText: getMessage("languageAndCurrency.additionalCurrency.addButtonText"),
              hideAdditional: this.hideAdditionalCurrencies,
              key: "section_currency"
            })}
          </div>

          <div className="e-col-3 section section_market">
            {this.renderSection({
              primaryHeader: (
                <Fragment>
                  {" "}
                  {getMessage("languageAndCurrency.primaryMarket.title")}
                  <e-tooltip content={getMessage("languageAndCurrency.primaryMarket.tooltip")} type="helper" />
                </Fragment>
              ),
              additionalHeader: getMessage("languageAndCurrency.additionalMarket.title"),
              stateProp: "market",
              stateObj: this.state.market,
              renderSelect: this.renderMarketSelect.bind(this),
              addButtonText: getMessage("languageAndCurrency.additionalMarket.addButtonText"),
              key: "section_market"
            })}
          </div>
          <div className="e-col e-col__separator" style={{ left: "50%" }}></div>
          <div className="e-col e-col__separator" style={{ left: "75%" }}></div>
        </div>
        {this.props.fromOnboarding ? null : (
          <Fragment>
            <hr className="e-separator e-separator-fullwidth" />
            {this.renderFooterButtons()}
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const showCurrentVersion = selectShowCurrentPlan(state);
  const nextPlan = selectPlan(state);
  const currentPlan = selectCurrentPlanVersion(state);

  return {
    showCurrentVersion: selectShowCurrentPlan(state),
    languageSettings: selectPlanLanguageSettings(state),
    storeUrlSettings: selectPlanStoreUrlSettings(state),
    currencySettings: selectPlanCurrencySettings(state),
    marketSettings: selectPlanMarketSettings(state),
    isAccountViewOnly: isAccountViewOnly(state),
    showCurrentVersion,
    currentPlan,
    plan: showCurrentVersion ? currentPlan : nextPlan
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateLocaleSettings: ({ language, storeUrl, currency, market, refreshTiers }) => {
    dispatch(updateLocaleSettings({ language, storeUrl, currency, market, refreshTiers }));
  },
  hideErrorAlert: () => {
    dispatch(hideErrorAlert());
  }
});

LanguageAndCurrency.propTypes = {
  registerSubmitFunc: PropTypes.func,
  registerValidateFunc: PropTypes.func,
  fromOnboarding: PropTypes.bool,
  isAdditionalBenefits: PropTypes.bool,
  showCurrentVersion: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageAndCurrency);
