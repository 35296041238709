import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import fileDownload from "js-file-download";

import DashboardHeader from "../components/DashboardHeader";
import DashboardOptions from "../components/DashboardOptions";
import StatusLabel from "../components/StatusLabel";
import Time from "../components/Time";

import VoucherCodesTemplateWidget from "../views/RewardDashboard/VoucherCodesTemplateWidget";

import rewardReportApi from "../api/rewardReportApi";
import { getRewardStatusLabel } from "../helpers/statusHelper";
import { getMessage } from "../messages";
import {
  selectActions,
  selectAdditionalBenefits,
  selectFixedBenefits,
  selectRewardById,
  selectTiers
} from "../reducers";
import { isDemoMode } from "../helpers/envHelper";
import { formatInputDate } from "../views/Dashboard/dashboardHelper";
import { VIEW_RANGE_ENUM } from "../constants/dashboardConstants";

import rewardService from "../services/rewardService";

const RewardDashboard = (props) => {
  const reward = useSelector((state) => selectRewardById(state, props.match.params.id));
  const actions = useSelector((state) => selectActions(state));
  const fixedBenefits = useSelector((state) => selectFixedBenefits(state));
  const additionalBenefits = useSelector((state) => selectAdditionalBenefits(state));
  const tiers = useSelector((state) => selectTiers(state));

  const rewardId = reward.id;
  const status = rewardService.getRewardStatus(reward, actions, fixedBenefits, additionalBenefits, tiers);
  const statusLabel = getRewardStatusLabel(status, reward);

  const initialStartMoment = isDemoMode() ? moment("2022-01-01") : moment().subtract(1, "months");
  const initialEndMoment = isDemoMode() ? moment("2022-04-10") : moment();

  const [dateRange, setDateRange] = useState({
    start: formatInputDate(initialStartMoment),
    end: formatInputDate(initialEndMoment)
  });
  const [dateAgg, setDateAgg] = useState(VIEW_RANGE_ENUM.DAY);
  const [loadingCsv, setLoadingCsv] = useState(false);

  function onDatesChange({ start, end }) {
    setDateRange({ start, end });
  }

  function onDateAggChange(value) {
    setDateAgg(value);
  }

  function onDownloadCsv() {
    const apiFunc = rewardService.isVoucherForRewards(reward.masterReward)
      ? rewardReportApi.getVoucherPoolAsRewardCsv
      : rewardReportApi.getVoucherPoolNotAsRewardCsv;

    setLoadingCsv(true);

    apiFunc({
      rewardId,
      startDateStr: dateRange.start,
      endDateStr: dateRange.end
    })
      .then((data) => fileDownload(data, "voucher-pool.csv"))
      .finally(() => setLoadingCsv(false));
  }

  return (
    <div className="e-layout e-layout-without_navigation">
      <DashboardHeader
        title={reward.name}
        overviewLink={`/vouchers/${reward.masterReward}`}
        editLink={`/reward/${rewardId}`}
        overviewLinkText={getMessage(`voucherDashboard.overviewLinkText.${reward.masterReward}`)}
        editLinkText={getMessage("voucherDashboard.editButton.label")}
        statueLabelElement={
          <StatusLabel
            label={statusLabel.label}
            className={`${statusLabel.className} e-margin-left-s`}
            tooltip={statusLabel.tooltip}
          />
        }
      />
      <DashboardOptions
        dateRange={dateRange}
        dateAgg={dateAgg}
        startedAtElement={getMessage("voucherDashboard.voucherCreatedAt", {
          time: <Time value={reward.createdAt} onlyDate={true} />
        })}
        onDatesChange={onDatesChange}
        onDateAggChange={onDateAggChange}
        onDownloadCsv={onDownloadCsv}
        csvDownloadLoading={loadingCsv}
        csvDownloadButtonText={getMessage("voucherDashboard.downloadVoucherDataButton.label")}
        csvDownloadButtonTooltip={
          rewardService.isVoucherForRewards(reward.masterReward)
            ? getMessage("voucherDashboard.downloadVoucherDataButton.tooltipAsReward")
            : ""
        }
      />
      <div className="l-dashboard">
        {rewardService.isVoucherForRewards(reward.masterReward) && (
          <div className="e-row e-margin-top-l">
            <div className="e-col-12">
              <VoucherCodesTemplateWidget
                rewardId={rewardId}
                startDateStr={dateRange.start}
                endDateStr={dateRange.end}
                dateAggStr={dateAgg}
                apiChartFunc={rewardReportApi.getVoucherCodesDistributedChartData}
                apiKpiFunc={rewardReportApi.getVoucherCodesDistributedChartTotals}
                messages={{
                  widgetTitle: getMessage("voucherDashboard.vouchesAttachedWidget.title"),
                  kpiTitle: getMessage("voucherDashboard.vouchesAttachedWidget.kpiTitle"),
                  kpiTooltip: getMessage("voucherDashboard.vouchesAttachedWidget.kpiTooltip"),
                  chartTotalMetric: getMessage("voucherDashboard.vouchesAttachedWidget.chartTotalMetric")
                }}
              />
            </div>
          </div>
        )}
        <div className="e-row e-margin-top-l">
          <div className="e-col-12">
            <VoucherCodesTemplateWidget
              rewardId={rewardId}
              startDateStr={dateRange.start}
              endDateStr={dateRange.end}
              dateAggStr={dateAgg}
              apiChartFunc={rewardReportApi.getVoucherCodesExposedChartData}
              apiKpiFunc={rewardReportApi.getVoucherCodesExposedChartTotals}
              messages={{
                widgetTitle: getMessage("voucherDashboard.vouchesDistributedWidget.title"),
                kpiTitle: getMessage("voucherDashboard.vouchesDistributedWidget.kpiTitle"),
                kpiTooltip: getMessage("voucherDashboard.vouchesDistributedWidget.kpiTooltip"),
                chartTotalMetric: getMessage("voucherDashboard.vouchesDistributedWidget.chartTotalMetric")
              }}
            />
          </div>
        </div>
        <div className="e-row e-margin-top-l"></div>
      </div>
    </div>
  );
};

export default RewardDashboard;
