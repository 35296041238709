import * as TYPES from "./TYPES";
import actionApi from "../api/actionApi";
import { invalidateJwt } from "./sessionActions";

export const getAllActions = () => {
  return (dispatch) => {
    dispatch({
      type: TYPES.GET_ALL_ACTIONS_REQUEST
    });
    return actionApi
      .getAllActions()
      .then((data) => {
        dispatch({
          type: TYPES.GET_ALL_ACTIONS_SUCCESS,
          payload: data
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.GET_ALL_ACTIONS_FAILURE
        });
      });
  };
};

export const updateAction = (action, id, disableAlert, fields) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.POST_ACTION_REQUEST
    });

    const isNew = !id;
    const setMethod = isNew ? actionApi.createAction : actionApi.updateAction;
    return setMethod(action, id)
      .then((data) => {
        dispatch({
          type: TYPES.POST_ACTION_SUCCESS,
          payload: {
            data,
            isNew,
            disableAlert,
            fields
          }
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.POST_ACTION_FAILURE,
          payload: {
            isNew
          }
        });

        console.error(err);
      });
  };
};

export const deleteAction = (id) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.DELETE_ACTION_REQUEST
    });

    return actionApi
      .deleteAction(id)
      .then((response) => {
        if (response.status === 204) {
          dispatch({
            type: TYPES.DELETE_ACTION_SUCCESS,
            payload: {
              doDelete: true,
              id
            }
          });
        } else {
          dispatch({
            type: TYPES.DELETE_ACTION_FAILURE
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.DELETE_ACTION_FAILURE
        });
      });
  };
};

export const setActionEditState = (action, stepId) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.SET_ACTION_EDIT_STATE,
      payload: {
        action,
        stepId
      }
    });
  };
};

export const resetActionEditState = () => {
  return function (dispatch) {
    dispatch({
      type: TYPES.RESET_ACTION_EDIT_STATE
    });
  };
};

export const resetNewActionId = () => {
  return function (dispatch) {
    dispatch({
      type: TYPES.RESET_NEW_ACTION_ID
    });
  };
};
