import * as TYPES from "./TYPES";
import sessionApi from "../api/sessionApi";

export const getJwt = (url) => {
  return (dispatch) => {
    dispatch({
      type: TYPES.GET_JWT_REQUEST
    });

    return sessionApi
      .getJwt(url)
      .then((jwtToken) => {
        dispatch({
          type: TYPES.GET_JWT_SUCCESS,
          payload: jwtToken
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.GET_JWT_FAILED
        });
      });
  };
};

export const invalidateJwt = (response) => {
  return (dispatch) => {
    if (response.status === 401) {
      dispatch({
        type: TYPES.INVALIDATE_JWT
      });
    }
  };
};
