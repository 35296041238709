import * as TYPES from "./TYPES";
import planApi from "../api/planApi";
import { invalidateJwt } from "./sessionActions";
import { switchPlanEditMode } from "./currentPlanVersionActions";
import { getAllTiers } from "./tierActions";
import { getCustomer } from "./customerActions";
import { isDemoMode } from "../helpers/envHelper";
import { getAppPlanData } from ".";

export const getPlan = () => {
  return (dispatch) => {
    dispatch({
      type: TYPES.GET_PLAN_REQUEST
    });

    return planApi
      .getPlan()
      .then((data) => {
        dispatch({
          type: TYPES.GET_PLAN_SUCCESS,
          payload: data
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.GET_PLAN_FAILURE
        });
      });
  };
};

export const updateAccountSettingsPlans = ({ data, disableAlert }) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.UPDATE_ACCOUNT_SETTINGS_PLANS_REQUEST
    });
    return planApi
      .updateAccountSettingsPlans(data)
      .then((planIds) => {
        dispatch({
          type: TYPES.UPDATE_ACCOUNT_SETTINGS_PLANS_SUCCESS,
          payload: {
            planIds
          }
        });
        dispatch(getCustomer());
        dispatch(getPlan());
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.UPDATE_ACCOUNT_SETTINGS_PLANS_FAILURE
        });
      });
  };
};

export const updatePlan = ({ id, data, disableAlert }) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.UPDATE_PLAN_REQUEST
    });
    return planApi
      .updatePlan(id, data)
      .then((data) => {
        dispatch({
          type: TYPES.UPDATE_PLAN_SUCCESS,
          payload: {
            plan: data,
            disableAlert
          }
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
          console.error(err);
        }
        dispatch({
          type: TYPES.UPDATE_PLAN_FAILURE
        });
      });
  };
};

export const deletePlan = (id) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.DELETE_PLAN_REQUEST
    });

    return planApi
      .deletePlan(id)
      .then((response) => {
        if (response.status === 204) {
          dispatch({
            type: TYPES.DELETE_PLAN_SUCCESS
          });
          dispatch(getCustomer());
          dispatch(getPlan());
          dispatch(getAppPlanData());
        } else {
          dispatch({
            type: TYPES.DELETE_PLAN_FAILURE
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.DELETE_ACTION_FAILURE
        });
      });
  };
};

export const launchPlan = () => {
  return function (dispatch) {
    dispatch({
      type: TYPES.LAUNCH_PLAN_REQUEST
    });

    return planApi
      .launchPlan()
      .then((data) => {
        dispatch({
          type: TYPES.LAUNCH_PLAN_SUCCESS,
          payload: {
            currentPlanVersion: data
          }
        });
        dispatch(switchPlanEditMode(true));
        dispatch(getPlan());
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.LAUNCH_PLAN_FAILURE
        });
      });
  };
};

export const updateLocaleSettings = ({ language, storeUrl, currency, market, refreshTiers }) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.UPDATE_PLAN_LOCALE_REQUEST
    });

    const data = { language, storeUrl, currency, market };

    return planApi
      .updateLocale(data)
      .then(() => {
        dispatch({
          type: TYPES.UPDATE_PLAN_LOCALE_SUCCESS
        });
        dispatch(getPlan());
        if (refreshTiers) {
          dispatch(getAllTiers());
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.UPDATE_PLAN_LOCALE_FAILURE
        });
      });
  };
};

export const updateDistribution = ({ data, refreshTiers, disableAlert }) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.UPDATE_PLAN_DISTRIBUTION_REQUEST
    });

    return planApi
      .updateDistribution(data)
      .then((data) => {
        dispatch({
          type: TYPES.UPDATE_PLAN_DISTRIBUTION_SUCCESS,
          payload: {
            distribution: data,
            disableAlert
          }
        });
        if (refreshTiers) {
          dispatch(getAllTiers());
          dispatch(getPlan());
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.UPDATE_PLAN_DISTRIBUTION_FAILURE
        });
      });
  };
};

export const activatePlan = () => {
  return function (dispatch) {
    dispatch({
      type: TYPES.ACTIVATE_PLAN_REQUEST
    });

    if (isDemoMode()) {
      return setTimeout(() => {
        sessionStorage.setItem("activated", true);
        dispatch({
          type: TYPES.ACTIVATE_PLAN_SUCCESS
        });
      }, 2000);
    }

    return planApi
      .activatePlan()
      .then(() => {
        dispatch({
          type: TYPES.ACTIVATE_PLAN_SUCCESS
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.ACTIVATE_PLAN_FAILURE
        });
      });
  };
};
