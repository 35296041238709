export const SMART_INSIGHTS_HELP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/360003457657-Smart-Insight-Self-Service-tool";

export const RDS_SETUP_HELP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/7728720828817-implementation-setting-up-loyalty-rds-connection";

export const PLAN_RELAUNCH_HELP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4607661960593-Launching-Your-Loyalty-Plan-Plan-relaunch";

export const DELETE_CONTACT_API_HELP_LINK =
  "https://dev.emarsys.com/docs/loyalty-api-v3/aeb9b068a70ec-delete-a-contact";

export const PLAN_FIRST_LAUNCH =
  "https://help.emarsys.com/hc/en-us/articles/4607627298065-Launching-Your-Loyalty-Plan-First-launch#i-want-to-launch-my-loy[%E2%80%A6]ble-what-do-i-need-to-do";

export const SALES_DATA_SHOPIFY_PLUGIN_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4417317181073#setting-up-flexible-sales-data-for-clients-using-t";

export const SALES_DATA_CSV_API_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4607666216593-Implementation-Implementation-Phases#migration-phase";

export const SALES_DATA_ORIGINAL_PRICE_FIELD_LINK =
  "https://help.emarsys.com/hc/en-us/articles/360003070654-preparing-your-sales-data-file#loyalty-fields";

export const SALES_DATA_IMPORT_LINK =
  "https://help.emarsys.com/hc/en-us/articles/10844302503953-Implementation-Sales-Data-Import#sales-data-import";

export const PRODUCT_CATALOG_IMPORT_LINK =
  "https://help.emarsys.com/hc/en-us/articles/10844302503953-Implementation-Loyalty-Data-Import#product-catalog-import";

export const DATA_LOAD_LINK =
  "https://help.emarsys.com/hc/en-us/articles/10844302503953-Implementation-Loyalty-Data-Import#data-load";

export const ACTIONS_OVERVIEW_HELP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4608003114257-Loyalty-Actions-Overview#overview";

export const ACTIONS_PURCHASE_SETUP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4608160718097-Loyalty-Actions-Setting-up-a-purchase-action";

export const ACTIONS_PROMOTION_SETUP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4608160648337-Loyalty-Actions-Setting-up-a-promotion-action";

export const ACTIONS_ENGAGEMENT_SETUP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4608050914321-Loyalty-Actions-Setting-up-event-actions-and-engagement-actions";

export const ACTIONS_EVENT_SETUP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4608050914321-Loyalty-Actions-Setting-up-event-actions-and-engagement-actions";

export const ACTIONS_REFERRAL_SETUP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4608104344849-Loyalty-Actions-Setting-up-Referral";

export const ACTIONS_JOIN_SETUP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4608220111761-Loyalty-Actions-Setting-up-the-join-action";

export const VOUCHERS_FOR_REWARD_SETUP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4608225375505-Rewards-Setting-up-vouchers-for-rewards";

export const VOUCHERS_FOR_POINTS_SETUP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4608160065937-Rewards-Setting-up-vouchers-for-points";

export const VOUCHERS_FOR_FREE_SETUP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4608195275025-Rewards-Setting-up-vouchers-for-free";

export const VOUCHERS_AS_CASHBACK_SETUP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4626412425361-Rewards-Cashback-vouchers";

export const EXCLUSIVE_ACCESS_SETUP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4608088460305-Rewards-Exclusive-Access";

export const TIERS_HELP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4607915685137-Tiers-amp-Benefits-Setting-up-tiers";

export const FIXED_BENEFITS_HELP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4607725936913-Tiers-amp-Benefits-Setting-up-fixed-benefits";

export const ADDITIONAL_BENEFITS_HELP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4608060118161-Tiers-amp-Benefits-Additional-Benefits";

export const INVITE_TIER_HELP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4608000085905-Tiers-Benefits-Invitation-only-tier";

export const ACCOUNT_SETTINGS_HELP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4607659292177-Activation-Activating-your-Loyalty-account-Loyalty-Onboarding";

export const LANGUAGE_AND_CURRENCY_HELP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/5147224140561-Activation-Activating-your-Loyalty-account-Points-Redemption-Plan#setup";

export const MARKETS_HELP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4607641983889-Activation-Multiple-market-support";

export const PLAN_SETTING_HELP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/5147224140561-Activation-Activating-your-Loyalty-account-Points-Redemption-Plan#setting-up-your-plan";

export const MEMBERSHIP_SETTINGS_HELP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/5147224140561-Activation-Activating-your-Loyalty-account-Points-Redemption-Plan#launch-settings";

export const PLAN_LAUNCH_HELP_LINK =
  " https://help.emarsys.com/hc/en-us/articles/4607661960593-Launching-Your-Loyalty-Plan-Plan-relaunch";
