import React, { Fragment } from "react";
import PropTypes from "prop-types";

import MultiSelectField from "../../components/MultiSelectField";
import SelectField from "../../components/SelectField";
import CheckboxField from "../../components/CheckboxField";

import { getErrorMessage } from "../../validators";

import { benefitType } from "../../types";
import { getMessage } from "../../messages";

const BenefitSetupExclusiveAccessFields = ({
  benefit,
  tierList,
  options,
  onInputChange,
  onCheckboxChange,
  errors,
  disabled,
  isAdditionalBenefit
}) => {
  const tiersError = getErrorMessage(errors, "tiers");

  return isAdditionalBenefit ? (
    <SelectField
      label={getMessage("benefitSetupExclusiveAccessFields.exclusiveAccessIds.label")}
      name={`exclusiveAccessIds.0`}
      value={benefit.exclusiveAccessIds ? benefit.exclusiveAccessIds[0] : ""}
      options={options}
      onChange={onInputChange}
      placeholder={getMessage("benefitSetupExclusiveAccessFields.exclusiveAccessIds.placeholder")}
      disabled={disabled}
      errorMsg={getErrorMessage(errors, `exclusiveAccessIds`)}
      tooltip={getMessage("benefitSetupExclusiveAccessFields.exclusiveAccessIds.tooltip")}
    />
  ) : (
    <Fragment>
      <label className="e-field__label">
        {getMessage("benefitSetupExclusiveAccessFields.tiersAndAccess.title")}{" "}
        <e-tooltip content={getMessage("benefitSetupExclusiveAccessFields.tiersAndAccess.tooltip")} type="helper" />
      </label>
      <div className="benefit-setup-tiers">
        {tierList.map((tier) => {
          const benefitTier = benefit.tiers[tier.id];
          return (
            <div key={`exclusiveAccessFields_${tier.id}`}>
              <CheckboxField
                name={`tiers.${tier.id}.enabled`}
                noMargin={true}
                label={tier.name}
                checked={benefitTier.enabled}
                onChange={(event) => onCheckboxChange(event, tier.id)}
                errorMsg={tiersError}
                disabled={disabled}
              />
              <div className="m-t-5">
                <MultiSelectField
                  name={`tiers.${tier.id}.exclusiveAccessIds`}
                  noMargin={true}
                  value={benefitTier.enabled ? benefitTier.exclusiveAccessIds : ""}
                  options={options}
                  onChange={onInputChange}
                  placeholder={getMessage("benefitSetupExclusiveAccessFields.exclusiveAccessIds.placeholder")}
                  disabled={disabled || !benefitTier.enabled}
                  errorMsg={getErrorMessage(errors, `tiers.${tier.id}.exclusiveAccessIds`)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div class="e-field__message e-field__message-error m-n-t-10">{tiersError}</div>
    </Fragment>
  );
};

BenefitSetupExclusiveAccessFields.propTypes = {
  benefit: benefitType,
  tierList: PropTypes.array,
  options: PropTypes.array,
  onCheckboxChange: PropTypes.func,
  onInputChange: PropTypes.func,
  errors: PropTypes.shape({}),
  disabled: PropTypes.bool,
  isAdditionalBenefit: PropTypes.bool
};

export default BenefitSetupExclusiveAccessFields;
