import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { getMessage } from "../messages";

const FileInputField = (props) => {
  const onFileSelected = (acceptedFiles, rejectedFiles, x, y) => {
    if (rejectedFiles.length > 0) {
      if (rejectedFiles[0].size > props.maxFileSize) {
        props.onFileSelected({
          error: "maxSize"
        });
      }
    } else if (acceptedFiles.length === 1) {
      props.onFileSelected(acceptedFiles[0]);
    }
  };

  const labelClassNames = classNames("e-field__label", {
    "e-field__label-required": props.isRequired,
    "e-field__label-inline": props.inline
  });

  const uploadBtnClassName = props.enableUpload ? "" : "e-btn-disabled";

  return (
    <div>
      <div className="e-field">
        <label className={labelClassNames}>
          {props.label}
          {props.tooltip ? <e-tooltip content={props.tooltip} type="helper" /> : null}
        </label>
        <div className="e-grid">
          <div className="e-cell e-cell-auto">
            <Dropzone onDrop={onFileSelected} accept={"text/csv, application/vnd.ms-excel"} maxSize={props.maxFileSize}>
              {({ getRootProps, getInputProps }) => (
                <div className="e-upload" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="clearfix">
                    <div className="e-grid">
                      <div className="e-cell e-cell-auto">
                        <input
                          type="text"
                          readOnly
                          className="e-input"
                          placeholder={props.placeholder}
                          value={props.value}
                          disabled={props.disabled}
                        />
                      </div>
                      <div className="e-cell m-l-10">
                        <button type="button" className="e-btn e-btn-dark" disabled={props.disabled}>
                          <e-icon icon="folder-open" />
                          {getMessage("fileInputField.browseBtn.label")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
          <div className="e-cell">
            <a className={`e-btn e-btn-primary m-l-10 ${uploadBtnClassName}`} onClick={props.onUpload}>
              {getMessage("fileInputField.uploadBtn.label")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

FileInputField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,
  value: PropTypes.string,
  onFileSelected: PropTypes.func,
  onUpload: PropTypes.func,
  enableUpload: PropTypes.bool,
  maxFileSize: PropTypes.number,
  disabled: PropTypes.bool
};

export default FileInputField;
