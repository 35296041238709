import validate from "validate.js";
import { getMessage } from "../messages";

export default class LanguageAndCurrencyValidator {
  constructor() {
    this.constraints = this.createConstraints();
  }

  createConstraints() {
    return {
      language: {
        presence: {
          allowEmpty: false,
          message: getMessage("languageAndCurrencyValidator.language.presence")
        }
      },
      currency: {
        presence: {
          allowEmpty: false,
          message: getMessage("languageAndCurrencyValidator.currency.presence")
        }
      },
      storeUrl: {
        url: {
          message: getMessage("languageAndCurrencyValidator.storeUrl.url")
        }
      }
    };
  }

  validateField(data, propName) {
    return validate(data, { [propName]: this.constraints[propName] });
  }
}
