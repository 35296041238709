import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class AccordionItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidMount() {
    this.setState({
      open: !!this.props.open
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open === this.props.open) {
      return;
    }

    this.setState({
      open: this.props.open
    });
  }

  toggle() {
    const newState = !this.state.open;

    this.setState({
      open: newState
    });

    this.props.onStateChange && this.props.onStateChange(this.props.id, newState);
  }

  render() {
    const inputId = `${this.props.name}-${this.props.id}`;

    let attr = {};
    if (this.props.disabled) {
      attr["disabled"] = true;
    }

    const title = this.props.tooltip ? (
      <div>
        {this.props.title}
        <e-tooltip content={this.props.tooltip} type="helper"></e-tooltip>
      </div>
    ) : (
      <div>{this.props.title}</div>
    );

    return (
      <Fragment>
        <input
          type="checkbox"
          name={this.props.name}
          id={inputId}
          checked={this.state.open}
          onClick={this.toggle.bind(this)}
          {...attr}
          readOnly
        />
        <label className={"e-accordion__title"} htmlFor={inputId}>
          {title}
          <div className="e-header__button">{this.props.renderSwitch}</div>
        </label>
        <div className={classNames("e-accordion__content", this.props.contentClassName)}>{this.props.children}</div>
      </Fragment>
    );
  }
}

AccordionItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.any,
  open: PropTypes.bool,
  renderSwitch: PropTypes.element,
  disabled: PropTypes.bool,
  contentClassName: PropTypes.string,
  onStateChange: PropTypes.func,
  tooltip: PropTypes.string
};

export default AccordionItem;
