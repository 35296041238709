import React, { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import securityApi from "../api/securityApi";
import customerApi from "../api/customerApi";
import LoaderOverlay from "../components/LoaderOverlay";
import Notification from "../components/Notification";
import ReAuthPopup, { handleAuthError } from "../views/Security/ReAuthPopup";
import { showErrorAlert } from "../actions/alertActions";
import { getMessage } from "../messages";
import { RDS_SETUP_HELP_LINK } from "../constants/linkConstants";

export default function SecurityRdsConfiguration() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [userName, setUserName] = useState("");
  const [cert, setCert] = useState("");
  const [password, setPassword] = useState("");
  const [showReAuthPopup, setShowReAuthPopup] = useState(false);
  const [showReAuthPopupLoading, setShowReAuthPopupLoading] = useState(false);
  const [authError, setAuthError] = useState("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const notificationMessageLink = `<a target="_blank" href="${RDS_SETUP_HELP_LINK}">${getMessage(
    `securitySettings.rds.createNotificationMessageLinkText`
  )}</a>`;

  const createNotificationMessage = getMessage("securitySettings.rds.createNotificationMessage", {
    helpLink: notificationMessageLink
  });
  const createdNotificationMessageWithPassword = getMessage(
    "securitySettings.rds.createdNotificationMessageWithPassword",
    { helpLink: notificationMessageLink }
  );
  const createdNotificationMessageWithoutPassword = getMessage(
    "securitySettings.rds.createdNotificationMessageWithoutPassword",
    { helpLink: notificationMessageLink }
  );

  //get rds credentials from api
  useEffect(() => {
    const init = async () => {
      setLoading(true);

      try {
        const data = await customerApi.isSuperAdmin();
        setIsSuperAdmin(data.isSuperAdmin);
      } catch (error) {
        dispatch(showErrorAlert(getMessage("securitySettings.error.general.text")));
        console.error(error);
      }

      try {
        const rdsSettings = await securityApi.getRdsSettings();
        if (rdsSettings) {
          setUserName(rdsSettings.userName);
          setCert(rdsSettings.cert);
        }
      } catch (error) {
        dispatch(showErrorAlert(getMessage("securitySettings.error.general.text")));
        console.error(error);
      }
      setLoading(false);
    };
    init();
  }, []);

  function createRdsCredentials(password) {
    setShowReAuthPopupLoading(true);
    securityApi
      .createRdsCredentials(password)
      .then((rdsCredentials) => {
        setUserName(rdsCredentials.userName);
        setPassword(rdsCredentials.password);
        setShowReAuthPopup(false);
      })
      .catch((error) => {
        setAuthError(handleAuthError(error));
      })
      .finally(() => {
        setShowReAuthPopupLoading(false);
      });
  }

  function CreateSection() {
    const button = (
      <button
        onClick={() => {
          setShowReAuthPopup(true);
        }}
        className="e-btn e-btn-primary"
        disabled={!isSuperAdmin}
      >
        {getMessage("securitySettings.rds.createButton")}
      </button>
    );

    const buttonWithTooltip = (
      <e-tooltip content={getMessage("securitySettings.rds.createButton.disabledTooltip.notAccountOwner")}>
        {button}
      </e-tooltip>
    );

    return (
      <div className="box-padding">
        <Notification content={createNotificationMessage} />
        {isSuperAdmin ? button : buttonWithTooltip}
      </div>
    );
  }

  function SecurityField({ label, value }) {
    return (
      <div>
        <p>{label}</p>
        <p>
          <strong>{value}</strong>
        </p>
      </div>
    );
  }

  function ViewRdsSection() {
    const notificationMessage = password
      ? createdNotificationMessageWithPassword
      : createdNotificationMessageWithoutPassword;

    return (
      <Fragment>
        <Notification content={notificationMessage} />
        {password && (
          <Notification isWarning={true} content={getMessage("securitySettings.rds.createdWarningNotification")} />
        )}
        <div className="e-row">
          <div className="e-col-6">
            <div className="e-row">
              <div className="e-col-4">
                <SecurityField label={getMessage("securitySettings.rds.fields.databaseName")} value={"EmarsysRDS"} />
              </div>
              <div className="e-col-4">
                <SecurityField label={getMessage("securitySettings.rds.fields.port")} value={"3306"} />
              </div>
              <div className="e-col-4">
                <SecurityField
                  label={getMessage("securitySettings.rds.fields.host")}
                  value={"emarsys-rds.loyalsys.io"}
                />
              </div>
            </div>
            <div className="e-row  e-margin-top-m">
              <div className="e-col-4">
                <SecurityField label={getMessage("securitySettings.rds.fields.userName")} value={userName} />
              </div>
              {password && (
                <div className="e-col-4">
                  <SecurityField label={getMessage("securitySettings.rds.fields.password")} value={password} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="e-row  e-margin-top-m">
          <div className="e-col-6">
            <div className="e-field">
              <label className="e-field__label" htmlFor="textarea_cert">
                {getMessage("securitySettings.rds.fields.certificate")}
              </label>
              <textarea
                value={cert}
                className="e-input e-input-textarea e-input-auto_height"
                id="textarea_cert"
                rows="22"
                readOnly
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <div className="box-padding">
      {!loading && (userName ? <ViewRdsSection /> : <CreateSection />)}
      <ReAuthPopup
        id="createRdsPopup"
        title={getMessage("securitySettings.rds.authPopup.create.title")}
        subTitle={getMessage("securitySettings.rds.authPopup.create.content")}
        show={showReAuthPopup}
        onClose={() => setShowReAuthPopup(false)}
        onConfirm={createRdsCredentials}
        loading={showReAuthPopupLoading}
        authError={authError}
      />
      <LoaderOverlay show={loading} />
    </div>
  );
}
