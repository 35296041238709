import React, { Fragment } from "react";
import PropTypes from "prop-types";

const WidgetCollapsedContentElement = ({ value, valueNumericAttr, valueColor, title }) => {
  return (
    <Fragment>
      <strong>
        <e-numeric value={value} {...valueNumericAttr} style={{ color: valueColor }}></e-numeric>
      </strong>
      &nbsp;<span className="text-color-gray-350">{title}</span>
    </Fragment>
  );
};

WidgetCollapsedContentElement.propTypes = {
  value: PropTypes.any,
  valueNumericAttr: PropTypes.object,
  valueColor: PropTypes.string,
  title: PropTypes.string
};

export default WidgetCollapsedContentElement;
