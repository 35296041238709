import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { uniqBy } from "lodash";

import CardsContainer from "../components/CardsContainer";

import {
  selectTiers,
  selectTierExistsInCurrentVersion,
  selectIsCurrentPlanVersionExists,
  selectIsBeforeFirstPlanLaunch,
  selectPlanContactDistribution,
  selectShowCurrentPlan,
  isAppDataLoaded,
  selectPlan
} from "../reducers";
import { deleteTier } from "../actions/tierActions";

import { openDeleteDialog } from "../helpers/dialogHelper";

import planService from "../services/planService";
import tierService from "../services/tierService";

import "../assets/styles/Tiers.scss";
import { getIconName } from "../helpers/iconHelper";
import { getTierIconsUrl } from "../helpers/envHelper";
import { getMessage, getPluralCardinalMessage } from "../messages";
import { showWarningAlert } from "../actions/alertActions";
import { PLAN_RELAUNCH_HELP_LINK, TIERS_HELP_LINK } from "../constants/linkConstants";
import TiersNavigation from "../views/TiersNavigation";

class Tiers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showBenefitsPopup: false,
      previewTierId: undefined
    };

    if (this.props.showCurrentVersion) {
      const link = `<a target="_blank" href="${PLAN_RELAUNCH_HELP_LINK}">${getMessage(
        "alertContainer.liveModeAlert.message.linkText"
      )}</a>`;

      const alertMessage = getMessage("alertContainer.liveModeAlert.message", { link });
      setTimeout(() => {
        this.props.showLiveAlert(alertMessage);
      }, 1000);
    }
  }

  getEditIcon(tier, prevTier) {
    let editIcon = {
      link: `/tier/${tier.id}`
    };

    if (prevTier && prevTier.settings.points === undefined && tierService.isRegularTier(prevTier)) {
      editIcon.disabled = true;
      editIcon.tooltip = getMessage("tiers.editIcon.tooltip");
    }
    return editIcon;
  }

  getPreviewIcon(tier) {
    const previewIcon = {
      link: `/tier/${tier.id}`,
      tooltip: getMessage("tiers.reviewIcon.tooltip")
    };

    return previewIcon;
  }

  getStatusLabel(tier) {
    let label = getMessage("status.active");
    let className = "e-label-primary";
    let tooltip = "";

    if (!this.props.showCurrentVersion) {
      if (!tier.isValid) {
        label = getMessage("status.inactive");
        className = "e-label-default background-color-placeholder";
        tooltip = getMessage("tiers.status.inactive.tooltip");
      } else {
        label = getMessage("status.readyToLaunch");
        className = "e-label-warning";
        tooltip = "";
      }
    }

    return { label, className, tooltip };
  }

  getCurrentCardTiers() {
    let cards = [];
    const { showCurrentVersion } = this.props;

    const tiers = this.props.tiers;

    if (tiers && tiers.length) {
      for (let i = 0; i < tiers.length; i++) {
        const tier = tiers[i];
        const nextTier = i == tiers.length ? null : tiers[i + 1];
        const prevTier = i === 0 ? null : tiers[i - 1];
        const statusLabel = this.getStatusLabel(tier);
        const card = {
          id: tier.id,
          title: tier.name,
          titleIconUrl: tier.settings.icon
            ? `${getTierIconsUrl()}/${getIconName(tier.settings.icon.fileName, ".png")}`
            : null,
          subTitle: this.getCardSubtitle(tier, nextTier),
          statusLabel,
          className: tier.settings.subscr ? "tier-subscr" : "",
          isRoadmap: !!tier.settings.subscr,
          editIcon: showCurrentVersion ? null : this.getEditIcon(tier, prevTier),
          previewIcon: showCurrentVersion ? this.getPreviewIcon(tier) : null,
          filters: {
            statusLabel: statusLabel.label
          }
        };

        if (
          !showCurrentVersion &&
          !this.props.tierExistsInCurrentPlan(tier.id) &&
          (tierService.isLastRegularTier(tier.id, tiers) || tierService.isInviteTier(tier))
        ) {
          card.deleteIcon = {
            onClick: this.onDeleteTierClick.bind(this)
          };
        }

        cards.push(card);
      }
    }

    return cards;
  }

  getCardSubtitle(tier, nextTier) {
    const { plan } = { ...this.props };
    const { points } = tier.settings;

    if (!tier.isValid && points === undefined) {
      return null;
    }

    const maxPoints = nextTier ? nextTier.settings.points - 1 : null;
    const pointsText = planService.isSpendPlan(plan) ? getMessage("credits") : getMessage("points");
    let text = "";

    if (tierService.isInviteTier(tier)) {
      text = getMessage("tiers.cardSubtitle.points.text.invitationOnly");
    } else if (maxPoints && maxPoints !== points) {
      text = getMessage("tiers.cardSubtitle.points.text.pointsUnderMax", { points, maxPoints, pointsText });
    } else {
      text = getMessage("tiers.cardSubtitle.points.text.pointsAtMax", { points, pointsText });
    }

    const pointsElm = <div>{text}</div>;
    let purchasesElm;
    let purchasesRequired = !!tier.settings.purchases ? tier.settings.purchases : 0;
    if (purchasesRequired > 0) {
      purchasesElm = (
        <div>
          {getPluralCardinalMessage("tiers.cardSubtitle.purchasesRequired", purchasesRequired, {
            number: purchasesRequired
          })}
        </div>
      );
    }

    return (
      <Fragment>
        {pointsElm}
        {purchasesElm}
      </Fragment>
    );
  }

  onDeleteTierClick(tierId) {
    const onDeleteTier = () => {
      this.props.deleteTier(tierId);
    };

    openDeleteDialog(
      getMessage("tiers.deleteTierDialog.headline"),
      getMessage("tiers.deleteTierDialog.content"),
      onDeleteTier
    );
  }

  onPreviewClick(tierId) {
    this.setState({
      previewTierId: tierId,
      showBenefitsPopup: true
    });
  }

  onPreviewClose() {
    this.setState({
      showBenefitsPopup: false
    });
  }

  isLastTierValid() {
    const { tiers } = { ...this.props };
    if (tiers.length == 0) {
      return true;
    }
    const lastTier = tiers[tiers.length - 1];
    return lastTier && (lastTier.settings.points !== undefined || tierService.isInviteTier(lastTier));
  }

  render() {
    if (!this.props.isAppDataLoaded) {
      return null;
    }

    const currentCards = this.getCurrentCardTiers();

    return (
      <TiersNavigation>
        <div className="l-tiers box-padding">
          <div className="l-tiers-cards">
            <CardsContainer
              cards={currentCards}
              disableAddNewCard={!this.isLastTierValid()}
              addNewCardTooltip={this.isLastTierValid() ? "" : getMessage("tiers.addNewCard.tooltip.invalid")}
              addNewCardLink={`/tier-new`}
              addNewCardText={getMessage("tiers.addNewCard.text")}
              isPreviewMode={this.props.showCurrentVersion}
              hideFilterBar={true}
              header={getMessage("tiers.header.title", {
                learnMoreLink: (
                  <a target="_blank" href={TIERS_HELP_LINK}>
                    {getMessage("tiers.header.title.learnMoreLinkText")}
                  </a>
                )
              })}
            />
          </div>
        </div>
      </TiersNavigation>
    );
  }
}

const mapStateToProps = (state) => {
  const isBeforeFirstPlanLaunch = selectIsBeforeFirstPlanLaunch(state);
  const showCurrentVersion = selectShowCurrentPlan(state);
  let tiers = selectTiers(state);

  return {
    tiers,
    showCurrentVersion: showCurrentVersion,
    tierExistsInCurrentPlan: selectTierExistsInCurrentVersion(state),
    isCurrentPlanExists: selectIsCurrentPlanVersionExists(state),
    isBeforeFirstPlanLaunch: isBeforeFirstPlanLaunch,
    planContactDistribution: selectPlanContactDistribution(state),
    isAppDataLoaded: isAppDataLoaded(state),
    plan: selectPlan(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteTier: (id) => {
    dispatch(deleteTier(id));
  },
  showLiveAlert: (alertMessage) => {
    dispatch(showWarningAlert(alertMessage, null, true));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Tiers);
