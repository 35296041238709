import React, { useState, useEffect, Fragment } from "react";
import { renderToString } from "react-dom/server";
import cloneDeep from "lodash.clonedeep";
import actionReportApi from "../../api/actionReportApi";
import { convertDateForChart, formatTrendText, datesDiffStr } from "../Dashboard/dashboardHelper";
import Widget from "../../components/Widget";
import Time from "../../components/Time";
import WidgetTotalsItem from "../Dashboard/WidgetTotalsItem";
import WidgetHelpLink from "../Dashboard/WidgetHelpLink";
import LegendWithSelection from "../Dashboard/LegendWithSelection";
import DashboardEmptyState from "../Dashboard/DashboardEmptyState";

import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { selectIsCurrentPlanVersionExists, selectCurrentPlanTiersMapById, selectTiersMapById } from "../../reducers";

import { TIER_COLORS_ARRAY } from "../../constants/dashboardConstants";
import { getMessage } from "../../messages";

const CHART_COLORS = {
  SUCCESS: "#424E88"
};

const CHART_HEIGHT = 164;

const ReferralSuccessWidget = (props) => {
  const { actionId, startDateStr, endDateStr, dateAggStr } = props;

  let tiers = useSelector((state) => {
    const isCurrentPlanExists = selectIsCurrentPlanVersionExists(state);
    return isCurrentPlanExists ? selectCurrentPlanTiersMapById(state) : selectTiersMapById(state);
  });

  const getChartMetaData = (tiers) => {
    let chartMetaData = [
      {
        id: "Success",
        displayName: getMessage("referralSucccessWidget.chartMetaData.diaplayName.successRate"),
        color: CHART_COLORS.SUCCESS,
        data: []
      }
    ];

    Object.keys(tiers).forEach((tierId, index) => {
      chartMetaData.push({
        id: tierId,
        displayName: tiers[tierId].name,
        isTier: true,
        color: TIER_COLORS_ARRAY[index],
        data: []
      });
    });

    return chartMetaData;
  };

  let initialLegendSelected = (tiers) => {
    let legendSelection = {};
    legendSelection["Success"] = true;
    Object.keys(tiers).forEach((tierId) => {
      legendSelection[tierId] = true;
    });

    return legendSelection;
  };

  const [loading, setLoading] = useState(true);
  const [chartSeriesArr, setChartSeriesArr] = useState(getChartMetaData(tiers));
  const [legendSelection, setLegendSelection] = useState(initialLegendSelected(tiers));
  const [totals, setTotals] = useState({});
  const [chartCustomTooltip, setChartCustomTooltip] = useState("Blah Blah");
  const [showEmptyState, setShowEmptyState] = useState(false);

  const getPctValueAttr = () => {
    return { humanize: "auto", precision: "2", type: "percent", "trim-fraction-zeros": "true" };
  };

  const getChartTooltipContent = (date, dateTiers) => {
    let content = [];

    const dateElm = renderToString(<Time value={date} onlyDate={true} />);

    content.push({ label: dateElm, type: "header" });

    content.push({
      value: dateTiers["Success"].toString(),
      label: "Total Success Rate",
      color: CHART_COLORS.SUCCESS,
      valueFormat: {
        type: "number",
        options: getPctValueAttr()
      }
    });

    Object.keys(tiers).forEach((tierId, index) => {
      content.push({
        value: dateTiers[tierId].toString(),
        label: tiers[tierId].name,
        color: TIER_COLORS_ARRAY[index],
        valueFormat: {
          type: "number",
          options: getPctValueAttr()
        }
      });
    });

    return content;
  };

  const setChartSeriesArrState = (dataRows) => {
    if (!dataRows) {
      setShowEmptyState(true);
      return;
    }

    //dataRows = transformDataDateForDemo(dataRows, this.state.selectedDaysRange);
    dataRows = dataRows.data;

    let newChartSeriesArr = cloneDeep(chartSeriesArr);

    let chartCustomTooltip = {};
    let dataByDates = {};
    //reset data on each series
    newChartSeriesArr.forEach((series) => {
      series.data = [];
    });

    dataRows.forEach((row) => {
      const date = convertDateForChart(row.date);
      if (!dataByDates[date]) {
        dataByDates[date] = { Success: 0 };
        Object.keys(tiers).forEach((tierId, index) => {
          dataByDates[date][tierId] = 0;
        });
      }
      let tierIndex = newChartSeriesArr.findIndex((series) => series.id === row.tierId);
      if (tierIndex > -1) {
        newChartSeriesArr[tierIndex].data.push({
          x: date,
          y: row.successRate || 0
        });
        dataByDates[date][newChartSeriesArr[tierIndex].id] = row.successRate || 0;
      }
    });
    Object.keys(dataByDates).forEach((date) => {
      chartCustomTooltip[date] = { rows: getChartTooltipContent(date, dataByDates[date]) };
    });
    setChartSeriesArr(newChartSeriesArr);
    setChartCustomTooltip(chartCustomTooltip);
  };

  const setTotalState = (data) => {
    if (!data) {
      setShowEmptyState(true);
      return;
    }

    const successRate = data.value;
    const successRatePrevDiffPct = data.prevDiffPct;
    let totals = {
      successRate: successRate || 0,
      successRatePrevDiffPct: successRatePrevDiffPct || 0
    };
    setTotals(totals);
  };

  const loadData = () => {
    const promises = [
      actionReportApi.getActionReferralSuccessChartData({ actionId, startDateStr, endDateStr, dateAggStr }),
      actionReportApi.getActionReferralSuccessChartTotals({ actionId, startDateStr, endDateStr, dateAggStr })
    ];
    setLoading(true);
    setShowEmptyState(false);
    Promise.all(promises)
      .then((response) => {
        setChartSeriesArrState(response[0]);
        setTotalState(response[1]);
      })
      .catch((error) => {
        setShowEmptyState(true);
        throw error;
      })
      .finally(() => setLoading(false));
  };

  const getNumberValueAttr = () => {
    return { humanize: "auto", precision: "2", "trim-fraction-zeros": "true" };
  };

  const getTrendText = (value) => {
    return formatTrendText`${value} than previous ${datesDiffStr(startDateStr, endDateStr, dateAggStr)}`;
  };

  const getItemsForLegend = () => {
    return chartSeriesArr.map((item) => {
      return {
        id: item.id,
        displayName: item.displayName,
        color: item.color,
        isSelected: legendSelection[item.id]
      };
    });
  };

  useEffect(() => {
    loadData();
  }, [startDateStr, endDateStr, dateAggStr]);

  const onLegendSelectionChange = (selectionId) => {
    let newLegendSelection = cloneDeep(legendSelection);
    newLegendSelection[selectionId] = !newLegendSelection[selectionId];
    setLegendSelection(newLegendSelection);
  };

  const renderTotals = () => {
    const { successRate, successRatePrevDiffPct } = {
      ...totals
    };
    return (
      <div className="e-grid">
        <div className="e-cell e-cell-4">
          <WidgetTotalsItem
            title={getMessage("referralSucccessWidget.successRate.title")}
            titleTooltip={getMessage("referralSucccessWidget.successRate.titleTooltip")}
            value={successRate}
            valueNumericAttr={getPctValueAttr()}
            valueColor={CHART_COLORS.SUCCESS}
            trendValue={successRatePrevDiffPct}
            trendText={getTrendText(successRatePrevDiffPct)}
          />
        </div>
      </div>
    );
  };

  const renderChart = () => {
    return (
      <ec-chart height={CHART_HEIGHT} domain-type="time" custom-tooltip={JSON.stringify(chartCustomTooltip)}>
        {chartSeriesArr.map((series, index) => {
          if (legendSelection[series.id]) {
            return (
              <ec-series-line
                key={series.id}
                order={index}
                name={series.name}
                data={JSON.stringify(series.data)}
                color={series.color}
              ></ec-series-line>
            );
          }
        })}
      </ec-chart>
    );
  };

  const renderContent = () => {
    if (showEmptyState) {
      return <DashboardEmptyState />;
    }

    return (
      <Fragment>
        <div>{renderTotals()}</div>
        <div className="e-margin-top-m">{renderChart()}</div>
        <div className="e-margin-top-m">
          <LegendWithSelection items={getItemsForLegend()} onChange={onLegendSelectionChange} visible={true} />
        </div>
      </Fragment>
    );
  };

  return (
    <Widget
      title={getMessage("referralSucccessWidget.title")}
      className="referral-success-widget"
      footerActions={<WidgetHelpLink />}
      content={renderContent()}
      loading={loading}
      isEmptyState={showEmptyState}
    />
  );
};

ReferralSuccessWidget.propTypes = {
  actionId: PropTypes.string,
  startDateStr: PropTypes.string,
  endDateStr: PropTypes.string,
  dateAggStr: PropTypes.string
};

export default ReferralSuccessWidget;
