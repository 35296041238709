import axios from "axios";
import sessionHelper from "../helpers/sessionHelper";

// const getParams = ({ startDateStr, endDateStr, dateAggStr }) => {
//   return {
//     startDate: startDateStr,
//     endDate: endDateStr,
//     dateAgg: dateAggStr
//   };
// };

async function getReport({ name, rewardId, startDateStr, endDateStr, dateAggStr }) {
  const planId = sessionHelper.getPlanId();
  const response = await axios.get(`/plan/${planId}/report/reward/${rewardId}/${name}`, {
    params: {
      startDate: startDateStr,
      endDate: endDateStr,
      dateAgg: dateAggStr
    }
  });
  return response.data;
}

async function getCsv({ name, rewardId, startDateStr, endDateStr }) {
  const planId = sessionHelper.getPlanId();
  const response = await axios.get(`/plan/${planId}/report/reward/${rewardId}/${name}`, {
    params: {
      startDate: startDateStr,
      endDate: endDateStr
    },
    responseType: "blob"
  });
  return response.data;
}

export default class rewardReportApi {
  static async getVoucherCodesDistributedChartData({ rewardId, startDateStr, endDateStr, dateAggStr }) {
    return getReport({ name: "voucher-codes-distributed-data", rewardId, startDateStr, endDateStr, dateAggStr });
  }

  static async getVoucherCodesDistributedChartTotals({ rewardId, startDateStr, endDateStr, dateAggStr }) {
    return getReport({ name: "voucher-codes-distributed-totals", rewardId, startDateStr, endDateStr, dateAggStr });
  }

  static async getVoucherCodesExposedChartData({ rewardId, startDateStr, endDateStr, dateAggStr }) {
    return getReport({ name: "voucher-codes-exposed-data", rewardId, startDateStr, endDateStr, dateAggStr });
  }

  static async getVoucherCodesExposedChartTotals({ rewardId, startDateStr, endDateStr, dateAggStr }) {
    return getReport({ name: "voucher-codes-exposed-totals", rewardId, startDateStr, endDateStr, dateAggStr });
  }

  static async getVoucherPoolAsRewardCsv({ rewardId, startDateStr, endDateStr, dateAggStr }) {
    return getCsv({ name: "voucher-pool-as-reward-csv", rewardId, startDateStr, endDateStr });
  }

  static async getVoucherPoolNotAsRewardCsv({ rewardId, startDateStr, endDateStr, dateAggStr }) {
    return getCsv({ name: "voucher-pool-not-as-reward-csv", rewardId, startDateStr, endDateStr });
  }

  // static async getActionReferralReportCsv({ actionId, startDateStr, endDateStr, dateAggStr }) {
  //   const params = getParams({ startDateStr, endDateStr, dateAggStr });
  //   const response = await axios.get(`/report/action/${actionId}/action-referral-csv`, {
  //     params: params,
  //     responseType: "blob"
  //   });
  //   return response.data;
  // }
}
