import React from "react";
import PropTypes from "prop-types";

import InputField from "./InputField";

const InputFieldWithTokens = (props) => {
  let tokens = props.tokens;
  if (!!tokens && !Array.isArray(tokens)) {
    tokens = [props.tokens];
  }

  const getReplacedValue = () => {
    let value = props.value;

    if (!tokens) {
      return value;
    }

    tokens.forEach((tokenObj) => {
      value = value.replace(tokenObj.token, tokenObj.display);
    });

    return value;
  };

  const onChange = (event) => {
    let value = event.target.value;

    if (!!tokens) {
      if (tokens.some((tokenObj) => value.indexOf(tokenObj.display) === -1)) {
        return;
      }

      tokens.forEach((tokenObj) => {
        value = value.replace(tokenObj.display, tokenObj.token);
      });
    }

    props.onChange(event, value);
  };

  const additionalProps = { ...props };
  additionalProps.value = getReplacedValue();
  additionalProps.onChange = onChange;

  return <InputField {...additionalProps} />;
};

InputFieldWithTokens.propTypes = {
  isRequired: PropTypes.bool,
  tokens: PropTypes.oneOfType([
    PropTypes.shape({
      token: PropTypes.string,
      display: PropTypes.string
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        token: PropTypes.string,
        display: PropTypes.string
      })
    )
  ])
};

export default InputFieldWithTokens;
//
