import axios from "axios";

export default class iconsApi {
  static getInstance(type) {
    const url = `${process.env.REACT_APP_LOYALTY_IMAGES_HOST}/icons/-1/${type}/meta.json`;
    return axios.create({
      baseURL: url,
      transformRequest: [
        (data, headers) => {
          delete headers.common.Authorization;
          return data;
        }
      ]
    });
  }

  static async getTierIcons() {
    const instance = iconsApi.getInstance("tier");
    const response = await instance.get();
    return response.data;
  }

  static async getGeneralIcons() {
    const instance = iconsApi.getInstance("general");
    const response = await instance.get();
    return response.data;
  }

  static async getActionIcons() {
    const instance = iconsApi.getInstance("action");
    const response = await instance.get();
    return response.data;
  }

  static async getRewardIcons() {
    const instance = iconsApi.getInstance("reward");
    const response = await instance.get();
    return response.data;
  }

  static async getBenefitsIcons() {
    const instance = iconsApi.getInstance("benefit");
    const response = await instance.get();
    return response.data;
  }

  static async getCustomerIcons(iconType) {
    const response = await axios.get(`/icons/${iconType}`);
    return response.data;
  }

  static async uploadCustomerIconFile(iconType, data) {
    const response = await axios.post(`/icons/${iconType}`, data, {
      headers: { "content-type": "image/png" }
    });
    return response.data;
  }

  static async deleteCustomerIcon(iconName, iconType) {
    const response = await axios.delete(`/icons/${iconType}/${iconName}`);
    return response;
  }

  static async updateCustomerIconName(iconName, iconNewName, iconType) {
    const response = await axios.put(`/icons/${iconType}/${iconName}/${iconNewName}`);
    return response;
  }
}
