import some from "lodash/some";

import { convertObjectToOptionsArray } from "../helpers/formHelper";
import moment from "moment";
import actionService from "./actionService";
import tierService from "./tierService";
import benefitService from "./benefitService";

import {
  REWARD_STATUS_ENUM,
  VOUCHER_TYPE_ENUM,
  MASTER_REWARD_ENUM,
  FORM_STEPS_Rewards,
  FORM_STEP_ENUM,
  SPECIAL_TIER_ENUM,
  VALIDITY_TYPE_ENUM
} from "../constants/rewardConstants";
import { getMessage } from "../messages";

export default class rewardService {
  static getRewardTitle = (reward, onlyPoolName) => {
    if (this.isCashback(reward.masterReward)) {
      return reward.voucherName;
    }
    return onlyPoolName ? reward.name : `${reward.name} / ${reward.voucherName}`;
  };

  static getRewardVoucherName = (reward) => (reward ? reward.voucherName : "");

  static getRedemptionValue = (rewardsById, rewardId) => {
    const reward = rewardsById[rewardId];

    if (!reward) {
      return null;
    }

    return reward.redemption.value;
  };

  static getRewardOptionsForSelect = (rewardsById, filterFunc) => {
    let validRewards = {};
    Object.keys(rewardsById)
      .filter((id) => rewardsById[id].isValid)
      .filter(filterFunc)
      .forEach((id) => (validRewards[id] = rewardsById));

    return convertObjectToOptionsArray(validRewards, (rewardId) => {
      const reward = rewardsById[rewardId];
      return rewardService.getRewardTitle(reward);
    });
  };

  static isRewardConnectedToTier = (reward) => {
    let result = false;
    for (const [tierId, tier] of Object.entries(reward.redemption.value)) {
      if (tier.pointsEnabled) {
        return true;
      }
    }
    return result;
  };

  static getVoucherForRewardSteps(reward) {
    if (rewardService.isRewardConnectedToTier(reward)) return FORM_STEPS_Rewards;
    else return FORM_STEPS_Rewards.filter((step) => step.id != FORM_STEP_ENUM.REDEMPTION_VALUE);
  }
  static getVoucherRewardOptionsForAction = (rewardsById) => {
    const rewardFilter = (rewardId) => rewardService.isVoucherForRewards(rewardsById[rewardId].masterReward);

    return rewardService.getRewardOptionsForSelect(rewardsById, rewardFilter);
  };

  static getRewardOptionsForOneTimeBenefit = (rewardsById) => {
    const benefitFilter = (rewardId) => {
      const reward = rewardsById[rewardId];
      return (
        rewardService.isVoucherForRewards(reward.masterReward) && reward.voucherType === VOUCHER_TYPE_ENUM.ONE_TIME
      );
    };
    return rewardService.getRewardOptionsForSelect(rewardsById, benefitFilter);
  };

  static getRewardOptionsForFixedYearlyBenefit = (rewardsById) => {
    const benefitFilter = (rewardId) => {
      const reward = rewardsById[rewardId];
      return (
        rewardService.isVoucherForRewards(reward.masterReward) && reward.voucherType === VOUCHER_TYPE_ENUM.FIXED_YEARLY
      );
    };
    return rewardService.getRewardOptionsForSelect(rewardsById, benefitFilter);
  };

  static getEnabledRedemptionTiers(redemptionValue, tiers) {
    const filterFunc = !!redemptionValue[SPECIAL_TIER_ENUM.ALL].pointsEnabled
      ? () => {
          return true;
        }
      : (tier) => !!redemptionValue[tier.id]?.pointsEnabled;
    return tiers.filter(filterFunc).map((tier) => tier.name);
  }

  static getRewardStatus = (reward, actions, fixedBenefits, additionalBenefits, tiers) => {
    const connectedFixedBenefitNames = fixedBenefits
      ? tierService.getBenefitsConnectedToReward(reward.id, fixedBenefits)
      : null;
    const connectedActionNames = actions ? actionService.getActionsConnectedToReward(reward.id, actions) : null;
    const connectedAdditionalBenefitsNames = additionalBenefits
      ? benefitService.getAdditionalBenefitsConnectedToReward(reward.id, additionalBenefits)
      : null;

    let connectedRedemptionTierNames;
    let redemptionOn = false;

    if (reward.redemption) {
      redemptionOn = rewardService.isRedemptionOn(reward.redemption.value);

      if (redemptionOn && tiers) {
        connectedRedemptionTierNames = rewardService.getEnabledRedemptionTiers(reward.redemption.value, tiers);
      }
    }

    if (!reward.isValid) {
      const availableCodes = reward.pool ? reward.pool.availableCodes : null;
      if (availableCodes === 0) {
        return {
          status: REWARD_STATUS_ENUM.EMPTY,
          redemptionOn,
          connectedActionNames,
          connectedFixedBenefitNames,
          connectedAdditionalBenefitsNames,
          connectedRedemptionTierNames
        };
      }

      //check for expired status
      if (
        (reward.validity?.deactivationDate && moment(reward.validity.deactivationDate) < moment()) ||
        (reward.validity?.voucherValidityType === VALIDITY_TYPE_ENUM.FIXED_DATE &&
          reward.validity?.voucherExpiryDate &&
          moment(reward.validity.voucherExpiryDate) < moment())
      ) {
        return {
          status: REWARD_STATUS_ENUM.EXPIRED,
          redemptionOn,
          connectedActionNames,
          connectedFixedBenefitNames,
          connectedAdditionalBenefitsNames,
          connectedRedemptionTierNames
        };
      }

      return {
        status: REWARD_STATUS_ENUM.INACTIVE,
        redemptionOn,
        connectedActionNames,
        connectedFixedBenefitNames,
        connectedAdditionalBenefitsNames,
        connectedRedemptionTierNames
      };
    }

    if (
      redemptionOn &&
      reward.validity?.offerValidity?.start &&
      moment(reward.validity.offerValidity.start) > moment()
    ) {
      return {
        status: REWARD_STATUS_ENUM.SCHEDULED,
        redemptionOn,
        connectedActionNames,
        connectedFixedBenefitNames,
        connectedAdditionalBenefitsNames,
        connectedRedemptionTierNames
      };
    }

    if (
      redemptionOn ||
      (connectedFixedBenefitNames && connectedFixedBenefitNames.length > 0) ||
      (connectedActionNames && connectedActionNames.length) > 0 ||
      (connectedAdditionalBenefitsNames && connectedAdditionalBenefitsNames.length > 0)
    ) {
      return {
        status: REWARD_STATUS_ENUM.ACTIVE,
        redemptionOn,
        connectedActionNames,
        connectedFixedBenefitNames,
        connectedAdditionalBenefitsNames,
        connectedRedemptionTierNames
      };
    }

    if (reward.pool && reward.pool.availableCodes > 0) {
      return {
        status: REWARD_STATUS_ENUM.READY_TO_USE
      };
    }

    return {
      status: REWARD_STATUS_ENUM.INACTIVE
    };
  };

  static getDefaultIcon = () => {
    return { displayName: "Price tag", fileName: "price-tag" };
  };

  static isPoolValidityDateValid = (reward) => {
    const deactivationDate = reward.validity.deactivationDate;

    if (deactivationDate) {
      const diff = moment(deactivationDate).diff(moment(), "minutes");
      return diff > 0;
    }

    return false;
  };

  static isPoolValidityAmountValid = (reward) => {
    const availableCodes = reward.pool ? reward.pool.availableCodes : null;

    if (availableCodes === 0) return false;

    return true;
  };

  static getCardExtraLabels = (reward) => {
    let extraLabels = [];
    if (rewardService.isCashback(reward.masterReward)) return extraLabels;

    if (reward.voucherType === VOUCHER_TYPE_ENUM.ONE_TIME) {
      extraLabels.push({
        type: "voucherType",
        text: getMessage("voucherType.oneTimeUse")
      });
    }

    if (reward.voucherType === VOUCHER_TYPE_ENUM.FIXED_YEARLY) {
      extraLabels.push({
        type: "voucherType",
        text: getMessage("voucherType.multiUse")
      });
    }

    return extraLabels;
  };

  static isRewardForPoints = (masterReward) => {
    return masterReward === MASTER_REWARD_ENUM.VOUCHERS_FOR_POINTS;
  };

  static isRewardForFree = (masterReward) => {
    return masterReward === MASTER_REWARD_ENUM.VOUCHERS_FOR_FREE;
  };

  static isVoucherForRewards = (masterReward) => {
    return masterReward === MASTER_REWARD_ENUM.VOUCHERS_FOR_REWARDS;
  };

  static isRedemptionOn = (redemptionValues) => {
    return some(redemptionValues, { pointsEnabled: true });
  };

  static getOptionsWithSelected = ({ allOptions, validOptions, selectedValue }) => {
    const exists = validOptions.some((r) => r.value === selectedValue);
    if (!exists) {
      const index = allOptions.findIndex((r) => r.value === selectedValue);
      if (index > -1) {
        let option = allOptions[index];
        option.disabled = true;
        validOptions.unshift(option);
      }
    }
    return validOptions;
  };

  static hasRedemptionValue(reward) {
    const redemptionValue = reward.redemption.value;
    if (!!redemptionValue) {
      return Object.keys(redemptionValue).some((tierId) => !!redemptionValue[tierId].pointsEnabled);
    }

    return false;
  }

  static getRewardValueType = (masterReward) => {
    if (masterReward === MASTER_REWARD_ENUM.VOUCHERS_FOR_POINTS) return "points";
    return "free";
  };

  static isCashback = (masterReward) => {
    return masterReward === MASTER_REWARD_ENUM.VOUCHERS_AS_CASHBACK;
  };

  static getNewRewardText = (masterReward) => {
    if (this.isCashback(masterReward)) return getMessage("voucherOverviewPage.createNew.vouchersAsCashback");
    return getMessage("voucherOverviewPage.createNew");
  };
}
