import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import InputField from "../../components/InputField";
import InputFieldWithTokens from "../../components/InputFieldWithTokens";
import Notification from "../../components/Notification";
import IconSelectInput from "../../components/IconSelectInput";
import CatalogSelectorInput from "../../components/CatalogSelectorInput";
import HeaderWithMessageTooltip from "../../components/HeaderWithMessageTooltip";
import MultiLangPopupOpener from "../../components/MultiLangPopupOpener";
import RadioButtonList from "../../components/RadioButtonList";
import PointsRatesFields from "../../views/TierSetup/PointsRatesFields";

import tierService from "../../services/tierService";
import planService from "../../services/planService";

import { getGeneralIconsUrl } from "../../helpers/envHelper";
import { getCurrencySymbol } from "../../helpers/currencyHelper";
import { getErrorMessage } from "../../validators";

import PurchaseBenefitsMessageImg from "../../assets/images/tier/purchase_benefit_message.png";
import PurchaseBenefitsCatalogMessageImg from "../../assets/images/tier/purcahse_benefit_catalog_message.png";

import iconsApi from "../../api/iconsApi";

import { tierType } from "../../types";
import { getMessage } from "../../messages";
import SelectField from "../../components/SelectFieldV2";

import { POINTS_TYPE_OPTIONS_POINTS_PLAN } from "../../constants/tierConstants";

const TierSetupPurchaseBenefits = (props) => {
  const {
    tier,
    onInputChange,
    errors,
    onIconInputChange,
    disabled,
    onShowPurchaseBenefitsMessageMultiLang,
    currencies,
    tokens,
    hasMultiLanguage,
    onDeleteCatalogBenefit,
    onAddCatalogBenefit,
    plan,
    onLocaltyCreditFromPurchasesChange,
    isPointSplitDisabled
  } = props;
  const { pointsBenefit, pointsCatalogBenefits } = { ...tier.purchaseBenefits };
  const pointsBenefitNameProp = "purchaseBenefits.pointsBenefit";
  const pointsCatalogBenefitsNameProp = "purchaseBenefits.pointsCatalogBenefits";

  const currency = currencies[0];
  const pointsSpendPointsRateName = `purchaseBenefits.pointsBenefit.pointsRates.${currency}.points`;
  const pointsSpendPointsRateValue =
    tier.purchaseBenefits.pointsBenefit &&
    tier.purchaseBenefits.pointsBenefit.pointsRates[currency] &&
    tier.purchaseBenefits.pointsBenefit.pointsRates[currency].points != null
      ? tier.purchaseBenefits.pointsBenefit.pointsRates[currency].points
      : 1;

  const isMultiCurrency = currencies.length > 1;

  const getCatalogDisabledItems = (benefitIndex, itemType) => {
    return tier.purchaseBenefits.pointsCatalogBenefits
      .filter((benefit, index) => benefitIndex != index && !!benefit[itemType])
      .map((benefit) => benefit[itemType])
      .flat();
  };
  const renderSingleCatalogBenefit = (benefit, index) => {
    return (
      <Fragment>
        <div className="m-b-25">
          <PointsRatesFields
            benefit={benefit}
            currencies={currencies}
            onInputChange={onInputChange}
            propNamePrefix={`${pointsCatalogBenefitsNameProp}.${index}`}
            errors={errors}
            disabled={disabled}
            plan={plan}
            tier={tier}
          />
        </div>

        <CatalogSelectorInput
          id={`catalog_selector_${index}`}
          label={getMessage("tierSetupPurchaseBenefits.catalogSelector.label")}
          name={`${pointsCatalogBenefitsNameProp}.${index}.categories`}
          title="Select brands and categories"
          onChange={({ brands, categories }) =>
            props.onCatalogSelectionChange({ benefitIndex: index, brands, categories })
          }
          initialBrandIds={benefit.brands}
          initialCategoryIds={benefit.categories}
          placeholder={getMessage("tierSetupPurchaseBenefits.catalogSelector.placeholder")}
          errorMsg={getErrorMessage(errors, `${pointsCatalogBenefitsNameProp}.${index}`)}
          disabled={disabled}
          disabledCategoryIds={getCatalogDisabledItems(index, "categories")}
          disabledBrandIds={getCatalogDisabledItems(index, "brands")}
          disabledItemsTooltip={getMessage("tierSetupPurchaseBenefits.catalogSelector.disabledItemsTooltip")}
        />

        {tierService.isPurchaseBenefitCatalogSelected(benefit) && !tierService.hasZeroPointsRate(benefit, currencies) && (
          <Fragment>
            <HeaderWithMessageTooltip
              headerText={getMessage("tierSetupPurchaseBenefits.benefitMessageHeader.title")}
              headerTooltip={getMessage("tierSetupPurchaseBenefits.benefitMessageHeader.tooltip")}
              messageTooltipText={getMessage("tierSetupPurchaseBenefits.benefitMessageHeader.tooltipText")}
              messageTooltipContent={`<img src="${PurchaseBenefitsCatalogMessageImg}"/>`}
            />

            <InputField
              label={getMessage("tierSetupPurchaseBenefits.benefitMessageTitle.label")}
              placeholder={getMessage("tierSetupPurchaseBenefits.benefitMessageTitle.placeholder")}
              name={`${pointsCatalogBenefitsNameProp}.${index}.title`}
              value={benefit.title}
              onChange={onInputChange}
              isRequired={true}
              errorMsg={
                getErrorMessage(errors, `${pointsCatalogBenefitsNameProp}.${index}.title`) ||
                getErrorMessage(errors, `${pointsCatalogBenefitsNameProp}.${index}.titleML`)
              }
              disabled={disabled}
              postfixButtons={
                hasMultiLanguage && (
                  <MultiLangPopupOpener
                    onClick={() => onShowPurchaseBenefitsMessageMultiLang({ isCatalog: true, index })}
                  />
                )
              }
            />

            <InputFieldWithTokens
              label={getMessage("tierSetupPurchaseBenefits.benefitMessageText.label")}
              placeholder={getMessage("tierSetupPurchaseBenefits.benefitMessageText.placeholder")}
              name={`${pointsCatalogBenefitsNameProp}.${index}.text`}
              value={benefit.text}
              onChange={onInputChange}
              isRequired={true}
              errorMsg={
                getErrorMessage(errors, `${pointsCatalogBenefitsNameProp}.${index}.text`) ||
                getErrorMessage(errors, `${pointsCatalogBenefitsNameProp}.${index}.textML`)
              }
              disabled={disabled}
              postfixButtons={
                hasMultiLanguage && (
                  <MultiLangPopupOpener
                    onClick={() => onShowPurchaseBenefitsMessageMultiLang({ isCatalog: true, index })}
                  />
                )
              }
              tokens={tokens}
              tooltip={!!tokens ? getMessage("tierSetupPurchaseBenefits.benefitMessageText.tooltip") : ""}
            />

            <IconSelectInput
              id="pointsCatalogBenefitIcon"
              label={getMessage("tierSetupPurchaseBenefits.benefitIcon.label")}
              name={`${pointsCatalogBenefitsNameProp}.${index}.icon`}
              displayName={benefit.icon && benefit.icon.displayName}
              fileName={benefit.icon && benefit.icon.fileName}
              iconPrefixUrl={getGeneralIconsUrl()}
              selectIconText={getMessage("tierSetupPurchaseBenefits.benefitIcon.selectIconText")}
              onChange={(icon) => onIconInputChange(`${pointsCatalogBenefitsNameProp}.${index}.icon`, icon)}
              loadPromise={iconsApi.getGeneralIcons}
              loadCustomerPromise={iconsApi.getCustomerIcons}
              iconType="general"
              disabled={disabled}
            />
          </Fragment>
        )}
      </Fragment>
    );
  };

  const TIER_PURCHASE_BENEFIT_SPEND_OPTIONS = [
    {
      name: getMessage("tierSetupPurchaseBenefits.tierPurchaseBenefitSpend.options.equal", {
        symbol: getCurrencySymbol(currencies[0])
      }),
      value: 1
    },
    {
      name: getMessage("tierSetupPurchaseBenefits.tierPurchaseBenefitSpend.options.selectedItems"),
      value: 0
    }
  ];

  const disableAddNewCatalogBenefit = !tierService.isPurchaseBenefitCatalogSelected(
    tier.purchaseBenefits.pointsCatalogBenefits[tier.purchaseBenefits.pointsCatalogBenefits.length - 1]
  );

  const getPointsCatalogBenefitsTooltip = (index) => {
    if (!planService.isSpendPlan(plan)) {
      if (index === 0) {
        return getMessage("tierSetupPurchaseBenefits.pointsCatalogBenefitsTooltip.points.first");
      } else {
        return getMessage("tierSetupPurchaseBenefits.pointsCatalogBenefitsTooltip.points.other");
      }
    } else {
      if (index === 0) {
        return getMessage("tierSetupPurchaseBenefits.pointsCatalogBenefitsTooltip.spend.first");
      } else {
        return getMessage("tierSetupPurchaseBenefits.pointsCatalogBenefitsTooltip.spend.other");
      }
    }
  };

  return (
    <div className="e-box">
      {isMultiCurrency && <Notification content={getMessage("tierSetupPurchaseBenefits.notification")} />}
      {!isPointSplitDisabled && planService.hasRedemption(plan) && (
        <div className="e-row">
          <div className="e-col-6">
            <h2>{getMessage("tierSetupPurchaseBenefits.pointsType.title")}</h2>
            <SelectField
              label={getMessage("tierSetupPurchaseBenefits.pointsType.label")}
              options={POINTS_TYPE_OPTIONS_POINTS_PLAN}
              name={"pointsType"}
              value={tier.pointsType}
              onChange={onInputChange}
              disabled={disabled}
              tooltip={getMessage("tierSetupPurchaseBenefits.pointsType.tooltip")}
              errorMsg={getErrorMessage(errors, "pointsType")}
            />
          </div>
        </div>
      )}
      <div className="e-row">
        <div className="e-col-6">
          <h2>
            {!planService.isSpendPlan(plan)
              ? getMessage("tierSetupPurchaseBenefits.header.points")
              : getMessage("tierSetupPurchaseBenefits.header.spend")}{" "}
            <e-tooltip
              content={
                !planService.isSpendPlan(plan)
                  ? getMessage("tierSetupPurchaseBenefits.header.tooltip.points")
                  : getMessage("tierSetupPurchaseBenefits.header.tooltip.spend")
              }
              type="helper"
            />
          </h2>

          {!planService.isSpendPlan(plan) && (
            <div className="m-b-25">
              <PointsRatesFields
                benefit={pointsBenefit}
                currencies={currencies}
                onInputChange={onInputChange}
                propNamePrefix={pointsBenefitNameProp}
                errors={errors}
                disabled={disabled}
                plan={plan}
                tier={tier}
              />
            </div>
          )}
          {planService.isSpendPlan(plan) && (
            <div className="m-b-25">
              <RadioButtonList
                label=""
                name={pointsSpendPointsRateName}
                value={pointsSpendPointsRateValue}
                options={TIER_PURCHASE_BENEFIT_SPEND_OPTIONS}
                onChange={onLocaltyCreditFromPurchasesChange}
                disabled={disabled}
              />
            </div>
          )}

          {!tierService.hasZeroPointsRate(pointsBenefit, currencies) && (
            <Fragment>
              <HeaderWithMessageTooltip
                headerText={getMessage("tierSetupPurchaseBenefits.benefitMessageHeader.title")}
                headerTooltip={getMessage("tierSetupPurchaseBenefits.benefitMessageHeader.tooltip")}
                messageTooltipText={getMessage("tierSetupPurchaseBenefits.benefitMessageHeader.tooltipText")}
                messageTooltipContent={`<img src="${PurchaseBenefitsMessageImg}"/>`}
              />

              <InputField
                label={getMessage("tierSetupPurchaseBenefits.benefitMessageTitle.label")}
                placeholder={getMessage("tierSetupPurchaseBenefits.benefitMessageTitle.placeholder")}
                name={`${pointsBenefitNameProp}.title`}
                value={pointsBenefit.title}
                onChange={onInputChange}
                isRequired={true}
                errorMsg={
                  getErrorMessage(errors, `${pointsBenefitNameProp}.title`) ||
                  getErrorMessage(errors, `${pointsBenefitNameProp}.titleML`)
                }
                disabled={disabled}
                postfixButtons={
                  hasMultiLanguage && (
                    <MultiLangPopupOpener
                      onClick={() => onShowPurchaseBenefitsMessageMultiLang({ isCatalog: false })}
                    />
                  )
                }
              />

              <InputFieldWithTokens
                label={getMessage("tierSetupPurchaseBenefits.benefitMessageText.label")}
                placeholder={getMessage("tierSetupPurchaseBenefits.benefitMessageText.placeholder")}
                name={`${pointsBenefitNameProp}.text`}
                value={pointsBenefit.text}
                onChange={onInputChange}
                isRequired={true}
                errorMsg={
                  getErrorMessage(errors, `${pointsBenefitNameProp}.text`) ||
                  getErrorMessage(errors, `${pointsBenefitNameProp}.textML`)
                }
                disabled={disabled}
                postfixButtons={
                  hasMultiLanguage && (
                    <MultiLangPopupOpener
                      onClick={() => onShowPurchaseBenefitsMessageMultiLang({ isCatalog: false })}
                    />
                  )
                }
                tokens={tokens}
                tooltip={!!tokens ? getMessage("tierSetupPurchaseBenefits.benefitMessageText.tooltip") : ""}
              />

              <IconSelectInput
                id="pointsBenefitIcon"
                label={getMessage("tierSetupPurchaseBenefits.benefitIcon.label")}
                name={`${pointsBenefitNameProp}.icon`}
                displayName={pointsBenefit.icon && pointsBenefit.icon.displayName}
                fileName={pointsBenefit.icon && pointsBenefit.icon.fileName}
                iconPrefixUrl={getGeneralIconsUrl()}
                selectIconText={getMessage("tierSetupPurchaseBenefits.benefitIcon.selectIconText")}
                onChange={(icon) => onIconInputChange(`${pointsBenefitNameProp}.icon`, icon)}
                loadPromise={iconsApi.getGeneralIcons}
                loadCustomerPromise={iconsApi.getCustomerIcons}
                iconType="general"
                disabled={disabled}
              />
            </Fragment>
          )}
        </div>
        <div className="e-col-6">
          {pointsCatalogBenefits.map((benefit, index) => {
            return (
              <div className={classNames({ "m-t-40": index > 0 })} key={index}>
                <div className="e-grid e-grid-between">
                  <div className="e-cell">
                    <h2>
                      {!planService.isSpendPlan(plan)
                        ? getMessage("tierSetupPurchaseBenefits.catalogBenefit.title.points")
                        : getMessage("tierSetupPurchaseBenefits.catalogBenefit.title.spend")}
                      <e-tooltip content={getPointsCatalogBenefitsTooltip(index)} type="helper" />
                    </h2>
                  </div>
                  {index > 0 && (
                    <div className="e-cell">
                      <e-icon
                        className="e-clickable"
                        icon="ac-icon_delete"
                        onClick={() => onDeleteCatalogBenefit(index)}
                      ></e-icon>
                    </div>
                  )}
                </div>
                {renderSingleCatalogBenefit(benefit, index)}
              </div>
            );
          })}

          <div className="m-b-25">
            <div
              className={classNames("e-btn", { "e-btn-disabled": disableAddNewCatalogBenefit })}
              onClick={disableAddNewCatalogBenefit ? null : onAddCatalogBenefit}
            >
              <e-icon icon="plus"></e-icon>
              {!planService.isSpendPlan(plan)
                ? getMessage("tierSetupPurchaseBenefits.catalogBenefit.addNewBtn.points")
                : getMessage("tierSetupPurchaseBenefits.catalogBenefit.addNewBtn.spend")}
            </div>
          </div>
        </div>
        <div className="e-col e-col__separator" />
      </div>
    </div>
  );
};

TierSetupPurchaseBenefits.propTypes = {
  tier: tierType.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onIconInputChange: PropTypes.func.isRequired,
  onCatalogSelectionChange: PropTypes.func.isRequired,
  //onShowCurrencyPointsPopup: PropTypes.func.isRequired,
  onShowCurrencyPointsPopupCatalog: PropTypes.func.isRequired,
  onShowPurchaseBenefitsMessageMultiLang: PropTypes.func,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
  currencies: PropTypes.array,
  tokens: PropTypes.object,
  hasMultiLanguage: PropTypes.bool,
  onDeleteCatalogBenefit: PropTypes.func,
  onAddCatalogBenefit: PropTypes.func
};

export default TierSetupPurchaseBenefits;
