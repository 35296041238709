import React, { Component, Fragment } from "react";
import classNames from "classnames";
import pluralize from "pluralize";
import PropTypes from "prop-types";

import PopupContainer from "./PopupContainer";
import InputField from "./InputField";
import CatalogSelector from "./CatalogSelector";
import { getMessage, getPluralCardinalMessage } from "../messages";

class CatalogSelectorInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      selectedBrandIds: props.initialBrandIds || [],
      selectedCategoryIds: props.initialCategoryIds || [],
      selectedItemIds: props.initialItemIds || []
    };
  }

  onShowDialog() {
    this.setState({
      showDialog: true
    });
  }

  onPopupClose() {
    this.setState({
      showDialog: false
    });
  }

  onCatalogSelectorSave({ brands, categories, items }) {
    this.setState({
      selectedBrandIds: brands,
      selectedCategoryIds: categories,
      selectedItemIds: items
    });

    this.props.onChange({ brands, categories, items });
  }

  getInputValue() {
    const { selectedBrandIds, selectedCategoryIds, selectedItemIds } = this.state;

    return this.getSelectedText({
      brands: selectedBrandIds,
      categories: selectedCategoryIds,
      items: selectedItemIds
    });
  }

  getSelectedText({ brands, categories, items }) {
    // let messageObj = {
    //   brandVal: brands.length,
    //   catAndShow: (brands.length > 0 && categories.length > 0).toString(),
    //   catVal: categories.length,
    //   prodAndShow: ((brands.length > 0 || categories.length > 0) && items.length > 0).toString(),
    //   prodVal: items.length,
    //   anySelected: (items.length + categories.length + brands.length > 0).toString()
    // };

    let itemsTextArr = [];
    if (brands.length > 0) {
      itemsTextArr.push(getPluralCardinalMessage("catalogSelector.brands", brands.length, { number: brands.length }));
    }
    if (categories.length > 0) {
      itemsTextArr.push(
        getPluralCardinalMessage("catalogSelector.category", categories.length, { number: categories.length })
      );
    }
    if (items.length > 0) {
      itemsTextArr.push(getPluralCardinalMessage("catalogSelector.product", items.length, { number: items.length }));
    }

    let firstItemsTexts;
    let lastItemText;

    if (itemsTextArr.length === 0) {
      return "";
    } else if (itemsTextArr.length === 1) {
      firstItemsTexts = itemsTextArr[0];
    } else {
      lastItemText = itemsTextArr[itemsTextArr.length - 1];
      firstItemsTexts = [...itemsTextArr].splice(0, itemsTextArr.length - 1).join(", ");
    }

    const messageId =
      itemsTextArr.length === 1 ? "catalogSelector.selected.single" : "catalogSelector.selected.multiple";

    let message = getMessage(messageId, {
      items: firstItemsTexts,
      lastItems: lastItemText
    });

    return message;
  }

  render() {
    const { props } = this;

    const className = classNames("e-field", props.className);
    return (
      <div className={className}>
        <label className="e-field__label">
          {props.label}
          {props.tootip ? <e-tooltip content={props.tooltip} type="helper" /> : null}
        </label>
        <div className="e-grid e-grid-wrap e-grid-small">
          <div className="e-cell e-cell-9 e-cell-small">
            <InputField
              value={this.getInputValue()}
              readOnly={true}
              disabled={props.disabled}
              placeholder={props.placeholder}
              errorMsg={props.errorMsg}
              noMargin={true}
              labelClassName="no-margin"
            />
          </div>
          <div className="e-cell e-cell-3 e-cell-small">
            <a className="e-btn e-btn-secondary e-btn-fullwidth" onClick={this.onShowDialog.bind(this)}>
              Select from catalog
            </a>
          </div>
        </div>

        <PopupContainer
          id={props.id || "catalo_selector_popup"}
          title={props.title}
          show={this.state.showDialog}
          width={"1170px"}
          height={"740px"}
          onClose={this.onPopupClose.bind(this)}
        >
          {this.state.showDialog ? (
            <CatalogSelector
              onSave={this.onCatalogSelectorSave.bind(this)}
              onCancel={this.onPopupClose.bind(this)}
              initialBrandIds={props.initialBrandIds}
              initialCategoryIds={props.initialCategoryIds}
              initialItemIds={props.initialItemIds}
              disabledBrandIds={props.disabledBrandIds}
              disabledCategoryIds={props.disabledCategoryIds}
              disabledItemsTooltip={props.disabledItemsTooltip}
              allowItemsSelection={props.allowItemsSelection}
              getSelectedTextFunc={this.getSelectedText}
              disabled={props.disabled}
            />
          ) : null}
        </PopupContainer>
      </div>
    );
  }
}

CatalogSelectorInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  initialBrandIds: PropTypes.array,
  initialCategoryIds: PropTypes.array,
  initialItemIds: PropTypes.array,
  disabledBrandIds: PropTypes.array,
  disabledCategoryIds: PropTypes.array,
  disabledItemsTooltip: PropTypes.string,
  allowItemsSelection: PropTypes.bool,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMsg: PropTypes.string
};

export default CatalogSelectorInput;
