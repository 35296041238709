import { shape, bool, string, number, array, arrayOf, oneOfType } from "prop-types";

export const planType = shape({
  type: string,
  name: string,
  pointsName: string,
  launchedAt: string,
  validity: shape({
    start: string,
    end: string
  }),
  rules: shape({
    rewardsAllocationDelay: shape({
      type: string,
      duration: number
    }).isRequired,
    balancePointsExpiration: shape({
      type: string,
      duration: number,
      disabled: bool
    }).isRequired,
    pointsAboutToExpire: shape({
      type: string,
      duration: number
    }).isRequired,
    yearlyCalculation: string.isRequired,
    optIn: string.isRequired,
    launchType: string.isRequired,
    contactDistribution: string.isRequired,
    keepStatus: bool.isRequired
  })
});

export const tierType = shape({
  name: string.isRequired,
  status: string,
  settings: shape({
    points: oneOfType([number, string]),
    purchases: oneOfType([number, string]),
    subscr: shape({
      points: string
    }),
    icon: shape({
      name: string,
      url: string
    })
  }).isRequired,
  restrictions: shape({
    tierDropout: string
  }),
  purchaseBenefits: shape({
    pointsBenefit: shape({
      points: number,
      title: string,
      text: string,
      icon: shape({
        name: string,
        url: string
      })
    }),
    pointsCatalogBenefit: shape({
      points: number,
      title: string,
      text: string,
      icon: shape({
        name: string,
        url: string
      }),
      categories: arrayOf(string),
      brands: arrayOf(string)
    })
  }),
  fixedBenefits: arrayOf(
    shape({
      name: string,
      type: string,
      rewardId: string,
      eventActionId: string,
      custom: string
    })
  )
});

export const actionType = shape({
  isDefault: bool,
  actionType: string,
  activated: oneOfType([bool, string]),
  name: string.isRequired,
  tactic: string,
  description: string.isRequired,
  reward: shape({
    redemptionRewardId: string,
    value: shape({
      tierId: shape({
        multiplyPoints: string,
        multiplyPointsEnabled: bool,
        points: number,
        pointsEnabled: bool,
        rewardPool: string,
        rewardPoolEnabled: bool,
        exclusiveAccess: string,
        exclusiveAccessEnabled: bool,
        statusUpgrade: string,
        statusUpgradeEnabled: bool
      })
    })
  }),
  content: shape({
    member: shape({
      blockMessage: string.isRequired,
      ctaButton: string.isRequired,
      ctaUrl: string.isRequired
    }),
    nonMember: shape({
      blockMessage: string.isRequired,
      ctaButton: string.isRequired,
      ctaUrl: string.isRequired
    }),
    notification: shape({
      url: string.isRequired,
      limitsOfUse: string
    }),
    rewardTokenName: string
  }).isRequired,
  scheduling: shape({
    pointsAllocationDelay: string,
    voucherObjective: string,
    limitsOfUse: string,
    enableInSmartBox: string,
    availability: shape({
      start: string,
      end: string
    }),
    validity: shape({
      type: string,
      duration: oneOfType([number, string])
    })
  }),
  promotion: shape({
    usageType: string.isRequired,
    type: string.isRequired,
    catalogSelection: shape({
      categories: arrayOf(string).isRequired,
      items: arrayOf(string).isRequired
    }),
    showInWallet: bool
  })
});

export const rewardType = shape({
  name: string.isRequired,
  voucherType: string,
  voucherName: string,
  status: string,
  redemption: shape({
    message: shape({
      title: string,
      text: string,
      ctaText: string,
      valueToken: string
    }),
    value: shape({
      tierId: shape({
        points: number,
        pointsEnabled: bool
      })
    }),
    limitType: string,
    limitValue: number
  }).isRequired,
  validity: shape({
    poolValidity: string,
    voucherValidityType: string,
    timeValidity: shape({
      type: string,
      duration: oneOfType([number, string])
    }),
    voucherExpiryDate: string,
    refillReminder: oneOfType([number, string]),
    defaultMessage: string
  }),
  blocking: shape({
    exactAccessUrl: string,
    startsWithAccessUrl: string,
    isStartsWith: bool,
    landingPageUrl: string
  }),
  editor: shape({
    editorType: string.isRequired,
    styles: shape({}),
    content: shape({}),
    htmlFiles: shape({})
  }),
  exclusiveAccessValidity: shape({
    exclusivityType: string,
    blockingValidity: shape({
      start: string,
      end: string
    }),
    accessValidityType: string,
    accessValidity: shape({
      type: string,
      duration: oneOfType([number, string])
    }),
    accessExpiryDate: string,
    isActive: bool
  })
});

export const benefitType = shape({
  id: string,
  name: string,
  type: string,
  rewardId: string,
  exclusiveAccessIds: arrayOf(string),
  eventActionId: string,
  custom: string,
  tiers: arrayOf(
    shape({
      enabled: bool,
      rewardId: string,
      exclusiveAccessIds: arrayOf(string)
    })
  ),
  targetMarkets: arrayOf(string)
});
