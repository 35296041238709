import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import sanitizeHtml from "sanitize-html";
import PropTypes from "prop-types";

import FileInputField from "../components/FileInputField";
import PopupContainer from "../components/PopupContainer";
import Notification from "../components/Notification";
import InputNumberField from "../components/InputNumberField";

import rewardService from "../services/rewardService";

import { VOUCHERS_HELP_CENTER_URL, REWARD_STATUS_ENUM } from "../constants/rewardConstants";

import rewardApi from "../api/rewardApi";
import { showErrorAlert } from "../actions/alertActions";

import { bytesToSize } from "../helpers/stringHelper";
import { openDeleteDialog } from "../helpers/dialogHelper";
import { getErrorMessage } from "../validators";

import { selectRewardById, selectActions, selectFixedBenefits, selectAdditionalBenefits } from "../reducers";
import { getMessage } from "../messages";
import Time from "../components/Time";

class RewardSetupUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numberOfCodes: 0,
      progress: 0,
      file: null,
      uploadSuccess: false,
      currPendingUpload: null,
      sampleCodes: null,
      showSampleCodeDialog: false,
      showUploadErrorDialog: false,
      showResetPoolDialog: false,
      uploadError: "",
      enableUpload: false,
      reward: this.props.reward,
      refillReminder: this.props.refillReminder
    };
  }

  onFileSelected(file) {
    if (file.error === "maxSize") {
      const uploadError = getMessage("rewardSetupUpload.error.fileSize");
      this.setState({
        uploadError,
        enableUpload: false
      });
      this.toggleUploadErrorDialog();
    } else if (file.size === 0) {
      const uploadError = getMessage("rewardSetupUpload.error.fileEmpty");
      this.setState({
        uploadError,
        enableUpload: false
      });
      this.toggleUploadErrorDialog();
    } else {
      this.setState({
        file,
        enableUpload: true
      });
    }
  }

  resetProgress() {
    this.setState({
      progress: 0
    });
  }

  onUploadClick() {
    if (!this.state.enableUpload) {
      return;
    }

    const { file } = this.state;

    let data = new FormData();
    data.append("file", file, file.name);
    data.append("numberOfSamples", 10);

    rewardApi
      .uploadVouchersFile(this.props.rewardId, data, this.onUploadProgress.bind(this))
      .then((res) => {
        this.setState({
          progress: 100
        });
        setTimeout(() => {
          this.resetProgress();
          let currPendingUpload = res.data;
          currPendingUpload.size = file.size;
          this.setState({
            currPendingUpload
          });
          this.props.onUploadPool();
        }, 1000);
      })
      .catch(() => {
        this.props.showErrorAlert(getMessage("rewardSetupUpload.error.upload"));
        this.resetProgress();
      });
  }

  onUploadProgress(progressEvent) {
    let percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);

    percent = Math.min(percent, 90);

    if (percent <= 90) {
      this.setState({
        progress: percent
      });
    }
  }

  onDeleteCurrentUpload() {
    const deleteCurrentUpload = () => {
      this.setState({
        currPendingUpload: null,
        file: null
      });
      this.props.onDeleteCurrentUpload();
    };

    openDeleteDialog(
      getMessage("rewardSetupUpload.deleteDialog.headline"),
      getMessage("rewardSetupUpload.deleteDialog.content"),
      deleteCurrentUpload
    );
  }

  onAddToPool() {
    this.props.onAddToPool(this.state.currPendingUpload);
    this.setState({
      currPendingUpload: null,
      file: null
    });
  }

  onPoolCodesPreview() {
    if (!this.props.currentReward) {
      return;
    }

    const pool = this.props.currentReward.pool;
    this.setState({
      sampleCodes: pool.uploads[pool.uploads.length - 1].sampleCodes
    });
    this.toggleSampleCodeDialog();
  }

  onUploadCodePreview() {
    this.setState({
      sampleCodes: this.state.currPendingUpload.sampleCodes
    });
    this.toggleSampleCodeDialog();
  }

  onResetPoolClick() {
    this.toggleResetPoolDialog();
  }

  resetPool() {
    const { rewardId } = this.props;
    this.props.onDeletePool(rewardId);
  }

  rednerNewUploadSection(file) {
    let contentLink = `<a target="_blank" href="${VOUCHERS_HELP_CENTER_URL}">${getMessage(
      "rewardSetupUpload.newUplad.contentLink"
    )}</a>`;
    let link = process.env.REACT_APP_LOYALTY_IMAGES_HOST.concat("/admin/sample.csv");
    let sampleLink = `<a href="${link}">${getMessage("rewardSetupUpload.newUplad.sampleLink")}</a>`;
    return (
      <Fragment>
        <div className="e-col-12">
          <Notification content={getMessage("rewardSetupUpload.newUplad.notification.content", { contentLink })} />
        </div>
        <div className="e-col-6">
          <FileInputField
            label={getMessage("rewardSetupUpload.newUplad.label")}
            placeholder={getMessage("rewardSetupUpload.newUplad.placeholder")}
            tooltip={getMessage("rewardSetupUpload.newUplad.tooltip")}
            onFileSelected={this.onFileSelected.bind(this)}
            onUpload={this.onUploadClick.bind(this)}
            value={file ? file.name : ""}
            enableUpload={this.state.enableUpload}
            maxFileSize={15000000}
            disabled={this.props.viewMode}
            isRequired={true}
          />
          <p
            className="text-size-small m-n-t-10"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(getMessage("rewardSetupUpload.newUplad.downloadSample", { sampleLink }))
            }}
          ></p>
        </div>
      </Fragment>
    );
  }

  renderPendingUploadSection() {
    const { currPendingUpload } = this.state;

    return (
      <div className="e-col-12">
        <label className="e-field__label">Codes uploaded</label>
        <table className="e-table e-table-bordered e-table-verticalcentered" data-e-version="2">
          <thead>
            <tr>
              <th className="e-table__col e-table__col-medium">
                {getMessage("rewardSetupUpload.pendingUpload.table.header.fileName")}
              </th>
              <th className="e-table__col e-table__col-medium">
                {getMessage("rewardSetupUpload.pendingUpload.table.header.fileSize")}
              </th>
              <th className="e-table__col e-table__col-medium">
                {getMessage("rewardSetupUpload.pendingUpload.table.header.numCodes")}
              </th>
              <th className="e-table__col e-table__col-actions" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{currPendingUpload.fileName}</td>
              <td>{bytesToSize(currPendingUpload.size)}</td>
              <td>
                <e-numeric value={currPendingUpload.numberOfCodes} />
              </td>

              {this.renderUploadDataTableActionsCol()}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  renderUploadDataTableActionsCol() {
    const { state, props } = this;
    return (
      <td className="e-table__col e-table__col-actions">
        <e-tooltip valign="baseline" content={getMessage("rewardSetupUpload.poolStatus.preview.tooltip")}>
          <a className="e-svgclickfix" onClick={this.onUploadCodePreview.bind(this)}>
            <e-icon icon="eye" type="table" />
          </a>
        </e-tooltip>
        <e-tooltip valign="baseline" content={getMessage("delete")}>
          <a className="e-svgclickfix" onClick={this.onDeleteCurrentUpload.bind(this)}>
            <e-icon icon="trash-o" type="table" />
          </a>
        </e-tooltip>
        <a className="e-btn e-btn-small e-btn-primary m-l-15" onClick={this.onAddToPool.bind(this)}>
          Add to pool
        </a>
      </td>
    );
  }

  renderPoolStatusSection() {
    if (!this.props.currentReward) {
      return null;
    }

    let deleteIconConfig = {
      deleteIconClassName: "e-svgclickfix",
      disabledToolTip: false,
      iconColor: "title",
      onClick: this.onResetPoolClick.bind(this)
    };

    if (
      this.props.statusObj.status === REWARD_STATUS_ENUM.ACTIVE ||
      this.props.statusObj.status === REWARD_STATUS_ENUM.EMPTY ||
      this.props.viewMode
    ) {
      deleteIconConfig.deleteIconClassName = "";
      deleteIconConfig.disabledToolTip = true;
      deleteIconConfig.iconColor = "disabled";
      deleteIconConfig.onClick = () => {};
    }

    const pool = this.props.currentReward.pool;
    if (!pool) {
      return null;
    }

    if (!pool.uploads || pool.uploads.length === 0) {
      return null;
    }

    const rewardStatus = rewardService.getRewardStatus(
      this.props.currentReward,
      this.props.actions,
      this.props.fixedBenefits,
      this.props.additionalBenefits
    );

    const firstUploadTimeIso = new Date(pool.uploads[0].uploadedAt).toISOString();
    const lastUploadTimeIso = new Date(pool.uploads[pool.uploads.length - 1].uploadedAt).toISOString();

    return (
      <div className="m-b-30 m-t-25">
        <label className="e-field__label">{getMessage("rewardSetupUpload.poolStatus.codeStatusLabel")}</label>
        <table className="e-table e-table-bordered e-table-verticalcentered" data-e-version="2">
          <thead>
            <tr>
              <th className="e-table__col e-table__col-medium">
                {getMessage("rewardSetupUpload.poolStatus.table.header.currentlyAvailable")}
              </th>
              <th className="e-table__col e-table__col-medium">
                {getMessage("rewardSetupUpload.poolStatus.table.header.numUploads")}
              </th>
              <th className="e-table__col e-table__col-medium">
                {getMessage("rewardSetupUpload.poolStatus.table.header.totalCodes")}
              </th>

              <th className="e-table__col e-table__col-medium">
                {getMessage("rewardSetupUpload.poolStatus.table.header.firstUplaod")}
              </th>
              <th className="e-table__col e-table__col-medium">
                {getMessage("rewardSetupUpload.poolStatus.table.header.lastUplaod")}
              </th>
              <th className="e-table__col e-table__col-medium">
                {getMessage("rewardSetupUpload.poolStatus.table.header.poolStatus")}
              </th>
              <th className="e-table__col e-table__col-actions" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <e-numeric value={pool.availableCodes} />
              </td>
              <td>
                <e-numeric value={pool.uploads.length} />
              </td>
              <td>
                <e-numeric value={pool.totalCodes} />
              </td>
              <td>
                <Time value={firstUploadTimeIso} />
              </td>
              <td>
                <Time value={lastUploadTimeIso} />
              </td>
              <td>{rewardStatus.status}</td>
              <td className="e-table__col e-table__col-actions">
                <e-tooltip valign="baseline" content={getMessage("rewardSetupUpload.poolStatus.preview.tooltip")}>
                  <a className="e-svgclickfix" onClick={this.onPoolCodesPreview.bind(this)}>
                    <e-icon icon="eye" type="table" />
                  </a>
                </e-tooltip>
                <e-tooltip
                  valign="baseline"
                  content={getMessage("rewardSetupUpload.poolStatus.delete.tooltip")}
                  disabled={deleteIconConfig.disabledToolTip}
                  onClick={deleteIconConfig.onClick}
                >
                  <a className={deleteIconConfig.deleteIconClassName} onClick={() => {}}>
                    <e-icon icon="e-clear" type="table" color={deleteIconConfig.iconColor} />
                  </a>
                </e-tooltip>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  renderSampleCodesContent(sampleCodes) {
    if (!sampleCodes) {
      return <Fragment />;
    }

    return (
      <Fragment>
        <div className="m-b-20">{getMessage("rewardSetupUpload.sampleCodes.content")}</div>
        <div>
          {sampleCodes.map((code) => (
            <div key={code}>{code}</div>
          ))}
        </div>
      </Fragment>
    );
  }

  renderUploadErrorContent(error) {
    return (
      <Fragment>
        <div>{getMessage("rewardSetupUpload.popupUploadError.content")}</div>
        <div className="text-color-danger">{error}</div>
      </Fragment>
    );
  }

  toggleSampleCodeDialog() {
    this.setState({
      showSampleCodeDialog: !this.state.showSampleCodeDialog
    });
  }

  toggleUploadErrorDialog() {
    this.setState({
      showUploadErrorDialog: !this.state.showUploadErrorDialog
    });
  }

  toggleResetPoolDialog() {
    this.setState({
      showResetPoolDialog: !this.state.showResetPoolDialog
    });
  }

  render() {
    const { file, progress, currPendingUpload, sampleCodes, uploadError } = this.state;

    return (
      <div className="e-box" style={{ minHeight: 280 }}>
        <div className="e-row">
          {currPendingUpload ? this.renderPendingUploadSection() : this.rednerNewUploadSection(file)}

          {!currPendingUpload && progress > 0 ? (
            <div className="e-col-12">
              <div className="e-progress">
                <div
                  className="e-progress__bar"
                  data-e-percent={this.state.progress}
                  style={{ width: `${this.state.progress}%` }}
                />
              </div>
            </div>
          ) : null}

          <div className="e-col-12">{this.renderPoolStatusSection()}</div>
        </div>

        <PopupContainer
          id="previewPoolSampleCodes"
          title={getMessage("rewardSetupUpload.previewPoolSampleCodes.title")}
          show={this.state.showSampleCodeDialog}
          buttons={[
            {
              text: getMessage("closeBtn.label"),
              isClose: true
            }
          ]}
          onClose={this.toggleSampleCodeDialog.bind(this)}
        >
          {this.renderSampleCodesContent(sampleCodes)}
        </PopupContainer>

        <PopupContainer
          id="popupUploadError"
          title={getMessage("rewardSetupUpload.popupUploadError.title")}
          show={this.state.showUploadErrorDialog}
          buttons={[
            {
              text: "Upload new file",
              className: "e-btn-primary",
              isClose: true
            }
          ]}
          onClose={this.toggleUploadErrorDialog.bind(this)}
        >
          {this.renderUploadErrorContent(uploadError)}
        </PopupContainer>

        <PopupContainer
          id="popupResetPool"
          title={getMessage("rewardSetupUpload.popupResetPool.title")}
          show={this.state.showResetPoolDialog}
          buttons={[
            {
              text: getMessage("cancelBtn.label"),
              isClose: true
            },
            {
              text: getMessage("resetBtn.label"),
              isClose: true,
              onClick: this.resetPool.bind(this)
            }
          ]}
          onClose={this.toggleResetPoolDialog.bind(this)}
        >
          <div>{getMessage("rewardSetupUpload.popupResetPool.notice")}</div>
        </PopupContainer>
        {this.props.currentReward?.pool?.availableCodes > 0 && (
          <div>
            <InputNumberField
              label={getMessage("rewardSetupUpload.refillReminder.label")}
              type="number"
              name="validity.refillReminder"
              value={this.props.refillReminder}
              onChange={this.props.onInputChange}
              errorMsg={getErrorMessage(this.props.errors, "validity.refillReminder")}
              disabled={this.props.viewMode}
              tooltip={getMessage("rewardSetupUpload.refillReminder.tooltip")}
              isRequired={true}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  if (ownProps.rewardId) {
    return {
      currentReward: selectRewardById(state, ownProps.rewardId),
      actions: selectActions(state),
      fixedBenefits: selectFixedBenefits(state),
      additionalBenefits: selectAdditionalBenefits(state)
    };
  }

  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    showErrorAlert: (message) => {
      dispatch(showErrorAlert(message));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RewardSetupUpload);

RewardSetupUpload.propTypes = {
  rewardId: PropTypes.string,
  statusObj: PropTypes.object,
  onInputChange: PropTypes.func.isRequired,
  onAddToPool: PropTypes.func.isRequired,
  onDeletePool: PropTypes.func.isRequired,
  onDeleteCurrentUpload: PropTypes.func.isRequired,
  onUploadPool: PropTypes.func.isRequired,
  status: PropTypes.string
};
