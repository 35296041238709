import React, { Fragment, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import SelectField from "./SelectFieldV2";
import { getMessage } from "../messages";

const Pagination = ({
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
  onItemsPerPageChange,
  pageSizeMessageId,
  itemsDisplayedMessageId,
  pageSizeOptions,
  hideIfLessThenFirstPageSize
}) => {
  const element = useRef(null);
  const indexOfLastRecord = currentPage * itemsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;

  useEffect(() => {
    if (element.current?.addEventListener) {
      element.current.addEventListener("change", (event) => {
        onPageChange(event.detail.page);
      });
    }
  }, [totalItems]);

  if (hideIfLessThenFirstPageSize && totalItems <= pageSizeOptions[0]) {
    return null;
  }

  return (
    <div className="e-grid e-grid-between e-grid-vertical_center">
      <div className="e-cell">
        {getMessage(pageSizeMessageId, {
          input: (
            <SelectField
              inline={true}
              options={pageSizeOptions}
              value={itemsPerPage}
              extraClassName="e-margin-none"
              onChange={(event, customValue) => {
                onItemsPerPageChange(customValue);
                onPageChange(1);
              }}
            />
          )
        })}
      </div>
      <div className="e-cell">
        <div className="e-grid e-grid-vertical_center">
          <div className="e-cell">
            {getMessage(itemsDisplayedMessageId, {
              start: indexOfFirstRecord + 1,
              end: indexOfLastRecord > totalItems ? totalItems : indexOfLastRecord,
              total: totalItems
            })}
          </div>
          <div className="e-cell">
            <e-pagination
              ref={element}
              max-page={Math.ceil(totalItems / itemsPerPage)}
              current-page={currentPage}
            ></e-pagination>
          </div>
        </div>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  itemsPerPage: PropTypes.number,
  totalItems: PropTypes.number,
  onPageChange: PropTypes.func,
  onItemsPerPageChange: PropTypes.func,
  pageSizeMessageId: PropTypes.string,
  itemsDisplayedMessageId: PropTypes.string,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  hideIfLessThenFirstPageSize: PropTypes.bool
};

export default Pagination;
