import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classNames from "classnames";
import sanitizeHtml from "sanitize-html";
import { getMessage } from "../../messages";
import usePagination from "../../hooks/usePagination";

import Pagination from "../../components/Pagination";

import {
  ACTIVITY_TYPE,
  ACTIVITY_SUB_TYPE,
  ACTIVITY_ELEMENT_TYPE,
  ACTIVITY_ELEMENT_LABEL,
  EMPTY_CELL_TEXT,
  ACTIVITY_PAGE_SIZE_OPTIONS
} from "../../constants/memberProfileConstants";

import {
  renderStatusLabel,
  renderDateTimeText,
  getActivityName,
  getActivityElementsData,
  getActivitiesTotalPoints,
  getActivitiesPointsText,
  getActivityNameTooltip
} from "./activityRenderHelper";

import { formatPointsNameText, formatCashbackNameText } from "../../helpers/pointsHelper";

import planService from "../../services/planService";

import "../../assets/styles/MemberProfileActivity.scss";
import { useEffect } from "react";
import InputSearchField from "../../components/InputSearchField";

const MemberProfileActivity = ({ initialActivities, pointsNameObj, plan, emsAccountsMap }) => {
  const [searchValue, setSearchValue] = useState("");
  const { currentPage, setCurrentPage, itemsPerPage, setItemsPerPage, setItemList, getItemList, totalItems } =
    usePagination(ACTIVITY_PAGE_SIZE_OPTIONS[0]);

  useEffect(() => {
    const activities = transformActivities();
    setItemList(activities);
  }, [initialActivities, searchValue, itemsPerPage, currentPage, pointsNameObj]);

  function transformActivities() {
    let data = [];

    let activities = [...initialActivities];

    activities.forEach((activity, index) => {
      let totalPointArr = getActivitiesTotalPoints(activity, !planService.isSpendPlan(plan));
      let totalPointsText = "";
      if (
        activity.elements.some((elem) =>
          [ACTIVITY_ELEMENT_LABEL.NO_CHANGE, ACTIVITY_ELEMENT_LABEL.JOINED].includes(elem?.value?.label)
        )
      ) {
        totalPointsText = EMPTY_CELL_TEXT;
      } else {
        totalPointsText = totalPointArr
          .map((totalObj) =>
            getActivitiesPointsText({
              points: totalObj.totalPoints,
              pointsType: totalObj.pointsType,
              getPointsNameText,
              splitLoyaltyPoints: true
            })
          )
          .join("</br>");
      }
      data.push({
        activityType: activity.activityType,
        activitySubType: getActivitySubType(activity),
        dateFormatUS: moment(activity.activityTime).format("MM-DD-YYYY"),
        dateFormatEU: moment(activity.activityTime).format("DD.MM.YYYY"),
        timestamp: activity.activityTime,
        name: getActivityName(activity, getPointsNameText),
        totalPoints: totalPointsText,
        rewards: getActivityRewards(activity),
        orderId: activity.orderId || "",
        status: activity.status,
        nameTooltip: getActivityNameTooltip(activity),
        elements: getActivityElementsData(
          activity,
          getPointsNameText,
          !planService.isSpendPlan(plan),
          planService.isSpendPlan(plan),
          getCashbackNameText
        ),
        adminId: activity.adminId
      });
    });

    //filter by search
    if (searchValue.length > 1) {
      data = filterOnSearch(data);
    }

    //sort by timestamp
    data
      .sort((a, b) => {
        if (b.timestamp === a.timestamp) {
          return b.orderId < a.orderId ? 1 : -1;
        }
        return b.timestamp - a.timestamp;
      })
      .forEach((d, index) => (d.key = `${d.timestamp}_${index}`));

    return data;
  }

  function getActivitySubType(activity) {
    if (activity.activityType === ACTIVITY_TYPE.CUSTOMER_SUPPORT) {
      if (activity.elements.some((element) => element.type === ACTIVITY_ELEMENT_TYPE.SUPPORT_PLAN_CHANGE)) {
        return ACTIVITY_SUB_TYPE.PLAN_MIGRATION;
      }
    }

    return "";
  }

  function onSearchChange(event) {
    const value = event.target.value;
    setSearchValue(value);
    setCurrentPage(1);
  }

  function getPointsNameText(text, pointsValue) {
    return formatPointsNameText({ text, pointsValue, namesObj: pointsNameObj });
  }

  function getCashbackNameText(text, cashbackValue) {
    return formatCashbackNameText({ text, cashbackValue, namesObj: pointsNameObj });
  }

  function getActivityRewards(activity) {
    const elements = activity.elements
      .filter(
        (elem) =>
          [
            ACTIVITY_ELEMENT_TYPE.VOUCHER,
            ACTIVITY_ELEMENT_TYPE.EXCLUSIVE_ACCESS,
            ACTIVITY_ELEMENT_TYPE.STATUS_UPGRADE,
            ACTIVITY_ELEMENT_TYPE.REDEMPTION_FREE,
            ACTIVITY_ELEMENT_TYPE.REDEMPTION_POINTS
          ].includes(elem.type) && !elem.isCanceled
      )
      .sort((elem) => (elem.type === ACTIVITY_ELEMENT_TYPE.STATUS_UPGRADE ? -1 : 1));

    const rewards = elements
      .map((elem) => {
        if (elem.type === ACTIVITY_ELEMENT_TYPE.STATUS_UPGRADE) {
          return elem.value.tierName;
        } else if (
          elem.type === ACTIVITY_ELEMENT_TYPE.REDEMPTION_FREE ||
          elem.type === ACTIVITY_ELEMENT_TYPE.REDEMPTION_POINTS
        ) {
          return elem.name;
        } else return elem.value.text;
      })
      .sort();
    return rewards;
  }

  function filterOnSearch(activities) {
    const searchLower = searchValue.toLowerCase();

    const filterFunc = (activity) => {
      const hasOrderId = activity.orderId.toLowerCase().indexOf(searchLower) > -1;
      const hasName = activity.name.toLowerCase().indexOf(searchLower) > -1;
      const hasStatus = activity.status.toLowerCase().indexOf(searchLower) > -1;
      const hasDate =
        activity.dateFormatEU.indexOf(searchLower) > -1 || activity.dateFormatUS.indexOf(searchLower) > -1;

      return hasOrderId || hasDate || hasName || hasStatus;
    };

    return activities.filter((activity) => {
      return filterFunc(activity);
    });
  }

  function renderAccordionHeader() {
    return (
      <label className="e-accordion__title">
        <span className="font-weight-bold">{getMessage("groupProfileActivity.header.time")}</span>
        <span className="font-weight-bold">{getMessage("memberProfile.activity.title")}</span>
        <span className="font-weight-bold">{getMessage("groupProfileActivity.header.admin")}</span>
        <span className="font-weight-bold">
          {!planService.isSpendPlan(plan)
            ? getMessage("groupProfileActivity.header.totalPoints")
            : getMessage("groupProfileActivity.header.totalLoyaltyCredit")}
        </span>
        <span className="font-weight-bold">{getMessage("actionSetupRewards.title")}</span>
        <span className="font-weight-bold">{getMessage("groupProfileActivity.header.orderId")}</span>
        <span className="font-weight-bold">{getMessage("groupProfileActivity.header.status")}</span>
      </label>
    );
  }

  function renderAccordionItemTitle(activity, key, hasElements) {
    const id = `activity_accordion_${key}`;

    let rewards = "";
    let totalPoints = "";
    let orderId = "";

    if (activity.activitySubType !== ACTIVITY_SUB_TYPE.PLAN_MIGRATION) {
      totalPoints = activity.totalPoints;
      rewards = activity.rewards.length > 0 ? activity.rewards.join("<br />") : EMPTY_CELL_TEXT;
      orderId = activity.orderId || EMPTY_CELL_TEXT;
    }

    let inputAttr = {};
    if (!hasElements) {
      inputAttr["disabled"] = true;
    }

    return (
      <Fragment>
        <input type="checkbox" name="accordion-checkbox" id={id} {...inputAttr} />
        <label className="e-accordion__title" htmlFor={id}>
          <span>{renderDateTimeText({ timestamp: activity.timestamp })}</span>
          <span>
            {activity.name} {activity.nameTooltip ? <e-tooltip content={activity.nameTooltip} type="helper" /> : ""} {}
          </span>
          <span>{emsAccountsMap?.[activity.adminId]?.userName || EMPTY_CELL_TEXT}</span>
          <span
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(totalPoints)
            }}
          />

          <span
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(rewards)
            }}
          />
          <span>{orderId}</span>
          {renderStatusLabel(activity.status)}
        </label>
      </Fragment>
    );
  }

  function renderAccordionItemContent(elements, key) {
    if (elements.length === 0) {
      return null;
    }
    return (
      <div className="e-accordion__content">
        <table className="e-table e-table-bordered" data-e-version="2">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th>{getMessage("groupProfileActivity.header.time")}</th>
              <th>{getMessage("groupProfileActivity.header.activityType")}</th>
              <th>{getMessage("groupProfileActivity.header.reward")}</th>
              <th>{getMessage("groupProfileActivity.header.value")}</th>
              <th>{getMessage("groupProfileActivity.header.expiration")}</th>
            </tr>
          </thead>
          <tbody>
            {elements.map((element, index) => {
              let timestamp = element.timestamp;

              //if the time diff between two elements is less then 1 minute, don't display the time
              if (index > 0) {
                const momentA = moment(timestamp);
                const momentB = moment(elements[index - 1].timestamp);
                const duration = moment.duration(momentB.diff(momentA));
                if (duration.as("minutes") < 1) {
                  timestamp = "";
                }
              }

              const valueClassName = classNames({
                "text-color-danger": element.isCanceled || element.isReturned,
                "text-line-through": element.isCanceled
              });

              return (
                <tr key={`activity_${key}_element_${index}`}>
                  <td>{renderDateTimeText({ timestamp })}</td>
                  <td>
                    {element.type}{" "}
                    {element.nameTooltip ? <e-tooltip content={element.nameTooltip} type="helper" /> : ""}
                  </td>
                  <td>{element.rewardName || EMPTY_CELL_TEXT}</td>
                  <td>
                    <span
                      className={valueClassName}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(element.rewardValue)
                      }}
                    ></span>
                  </td>
                  <td className={valueClassName}>{element.expirationDateText}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  function renderAccordionItem(activity, key) {
    const elements = activity.elements;
    const hasElements = elements.length > 0;
    return (
      <Fragment key={`accordion-item-${key}`}>
        {renderAccordionItemTitle(activity, key, hasElements)}
        {renderAccordionItemContent(elements, key)}
      </Fragment>
    );
  }

  function renderAccordion() {
    return (
      <Fragment>
        {renderAccordionHeader()}
        {getItemList().map((activity) => {
          return renderAccordionItem(activity, activity.key);
        })}
      </Fragment>
    );
  }

  return (
    <div className="member-profile-activity">
      {" "}
      <div className="search-header">
        <label className="e-field__label e-field__label-inline">{getMessage("searchBtn.label")}</label>
        <e-tooltip placement="right" content={getMessage("memberProfileActivity.search.tooltip")} width="250">
          <InputSearchField
            value={searchValue}
            placeholder={getMessage("searchBtn.label")}
            onChange={onSearchChange}
            noMargin={true}
          />
        </e-tooltip>
      </div>
      <div className="e-accordion">{renderAccordion()}</div>
      <Pagination
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        onPageChange={setCurrentPage}
        onItemsPerPageChange={setItemsPerPage}
        pageSizeMessageId="memberProfile.activity.pagination.pageSize"
        itemsDisplayedMessageId="memberProfile.activity.pagination.recordsDisplayed"
        pageSizeOptions={ACTIVITY_PAGE_SIZE_OPTIONS}
        hideIfLessThenFirstPageSize={true}
      />
    </div>
  );
};

MemberProfileActivity.propTypes = {
  activities: PropTypes.array,
  pointsNameObj: PropTypes.object,
  emsAccountsMap: PropTypes.objectOf(
    PropTypes.shape({
      userName: PropTypes.string
    })
  )
};

export default MemberProfileActivity;
