import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const TextareaField = (props) => {
  const getValue = () => {
    const { tokenObj } = props;

    if (!tokenObj) {
      return props.value;
    }

    const value = props.value.replace(tokenObj.token, tokenObj.display);
    return value;
  };

  const onChange = (event) => {
    const { tokenObj } = props;

    if (!tokenObj) {
      props.onChange(event);
      return;
    }

    const value = event.target.value;
    if (value.indexOf(tokenObj.display) === -1) {
      return;
    }

    const newValue = value.replace(tokenObj.display, tokenObj.token);
    props.onChange(event, newValue);
  };

  let style = null;
  if (props.height) {
    style = {
      height: props.height
    };
  }

  let attr = [];
  if (props.disabled) {
    attr["disabled"] = true;
  }

  const textareaClassNames = classNames("e-input e-input-textarea", {
    "e-input-error": !!props.errorMsg
  });

  const renderInput = () => {
    return (
      <textarea
        className={textareaClassNames}
        name={props.name}
        value={getValue()}
        onChange={onChange}
        placeholder={props.placeholder}
        style={style}
        {...attr}
      />
    );
  };

  const renderInputWithPostfixButtons = (inputElm) => {
    return (
      <div>
        <span className="e-field__postfix__cell">{inputElm}</span>
        <span
          className="e-field__postfix__cell e-field__postfix__cell-short e-field__postfix__cell-shortsticky
      "
        >
          {props.postfixButtons}
        </span>
      </div>
    );
  };

  let inputElm = renderInput();
  if (props.postfixButtons) {
    inputElm = renderInputWithPostfixButtons(inputElm);
  }

  return (
    <div className="e-field">
      <label className={classNames("e-field__label", { "e-field__label-required": props.isRequired })}>
        {props.label}
        {props.tooltip ? <e-tooltip content={props.tooltip} type="helper" /> : null}
      </label>
      {inputElm}

      {props.errorMsg ? <span className="e-field__message e-field__message-error">{props.errorMsg}</span> : null}
    </div>
  );
};

TextareaField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  tooltip: PropTypes.string,
  height: PropTypes.string,
  errorMsg: PropTypes.string,
  disabled: PropTypes.bool,
  tokenObj: PropTypes.shape({
    token: PropTypes.string,
    display: PropTypes.string
  }),
  postfixButtons: PropTypes.object,
  isRequired: PropTypes.bool
};

export default TextareaField;
