import React, { useState, useEffect, useCallback, Fragment, useRef } from "react";
import { renderToString } from "react-dom/server";
import { useDispatch } from "react-redux";
import validate from "validate.js";
import { showErrorAlert, showSuccessAlert } from "../actions/alertActions";
import customerApi from "../api/customerApi";
import LoaderOverlay from "../components/LoaderOverlay";
import { getMessage } from "../messages";
import { TimeField } from "../components/TimeFields";
import SelectField from "../components/SelectFieldV2";
import EmptyState from "../components/EmptyState";

import { getErrorMessage, validateSingleValue } from "../validators";

import { PRODUCT_CATALOG_IMPORT_LINK, SALES_DATA_IMPORT_LINK } from "../constants/linkConstants";
import Notification from "../components/Notification";
import { updateStateOnInputChange } from "../helpers/formHelper";
import useStateCallback from "../hooks/useStateCallback";

export default function ProductCatalogConfiguration() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [errors, setErrors] = useState();
  const [config, setConfig] = useStateCallback({
    categorySeparator: "",
    startTimeImportUtc: 0,
    startTimeImportCET: 0,
    isImportActive: false
  });

  useEffect(() => {
    loadSettings();
  }, []);

  async function loadSettings() {
    setLoading(true);

    try {
      const importConfig = await customerApi.getProductCatalogConfiguration();
      setConfig(importConfig);
    } catch (error) {
      console.error(error);
      setLoadError(true);
    }

    setLoading(false);
  }

  function onInputChange(event, customValue) {
    if (event.persist) {
      event.persist();
    }

    setConfig((currentConfig) => {
      const newConfig = { ...currentConfig };
      const inputName = updateStateOnInputChange({ stateObj: newConfig, event, customValue });
      let newErrors = validateSingleValue(() => validateForm(newConfig), errors, inputName);
      setErrors(newErrors);
      return newConfig;
    });
  }

  function onStartTimeInputChange(event, timeStr) {
    const hour = timeStr ? parseInt(timeStr.split(":")[0]) : 0;
    onInputChange(event, hour);
  }

  function validateForm(configToValidate) {
    const { categorySeparator } = { ...configToValidate };

    const fieldsToValidate = {
      categorySeparator
    };

    const constraints = {
      categorySeparator: {
        presence: {
          allowEmpty: false,
          message: getMessage("securitySettings.productCatalog.categoryPathSeparator.isRequiredError")
        }
      }
    };

    const newErrors = validate(fieldsToValidate, constraints);
    return newErrors;
  }

  async function onSave() {
    const newErrors = validateForm(config);
    setErrors(newErrors);
    if (!newErrors) {
      setLoading(true);
      try {
        const newConfig = await customerApi.updateProductCatalogConfiguration(config);
        setConfig(newConfig);
        dispatch(showSuccessAlert(getMessage("securitySettings.productCatalog.savedSuccessfully")));
      } catch (error) {
        dispatch(showErrorAlert(getMessage("securitySettings.productCatalog.savedError")));
        console.error(error);
      }
      setLoading(false);
    } else {
      dispatch(showErrorAlert(getMessage("form.missingSettingsAlert")));
    }
  }

  if (loadError) {
    return (
      <EmptyState
        icon="exclamation"
        title={getMessage("securitySettings.error.general.title")}
        text={getMessage("securitySettings.error.general.text")}
      />
    );
  }

  return (
    <div className="box-padding">
      <Notification
        content={getMessage("securitySettings.productCatalog.topNotification", {
          link: renderToString(
            <a target="_blank" href={PRODUCT_CATALOG_IMPORT_LINK}>
              {getMessage("securitySettings.productCatalog.topNotification.linkText")}
            </a>
          )
        })}
      />
      <div className="e-row">
        <div className="e-col-6">
          <h2>{getMessage("securitySettings.productCatalog.pageTitle")}</h2>
          <SelectField
            label={getMessage("securitySettings.productCatalog.categoryPathSeparator.label")}
            value={config.categorySeparator}
            name="categorySeparator"
            options={[">", "/", "\\"]}
            onChange={onInputChange}
            tooltip={getMessage("securitySettings.productCatalog.categoryPathSeparator.tooltip")}
            errorMsg={getErrorMessage(errors, "categorySeparator")}
          />
          <TimeField
            label={getMessage("securitySettings.productCatalog.startTimeImport.label")}
            value={config.startTimeImportCET || 0}
            name="startTimeImportCET"
            showOnlyHour={true}
            tooltip={getMessage("securitySettings.productCatalog.startTimeImport.tooltip")}
            onChange={onStartTimeInputChange}
          />
          <SelectField
            label={getMessage("securitySettings.productCatalog.activation.label")}
            type="bool"
            value={config.isImportActive}
            name={"isImportActive"}
            onChange={onInputChange}
            options={[
              { name: getMessage("securitySettings.productCatalog.activation.options.activate"), value: true },
              { name: getMessage("securitySettings.productCatalog.activation.options.deactivate"), value: false }
            ]}
          />

          <button className="e-btn e-btn-primary" onClick={onSave}>
            {getMessage("securitySettings.productCatalog.saveButton")}
          </button>
        </div>
        <div className="e-col-6"></div>
        <div className="e-col e-col__separator"></div>
      </div>
      <LoaderOverlay show={loading} />
    </div>
  );
}
