import React from "react";
import PropTypes from "prop-types";

import TextareaField from "../../components/TextareaField";

import { getMultiLangFieldLabel } from "../../helpers/multiLangHelper";

const MultiLangDefaultTextField = ({ onChange, lang, translations, disabled }) => {
  return (
    <TextareaField
      label={getMultiLangFieldLabel({ label: "Default text", lang })}
      value={translations["validity.defaultMessageML"][lang]}
      name={"validity.defaultMessageML"}
      onChange={(event) => onChange(event, lang)}
      disabled={disabled}
    />
  );
};

MultiLangDefaultTextField.propTypes = {
  onChange: PropTypes.func,
  lang: PropTypes.string,
  translations: PropTypes.object,
  disabled: PropTypes.bool
};

export default MultiLangDefaultTextField;
