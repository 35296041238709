import * as TYPES from "./TYPES";
import customerApi from "../api/customerApi";
import { getEmsSuiteConfig, isEmsSuite } from "../helpers/emsHelper";

export const getCustomer = () => {
  return (dispatch) => {
    dispatch({
      type: TYPES.GET_CUSTOMER_REQUEST
    });
    return customerApi
      .getCustomer()
      .then((customer) => {
        dispatch({
          type: TYPES.GET_CUSTOMER_SUCCESS,
          payload: customer
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.GET_CUSTOMER_FAILURE
        });
        throw error;
      });
  };
};

export const getEmsConfig = () => {
  return (dispatch) => {
    dispatch({
      type: TYPES.GET_EMS_CONFIG_REQUEST
    });
    return getEmsSuiteConfig()
      .then((config) => {
        dispatch({
          type: TYPES.GET_EMS_CONFIG_SUCCESS,
          payload: config
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.GET_EMS_CONFIG_FAILURE
        });
        throw error;
      });
  };
};

export const getEmsAccountPermissions = () => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.GET_EMS_ACCOUNT_PERMISSIONS_REQUEST
    });

    //if in Emarsys suite call the api to get the account permissions, otherwise the default is edit=true
    if (await isEmsSuite()) {
      return customerApi
        .getEmsAccountPermissions()
        .then((data) => {
          dispatch({
            type: TYPES.GET_EMS_ACCOUNT_PERMISSIONS_SUCCESS,
            payload: data
          });
        })
        .catch((error) => {
          dispatch({
            type: TYPES.GET_EMS_ACCOUNT_PERMISSIONS_FAILURE
          });
          throw error;
        });
    } else {
      dispatch({
        type: TYPES.GET_EMS_ACCOUNT_PERMISSIONS_SUCCESS,
        payload: {
          edit: true
        }
      });
    }
  };
};
