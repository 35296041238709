import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import SelectField from "../../components/SelectFieldV2";
import InputField from "../../components/InputField";
import CheckboxField from "../../components/CheckboxField";
import RadioButtonList from "../../components/RadioButtonList";
import LoaderOverlay from "../../components/LoaderOverlay";
import PopupContainer from "../../components/PopupContainer";
import Notification from "../../components/Notification";

import currentPlanVersionApi from "../../api/currentPlanVersionApi";

import { updateStateOnInputChange } from "../../helpers/formHelper";

import { validateSingleValue, getErrorMessage } from "../../validators";
import memberTierUpdateValidator from "../../validators/memberTierUpdateValidator";

import {
  TIER_UPDATE_TYPE,
  TIER_UPDATE_OPTIONS,
  TIER_DOWNGRADE_TYPE_OPTIONS,
  TIER_DOWNGRADE_TYPE_ENUM,
  TIER_DOWNGRADE_TYPE_OPTIONS_SPEND
} from "../../constants/memberProfileConstants";

import tierService from "../../services/tierService";
import planService from "../../services/planService";
import { getMessage } from "../../messages";

class MemberProfileUpdateTierPopup extends Component {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
    this.state.tiersByPlan = {};
  }

  componentDidUpdate(prevProps) {
    if (this.props.isShow && !prevProps.isShow) {
      this.validator = new memberTierUpdateValidator();

      const { contactPlanId } = this.props;

      if (!this.state.tiersByPlan[contactPlanId]) {
        this.fetchTiers();
      }
    }
    if (!!this.props.contactPlanId && this.props.contactTierId !== prevProps.contactTierId) {
      const { tiersByPlan } = { ...this.state };
      const { contactPlanId, contactTierId } = { ...this.props };

      let contactTier;
      if (tiersByPlan[contactPlanId]) {
        const tiers = tiersByPlan[contactPlanId];
        contactTier = tiers.find((tier) => tier.id === contactTierId);
        this.setState({ contactTier });
      }
    }
  }

  async fetchTiers() {
    const { contactPlanId } = this.props;
    this.setState({
      loading: true
    });

    const plan = await currentPlanVersionApi.getCurrentPlanVersion(contactPlanId);
    const tiers = plan.tiers;

    const { tiersByPlan } = { ...this.state };
    tiersByPlan[contactPlanId] = tierService.sortTiers(tiers);
    const contactTier = tiers.find((tier) => tier.id === this.props.contactTierId);

    this.setState({
      tiersByPlan,
      contactTier,
      plan,
      loading: false
    });
  }

  getDefaultState() {
    return {
      tierUpdateType: TIER_UPDATE_TYPE.UPGRADE_TIER,
      tierDowngradeType: "",
      tierOptions: [],
      selectedTierId: undefined,
      reason: "",
      confirmed: false,
      loading: false,
      errors: undefined
    };
  }

  getTierOptions(tierUpdateType) {
    const { contactPlanId, contactTierId } = this.props;
    const { tiersByPlan } = this.state;
    let allTiers = tiersByPlan[contactPlanId] || [];
    let tiersForOptions = [];

    const mapToOptions = (tier) => {
      const name = tierService.isInviteTier(tier)
        ? getMessage("memberProfileUpdateTierPopup.options.invitationOnly", { tierName: tier.name })
        : tier.name;
      return {
        value: tier.id,
        name
      };
    };

    if (allTiers.length === 0) {
      return tiersForOptions;
    }

    const contactTier = allTiers.find((tier) => tier.id === contactTierId);

    let regularTiers = allTiers.filter((tier) => tierService.isRegularTier(tier));
    const contactRegularTierIndex = regularTiers.findIndex((tier) => tier.id === contactTierId);

    let inviteTiers = allTiers.filter((tier) => tierService.isInviteTierVisible(tier) && tier.id !== contactTierId);

    if (tierUpdateType === TIER_UPDATE_TYPE.UPGRADE_TIER) {
      if (tierService.isInviteTier(contactTier)) {
        tiersForOptions = [...inviteTiers];
      } else if (tierService.isRegularTier(contactTier)) {
        regularTiers = regularTiers.slice(contactRegularTierIndex + 1);
        tiersForOptions = [...regularTiers, ...inviteTiers];
      }
    } else if (tierUpdateType === TIER_UPDATE_TYPE.DOWNGRADE_TIER) {
      if (tierService.isRegularTier(contactTier)) {
        regularTiers = regularTiers.slice(0, contactRegularTierIndex); //if regular tier - take all tiers higher than contact tier
      }
      tiersForOptions = [...regularTiers];
    }

    return tiersForOptions.map(mapToOptions);
  }

  onInputChange(event) {
    const state = { ...this.state };

    const inputName = updateStateOnInputChange({ stateObj: state, event });

    const errors = validateSingleValue(() => this.validator.validate(state), state.errors, inputName);
    state.errors = errors;

    this.setState(state);
    return state;
  }

  onUpdateTypeChange(event) {
    const state = { ...this.state };

    state.selectedTierId = undefined;

    //reset points value error message
    if (state.errors) {
      state.errors.pointsValue = undefined;
    }

    updateStateOnInputChange({ stateObj: state, event });
    this.setState(state);
  }

  onDowngradeTypeChange(event) {
    const state = this.onInputChange(event);
    if (state.tierDowngradeType === TIER_DOWNGRADE_TYPE_ENUM.STATUS_POINTS) {
      state.selectedTierId = "";
      this.setState(state);
    }
  }

  onTierUpdateClick() {
    const errors = this.validator.validate(this.state);
    if (errors) {
      this.setState({
        errors
      });

      return;
    }

    const { tierUpdateType, selectedTierId, reason, tierDowngradeType } = { ...this.state };

    this.props.onUpdateTier({
      tierUpdateType,
      selectedTierId,
      reason,
      activityName: this.state.tierUpdateType === TIER_UPDATE_TYPE.DOWNGRADE_TIER ? "Downgrade tier" : "Upgrade tier",
      tierDowngradeType
    });
  }

  closePopup() {
    this.setState(this.getDefaultState());
    this.props.onClose();
  }

  showTierSelect() {
    const { tierUpdateType, tierDowngradeType, contactTier } = { ...this.state };

    return (
      tierUpdateType === TIER_UPDATE_TYPE.UPGRADE_TIER ||
      tierService.isRegularTier(contactTier) ||
      (tierUpdateType === TIER_UPDATE_TYPE.DOWNGRADE_TIER &&
        tierDowngradeType === TIER_DOWNGRADE_TYPE_ENUM.SPECIFIC_TIER)
    );
  }

  render() {
    const { tierUpdateType, tierDowngradeType, selectedTierId, contactTier, errors } = { ...this.state };
    return (
      <PopupContainer
        id="memberProfileTierUpdatePopup"
        title={getMessage("memberProfileUpdateTierPopup.title")}
        show={this.props.isShow}
        onClose={this.closePopup.bind(this)}
      >
        <Fragment>
          <LoaderOverlay show={this.state.loading} />

          <Notification content={getMessage("memberProfileUpdateTierPopup.notification")} />

          <RadioButtonList
            isHorizontal={true}
            options={TIER_UPDATE_OPTIONS}
            name={"tierUpdateType"}
            value={tierUpdateType}
            onChange={this.onUpdateTypeChange.bind(this)}
            className="no-margin"
          />

          {tierUpdateType === TIER_UPDATE_TYPE.DOWNGRADE_TIER && tierService.isInviteTier(contactTier) && (
            <RadioButtonList
              isHorizontal={true}
              options={
                !planService.isSpendPlan(this.state.plan)
                  ? TIER_DOWNGRADE_TYPE_OPTIONS
                  : TIER_DOWNGRADE_TYPE_OPTIONS_SPEND
              }
              name={"tierDowngradeType"}
              value={tierDowngradeType}
              onChange={this.onDowngradeTypeChange.bind(this)}
              className="no-margin"
              errorMsg={getErrorMessage(errors, "tierDowngradeType")}
            />
          )}

          {this.showTierSelect() && (
            <SelectField
              name={"selectedTierId"}
              value={selectedTierId}
              placeholder={getMessage("memberProfileUpdateTierPopup.selectTier.placeholder")}
              options={this.getTierOptions(tierUpdateType)}
              onChange={this.onInputChange.bind(this)}
              errorMsg={getErrorMessage(errors, "selectedTierId")}
            />
          )}

          <InputField
            label={getMessage("memberProfileAdditionalBenefitsPopup.reason.label")}
            name="reason"
            value={this.state.reason}
            onChange={this.onInputChange.bind(this)}
            errorMsg={getErrorMessage(errors, "reason")}
            tooltip={getMessage("memberProfileUpdateTierPopup.reason.tooltip", {
              text: tierUpdateType === TIER_UPDATE_TYPE.DOWNGRADE_TIER ? "downgrade" : "upgrade"
            })}
          />

          <CheckboxField
            name={`confirmed`}
            id="updateTierConfirmed"
            inline={true}
            label={getMessage("memberProfileUpdateTierPopup.confirmCb.label", {
              text: this.props.isGroupTooltip ? "group's" : "member's"
            })}
            checked={this.state.confirmed}
            onChange={this.onInputChange.bind(this)}
            errorMsg={getErrorMessage(errors, "confirmed")}
          />

          <hr className="e-separator e-separator-fullwidth" />
          <div className="e-buttongroup">
            <a className="e-btn" onClick={this.closePopup.bind(this)}>
              {getMessage("cancelBtn.label")}
            </a>
            <a className="e-btn e-btn-primary" onClick={this.onTierUpdateClick.bind(this)}>
              {getMessage("updateBtn.label")}
            </a>
          </div>
        </Fragment>
      </PopupContainer>
    );
  }
}

MemberProfileUpdateTierPopup.propTypes = {
  isShow: PropTypes.bool,
  isGroupTooltip: PropTypes.bool,
  onClose: PropTypes.func,
  contactPlanId: PropTypes.string,
  contactTierId: PropTypes.string,
  downgradeTierOptions: PropTypes.array,
  upgradeTierOptions: PropTypes.array,
  onUpdateTier: PropTypes.func
};

export default MemberProfileUpdateTierPopup;
