import React, { Fragment } from "react";
import { useRef } from "react";

const Button = (props) => (
  <a className="e-btn e-btn-onlyicon i-icon-upload" onClick={props.onClick}>
    <e-tooltip content={props.tooltip} placement="bottom">
      <e-icon icon="cloud-upload" />
    </e-tooltip>
  </a>
);

const FileUploader = (props) => {
  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
    hiddenFileInput.current.value = null; //chrome bug fix
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files;
    props.onFileSelected(fileUploaded);
  };
  return (
    <>
      <Button onClick={handleClick} tooltip={props.tooltip}></Button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
        multiple={props.multiple}
        accept={props.accept}
      />
    </>
  );
};

export default FileUploader;
