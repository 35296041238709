import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import SelectField from "../../components/SelectFieldV2";
import { ReferralContentReferrerForm, ReferralContentRefereeForm } from "./ReferralContentForm";
import { ReferralContentReferrerPreview, ReferralContentRefereePreview } from "./ReferralContentPreview";

import { selectPlanLanguages, selectPlanPrimaryLanguage, selectPlanHasMultiLang } from "../../reducers";

import { actionType } from "../../types";
import { getLanguageOptions } from "../../helpers/multiLangHelper";
import { Fragment } from "react";
import { getMessage } from "../../messages";

const ActionSetupReferralContent = ({ action, onInputChange, errors, viewMode }) => {
  const EDIT_TAB_ENUM = {
    MEMBER: "members",
    FRIEND: "friend"
  };

  const languages = useSelector((state) => selectPlanLanguages(state));
  const primaryLang = useSelector((state) => selectPlanPrimaryLanguage(state));
  const hasMultiLang = useSelector((state) => selectPlanHasMultiLang(state));
  const [selectedTab, setSelectedTab] = useState(EDIT_TAB_ENUM.MEMBER);
  const [selectedLang, setSelectedLang] = useState(languages[0]);

  const formComponentProps = { action, onInputChange, errors, selectedLang, primaryLang, viewMode };

  function hasFormErrors(path) {
    if (!errors) {
      return false;
    }
    return Object.keys(errors).some((key) => key.indexOf(path) > -1);
  }

  return (
    <main className="e-layout__content">
      <section className="e-layout__section">
        <div className="e-verticalnav e-verticalnav-fullheight">
          <e-verticalnav-menu no-toggle="no-toggle">
            <e-verticalnav-item
              icon="user-verify"
              value="Member"
              status={selectedTab === EDIT_TAB_ENUM.MEMBER ? "active" : ""}
              badge={selectedTab !== EDIT_TAB_ENUM.MEMBER && hasFormErrors("referralContent.referrer") ? "!" : null}
              onClick={() => setSelectedTab(EDIT_TAB_ENUM.MEMBER)}
            />
            <e-verticalnav-item
              icon="user-plus"
              value="Friend"
              status={selectedTab === EDIT_TAB_ENUM.FRIEND ? "active" : ""}
              badge={selectedTab !== EDIT_TAB_ENUM.FRIEND && hasFormErrors("referralContent.referee") ? "!" : null}
              onClick={() => setSelectedTab(EDIT_TAB_ENUM.FRIEND)}
            />
          </e-verticalnav-menu>
          <div className="e-verticalnav__content e-verticalnav__content-nopadding e-section">
            <div className="e-section__header">
              <div className="e-section__title">{getMessage("actionSetupReferralContent.title")}</div>
              <div className="e-section__actions">
                {hasMultiLang && (
                  <Fragment>
                    <label>{getMessage("actionSetupReferralContent.translateForm.label")}</label>
                    <e-tooltip
                      content={getMessage("actionSetupReferralContent.translateForm.tooltip")}
                      type="helper"
                      class="m-r-5"
                    ></e-tooltip>
                    <SelectField
                      options={getLanguageOptions({ languages })}
                      value={selectedLang}
                      onChange={(event) => {
                        setSelectedLang(event.target.value);
                      }}
                      extraClassName="no-margin"
                    />
                  </Fragment>
                )}
              </div>
            </div>
            <div className="e-section__content">
              {selectedTab === EDIT_TAB_ENUM.FRIEND ? (
                <ReferralContentRefereeForm {...formComponentProps} />
              ) : (
                <ReferralContentReferrerForm {...formComponentProps} />
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="e-layout__section">
        <div className="e-section">
          <div className="e-section__header">
            <div className="e-section__title">{getMessage("actionSetupReferralContent.preview")}</div>
          </div>
          <div className="e-section__content">
            {selectedTab === EDIT_TAB_ENUM.FRIEND ? (
              <ReferralContentRefereePreview action={action} selectedLang={selectedLang} primaryLang={primaryLang} />
            ) : (
              <ReferralContentReferrerPreview action={action} selectedLang={selectedLang} primaryLang={primaryLang} />
            )}{" "}
          </div>
        </div>
      </section>
    </main>
  );
  w;
};

ActionSetupReferralContent.propTypes = {
  action: actionType.isRequired,
  errors: PropTypes.object,
  onInputChange: PropTypes.func,
  viewMode: PropTypes.bool
};

export default ActionSetupReferralContent;
