import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import EmptyContentPlaceholder from "./EmptyContentPlaceholder";

import "../../../assets/styles/ExclusiveAccessCustomHtmlEditor.scss";
import { getMessage } from "../../../messages";

class ExclusiveAccessCustomHtmlEditor extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.initHtmlEditorListener();
  }

  initHtmlEditorListener() {
    this.htmlEditorElm.addEventListener("update", (event) => {
      const html = event.detail;
      this.props.onHtmlContentChange(html);
    });
  }

  renderEditorSide() {
    return (
      <React.Fragment>
        <vce-html-editor
          html={this.props.content}
          ref={(elem) => (this.htmlEditorElm = elem)}
          mode="htmlmixed"
          disabled={this.props.isViewOnly}
        />
        <EmptyContentPlaceholder
          show={!this.props.content}
          icon="code"
          heading={getMessage("exclusiveAccessCustomHtmlEditor.editorSide.emptyContentPlaceholder.heading")}
          content={getMessage("exclusiveAccessCustomHtmlEditor.editorSide.emptyContentPlaceholder.content")}
        />
      </React.Fragment>
    );
  }

  renderPreviewSide() {
    return (
      <React.Fragment>
        <vce-preview
          id="preview"
          iframe-width="100%"
          extra-class="e-contentblocks-preview__iframe e-contentblocks-preview__iframe-desktop"
          content={this.props.content}
        >
          <vce-plugin-prevent-link-click />
        </vce-preview>
        <EmptyContentPlaceholder
          show={!this.props.content}
          icon="monitor"
          heading={getMessage("exclusiveAccessCustomHtmlEditor.previewSide.emptyContentPlaceholder.heading")}
          content={getMessage("exclusiveAccessCustomHtmlEditor.previewSide.emptyContentPlaceholder.content")}
        />
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="ea-custom-html-editor e-fullheight__wrapper">
        <div className="e-layout e-layout-fullheight editor-layout">
          <main className="e-layout__content">
            <section className="e-layout__section">
              <div className="e-verticalnav e-verticalnav-fullheight">
                {this.props.memberModeTabsComp}
                <div className="e-verticalnav__content e-verticalnav__content-nopadding e-section">
                  <div className="e-section__header">
                    <div className="e-section__title">Blocking Page Editor</div>
                    <div className="e-section__actions">
                      {this.props.deviceModeTabsComp} <div className="m-l-5">{this.props.multiLangSelect}</div>
                    </div>
                  </div>
                  <div className="e-section__content">{this.renderEditorSide()}</div>
                </div>
              </div>
            </section>
            <section className="e-layout__section">
              <div className="e-fullheight__header">
                <h2>Preview</h2>
              </div>

              <div className="e-fullheight__body no-padding">{this.renderPreviewSide()}</div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}

ExclusiveAccessCustomHtmlEditor.propTypes = {
  rewardId: PropTypes.string.isRequired,
  onHtmlContentChange: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  memberModeTabsComp: PropTypes.element.isRequired,
  deviceModeTabsComp: PropTypes.element.isRequired,
  multiLangSelect: PropTypes.element,
  isViewOnly: PropTypes.bool
};

export default ExclusiveAccessCustomHtmlEditor;
