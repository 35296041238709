import React, { useState } from "react";
import classNames from "classnames";
import fileDownload from "js-file-download";

import reportApi from "../../api/reportApi";
import { getMessage } from "../../messages";

const MembersReportDownloadButton = (props) => {
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    setLoading(true);

    reportApi
      .getMembersReportCsv()
      .then((data) => fileDownload(data, "members.csv"))
      .finally(() => setLoading(false));
  };

  let btnAttr = {};
  if (loading) {
    btnAttr.disabled = true;
  }

  return (
    <e-tooltip content={getMessage("membersReportDownloadButton.tooltip")} block>
      <button className="e-btn" onClick={onClick} {...btnAttr}>
        <div className={classNames("e-btn__loading", { "e-btn__loading-active": loading })}>
          <e-spinner data-size="small"></e-spinner>
          <e-icon icon="e-download"></e-icon>
        </div>
        {getMessage("membersReportDownloadButton.label")}
      </button>
    </e-tooltip>
  );
};

MembersReportDownloadButton.propTypes = {};

export default MembersReportDownloadButton;
