import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import SelectField from "../components/SelectFieldV2";
import PreLaunchPopup from "../components/PreLaunchPopup";
import LoaderOverlay from "../components/LoaderOverlay";
import Time from "../components/Time";

import { launchPlan } from "../actions/planActions";
import { showWarningAlert } from "../actions/alertActions";
import { switchPlanEditMode, setPlanLaunchInProgress } from "../actions/currentPlanVersionActions";

import {
  selectIsBeforeFirstPlanLaunch,
  selectShowCurrentPlan,
  selectDefaultJoinAction,
  selectPlanName,
  selectHasInvalidTier,
  selectPlanLaunchedAt,
  selectCurrentPlanVersionId,
  selectIsPlanLaunchInProgress,
  selectDefaultPurchaseAction,
  isAccountViewOnly,
  selectCustomerIsDoubleHashSet
} from "../reducers";

import currentPlanVersionApi from "../api/currentPlanVersionApi";

import { MODE_TYPE_OPTIONS, MODE_TYPE_ENUM } from "../constants/planConstants";
import { PLAN_FIRST_LAUNCH, PLAN_LAUNCH_HELP_LINK, SMART_INSIGHTS_HELP_LINK } from "../constants/linkConstants";

import sessionHelper from "../helpers/sessionHelper";
import { openConfirmationDialog } from "../helpers/dialogHelper";
import customerApi from "../api/customerApi";

import { getMessage } from "../messages";
class UpdateAndLaunch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPreLaunchPopup: false,
      planLaunchStatus: null,
      loading: false,
      hasSalesData: false,
      disableLaunchButton: false
    };
  }

  async componentDidMount() {
    const { isCustomerDoubleHashSet } = { ...this.props };

    this.startPlanLaunchPulling();

    if (this.props.isBeforeFirstPlanLaunch) {
      this.setState({ loading: true });
      const resp = await customerApi.getHasSalesData();

      try {
        if (!isCustomerDoubleHashSet) {
          await customerApi.getHasEmsSiData();
        }
      } catch (error) {
        const link = `<a target="_blank" href="${SMART_INSIGHTS_HELP_LINK}">${getMessage(
          "updateAndLaunch.warning.smartInsightsLinkText"
        )}</a>`;

        const alertMessage = getMessage("updateAndLaunch.warning.smartInsights", { link });
        this.props.showWarningAlert(alertMessage, null, true);
        this.setState({
          disableLaunchButton: true
        });
      }

      this.setState({ hasSalesData: resp.hasSalesData, loading: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isPlanLaunchInProgress === this.props.isPlanLaunchInProgress) {
      this.startPlanLaunchPulling();
    }
  }

  componentWillMount() {
    if (this.pullInterval) {
      clearInterval(this.pullInterval);
    }
  }

  startPlanLaunchPulling() {
    if (!this.props.isPlanLaunchInProgress) {
      return;
    }

    this.getPlanLaunchStatus();
    this.pullInterval = setInterval(() => {
      this.getPlanLaunchStatus().catch((e) => {
        console.error(e);
        if (this.pullInterval) {
          clearInterval(this.pullInterval);
        }
      });
    }, 1500);
  }

  async getPlanLaunchStatus() {
    const currentPlanVersionId = this.props.currentPlanVersionId;
    const status = await currentPlanVersionApi.getPlanLaunchStatus(currentPlanVersionId);

    const planLaunchStatus = status;
    this.setState({
      planLaunchStatus
    });

    if (status && status.isCompleted) {
      setTimeout(() => {
        clearInterval(this.pullInterval);
        this.props.setPlanLaunchInProgress(false);
      }, 1500);
      return;
    }
  }

  getMissingSettingsErrors() {
    const { hasInvalidTier, defaultJoinAction, defaultPurchaseAction, isBeforeFirstPlanLaunch } = this.props;
    const joinDefaultNotValid = !defaultJoinAction.isValid;
    const purchaseDefaultNotValid = !defaultPurchaseAction.isValid;

    let errors = [];

    if (hasInvalidTier) {
      errors.push(getMessage("updateAndLaunch.missingSettings.invalidTier"));
    }

    if (joinDefaultNotValid) {
      errors.push(getMessage("updateAndLaunch.missingSettings.joinDefaultInvalid"));
    }

    if (purchaseDefaultNotValid) {
      errors.push(getMessage("updateAndLaunch.missingSettings.purchaseDefaultInvalid"));
    }

    if (isBeforeFirstPlanLaunch && !this.state.hasSalesData) {
      errors.push(getMessage("updateAndLaunch.missingSettings.noSalesData"));
    }

    return errors;
  }

  onLaunchPlanClick() {
    const { isBeforeFirstPlanLaunch } = this.props;

    if (isBeforeFirstPlanLaunch) {
      this.toggleShowPreLaunchPopup();
    } else {
      openConfirmationDialog({
        headline: getMessage("updateAndLaunch.launchConfirmationDialog.headline"),
        content: getMessage("updateAndLaunch.launchConfirmationDialog.content"),
        confirmLabel: getMessage("updateAndLaunch.launchConfirmationDialog.label"),
        confirmFunc: this.props.launchPlan
      });
    }
  }

  toggleShowPreLaunchPopup() {
    this.setState((prevState) => ({
      showPreLaunchPopup: !prevState.showPreLaunchPopup
    }));
  }

  onVersionModeChange() {
    const { showCurrentVersion } = this.props;
    this.props.switchPlanEditMode(!showCurrentVersion);
  }

  renderNotification() {
    let content,
      title = "";

    let attr = {};

    const { isBeforeFirstPlanLaunch, showCurrentVersion, planId, planName, planLaunchedAt } = this.props;

    if (isBeforeFirstPlanLaunch) {
      title = getMessage("updateAndLaunch.notification.beforeFirstLaunch.title", { planName, planId });
      content = getMessage("updateAndLaunch.notification.beforeFirstLaunch.content");
    } else if (showCurrentVersion) {
      title = getMessage("updateAndLaunch.notification.showCurrentVersion.title", { planName, planId });
      content = (
        <Fragment>
          {getMessage("updateAndLaunch.notification.showCurrentVersion.content")}
          <Time value={planLaunchedAt} />
        </Fragment>
      );
      attr.color = "success";
    } else {
      title = getMessage("updateAndLaunch.notification.editing.title", { planName, planId });
      content = getMessage("updateAndLaunch.notification.editing.content");
      attr.color = "danger";
    }

    return (
      <e-notification icon="e-bullhorn" {...attr}>
        <e-notification-title>{title}</e-notification-title>
        <e-notification-content>{content}</e-notification-content>
      </e-notification>
    );
  }

  renderSelectVersionMode() {
    const { isBeforeFirstPlanLaunch, showCurrentVersion, isAccountViewOnly } = this.props;

    if (isBeforeFirstPlanLaunch) {
      return null;
    }

    const value = showCurrentVersion || isAccountViewOnly ? MODE_TYPE_ENUM.LIVE_VERSION : MODE_TYPE_ENUM.EDIT_NEXT;

    return (
      <div className="m-t-30">
        <h2>{getMessage("updateAndLaunch.selectVersionMode.title")}</h2>
        <div className="e-row">
          <div className="e-col-6">
            <SelectField
              name="searchType"
              options={MODE_TYPE_OPTIONS}
              value={value}
              onChange={this.onVersionModeChange.bind(this)}
              disabled={isAccountViewOnly}
            />
          </div>
        </div>
      </div>
    );
  }

  renderLaunchPlanButton() {
    let attr = {};

    if (this.props.showCurrentVersion || this.getMissingSettingsErrors().length > 0 || this.state.disableLaunchButton) {
      attr["disabled"] = true;
    }

    const button = (
      <button className="e-btn e-btn-primary" type="submit" onClick={this.onLaunchPlanClick.bind(this)} {...attr}>
        Launch Plan
      </button>
    );

    return button;
  }

  renderLaunchPlanStatus() {
    const { planLaunchStatus } = { ...this.state };

    if (!this.props.isPlanLaunchInProgress || !planLaunchStatus) {
      return;
    }

    let value = 0;
    if (planLaunchStatus.total > 0) {
      value = ((planLaunchStatus.processed / planLaunchStatus.total) * 100).toFixed(1);
    }

    if (planLaunchStatus.isCompleted) {
      value = 100;
    }

    let progressBarAttr = {};
    if (value) {
      progressBarAttr["data-e-percent"] = value;
    }

    return (
      <Fragment>
        <div>
          <e-icon icon="e-info-circle" type="inline" color="info" size="small" />
          <label>{getMessage("updateAndLaunch.planLaunchStatus.label")}</label>
        </div>
        <div className="e-progress m-t-5">
          <div className="e-progress__bar" {...progressBarAttr} style={{ width: `${value}%` }} />
          <div className="e-progress__label" />
        </div>
      </Fragment>
    );
  }

  renderFooterButtons() {
    if (!this.props.isPlanLaunchInProgress || !this.state.planLaunchStatus) {
      return (
        <div className="e-buttongroup m-b-15">
          <button className="e-btn">Cancel</button>
          {this.renderLaunchPlanButton()}
        </div>
      );
    }

    return this.renderLaunchPlanStatus();
  }

  renderMissingSettingNotification() {
    const missingSettingsErrors = this.getMissingSettingsErrors();
    if (missingSettingsErrors.length === 0) {
      return null;
    }

    return (
      <e-notification type="warning">
        <e-notification-title>{getMessage("updateAndLaunch.missingSettings.title")}</e-notification-title>
        <e-notification-content>
          {getMessage("updateAndLaunch.missingSettings.content", {
            missingSettings: (
              <ul className="e-list e-list-bulleted">
                {missingSettingsErrors.map((error) => (
                  <li className="e-list__item e-padding-none e-margin-top-xs e-margin-bottom-xs">{error}</li>
                ))}
              </ul>
            )
          })}
        </e-notification-content>
        <e-notification-footer>
          <a className="e-helperlink e-helperlink-nopadding" href={PLAN_FIRST_LAUNCH} target="_blank">
            <e-icon class="e-helperlink__icon" icon="e-helperlink" size="small"></e-icon>
            <span className="e-helperlink__text">{getMessage("updateAndLaunch.missingSettings.learnMore")}</span>
          </a>
        </e-notification-footer>
      </e-notification>
    );
  }

  render() {
    const content = this.props.isBeforeFirstPlanLaunch ? (
      getMessage("updateAndLaunch.content.beforeFirstLaunch")
    ) : (
      <Fragment>
        {getMessage("updateAndLaunch.content.afterEdit", {
          learnMoreLinkText: (
            <a target={"_blank"} href={PLAN_LAUNCH_HELP_LINK}>
              {getMessage("updateAndLaunch.content.afterEdit.learnMoreLinkText")}
            </a>
          )
        })}
      </Fragment>
    );
    return (
      <Fragment>
        <div className="box-padding">
          {this.renderNotification()}
          <h2 className="m-t-15">{getMessage("updateAndLaunch.title")}</h2>
          <div>{content}</div>
          {this.renderSelectVersionMode()}
          <hr className="e-separator e-separator-fullwidth m-t-30" />
          <div className="m-b-30">{this.renderMissingSettingNotification()}</div>
          <div className="m-b-15">{this.renderFooterButtons()}</div>
        </div>
        <PreLaunchPopup
          showPreLaunchPopup={this.state.showPreLaunchPopup}
          toggleShowPreLaunchPopup={this.toggleShowPreLaunchPopup.bind(this)}
          launchPlanFunc={this.props.launchPlan}
        />
        <LoaderOverlay show={this.state.loading} />
      </Fragment>
    );
  }
}

UpdateAndLaunch.propTypes = {};

const mapStateToProps = (state) => {
  return {
    planId: sessionHelper.getPlanId(),
    planName: selectPlanName(state),
    planLaunchedAt: selectPlanLaunchedAt(state),
    currentPlanVersionId: selectCurrentPlanVersionId(state),
    isPlanLaunchInProgress: selectIsPlanLaunchInProgress(state),
    hasInvalidTier: selectHasInvalidTier(state),
    showCurrentVersion: selectShowCurrentPlan(state),
    isBeforeFirstPlanLaunch: selectIsBeforeFirstPlanLaunch(state),
    defaultJoinAction: selectDefaultJoinAction(state),
    defaultPurchaseAction: selectDefaultPurchaseAction(state),
    isAccountViewOnly: isAccountViewOnly(state),
    isCustomerDoubleHashSet: selectCustomerIsDoubleHashSet(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  launchPlan: () => {
    dispatch(launchPlan());
  },
  switchPlanEditMode: (showCurrentVersion) => {
    dispatch(switchPlanEditMode(showCurrentVersion));
  },
  setPlanLaunchInProgress: (isInProgress) => {
    dispatch(setPlanLaunchInProgress(isInProgress));
  },
  showWarningAlert: (message, time, isFixed) => {
    dispatch(showWarningAlert(message, time, isFixed));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAndLaunch);
