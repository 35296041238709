import * as TYPES from "../actions/TYPES";
import { arrayToObject } from "../helpers/objectHelper";
import find from "lodash/find";

import actionService from "../services/actionService";
import { MASTER_ACTION_ENUM } from "../constants/actionConstants";

const initialState = {
  actionsById: {},
  loading: false,
  dataLoaded: false,
  postSuccess: false,
  newActionId: null,
  actionEditState: {
    action: null,
    stepId: null
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.POST_ACTION_REQUEST: {
      return {
        ...state,
        loading: true,
        postSuccess: false
      };
    }

    case TYPES.POST_ACTION_REQUEST:
    case TYPES.DELETE_ACTION_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case TYPES.GET_ALL_ACTIONS_REQUEST: {
      return {
        ...state,
        loading: true,
        dataLoaded: false
      };
    }

    case TYPES.GET_ALL_ACTIONS_SUCCESS: {
      const actionsData = action.payload == null ? [] : action.payload;
      const actionsById = arrayToObject(actionsData);

      return {
        ...state,
        actionsById,
        loading: false,
        dataLoaded: true
      };
    }

    case TYPES.POST_ACTION_SUCCESS: {
      const actionToSet = action.payload.data;

      const { actionsById } = { ...state };
      actionsById[actionToSet.id] = actionToSet;

      let result = {
        ...state,
        actionsById,
        loading: false,
        postSuccess: true
      };

      if (action.payload.isNew) {
        result.newActionId = actionToSet.id;
      }
      return result;
    }

    case TYPES.DELETE_ACTION_SUCCESS: {
      const { doDelete, id, data } = action.payload;
      let { actionsById } = { ...state };

      //if data is defined
      doDelete ? delete actionsById[id] : (actionsById[data.id] = data);

      return {
        ...state,
        actionsById,
        loading: false
      };
    }

    case TYPES.GET_ALL_ACTIONS_FAILURE:
    case TYPES.POST_ACTION_FAILURE:
    case TYPES.DELETE_ACTION_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case TYPES.SET_ACTION_EDIT_STATE: {
      return {
        ...state,
        actionEditState: {
          action: { ...action.payload.action },
          stepId: action.payload.stepId
        }
      };
    }

    case TYPES.RESET_ACTION_EDIT_STATE: {
      return {
        ...state,
        actionEditState: { ...initialState.actionEditState }
      };
    }

    case TYPES.RESET_NEW_ACTION_ID: {
      return {
        ...state,
        newActionId: null
      };
    }
    default:
      return state;
  }
}

export const selectActions = (state) => {
  const { actionsById } = { ...state };
  const actions = Object.keys(actionsById).map((id) => actionsById[id]);
  return actions;
};

export const selectActionNames = (state) => {
  const { actionsById } = { ...state };
  return Object.keys(actionsById).map((id) => {
    return { id, name: actionsById[id].name };
  });
};

export const selectActionInternalNames = (state) => {
  const { actionsById } = { ...state };
  return Object.keys(actionsById)
    .filter((id) => !!actionsById[id].internalName)
    .map((id) => {
      if (actionsById[id].internalName) {
        return { id, internalName: actionsById[id].internalName };
      }
    });
};

export const selectActionsById = (state) => {
  const { actionsById } = { ...state };
  return actionsById;
};

export const selectActionById = (state, id) => {
  const { actionsById } = { ...state };
  return actionsById[id];
};

export const selectDefaultAction = (state, masterAction) => {
  const { actionsById } = { ...state };
  const defaultActionId = Object.keys(actionsById).filter(
    (id) => actionsById[id].isDefault && actionsById[id].masterAction === masterAction
  );
  return actionsById[defaultActionId];
};

export const selectDefaultJoin = (state) => {
  return selectDefaultAction(state, MASTER_ACTION_ENUM.JOIN);
};

export const selectDefaultPurchase = (state) => {
  return selectDefaultAction(state, MASTER_ACTION_ENUM.PURCHASES);
};

export const selectActionEditState = (state) => {
  return { ...state.actionEditState };
};

export const selectNewActionId = (state) => {
  return state.newActionId;
};

export const isDataLoaded = (state) => {
  return state.dataLoaded;
};

export const isLoading = (state) => {
  return state.loading;
};

export const isPostSuccess = (state) => {
  return state.postSuccess;
};

export const selectRewardTiers = (state, actionId) => {
  const { actionsById } = { ...state };
  const action = actionsById[actionId];
  return action.reward.value;
};
