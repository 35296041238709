import { createIntl, createIntlCache } from "react-intl";
import { en, de } from "../translations/translations";
import { getAdminLanguage } from "../helpers/envHelper";

const messages = {
  en,
  de
};

export const defaultMessages = messages.en;

let intl;
export const initIntl = () => {
  const cache = createIntlCache();
  const language = getAdminLanguage();
  intl = createIntl(
    {
      locale: language,
      messages: messages[language]
    },
    cache
  );
};

export const getMessage = (key, textObject) => {
  if (intl == undefined) {
    initIntl();
  }
  let result = "";
  if (textObject === undefined) {
    result = intl.formatMessage({
      id: key,
      defaultMessage: defaultMessages[key]
    });
  } else {
    result = intl.formatMessage(
      {
        id: key,
        defaultMessage: defaultMessages[key]
      },
      textObject
    );
  }

  return result;
};

export const getPluralCardinalMessage = (messageId, value, params) => {
  const pluralCategory = intl.formatPlural(value, { type: "cardinal" });
  messageId += `.plural.${pluralCategory}`;
  return getMessage(messageId, params);
};

export const getPluralOrdinalMessage = (messageId, value, params) => {
  const pluralCategory = intl.formatPlural(value, { type: "ordinal" });
  messageId += `.ordinal.${pluralCategory}`;
  return getMessage(messageId, params);
};
