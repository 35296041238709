import React, { Component } from "react";
import PropTypes from "prop-types";

import IconsGallery from "../components/IconsGallery";
import InputField from "../components/InputField";
import { getIconName } from "../helpers/iconHelper";

class IconSelectInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showIconsGallery: false
    };
  }

  onShowIconsGallery() {
    if (!this.props.disabled) {
      this.setState({
        showIconsGallery: true
      });
    }
  }

  onSelectIcon(icon) {
    if (!this.props.disabled) {
      this.props.onChange({
        displayName: icon.name,
        fileName: icon.file
      });
      this.setState({
        showIconsGallery: false
      });
    }
  }

  onClear() {
    if (!this.props.disabled) {
      this.props.onChange({ displayName: "", fileName: "" });
    }
  }

  onPopupClose() {
    this.setState({
      showIconsGallery: false
    });
  }

  getImage() {
    const { fileName, iconPrefixUrl } = this.props;

    if (!fileName) {
      return null;
    }

    const iconUrl = iconPrefixUrl + "/" + getIconName(fileName, ".svg");
    return `<img width="40" src="${iconUrl}" />`;
  }

  render() {
    const { props } = this;

    const buttonClassName = this.props.disabled ? "e-btn e-btn-onlyicon e-btn-disabled" : "e-btn e-btn-onlyicon";
    return (
      <div className={props.className}>
        <InputField
          label={props.label}
          tooltip={props.tooltip}
          value={props.displayName}
          readOnly={true}
          onClick={this.onShowIconsGallery.bind(this)}
          prefixIconName="picture-o"
          prefixIconTooltip={this.getImage()}
          disabled={props.disabled}
          addPadding={true}
          postfixButtons={
            <a className={buttonClassName} onClick={this.onClear.bind(this)}>
              <e-icon disabled={props.disabled} icon="reset-block" />
            </a>
          }
        />

        <IconsGallery
          id={props.id}
          inputName={props.name}
          show={this.state.showIconsGallery}
          iconPrefixUrl={this.props.iconPrefixUrl}
          selectIconText={this.props.selectIconText}
          onSelectIcon={this.onSelectIcon.bind(this)}
          loadPromise={this.props.loadPromise}
          loadCustomerPromise={this.props.loadCustomerPromise}
          onPopupClose={this.onPopupClose.bind(this)}
          disabled={props.disabled}
          iconType={this.props.iconType}
        />
      </div>
    );
  }
}

IconSelectInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  fileName: PropTypes.string,
  iconPrefixUrl: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectIconText: PropTypes.string,
  loadPromise: PropTypes.func,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool
};

export default IconSelectInput;
