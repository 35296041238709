import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import MultiSelectField from "../components/MultiSelectField";
import SelectField from "../components/SelectFieldV2";

import { selectPlanMarkets, selectPlanHasMultiMarket } from "../reducers";
import { getMessage } from "../messages";

const MultiMarketSelect = ({
  isMultiSelect,
  label,
  name,
  value,
  onChange,
  errorMsg,
  tooltip,
  disabled,
  excludeMarkets,
  isRequired
}) => {
  const hasMultiMarket = useSelector((state) => selectPlanHasMultiMarket(state));
  if (!hasMultiMarket) {
    return null;
  }

  const [options, setOptions] = useState([]);
  let markets = useSelector((state) => selectPlanMarkets(state));
  useEffect(() => {
    if (excludeMarkets) {
      markets = markets.filter((market) => !excludeMarkets.includes(market) || market === value);
    }
    setOptions(markets);
  }, []);

  const props = {
    options,
    label,
    name,
    value,
    onChange,
    errorMsg,
    tooltip,
    disabled,
    isRequired
  };
  return isMultiSelect ? (
    <MultiSelectField placeholder={getMessage("multiMarketSelector.multiSelectField.placeholder")} {...props} />
  ) : (
    <SelectField placeholder={getMessage("multiMarketSelector.selectField.placeholder")} {...props} />
  );
};

MultiMarketSelect.propTypes = {
  isMultiSelect: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOf([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  errorMsg: PropTypes.string,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  excludeMarkets: PropTypes.arrayOf(PropTypes.string)
};

export default MultiMarketSelect;
