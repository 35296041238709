import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import cloneDeep from "lodash/cloneDeep";
import _values from "lodash/values";
import deepEqual from "deep-equal";

import { validateSingleValue, validateSingleMultiLangValue } from "../validators";
import actionValidator from "../validators/actionValidator";

import {
  updateStateOnInputChange,
  getValueByType,
  convertObjectToOptionsArray,
  getInputNameAndValue,
  updateSpecificLanguageInput,
  convertArrayToOptionsArray
} from "../helpers/formHelper";
import {
  multiLangUpdateState,
  initTranslationsObj,
  setTranslationsOnInputChange,
  getMultiLangValue
} from "../helpers/multiLangHelper";
import { filterObjectByKeys } from "../helpers/objectHelper";
import { getNavigationCanceled } from "../helpers/navHelper";
import { formatPointsNameText, POINTS_PLACEHOLDER } from "../helpers/pointsHelper";

import Notification from "../components/Notification.jsx";

import ActionSetupGeneral from "../views/ActionSetup/ActionSetupGeneral";
import ActionSetupRewards from "../views/ActionSetup/ActionSetupRewards";
import ActionSetupContentCreation from "../views/ActionSetup/ActionSetupContentCreation";
import ActionSetupScheduling from "../views/ActionSetup/ActionSetupScheduling";
import MultiLangActionNameField from "../views/ActionSetup/MultiLangActionNameField";
import MultiLangEmailCampaignIdField from "../views/ActionSetup/MultiLangEmailCampaignIdField";
import MultiLangActionContentFields from "../views/ActionSetup/MultiLangActionContentFields";
import MultiCurrencyMinOrderFields from "../views/ActionSetup/MultiCurrencyMinOrderFields";
import MultiLangExternalBlockLinkFields from "../views/Shared/MultiLangExternalBlockLinkFields";
import ActionSetupReferralContent from "../views/ActionSetup/ActionSetupReferralContent";
import Time from "../components/Time";

import FormStepsContainer from "../components/FormStepsContainer";
import MultiLangPopup from "../components/MultiLangPopup";
import Alert from "../components/Alert";

import { updateAction, setActionEditState, resetActionEditState, resetNewActionId } from "../actions/actionActions";

import {
  selectTiersMapById,
  selectActionById,
  selectTiers,
  selectActions,
  selectRewardsById,
  selectPlanOptInValue,
  isActionPostSuccess,
  selectRewardsAllocationDelay,
  selectPlanPointsNames,
  selectDefaultAction,
  selectActionEditState,
  selectNewActionId,
  selectIsCurrentPlanVersionExists,
  selectCurrentPlanVersion,
  selectCurrentPlanTiersMapById,
  selectExternalEvents,
  selectPlanLanguages,
  selectPlanPrimaryLanguage,
  selectPlanHasMultiLang,
  selectPlanHasMultiMarket,
  selectPlanCurrencies,
  selectPlanPrimaryCurrency,
  selectPlanHasMultiCurrency,
  selectPlanStoreUrlLangMap,
  selectPlan,
  selectExclusivesById,
  isAccountViewOnly,
  selectEmsAdminTimezone,
  selectCustomerFeaturesConfigByName,
  selectActionInternalNames
} from "../reducers";

import planService from "../services/planService";
import actionService from "../services/actionService";
import rewardService from "../services/rewardService";
import exclusiveAccessService from "../services/exclusiveAccessService";
import tierService from "../services/tierService";

import emailApi from "../api/emailApi";

import {
  FORM_STEPS,
  FORM_STEPS_DEFAULT_ACTION,
  FORM_STEPS_DEFAULT_JOIN_ACTION,
  FORM_STEPS_PROMOTIONS_ACTION,
  SPECIAL_TIER_REWARD_SETTINGS,
  FRIEND_REWARD_SETTINGS,
  SPECIAL_TIER_ENUM,
  ACTION_TYPE_OPTIONS,
  ENABLE_IN_SMART_BOX_OPTIONS,
  POINTS_ALLOCATION_DELAY_OPTIONS,
  SMART_BOX_RESTRICTIONS_OPTIONS,
  VOUCHER_OBJECTIVE_OPTIONS,
  VOUCHER_OBJECTIVE_ENUM,
  ACTIVATE_OPTIONS,
  ACTIVATE_PROMOTION_OPTIONS,
  MULTIPLY_POINTS_OPTIONS,
  MULTIPLY_SPEND_OPTIONS,
  REWARD_TOKEN,
  FORM_STEP_ENUM,
  MASTER_ACTION_ENUM,
  PROMOTION_USAGE_TYPE_OPTIONS,
  SELECTED_PROMOTION_OPTIONS,
  DEFAULT_JOIN_MESSAGE_WITH_REWARD,
  DEFAULT_JOIN_MESSAGE_NO_REWARD,
  PROMOTION_USAGE_TYPE_ENUM,
  MULTI_LANG_PROPS_MAP,
  MULTI_CURRENCY_PROPS_MAP,
  VALIDITY_TYPE_ENUM,
  REFERRAL_LIMIT_TYPE_ENUM,
  REFERRAL_CLAIM_TYPE_ENUM,
  SCHEDULING_RECURRENCE_FREQUENCY_ENUM,
  SCHEDULING_RECURRENCE_MONTHLY_OPTIONS_ENUM,
  SCHEDULING_RECURRENCE_TYPE_ENUM
} from "../constants/actionConstants";

import { POINTS_TYPE_ENUM } from "../constants/tierConstants";

import { EXCLUSIVE_ACCESS_OFFERS_ENUM } from "../constants/exclusiveAccessConstants";

import { CONFIG_OBJ_ENUM } from "../constants/configConstants";

import { ALERT_STATUS } from "../constants/alertContants";

import { translate } from "../translations";

import "../assets/styles/ActionSetup.scss";
import { getMessage } from "../messages";

class ActionSetup extends Component {
  constructor(props) {
    super(props);

    const masterAction = props.masterAction;

    this.isDefaultAction = props.action.isDefault;
    this.isNavigationCanceled = getNavigationCanceled();

    this.isJoinAction = actionService.isJoinAction(masterAction);
    this.isPurchasesAction = actionService.isPurchasesAction(masterAction);
    this.isPromotionAction = actionService.isPromotionAction(masterAction);
    this.isReferralAction = actionService.isReferralAction(masterAction);
    this.isEventAction = actionService.isEventAction(masterAction);

    this.actionTypeText = this.isReferralAction ? getMessage("action.referral") : getMessage("action.action");

    this.rewardToken = REWARD_TOKEN;

    let tiersForReward = {
      ...this.props.tiersById,
      ...SPECIAL_TIER_REWARD_SETTINGS
    };

    if (this.isReferralAction) tiersForReward = { ...tiersForReward, ...FRIEND_REWARD_SETTINGS };

    this.steps = FORM_STEPS;
    if (this.isDefaultAction && this.isJoinAction) {
      this.steps = FORM_STEPS_DEFAULT_JOIN_ACTION;
    } else if (this.isDefaultAction) {
      this.steps = FORM_STEPS_DEFAULT_ACTION;
    }

    let actionDetails = {};
    if (props.dupActionId) {
      actionDetails = this.getDuplicatedData(masterAction, tiersForReward);
    } else if (props.editActionId) {
      actionDetails = this.getEditActionData(masterAction, tiersForReward);
    } else {
      actionDetails = this.getDefaultData(masterAction, tiersForReward);
    }

    this.viewMode =
      (props.editActionId &&
        ((actionService.isAttachable(actionDetails) && actionService.isActionAttached(actionDetails)) ||
          actionService.isPromotionEnded(actionDetails))) ||
      props.isAccountViewOnly;

    //send to validator all action names without the current action name
    this.validator = new actionValidator({
      action: actionDetails,
      actionInternalNames: props.actionInternalNames,
      currencies: props.currencies,
      languages: props.languages,
      hasMultiMarket: props.hasMultiMarket
    });

    const rewardEmptyErrorMsg = this.validateRewardNotEmpty(actionDetails);
    this.state = {
      actionDetails,
      tiersForReward,
      redirectToOverview: false,
      currentStep: FORM_STEPS[0],
      rewardEmptyErrorMsg: rewardEmptyErrorMsg,
      tiersListAccordion: this.initTiersListRewardSetup(actionDetails, tiersForReward),
      multiLangPopup: {
        show: false,
        isForCurrency: false
      },
      translations: {}, //multi lang popup translations object
      translationErrors: undefined,
      broadcastEmailOptions: [],
      loadingBroadcastEmailOptions: false,
      showApproveDialog: undefined,
      pointTypeChangeEvent: undefined
    };

    this.startStepId = this.isNavigationCanceled && props.actionEditState.stepId ? props.actionEditState.stepId : null;

    this.overviewLink = `/actions/${masterAction}`;

    this.attachedAlertElement = this.getAttachedAlertElement(actionDetails);
  }

  //save the current edited action in case of back in browser
  componentDidMount() {
    window.onpopstate = () => {
      if (this.shouldBlockNavigation()) {
        this.props.setActionEditState(this.state.actionDetails, this.state.currentStep.id);
      }
    };

    if (this.isReferralAction) {
      this.setState({ loadingBroadcastEmailOptions: true });
      emailApi
        .getBroadcastEmails()
        .then((emailCampaigns) => {
          this.setState({
            broadcastEmailOptions: emailCampaigns.map((email) => {
              return { value: email.id, name: email.name };
            })
          });
        })
        .finally(() => {
          this.setState({ loadingBroadcastEmailOptions: false });
        });
    }
  }

  componentWillUnmount() {
    this.props.resetActionEditState();
    this.props.resetNewActionId();
    window.onpopstate = () => {};
  }

  componentDidUpdate(prevProps) {
    //if action was saved init the currnet action state with the saved action data
    if (!prevProps.postSuccess && this.props.postSuccess) {
      this.initEditAction();
    }

    if (this.props.postSuccess && this.state.redirectToOverview) {
      this.props.history.push(this.overviewLink);
    }
  }

  getEditActionData(masterAction, tiersForReward) {
    if (this.isNavigationCanceled) {
      return this.props.actionEditState.action;
    } else {
      let action = cloneDeep(this.props.action);

      let doUpdate = false;
      if (!action.reward) {
        action.reward = this.initReward(tiersForReward, masterAction);
        doUpdate = true;
      }
      if (!action.icon) {
        const icon = actionService.getDefaultIcon(masterAction);
        if (icon) {
          action.icon = icon;
        }
      }
      if (action.scheduling && !action.scheduling.rewardsAllocationDelay) {
        let pointsAllocationDelay = parseInt(action.scheduling.pointsAllocationDelay);

        if (!pointsAllocationDelay) {
          pointsAllocationDelay = undefined;
        }

        action.scheduling.rewardsAllocationDelay = this.getRewardsAllocationDelay(pointsAllocationDelay);
        doUpdate = true;
      }

      //update reward tiers state if action is not 'join'.
      //'Join' action has only non member reward tier
      if (!this.isJoinAction && this.updateRewardTiersState(tiersForReward, action)) {
        doUpdate = true;
      }

      if (this.isEventAction && this.initEventActionContent(action.content)) {
        doUpdate = true;
      }

      if (this.updateRewardsText(action)) {
        doUpdate = true;
      }

      if (!action.blockExternalLink) {
        action.blockExternalLink = {
          text: "",
          url: ""
        };
        doUpdate = true;
      }

      if (action.scheduling && !action.scheduling.validityType) {
        action.scheduling.validityType = VALIDITY_TYPE_ENUM.LIMITED_FROM_EARN;
        doUpdate = true;
      }

      if (!action.pointsType) {
        action.pointsType = this.getDefaultPointTypeValue();
        doUpdate = true;
      }

      if (doUpdate) {
        this.props.updateAction(action, action.id, true);
      }
      return action;
    }
  }

  initEditAction() {
    const actionDetails = cloneDeep(this.props.action);
    this.setState({
      actionDetails
    });
    this.setTiersListAccordionRewards(null, actionDetails);
    if (this.isPurchasesAction || this.isPromotionAction) {
      this.updateMultilplyPointsEnable(actionDetails);
    }
  }

  getDefaultPointTypeValue() {
    if (planService.isSpendPlan(this.props.plan)) {
      return POINTS_TYPE_ENUM.STATUS;
    }
    return POINTS_TYPE_ENUM.LOYALTY;
  }

  getDefaultData(masterAction, tiersForReward) {
    const actionType = masterAction ? ACTION_TYPE_OPTIONS[masterAction][0].value : "";
    const activated =
      masterAction && masterAction === MASTER_ACTION_ENUM.PROMOTIONS
        ? ACTIVATE_PROMOTION_OPTIONS[0].value
        : ACTIVATE_OPTIONS[0].value;
    const defaultValues = {
      masterAction,
      actionType,
      activated: activated,
      internalName: "",
      name: "",
      description: "",
      icon: actionService.getDefaultIcon(masterAction),
      blockExternalLink: {},
      reward: this.initReward(tiersForReward, masterAction),
      content: this.isReferralAction ? null : this.initContent(masterAction),
      scheduling: {
        rewardsAllocationDelay: this.getRewardsAllocationDelay(0),
        voucherObjective: VOUCHER_OBJECTIVE_ENUM.REWARD,
        smartBoxRestrictions: SMART_BOX_RESTRICTIONS_OPTIONS[0].value,
        enableInSmartBox: ENABLE_IN_SMART_BOX_OPTIONS[0].value,
        availability: {
          start: null,
          end: null
        },
        validity: {
          type: "days",
          duration: this.isJoinAction ? null : 7
        },
        validityType: VALIDITY_TYPE_ENUM.LIMITED_FROM_EARN,
        validityFixedDate: undefined,
        promoValidity: {
          start: null,
          end: null
        },
        referralCta: this.isReferralAction ? {} : null,
        recurringType: SCHEDULING_RECURRENCE_TYPE_ENUM.ONE_TIME,
        recurring: {
          startDate: null,
          endDate: null,
          startHour: null,
          endHour: 23 * 60 + 59,
          repeat: 1,
          isWholeDay: true,
          recurrenceRepeatInterval: SCHEDULING_RECURRENCE_FREQUENCY_ENUM.DAILY,
          weekdays: [],
          monthdays: [],
          monthlySelectionType: SCHEDULING_RECURRENCE_MONTHLY_OPTIONS_ENUM.MONTH,
          monthWeek: 1,
          isLastMonthDay: false,
          timezone: this.props.emsAdminTimezone
        }
      },
      promotion: actionService.isPromotionAction(masterAction)
        ? {
            usageType: PROMOTION_USAGE_TYPE_ENUM.ONE_TIME,
            catalogSelection: {
              brands: [],
              categories: [],
              items: []
            },
            showInWallet: true
          }
        : null,
      referralLimitations: this.isReferralAction
        ? {
            rewards: {
              type: REFERRAL_LIMIT_TYPE_ENUM.BEGINNING_OF_YEAR
            },
            codes: {
              type: REFERRAL_LIMIT_TYPE_ENUM.BEGINNING_OF_YEAR
            }
          }
        : null,
      referralContent: this.isReferralAction ? this.initReferralContent() : null,
      pointsType: this.getDefaultPointTypeValue()
    };

    return defaultValues;
  }

  getRewardsAllocationDelay(duration) {
    if (this.isPurchasesAction || this.isPromotionAction) {
      return this.props.planRewardsAllocationDelayValue;
    }

    return {
      type: "days",
      duration
    };
  }

  getDuplicatedData(masterAction, tiersForReward) {
    let action = this.getEditActionData(masterAction, tiersForReward);
    action.tactic = undefined;
    action.isCreatedInBE = false;

    action.internalName = actionService.getActionNameForDuplicated(action.internalName, this.props.actionInternalNames);

    if (action.nameML) {
      action.nameML[this.props.primaryLanguage] = action.name;
    }

    if (action.isAttachedToContact) {
      action.isAttachedToContact = undefined;
    }

    if (this.isPurchasesAction) {
      action.scheduling.rewardsAllocationDelay = this.props.planRewardsAllocationDelayValue;
    }

    if (!action.reward) {
      action.reward = this.initReward(tiersForReward, masterAction);
    }

    if (actionService.isPromotionEnded(action)) {
      action.scheduling.promoValidity = {
        start: null,
        end: null
      };
    }

    if (this.isReferralAction) {
      action.market = "";
    }

    if (action.apiTriggers) {
      action.apiTriggers = [];
    }

    this.updateRewardTiersState(tiersForReward, action);

    this.props.updateAction(action, "", true);

    return action;
  }

  initReward(tiersForReward, masterAction) {
    return {
      value: this.initTiersDefaultValues(tiersForReward, masterAction),
      redemptionRewardId: ""
    };
  }

  initContent(masterAction) {
    const { primaryLanguage, storeUrlLangMap } = { ...this.props };
    const { token } = { ...this.rewardToken };

    let content = {
      notification: {
        url: "",
        limitsOfUse: ""
      },
      rewardTokenName: REWARD_TOKEN.token
    };

    const params = { token, primaryLanguage, storeUrlLangMap };

    if (!this.isDefaultAction) {
      if (this.isJoinAction) {
        content.nonMember = actionService.getEmptyContentObj(params);
      } else {
        content.nonMember = actionService.getEmptyContentObj(params);
        content.member = actionService.getEmptyContentObj(params);
      }

      if (this.isEventAction) {
        this.initEventActionContent(content);
      }
    }

    return content;
  }

  initReferralContent() {
    return {
      referrer: {
        title: getMessage("actionSetup.referralContent.referrer.title"),
        text: getMessage("actionSetup.referralContent.referrer.text", { token: REWARD_TOKEN.token }),
        subtitle: getMessage("actionSetup.referralContent.referrer.subtitle"),
        rewardTokenName: REWARD_TOKEN.token,
        consentText: getMessage("actionSetup.referralContent.referrer.consentText"),
        consentLinkText: getMessage("actionSetup.referralContent.referrer.consentLinkText")
      },
      referee: {
        title: getMessage("actionSetup.referralContent.referee.title"),
        text: getMessage("actionSetup.referralContent.referee.text"),
        secondaryTitle: getMessage("actionSetup.referralContent.referee.secondaryTitle"),
        secondaryText: getMessage("actionSetup.referralContent.referee.secondaryText"),
        inputFieldPlaceholder: getMessage("actionSetup.referralContent.referee.inputFieldPlaceholder"),
        consentText: getMessage("actionSetup.referralContent.referee.consentText"),
        consentLinkText: getMessage("actionSetup.referralContent.referee.consentLinkText")
      }
    };
  }

  initEventActionContent(actionContent) {
    let isChanged = false;
    const ctaButton = actionContent.member.ctaButton;
    const ctaButtonML = actionContent.member.ctaButtonML;

    const translateKey = "action.content.redeem";

    const { tokenTranslations, primaryLanguage } = this.props;

    const newCtaButton = translate({
      translations: tokenTranslations,
      key: translateKey,
      lang: primaryLanguage
    });

    if (ctaButton !== newCtaButton) {
      actionContent.member.ctaButton = newCtaButton;
      isChanged = true;
    }
    if (this.props.hasMultiLanguage && !ctaButtonML) {
      let newCtaButtonML = {};
      this.props.languages.forEach(
        (lang) =>
          (newCtaButtonML[lang] = translate({
            translations: tokenTranslations,
            key: translateKey,
            lang
          }))
      );
      actionContent.member.ctaButtonML = newCtaButtonML;
      isChanged = true;
    }

    return isChanged;
  }

  //update reward tiers according to tiers set for plan
  updateRewardTiersState(tiersForReward, actionDetails) {
    let didUpdate = false;
    Object.keys(tiersForReward).forEach((tierId) => {
      if (!actionDetails.reward.value[tierId]) {
        const tier = tiersForReward[tierId];
        const tierForReward = {};
        tierForReward[tierId] = tier;
        const tierRewards = this.initTiersDefaultValues(tierForReward, actionDetails.masterAction);
        actionDetails.reward.value[tierId] = tierRewards[tierId];
        didUpdate = true;
      }
    });

    // delete from action reward old tiers that been deleted
    const rewardTiers = actionDetails.reward.value;
    Object.keys(rewardTiers).forEach((tierId) => {
      if (!tiersForReward[tierId]) {
        delete rewardTiers[tierId];
        didUpdate = true;
      }
    });

    const { tiersById, currencies } = { ...this.props };
    const tierZeroPointsMap = actionService.getRewardTiersIsZeroPoints({
      action: actionDetails,
      tiersById,
      currencies
    });

    Object.keys(rewardTiers).forEach((tierId) => {
      if (!!rewardTiers[tierId]?.multiplyPointsEnabled && tierZeroPointsMap[tierId]) {
        rewardTiers[tierId].multiplyPointsEnabled = false;
        rewardTiers[tierId].multiplyPoints = "";
        didUpdate = true;
      }
    });

    return didUpdate;
  }

  updateRewardsText(actionDetails) {
    let isChanged = false;
    Object.keys(actionDetails.reward.value).forEach((tierId) => {
      const rewardTier = actionDetails.reward.value[tierId];

      if (!rewardTier) {
        return false;
      }

      const { rewardsText, rewardsTextML } = this.generateRewardsText(actionDetails, tierId);

      if (rewardsText !== rewardTier.rewardsText) {
        actionDetails.reward.value[tierId].rewardsText = rewardsText;
        if (!rewardTier.rewardsTextML) {
          actionDetails.reward.value[tierId].rewardsTextML = null;
        }
        isChanged = true;
      }

      if (!!rewardTier.rewardsTextML && !deepEqual(rewardsTextML, rewardTier.rewardsTextML)) {
        actionDetails.reward.value[tierId].rewardsTextML = rewardsTextML;
        isChanged = true;
      }
    });

    return isChanged;
  }

  initTiersDefaultValues(tiersForReward, masterAction) {
    const tiersDefaultValues = {};

    Object.keys(tiersForReward).forEach((tierId) => {
      if (this.isJoinAction && tierId !== SPECIAL_TIER_ENUM.NON) {
        return;
      }

      const rewardRules = actionService.getRewardRules(tierId, masterAction);

      tiersDefaultValues[tierId] = {};

      if (rewardRules.enableMultiplyPoints) {
        tiersDefaultValues[tierId].multiplyPoints = "";
        tiersDefaultValues[tierId].multiplyPointsEnabled = false;
      }

      if (rewardRules.enablePoints) {
        tiersDefaultValues[tierId].points = null;
        tiersDefaultValues[tierId].pointsEnabled = false;
      }

      if (rewardRules.enableVoucher) {
        tiersDefaultValues[tierId].rewardPool = "";
        tiersDefaultValues[tierId].rewardPoolEnabled = false;
      }

      if (rewardRules.enableExclusiveAccess) {
        tiersDefaultValues[tierId].exclusiveAccess = "";
        tiersDefaultValues[tierId].exclusiveAccessEnabled = false;
      }

      if (rewardRules.enableStatusUpgrade) {
        tiersDefaultValues[tierId].statusUpgrade = "";
        tiersDefaultValues[tierId].statusUpgradeEnabled = false;
      }
    });
    return tiersDefaultValues;
  }

  // create reward data per tier to be passed to the reward settings form
  initTiersListRewardSetup(actionDetails, tiersForReward) {
    let tiers = {};
    let disableMultiplyTiersNames = [];
    const { currencies, tiersById } = { ...this.props };
    const tierZeroPointsMap = actionService.getRewardTiersIsZeroPoints({
      action: actionDetails,
      tiersById,
      currencies
    });
    let disableMultiplyInAllTier = false;
    Object.keys(tiersForReward).forEach((tierId) => {
      const tier = tiersForReward[tierId];
      let multiplyPointsEnabledTooltip = "";

      if (!tier) {
        return;
      }

      if (tier.settings && tier.settings.subscr) {
        return;
      }

      if (this.isJoinAction && tierId !== SPECIAL_TIER_ENUM.NON) {
        return;
      }

      if (
        !this.isJoinAction &&
        planService.isOptInAutomatic(this.props.planOptInValue) &&
        tierId === SPECIAL_TIER_ENUM.NON
      ) {
        return;
      }

      let rewards;
      if (!!actionDetails.reward.value[tierId]) {
        rewards = cloneDeep(actionDetails.reward.value[tierId]);
      }

      let displaySettingsName = tier.displaySettingsName || tier.name;
      if (tierId === SPECIAL_TIER_ENUM.NON && !this.isJoinAction) {
        displaySettingsName = "Non - member";
      }

      let disableMultiplyInThisTier =
        tier.pointsType !== undefined &&
        actionDetails.pointsType != tier.pointsType &&
        tier.pointsType != POINTS_TYPE_ENUM.LOYALTY;

      if (disableMultiplyInThisTier) {
        disableMultiplyInAllTier = true;
        multiplyPointsEnabledTooltip = getMessage("actionSetupRewards.accordionTierItem.tooltip.tier.disabled", {
          pointType:
            actionDetails.pointsType == POINTS_TYPE_ENUM.STATUS
              ? getMessage("actionSetupRewards.accordionTierItem.tooltip.pointType.status")
              : getMessage("actionSetupRewards.accordionTierItem.tooltip.pointType.balance")
        });
        disableMultiplyTiersNames.push(tier.name);
      }

      tiers[tierId] = {
        tierId,
        name: tier.name,
        displaySettingsName,
        rewards,
        disableMultiplyPoints: tierZeroPointsMap[tierId] || disableMultiplyInThisTier,
        multiplyPointsEnabledTooltip
      };
    });

    //sort rewardTiers
    let sortedTiers = [];
    for (const tierId in tiers) {
      if (tierId != SPECIAL_TIER_ENUM.NON && tierId != SPECIAL_TIER_ENUM.ALL && tierId != SPECIAL_TIER_ENUM.GUEST) {
        sortedTiers.push(tiers[tierId]);
      }
    }

    if (tiers[SPECIAL_TIER_ENUM.NON]) {
      sortedTiers.push(tiers[SPECIAL_TIER_ENUM.NON]);
    }

    if (tiers[SPECIAL_TIER_ENUM.ALL]) {
      tiers[SPECIAL_TIER_ENUM.ALL].disableMultiplyPoints = disableMultiplyInAllTier;
      if (disableMultiplyInAllTier) {
        tiers[SPECIAL_TIER_ENUM.ALL].multiplyPointsEnabledTooltip = getMessage(
          "actionSetupRewards.accordionTierItem.tooltip.all.disabled",
          {
            pointType:
              actionDetails.pointsType == POINTS_TYPE_ENUM.STATUS
                ? getMessage("actionSetupRewards.accordionTierItem.tooltip.pointType.status")
                : getMessage("actionSetupRewards.accordionTierItem.tooltip.pointType.balance"),
            tierNames: disableMultiplyTiersNames.join(", ")
          }
        );
      }
      sortedTiers.push(tiers[SPECIAL_TIER_ENUM.ALL]);
    }

    if (tiers[SPECIAL_TIER_ENUM.GUEST]) {
      tiers[SPECIAL_TIER_ENUM.GUEST].accordionOpenState = true;
      sortedTiers.push(tiers[SPECIAL_TIER_ENUM.GUEST]);
    }

    sortedTiers[0].accordionOpenState = true;
    return sortedTiers;
  }

  setTiersListAccordionRewards(tierId, actionDetails) {
    if (tierId) {
      this.setTierListAccordionState(tierId, "rewards", {
        ...actionDetails.reward.value[tierId]
      });
    } else {
      const tiersListAccordion = [...this.state.tiersListAccordion];

      tiersListAccordion.forEach((tier, index) => {
        tiersListAccordion[index].rewards = {
          ...actionDetails.reward.value[tier.tierId]
        };
      });

      this.setState({
        tiersListAccordion
      });
    }
  }

  setTiersListAccordionOpenState(tierId, newState) {
    this.setTierListAccordionState(tierId, "accordionOpenState", newState);
  }

  setTierListAccordionState(tierId, property, value) {
    const tiersListAccordion = [...this.state.tiersListAccordion];
    const index = tiersListAccordion.findIndex((tier) => tier.tierId === tierId);
    tiersListAccordion[index][property] = value;
    this.setState({
      tiersListAccordion
    });
  }

  onInputChange(event, customValue) {
    const actionDetails = { ...this.state.actionDetails };

    const inputName = updateStateOnInputChange({
      stateObj: actionDetails,
      event,
      customValue,
      hasMultiLanguage: this.props.hasMultiLanguage,
      primaryLanguage: this.props.primaryLanguage,
      multiLangPropsMap: MULTI_LANG_PROPS_MAP,
      hasMultiCurrency: this.props.hasMultiCurrency,
      primaryCurrency: this.props.primaryCurrency,
      multiCurrencyPropsMap: MULTI_CURRENCY_PROPS_MAP
    });

    //reward value change - special validation
    let inputNameValidate = inputName;
    if (inputName.indexOf("reward.value") === 0 && inputName.endsWith("Enabled")) {
      inputNameValidate = inputName.replace("Enabled", "");
    }

    let errors = validateSingleValue(() => this.validate(actionDetails), this.state.errors, inputNameValidate);

    //validate minOrderMC on minOrder change and only if it has no value
    if (inputName === "minOrder" && !!errors && !!errors["minOrderMC"] && !actionDetails.minOrder) {
      errors = validateSingleValue(() => this.validate(actionDetails), errors, "minOrderMC");
    }

    if (inputName === "scheduling.recurring.isLastMonthDay") {
      errors = validateSingleValue(() => this.validate(actionDetails), errors, "scheduling.recurring.monthdays");
    }

    this.setState({
      actionDetails,
      errors
    });

    return { actionDetails, errors };
  }

  onOverrideLanguageInputChange(event, language, customValue) {
    if (language !== this.props.primaryLanguage) {
      const actionDetails = { ...this.state.actionDetails };
      updateSpecificLanguageInput({
        stateObj: actionDetails,
        event,
        language,
        customValue,
        propsMap: MULTI_LANG_PROPS_MAP
      });
      this.setState({
        actionDetails
      });
    } else {
      this.onInputChange(event, customValue);
    }
  }

  onRewardChange(actionDetails, tierId, errors) {
    const { rewardsText, rewardsTextML } = this.generateRewardsText(actionDetails, tierId);

    actionDetails.reward.value[tierId].rewardsText = rewardsText;
    actionDetails.reward.value[tierId].rewardsTextML = rewardsTextML;

    const rewardEmptyErrorMsg = this.validateRewardNotEmpty(actionDetails);

    this.setState({
      actionDetails,
      rewardEmptyErrorMsg,
      errors
    });

    const currActionHasReward = actionService.hasRewardForNonMember(actionDetails);
    if (
      (tierId === SPECIAL_TIER_ENUM.NON || tierId === SPECIAL_TIER_ENUM.ALL) &&
      currActionHasReward !== actionService.hasRewardForNonMember(this.props.action)
    ) {
      this.UpdateNewMemberContent(currActionHasReward);
    }

    this.setTiersListAccordionRewards(tierId, actionDetails);
  }

  onRewardInputChange(event, customValue, tierId) {
    const actionDetails = { ...this.state.actionDetails };

    const inputName = updateStateOnInputChange({
      stateObj: actionDetails,
      event,
      customValue
    });

    const errors = validateSingleValue(() => this.validate(actionDetails), this.state.errors, inputName);

    this.onRewardChange(actionDetails, tierId, errors);
  }

  onRewardEnableInputChange(event, tierId, valueProp, emptyValue) {
    const actionDetails = { ...this.state.actionDetails };
    const value = getValueByType(event.target);

    const inputName = updateStateOnInputChange({
      stateObj: actionDetails,
      event
    });

    //if checkbox is turend to checked, validate the checkbox input, else validate the value input
    const inputNameToValidate = value ? inputName : inputName.replace("Enabled", "");

    const errors = validateSingleValue(() => this.validate(actionDetails), this.state.errors, inputNameToValidate);

    if (value === false) {
      actionDetails.reward.value[tierId][valueProp] = emptyValue;
    }

    this.onRewardChange(actionDetails, tierId, errors);
  }

  onValidityTypeChange(event) {
    const { actionDetails } = this.onInputChange(event);

    const newValidityType = actionDetails.scheduling.validityType;

    if (newValidityType === VALIDITY_TYPE_ENUM.LIMITED_FROM_EARN) {
      actionDetails.scheduling.validityFixedDate = undefined;
    } else if (newValidityType === VALIDITY_TYPE_ENUM.FIXED_DATE) {
      actionDetails.scheduling.validity.duration = undefined;
    }

    this.setState({ actionDetails });
  }

  onReferralCtaChange(event) {
    const { actionDetails } = this.onInputChange(event);

    let errors = validateSingleValue(() => this.validate(actionDetails), this.state.errors, "scheduling.referralCta");
    this.setState({ errors });
  }

  onRecurringTypeChange(event) {
    const { actionDetails } = this.onInputChange(event);

    actionDetails.scheduling.recurring = {
      ...actionDetails.scheduling.recurring,
      startDate: null,
      endDate: null,
      startHour: null,
      endHour: 23 * 60 + 59,
      repeat: 1,
      isWholeDay: true,
      recurrenceRepeatInterval: SCHEDULING_RECURRENCE_FREQUENCY_ENUM.DAILY,
      weekdays: [],
      monthdays: [],
      monthlySelectionType: SCHEDULING_RECURRENCE_MONTHLY_OPTIONS_ENUM.MONTH,
      monthWeek: 1,
      isLastMonthDay: false
    };

    actionDetails.scheduling.promoValidity = {
      start: null,
      end: null
    };

    let errors = this.state.errors;
    if (!!errors) {
      delete errors["scheduling.promoValidity"];
      delete errors["scheduling.recurring.startDate"];
      delete errors["scheduling.recurring.endDate"];
      delete errors["scheduling.recurring.endHour"];
      delete errors["scheduling.recurring.monthdays"];
      delete errors["scheduling.recurring.weekdays"];
    }
    this.setState({ actionDetails, errors });
  }

  recurringChangeHandlerClearMonthlySelections(event) {
    const { actionDetails } = this.onInputChange(event);
    actionDetails.scheduling.recurring = {
      ...actionDetails.scheduling.recurring,
      weekdays: [],
      monthdays: [],
      monthWeek: 1,
      isLastMonthDay: false
    };
    let errors = this.state.errors;
    if (!!errors) {
      delete errors["scheduling.recurring.monthdays"];
      delete errors["scheduling.recurring.weekdays"];
    }
    this.setState({ actionDetails, errors });
  }

  onRecurringIntervalChange(event) {
    const { actionDetails } = this.onInputChange(event);
    actionDetails.scheduling.recurring = {
      ...actionDetails.scheduling.recurring,
      monthlySelectionType: SCHEDULING_RECURRENCE_MONTHLY_OPTIONS_ENUM.MONTH,
      repeat: 1,
      weekdays: [],
      monthdays: [],
      monthWeek: 1,
      isLastMonthDay: false
    };

    let errors = this.state.errors;
    if (!!errors) {
      delete errors["scheduling.recurring.monthdays"];
      delete errors["scheduling.recurring.weekdays"];
    }

    this.setState({ actionDetails, errors });
  }

  onRecurringDaysOfChange(gridName, index) {
    const actionDetails = { ...this.state.actionDetails };
    let foundAtIndex = actionDetails.scheduling.recurring[gridName].indexOf(index);
    if (foundAtIndex != -1) {
      actionDetails.scheduling.recurring[gridName].splice(foundAtIndex, 1);
    } else {
      actionDetails.scheduling.recurring[gridName].push(index);
    }
    let errors = validateSingleValue(
      () => this.validate(actionDetails),
      this.state.errors,
      `scheduling.recurring.${gridName}`
    );

    this.setState({ actionDetails, errors });
  }

  onRecurringTimeChange(event, value) {
    const { actionDetails } = this.onInputChange(event, value);
    actionDetails.scheduling.recurring = {
      ...actionDetails.scheduling.recurring,
      isWholeDay: false
    };

    let errors = validateSingleValue(
      () => this.validate(actionDetails),
      this.state.errors,
      `scheduling.recurring.endHour`
    );
    this.setState({ actionDetails, errors });
  }

  onRecurringIsWholeDayChange(event) {
    const { actionDetails } = this.onInputChange(event);
    actionDetails.scheduling.recurring = {
      ...actionDetails.scheduling.recurring,
      startHour: 0,
      endHour: 23 * 60 + 59
    };

    let errors = validateSingleValue(
      () => this.validate(actionDetails),
      this.state.errors,
      `scheduling.recurring.endHour`
    );
    this.setState({ actionDetails, errors });
  }

  onPointsTypeChange(event) {
    let approveRequred = this.pointTypeChangeRequiresApproval(event);
    if (approveRequred.show) {
      this.setState({ showApproveDialog: approveRequred, pointTypeChangeEvent: event });
    } else {
      const { actionDetails } = this.onInputChange(event);
      if (this.isPurchasesAction || this.isPromotionAction) {
        this.updateMultilplyPointsEnable(actionDetails);
      }
    }
  }

  onPointTypeChangeCanceled() {
    this.setState({ showApproveDialog: undefined, pointTypeChangeEvent: undefined });
  }

  onPointTypeChangeApproved() {
    const { actionDetails } = this.onInputChange(this.state.pointTypeChangeEvent);
    this.updateMultilplyPointsEnable(actionDetails);
  }

  getPointTypeChangeApprovalMessage() {
    if (!!this.state.showApproveDialog?.tiers)
      return getMessage("actionSetupRewards.pointsType.popup.message", {
        tiers: this.state.showApproveDialog.tiers.join(",")
      });
    return "";
  }

  pointTypeChangeRequiresApproval(event) {
    let show = false,
      tiers = [];
    const { tiersById } = { ...this.props };
    const actionDetails = { ...this.state.actionDetails };

    for (const [tierId, tier] of Object.entries(tiersById)) {
      if (tier.pointsType != event.target.value && tier.pointsType != POINTS_TYPE_ENUM.LOYALTY) {
        let tierReward = { ...actionDetails.reward.value[tierId] };
        let allTierReward = { ...actionDetails.reward.value[SPECIAL_TIER_ENUM.ALL] };
        if (tierReward.multiplyPointsEnabled || allTierReward.multiplyPointsEnabled) {
          show = true;
          tiers.push(tier.name);
        }
      }
    }
    return { show: show, tiers: tiers };
  }

  generateRewardsTextForTier = (rewardTier, lang) => {
    if (!rewardTier) {
      return "";
    }

    let rewards = [];

    const pointsNamesObj = this.props.pointsNamesObj;

    const pointsName = getMultiLangValue({
      value: pointsNamesObj.pointsName,
      valueML: pointsNamesObj.pointsNameML,
      lang
    });

    const { tokenTranslations } = this.props;

    if (rewardTier.multiplyPointsEnabled && rewardTier.multiplyPoints) {
      //const text = translate(`action.reward.multiplyPoints.${rewardTier.multiplyPoints}`, lang);
      const text = translate({
        translations: tokenTranslations,
        key: `action.reward.multiplyPoints.${rewardTier.multiplyPoints}`,
        lang,
        replaceTokens: { pointsName }
      });
      rewards.push(text);
    }

    if (rewardTier.pointsEnabled) {
      const pointsText = formatPointsNameText({
        text: `${rewardTier.points} ${POINTS_PLACEHOLDER}`,
        pointsValue: rewardTier.points,
        namesObj: pointsNamesObj,
        lang
      });
      rewards.push(pointsText);
      //const text = translate(`action.reward.bonusPoints`, lang, { points: rewardTier.points });
      //rewards.push(text);
    }

    if (rewardTier.rewardPoolEnabled) {
      const rewardPool = this.props.rewardsById[rewardTier.rewardPool];
      if (rewardPool) {
        const voucherName = getMultiLangValue({
          value: rewardPool.voucherName,
          valueML: rewardPool.voucherNameML,
          lang
        });
        rewards.push(voucherName);
      }
    }

    if (rewardTier.statusUpgradeEnabled && rewardTier.statusUpgrade) {
      const tier = this.props.tiersById[rewardTier.statusUpgrade];
      if (tier) {
        const tierName = getMultiLangValue({
          value: tier.name,
          valueML: tier.nameML,
          lang
        });
        // const text = translate(`action.reward.statusUpgrade`, lang, { tierName });
        const text = translate({
          translations: tokenTranslations,
          key: `action.reward.statusUpgrade`,
          lang,
          replaceTokens: { tierName }
        });
        rewards.push(text);
      }
    }

    if (rewardTier.exclusiveAccessEnabled) {
      const exclusiveAccess = this.props.exclusivesById[rewardTier.exclusiveAccess];
      if (exclusiveAccess) {
        const accessName = getMultiLangValue({
          value: exclusiveAccess.accessName,
          valueML: exclusiveAccess.accessNameML,
          lang
        });
        rewards.push(accessName);
      }
    }

    return rewards.join(", ");
  };

  generateRewardsText = (actionDetails, tierId) => {
    const rewardsText = this.generateRewardsTextForTier(actionDetails.reward.value[tierId], this.props.primaryLanguage);
    const rewardsTextML = this.props.languages.reduce((map, lang) => {
      return {
        ...map,
        [lang]: this.generateRewardsTextForTier(actionDetails.reward.value[tierId], lang)
      };
    }, {});

    return {
      rewardsText,
      rewardsTextML
    };
  };

  updateMultilplyPointsEnable(actionDetails) {
    const { currencies, tiersById } = { ...this.props };
    const tierZeroPointsMap = actionService.getRewardTiersIsZeroPoints({
      action: actionDetails,
      tiersById,
      currencies
    });

    let disableMultiplyPointsForAllTier = false;
    let disabledMultiplyPointsTierNames = [];
    for (const [tierId, tier] of Object.entries(tiersById)) {
      if (tier.pointsType == actionDetails.pointsType || tier.pointsType == POINTS_TYPE_ENUM.LOYALTY) {
        this.setTierListAccordionState(tierId, "multiplyPointsEnabledTooltip", "");
        this.setTierListAccordionState(tierId, "disableMultiplyPoints", tierZeroPointsMap[tierId]);
      } else {
        let tierReward = { ...actionDetails.reward.value[tierId] };

        tierReward.multiplyPointsEnabled = false;
        tierReward.multiplyPoints = "";
        actionDetails.reward.value[tierId] = tierReward;

        let allTierReward = { ...actionDetails.reward.value[SPECIAL_TIER_ENUM.ALL] };
        allTierReward.multiplyPointsEnabled = false;
        allTierReward.multiplyPoints = "";
        disabledMultiplyPointsTierNames.push(tier.name);
        actionDetails.reward.value[SPECIAL_TIER_ENUM.ALL] = allTierReward;

        this.setState({
          actionDetails
        });

        this.setTiersListAccordionRewards(tierId, actionDetails);
        this.setTiersListAccordionRewards(SPECIAL_TIER_ENUM.ALL, actionDetails);
        this.setTierListAccordionState(tierId, "disableMultiplyPoints", true);
        this.setTierListAccordionState(
          tierId,
          "multiplyPointsEnabledTooltip",
          getMessage("actionSetupRewards.accordionTierItem.tooltip.tier.disabled", {
            pointType:
              actionDetails.pointsType == POINTS_TYPE_ENUM.STATUS
                ? getMessage("actionSetupRewards.accordionTierItem.tooltip.pointType.status")
                : getMessage("actionSetupRewards.accordionTierItem.tooltip.pointType.balance")
          })
        );
        this.setTierListAccordionState(
          SPECIAL_TIER_ENUM.ALL,
          "multiplyPointsEnabledTooltip",
          getMessage("actionSetupRewards.accordionTierItem.tooltip.all.disabled", {
            tierNames: disabledMultiplyPointsTierNames.join(", "),
            pointType:
              actionDetails.pointsType == POINTS_TYPE_ENUM.STATUS
                ? getMessage("actionSetupRewards.accordionTierItem.tooltip.pointType.status")
                : getMessage("actionSetupRewards.accordionTierItem.tooltip.pointType.balance")
          })
        );

        disableMultiplyPointsForAllTier = true;
      }
    }

    this.setTierListAccordionState(SPECIAL_TIER_ENUM.ALL, "disableMultiplyPoints", disableMultiplyPointsForAllTier);
    if (!disableMultiplyPointsForAllTier) {
      this.setTierListAccordionState(SPECIAL_TIER_ENUM.ALL, "multiplyPointsEnabledTooltip", "");
      this.setTiersListAccordionRewards(undefined, actionDetails);
    }
  }

  onIconInputChange(icon) {
    let { actionDetails } = { ...this.state };

    actionDetails.icon = icon;
    this.setState({
      actionDetails
    });
  }

  onCatalogSelectionChange({ brands, categories, items }) {
    const { actionDetails } = { ...this.state };

    actionDetails.promotion.catalogSelection = {
      brands,
      categories,
      items
    };

    const errors = validateSingleValue(
      () => this.validate(actionDetails),
      this.state.errors,
      "promotion.catalogSelection"
    );

    this.setState({
      actionDetails,
      errors
    });
  }

  onRedemptionRewardChange(rewardId) {}

  onLangInputChange(event, lang, customValue) {
    let translationErrors;
    const translations = { ...this.state.translations };
    const { name } = getInputNameAndValue(event);

    setTranslationsOnInputChange({ translations, event, lang, customValue });

    if (name === "minOrderMC" || name === "blockExternalLink.urlML" || name === "blockExternalLink.textML") {
      const hasValue = _values(translations[name]).some((v) => !!v);
      const validateFunc =
        name === "minOrderMC" ? this.validator.validateMultiCurrencyPopup : this.validator.validateMultiLangPopup;

      if (hasValue) {
        translationErrors = validateSingleMultiLangValue(
          () => validateFunc(translations),
          this.state.translationErrors,
          name,
          lang
        );
      } else {
        translationErrors = validateFunc(translations);
      }
    }

    this.setState({
      translations,
      translationErrors
    });
  }

  onMultiLangPopupSave() {
    const actionDetails = { ...this.state.actionDetails };
    const translations = { ...this.state.translations };
    const isForCurrency = this.state.multiLangPopup.isForCurrency;

    const translationErrors = isForCurrency
      ? this.validator.validateMultiCurrencyPopup(translations)
      : this.validator.validateMultiLangPopup(translations);

    if (!translationErrors) {
      multiLangUpdateState({
        stateObj: actionDetails,
        translations,
        propsMap: isForCurrency ? MULTI_CURRENCY_PROPS_MAP : MULTI_LANG_PROPS_MAP,
        primaryLanguage: isForCurrency ? this.props.primaryCurrency : this.props.primaryLanguage
      });

      this.setState({
        actionDetails,
        multiLangPopup: {
          show: false,
          isForCurrency
        }
      });
    }

    this.setState({
      translationErrors
    });
  }

  onShowMultiLangPopup(propList) {
    const actionDetails = { ...this.state.actionDetails };
    const propsMap = filterObjectByKeys(MULTI_LANG_PROPS_MAP, propList);
    const translations = initTranslationsObj({
      stateObj: actionDetails,
      propsMap,
      primaryLanguage: this.props.primaryLanguage
    });

    this.setState({
      translations,
      translationErrors: undefined,
      multiLangPopup: {
        show: true
      }
    });
  }

  onShowActionContentMultiLang({ isMember }) {
    const actionDetails = { ...this.state.actionDetails };

    const propPrefix = isMember ? "content.member" : "content.nonMember";

    const propsMap = filterObjectByKeys(MULTI_LANG_PROPS_MAP, [
      `${propPrefix}.blockMessageML`,
      `${propPrefix}.ctaButtonML`,
      `${propPrefix}.ctaUrlML`
    ]);

    const translations = initTranslationsObj({
      stateObj: actionDetails,
      propsMap,
      primaryLanguage: this.props.primaryLanguage
    });

    this.setState({
      translations,
      multiLangPopup: {
        show: true
      }
    });
  }

  onShowMinOrderMultiLang() {
    const actionDetails = { ...this.state.actionDetails };
    let propsArr = ["minOrderMC"];

    if (actionService.hasMinOrderDisplay(actionDetails)) {
      propsArr.push("minOrderDisplayMC");
    }

    const propsMap = filterObjectByKeys(MULTI_CURRENCY_PROPS_MAP, propsArr);
    const translations = initTranslationsObj({
      stateObj: actionDetails,
      propsMap,
      primaryLanguage: this.props.primaryCurrency
    });

    this.setState({
      translations,
      translationErrors: undefined,
      multiLangPopup: {
        show: true,
        isForCurrency: true
      },
      errors: undefined
    });
  }

  getMultiLangPopupFields() {
    const translations = { ...this.state.translations };
    const actionDetails = { ...this.state.actionDetails };

    if (!!translations.nameML) {
      return (
        <MultiLangActionNameField
          disabled={this.viewMode}
          label={
            this.isReferralAction
              ? getMessage("actionSetupGeneral.actionNameInput.referral.label")
              : getMessage("actionSetup.multilangActionNameField.label")
          }
        />
      );
    } else if (!!translations["content.member.blockMessageML"]) {
      return (
        <MultiLangActionContentFields
          propPrefix="content.member"
          rewardToken={this.rewardToken}
          primaryBlockMessage={actionDetails.content.member.blockMessage}
          disabled={this.viewMode}
          disableCtaButton={this.isEventAction}
        />
      );
    } else if (!!translations["content.nonMember.blockMessageML"]) {
      return (
        <MultiLangActionContentFields
          propPrefix="content.nonMember"
          rewardToken={this.rewardToken}
          primaryBlockMessage={actionDetails.content.nonMember.blockMessage}
          disabled={this.viewMode}
        />
      );
    } else if (!!translations.minOrderMC) {
      return <MultiCurrencyMinOrderFields disabled={this.viewMode} errors={this.state.translationErrors} />;
    } else if (!!translations["blockExternalLink.urlML"]) {
      return <MultiLangExternalBlockLinkFields disabled={this.viewMode} errors={this.state.translationErrors} />;
    } else if (!!translations["emailCampaignIdML"]) {
      return (
        <MultiLangEmailCampaignIdField
          disabled={this.viewMode}
          broadcastEmailOptions={this.state.broadcastEmailOptions}
          loadingBroadcastEmailOptions={this.state.loadingBroadcastEmailOptions}
        />
      );
    }
    return null;
  }

  getMultiLangPopupTitle() {
    const translations = { ...this.state.translations };
    if (!!translations["emailCampaignIdML"]) {
      return getMessage("actionSetup.multilangPopup.title");
    }

    return null;
  }

  //update state with default join content according to the existing of a reward
  UpdateNewMemberContent(currActionHasRewards) {
    const actionDetails = { ...this.state.actionDetails };
    const defaultJoinAction = { ...this.props.defaultJoinAction };

    let joinMessage = REWARD_TOKEN.token;
    if (this.isDefaultAction && this.isJoinAction) {
      //default join action - get default message according to reward state
      joinMessage = currActionHasRewards ? DEFAULT_JOIN_MESSAGE_WITH_REWARD : DEFAULT_JOIN_MESSAGE_NO_REWARD;
    } else if (!this.isDefaultAction) {
      joinMessage = actionDetails.content.nonMember.blockMessage;
    } else {
      return;
    }

    actionDetails.content.nonMember.blockMessage = joinMessage;
    actionDetails.content.nonMember.blockMessageML = null;

    this.setState({
      actionDetails
    });
  }

  onStepChange(step) {
    this.setState({
      currentStep: step,
      goToStep: undefined,
      errors: undefined
    });
  }

  getFormSteps() {
    //const steps = this.isDefaultAction && !this.isJoinAction ? FORM_STEPS_DEFAULT_ACTION : FORM_STEPS;
    let steps = cloneDeep(this.steps);

    //all steps should be disabled if name is not set
    if (!this.props.action.name) {
      return steps;
    }

    return steps.map((step) => {
      if (this.isReferralAction && step.id === FORM_STEP_ENUM.ACTION_SETTINGS) {
        step.title = getMessage("form.Steps.actionSettings.title.referral");
      }
      return { ...step, disabled: false };
    });
  }

  validate(actionDetails) {
    const stepId = this.state.currentStep.id;
    return this.validator.validateStep(stepId, actionDetails);
  }

  validateRewardNotEmpty(actionDetails) {
    const errors = this.validator.validateReward(actionDetails, this.isJoinAction);

    if (errors && errors["reward.value"]) {
      return errors["reward.value"][0];
    }

    return undefined;
  }

  validateStep() {
    const { actionDetails, errors } = this.state;
    if (errors) {
      return false;
    }

    const newErrors = this.validate(actionDetails);

    this.setState({
      errors: newErrors
    });

    if (newErrors) {
      return false;
    }

    if (this.state.currentStep.id === FORM_STEP_ENUM.REWARD_SETTINGS) {
      const rewardEmptyErrorMsg = this.validateRewardNotEmpty(actionDetails);
      if (rewardEmptyErrorMsg) {
        return false;
      }
    }

    return true;
  }

  onSubmit() {
    if (this.props.isAccountViewOnly) {
      return;
    }

    const { actionDetails } = this.state;
    const action = { ...this.props.action, ...actionDetails };
    const actionId = this.props.editActionId;

    //if action id in not defined, it's the first save of edit or duplicate action
    const doUpdate = this.actionHasChanged() || !actionId;

    if (doUpdate) {
      this.props.updateAction(action, actionId);
    }

    const currentStepId = this.state.currentStep.id;
    const steps = this.steps;
    if (currentStepId === steps[steps.length - 1].id) {
      if (doUpdate) {
        this.setState({
          redirectToOverview: true
        });
      } else {
        this.props.history.push(this.overviewLink);
      }
    }
  }

  shouldBlockNavigation() {
    return this.actionHasChanged();
  }

  actionHasChanged() {
    let actionsDetails = { ...this.state.actionDetails };
    let action = { ...this.props.action };

    // reset some properties we don't want to compare
    actionsDetails.isAttachedToContact = undefined;
    action.isAttachedToContact = undefined;

    actionsDetails.tactic = undefined;
    action.tactic = undefined;

    const result = !deepEqual(actionsDetails, action);
    return result;
  }

  onCustomConfirmNavigation() {
    this.initEditAction();
  }

  doRenderNonMemberContent(action) {
    if (!actionService.isJoinAction(action.masterAction)) {
      return !action.content.nonMember ? false : !planService.isOptInAutomatic(this.props.planOptInValue);
    }

    return !!action.content.nonMember;
  }

  doRenderMemberContent(action) {
    return !!action.content.member;
  }

  setGoToStep(stepIndex) {
    this.setState({
      goToStep: stepIndex
    });
  }

  getAttachedAlertElement(action) {
    let message,
      status = "";
    let isMultiline = false;

    if (actionService.isPromotionEnded(action)) {
      const message = getMessage("actionSetup.alert.promotionEnded");
      return <Alert message={message} status={ALERT_STATUS.WARNING} isMultiline={false} />;
    } else if (actionService.isAttachable(action) && actionService.isActionAttached(action)) {
      if (action.activated) {
        const messageText = getMessage("actionSetup.alert.activeMustDeactivate");
        const splitMessageText = messageText.split("{deactivateTag}");
        message = (
          <label>
            {splitMessageText[0]}
            <a className="e-clickable" onClick={() => this.setGoToStep(FORM_STEPS.length - 1)}>
              {getMessage("actionSetup.alert.activeMustDeactivate.deactivateText")}
            </a>
            {splitMessageText[1]}
          </label>
        );
      } else {
        const messageText = getMessage("actionSetup.alert.notActivated");
        const splitMessageText = messageText.split("{timeTag}");
        message = (
          <label>
            {splitMessageText[0]}
            <Time value={action.lastAttachedExpireAt} onlyDate={true} />
            {splitMessageText[1]}
          </label>
        );
      }
      status = action.activated ? ALERT_STATUS.INFO : ALERT_STATUS.WARNING;
      isMultiline = action.activated;

      return <Alert message={message} status={status} isMultiline={isMultiline} />;
    }

    return null;
  }

  getTierNameById(tierId) {
    return this.state.tiersForReward[tierId].name;
  }

  addError(name, errorText) {
    let { errors } = { ...this.state };
    if (!errors) {
      errors = {};
    }
    errors[name] = [errorText];
    this.setState({ errors });
  }

  getTzMismatchError() {
    const actionDetails = { ...this.state.actionDetails };

    if (
      !!this.props.action.scheduling?.recurring?.timezone &&
      actionService.isRecurring(actionDetails) &&
      this.props.emsAdminTimezone != this.props.action.scheduling.recurring.timezone
    ) {
      return getMessage("actionSetupScheduling.recurringType.recurring.tzMismatchNotifiation", {
        timezone: this.props.action.scheduling.recurring.timezone
      });
    }

    return "";
  }

  renderSetupNotification(action) {
    const currentStepId = this.state.currentStep.id;

    if (action.isDefault) {
      return null;
    }

    if (currentStepId === FORM_STEP_ENUM.REWARD_SETTINGS) {
      if (this.isPurchasesAction || actionService.isEngagementAction(action.masterAction)) {
        return <Notification content={getMessage("actionSetup.rewardSetting.notification.purchaseAction")} />;
      }
      if (this.isEventAction) {
        return <Notification content={getMessage("actionSetup.rewardSetting.notification.eventAction")} />;
      }
      if (this.isPromotionAction) {
        return <Notification content={getMessage("actionSetup.rewardSetting.notification.promotionAction")} />;
      }
    }

    if (currentStepId === FORM_STEP_ENUM.ACTION_SETTINGS) {
      let noticeText;
      if (actionService.isEngagementAction(action.masterAction)) {
        noticeText = getMessage("actionSetup.actionSettings.notification.engagementAction");
      } else if (this.isEventAction) {
        noticeText = getMessage("actionSetup.actionSettings.notification.eventAction");
      } else if (this.isReferralAction) {
        noticeText = getMessage("actionSetup.actionSettings.notification.referralAction");
      }

      return <Notification content={noticeText} />;
    }

    if (currentStepId == FORM_STEP_ENUM.SCHEDULING && this.getTzMismatchError() != "") {
      return <Notification content={this.getTzMismatchError()} />;
    }

    return null;
  }

  renderActionSetupGeneral(action) {
    const actionTypeOptions = actionService.getActionTypeOptions(action);
    const initialAction = this.props.action;

    return (
      <ActionSetupGeneral
        key="actionSetupGeneral"
        action={action}
        onInputChange={this.onInputChange.bind(this)}
        onIconInputChange={this.onIconInputChange.bind(this)}
        onCatalogSelectionChange={this.onCatalogSelectionChange.bind(this)}
        actionTypeOptions={actionTypeOptions}
        promotionTypeOptions={PROMOTION_USAGE_TYPE_OPTIONS}
        selectPromotionOptions={SELECTED_PROMOTION_OPTIONS}
        showPromotionSettings={!action.isDefault && this.isPromotionAction}
        showCatalogSelection={actionService.hasCatalogSelection(action)}
        showMinOrder={actionService.hasMinOrder(action)}
        showMinOrderDisplay={actionService.hasMinOrderDisplay(action)}
        errors={this.state.errors}
        viewMode={this.viewMode}
        notificationElem={this.renderSetupNotification(action)}
        actionTypeText={this.actionTypeText}
        onShowActionNameMultiLang={() => this.onShowMultiLangPopup(["nameML"])}
        onBlockExternalLinkMultiLang={() =>
          this.onShowMultiLangPopup(["blockExternalLink.urlML", "blockExternalLink.textML"])
        }
        onShowMinOrderMultiLang={this.onShowMinOrderMultiLang.bind(this)}
        primaryCurrency={this.props.primaryCurrency}
        isActionActive={actionService.isActionActive(initialAction) && action.isCreatedInBE}
      />
    );
  }

  renderActionRewardSettings(action) {
    return (
      <ActionSetupRewards
        key="actionSetupRewards"
        action={action}
        tiers={this.state.tiersListAccordion}
        onRewardInputChange={this.onRewardInputChange.bind(this)}
        onRewardEnableInputChange={this.onRewardEnableInputChange.bind(this)}
        rewardVoucherOptions={this.props.rewardVoucherOptions}
        rewardExclusiveAccessOptions={this.props.rewardExclusiveAccessOptions}
        tierOptions={this.props.tierOptions}
        allVoucherOptions={this.props.allVoucherOptions}
        allExclusiveAccessOptions={this.props.allExclusiveAccessOptions}
        multiplyPointsOptions={
          !planService.isSpendPlan(this.props.plan) ? MULTIPLY_POINTS_OPTIONS : MULTIPLY_SPEND_OPTIONS
        }
        errors={this.state.errors}
        viewMode={this.viewMode}
        showOnlyNonMember={this.isJoinAction}
        hideNonMember={!actionService.isForNewMembers(action)}
        onAccordionStateChange={this.setTiersListAccordionOpenState.bind(this)}
        onInputChange={this.onInputChange.bind(this)}
        notificationElem={this.renderSetupNotification(action)}
        isExclusiveAccessEnabled={this.props.isExclusiveAccessEnabled}
        plan={this.props.plan}
        isPointSplitDisabled={this.props.isPointSplitDisabled}
        onPointsTypeChange={this.onPointsTypeChange.bind(this)}
        showApproveDialog={this.state.showApproveDialog?.show}
        approveDialogMessage={this.getPointTypeChangeApprovalMessage()}
        onPointTypeChangeApproved={this.onPointTypeChangeApproved.bind(this)}
        onPointTypeChangeCanceled={this.onPointTypeChangeCanceled.bind(this)}
      />
    );
  }

  renderActionContentCreation(action) {
    if (this.isReferralAction) {
      return (
        <ActionSetupReferralContent
          action={action}
          onInputChange={this.onOverrideLanguageInputChange.bind(this)}
          errors={this.state.errors}
          viewMode={this.viewMode}
        />
      );
    }
    return (
      <ActionSetupContentCreation
        key="actionSetupContent"
        action={action}
        defaultJoinAction={this.props.defaultJoinAction}
        getTierNameById={this.getTierNameById.bind(this)}
        onInputChange={this.onInputChange.bind(this)}
        renderMember={this.doRenderMemberContent(action)}
        renderNonMember={this.doRenderNonMemberContent(action)}
        errors={this.state.errors}
        viewMode={this.viewMode}
        rewardToken={this.rewardToken}
        defaultMemberContent={this.props.defaultAction?.content.member}
        previewWalletDefault={false}
        isJoinAction={this.isJoinAction}
        hasRewardForNonMember={actionService.hasRewardForNonMember(action)}
        actionTypeText={this.actionTypeText}
        onShowMemberMultiLang={() => this.onShowActionContentMultiLang({ isMember: true })}
        onShowNonMemberMultiLang={() => this.onShowActionContentMultiLang({ isMember: false })}
        hasMultiLanguage={this.props.hasMultiLanguage}
      />
    );
  }

  renderActionScheduling(action) {
    const activateOptions =
      action.masterAction === MASTER_ACTION_ENUM.PROMOTIONS ? ACTIVATE_PROMOTION_OPTIONS : ACTIVATE_OPTIONS;
    const initialAction = this.props.action;
    return (
      <ActionSetupScheduling
        key="actionSetupScheduling"
        action={action}
        initialAction={this.props.action}
        onInputChange={this.onInputChange.bind(this)}
        onInputError={this.addError.bind(this)}
        rewardsAllocationDelayOptions={POINTS_ALLOCATION_DELAY_OPTIONS}
        showRewardsAllocationDelay={false}
        disableRewardsAllocationDelay={this.isPurchasesAction}
        voucherObjectiveOptions={VOUCHER_OBJECTIVE_OPTIONS}
        showVoucherObjective={false}
        smartBoxRestrictionsOptions={SMART_BOX_RESTRICTIONS_OPTIONS}
        enableInSmartBoxOptions={ENABLE_IN_SMART_BOX_OPTIONS}
        activateOptions={activateOptions}
        errors={this.state.errors}
        viewMode={this.viewMode}
        isPromotionAction={actionService.isPromotionAction(action.masterAction)}
        isPromotionDisplayedInWallet={actionService.isPromotionDisplayedInWallet(action)}
        hideValidity={this.isEventAction || this.isReferralAction}
        notificationElem={this.renderSetupNotification(action)}
        onValidityTypeChange={this.onValidityTypeChange.bind(this)}
        onShowEmailCampaignIdMultiLang={() => this.onShowMultiLangPopup(["emailCampaignIdML"])}
        loadingBroadcastEmailOptions={this.state.loadingBroadcastEmailOptions}
        broadcastEmailOptions={this.state.broadcastEmailOptions}
        onReferralCtaChange={this.onReferralCtaChange.bind(this)}
        disableActivate={this.props.isAccountViewOnly}
        onRecurringTypeChange={this.onRecurringTypeChange.bind(this)}
        onRecurringIntervalChange={this.onRecurringIntervalChange.bind(this)}
        onRecurringDaysOfChange={this.onRecurringDaysOfChange.bind(this)}
        onRecurringMonthlySelectionTypeChange={this.recurringChangeHandlerClearMonthlySelections.bind(this)}
        onRecurringTimeChange={this.onRecurringTimeChange.bind(this)}
        onRecurringIsWholeDayChange={this.onRecurringIsWholeDayChange.bind(this)}
        isActionActive={actionService.isActionActive(initialAction) && action.isCreatedInBE}
      />
    );
  }

  renderFormStepsComponents(action) {
    let result = [];

    this.steps.forEach((step) => {
      if (step.id === FORM_STEP_ENUM.ACTION_SETTINGS) result.push(this.renderActionSetupGeneral(action));
      if (step.id === FORM_STEP_ENUM.REWARD_SETTINGS) result.push(this.renderActionRewardSettings(action));
      if (step.id === FORM_STEP_ENUM.CONTENT_CREATION) result.push(this.renderActionContentCreation(action));
      if (step.id === FORM_STEP_ENUM.SCHEDULING) result.push(this.renderActionScheduling(action));
    });

    return result;
  }

  render() {
    const action = this.state.actionDetails;

    const { isNewAction, editActionId } = this.props;

    if (!action) {
      return null;
    }

    let title;
    if (!!action.isDefault) {
      title = getMessage("actionSetup.title.default");
    } else {
      title = isNewAction
        ? getMessage("actionSetup.title.new", { actionType: this.actionTypeText })
        : getMessage("actionSetup.title.edit", { actionType: this.actionTypeText, actionName: this.props.action.name });
    }

    const formClassName = classNames("action-setup-form", {
      "l-layout-fullheight e-layout-with_steps": this.state.currentStep.id === FORM_STEP_ENUM.CONTENT_CREATION
    });

    return (
      <Fragment>
        <FormStepsContainer
          id={editActionId}
          title={title}
          steps={this.getFormSteps()}
          onSubmit={this.onSubmit.bind(this)}
          nextStepOnSubmit={true}
          applySubmitOnNextStep={true}
          overviewLink={this.overviewLink}
          className={formClassName}
          onStepChange={this.onStepChange.bind(this)}
          validateStepFunc={this.validateStep.bind(this)}
          shouldBlockNavigationFunc={this.shouldBlockNavigation.bind(this)}
          viewMode={this.viewMode}
          alertElement={this.attachedAlertElement}
          goToStep={this.state.goToStep}
          onCustomConfirmNavigation={this.onCustomConfirmNavigation.bind(this)}
          disableSubmitButton={this.viewMode && this.state.currentStep.id !== FORM_STEP_ENUM.SCHEDULING}
          hasErrors={
            !!this.state.errors ||
            (!!this.state.rewardEmptyErrorMsg && this.state.currentStep.id === FORM_STEP_ENUM.REWARD_SETTINGS)
          }
          customErrorAlertMessage={
            this.state.currentStep.id === FORM_STEP_ENUM.REWARD_SETTINGS ? this.state.rewardEmptyErrorMsg : ""
          }
          startStepId={this.startStepId}
        >
          {this.renderFormStepsComponents(action)}
        </FormStepsContainer>
        <MultiLangPopup
          id={"action_setup_multi_lang"}
          show={this.state.multiLangPopup.show}
          languages={this.props.languages}
          currencies={this.props.currencies}
          translations={this.state.translations}
          hasErrors={!!this.state.translationErrors}
          isForCurrency={this.state.multiLangPopup.isForCurrency}
          element={this.getMultiLangPopupFields()}
          title={this.getMultiLangPopupTitle()}
          showNoticeForDefault={!this.state.multiLangPopup.isForCurrency}
          onChange={this.onLangInputChange.bind(this)}
          onSave={this.onMultiLangPopupSave.bind(this)}
          disableSave={this.viewMode}
          onClose={() => {
            this.setState({
              multiLangPopup: {
                show: false,
                isForCurrency: false
              }
            });
          }}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const newActionId = selectNewActionId(state);
  const editActionId = ownProps.match.params.id || newActionId;
  const dupActionId = ownProps.match.params.dupId;

  const rewardsById = selectRewardsById(state);
  const exclusivesById = selectExclusivesById(state);

  const actionId = editActionId || dupActionId;
  const action = actionId ? cloneDeep(selectActionById(state, actionId)) : {};

  const masterAction = editActionId ? action.masterAction : ownProps.match.params.masterAction;

  const isCurrentPlanExists = selectIsCurrentPlanVersionExists(state);
  const currentPlanVersion = selectCurrentPlanVersion(state);
  const tiers = selectTiers(state);

  return {
    tiersById: isCurrentPlanExists ? selectCurrentPlanTiersMapById(state) : selectTiersMapById(state),
    tiers,
    allActions: selectActions(state),
    currentPlanFixedBenefits: currentPlanVersion.fixedBenefits,
    action,
    masterAction,
    editActionId,
    dupActionId,
    newActionId,
    isNewAction: !ownProps.match.params.id,
    rewardVoucherOptions: rewardService.getVoucherRewardOptionsForAction(rewardsById),
    rewardExclusiveAccessOptions: exclusiveAccessService.getExclusiveAccessRewardOptions(
      exclusivesById,
      null,
      EXCLUSIVE_ACCESS_OFFERS_ENUM.AS_REWARD
    ),
    tierOptions: convertArrayToOptionsArray(
      tiers.filter((tier) => tierService.isRegularTier(tier)),
      "id",
      "name"
    ),
    allVoucherOptions: convertObjectToOptionsArray(rewardsById, "name"),
    allExclusiveAccessOptions: convertObjectToOptionsArray(exclusivesById, "name"),
    rewardsById,
    exclusivesById,
    planOptInValue: selectPlanOptInValue(state),
    planRewardsAllocationDelayValue: selectRewardsAllocationDelay(state),
    postSuccess: isActionPostSuccess(state),
    actionInternalNames: selectActionInternalNames(state)
      .filter((a) => actionId !== a.id)
      .map((a) => a.internalName),
    defaultAction: selectDefaultAction(state, masterAction),
    defaultJoinAction: selectDefaultAction(state, MASTER_ACTION_ENUM.JOIN),
    actionEditState: selectActionEditState(state),
    storeUrlLangMap: selectPlanStoreUrlLangMap(state),
    externalEventsOptions: convertObjectToOptionsArray(selectExternalEvents(state), "name"),
    isExclusiveAccessEnabled: true,
    languages: selectPlanLanguages(state),
    primaryLanguage: selectPlanPrimaryLanguage(state),
    hasMultiLanguage: selectPlanHasMultiLang(state),
    hasMultiMarket: selectPlanHasMultiMarket(state),
    currencies: selectPlanCurrencies(state),
    primaryCurrency: selectPlanPrimaryCurrency(state),
    hasMultiCurrency: selectPlanHasMultiCurrency(state),
    pointsNamesObj: selectPlanPointsNames(state),
    tokenTranslations: state.translation.translations,
    plan: selectPlan(state),
    isAccountViewOnly: isAccountViewOnly(state),
    emsAdminTimezone: selectEmsAdminTimezone(state),
    isPointSplitDisabled: selectCustomerFeaturesConfigByName(state, CONFIG_OBJ_ENUM.DISABLE_POINT_SPLIT)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAction: (action, id, disableAlert, fields) => {
      dispatch(updateAction(action, id, disableAlert, fields));
    },
    setActionEditState: (action, stepId) => {
      dispatch(setActionEditState(action, stepId));
    },
    resetActionEditState: () => {
      dispatch(resetActionEditState());
    },
    resetNewActionId: () => {
      dispatch(resetNewActionId());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionSetup);
