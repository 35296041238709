import validate from "validate.js";

import { MAX_NUMBER_OF_TIERS } from "../constants/onBoardingConstants";
import { getMessage } from "../messages";

export default class tiersCreationValidator {
  constructor() {
    const constraints = {
      numberOfTiers: {
        presence: {
          allowEmpty: false,
          message: getMessage("tiersCreationValidator.numberOfTiers.presence")
        },
        numericality: {
          onlyInteger: true,
          notInteger: getMessage("tiersCreationValidator.numberOfTiers.numericality.notInteger"),
          lessThan: MAX_NUMBER_OF_TIERS,
          notLessThan: getMessage("tiersCreationValidator.numberOfTiers.numericality.notLessThan"),
          greaterThan: 1,
          notGreaterThan: getMessage("tiersCreationValidator.numberOfTiers.numericality.notGreaterThan")
        }
      }
    };

    this.constraints = constraints;
  }

  validateForm = (fields) => {
    return validate(fields, this.constraints);
  };

  validateTierSettings = (tiers, index) => {
    const tier = tiers[index];

    const allZeros = tiers.every((tier) => tier.spend === 0);

    const prevTierSpend = index === 0 ? -1 : tiers[index - 1].spend;

    let constraints = {
      spend: {
        presence: {
          allowEmpty: false,
          message: getMessage("tiersCreationValidator.spend.presence")
        },
        numericality: {
          onlyInteger: true,
          notInteger: getMessage("tiersCreationValidator.spend.numericality.notInteger")
        }
      }
    };

    if (!allZeros) {
      constraints.spend.numericality.greaterThan = prevTierSpend;
      constraints.spend.numericality.notGreaterThan = getMessage(
        "tiersCreationValidator.spend.numericality.notGreaterThan"
      );
    }

    return validate(tier, constraints);
  };
}
