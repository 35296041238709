import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import LoaderOverlay from "./LoaderOverlay";
import { getMessage } from "../messages";

const Widget = ({
  title,
  className,
  content,
  headerActions,
  footerActions,
  loading,
  isEmptyState,
  collapsedContentElements
}) => {
  const [collapsed, setCollapsed] = useState(false);

  const renderCollapsedContent = () => {
    return (
      <div className="e-grid e-grid-vertical_center e-grid-between">
        <div className="e-cell">
          <div className="e-grid e-grid-small e-grid-vertical_center">
            <div className="e-cell e-cell-small">
              <h4 className="e-widget__title">{title}</h4>
            </div>
            {collapsedContentElements.map((element, index) => {
              return (
                <div key={`wcce${index}`} className="e-cell e-cell-small">
                  {element}
                </div>
              );
            })}
          </div>
        </div>
        <div className="e-cell">
          <div className="e-widget__header_action e-btn e-btn-borderless" onClick={() => setCollapsed(false)}>
            {getMessage("extendBtn.label")}
            <e-icon class="e-widget__collapse_icon" icon="e-move-down" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <e-widget class={classNames("e-widget-equal_height", className)}>
      {!collapsed && (
        <header className="e-widget__header">
          <h4 className="e-widget__title">{title}</h4>
          <div className="e-widget__header_actions">
            {headerActions}

            {collapsedContentElements && (
              <div className="e-widget__header_action e-btn e-btn-borderless" onClick={() => setCollapsed(true)}>
                {getMessage("collapseBtn.label")}
                <e-icon class="e-widget__collapse_icon" icon="e-move-up" />
              </div>
            )}
          </div>
        </header>
      )}
      <section
        className={classNames("e-widget__content", {
          "e-padding-m": !collapsed,
          "e-widget__content-collapsed": collapsed,
          "empty-state": isEmptyState
        })}
      >
        {loading ? <LoaderOverlay show={loading} /> : collapsed ? renderCollapsedContent() : content}
      </section>
      {!collapsed && (
        <footer className="e-widget__footer">
          <div className="e-widget__footer_actions">{footerActions}</div>
        </footer>
      )}
    </e-widget>
  );
};

Widget.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  headerActions: PropTypes.element,
  content: PropTypes.element,
  collapsedContentElements: PropTypes.arrayOf(PropTypes.element),
  footerActions: PropTypes.element,
  loading: PropTypes.bool,
  isEmptyState: PropTypes.bool
};

export default Widget;
