export const translate = ({ translations, key, lang, replaceTokens }) => {
  const defaultLang = "en";
  if (!lang) {
    lang = defaultLang;
  }

  //get specific language entry
  const entry = translations[lang] || translations[defaultLang];

  let result = entry[key];

  if (replaceTokens) {
    Object.keys(replaceTokens).forEach((key) => {
      result = result.replace(`{{${key}}}`, replaceTokens[key]);
    });
  }

  return result;
};
