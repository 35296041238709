import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash.isequal";
import merge from "deepmerge";

import FormStepsContainer from "../components/FormStepsContainer";
import MultiLangPopup from "../components/MultiLangPopup";

import { updateStateOnInputChange, getInputNameAndValue } from "../helpers/formHelper";
import { updateObjectByNameAndValue, filterObjectByKeys } from "../helpers/objectHelper";
import { getNavigationCanceled } from "../helpers/navHelper";
import { multiLangUpdateState, initTranslationsObj, setTranslationsOnInputChange } from "../helpers/multiLangHelper";

import { validateSingleValue, convertPathStrName } from "../validators";
import tierValidator from "../validators/tierValidator";

import TierSetupGeneral from "../views/TierSetup/TierSetupGeneral";
import TierSetupPurchaseBenefits from "../views/TierSetup/TierSetupPurchaseBenefits";
import TierSetupVisibility from "../views/TierSetup/TierSetupVisibility";
import MultiLangTierNameField from "../views/TierSetup/MultiLangTierNameField";
import MultiLangPurchaseBenefitFields from "../views/TierSetup/MultiLangPurchaseBenefitFields";

import { updateTier, setTierEditState, resetTierEditState, resetNewTierId } from "../actions/tierActions";

import {
  selectTierById,
  selectPlanSettings,
  isTierPostSuccess,
  selectTiers,
  selectTierEditState,
  selectNewTierId,
  selectTierNames,
  selectCurrentPlanVersion,
  selectCurrentVersionTierById,
  selectPlanCurrencies,
  selectPlanLanguages,
  selectPlanPrimaryLanguage,
  selectPlanHasMultiLang,
  selectShowCurrentPlan,
  selectPlanHasMultiCurrency,
  selectTierExistsInCurrentVersion,
  selectPlan,
  selectPlanCurrencyIdentifierSign,
  selectCustomerFeaturesConfigByName,
  selectActions
} from "../reducers";

import {
  TIER_DROPOUT_ENUM,
  FORM_STEPS,
  FORM_STEPS_INVITE_TIER,
  MULTI_LANG_PROPS_MAP,
  PURCHASE_POINTS_TOKEN,
  PURCHASE_POINTS_TOKEN_LOYALTY,
  PURCHASE_POINTS_TOKEN_STATUS,
  PURCHASE_POINTS_TOKEN_BALANCE,
  PURCHASE_CURRENCY_TOKEN,
  TIER_TYPE_ENUM,
  POINTS_TYPE_ENUM
} from "../constants/tierConstants";
import { TIERS_CALCULATION_TYPE_ENUM } from "../constants/planConstants";
import { CONFIG_OBJ_ENUM } from "../constants/configConstants";

import tierService from "../services/tierService";
import { openDeleteDialog } from "../helpers/dialogHelper";
import { getMessage } from "../messages";
import planService from "../services/planService";

class TierSetup extends Component {
  constructor(props) {
    super(props);

    this.isNavigationCanceled = getNavigationCanceled();

    let tier = {};
    if (props.editTierId) {
      tier = this.getEditTierData();
    } else {
      const defaultValues = this.getDefaultData();
      tier = merge.all([defaultValues, props.tier]);
    }

    this.state = {
      tier,
      showIconsGallery: false,
      redirectToOverview: false,
      currentStep: FORM_STEPS[0],
      currencyPointsPopup: {
        show: false,
        isCatalog: false
      },
      showMultiLangPopup: false,
      translations: {},
      translationErrors: undefined,
      translationPropIndex: undefined,
      multiLangPropsMap: undefined
    };

    this.startStepId = this.isNavigationCanceled && props.tierEditState.stepId ? props.tierEditState.stepId : null;

    this.prevTier = this.getPrevTier(this.props.tier);
    this.nextTier = this.getNextTier(this.props.tier);

    this.validator = new tierValidator({
      currencies: this.props.currencies,
      languages: this.props.languages,
      tierNames: this.props.tierNames.filter((name) => name != tier.name),
      prevTierPoints: this.prevTier ? this.prevTier.settings.points : undefined,
      nextTierPoints: this.nextTier ? this.nextTier.settings.points : undefined,
      tiersCalculationType: this.props.planSettings.rules.tiersCalculationType,
      plan: this.props.plan,
      tierId: props.editTierId,
      actions: this.props.actions
    });
  }

  get viewMode() {
    return this.props.showCurrentVersion;
  }

  //save the current edited tier in case of back in browser
  componentDidMount() {
    this.setMultiLangPropsMap(this.state.tier);
    window.onpopstate = () => {
      if (this.shouldBlockNavigation()) {
        this.props.setTierEditState(this.state.tier, this.state.currentStep.id);
      }
    };
  }

  componentWillUnmount() {
    this.props.resetTierEditState();
    this.props.resetNewTierId();
    window.onpopstate = () => {};
  }

  componentDidUpdate(prevProps) {
    //if tier was saved init the tier state with the saved tier data
    if (!prevProps.postSuccess && this.props.postSuccess) {
      this.initEditTier();
    }

    if (this.props.postSuccess && this.state.redirectToOverview) {
      this.props.history.push("/tiers");
    }
  }

  getEditTierData() {
    if (this.isNavigationCanceled) {
      return this.props.tierEditState.tier;
    }

    let tier = cloneDeep(this.props.tier);

    if (this.viewMode) {
      return tier;
    }

    let doUpdate = false;
    if (!tier.purchaseBenefits) {
      tier.purchaseBenefits = tierService.getPurchaseBenefitsDefaultData(
        tier,
        this.prevTier,
        this.props.currencies,
        this.props.plan
      );
      doUpdate = true;
    }

    if (this.initPointsRatesValues(tier.purchaseBenefits.pointsBenefit)) {
      doUpdate = true;
    }

    tier.purchaseBenefits.pointsCatalogBenefits.forEach((benefit) => {
      if (this.initPointsRatesValues(benefit)) {
        doUpdate = true;
      }
    });

    if (this.initPurchaseBenefitMessage(tier.purchaseBenefits.pointsBenefit)) {
      doUpdate = true;
    }

    if (tier.purchaseBenefits.pointsCatalogBenefits) {
      tier.purchaseBenefits.pointsCatalogBenefits.forEach((benefit) => {
        if (this.initPurchaseBenefitMessage(benefit)) {
          doUpdate = true;
        }
      });
    }

    if (!tier.calculation) {
      const planRules = this.props.planSettings.rules;
      tier.calculation = {
        tierDropout: this.getTierDropoutValue(),
        tiersCalculationYearlyInterval: planRules.tiersCalculationYearlyInterval,
        tiersCalculationMonthlyInterval: planRules.tiersCalculationMonthlyInterval
      };
      doUpdate = true;
    }

    if (!tier.pointsType) {
      tier.pointsType = this.getDefaultPointTypeValue();
      doUpdate = true;
    }

    if (doUpdate) {
      this.props.updateTier(tier, tier.id, true);
    }

    return tier;
  }

  initEditTier() {
    const tier = cloneDeep(this.props.tier);
    this.setState({
      tier
    });
  }

  getDefaultData() {
    const { tiers } = { ...this.props };
    const planRules = this.props.planSettings.rules;
    let tier = {
      name: "",
      tierType: TIER_TYPE_ENUM.REGULAR,
      pointsType: this.getDefaultPointTypeValue(),
      settings: {
        points: undefined,
        purchases: undefined
      },
      restrictions: {
        tierDropout: this.getTierDropoutValue()
      },
      calculation: {
        tierDropout: this.getTierDropoutValue(),
        tiersCalculationYearlyInterval: planRules.tiersCalculationYearlyInterval,
        tiersCalculationMonthlyInterval: planRules.tiersCalculationMonthlyInterval
      }
    };

    if (tiers.length == 0 || tiers.every((tier) => tierService.isInviteTier(tier))) {
      tier.settings.points = 0;
      tier.settings.purchases = 0;
    }

    tier.purchaseBenefits = tierService.getPurchaseBenefitsDefaultData(
      tier,
      this.prevTier,
      this.props.currencies,
      this.props.plan
    );
    this.initPurchaseBenefitMessage(tier.purchaseBenefits.pointsBenefit);
    this.initPurchaseBenefitMessage(tier.purchaseBenefits.pointsCatalogBenefits[0]);
    return tier;
  }

  initPointsRatesValues(benefit) {
    let pointsRates = benefit.pointsRates;

    let isChanged = false;
    if (!pointsRates) {
      pointsRates = {};
    }

    this.props.currencies.forEach((currency) => {
      if (!pointsRates[currency]) {
        pointsRates[currency] = {};
        isChanged = true;
      }
    });

    benefit.pointsRates = pointsRates;
    return isChanged;
  }

  initPurchaseBenefitMessage(purchaseBenefit) {
    let isChanged = false;

    const getNewText = (text) => {
      return purchaseBenefit.text;
    };

    const newText = getNewText(purchaseBenefit.text);
    isChanged = purchaseBenefit.text !== newText;

    if (this.props.hasMultiLanguage) {
      this.props.languages.forEach((lang) => {
        if (!purchaseBenefit.textML) {
          purchaseBenefit.textML = {};
        }

        const newText = getNewText(purchaseBenefit.textML[lang]);
        if (!purchaseBenefit.textML[lang]) {
          purchaseBenefit.textML[lang] = newText;
          isChanged = true;
        }
      });
    }

    purchaseBenefit.text = newText;
    return isChanged;
  }

  updateState(propertyPath, value) {
    const tier = { ...this.state.tier };
    updateObjectByNameAndValue(tier, propertyPath, value);
    this.setState({
      tier
    });
  }

  get isFirstTier() {
    const { tiers, editTierId } = this.props;
    return tiers.length == 0 || tiers[0].id === editTierId;
  }

  get isLastTier() {
    const { tiers, editTierId } = this.props;
    return tiers.length > 0 && tiers[tiers.length - 1].id === editTierId;
  }

  getPointsToken() {
    const { tier } = { ...this.state };
    if (tier.pointsType == POINTS_TYPE_ENUM.LOYALTY) return PURCHASE_POINTS_TOKEN_LOYALTY;
    if (tier.pointsType == POINTS_TYPE_ENUM.BALANCE) return PURCHASE_POINTS_TOKEN_BALANCE;
    return PURCHASE_POINTS_TOKEN_STATUS;
  }

  getPurchaseMessageTokens() {
    return this.props.hasMultiCurrency ? [this.getPointsToken(), PURCHASE_CURRENCY_TOKEN] : null;
  }

  getPrevTier(currTier) {
    const { tiers, editTierId } = this.props;

    //if first tier, return null
    if (this.isFirstTier) {
      return null;
    }

    //if setting up a new tier, return the last tier as prevTier
    if (!editTierId) {
      for (let i = tiers.length - 1; i >= 0; i--) {
        if (tierService.isRegularTier(tiers[i])) {
          return tiers[i];
        }
      }
    }

    const prevTier = tiers.find((t, i) => {
      return i < tiers.length && tiers[i + 1]?.id === currTier.id;
    });

    return prevTier;
  }

  getNextTier(currTier) {
    const { tiers, editTierId } = this.props;

    //if last tier, return null
    if (this.isLastTier) {
      return null;
    }

    //if setting up a new tier, return null
    if (!editTierId) {
      return null;
    }

    const nextTier = tiers.find((t, i) => {
      return i > 0 && tiers[i - 1].id === currTier.id;
    });

    return nextTier;
  }

  onInputChange(event, customValue) {
    const { tier, multiLangPropsMap } = { ...this.state };

    const inputName = updateStateOnInputChange({
      stateObj: tier,
      event,
      customValue,
      hasMultiLanguage: this.props.hasMultiLanguage,
      primaryLanguage: this.props.primaryLanguage,
      multiLangPropsMap: multiLangPropsMap
    });

    const errors = validateSingleValue(() => this.validate(tier), this.state.errors, inputName);

    this.setState({
      tier,
      errors
    });

    return tier;
  }

  onLangInputChange(event, lang, customValue) {
    const translations = { ...this.state.translations };
    setTranslationsOnInputChange({ translations, event, lang, customValue });
    const { name } = getInputNameAndValue(event);
    const nameToValidate = `${convertPathStrName(name)}.${lang}`;

    const translationErrors = validateSingleValue(
      () => this.validator.validateMultiLangPopup(translations),
      this.state.translationErrors,
      nameToValidate
    );

    this.setState({
      translations,
      translationErrors
    });
  }

  onMultiLangPopupSave() {
    const { tier, translations, multiLangPropsMap } = { ...this.state };

    const translationErrors = this.validator.validateMultiLangPopup(translations);

    if (!translationErrors) {
      multiLangUpdateState({
        stateObj: tier,
        translations,
        propsMap: multiLangPropsMap,
        primaryLanguage: this.props.primaryLanguage
      });
      this.setState({
        tier,
        showMultiLangPopup: false
      });
    }

    this.setState({
      translationErrors
    });
  }

  onIconInputChange(icon) {
    let tier = { ...this.state.tier };
    tier.settings.icon = icon;
    this.setState({
      tier
    });
  }

  onTierTypeChange(event) {
    let tier = this.onInputChange(event);

    if (tierService.isInviteTier(tier)) {
      tier.settings.points = null;
      tier.visible = true;
    } else {
      tier.settings.points = this.isFirstTier ? 0 : null;
    }

    this.setState({ tier });
  }

  onCatalogSelectionChange({ benefitIndex, brands, categories }) {
    const { tier } = { ...this.state };

    tier.purchaseBenefits.pointsCatalogBenefits[benefitIndex].brands = brands;
    tier.purchaseBenefits.pointsCatalogBenefits[benefitIndex].categories = categories;

    //Todo: support index
    const errors = validateSingleValue(
      () => this.validate(tier),
      this.state.errors,
      `purchaseBenefits.pointsCatalogBenefits.${benefitIndex}`
    );

    this.setState({
      tier,
      errors
    });
  }

  onLocaltyCreditFromPurchasesChange(event) {
    const pointsName = `purchaseBenefits.pointsBenefit.pointsRates.${this.props.currencies[0]}.points`;
    const spentName = `purchaseBenefits.pointsBenefit.pointsRates.${this.props.currencies[0]}.spent`;
    const { tier, multiLangPropsMap } = { ...this.state };
    let val = event.target.value === "0" ? 0 : 1;

    updateStateOnInputChange({
      stateObj: tier,
      event,
      customValue: val,
      hasMultiLanguage: this.props.hasMultiLanguage,
      primaryLanguage: this.props.primaryLanguage,
      multiLangPropsMap: multiLangPropsMap,
      customName: pointsName
    });

    updateStateOnInputChange({
      stateObj: tier,
      event,
      customValue: 1,
      hasMultiLanguage: this.props.hasMultiLanguage,
      primaryLanguage: this.props.primaryLanguage,
      multiLangPropsMap: multiLangPropsMap,
      customName: spentName
    });

    this.setState({
      tier
    });

    return tier;
  }

  onStepChange(step) {
    this.setState({
      currentStep: step,
      errors: undefined
    });
  }

  setMultiLangPropsMap(tier) {
    let propsMap = MULTI_LANG_PROPS_MAP;
    tier.purchaseBenefits.pointsCatalogBenefits.forEach((element, index) => {
      propsMap[
        `purchaseBenefits.pointsCatalogBenefits.${index}.titleML`
      ] = `purchaseBenefits.pointsCatalogBenefits.${index}.title`;
      propsMap[
        `purchaseBenefits.pointsCatalogBenefits.${index}.textML`
      ] = `purchaseBenefits.pointsCatalogBenefits.${index}.text`;
    });

    this.setState({ multiLangPropsMap: propsMap });
  }

  toggleShowCurrencyPointsPopup({ isCatalog }) {
    const currencyPointsPopup = {
      show: !this.state.currencyPointsPopup.show,
      isCatalog: !!isCatalog
    };
    this.setState({
      currencyPointsPopup
    });
  }

  onShowTierNameMultiLang() {
    const { tier, multiLangPropsMap } = { ...this.state };
    const propsMap = filterObjectByKeys(multiLangPropsMap, ["nameML"]);
    const translations = initTranslationsObj({ stateObj: tier, propsMap, primaryLanguage: this.props.primaryLanguage });

    this.setState({
      translations,
      translationErrors: undefined,
      showMultiLangPopup: true,
      errors: undefined
    });
  }

  onShowPurchaseBenefitsMessageMultiLang({ isCatalog, index }) {
    const { tier, multiLangPropsMap } = { ...this.state };

    const propPrefix = isCatalog ? `purchaseBenefits.pointsCatalogBenefits.${index}` : "purchaseBenefits.pointsBenefit";

    const propsMap = filterObjectByKeys(multiLangPropsMap, [`${propPrefix}.titleML`, `${propPrefix}.textML`]);

    const translations = initTranslationsObj({
      stateObj: tier,
      propsMap,
      primaryLanguage: this.props.primaryLanguage
    });

    this.setState({
      translations,
      translationErrors: undefined,
      translationPropIndex: index,
      showMultiLangPopup: true,
      errors: undefined
    });
  }

  getFormSteps() {
    const { tier } = { ...this.props };
    let steps = tierService.isInviteTier(tier) ? FORM_STEPS_INVITE_TIER : FORM_STEPS;

    if (!tier.name) {
      return steps;
    }

    return steps.map((step) => {
      return { ...step, disabled: false };
    });
  }

  validate(tier) {
    const stepId = this.state.currentStep.id;
    return this.validator.validateStep(stepId, tier);
  }

  validateStep() {
    const { tier } = this.state;
    const errors = this.validate(tier);

    this.setState({
      errors
    });

    return errors === undefined;
  }

  getTierDropoutValue() {
    const { rules } = this.props.planSettings;

    if (this.isFirstTier) {
      return TIER_DROPOUT_ENUM.NO;
    }

    if (rules.tiersCalculationType === TIERS_CALCULATION_TYPE_ENUM.NO_DROPOUT) {
      return TIER_DROPOUT_ENUM.NO;
    }

    return TIER_DROPOUT_ENUM.YES;
  }

  getDefaultPointTypeValue() {
    if (planService.isSpendPlan(this.props.plan)) {
      return POINTS_TYPE_ENUM.STATUS;
    }
    return POINTS_TYPE_ENUM.LOYALTY;
  }

  onSubmit() {
    if (this.props.isAccountViewOnly) {
      return;
    }

    const tier = { ...this.props.tier, ...this.state.tier };

    const tierId = this.props.editTierId;
    this.props.updateTier(tier, tierId);

    const steps = this.getFormSteps();
    if (this.state.currentStep.id === steps[steps.length - 1].id) {
      this.setState({
        redirectToOverview: true
      });
    }
  }

  onDeleteCatalogBenefit(index) {
    const { tier } = { ...this.state };

    const deleteCatalogBenefit = () => {
      tier.purchaseBenefits.pointsCatalogBenefits.splice(index, 1);
      this.setState({ tier });
      this.setMultiLangPropsMap(tier);
    };

    openDeleteDialog(
      getMessage("tierSetup.deleteCatalogBenefitDialog.headline"),
      getMessage("tierSetup.deleteCatalogBenefitDialog.content"),
      deleteCatalogBenefit
    );
  }

  onAddCatalogBenefit() {
    const { tier } = { ...this.state };
    const prevBenefit =
      tier.purchaseBenefits.pointsCatalogBenefits[tier.purchaseBenefits.pointsCatalogBenefits.length - 1];

    tier.purchaseBenefits.pointsCatalogBenefits.push(
      tierService.getPurchaseCatalogBenefitData({
        currencies: this.props.currencies,
        title: prevBenefit.title,
        text: prevBenefit.text,
        icon: prevBenefit.icon
      })
    );

    this.setMultiLangPropsMap(tier);
    this.setState({ tier });
  }

  shouldBlockNavigation() {
    if (this.props.editTierId) {
      return !isEqual(this.state.tier, this.props.tier);
    }

    return false;
  }

  onCustomConfirmNavigation() {
    this.initEditTier();
  }

  getMultiLangPopupFields() {
    const { translations, translationPropIndex } = { ...this.state };

    if (!!translations.nameML) {
      return <MultiLangTierNameField disabled={this.viewMode} errors={this.state.translationErrors} />;
    } else if (!!translations["purchaseBenefits.pointsBenefit.titleML"]) {
      return (
        <MultiLangPurchaseBenefitFields
          propPrefix="purchaseBenefits.pointsBenefit"
          disabled={this.viewMode}
          errors={this.state.translationErrors}
          tokens={this.getPurchaseMessageTokens()}
        />
      );
    } else if (Object.keys(translations).some((t) => t.indexOf("purchaseBenefits.pointsCatalogBenefits") === 0)) {
      return (
        <MultiLangPurchaseBenefitFields
          propPrefix={`purchaseBenefits.pointsCatalogBenefits.${translationPropIndex}`}
          disabled={this.viewMode}
          errors={this.state.translationErrors}
          tokens={this.getPurchaseMessageTokens()}
        />
      );
    }

    return null;
  }

  render() {
    const { tier, errors } = this.state;
    const { editTierId } = this.props;

    if (!tier) {
      return null;
    }

    const title = !editTierId
      ? getMessage("tierSetup.title.new")
      : getMessage("tierSetup.title.edit", { name: tier.name });
    return (
      <Fragment>
        <FormStepsContainer
          id={editTierId}
          title={title}
          steps={this.getFormSteps()}
          onSubmit={this.onSubmit.bind(this)}
          nextStepOnSubmit={true}
          applySubmitOnNextStep={true}
          overviewLink="/tiers"
          className="tier-setup-form"
          onStepChange={this.onStepChange.bind(this)}
          validateStepFunc={this.validateStep.bind(this)}
          shouldBlockNavigationFunc={this.shouldBlockNavigation.bind(this)}
          onCustomConfirmNavigation={this.onCustomConfirmNavigation.bind(this)}
          hasErrors={!!this.state.errors}
          startStepId={this.startStepId}
          disableSubmitButton={this.props.showCurrentVersion}
        >
          <TierSetupGeneral
            tier={tier}
            onInputChange={this.onInputChange.bind(this)}
            onIconInputChange={this.onIconInputChange.bind(this)}
            onShowTierNameMultiLang={this.onShowTierNameMultiLang.bind(this)}
            onTierTypeChange={this.onTierTypeChange.bind(this)}
            errors={errors}
            prevTier={this.prevTier}
            isFirstRegularTier={tierService.isFirstRegularTier(tier.id, this.props.tiers)}
            disabled={this.viewMode}
            tiersCalculationType={this.props.planSettings.rules.tiersCalculationType}
            hasMultiLanguage={this.props.hasMultiLanguage}
            tierExistsInCurrentPlan={this.props.tierExistsInCurrentPlan(tier.id)}
            plan={this.props.plan}
            currency={this.props.currencies[0]}
          />
          <TierSetupPurchaseBenefits
            tier={tier}
            onInputChange={this.onInputChange.bind(this)}
            onIconInputChange={this.updateState.bind(this)}
            onCatalogSelectionChange={this.onCatalogSelectionChange.bind(this)}
            onShowCurrencyPointsPopupCatalog={() => this.toggleShowCurrencyPointsPopup({ isCatalog: true })}
            onShowPurchaseBenefitsMessageMultiLang={this.onShowPurchaseBenefitsMessageMultiLang.bind(this)}
            errors={errors}
            disabled={this.viewMode}
            currencies={this.props.currencies}
            tokens={this.getPurchaseMessageTokens()}
            hasMultiLanguage={this.props.hasMultiLanguage}
            onDeleteCatalogBenefit={this.onDeleteCatalogBenefit.bind(this)}
            onAddCatalogBenefit={this.onAddCatalogBenefit.bind(this)}
            plan={this.props.plan}
            planCurrencyIdentifierSign={this.props.planCurrencyIdentifierSign}
            onLocaltyCreditFromPurchasesChange={this.onLocaltyCreditFromPurchasesChange.bind(this)}
            isPointSplitDisabled={this.props.isPointSplitDisabled}
          />
          <TierSetupVisibility
            tier={tier}
            onInputChange={this.onInputChange.bind(this)}
            disabled={this.viewMode}
            isFirstTier={this.isFirstTier}
          />
        </FormStepsContainer>

        <MultiLangPopup
          id={"tier_setup_multi_lang"}
          show={this.state.showMultiLangPopup}
          languages={this.props.languages}
          translations={this.state.translations}
          element={this.getMultiLangPopupFields()}
          onChange={this.onLangInputChange.bind(this)}
          onSave={this.onMultiLangPopupSave.bind(this)}
          onClose={() => {
            this.setState({
              showMultiLangPopup: false
            });
          }}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const newTierId = selectNewTierId(state);
  const editTierId = ownProps.match.params.id || newTierId;
  const showCurrentVersion = selectShowCurrentPlan(state);

  var tier;
  if (editTierId) {
    if (showCurrentVersion) {
      tier = selectCurrentVersionTierById(state, editTierId);
    } else {
      tier = selectTierById(state, editTierId);
    }
  } else {
    tier = {};
  }

  return {
    editTierId,
    newTierId,
    isNewTier: !ownProps.match.params.id,
    planSettings: selectPlanSettings(state),
    tier,
    tiers: showCurrentVersion ? selectCurrentPlanVersion(state).tiers : selectTiers(state),
    postSuccess: isTierPostSuccess(state),
    tierEditState: selectTierEditState(state),
    tierNames: selectTierNames(state),
    showCurrentVersion,
    currencies: selectPlanCurrencies(state),
    languages: selectPlanLanguages(state),
    primaryLanguage: selectPlanPrimaryLanguage(state),
    hasMultiLanguage: selectPlanHasMultiLang(state),
    hasMultiCurrency: selectPlanHasMultiCurrency(state),
    tierExistsInCurrentPlan: selectTierExistsInCurrentVersion(state),
    plan: selectPlan(state),
    planCurrencyIdentifierSign: selectPlanCurrencyIdentifierSign(state),
    isPointSplitDisabled: selectCustomerFeaturesConfigByName(state, CONFIG_OBJ_ENUM.DISABLE_POINT_SPLIT),
    actions: selectActions(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateTier: (tier, id, disableAlert) => {
    dispatch(updateTier(tier, id, disableAlert));
  },
  setTierEditState: (tier, stepId) => {
    dispatch(setTierEditState(tier, stepId));
  },
  resetTierEditState: () => {
    dispatch(resetTierEditState());
  },
  resetNewTierId: () => {
    dispatch(resetNewTierId());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TierSetup);
