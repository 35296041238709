import FormStepsContainer from "../components/FormStepsContainer";
import { getMessage } from "../messages";
import { INVITE_TIER_HELP_LINK } from "./linkConstants";

export const FORM_STEP_ENUM = {
  TIER_SETTINGS: "tierSettings",
  PURCHASE_BENEFITS: "purchaseBenefits",
  // FIXED_BENEFITS: "fixedBenefits"
  VISIBILITY: "visibility"
};

export const FORM_STEPS = [
  {
    id: FORM_STEP_ENUM.TIER_SETTINGS,
    title: getMessage("formStepsContainer.tierSettings.title")
  },
  {
    id: FORM_STEP_ENUM.PURCHASE_BENEFITS,
    title: getMessage("formStepsContainer.purchaseBenefits.title")
  }
];

export const FORM_STEPS_INVITE_TIER = [
  ...FORM_STEPS,
  { id: FORM_STEP_ENUM.VISIBILITY, title: getMessage("formStepsContainer.visibility.title") }
];

export const TIER_DROPOUT_ENUM = {
  YES: "yes",
  NO: "no"
};

export const TIER_DROPOUT_OPTIONS = [
  {
    name: getMessage("tierDropout.options.yes.name"),
    value: TIER_DROPOUT_ENUM.YES
  },
  {
    name: getMessage("tierDropout.options.no.name"),
    value: TIER_DROPOUT_ENUM.NO
  }
];

export const BRAND_SELECTION_OPTIONS = Array.from(Array(30).keys()).map((index) => {
  return "brand" + index;
});

export const BENEFIT_TYPE_ENUM = {
  EVENT_ACTION: "eventAction",
  EXCLUSIVE_ACCESS: "exclusiveAccess",
  ONE_TIME_VOUCHER: "oneTimeVoucher",
  FIXED_YEARLY_VOUCHER: "fixedYearlyVoucher",
  CUSTOM: "custom"
};

export const FIXED_BENEFIT_TYPE_OPTIONS = [
  {
    name: getMessage("fixedBenfitType.options.oneTimeVoucher.name"),
    value: BENEFIT_TYPE_ENUM.ONE_TIME_VOUCHER
  },
  {
    name: getMessage("fixedBenfitType.options.fixedYearlyVoucher.name"),
    value: BENEFIT_TYPE_ENUM.FIXED_YEARLY_VOUCHER
  },
  {
    name: getMessage("fixedBenfitType.options.eventAction.name"),
    value: BENEFIT_TYPE_ENUM.EVENT_ACTION
  },
  {
    name: getMessage("fixedBenfitType.options.exclusiveAccess.name"),
    value: BENEFIT_TYPE_ENUM.EXCLUSIVE_ACCESS
  },
  {
    name: getMessage("fixedBenfitType.options.custom.name"),
    value: BENEFIT_TYPE_ENUM.CUSTOM
  }
];

export const ADDITIONAL_BENEFIT_TYPE_OPTIONS = [
  {
    name: getMessage("fixedBenfitType.options.oneTimeVoucher.name"),
    value: BENEFIT_TYPE_ENUM.ONE_TIME_VOUCHER
  },
  {
    name: getMessage("fixedBenfitType.options.fixedYearlyVoucher.additional.name"),
    value: BENEFIT_TYPE_ENUM.FIXED_YEARLY_VOUCHER
  },
  {
    name: getMessage("fixedBenfitType.options.exclusiveAccess.name"),
    value: BENEFIT_TYPE_ENUM.EXCLUSIVE_ACCESS
  }
];

export const MULTI_LANG_PROPS_MAP = {
  nameML: "name",
  "purchaseBenefits.pointsBenefit.titleML": "purchaseBenefits.pointsBenefit.title",
  "purchaseBenefits.pointsBenefit.textML": "purchaseBenefits.pointsBenefit.text",
  "purchaseBenefits.pointsCatalogBenefit.titleML": "purchaseBenefits.pointsCatalogBenefit.title",
  "purchaseBenefits.pointsCatalogBenefit.textML": "purchaseBenefits.pointsCatalogBenefit.text"
};

export const MULTI_LANG_BENEFITS_PROPS_MAP = {
  nameML: "name",
  customML: "custom"
};

export const PURCHASE_POINTS_TOKEN = {
  token: "{{pointsToken}}",
  display: getMessage("purchasePointsToken.display")
};

export const PURCHASE_POINTS_TOKEN_LOYALTY = {
  token: "{{pointsToken}}",
  display: getMessage("purchasePointsToken.display")
};

export const PURCHASE_POINTS_TOKEN_STATUS = {
  token: "{{pointsToken}}",
  display: getMessage("purchasePointsToken.display.status")
};

export const PURCHASE_POINTS_TOKEN_BALANCE = {
  token: "{{pointsToken}}",
  display: getMessage("purchasePointsToken.display.balance")
};

export const PURCHASE_CURRENCY_TOKEN = {
  token: "{{currencyToken}}",
  display: getMessage("purchaseCurrencyToken.display")
};

export const TIER_TYPE_ENUM = {
  REGULAR: "regular",
  INVITE: "invite"
};

const inviteTierTooltip = getMessage("tierTypeOptions.invite.tooltip", {
  learnMoreLink: `<a target="_blank" href="${INVITE_TIER_HELP_LINK}">${getMessage(
    "tierTypeOptions.invite.tooltip.learnMoreLinkText"
  )}</a>`
});

export const TIER_TYPE_OPTIONS = [
  {
    name: getMessage("tierTypeOptions.regular.name"),
    value: TIER_TYPE_ENUM.REGULAR
  },
  {
    name: getMessage("tierTypeOptions.invite.name"),
    value: TIER_TYPE_ENUM.INVITE,
    hoverableTooltip: true,
    tooltip: inviteTierTooltip
  }
];

export const TIER_TYPE_OPTIONS_SPEND = [
  {
    name: getMessage("tierTypeOptions.spend.name"),
    value: TIER_TYPE_ENUM.REGULAR
  },
  {
    name: getMessage("tierTypeOptions.invite.name"),
    value: TIER_TYPE_ENUM.INVITE,
    hoverableTooltip: true,
    tooltip: inviteTierTooltip
  }
];

export const VISIBILITY_OPTIONS = [
  {
    name: getMessage("visibility.options.visible"),
    value: true
  },
  {
    name: getMessage("visibility.options.invisible"),
    value: false
  }
];

export const POINTS_TYPE_ENUM = {
  LOYALTY: "loyalty",
  STATUS: "status",
  BALANCE: "balance"
};

/* Note - messages are the same as in the member profile update points */

export const POINTS_TYPE_OPTIONS_POINTS_PLAN = [
  {
    name: getMessage("tierSetupPurchaseBenefits.pointsType.options.loyalty"),
    value: POINTS_TYPE_ENUM.LOYALTY
  },
  {
    name: getMessage("tierSetupPurchaseBenefits.pointsType.options.status"),
    value: POINTS_TYPE_ENUM.STATUS
  },
  {
    name: getMessage("tierSetupPurchaseBenefits.pointsType.options.spend"),
    value: POINTS_TYPE_ENUM.BALANCE
  }
];
