import React, { Fragment } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import { useSelector } from "react-redux";

import InputField from "../../components/InputField";
import InputNumberField from "../../components/InputNumberField";
import SelectField from "../../components/SelectFieldV2";
import IconSelectInput from "../../components/IconSelectInput";
import RadioButtonList from "../../components/RadioButtonList";
import MultiLangPopupOpener from "../../components/MultiLangPopupOpener";
import Notification from "../../components/Notification";

import { TIERS_CALCULATION_TYPE_ENUM } from "../../constants/planConstants";
import {
  TIER_DROPOUT_OPTIONS,
  TIER_DROPOUT_ENUM,
  TIER_TYPE_OPTIONS,
  TIER_TYPE_ENUM,
  TIER_TYPE_OPTIONS_SPEND
} from "../../constants/tierConstants";
import { CONFIG_OBJ_ENUM } from "../../constants/configConstants";

import iconsApi from "../../api/iconsApi";

import { tierType } from "../../types";

import { getTierIconsUrl } from "../../helpers/envHelper";
import { getCurrencySymbol } from "../../helpers/currencyHelper";

import { selectCustomerFeaturesConfigByName } from "../../reducers";

import { getErrorMessage } from "../../validators";
import tierService from "../../services/tierService";
import planService from "../../services/planService";
import { getMessage, getPluralCardinalMessage } from "../../messages";

const TierSetupGeneral = (props) => {
  const {
    tier,
    prevTier,
    isFirstRegularTier,
    onInputChange,
    onTierTypeChange,
    errors,
    onIconInputChange,
    onShowTierNameMultiLang,
    disabled,
    tiersCalculationType,
    hasMultiLanguage,
    tierExistsInCurrentPlan,
    plan,
    currency
  } = props;

  const isPointsDisabled = isFirstRegularTier;
  let pointsPlaceholder = "";

  let tierValueInPointsTooltip = "";
  let numberOfPurchasesTooltip = "";

  if (!isPointsDisabled && tierService.isRegularTier(tier)) {
    const prevTierPoints = prevTier.settings.points + 1;
    pointsPlaceholder = prevTier
      ? //? `A minimum of ${pluralize(pointTypeName, prevTier.settings.points + 1, true)} is required`
        planService.isSpendPlan(plan)
        ? getPluralCardinalMessage("tierSetupPurchaseBenefits.tierMinimumPoints", prevTierPoints, {
            number: prevTierPoints
          })
        : getPluralCardinalMessage("tierSetupPurchaseBenefits.tierMinimumSpend", prevTierPoints, {
            number: prevTierPoints
          })
      : "";

    tierValueInPointsTooltip = planService.isSpendPlan(plan)
      ? getMessage("tierSetupGeneral.tierValueInPointsTooltip.spend")
      : getMessage("tierSetupGeneral.tierValueInPointsTooltip.points");
    numberOfPurchasesTooltip = getMessage("tierSetupGeneral.numberOfPurchasesTooltip.pointsOrSpend");
  } else {
    tierValueInPointsTooltip = numberOfPurchasesTooltip = getMessage("tierSetupGeneral.tierValueInPointsTooltip.other");
  }

  let tierTypeOptions = () => {
    if (!planService.isSpendPlan(plan)) return TIER_TYPE_OPTIONS;

    return TIER_TYPE_OPTIONS_SPEND;
  };

  const isGroupEnabled = useSelector((state) =>
    selectCustomerFeaturesConfigByName(state, CONFIG_OBJ_ENUM.ENABLE_MEMBER_GROUP)
  );

  if (isGroupEnabled) {
    tierTypeOptions().forEach((option) => {
      if (option.value === TIER_TYPE_ENUM.INVITE) {
        option.disabled = true;
      }
    });
  }

  return (
    <div className="e-box">
      <div className="e-row">
        <div className="e-col-6">
          <h2>{getMessage("tierSetupGeneral.title")}</h2>

          <div>
            <RadioButtonList
              label={getMessage("tierSetupGeneral.tierType.label")}
              name="tierType"
              value={tier.tierType}
              options={tierTypeOptions()}
              onChange={onTierTypeChange}
              disabled={disabled || tierExistsInCurrentPlan}
              isRequired={true}
            />
          </div>

          {tierService.isInviteTier(tier) && (
            <Notification content={getMessage("tierSetupGeneral.inviteTier.notification")} />
          )}

          <InputField
            label={getMessage("tierSetupGeneral.tierName.label")}
            name="name"
            value={tier.name}
            onChange={onInputChange}
            isRequired={true}
            errorMsg={getErrorMessage(errors, "name") || getErrorMessage(errors, "nameML")}
            tooltip={getMessage("tierSetupGeneral.tierName.tooltip")}
            disabled={disabled}
            postfixButtons={hasMultiLanguage && <MultiLangPopupOpener onClick={onShowTierNameMultiLang} />}
          />

          <IconSelectInput
            label={getMessage("tierSetupGeneral.tierIcon.label")}
            name="settings.icon"
            displayName={tier.settings.icon && tier.settings.icon.displayName}
            fileName={tier.settings.icon && tier.settings.icon.fileName}
            iconPrefixUrl={getTierIconsUrl()}
            selectIconText={getMessage("tierSetupGeneral.tierIcon.iconText")}
            onChange={onIconInputChange}
            loadPromise={iconsApi.getTierIcons}
            loadCustomerPromise={iconsApi.getCustomerIcons}
            iconType="tier"
            tooltip={getMessage("tierSetupGeneral.tierIcon.tooltip")}
            disabled={disabled}
          />
        </div>
        {tierService.isRegularTier(tier) && (
          <Fragment>
            <div className="e-col-6">
              <h2>{getMessage("tierSetupGeneral.requirements.title")}</h2>
              <InputNumberField
                label={
                  !planService.isSpendPlan(plan)
                    ? getMessage("tierSetupGeneral.tierPoints.label.points")
                    : getMessage("tierSetupGeneral.tierPoints.label.spend")
                }
                name="settings.points"
                value={tier.settings.points}
                onChange={onInputChange}
                postfixLabel={
                  !planService.isSpendPlan(plan)
                    ? getMessage("tierSetupGeneral.tierPoints.postfixLabel.points")
                    : getMessage("tierSetupGeneral.tierPoints.postfixLabel.spend", {
                        value: getCurrencySymbol(currency)
                      })
                }
                tooltip={tierValueInPointsTooltip}
                errorMsg={getErrorMessage(errors, "settings.points")}
                min={0}
                disabled={isPointsDisabled || disabled}
                placeholder={pointsPlaceholder}
                isRequired={true}
              />
              <InputNumberField
                label={getMessage("tierSetupGeneral.tierPurchases.label")}
                name="settings.purchases"
                value={tier.settings.purchases}
                onChange={onInputChange}
                tooltip={numberOfPurchasesTooltip}
                min={0}
                disabled={isPointsDisabled || disabled}
                postfixLabel={getMessage("tierSetupGeneral.tierPurchases.postfixLabel")}
              />

              {/* Don't render if first tier or tier calculation is set to no calculation */}
              {tier.settings.points === 0 || tiersCalculationType === TIERS_CALCULATION_TYPE_ENUM.NO_DROPOUT ? null : (
                <Fragment>
                  <SelectField
                    label={getMessage("tierSetupGeneral.calculation.tierDropout.label")}
                    name="calculation.tierDropout"
                    value={tier.calculation.tierDropout}
                    options={TIER_DROPOUT_OPTIONS}
                    onChange={onInputChange.bind(this)}
                    tooltip={getMessage("tierSetupGeneral.calculation.tierDropout.tooltip")}
                    disabled={disabled}
                  />

                  {tier.calculation.tierDropout === TIER_DROPOUT_ENUM.YES &&
                    tiersCalculationType === TIERS_CALCULATION_TYPE_ENUM.END_OF_YEAR && (
                      <InputNumberField
                        label={getMessage("tierSetupGeneral.calculation.tiersCalculationYearlyInterval.label")}
                        tooltip={getMessage("tierSetupGeneral.calculation.tiersCalculationYearlyInterval.tooltip")}
                        name="calculation.tiersCalculationYearlyInterval"
                        value={tier.calculation.tiersCalculationYearlyInterval}
                        onChange={onInputChange}
                        postfixLabel={getMessage(
                          "tierSetupGeneral.calculation.tiersCalculationYearlyInterval.postfixLabel"
                        )}
                        errorMsg={getErrorMessage(errors, "calculation.tiersCalculationYearlyInterval")}
                        labelClassName="no-margin"
                        disabled={disabled}
                      />
                    )}

                  {tier.calculation.tierDropout === TIER_DROPOUT_ENUM.YES &&
                    tiersCalculationType === TIERS_CALCULATION_TYPE_ENUM.AFTER_JOIN && (
                      <InputNumberField
                        label={getMessage("tierSetupGeneral.calculation.tiersCalculationMonthlyInterval.label")}
                        tooltip={getMessage("tierSetupGeneral.calculation.tiersCalculationMonthlyInterval.tooltip")}
                        name="calculation.tiersCalculationMonthlyInterval"
                        value={tier.calculation.tiersCalculationMonthlyInterval}
                        onChange={onInputChange}
                        postfixLabel={getMessage(
                          "tierSetupGeneral.calculation.tiersCalculationMonthlyInterval.postfixLabel"
                        )}
                        errorMsg={getErrorMessage(errors, "calculation.tiersCalculationMonthlyInterval")}
                        labelClassName="no-margin"
                        disabled={disabled}
                      />
                    )}
                </Fragment>
              )}
            </div>
            <div className="e-col e-col__separator" />
          </Fragment>
        )}
      </div>
    </div>
  );
};

TierSetupGeneral.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onIconInputChange: PropTypes.func.isRequired,
  onTierTypeChange: PropTypes.func.isRequired,
  tier: tierType.isRequired,
  prevTier: tierType,
  isFirstRegularTier: PropTypes.bool,
  onShowTierNameMultiLang: PropTypes.func,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
  tiersCalculationType: PropTypes.string,
  hasMultiLanguage: PropTypes.bool,
  tierExistsInCurrentPlan: PropTypes.bool
};

export default TierSetupGeneral;
