import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import Widget from "../../components/Widget";
import WidgetDateRange from "../../components/WidgetDateRange";
import DashboardEmptyState from "./DashboardEmptyState";
import WidgetHelpLink from "./WidgetHelpLink";
import LegendWithSelection from "./LegendWithSelection";
import WidgetTotalsItem from "./WidgetTotalsItem";

import { PLAN_TYPE_ENUM } from "../../constants/planConstants";
import { VOUCHERS_ANALYTICS_HELP_LINK } from "../../constants/dashboardConstants";

import reportApi from "../../api/reportApi";

import { convertDateForChart, formatTrendText, transformDataDateForDemo, getDaysDiff } from "./dashboardHelper";
import { getMessage } from "../../messages";

const CHART_HEIGHT = 164;

const CHART_COLORS = {
  REWARD: "#86D985",
  POINTS: "#52B9DE",
  FREE: "#fcb15a"
};

const REWARD_META_DATA = {
  id: "reward",
  displayName: getMessage("vouchersAnalyticsWidget.rewardMetaData.displayName"),
  color: CHART_COLORS.REWARD,
  selectedInLegend: true,
  totalsTooltip: getMessage("vouchersAnalyticsWidget.rewardMetaData.totalsTooltip")
};

const POINTS_META_DATA = {
  id: "points",
  displayName: getMessage("vouchersAnalyticsWidget.pointsMetaData.displayName"),
  color: CHART_COLORS.POINTS,
  selectedInLegend: true,
  totalsTooltip: getMessage("vouchersAnalyticsWidget.pointsMetaData.totalsTooltip")
};

const FREE_META_DATA = {
  id: "free",
  displayName: getMessage("vouchersAnalyticsWidget.freeMetaData.displayName"),
  color: CHART_COLORS.FREE,
  selectedInLegend: true,
  totalsTooltip: getMessage("vouchersAnalyticsWidget.freeMetaData.totalsTooltip")
};

const CASHBACK_META_DATA = {
  id: "cashback",
  displayName: getMessage("vouchersAnalyticsWidget.cashbackMetaData.displayName"),
  color: CHART_COLORS.POINTS,
  selectedInLegend: true,
  totalsTooltip: getMessage("vouchersAnalyticsWidget.cashbackMetaData.totalsTooltip")
};

const CHART_META_DATA_POINTS = [REWARD_META_DATA, POINTS_META_DATA, FREE_META_DATA];
const CHART_META_DATA_POINTS_AND_BENEFITS = [REWARD_META_DATA, FREE_META_DATA];
const CHART_META_DATA_SPEND = [REWARD_META_DATA, CASHBACK_META_DATA, FREE_META_DATA];

class VouchersAnalyticsWidget extends Component {
  constructor(props) {
    super(props);

    let legendSelection = {};
    let chartSeriesArr = [];

    switch (props.plan.planType) {
      case PLAN_TYPE_ENUM.POINTS:
        this.chartMetaData = CHART_META_DATA_POINTS;
        break;
      case PLAN_TYPE_ENUM.POINTS_AND_BENEFITS:
        this.chartMetaData = CHART_META_DATA_POINTS_AND_BENEFITS;
        break;
      case PLAN_TYPE_ENUM.SPEND:
        this.chartMetaData = CHART_META_DATA_SPEND;
        break;
    }

    this.chartMetaData.forEach((meta) => {
      legendSelection[meta.id] = !!meta.selectedInLegend;
      chartSeriesArr.push({ id: meta.id, name: meta.displayName, color: meta.color, data: [] });
    });

    this.state = {
      loading: true,
      selectedDaysRange: getDaysDiff(props.initialStartDate, props.initialEndDate),
      dateRange: {
        startDateStr: props.initialStartDate,
        endDateStr: props.initialEndDate
      },
      chartSeriesArr,
      totals: {},
      legendSelection,
      showEmptyState: false
    };
  }

  componentDidMount() {
    this.loadData(this.state.dateRange);
  }

  loadData(dateRange) {
    const promises = [reportApi.getVouchersChartData(dateRange), reportApi.getVouchersChartTotals(dateRange)];

    this.setState({ loading: true, showEmptyState: false });

    Promise.all(promises)
      .then((response) => {
        this.setChartSeriesState(response[0].data);
        this.setTotalsState(response[1]);
      })
      .catch((error) => {
        this.setState({ showEmptyState: true });
        throw error;
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  getNumberValueAttr() {
    return { humanize: "auto", precision: "2", "trim-fraction-zeros": "true" };
  }

  getPctValueAttr() {
    return { humanize: "auto", precision: "1", type: "percent", "trim-fraction-zeros": "true" };
  }

  getTrendText(value, daysRange) {
    return formatTrendText`${value} than previous ${daysRange} days`;
  }

  getItemsForLegend() {
    return this.chartMetaData.map((meta) => {
      return {
        id: meta.id,
        displayName: meta.displayName,
        color: meta.color,
        isSelected: this.state.legendSelection[meta.id]
      };
    });
  }

  setChartSeriesState(dataRows) {
    let { chartSeriesArr } = { ...this.state };

    if (!dataRows) {
      this.setState({ showEmptyState: true });
      return;
    }

    dataRows = transformDataDateForDemo(dataRows, this.state.selectedDaysRange);

    chartSeriesArr.forEach((series) => {
      series.data = [];
    });

    dataRows.forEach((row) => {
      const date = convertDateForChart(row.date);

      chartSeriesArr.forEach((series) => {
        series.data.push({
          x: date,
          y: row[series.id] || 0
        });
      });
    });

    this.setState({ chartSeriesArr });
  }

  setTotalsState(data) {
    this.setState({ totals: data });
  }

  onTimeRangeChange(value) {
    const { start, end } = value;
    let dateRange = {
      startDateStr: start,
      endDateStr: end
    };
    this.setState({ dateRange, selectedDaysRange: getDaysDiff(start, end) });
    this.loadData(dateRange);
  }

  onLegendSelectionChange(selectionId) {
    let { legendSelection } = { ...this.state };

    legendSelection[selectionId] = !legendSelection[selectionId];

    this.setState({ legendSelection });
  }

  renderTotals() {
    const { selectedDaysRange, totals, loading } = { ...this.state };

    if (loading) {
      return null;
    }

    return (
      <div className="e-grid">
        {this.chartMetaData.map((metaData) => {
          const prevDiffPct = totals[metaData.id]?.prevDiffPct || 0;
          const value = totals[metaData.id]?.value || 0;
          return (
            <div key={metaData.id} className={`e-cell e-cell-${12 / this.chartMetaData.length}`}>
              <WidgetTotalsItem
                title={metaData.displayName}
                titleTooltip={metaData.totalsTooltip}
                value={value}
                valueNumericAttr={this.getNumberValueAttr()}
                valueColor={metaData.color}
                trendValue={prevDiffPct}
                trendText={this.getTrendText(prevDiffPct, selectedDaysRange)}
              />
            </div>
          );
        })}
      </div>
    );
  }

  renderChart() {
    const { chartSeriesArr, legendSelection } = { ...this.state };

    return (
      <ec-chart height={CHART_HEIGHT} domain-type="time">
        {chartSeriesArr.map((series) => {
          let attr = {};

          if (!legendSelection[series.id]) {
            attr["visible"] = false;
            attr["hidden-in-tooltip"] = true;
          }

          return (
            <ec-series-line
              key={series.id}
              name={series.name}
              data={JSON.stringify(series.data)}
              color={series.color}
              format-y=",.0f"
              {...attr}
            ></ec-series-line>
          );
        })}
      </ec-chart>
    );
  }

  renderContent() {
    const { showEmptyState } = { ...this.state };

    if (showEmptyState) {
      return <DashboardEmptyState />;
    }

    return (
      <Fragment>
        <div>{this.renderTotals()}</div>
        <div className="e-margin-top-m">{this.renderChart()}</div>
        <div className="e-margin-top-m">
          <LegendWithSelection
            items={this.getItemsForLegend()}
            visible={true}
            onChange={this.onLegendSelectionChange.bind(this)}
          />
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <Widget
        title={getMessage("vouchersAnalyticsWidget.title")}
        className="vouchers-analytics-widget"
        headerActions={
          <WidgetDateRange
            onChange={this.onTimeRangeChange.bind(this)}
            isDemoMode={false}
            dateRange={{ start: this.state.dateRange.startDateStr, end: this.state.dateRange.endDateStr }}
          />
        }
        footerActions={<WidgetHelpLink linkUrl={VOUCHERS_ANALYTICS_HELP_LINK} />}
        content={this.renderContent()}
        loading={this.state.loading}
        isEmptyState={this.state.showEmptyState}
      />
    );
  }
}

VouchersAnalyticsWidget.propTypes = {
  plan: PropTypes.object,
  initialStartDate: PropTypes.string,
  initialEndDate: PropTypes.string
};

export default VouchersAnalyticsWidget;
