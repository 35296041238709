import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import PopupContainer from "../../components/PopupContainer";
import InputField from "../../components/InputField";
import SelectField from "../../components/SelectFieldV2";
import LoaderOverlay from "../../components/LoaderOverlay";
import Alert from "../../components/Alert";

import memberProfileApi from "../../api/memberProfileApi";
import planApi from "../../api/planApi";
import planService from "../../services/planService";

import { ALERT_STATUS } from "../../constants/alertContants";
import { getMessage } from "../../messages";

const CreateContactPopup = ({ show, hasMultiPlan, onSave, onPopupClose }) => {
  const [planId, setPlanId] = useState("");
  const [planOptions, setPlanOptions] = useState([]);
  const [externalId, setExternalId] = useState("");
  const [alertError, setAlertError] = useState("");
  const [externalIdError, setExternalIdError] = useState("");
  const [loading, setLoading] = useState(false);

  let unmounted = useRef(false);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    if (show) {
      if (planOptions.length === 0) {
        setLoading(true);
        loadPlans().finally(() => setLoading(false));
      }

      resetState();
    }
  }, [show]);

  function resetState() {
    setAlertError("");
    setPlanId(planOptions[0]?.value);
    setExternalId("");
    setExternalIdError("");
  }

  async function loadPlans() {
    const plans = await planApi.getAllPlans();
    if (unmounted.current) {
      return;
    }
    setPlanId(plans[0].id);
    setPlanOptions(planService.getPlanOptions(plans));
  }

  async function onCreateContact() {
    if (!externalId) {
      setExternalIdError(getMessage("error.externalIdError.required"));
      return;
    }

    setLoading(true);

    try {
      const contact = await memberProfileApi.searchMemberProfilesOverview({
        searchValue: externalId
      });

      if (contact) {
        setExternalIdError(getMessage("error.externalIdError.exists"));
        setLoading(false);
        return;
      }

      const { contactId } = await memberProfileApi.createMember({ planId, externalId });
      onSave({ contactId, externalId });
      onClose();
    } catch (error) {
      setAlertError(getMessage("error.failToCreateMember"));
      console.error(error);
    }

    setLoading(false);
  }

  function onClose() {
    onPopupClose();
  }

  return (
    <PopupContainer
      id="createContact"
      title={getMessage("createContactPopup.title")}
      width={"672px"}
      show={show}
      onClose={onClose}
    >
      {alertError && <Alert message={alertError} status={ALERT_STATUS.ERROR} />}

      <e-notification type="default">
        <e-notification-content>{getMessage("createContactPopup.notification")}</e-notification-content>
      </e-notification>

      <div className="e-margin-top-l">
        {hasMultiPlan && (
          <SelectField
            label={getMessage("createContactPopup.multiPlanSelect.label")}
            name="planId"
            options={planOptions}
            value={planId}
            onChange={(event) => setPlanId(event.target.value)}
            tooltip={getMessage("createContactPopup.multiPlanSelect.tooltip")}
            // disabled={contactToAdd || contactList.length > 0}
          />
        )}

        <InputField
          label={getMessage("createContactPopup.externalId.label")}
          value={externalId}
          name={"externalId"}
          errorMsg={externalIdError}
          tooltip={getMessage("createContactPopup.externalId.tooltip")}
          onChange={(event) => {
            setExternalId(event.target.value);
            setExternalIdError("");
          }}
        />
      </div>

      <hr className="e-separator e-separator-fullwidth e-margin-top-xl"></hr>

      <div className="e-buttongroup">
        <button className="e-btn" onClick={onPopupClose}>
          {getMessage("cancelBtn.label")}
        </button>
        <button className="e-btn e-btn-primary" onClick={onCreateContact}>
          {getMessage("createBtn.label")}
        </button>
      </div>

      <LoaderOverlay show={loading} />
    </PopupContainer>
  );
};

CreateContactPopup.propTypes = {
  show: PropTypes.bool,
  hasMultiPlan: PropTypes.bool,
  onPopupClose: PropTypes.func,
  onSave: PropTypes.func
};

export default CreateContactPopup;
