import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import sanitizeHtml from "sanitize-html";

import exclusiveAccessService from "../../../services/exclusiveAccessService";

import { LINK_BUTTON_ENUM } from "../../../constants/rewardConstants";

import templateCss from "!!raw-loader!./ExclusiveAccessTemplateHtmlContent.css";

const getButtonHref = (buttonLink) => {
  return buttonLink.redirectOption === LINK_BUTTON_ENUM.RETURN
    ? "javascript:void(0)"
    : getUrlWithParameters(buttonLink.redirectUrl, buttonLink.lsOpenWallet);
};

const getUrlWithParameters = (buttonHref, lsOpenWallet) => {
  if (buttonHref.includes("?")) {
    const withParameter = "&lsOpenWallet=".concat(lsOpenWallet);
    buttonHref = buttonHref.concat(withParameter);
    return buttonHref;
  }
  const withParameter = "/?lsOpenWallet=".concat(lsOpenWallet);
  buttonHref = buttonHref.concat(withParameter);
  return buttonHref;
};

const getMainClassName = (isMobile, highlightAll) => {
  return classNames("ea-main", {
    mobile: isMobile,
    highlight: highlightAll
  });
};

const getAttr = (isMobile, links) => {
  let attr = {
    tagAttr: {},
    firstHeader: {},
    secondHeader: {},
    middleText: {},
    actionTopText: {},
    leftButton: {},
    rightButton: {},
    actionBottomText: {}
  };
  if (!isMobile) {
    attr["tagAttr"] = { ["e-editable"]: "tag" };
    attr["firstHeader"] = { ["e-editable"]: "firstHeader" };
    attr["secondHeader"] = { ["e-editable"]: "secondHeader" };
    attr["middleText"] = { ["e-editable"]: "middleText" };
    attr["actionTopText"] = { ["e-editable"]: "actionTopText" };
    attr["leftButton"] = { ["e-editable"]: "leftButton" };
    attr["rightButton"] = { ["e-editable"]: "rightButton" };
    attr["actionBottomText"] = { ["e-editable"]: "actionBottomText" };
  }

  if (links && links.leftButtonLink.redirectOption === LINK_BUTTON_ENUM.RETURN) {
    attr["leftButton"]["data-goback"] = true;
  }

  if (links && links.rightButtonLink.redirectOption === LINK_BUTTON_ENUM.RETURN) {
    attr["rightButton"]["data-goback"] = true;
  }

  return attr;
};

const sanitize = (dirty) => {
  return sanitizeHtml(dirty, { allowedAttributes: { "*": ["style"], a: ["href", "title"] } });
};

const GetScriptContent = () => {
  return `
      var trapFocus = function() {
        var dummy = document.getElementById("dummy_input")
        document.body.onkeydown = function(e) {
          if (e.which == 9) {
            dummy.focus()
            e.preventDefault();
          }
        };
      }
     
      var goBack = function() {
        let parentDomain = document.referrer.substring(0, (document.referrer+"/").indexOf("/", 8));
        window.parent.postMessage(
          {
            action: "goback"
          },
          parentDomain
        );
      };

      trapFocus();

      var aList = document.getElementsByTagName("a")
        for (i = 0; i < aList.length; i++) {
          aList[i].setAttribute("target", "_parent")
        }

      var goBackButtons = document.querySelectorAll("[data-goback]");
      for (var i = 0, len = goBackButtons.length; i < len; i++) {
        goBackButtons[i].addEventListener("click", goBack, false);
      }`;
};

const TemplateHtmlContent = ({
  isMobile,
  pickerStyle,
  contentTexts,
  contentLinks,
  highlightEditable,
  highlightPickerName
}) => {
  const getElementClass = (className, pickerNames) => {
    return classNames(className, {
      highlight: pickerNames.includes(highlightPickerName)
    });
  };

  const ContentBody = ({ isMobile, pickerStyle, texts, links, highlightEditable, highlightPickerName }) => {
    const {
      overlayColor,
      overlayOpacity,
      contentBackgroundColor,
      tagBackgourdColor,
      tagRadius,
      leftButtonBackgroundColor,
      rightButtonBackgroundColor,
      buttonRadius
    } = pickerStyle;

    const rgb = exclusiveAccessService.hexToRgb(overlayColor);
    const rgba = [...rgb, overlayOpacity / 100];

    let rightButtonHref = links ? getButtonHref(links.rightButtonLink) : "";
    let leftButtonHref = links ? getButtonHref(links.leftButtonLink) : "";

    const mainClassName = getMainClassName(isMobile, highlightEditable && !isMobile);

    const attr = getAttr(isMobile, links);

    return (
      <div
        className={mainClassName}
        style={{
          backgroundColor: `rgba(${rgba[0]},${rgba[1]},${rgba[2]},${rgba[3]})`
        }}
      >
        <div
          className={getElementClass("popup-box", ["contentBackgroundColor"])}
          style={{
            backgroundColor: contentBackgroundColor
          }}
        >
          <div className="popup-box-content">
            <span
              className={getElementClass("ea-tag", ["tagColor", "tagRadius"])}
              {...attr.tagAttr}
              style={{
                backgroundColor: tagBackgourdColor,
                color: "#ffffff",
                borderRadius: `${tagRadius}px`
              }}
              dangerouslySetInnerHTML={{
                __html: sanitize(texts.tag)
              }}
            />

            <div
              className="first-header"
              {...attr.firstHeader}
              dangerouslySetInnerHTML={{
                __html: sanitize(texts.firstHeader)
              }}
            />
            <div
              className="second-header"
              {...attr.secondHeader}
              dangerouslySetInnerHTML={{
                __html: sanitize(texts.secondHeader)
              }}
            />

            <div
              className="middle-text"
              {...attr.middleText}
              dangerouslySetInnerHTML={{
                __html: sanitize(texts.middleText)
              }}
            />

            <div
              className="action-top-text"
              {...attr.actionTopText}
              dangerouslySetInnerHTML={{
                __html: sanitize(texts.actionTopText)
              }}
            />

            <div className="button-container">
              <a
                {...attr.leftButton}
                href={leftButtonHref}
                tabIndex="-1"
                target="_parent"
                className={getElementClass("left-button ea-button", [
                  "leftButtonLink",
                  "leftButtonBackgroundColor",
                  "buttonRadius"
                ])}
                style={{
                  backgroundColor: leftButtonBackgroundColor,
                  borderRadius: `${buttonRadius}px`
                }}
                dangerouslySetInnerHTML={{
                  __html: sanitize(texts.leftButton)
                }}
              />
              <a
                {...attr.rightButton}
                href={rightButtonHref}
                tabIndex="-1"
                className={getElementClass("right-button ea-button", [
                  "rightButtonLink",
                  "rightButtonBackgroundColor",
                  "buttonRadius"
                ])}
                target="_parent"
                style={{
                  backgroundColor: rightButtonBackgroundColor,
                  borderRadius: `${buttonRadius}px`
                }}
                dangerouslySetInnerHTML={{
                  __html: sanitize(texts.rightButton)
                }}
              />
            </div>
            <div
              className="action-bottom-text"
              {...attr.actionBottomText}
              dangerouslySetInnerHTML={{
                __html: sanitize(texts.actionBottomText)
              }}
            />
            <input type="text" id="dummy_input" style={{ position: "absolute", left: "-2000px" }} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <html>
      <head>
        {isMobile ? <meta name="viewport" content="width=device-width, initial-scale=1.0" /> : null}
        <style>{templateCss}</style>
      </head>
      <body>
        <ContentBody
          isMobile={isMobile}
          pickerStyle={pickerStyle}
          texts={contentTexts}
          links={contentLinks}
          highlightEditable={highlightEditable}
          highlightPickerName={highlightPickerName}
        />
        <script>{GetScriptContent()}</script>
      </body>
    </html>
  );
};

TemplateHtmlContent.propTypes = {
  contentTexts: PropTypes.object,
  contentLinks: PropTypes.object,
  isMobile: PropTypes.bool,
  highlightEditable: PropTypes.bool,
  pickerStyle: PropTypes.shape({
    overlayColor: PropTypes.string.isRequired,
    overlayOpacity: PropTypes.number.isRequired,
    contentBackgroundColor: PropTypes.string.isRequired,
    tagBackgourdColor: PropTypes.string.isRequired,
    tagRadius: PropTypes.number.isRequired,
    leftButtonBackgroundColor: PropTypes.string.isRequired,
    rightButtonBackgroundColor: PropTypes.string.isRequired,
    buttonRadius: PropTypes.number.isRequired
  })
};

export default TemplateHtmlContent;
