import React, { Component } from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import App from "./App";

import { Provider } from "react-redux";
import { configureStore } from "./storeConfig";
import { isDemoMode } from "./helpers/envHelper";
import { getAdminLanguage } from "./helpers/envHelper";
import { unregister } from "./registerServiceWorker";

class Bootloader extends Component {
  constructor() {
    super();
    window.lsversion = `${process.env.REACT_APP_VERSION}`;
    window.lsstatus = {
      demoMode: isDemoMode()
    };

    unregister();
  }

  render() {
    const store = configureStore();
    return (
      <IntlProvider locale={getAdminLanguage()}>
        <Provider store={store}>
          <App />
        </Provider>
      </IntlProvider>
    );
  }
}

ReactDOM.render(<Bootloader />, document.getElementById("root"));
