import { updateObjectByNameAndValue, getObjectByNamePath } from "./objectHelper";
import { getInputNameAndValue } from "./formHelper";
import { LANGUAGE_MAP } from "../constants/languageConstants";

/**
 * Update given state object with translations
 * @stateObj - the state object to be updated - tier/reward/action
 * @translations - an object holds translations for each property, example: {"nameML": {en:"", de: ""}}
 * @propsMap - a map of property name to the equivalent property that holds the multiLang values - on stateObj, example: {"nameML": "name"}
 * @primaryLanguage - the primary language, in order to update the default property
 */
export const multiLangUpdateState = ({ stateObj, translations, propsMap, primaryLanguage }) => {
  for (let [key, value] of Object.entries(translations)) {
    updateObjectByNameAndValue(stateObj, key, value);
    if (propsMap[key]) {
      updateObjectByNameAndValue(stateObj, propsMap[key], value[primaryLanguage]);
    }
  }
};

export const initTranslationsObj = ({ stateObj, propsMap, primaryLanguage }) => {
  let translations = {};
  for (let [multiLangProp, defaultProp] of Object.entries(propsMap)) {
    const multiLangValue = getObjectByNamePath(stateObj, multiLangProp);
    const defaultValue = getObjectByNamePath(stateObj, defaultProp);
    translations[multiLangProp] = { ...(multiLangValue || {}), [primaryLanguage]: defaultValue };
  }

  return translations;
};

export const setTranslationsOnInputChange = ({ translations, event, lang, customValue }) => {
  let { name, value } = getInputNameAndValue(event);
  if (customValue !== undefined) {
    value = customValue;
  }

  translations[name][lang] = value;
};

export const getMultiLangValue = ({ value, valueML, lang }) => {
  if (!lang) {
    return value;
  }

  return (!!valueML && valueML[lang]) || value;
};

export const getLanguageTitle = (lang) => {
  lang = lang || "en";
  return LANGUAGE_MAP[lang] || LANGUAGE_MAP["en"];
};

export const getMultiLangFieldLabel = ({ label, lang }) => {
  return `${label} (${getLanguageTitle(lang)})`;
};

export const getLanguageOptions = ({ languages }) => {
  return languages.map((lang) => {
    return { value: lang, name: LANGUAGE_MAP[lang] };
  });
};
