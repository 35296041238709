import React, { Fragment } from "react";

import TopMenuBar from "./TopMenuBar";
import Navigation from "../components/Navigation";
import AlertContainer from "../components/AlertContainer";

import { MAIN_TABS } from "../constants/navigationConstants";

export const EmptyLayout = (props) => {
  return <Fragment>{props.children}</Fragment>;
};

export const EmptyLayoutWithMenu = (props) => {
  return (
    <Fragment>
      <div className="e-layout e-layout-without_navigation">{props.children}</div>
    </Fragment>
  );
};

export const ContainerNavigationLayout = (props) => {
  return (
    <Fragment>
      <div className="e-layout e-layout-without_navigation">
        <Navigation title={props.title} tabsConfig={props.tabs || MAIN_TABS} children={props.children} />
      </div>
    </Fragment>
  );
};
