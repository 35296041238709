import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import map from "lodash/map";
import { Switch, Route, Redirect } from "react-router-dom";

import { hideErrorAlert } from "../actions/alertActions";

import Dashboard from "./Dashboard";
import Tiers from "./Tiers";
import Benefits from "./Benefits";
import Distribution from "./Distribution";
import TierSetup from "./TierSetup";
import Actions from "./Actions";
import ActionSetup from "./ActionSetup";
import PlanSettings from "./PlanSettings";
import AccountSettings from "./AccountSettings";
import LanguageAndCurrency from "./LanguageAndCurrency";
import CashbackSettings from "./CashbackSettings";
import MembershipSettings from "./MembershipSettings";
import UpdateAndLaunch from "./UpdateAndLaunch";
import Rewards from "./Rewards";
import RewardSetup from "./RewardSetup";
import OnBoarding from "./OnBoarding";
import MemberProfilesOverview from "./MemberProfilesOverview";
import MemberProfile from "./MemberProfile";
import MemberGroupProfile from "./MemberGroupProfile";
import Test from "./Test";
import ExternalLoyaltlyEvents from "./ExternalLoyaltlyEvents";
import ExclusiveAccessOverview from "./ExclusiveAccessOverview";
import ExclusiveAccessSetup from "./ExclusiveAccessSetup";
import ActionDashboard from "./ActionDashboard";
import SecurityWalletApp from "./SecurityWalletApp";
import SecurityApiKey from "./SecurityApiKey";
import SecurityRdsConfiguration from "./SecurityRdsConfiguration";
import SalesDataConfiguration from "./SalesDataConfiguration";
import ProductCatalogConfiguration from "./ProductCatalogConfiguration";
import TasksStats from "./TasksStats";

import PageNotFound from "./PageNotFound";

import { EmptyLayout, EmptyLayoutWithMenu, ContainerNavigationLayout } from "../Layouts";

import {
  ACTIONS_TABS,
  ACTIONS_TAB_ENUM,
  TIERS_TABS,
  MANAGEMENT_TABS,
  CONFIGURATION_SETTINGS_TABS
} from "../constants/navigationConstants";
import { getMessage } from "../messages";
import RewardDashboard from "./RewardDashboard";

const getActionsTabs = () => {
  return ACTIONS_TABS;
};

const Main = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(hideErrorAlert());
  });

  const { onBoarding } = props;

  let actionsTabs = getActionsTabs();

  const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
    const redirectOnBoarding = onBoarding && rest.path != "/on-boarding" && !rest.notFound;
    const redirectDefault = !onBoarding && rest.path === "/on-boarding";
    const redirectTo = rest.redirectTo;

    return (
      <Route
        {...rest}
        render={(props) =>
          redirectOnBoarding ? (
            <Redirect to="/on-boarding" />
          ) : redirectDefault ? (
            <Redirect to="/tiers" />
          ) : !!redirectTo ? (
            <Redirect to={redirectTo} />
          ) : (
            <Layout tabs={rest.tabs} title={rest.title}>
              <Component {...props} />
            </Layout>
          )
        }
      />
    );
  };

  return (
    <Switch>
      <AppRoute exact path="/" component={Dashboard} layout={EmptyLayoutWithMenu} />
      <AppRoute exact path="/dashboard" component={Dashboard} layout={EmptyLayoutWithMenu} />

      <AppRoute exact path="/tiers" component={Tiers} layout={EmptyLayoutWithMenu} />
      <AppRoute
        path="/tiers/fixed-benefits"
        component={(props) => <Benefits {...props} />}
        layout={EmptyLayoutWithMenu}
      />
      <AppRoute
        path="/tiers/additional-benefits"
        component={(props) => <Benefits isAdditionalBenefits={true} {...props} />}
        layout={EmptyLayoutWithMenu}
      />
      <AppRoute
        path="/tiers/distribution-chart"
        component={Distribution}
        layout={ContainerNavigationLayout}
        tabs={TIERS_TABS}
        title={getMessage("route.tiers.title")}
      />

      <AppRoute path="/tier/:id" component={TierSetup} layout={EmptyLayout} />
      <AppRoute path="/tier-new" component={TierSetup} layout={EmptyLayout} />

      <AppRoute
        path="/actions/:masterAction"
        component={Actions}
        layout={ContainerNavigationLayout}
        tabs={actionsTabs}
        title={getMessage("route.actions.title")}
      />
      <AppRoute
        path="/actions"
        component={Actions}
        layout={ContainerNavigationLayout}
        tabs={actionsTabs}
        title={getMessage("route.actions.title")}
      />
      <AppRoute path="/action/:id" component={ActionSetup} layout={EmptyLayout} />
      <AppRoute path="/action-dashboard/:id" component={ActionDashboard} layout={EmptyLayout} />
      <AppRoute path="/action-new/:masterAction/:dupId?" component={ActionSetup} layout={EmptyLayout} />

      <AppRoute exact path="/plan" redirectTo="plan-settings" />

      <AppRoute
        exact
        path="/account-settings"
        component={AccountSettings}
        layout={ContainerNavigationLayout}
        tabs={MANAGEMENT_TABS}
        title={getMessage("mainTabs.loyaltyManagement.title")}
      />

      <AppRoute
        exact
        path="/language-and-currency"
        component={LanguageAndCurrency}
        layout={ContainerNavigationLayout}
        tabs={MANAGEMENT_TABS}
        title={getMessage("mainTabs.loyaltyManagement.title")}
      />

      <AppRoute
        exact
        path="/plan-settings"
        component={PlanSettings}
        layout={ContainerNavigationLayout}
        tabs={MANAGEMENT_TABS}
        title={getMessage("mainTabs.loyaltyManagement.title")}
      />

      <AppRoute
        exact
        path="/membership-settings"
        component={MembershipSettings}
        layout={ContainerNavigationLayout}
        tabs={MANAGEMENT_TABS}
        title={getMessage("mainTabs.loyaltyManagement.title")}
      />

      <AppRoute
        exact
        path="/cashback-settings"
        component={CashbackSettings}
        layout={ContainerNavigationLayout}
        tabs={MANAGEMENT_TABS}
        title={getMessage("mainTabs.loyaltyManagement.title")}
      />

      <AppRoute
        exact
        path="/plan-launch"
        component={UpdateAndLaunch}
        layout={ContainerNavigationLayout}
        tabs={MANAGEMENT_TABS}
        title={getMessage("mainTabs.loyaltyManagement.title")}
      />

      <AppRoute
        exact
        path="/configuration/loyalty-wallet"
        component={SecurityWalletApp}
        layout={ContainerNavigationLayout}
        tabs={CONFIGURATION_SETTINGS_TABS}
        title={getMessage("mainTabs.security.title")}
      />

      <AppRoute
        exact
        path="/configuration/api-key"
        component={SecurityApiKey}
        layout={ContainerNavigationLayout}
        tabs={CONFIGURATION_SETTINGS_TABS}
        title={getMessage("mainTabs.security.title")}
      />

      <AppRoute
        exact
        path="/configuration/rds-configuration"
        component={SecurityRdsConfiguration}
        layout={ContainerNavigationLayout}
        tabs={CONFIGURATION_SETTINGS_TABS}
        title={getMessage("mainTabs.security.title")}
      />

      <AppRoute
        exact
        path="/configuration/sales-data"
        component={SalesDataConfiguration}
        layout={ContainerNavigationLayout}
        tabs={CONFIGURATION_SETTINGS_TABS}
        title={getMessage("mainTabs.security.title")}
      />

      <AppRoute
        exact
        path="/configuration/product-catalog"
        component={ProductCatalogConfiguration}
        layout={ContainerNavigationLayout}
        tabs={CONFIGURATION_SETTINGS_TABS}
        title={getMessage("mainTabs.security.title")}
      />

      <AppRoute
        exact
        path="/configuration/data-load"
        component={TasksStats}
        layout={ContainerNavigationLayout}
        tabs={CONFIGURATION_SETTINGS_TABS}
        title={getMessage("mainTabs.security.title")}
      />

      <AppRoute path="/vouchers/:masterReward" component={Rewards} layout={EmptyLayoutWithMenu} />

      <AppRoute path="/vouchers" component={Rewards} layout={EmptyLayoutWithMenu} />

      <AppRoute
        path="/exclusive-access/:masterExclusiveAccess"
        component={ExclusiveAccessOverview}
        layout={EmptyLayoutWithMenu}
      />

      <AppRoute path="/exclusive-access" component={ExclusiveAccessOverview} layout={EmptyLayoutWithMenu} />

      <AppRoute path="/reward/:id" component={RewardSetup} layout={EmptyLayout} />
      <AppRoute path="/reward-new/:masterReward/:dupId?" component={RewardSetup} layout={EmptyLayout} />
      <AppRoute path="/reward-dashboard/:id" component={RewardDashboard} layout={EmptyLayout} />

      <AppRoute path="/exclusive-access-edit/:id" component={ExclusiveAccessSetup} layout={EmptyLayout} />
      <AppRoute
        path="/exclusive-access-edit-new/:masterExclusiveAccess/:dupId?"
        component={ExclusiveAccessSetup}
        layout={EmptyLayout}
      />

      <AppRoute path="/on-boarding" component={OnBoarding} layout={EmptyLayout} />

      <AppRoute path="/member-profiles" component={MemberProfilesOverview} layout={EmptyLayoutWithMenu} />
      <AppRoute
        // redirectTo="/configuration/loyalty-wallet"
        path="/support"
        component={(props) => <MemberProfilesOverview supportMode={true} {...props} />}
        layout={EmptyLayout}
      />
      <AppRoute path="/member-profile/:id" component={MemberProfile} layout={EmptyLayout} />
      <AppRoute path="/member-group-profile/:id" component={MemberGroupProfile} layout={EmptyLayout} />
      <AppRoute path="/test" component={Test} layout={EmptyLayout} />
      <AppRoute path="/external-loyalty-events" component={ExternalLoyaltlyEvents} layout={EmptyLayoutWithMenu} />
      <AppRoute component={PageNotFound} layout={EmptyLayout} notFound={true} />
    </Switch>
  );
};

export default Main;
