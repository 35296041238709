import React from "react";
import PropTypes from "prop-types";

import InputField from "../../components/InputField";
import CheckboxField from "../../components/CheckboxField";
import MultiLangPopupOpener from "../../components/MultiLangPopupOpener";

import { getErrorMessage } from "../../validators";
import { benefitType } from "../../types";
import { getMessage } from "../../messages";

const BenefitSetupCustomFields = ({
  benefit,
  tierList,
  onInputChange,
  onCheckboxChange,
  errors,
  onShowMultiLangPopup,
  hasMultiLang
}) => {
  const tiersError = getErrorMessage(errors, "tiers");
  return (
    <React.Fragment>
      <label className="e-field__label m-n-b-10">Select tiers</label>
      {tierList.map((tier) => {
        return (
          <CheckboxField
            key={`benefit_tier_cb_${tier.id}`}
            name={`tiers.${tier.id}.enabled`}
            inline={true}
            label={tier.name}
            checked={benefit.tiers[tier.id].enabled}
            onChange={onCheckboxChange}
            errorMsg={tiersError}
          />
        );
      })}
      <div class="e-field__message e-field__message-error m-n-t-10 m-b-10">{tiersError}</div>
      <InputField
        label={getMessage("benefitSetupCustomFields.custom.label")}
        name="custom"
        value={benefit.custom}
        onChange={onInputChange}
        tooltip={getMessage("benefitSetupCustomFields.custom.tooltip")}
        errorMsg={getErrorMessage(errors, "custom")}
        postfixButtons={hasMultiLang && <MultiLangPopupOpener onClick={onShowMultiLangPopup} />}
      />
    </React.Fragment>
  );
};

BenefitSetupCustomFields.propTypes = {
  benefit: benefitType,
  tierList: PropTypes.array,
  onInputChange: PropTypes.func,
  onCheckboxChange: PropTypes.func,
  errors: PropTypes.shape({}),
  onShowMultiLangPopup: PropTypes.func,
  hasMultiLang: PropTypes.bool
};

export default BenefitSetupCustomFields;
