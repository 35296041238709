import { getMessage } from "../messages";
import { VALIDITY_TYPE_ENUM, FORM_STEP_ENUM } from "./rewardConstants";

export const MASTER_EXCLUSIVE_ACCESS_ENUM = {
  PERMANENT: "permanent",
  LIMITED: "limited"
};

export const EXCLUSIVE_ACCESS_STATUS_ENUM = {
  INACTIVE: "inactive",
  ACTIVE: "active",
  DEACTIVATED: "deactivated",
  SCHEDULED: "scheduled",
  EXPIRED: "expired",
  READYTOUSE: "readyToUse"
};

export const EXCLUSIVE_ACCESS_STATUS_LABEL = {
  inactive: {
    label: getMessage("status.inactive"),
    labelId: "status.inactive",
    className: "e-label-default background-color-placeholder"
  },
  active: {
    label: getMessage("status.active"),
    labelId: "status.active",
    className: "e-label-primary"
  },
  deactivated: {
    label: getMessage("status.deactivated"),
    labelId: "status.deactivated",
    className: "e-label-danger"
  },
  scheduled: {
    label: getMessage("status.scheduled"),
    labelId: "status.scheduled",
    className: "e-label-warning"
  },
  expired: {
    label: getMessage("status.expired"),
    labelId: "status.expired",
    className: "e-label-danger"
  },
  readyToUse: {
    label: getMessage("status.readyToUse"),
    labelId: "status.readyToUse",
    className: "e-label-success"
  }
};

export const EXCLUSIVE_ACCESS_OFFERS_ENUM = {
  AS_FIXEDBENEFIT: "fixedBenefit",
  AS_ADDITIONALBENEFITS: "additionalBenefits",
  AS_REWARD: "action",
  FOR_POINTS: "redemption",
  FOR_FREE: "redemptionForFree"
};

export const EXCLUSIVE_ACCESS_OFFERS_ELEMENTS_ENUM = {
  MESSAGE: "MESSAGE",
  TIERS: "TIERS",
  VALIDITY: "VALIDITY",
  OFFER_VALIDITY: "OFFER_VALIDITY",
  RESTRICTION: "RESTRICTION"
};
export const EXCLUSIVE_ACCESS_OFFERS_OPTIONS = [
  {
    accordionOpenState: false,
    id: EXCLUSIVE_ACCESS_OFFERS_ENUM.AS_FIXEDBENEFIT,
    title: getMessage("exclusiveAccessOffers.options.fixedBenefit.title"),
    elementname: "accordion-fixed-benfit",
    elementid: "accordion-fixed-benefit",
    elementkey: "accordion-fixed-benenfit",
    elements: [EXCLUSIVE_ACCESS_OFFERS_ELEMENTS_ENUM.VALIDITY]
  },
  {
    accordionOpenState: false,
    id: EXCLUSIVE_ACCESS_OFFERS_ENUM.AS_ADDITIONALBENEFITS,
    title: getMessage("exclusiveAccessOffers.options.additionalBenefits.title"),
    elementname: "accordion-additional-benfits",
    elementid: "accordion-additional-benefits",
    elementkey: "accordion-additional-benenfits",
    elements: [EXCLUSIVE_ACCESS_OFFERS_ELEMENTS_ENUM.VALIDITY]
  },
  {
    accordionOpenState: false,
    id: EXCLUSIVE_ACCESS_OFFERS_ENUM.AS_REWARD,
    title: getMessage("exclusiveAccessOffers.options.action.title"),
    elementname: "accordion-rewards",
    elementid: "accordion-rewards",
    elementkey: "accordion-rewards",
    elements: [EXCLUSIVE_ACCESS_OFFERS_ELEMENTS_ENUM.VALIDITY]
  },
  {
    accordionOpenState: false,
    id: EXCLUSIVE_ACCESS_OFFERS_ENUM.FOR_POINTS,
    title: getMessage("exclusiveAccessOffers.options.redemption.title"),
    elementname: "accordion-points",
    elementid: "accordion-points",
    elementkey: "accordion-points",
    elements: [
      EXCLUSIVE_ACCESS_OFFERS_ELEMENTS_ENUM.TIERS,
      EXCLUSIVE_ACCESS_OFFERS_ELEMENTS_ENUM.MESSAGE,
      EXCLUSIVE_ACCESS_OFFERS_ELEMENTS_ENUM.VALIDITY,
      EXCLUSIVE_ACCESS_OFFERS_ELEMENTS_ENUM.OFFER_VALIDITY,
      EXCLUSIVE_ACCESS_OFFERS_ELEMENTS_ENUM.RESTRICTION
    ],
    path: EXCLUSIVE_ACCESS_OFFERS_ENUM.FOR_POINTS
  },
  {
    accordionOpenState: false,
    id: EXCLUSIVE_ACCESS_OFFERS_ENUM.FOR_FREE,
    title: getMessage("exclusiveAccessOffers.options.redemptionForFree.title"),
    elementname: "accordion-free",
    elementid: "accordion-free",
    elementkey: "accordion-free",
    elements: [
      EXCLUSIVE_ACCESS_OFFERS_ELEMENTS_ENUM.TIERS,
      EXCLUSIVE_ACCESS_OFFERS_ELEMENTS_ENUM.MESSAGE,
      EXCLUSIVE_ACCESS_OFFERS_ELEMENTS_ENUM.VALIDITY,
      EXCLUSIVE_ACCESS_OFFERS_ELEMENTS_ENUM.OFFER_VALIDITY,
      EXCLUSIVE_ACCESS_OFFERS_ELEMENTS_ENUM.RESTRICTION
    ],
    path: EXCLUSIVE_ACCESS_OFFERS_ENUM.FOR_FREE
  }
];

export const ACCESS_VALIDITY_TYPE_OPTIONS = [
  {
    name: getMessage("accessValidityType.options.unlimited.name"),
    value: VALIDITY_TYPE_ENUM.UNLIMITED
  },
  {
    name: getMessage("accessValidityType.options.limitedFromEarn.name"),
    value: VALIDITY_TYPE_ENUM.LIMITED_FROM_EARN
  },
  {
    name: getMessage("accessValidityType.options.fixedDate.name"),
    value: VALIDITY_TYPE_ENUM.DATE_RANGE
  }
];

export const CREATE_OPTIONS_ENUM = {
  CREATE: "true",
  MODIFY: "false"
};

export const CREATE_OR_MODIFY_OPTIONS = [
  {
    name: getMessage("createOfModify.options.create.name"),
    value: CREATE_OPTIONS_ENUM.CREATE,
    tooltip: getMessage("createOfModify.options.create.tooltip")
  },
  {
    name: getMessage("createOfModify.options.modify.name"),
    value: CREATE_OPTIONS_ENUM.MODIFY,
    tooltip: getMessage("createOfModify.options.modify.tooltip")
  }
];

export const EXCLUSIVE_ACCESS_ACTIVATION_OPTIONS = [
  {
    name: "Activate exclusive page",
    value: true
  },
  {
    name: "Deactivate exclusive page",
    value: false
  }
];

export const EXCLUSIVE_ACCESS_FORM_STEPS = [
  {
    id: FORM_STEP_ENUM.EXCLUSIVE_ACCESS_POOL_SETTINGS,
    title: getMessage("exclusiveAccessFormSteps.exclusiveAccessPoolSettings.title"),
    disabled: false
  },
  {
    id: FORM_STEP_ENUM.EXCLUSIVE_ACCESS_EDITOR,
    title: getMessage("exclusiveAccessFormSteps.exclusiveAccessBlockingEditor.title"),
    disabled: false
  },
  {
    id: FORM_STEP_ENUM.REDEMPTION_VALUE,
    title: getMessage("exclusiveAccessFormSteps.redemptionValue.title"),
    disabled: false
  },
  {
    id: FORM_STEP_ENUM.EXCLUSIVE_ACCESS_VALIDITY,
    title: getMessage("exclusiveAccessFormSteps.EXCLUSIVE_ACCESS_VALIDITY.title"),
    disabled: false
  }
];

export const EXCLUSIVE_ACCESS_NO_REDEMPTION_FORM_STEPS = [
  {
    id: FORM_STEP_ENUM.EXCLUSIVE_ACCESS_POOL_SETTINGS,
    title: getMessage("exclusiveAccessFormSteps.exclusiveAccessPoolSettings.title"),
    disabled: false
  },
  {
    id: FORM_STEP_ENUM.EXCLUSIVE_ACCESS_EDITOR,
    title: getMessage("exclusiveAccessFormSteps.exclusiveAccessBlockingEditor.title"),
    disabled: false
  },
  {
    id: FORM_STEP_ENUM.EXCLUSIVE_ACCESS_VALIDITY,
    title: getMessage("exclusiveAccessFormSteps.EXCLUSIVE_ACCESS_VALIDITY.title"),
    disabled: false
  }
];
