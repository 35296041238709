import { combineReducers } from "redux";

import customer, * as fromCustomer from "./customerReducer";
import plan, * as fromPlan from "./planReducer";
import tier, * as fromTier from "./tierReducer";
import action, * as fromAction from "./actionReducer";
import reward, * as fromReward from "./rewardReducer";
import exclusiveAccess, * as fromExclusiveAccess from "./exclusiveAccessReducer";
import session, * as fromSession from "./sessionReducer";
import benefit, * as fromBenefit from "./benefitReducer";
import externalEvents, * as fromExternalEvents from "./externalEventReducer";
import currentPlanVersion, * as fromCurrentPlanVersion from "./currentPlanVersionReducer";
import translation, * as fromTranslations from "./translationReducer";
import alert from "./alertReducer";
import tierService from "../services/tierService";
import icon, * as fromIcon from "./iconsReducer";

const reducer = combineReducers({
  customer,
  plan,
  tier,
  action,
  reward,
  exclusiveAccess,
  session,
  benefit,
  externalEvents,
  currentPlanVersion,
  alert,
  translation,
  icon
});

export const isCustomerLoaded = (state) => {
  return fromCustomer.isCustomerLoaded(state.customer);
};

export const isEmsConfigLoaded = (state) => {
  return fromCustomer.isEmsConfigLoaded(state.customer);
};

export const isPlanLoaded = (state) => {
  return fromPlan.isDataLoaded(state.plan);
};

export const isAppDataLoaded = (state) =>
  fromPlan.isDataLoaded(state.plan) &&
  fromTier.isDataLoaded(state.tier) &&
  fromAction.isDataLoaded(state.action) &&
  fromReward.isDataLoaded(state.reward) &&
  fromExclusiveAccess.isDataLoaded(state.exclusiveAccess) &&
  fromBenefit.isDataLoaded(state.benefit) &&
  fromExternalEvents.isDataLoaded(state.externalEvents) &&
  fromCurrentPlanVersion.isDataLoaded(state.currentPlanVersion) &&
  fromTranslations.isDataLoaded(state.translation);

export const isLoading = (state) =>
  fromPlan.isLoading(state.plan) ||
  fromTier.isLoading(state.tier) ||
  fromSession.isLoading(state.session) ||
  fromCustomer.isLoading(state.customer) ||
  fromAction.isLoading(state.action) ||
  fromReward.isLoading(state.reward) ||
  fromExclusiveAccess.isLoading(state.exclusiveAccess);

//#region Session Selectors
export const selectJwtToken = (state) => fromSession.GetJwtToken(state.session);

export const isSessionDataLoaded = (state) => fromTier.isDataLoaded(state.session);
//#endregion

//#region Customer Selectors
export const selectCustomerId = (state) => fromCustomer.selectCustomerId(state.customer);
export const selectCustomerPlans = (state) => fromCustomer.selectCustomerPlans(state.customer);
export const selectCustomerHasMultiPlan = (state) => {
  const plans = fromCustomer.selectCustomerPlans(state.customer);
  return plans.length > 1;
};

export const selectCustomerAllowPlanEdit = (state) => {
  const isCurrentPlanVersionExists = fromCurrentPlanVersion.selectIsCurrentPlanVersionExists(state.currentPlanVersion);

  if (!isCurrentPlanVersionExists) {
    return false;
  }

  return true;
};

export const selectCustomerFeaturesConfigByName = (state, name) => {
  const config = fromCustomer.selectCustomerFeaturesConfig(state.customer);
  return config[name] || false;
};
export const selectCustomerDashboardUrl = (state) => fromCustomer.selectDashboardUrl(state.customer);

export const isAccountViewOnly = (state) => fromCustomer.isAccountViewOnly(state.customer);
export const isAccountViewOnlySupport = (state) => fromCustomer.isAccountViewOnlySupport(state.customer);
export const selectCustomerIsDoubleHashSet = (state) => fromCustomer.selectIsDoubleHashSet(state.customer);

export const selectEmsAdminId = (state) => fromCustomer.selectEmsAdminId(state.customer);
export const selectEmsAdminTimezone = (state) => fromCustomer.selectEmsAdminTimezone(state.customer);

//#endregion

//#region Tier Selectors
export const selectTiers = (state) => {
  let tiers = [];
  if (selectShowCurrentPlan(state)) {
    tiers = selectCurrentPlanVersion(state).tiers;
  } else {
    tiers = fromTier.selectTiers(state.tier);
  }
  return tierService.sortTiers(tiers);
};

export const selectHasInvalidTier = (state) => fromTier.selectHasInvalidTier(state.tier);

export const selectTierNames = (state) => fromTier.selectTierNames(state.tier);

export const selectTiersMapById = (state) => fromTier.selectTiersMapById(state.tier);

export const selectTierById = (state, id) => fromTier.selectTierById(state.tier, id);

export const isTierPostSuccess = (state) => fromTier.isPostSuccess(state.tier);

export const selectTierEditState = (state) => fromTier.selectTierEditState(state.tier);

export const selectNewTierId = (state) => fromTier.selectNewTierId(state.tier);

export const selectHasInviteTier = (state) => fromTier.selectHasInvalidTier(state.tier);

//#endregion

//#region Action Selectors
export const selectActions = (state) => fromAction.selectActions(state.action);

export const selectActionNames = (state) => fromAction.selectActionNames(state.action);
export const selectActionInternalNames = (state) => fromAction.selectActionInternalNames(state.action);

export const selectActionsById = (state) => fromAction.selectActionsById(state.action);

export const selectDefaultJoinAction = (state) => fromAction.selectDefaultJoin(state.action);
export const selectDefaultPurchaseAction = (state) => fromAction.selectDefaultPurchase(state.action);

export const selectActionById = (state, id) => fromAction.selectActionById(state.action, id);

export const isActionPostSuccess = (state) => fromAction.isPostSuccess(state.action);

export const selectDefaultAction = (state, masterAction) => fromAction.selectDefaultAction(state.action, masterAction);

export const selectActionEditState = (state) => fromAction.selectActionEditState(state.action);

export const selectNewActionId = (state) => fromAction.selectNewActionId(state.action);

export const selectIsActionLoading = (state) => fromAction.isLoading(state.action);

//Get the action reward tiers object, sorted by tiers from state
export const selectActionRewardTiers = (state, actionId) => {
  const tiers = selectTiers(state);
  const rewardTiers = fromAction.selectRewardTiers(state.action, actionId);

  // create an result object to hold the sorted tiers
  let result = {};
  tiers.forEach((tier) => {
    if (rewardTiers[tier.id]) {
      result[tier.id] = rewardTiers[tier.id];
    }
  });

  // add special tiers enums (all, non) after adding the sorted regular tiers
  Object.keys(rewardTiers).forEach((tierId) => {
    if (!result[tierId]) {
      result[tierId] = rewardTiers[tierId];
    }
  });

  return result;
};
//#endregion

//#region Reward Selectors
export const selectRewards = (state) => fromReward.selectRewards(state.reward);

export const selectRewardNames = (state) => fromReward.selectRewardNames(state.reward);

export const selectRewardsById = (state) => fromReward.selectRewardsById(state.reward);

export const selectRewardById = (state, id) => fromReward.selectRewardById(state.reward, id);

export const isRewardPostSuccess = (state) => fromReward.isPostSuccess(state.reward);

export const selectRewardEditState = (state) => fromReward.selectRewardEditState(state.reward);

export const selectNewRewardId = (state) => fromReward.selectNewRewardId(state.reward);
//#endregion

//#region Exclusive access Selectors
export const selectExclusives = (state) => fromExclusiveAccess.selectExclusives(state.exclusiveAccess);

export const selectExclusiveAccessNames = (state) =>
  fromExclusiveAccess.selectExclusiveAccessNames(state.exclusiveAccess);

export const selectExclusivesById = (state) => fromExclusiveAccess.selectExclusivesById(state.exclusiveAccess);

export const selectExclusiveAccessById = (state, id) =>
  fromExclusiveAccess.selectExclusiveAccessById(state.exclusiveAccess, id);

export const isExclusivePostSuccess = (state) => fromExclusiveAccess.isPostSuccess(state.exclusiveAccess);

export const selectExclusiveAccessEditState = (state) =>
  fromExclusiveAccess.selectExclusiveAccessEditState(state.exclusiveAccess);

export const selectNewExclusiveAccessId = (state) =>
  fromExclusiveAccess.selectNewExclusiveAccessId(state.exclusiveAccess);
//#endregion

//#region Benefit Selectors
export const selectFixedBenefits = (state) => fromBenefit.selectFixedBenefits(state.benefit);
export const selectAdditionalBenefits = (state) => fromBenefit.selectAdditionalBenefits(state.benefit);
//#endregion

export const selectExternalEvents = (state) => fromExternalEvents.selectExternalEvents(state.externalEvents);

//#region Current plan selectors
export const selectCurrentPlanVersion = (state) =>
  fromCurrentPlanVersion.selectCurrentPlanVersion(state.currentPlanVersion);
export const selectCurrentVersionSettings = (state) =>
  fromCurrentPlanVersion.selectCurrentVersionSettings(state.currentPlanVersion);

export const selectCurrentVersionTierById = (state, id) =>
  fromCurrentPlanVersion.selectCurrentVersionTierById(state.currentPlanVersion, id);

export const selectIsCurrentPlanVersionExists = (state) =>
  fromCurrentPlanVersion.selectIsCurrentPlanVersionExists(state.currentPlanVersion);

export const selectTierExistsInCurrentVersion = (state) => (id) =>
  fromCurrentPlanVersion.selectTierExistsInCurrentVersion(state.currentPlanVersion)(id);

export const selectIsPlanLaunchInProgress = (state) =>
  fromCurrentPlanVersion.selectIsPlanLaunchInProgress(state.currentPlanVersion);

export const selectCurrentPlanVersionId = (state) =>
  fromCurrentPlanVersion.selectCurrentPlanVersionId(state.currentPlanVersion);

export const selectCurrentPlanTiersMapById = (state) =>
  fromCurrentPlanVersion.selectTiersMapById(state.currentPlanVersion);

export const selectShowCurrentPlan = (state) =>
  fromCurrentPlanVersion.selectShowCurrentVersion(state.currentPlanVersion) && !selectIsBeforeFirstPlanLaunch(state);

//#endregion

//#region Plan Selectors
export const selectPlan = (state) => fromPlan.selectPlan(state.plan);
export const selectPlanSettings = (state) => fromPlan.selectPlanSettings(state.plan);
export const selectPlanName = (state) => fromPlan.selectPlanSettings(state.plan).name;
export const selectPlanType = (state) => fromPlan.selectPlanType(state.plan);
export const selectPlanCurrencyIdentifierSign = (state) => fromPlan.selectPlanCurrencyIdentifierSign(state.plan);
export const selectPlanLaunchedAt = (state) => fromPlan.selectPlanLaunchedAt(state.plan);
export const selectPlanDistribution = (state) => fromPlan.selectPlanDistribution(state.plan);
export const selectIsPlanActivated = (state) => fromPlan.selectIsPlanActivated(state.plan);
export const selectPlanOptInValue = (state) => fromPlan.selectOptInValue(state.plan);
export const selectRewardsAllocationDelay = (state) => fromPlan.selectRewardsAllocationDelay(state.plan);
export const selectPlanContactDistribution = (state) => fromPlan.selectPlanContactDistribution(state.plan);

export const selectPlanPointsNames = (state) =>
  selectShowCurrentPlan(state)
    ? fromCurrentPlanVersion.selectPointsNames(state.currentPlanVersion)
    : fromPlan.selectPlanPointsNames(state.plan);

export const selectIsBeforeFirstPlanLaunch = (state) => fromPlan.isBeforeFirstPlanLaunch(state.plan);

//languages
export const selectPlanLanguageSettings = (state) => fromPlan.selectLanguageSettings(state.plan);
export const selectPlanLanguages = (state) => fromPlan.selectLanguages(state.plan);
export const selectPlanPrimaryLanguage = (state) => fromPlan.selectPrimaryLanguage(state.plan);
export const selectPlanHasMultiLang = (state) => selectPlanLanguages(state).length > 1;

//store urls
export const selectPlanStoreUrlSettings = (state) => fromPlan.selectStoreUrlSettings(state.plan);
export const selectPlanStoreUrlLangMap = (state) => fromPlan.selectStoreUrlLangMap(state.plan);

//currencies
export const selectPlanCurrencySettings = (state) => fromPlan.selectCurrencySettings(state.plan);
export const selectPlanCurrencies = (state) => fromPlan.selectCurrencies(state.plan);
export const selectPlanPrimaryCurrency = (state) => fromPlan.selectPrimaryCurrency(state.plan);
export const selectPlanHasMultiCurrency = (state) => selectPlanCurrencies(state).length > 1;

//markets
export const selectPlanMarketSettings = (state) => fromPlan.selectMarketSettings(state.plan);
export const selectPlanMarkets = (state) => fromPlan.selectMarkets(state.plan);
export const selectPlanPrimaryMarket = (state) => fromPlan.selectPrimaryMarket(state.plan);
export const selectPlanHasMultiMarket = (state) => selectPlanMarkets(state).length > 1;
//#endregion

export const selectIcons = (state) => fromIcon.selectIcons(state.icon);
export const selectCustomerIcons = (state) => fromIcon.selectCustomerIcons(state.icon);
export const selectShowUploadErrorDialog = (state) => fromIcon.selectShowUploadErrorDialog(state.icon);
export const selectErrorFiles = (state) => fromIcon.selectErrors(state.icon);
export const selectEditedIcon = (state) => fromIcon.selectEditedIcon(state.icon);

export default reducer;
