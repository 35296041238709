import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";

import usePagination from "../../hooks/usePagination";
import {
  ACTIVITY_TYPE,
  ACTIVITY_ELEMENT_TYPE,
  EMPTY_CELL_TEXT,
  ACTIVITY_PAGE_SIZE_OPTIONS
} from "../../constants/memberProfileConstants";

import InputSearchField from "../../components/InputSearchField";
import Pagination from "../../components/Pagination";

import {
  renderStatusLabel,
  renderDateTimeText,
  getActivityName,
  getActivityElementType,
  getActivityElementsData,
  getActivityNameTooltip,
  getActivitiesTotalPoints,
  getActivityTierDetails
} from "./activityRenderHelper";
import { formatPointsNameText } from "../../helpers/pointsHelper";
import planService from "../../services/planService";
import { getMessage } from "../../messages";

const GroupProfileActivity = ({ originalActivities, externalIdsMap, pointsNameObj, plan, emsAccountsMap }) => {
  const [searchValue, setSearchValue] = useState("");
  const { currentPage, setCurrentPage, itemsPerPage, setItemsPerPage, setItemList, getItemList, totalItems } =
    usePagination(ACTIVITY_PAGE_SIZE_OPTIONS[0]);

  useEffect(() => {
    setItemList(getActivitiesData());
  }, [originalActivities, searchValue, itemsPerPage, currentPage, pointsNameObj]);

  function onSearchChange(event) {
    const value = event.target.value;
    setSearchValue(value);
    setCurrentPage(1);
  }

  function getActivitiesData() {
    let data = [];
    let activities = [...originalActivities];
    activities.sort((a, b) => new Date(b.activityTime) - new Date(a.activityTime));

    activities.forEach((activity, index) => {
      const { tierName, tierExp } = getActivityTierDetails(activity);
      const item = {
        key: `${activity.activityTime}_${index}`,
        dateFormatUS: moment(activity.activityTime).format("MM-DD-YYYY"),
        dateFormatEU: moment(activity.activityTime).format("DD.MM.YYYY"),
        timestamp: activity.activityTime,
        status: activity.status,
        activityType: activity.activityType,
        name: getActivityName(activity, getPointsNameText),
        nameTooltip: getActivityNameTooltip(activity),
        elements: getGroupActivityElementsData(activity),
        statusPoints: getActivityStatusPoints(activity),
        tier: tierName || EMPTY_CELL_TEXT,
        tierExp,
        adminId: activity.adminId
      };
      if (
        activity.activityType === ACTIVITY_TYPE.GROUP_CALCULATION ||
        activity.activityType === ACTIVITY_TYPE.GROUP_CREATION
      ) {
        const groupState = activity.elements.find((element) => element.type === ACTIVITY_ELEMENT_TYPE.GROUP_STATE);
        const { tierName, tierExpireAt } = { ...groupState.value };
        item.tier = tierName;
        item.tierExp = tierExpireAt;
      }

      data.push(item);
    });

    data = filterActivitiesBySearch(data);

    return data;
  }

  function getActivityStatusPoints(activity) {
    switch (activity.activityType) {
      case ACTIVITY_TYPE.GROUP_CALCULATION:
      case ACTIVITY_TYPE.GROUP_CREATION: {
        const groupState = activity.elements.find((element) => element.type === ACTIVITY_ELEMENT_TYPE.GROUP_STATE);
        return groupState.value.points;
      }
      default: {
        const pointsInfo = getActivitiesTotalPoints(activity, !planService.isSpendPlan(plan))[0];
        return pointsInfo?.totalPoints;
      }
    }
  }

  function getGroupActivityElementsData(activity) {
    let data = [];
    switch (activity.activityType) {
      case ACTIVITY_TYPE.GROUP_CALCULATION:
      case ACTIVITY_TYPE.GROUP_CREATION: {
        const elements = activity.elements.filter((element) => element.type === ACTIVITY_ELEMENT_TYPE.CONTACT_STATE);
        elements.forEach((element) => {
          const { points, tierName, tierExpireAt } = { ...element.value };
          data.push({
            memberId: externalIdsMap[element.contactId] || element.contactId, //Todo: take external id
            type: getActivityElementType(activity, element),
            statusPoints: points,
            tier: tierName,
            tierExp: renderDateTimeText({ timestamp: tierExpireAt, onlyDate: true }) || EMPTY_CELL_TEXT
          });
        });
        break;
      }
      case ACTIVITY_TYPE.TIER_CALCULATION:
        return data;
      default: {
        data = getActivityElementsData(activity, getPointsNameText);
        data.forEach(
          (item) =>
            (item.memberId = activity.initiateContactId
              ? externalIdsMap[activity.initiateContactId] || activity.initiateContactId
              : EMPTY_CELL_TEXT)
        );
      }
    }

    return data;
  }

  function getPointsNameText(text, pointsValue) {
    return formatPointsNameText({ text, pointsValue, namesObj: !!pointsValue ? null : pointsNameObj });
  }

  function filterActivitiesBySearch(activities) {
    if (searchValue) {
      const searchLower = searchValue.toLowerCase();
      return activities.filter((activity) => {
        const hasName = activity.name.toLowerCase().indexOf(searchLower) > -1;
        const hasDate =
          activity.dateFormatEU.indexOf(searchLower) > -1 || activity.dateFormatUS.indexOf(searchLower) > -1;
        const hasStatus = activity.status.toLowerCase().indexOf(searchLower) > -1;

        return hasDate || hasName || hasStatus;
      });
    }

    return activities;
  }

  function renderAccordionItem(activity, key) {
    const elements = activity.elements;
    const hasElements = elements.length > 0;
    return (
      <Fragment key={`accordion-item-${key}`}>
        {renderAccordionItemTitle(activity, key, hasElements)}
        {renderAccordionItemContent(activity.activityType, elements, key)}
      </Fragment>
    );
  }

  function renderAccordionItemTitle(activity, key, hasElements) {
    const id = `activity_accordion_${key}`;

    let inputAttr = {};
    if (!hasElements) {
      inputAttr["disabled"] = true;
    }

    return (
      <Fragment>
        <input type="checkbox" name="accordion-checkbox" id={id} {...inputAttr} />
        <label className="e-accordion__title" htmlFor={id}>
          <span>{renderDateTimeText({ timestamp: activity.timestamp }) || EMPTY_CELL_TEXT}</span>
          <span>
            {activity.name}
            {activity.nameTooltip ? <e-tooltip content={activity.nameTooltip} type="helper" /> : ""}
          </span>
          <span>{emsAccountsMap?.[activity.adminId]?.userName || EMPTY_CELL_TEXT}</span>
          <span>{activity.statusPoints || EMPTY_CELL_TEXT}</span>
          <span>{activity.tier}</span>
          <span>{renderDateTimeText({ timestamp: activity.tierExp, onlyDate: true }) || EMPTY_CELL_TEXT}</span>
          <span>{renderStatusLabel(activity.status)}</span>
        </label>
      </Fragment>
    );
  }

  function renderAccordionItemContent(activityType, elements, key) {
    if (elements.length === 0) {
      return null;
    }

    let thList = [];
    let dataKeys = [];
    switch (activityType) {
      case ACTIVITY_TYPE.GROUP_CREATION:
      case ACTIVITY_TYPE.GROUP_CALCULATION: {
        thList = [
          getMessage("memberProfileOverview.tableHeader.memberID"),
          getMessage("groupProfileActivity.header.activity"),
          getMessage("memberProfileOverview.groupTableHeader.points"),
          getMessage("memberProfileOverview.groupTableHeader.tier"),
          getMessage("groupProfileActivity.header.tierExpiration")
        ];
        dataKeys = ["memberId", "type", "statusPoints", "tier", "tierExp"];
        break;
      }
      case ACTIVITY_TYPE.CUSTOMER_SUPPORT:
      case ACTIVITY_TYPE.ACTION:
      case ACTIVITY_TYPE.POINTS_EXPIRATION:
        thList = [
          getMessage("memberProfileOverview.tableHeader.memberID"),
          getMessage("groupProfileActivity.header.activity"),
          getMessage("groupProfileActivity.header.reward"),
          getMessage("groupProfileActivity.header.value"),
          getMessage("groupProfileActivity.header.expiration")
        ];
        dataKeys = ["memberId", "type", "rewardName", "rewardValue", "expirationDateText"];
        break;
      case ACTIVITY_TYPE.ONLINE_PURCHASE:
        thList = [
          getMessage("memberProfileOverview.tableHeader.memberID"),
          getMessage("groupProfileActivity.header.activity"),
          getMessage("groupProfileActivity.header.orderId"),
          getMessage("groupProfileActivity.header.reward"),
          getMessage("groupProfileActivity.header.value"),
          getMessage("groupProfileActivity.header.expiration")
        ];
        dataKeys = ["memberId", "type", "orderId", "rewardName", "rewardValue", "expirationDateText"];
        break;
    }

    return (
      <div className="e-accordion__content">
        <table className="e-table e-table-bordered" data-e-version="2">
          <thead>
            <tr>
              {thList.map((text, index) => (
                <th key={`th_${index}`}>{text}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {elements.map((element, index) => {
              return (
                <tr key={`activity_${key}_element_${index}`}>
                  {dataKeys.map((key) => {
                    let value = element[key];
                    if (key == "memberId" || key == "orderId") {
                      if (index > 0 && value === elements[index - 1][key]) {
                        value = EMPTY_CELL_TEXT;
                      }
                    }

                    if (key != "statusPoints") {
                      value = value || EMPTY_CELL_TEXT;
                    }

                    return <td key={`td_${key}`}>{value}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  function renderAccordion() {
    return (
      <Fragment>
        <label className="e-accordion__title">
          <span className="font-weight-bold">{getMessage("groupProfileActivity.header.time")}</span>
          <span className="font-weight-bold">{getMessage("groupProfileActivity.header.activity")}</span>
          <span className="font-weight-bold">{getMessage("groupProfileActivity.header.admin")}</span>
          <span className="font-weight-bold">{getMessage("memberProfileOverview.groupTableHeader.points")}</span>
          <span className="font-weight-bold">{getMessage("memberProfileOverview.groupTableHeader.tier")}</span>
          <span className="font-weight-bold">{getMessage("groupProfileActivity.header.tierExpiration")}</span>
          <span className="font-weight-bold">{getMessage("groupProfileActivity.header.status")}</span>
        </label>
        {getItemList().map((activity) => {
          return renderAccordionItem(activity, activity.key);
        })}
      </Fragment>
    );
  }

  return (
    <div className="member-profile-activity">
      <div className="search-header">
        <label className="e-field__label e-field__label-inline">Search</label>
        <e-tooltip placement="right" content={getMessage("groupProfileActivity.search.tooltip")}>
          <InputSearchField
            placeholder={getMessage("searchBtn.label")}
            onChange={onSearchChange}
            noMargin={true}
            value={searchValue}
          />
        </e-tooltip>
      </div>
      <div className="e-accordion">{renderAccordion()}</div>
      <Pagination
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        onPageChange={setCurrentPage}
        onItemsPerPageChange={setItemsPerPage}
        pageSizeMessageId="memberProfile.activity.pagination.pageSize"
        itemsDisplayedMessageId="memberProfile.activity.pagination.recordsDisplayed"
        pageSizeOptions={ACTIVITY_PAGE_SIZE_OPTIONS}
        hideIfLessThenFirstPageSize={true}
      />
    </div>
  );
};

GroupProfileActivity.propTypes = {
  originalActivities: PropTypes.array,
  externalIdsMap: PropTypes.object,
  pointsNameObj: PropTypes.object,
  emsAccountsMap: PropTypes.objectOf(
    PropTypes.shape({
      userName: PropTypes.string
    })
  )
};

export default GroupProfileActivity;
