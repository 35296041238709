import SecurityPage from "../views/Security/SecurityPage";
import securityApi from "../api/securityApi";
import { SECURITY_PAGE_TYPE } from "../constants/securityConstants";

export default function SecurityWalletApp() {
  return (
    <SecurityPage
      pageType={SECURITY_PAGE_TYPE.WALLET_APP}
      loadApi={securityApi.getWalletApps}
      createApi={securityApi.createWalletApp}
      updateApi={securityApi.updateWalletApp}
      deleteApi={securityApi.deleteWalletApp}
    />
  );
}
