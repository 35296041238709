import React from "react";
import { NavLink } from "react-router-dom";
import { getMessage } from "../messages";

const mainMenuItems = [
  {
    name: getMessage("mainTabs.dashboard.title"),
    link: "/dashboard"
  },
  {
    name: getMessage("mainTabs.actions.title"),
    link: "/actions"
  },
  {
    name: getMessage("mainTabs.vouchers.title"),
    link: "/vouchers"
  },
  {
    name: getMessage("mainTabs.exclusiveAccesss.title"),
    link: "/exclusive-access"
  },
  {
    name: getMessage("mainTabs.tiers.title"),
    link: "/tiers"
  },
  {
    name: getMessage("mainTabs.memberProfiles.title"),
    link: "/member-profiles"
  },
  {
    name: getMessage("mainTabs.externalEvents.title"),
    link: "/external-loyalty-events"
  },
  {
    name: getMessage("mainTabs.loyaltyManagement.title"),
    link: "/plan"
  },
  {
    name: getMessage("mainTabs.security.title"),
    link: "/configuration/loyalty-wallet"
  }
];

const MainMenuItem = ({ name, link, subMenuItems }) => {
  return (
    <li className="e-menu__item e-menu__item-active" style={{ padding: "0px", display: "flex" }}>
      <NavLink className="e-menu__name" to={link}>
        {name}
      </NavLink>
    </li>
  );
};

const TopMenuBar = () => {
  return (
    <div className="e-topnav">
      <div className="e-topnav__container">
        <input className="e-topnav__mobilemenu" id="e-topnav__mobilemenu" type="checkbox" />
        <nav className="e-menu">
          <ul className="e-menu__list">
            {mainMenuItems.map((item) => (
              <MainMenuItem key={item.name} name={item.name} link={item.link} subMenuItems={item.subMenuItems} />
            ))}
          </ul>
        </nav>

        <label className="e-hamburger" htmlFor="e-topnav__mobilemenu">
          <span className="e-hamburger__line" />
        </label>
      </div>
    </div>
  );
};

export default TopMenuBar;
