import React from "react";
import PropTypes from "prop-types";

const EmptyContentPlaceholder = (props) => {
  if (!props.show) {
    return null;
  }

  return (
    <div className="empty-content-placeholder">
      <div className="empty-content-placeholder-icon">
        <e-icon icon={props.icon} />
      </div>
      <div className="empty-content-placeholder-heading">{props.heading}</div>
      <div className="empty-content-placeholder-content">{props.content}</div>
    </div>
  );
};

EmptyContentPlaceholder.propTypes = {
  show: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};

export default EmptyContentPlaceholder;
