import React, { Fragment } from "react";
import PropTypes from "prop-types";

import InputField from "../../components/InputField";
import Alert from "../../components/Alert";

import { getMultiLangFieldLabel } from "../../helpers/multiLangHelper";
import planService from "../../services/planService";
import { ALERT_STATUS } from "../../constants/alertContants";
import { getErrorMessage, convertPathStrName } from "../../validators";
import { getMessage } from "../../messages";

const MultiLangPlanSettingsFields = ({ onChange, lang, translations, disabled, plan, index, errors }) => {
  if (!planService.isSpendPlan(plan)) {
    return (
      <Fragment>
        {index === 0 && !!errors && (
          <Alert
            message={getMessage("planSettingsGeneral.multiLangPopup.errorNotification")}
            status={ALERT_STATUS.ERROR}
          />
        )}
        <InputField
          label={getMultiLangFieldLabel({ label: getMessage("accountSettings.input.planName.label"), lang })}
          value={translations["planSettings.nameML"][lang]}
          name={"planSettings.nameML"}
          onChange={(event) => onChange(event, lang)}
          disabled={disabled}
          isRequired={true}
          errorMsg={getErrorMessage(errors, `${convertPathStrName("planSettings.nameML")}.${lang}`)}
        />
        <div className="e-row">
          <div className="e-col-6">
            <InputField
              label={getMultiLangFieldLabel({ label: getMessage("planSettingsGeneral.pointsName"), lang })}
              value={translations["planSettings.pointsNameML"][lang]}
              name={"planSettings.pointsNameML"}
              onChange={(event) => onChange(event, lang)}
              disabled={disabled}
              isRequired={true}
              errorMsg={getErrorMessage(errors, `${convertPathStrName("planSettings.pointsNameML")}.${lang}`)}
            />
          </div>
          <div className="e-col-6">
            <InputField
              label={getMultiLangFieldLabel({ label: getMessage("planSettingsGeneral.singlePointName"), lang })}
              value={translations["planSettings.singlePointNameML"][lang]}
              name={"planSettings.singlePointNameML"}
              onChange={(event) => onChange(event, lang)}
              disabled={disabled}
              isRequired={true}
              errorMsg={getErrorMessage(errors, `${convertPathStrName("planSettings.singlePointNameML")}.${lang}`)}
            />
          </div>
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <InputField
          label={getMultiLangFieldLabel({ label: getMessage("accountSettings.input.planName.label"), lang })}
          value={translations["planSettings.nameML"][lang]}
          name={"planSettings.nameML"}
          onChange={(event) => onChange(event, lang)}
          disabled={disabled}
          isRequired={true}
          errorMsg={getErrorMessage(errors, `${convertPathStrName("planSettings.nameML")}.${lang}`)}
        />
        <InputField
          label={getMultiLangFieldLabel({ label: getMessage("planSettingsGeneral.loyaltyCreditName"), lang })}
          value={translations["planSettings.pointsNameML"][lang]}
          name={"planSettings.pointsNameML"}
          onChange={(event) => onChange(event, lang)}
          disabled={disabled}
          isRequired={true}
          errorMsg={getErrorMessage(errors, `${convertPathStrName("planSettings.pointsNameML")}.${lang}`)}
        />
      </Fragment>
    );
  }
};

MultiLangPlanSettingsFields.propTypes = {
  onChange: PropTypes.func,
  lang: PropTypes.string,
  translations: PropTypes.object,
  disabled: PropTypes.bool,
  planType: PropTypes.string,
  errors: PropTypes.object
};

export default MultiLangPlanSettingsFields;
