import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import { useSelector, useDispatch } from "react-redux";

import RadioButtonList from "../components/RadioButtonList";
import CheckboxField from "../components/CheckboxField";
import TimeValidityInput from "../components/TimeValidityInput";
import Notification from "../components/Notification";

import planValidator from "../validators/planValidator";

import { validateSingleValue } from "../validators";

import { updatePlan } from "../actions/planActions";
import { showErrorAlert, hideErrorAlert } from "../actions/alertActions";

import planService from "../services/planService";

import { selectCurrentPlanVersion, selectPlan, selectShowCurrentPlan, isAccountViewOnly } from "../reducers";

import { updateObjectByNameAndValue } from "../helpers/objectHelper";
import { updateStateOnInputChange } from "../helpers/formHelper";

import { getErrorMessage } from "../validators";
import { getMessage } from "../messages";

import {
  OPT_IN_OPTIONS,
  CONTACT_DISTRIBUTION_OPTIONS,
  CONTACT_DISTRIBUTION_ENUM,
  CONTACT_DISTRIBUTION_CALC_TYPE_OPTIONS,
  CONTACT_DISTRIBUTION_CALC_TYPE_ENUM
} from "../constants/planConstants";
import classNames from "classnames";
import { MEMBERSHIP_SETTINGS_HELP_LINK } from "../constants/linkConstants";

const MembershipSettings = ({ fromOnboarding, registerSubmitFunc, registerValidateFunc }) => {
  const dispatch = useDispatch();
  const [plan, setPlan] = useState(undefined);
  const [errors, setErrors] = useState(undefined);
  const showCurrentVersion = useSelector((state) => selectShowCurrentPlan(state));
  const initialPlan = useSelector((state) =>
    showCurrentVersion ? selectCurrentPlanVersion(state) : selectPlan(state)
  );
  const isViewOnly = useSelector((state) => isAccountViewOnly(state));

  const validator = new planValidator();

  const viewMode = (showCurrentVersion && !fromOnboarding) || isViewOnly;

  useEffect(() => {
    setPlan(cloneDeep(initialPlan));
  }, []);

  useEffect(() => {
    registerSubmitFunc && registerSubmitFunc(onSubmit);
    registerValidateFunc &&
      registerValidateFunc(() => {
        return validateStep(plan);
      });
  });

  function onInputChange(event, customValue) {
    const newPlan = cloneDeep(plan);
    const inputName = updateStateOnInputChange({ stateObj: newPlan, event, customValue });
    setPlan(newPlan);

    const newErrors = validateSingleValue(
      () => validator.validateMembershipSettings(newPlan),
      cloneDeep(errors),
      inputName
    );

    if (!newErrors) {
      dispatch(hideErrorAlert());
    }

    setErrors(newErrors);

    return newPlan;
  }

  function onContactDistributionChange(event) {
    const newPlan = onInputChange(event);
    if (newPlan.planSettings.rules.contactDistribution == CONTACT_DISTRIBUTION_ENUM.FIRST_TIER) {
      newPlan.planSettings.rules.addStatusPointsDistribution = false;
      setPlan(newPlan);

      const newErrors = validateSingleValue(
        () => validator.validateMembershipSettings(newPlan),
        cloneDeep(errors),
        "planSettings.rules.contactDistributionCalcPeriod.duration"
      );

      if (!newErrors) {
        dispatch(hideErrorAlert());
      }
    }
  }

  function onContactDistributionCalcTypeChange(event) {
    const newPlan = onInputChange(event);
    if (
      newPlan.planSettings.rules.contactDistributionCalcType === CONTACT_DISTRIBUTION_CALC_TYPE_ENUM.BEGINNING_OF_YEAR
    ) {
      newPlan.planSettings.rules.contactDistributionCalcPeriod.duration = undefined;
      setPlan(newPlan);
    }
  }

  function updateState(propertyPath, value) {
    const newPlan = cloneDeep(plan);
    updateObjectByNameAndValue(plan, propertyPath, value);
    setPlan(newPlan);

    const newErrors = validateSingleValue(() => validator.validateMembershipSettings(newPlan), errors, propertyPath);

    if (!newErrors) {
      dispatch(hideErrorAlert());
    }

    setErrors(newErrors);
  }

  function validateStep(currPlan) {
    const newErrors = validator.validateMembershipSettings(currPlan);

    if (newErrors) {
      dispatch(showErrorAlert(getMessage("form.missingSettingsAlert")));
    }

    setErrors(newErrors);

    return newErrors === undefined;
  }

  function onCancel() {
    setPlan(initialPlan);
  }

  function onSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    const newErrors = validator.validateMembershipSettings(plan);

    if (newErrors === undefined) {
      dispatch(
        updatePlan({
          id: plan.id,
          data: plan,
          disableAlert: fromOnboarding
        })
      );
    } else {
      dispatch(showErrorAlert(getMessage("form.missingSettingsAlert")));
    }

    setErrors(newErrors);
  }

  function calculationPeriodTooltip() {
    if (!planService.isSpendPlan(plan)) return getMessage("membershipSettings.calculationPeriodTooltip.points");
    return getMessage("membershipSettings.calculationPeriodTooltip.spend");
  }

  function renderFooterButtons() {
    return (
      <div className="e-buttongroup">
        <button className="e-btn" onClick={onCancel}>
          {getMessage("cancelBtn.label")}
        </button>
        <button className="e-btn e-btn-primary" type="submit" onClick={onSubmit} disabled={viewMode}>
          {getMessage("saveBtn.label")}
        </button>
      </div>
    );
  }

  if (!plan) {
    return null;
  }

  const rules = plan.planSettings.rules;

  return (
    <div className="box-padding">
      <div className="e-row">
        <Notification
          content={getMessage("membershipSettings.notification", {
            learnMoreLink: (
              <a target={"_blank"} href={MEMBERSHIP_SETTINGS_HELP_LINK}>
                {getMessage("membershipSettings.notification.learnMoreLinkText")}
              </a>
            )
          })}
        />
        <div className="e-col-6">
          <h2>{getMessage("membershipSettings.title")}</h2>
          {/* <RadioButtonList
            label="Contact sign-up process"
            name="planSettings.rules.optIn"
            value={rules.optIn}
            options={OPT_IN_OPTIONS}
            onChange={onInputChange}
            disabled={viewMode}
          /> */}

          <RadioButtonList
            label={getMessage("membershipSettings.contactDistributionRb.label")}
            name="planSettings.rules.contactDistribution"
            value={rules.contactDistribution}
            options={CONTACT_DISTRIBUTION_OPTIONS}
            onChange={onContactDistributionChange}
            tooltip={getMessage("membershipSettings.contactDistributionRb.tooltip")}
            disabled={viewMode}
          />

          <div className="e-field">
            {!planService.isSpendPlan(plan) && (
              <div>
                <label className="e-field__label">
                  {getMessage("membershipSettings.pointsDistribution.title")}
                  <e-tooltip type="helper" content={getMessage("membershipSettings.pointsDistribution.tooltip")} />
                </label>
                <CheckboxField
                  label={getMessage("membershipSettings.pointsDistribution.statusPoints.title")}
                  renderOnlyCheckbox={true}
                  checked={rules.addStatusPointsDistribution}
                  name="planSettings.rules.addStatusPointsDistribution"
                  onChange={onInputChange}
                  disabled={viewMode || rules.contactDistribution == CONTACT_DISTRIBUTION_ENUM.FIRST_TIER}
                  tooltip={getMessage("membershipSettings.pointsDistribution.statusPoints.tooltip")}
                />

                <div className="m-t-5">
                  <CheckboxField
                    label={getMessage("membershipSettings.pointsDistribution.balancePoints.title")}
                    renderOnlyCheckbox={true}
                    checked={rules.addBalancePointsDistribution}
                    name="planSettings.rules.addBalancePointsDistribution"
                    onChange={onInputChange}
                    disabled={viewMode}
                    tooltip={getMessage("membershipSettings.pointsDistribution.balancePoints.tooltip")}
                  />
                </div>
              </div>
            )}

            {planService.isSpendPlan(plan) && (
              <div>
                <CheckboxField
                  label={getMessage("membershipSettings.pointsDistribution.creditBalance.title")}
                  renderOnlyCheckbox={true}
                  checked={rules.addStatusPointsDistribution}
                  name="planSettings.rules.addStatusPointsDistribution"
                  onChange={onInputChange}
                  disabled={viewMode || rules.contactDistribution == CONTACT_DISTRIBUTION_ENUM.FIRST_TIER}
                  tooltip={getMessage("membershipSettings.pointsDistribution.creditBalance.tooltip")}
                />
              </div>
            )}
          </div>

          <div
            className={classNames({
              "l-overlay-disabled":
                rules.contactDistribution == CONTACT_DISTRIBUTION_ENUM.FIRST_TIER &&
                !rules.addStatusPointsDistribution &&
                !rules.addBalancePointsDistribution
            })}
          >
            <div>
              <RadioButtonList
                label={getMessage("membershipSettings.calculationPeriodRb.title")}
                name="planSettings.rules.contactDistributionCalcType"
                value={rules.contactDistributionCalcType}
                options={CONTACT_DISTRIBUTION_CALC_TYPE_OPTIONS}
                onChange={onContactDistributionCalcTypeChange}
                tooltip={calculationPeriodTooltip()}
                errorMsg={getErrorMessage(errors, "planSettings.rules.contactDistributionCalcType")}
                disabled={viewMode}
              />

              {planService.hasContactDistributionPeriod(plan) && (
                <div className="m-l-20 m-n-t-15">
                  <TimeValidityInput
                    name="planSettings.rules.contactDistributionCalcPeriod"
                    validity={rules.contactDistributionCalcPeriod}
                    onChange={onInputChange}
                    changeStateFunc={updateState}
                    errorMsg={getErrorMessage(errors, "planSettings.rules.contactDistributionCalcPeriod.duration")}
                    disabled={viewMode}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {fromOnboarding ? null : (
        <Fragment>
          <hr className="e-separator e-separator-fullwidth" />
          {renderFooterButtons()}
        </Fragment>
      )}
    </div>
  );
};

MembershipSettings.propTypes = {
  fromOnboarding: PropTypes.bool,
  registerSubmitFunc: PropTypes.func,
  registerValidateFunc: PropTypes.func
};

export default MembershipSettings;
