import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Navigation from "../components/Navigation";
import { TIERS_TABS, TIERS_TAB_ENUM } from "../constants/navigationConstants";
import { selectHasInviteTier } from "../reducers";

import { getMessage } from "../messages";

export default function TiersNavigation({ children }) {
  const [tabs, setTabs] = useState(TIERS_TABS);
  const hasInviteTier = useSelector((state) => selectHasInviteTier(state));
  useEffect(() => {
    if (hasInviteTier) {
      setTabs(tabs.filter((tab) => tab.id !== TIERS_TAB_ENUM.ADDITIONAL_BENEFITS));
    }
  }, []);

  return (
    <Navigation title={getMessage("route.tiers.title")} tabsConfig={tabs}>
      {children}
    </Navigation>
  );
}
