import * as TYPES from "./TYPES";
import customerApi from "../api/customerApi";
import { invalidateJwt } from "./sessionActions";

export const getTranslations = () => {
  return (dispatch) => {
    dispatch({
      type: TYPES.GET_TRANSLATIONS_REQUEST
    });
    return customerApi
      .getTranslations()
      .then((data) => {
        dispatch({
          type: TYPES.GET_TRANSLATIONS_SUCCESS,
          payload: {
            translations: data
          }
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.GET_TRANSLATIONS_FAILURE
        });
      });
  };
};
