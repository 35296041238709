import * as TYPES from "../actions/TYPES";

import tierService from "../services/tierService";
import { arrayToObject } from "../helpers/objectHelper";

const initialState = {
  tiersById: {},
  loading: false,
  dataLoaded: false,
  postSuccess: false,
  newTierId: null,
  tierEditState: {
    tier: null,
    stepId: null
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.POST_TIER_REQUEST: {
      return {
        ...state,
        loading: true,
        postSuccess: false
      };
    }

    case TYPES.POST_TIER_REQUEST:
    case TYPES.DELETE_TIER_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case TYPES.GET_ALL_TIERS_REQUEST: {
      return {
        ...state,
        loading: true,
        dataLoaded: false
      };
    }

    case TYPES.GET_ALL_TIERS_SUCCESS: {
      const tiersData = action.payload == null ? [] : action.payload;
      const tiersById = arrayToObject(tierService.sortTiers(tiersData));

      return {
        ...state,
        tiersById,
        loading: false,
        dataLoaded: true
      };
    }

    case TYPES.POST_TIER_SUCCESS: {
      const tierToSet = action.payload.data;

      const { tiersById } = { ...state };
      tiersById[tierToSet.id] = tierToSet;

      let result = {
        ...state,
        tiersById,
        loading: false,
        postSuccess: true
      };

      if (action.payload.isNew) {
        result.newTierId = tierToSet.id;
      }
      return result;
    }

    case TYPES.DELETE_TIER_SUCCESS: {
      const tierId = action.payload.id;
      const { tiersById } = { ...state };
      delete tiersById[tierId];

      return {
        ...state,
        tiersById,
        loading: false
      };
    }

    case TYPES.GET_ALL_TIERS_FAILURE:
    case TYPES.POST_TIER_FAILURE:
    case TYPES.DELETE_TIER_FAILURE:
      return {
        ...state,
        loading: false
      };

    case TYPES.SET_TIER_EDIT_STATE: {
      return {
        ...state,
        tierEditState: {
          tier: { ...action.payload.tier },
          stepId: action.payload.stepId
        }
      };
    }

    case TYPES.RESET_TIER_EDIT_STATE: {
      return {
        ...state,
        tierEditState: { ...initialState.tierEditState }
      };
    }

    case TYPES.RESET_NEW_TIER_ID: {
      return {
        ...state,
        newTierId: null
      };
    }
    default:
      return state;
  }
}

export const selectTiers = (state) => {
  const { tiersById } = { ...state };
  return Object.keys(tiersById).map((id) => tiersById[id]);
};

export const selectHasInvalidTier = (state) => {
  const tiers = selectTiers(state);
  return tiers.some((t) => !t.isValid);
};

export const selectTierNames = (state) => {
  const { tiersById } = { ...state };
  return Object.keys(tiersById).map((id) => tiersById[id].name);
};

export const selectTiersMapById = (state) => {
  const { tiersById } = { ...state };
  return tiersById;
};

export const selectTierById = (state, id) => {
  const { tiersById } = { ...state };
  return tiersById[id];
};

export const selectTierEditState = (state) => {
  return { ...state.tierEditState };
};

export const selectNewTierId = (state) => {
  return state.newTierId;
};

export const selectHasInviteTier = (state) => {
  const tiers = selectTiers(state);
  return tiers.some((t) => !tierService.isInviteTier(t));
};

export const isDataLoaded = (state) => {
  return state.dataLoaded;
};

export const isLoading = (state) => {
  return state.loading;
};

export const isPostSuccess = (state) => {
  return state.postSuccess;
};
