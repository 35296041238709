import validate from "validate.js";

import { convertPathStrName } from ".";
import {
  TIERS_CALCULATION_TYPE_ENUM,
  STATUS_POINTS_EXPIRATION_TYPE_ENUM,
  CASHBACK_LIMITATION_ENUM
} from "../constants/planConstants";
import { getMessage } from "../messages";
import planService from "../services/planService";

const POINTS_NAME_LENGTH = 10;

validate.validators.balancePointsExpiration = function (value, options, key, plan) {
  const { balancePointsExpiration } = plan.planSettings.rules;

  if (balancePointsExpiration.disabled) {
    return undefined;
  }

  const error = validate(plan, {
    "planSettings.rules.balancePointsExpiration.duration": {
      presence: {
        allowEmpty: false,
        message: getMessage("balancePointsExpiration.duration.presence")
      },
      numericality: {
        onlyInteger: true,
        notInteger: getMessage("balancePointsExpiration.duration.numericality.notInteger"),
        greaterThan: 0,
        notGreaterThan: getMessage("balancePointsExpiration.duration.numericality.notGreaterThan")
      }
    }
  });

  if (error !== undefined) {
    return error[key][0];
  }

  return undefined;
};

export default class planValidator {
  constructor(languages) {
    this.languages = languages;
  }

  createPlanSettingsConstraints(plan, validateHistoricalBalancePoints) {
    const { planSettings } = { ...plan };
    const { rules } = { ...planSettings };

    let constraints = {
      planType: {
        presence: {
          allowEmpty: false,
          message: getMessage("planSettingsConstraints.planType.presence")
        }
      },
      "planSettings.name": {
        presence: {
          allowEmpty: false,
          message: getMessage("planSettingsConstraints.planSettings.name.presence")
        }
      },
      "planSettings.nameML": {
        multiLangField: {
          languages: this.languages,
          message: getMessage("planSettingsConstraints.planSettings.nameMultiLanguage.presence")
        }
      },
      "planSettings.pointsName": {
        presence: {
          allowEmpty: false,
          message: getMessage(
            planService.isSpendPlan(plan)
              ? "planSettingsConstraints.planSettings.spendPlan.pointsName.presence"
              : "planSettingsConstraints.planSettings.pointsName.presence"
          )
        },
        length: {
          maximum: POINTS_NAME_LENGTH,
          message: getMessage(
            planService.isSpendPlan(plan)
              ? "planSettingsConstraints.planSettings.spendPlan.pointsName.length"
              : "planSettingsConstraints.planSettings.pointsName.length"
          )
        }
      },
      "planSettings.pointsNameML": {
        multiLangField: {
          languages: this.languages,
          message: getMessage(
            planService.isSpendPlan(plan)
              ? "planSettingsConstraints.planSettings.spendPlan.pointsNameMultiLanguage.presence"
              : "planSettingsConstraints.planSettings.pointsNameMultiLanguage.presence"
          )
        }
      },
      "planSettings.rules.balancePointsExpiration.duration": {
        balancePointsExpiration: {
          messageRequired: getMessage(
            "planSettingsConstraints.planSettings.balancePointsExpiration.duration.messageRequired"
          ),
          messageHigherThanZero: getMessage(
            "planSettingsConstraints.planSettings.balancePointsExpiration.duration.messageHigherThanZero"
          )
        }
      },
      "planSettings.rules.rewardsAllocationDelay.duration": {
        presence: {
          allowEmpty: false,
          message: getMessage("planSettingsConstraints.planSettings.rewardsAllocationDelay.duration.presence")
        },
        numericality: {
          onlyInteger: true,
          notInteger: getMessage(
            "planSettingsConstraints.planSettings.rewardsAllocationDelay.duration.numericality.notInteger"
          )
        }
      },
      "planSettings.rules.statusPointsExpirationType": {
        presence: {
          allowEmpty: false,
          message: getMessage("planSettingsConstraints.planSettings.statusPointsExpirationType.presence")
        }
      }
    };

    let tierExpirationFieldName = "";
    if (rules.tiersCalculationType === TIERS_CALCULATION_TYPE_ENUM.END_OF_YEAR) {
      tierExpirationFieldName = "planSettings.rules.tiersCalculationYearlyInterval";
    } else if (rules.tiersCalculationType === TIERS_CALCULATION_TYPE_ENUM.AFTER_JOIN) {
      tierExpirationFieldName = "planSettings.rules.tiersCalculationMonthlyInterval";
    }

    if (tierExpirationFieldName) {
      constraints[tierExpirationFieldName] = {
        presence: {
          allowEmpty: false,
          message: getMessage("planSettingsConstraints.planSettings.rules.tiersCalculationYearlyInterval.presence")
        },
        numericality: {
          onlyInteger: true,
          notInteger: getMessage(
            "planSettingsConstraints.planSettings.rules.tiersCalculationYearlyInterval.numericality.notInteger"
          ),
          greaterThan: 0,
          notGreaterThan: getMessage(
            "planSettingsConstraints.planSettings.rules.tiersCalculationYearlyInterval.numericality.notGreaterThan"
          )
        }
      };
    }

    if (rules.statusPointsExpirationType === STATUS_POINTS_EXPIRATION_TYPE_ENUM.FROM_EARN) {
      constraints["planSettings.rules.statusPointsValidity.duration"] = {
        presence: {
          allowEmpty: false,
          message: getMessage("planSettingsConstraints.planSettings.rules.statusPointsValidity.duration.presence")
        },
        numericality: {
          onlyInteger: true,
          notInteger: getMessage(
            "planSettingsConstraints.planSettings.rules.statusPointsValidity.duration.numericality.notInteger"
          ),
          greaterThan: 0,
          notGreaterThan: getMessage(
            "planSettingsConstraints.planSettings.rules.statusPointsValidity.duration.numericality.notGreaterThan"
          )
        }
      };
    }

    if (validateHistoricalBalancePoints) {
      constraints["planSettings.rules.historicalBalancePoints"] = {
        presence: {
          allowEmpty: false,
          message: getMessage("planSettingsConstraints.planSettings.rules.historicalBalancePoints.presence")
        }
      };
    }

    if (!planSettings.rules.balancePointsExpiration.disabled && !!planSettings.rules.balancePointsExpiration.duration) {
      const clacMultiplier = (type) => {
        switch (type) {
          case "weeks":
            return 7;
          case "months":
            return 30;
          default:
            return 1;
        }
      };

      const calcLessThan = (type, duration) => {
        let multiplier = clacMultiplier(type);
        return multiplier * duration;
      };

      const { type, duration } = planSettings.rules.balancePointsExpiration;
      const lessThan = calcLessThan(type, duration);

      const timeUnit = duration === 1 ? type.slice(0, -1) : type;

      constraints["planSettings.rules.pointsAboutToExpire.duration"] = {
        presence: {
          allowEmpty: false,
          message: getMessage("planSettingsConstraints.planSettings.rules.pointsAboutToExpire.duration.presence")
        },
        numericality: {
          onlyInteger: true,
          notInteger: getMessage(
            "planSettingsConstraints.planSettings.rules.pointsAboutToExpire.duration.numericality.notInteger"
          ),
          greaterThan: 0,
          notGreaterThan: getMessage(
            "planSettingsConstraints.planSettings.rules.pointsAboutToExpire.duration.numericality.notGreaterThan"
          ),
          lessThan: lessThan,
          notLessThan: getMessage(
            "planSettingsConstraints.planSettings.rules.pointsAboutToExpire.duration.numericality.notLessThan",
            { duration, timeUnit }
          )
        }
      };
    }

    if (planService.isSpendPlan(plan)) {
      constraints["planSettings.currencyIdentifierSign"] = {
        presence: {
          allowEmpty: false,
          message: getMessage("planSettingsConstraints.planSettings.spendPlan.currencyIdentifierSign.presence")
        }
      };
    }

    if (!planService.isSpendPlan(plan)) {
      constraints["planSettings.singlePointName"] = {
        presence: {
          allowEmpty: true,
          message: getMessage("planSettingsConstraints.planSettings.singlePointName.presence")
        },
        length: {
          maximum: POINTS_NAME_LENGTH,
          message: getMessage("planSettingsConstraints.planSettings.singlePointName.length")
        }
      };
      constraints["planSettings.singlePointNameML"] = {
        multiLangField: {
          languages: this.languages,
          message: getMessage("planSettingsConstraints.planSettings.singlePointNameMultiLanguage.presence")
        }
      };
    }

    return constraints;
  }

  createMembershipSettingsConstraints(plan) {
    let constraints = {
      "planSettings.rules.contactDistributionCalcType": {
        presence: {
          allowEmpty: false,
          message: getMessage("planSettingsConstraints.planSettings.contactDistributionCalcType.presence")
        }
      }
    };
    if (planService.hasContactDistributionPeriod(plan)) {
      constraints["planSettings.rules.contactDistributionCalcPeriod.duration"] = {
        presence: {
          allowEmpty: false,
          message: getMessage("planSettingsConstraints.planSettings.contactDistributionCalcPeriod.presence")
        },
        numericality: {
          onlyInteger: true,
          notInteger: getMessage(
            "planSettingsConstraints.planSettings.contactDistributionCalcPeriod.numericality.notInteger"
          ),
          greaterThan: 0,
          notGreaterThan: getMessage(
            "planSettingsConstraints.planSettings.contactDistributionCalcPeriod.numericality.notGreaterThan"
          )
        }
      };
    }
    return constraints;
  }

  createCashbackSettingsConstraints(plan, cashbackLimitation) {
    let constraints = {};
    if (plan.planSettings.rules?.cashback?.enabled) {
      constraints["planSettings.cashbackName"] = {
        presence: {
          allowEmpty: false,
          message: getMessage("planSettingsConstraints.planSettings.cashbackName.presence")
        },
        length: {
          maximum: POINTS_NAME_LENGTH,
          message: getMessage("planSettingsConstraints.planSettings.cashbackName.length")
        }
      };

      if (!!plan.planSettings.rules?.cashback?.resetAt?.month && false) {
        constraints["planSettings.rules.cashback.resetAt.day"] = {
          presence: {
            allowEmpty: false,
            message: "Reset at day is required."
          }
        };
      }

      if (cashbackLimitation === CASHBACK_LIMITATION_ENUM.CASHBACK_LIMITATION_LIMITED) {
        constraints["planSettings.rules.cashback.maxAmount"] = {
          presence: {
            allowEmpty: false,
            message: getMessage("planSettingsConstraints.cashback.maxAmount.presence")
          },
          numericality: {
            greaterThan: 0,
            notGreaterThan: getMessage("planSettingsConstraints.cashback.maxAmount.numericality.notGreaterThan")
          }
        };

        constraints["planSettings.rules.cashback.resetAt.day"] = {
          presence: {
            allowEmpty: false,
            message: getMessage("planSettingsConstraints.cashback.resetAt.day.presence")
          }
        };

        constraints["planSettings.rules.cashback.resetAt.month"] = {
          presence: {
            allowEmpty: false,
            message: getMessage("planSettingsConstraints.cashback.resetAt.day.presence")
          }
        };
      }
    }

    return constraints;
  }

  validatePlanSettings = (plan, validateHistoricalBalancePoints) => {
    return validate(plan, this.createPlanSettingsConstraints(plan, validateHistoricalBalancePoints));
  };

  validateMembershipSettings = (plan) => {
    return validate(plan, this.createMembershipSettingsConstraints(plan));
  };

  validateCashbackSettings = (plan, cashbackLimitation) => {
    return validate(plan, this.createCashbackSettingsConstraints(plan, cashbackLimitation));
  };

  validateMultiLangPopup = (plan, fields) => {
    let constraints = {};
    this.languages.forEach((lang) => {
      Object.keys(fields).forEach((key) => {
        constraints[`${convertPathStrName("planSettings.nameML")}.${lang}`] = {
          presence: {
            allowEmpty: false,
            message: getMessage("planSettingsConstraints.planSettings.name.presence")
          }
        };

        constraints[`${convertPathStrName("planSettings.pointsNameML")}.${lang}`] = {
          presence: {
            allowEmpty: false,
            message: getMessage(
              planService.isSpendPlan(plan)
                ? "planSettingsConstraints.planSettings.spendPlan.pointsName.presence"
                : "planSettingsConstraints.planSettings.pointsName.presence"
            )
          },
          length: {
            maximum: POINTS_NAME_LENGTH,
            message: getMessage(
              planService.isSpendPlan(plan)
                ? "planSettingsConstraints.planSettings.spendPlan.pointsName.length"
                : "planSettingsConstraints.planSettings.pointsName.length"
            )
          }
        };

        constraints[`${convertPathStrName("planSettings.singlePointNameML")}.${lang}`] = {
          presence: {
            allowEmpty: false,
            message: getMessage("planSettingsConstraints.planSettings.singlePointName.presence")
          },
          length: {
            maximum: POINTS_NAME_LENGTH,
            message: getMessage("planSettingsConstraints.planSettings.singlePointName.length")
          }
        };
      });
    });

    return validate(fields, constraints);
  };
}
