import { isTagElement } from "@formatjs/icu-messageformat-parser";
import React, { Fragment, useRef, useEffect } from "react";

const Btn = ({ name, label, active, disabled, onClick, gridName, value, tooltip, style }) => {
  //const element = useRef(null)
  //useEffect(() => {
  //   element.current.addEventListener("click", onBtnClick)
  //}, [])

  const clickHandler = (event, gridName, value) => {
    event.preventDefault();
    if (disabled) return;
    event.target.classList.toggle("e-btn-primary");
    onClick(event, gridName, value);
  };

  const getAttrib = () => {
    let attrib = {
      name: name,
      //ref: element,
      className: "e-btn e-btn-onlyicon"
    };

    if (!!disabled) {
      attrib["disabled"] = true;
    }

    if (!!active) {
      attrib["className"] = "e-btn e-btn-onlyicon e-btn-primary";
    }

    if (!!style) {
      attrib["className"] = "e-btn e-btn-onlyicon " + style;
    }

    if (!!tooltip && tooltip.length > 0) {
      attrib["title"] = tooltip;
    }
    return attrib;
  };

  return (
    <a {...getAttrib()} onClick={(event) => clickHandler(event, gridName, value)}>
      {label}
    </a>
  );
};

const ButtonGrid = ({ rowLength, items, disabled, onClick, gridName, inline, errorMsg }) => {
  const getButtons = (items, rowLength) => {
    let elements = [];
    let row = [];
    let classes = "e-buttongroup e-margin-top-s";
    if (!!inline) {
      classes = "e-buttongroup e-buttongroup-flex e-margin-top-s";
    }
    items.map((item, index) => {
      if (index % parseInt(rowLength) == 0 && index > 0) {
        elements.push(
          <div key={index} className={classes}>
            {row}
          </div>
        );
        row = [];
      }
      row.push(
        <Btn
          name={item.name}
          key={item.label}
          value={item.value}
          label={item.label}
          active={item.active}
          disabled={disabled}
          onClick={onClick}
          gridName={gridName}
          tooltip={item.tooltip}
          style={item.style}
        />
      );
    });

    elements.push(
      <div key={items.length} className={classes}>
        {row}
      </div>
    );
    return elements;
  };

  return (
    <Fragment>
      {getButtons(items, rowLength)}
      {errorMsg ? <span className="e-field__message e-field__message-error">{errorMsg}</span> : null}
    </Fragment>
  );
};

export default ButtonGrid;
