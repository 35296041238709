import React, { Fragment } from "react";
import PropTypes from "prop-types";

import InputField from "../components/InputField";
import InputNumberField from "../components/InputNumberField";
import SelectField from "../components/SelectFieldV2";
import IconSelectInput from "../components/IconSelectInput";
import MultiLangPopupOpener from "../components/MultiLangPopupOpener";
import Notification from "../components/Notification";

import { getErrorMessage } from "../validators";

import { getGeneralIconsUrl } from "../helpers/envHelper";

import iconsApi from "../api/iconsApi";
import { rewardType } from "../types";
import rewardService from "../services/rewardService";
import { getMessage } from "../messages";
import CheckboxField from "../components/CheckboxField";
import { VOUCHER_TYPE_ENUM } from "../constants/rewardConstants";
const RewardSetupPoolSettings = (props) => {
  const {
    reward,
    onInputChange,
    onVoucherTypeChange,
    viewMode,
    onShowVoucherNameMultiLang,
    onBlockExternalLinkMultiLang,
    hasMultiLanguage
  } = props;

  return (
    <div className="e-box">
      <div className="e-row">
        {rewardService.isCashback(reward.masterReward) && (
          <div className="e-col-12">
            <Notification content={getMessage("rewardSetupPoolSettings.notification.cashback")} />
          </div>
        )}
        <div className="e-col-6">
          <h2>{getMessage("rewardSetupPoolSettings.title")}</h2>

          {/* Pool Name */}
          {!rewardService.isCashback(reward.masterReward) && (
            <Fragment>
              <InputField
                label={getMessage("rewardSetupPoolSettings.reward.name.label")}
                type="text"
                name="name"
                value={reward.name}
                onChange={onInputChange}
                errorMsg={getErrorMessage(props.errors, "name")}
                disabled={viewMode}
                tooltip={getMessage("rewardSetupPoolSettings.reward.name.tooltip")}
                isRequired={true}
              />
              <SelectField
                label={getMessage("rewardSetupPoolSettings.voucherType.label")}
                name="voucherType"
                options={props.voucherTypeOptions}
                value={reward.voucherType}
                onChange={onVoucherTypeChange}
                disabled={viewMode}
                isRequired={true}
              />
              {reward.voucherType === VOUCHER_TYPE_ENUM.ONE_TIME && (
                <CheckboxField
                  label={getMessage("rewardSetupPoolSettings.isRemoveOnUse.label")}
                  name="isRemoveOnUse"
                  checked={reward.isRemoveOnUse}
                  onChange={onInputChange}
                  tooltip={getMessage("rewardSetupPoolSettings.isRemoveOnUse.tooltip")}
                />
              )}
            </Fragment>
          )}
          {/* Voucher Name */}
          <InputField
            label={getMessage("rewardSetupPoolSettings.voucherName.label")}
            type="text"
            name="voucherName"
            value={reward.voucherName}
            onChange={onInputChange}
            errorMsg={getErrorMessage(props.errors, "voucherName")}
            disabled={viewMode}
            postfixButtons={hasMultiLanguage && <MultiLangPopupOpener onClick={onShowVoucherNameMultiLang} />}
            tooltip={getMessage("rewardSetupPoolSettings.voucherName.tooltip")}
            isRequired={true}
          />

          <IconSelectInput
            label={getMessage("rewardSetupPoolSettings.voucherIcon.label")}
            name="icon"
            displayName={reward.icon && reward.icon.displayName}
            fileName={reward.icon && reward.icon.fileName}
            iconPrefixUrl={getGeneralIconsUrl()}
            selectIconText={getMessage("rewardSetupPoolSettings.voucherIcon.selectIconText")}
            onChange={props.onIconInputChange}
            loadPromise={iconsApi.getGeneralIcons}
            loadCustomerPromise={iconsApi.getCustomerIcons}
            iconType="general"
            disabled={viewMode}
            tooltip={getMessage("rewardSetupPoolSettings.voucherIcon.tooltip")}
          />
          {rewardService.isVoucherForRewards(reward.masterReward) && (
            <div className="e-row">
              <div className="e-col-6">
                <InputField
                  label={getMessage("rewardSetupPoolSettings.blockExternalLink.url.label")}
                  type="text"
                  name="blockExternalLink.url"
                  value={reward.blockExternalLink.url || "http://"}
                  onChange={onInputChange}
                  errorMsg={getErrorMessage(props.errors, "blockExternalLink.url")}
                  disabled={viewMode}
                  tooltip={getMessage("rewardSetupPoolSettings.blockExternalLink.url.tooltip")}
                  prefixLabel={<e-icon icon="link" size="small"></e-icon>}
                />
              </div>
              <div className="e-col-6">
                <InputField
                  label={getMessage("rewardSetupPoolSettings.blockExternalLink.text.label")}
                  type="text"
                  name="blockExternalLink.text"
                  value={reward.blockExternalLink.text}
                  onChange={onInputChange}
                  errorMsg={getErrorMessage(props.errors, "blockExternalLink.text")}
                  disabled={viewMode}
                  tooltip={getMessage("rewardSetupPoolSettings.blockExternalLink.text.tooltip")}
                  postfixButtons={hasMultiLanguage && <MultiLangPopupOpener onClick={onBlockExternalLinkMultiLang} />}
                />
              </div>
            </div>
          )}

          <InputNumberField
            label={getMessage("rewardSetupPoolSettings.voucherValue.label")}
            name="voucherValue"
            value={reward.voucherValue}
            onChange={onInputChange}
            tooltip={getMessage("rewardSetupPoolSettings.voucherValue.tooltip")}
            allowDecimal={true}
            disabled={viewMode}
          />
        </div>
      </div>
    </div>
  );
};

RewardSetupPoolSettings.propTypes = {
  reward: rewardType.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onIconInputChange: PropTypes.func.isRequired,
  onVoucherTypeChange: PropTypes.func.isRequired,
  onShowVoucherNameMultiLang: PropTypes.func.isRequired,
  onBlockExternalLinkMultiLang: PropTypes.func.isRequired,
  voucherTypeOptions: PropTypes.array,
  errors: PropTypes.object,
  viewMode: PropTypes.bool,
  hasMultiLanguage: PropTypes.bool
};

export default RewardSetupPoolSettings;
