import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import InputField from "../../components/InputField";
import InputCurrencyField from "../../components/InputCurrencyField";
import SelectField from "../../components/SelectFieldV2";
import IconSelectInput from "../../components/IconSelectInput";
import CatalogSelectorInput from "../../components/CatalogSelectorInput";
import CheckboxField from "../../components/CheckboxField";
import RadioButtonList from "../../components/RadioButtonList";
import Notification from "../../components/Notification";
import MultiLangPopupOpener from "../../components/MultiLangPopupOpener";
import MultiCurrencyPopupOpener from "../../components/MultiCurrencyPopupOpener";
import MultiMarketSelect from "../../components/MultiMarketSelect";

import {
  selectPlanHasMultiMarket,
  selectPlanHasMultiLang,
  selectPlanHasMultiCurrency,
  selectActions
} from "../../reducers";

import { getErrorMessage } from "../../validators";

import { getGeneralIconsUrl } from "../../helpers/envHelper";

import iconsApi from "../../api/iconsApi";

import actionService from "../../services/actionService";

import { actionType } from "../../types";

import { MASTER_ACTION_ENUM } from "../../constants/actionConstants";
import { getMessage } from "../../messages";

const ActionSetupGeneral = (props) => {
  const {
    action,
    onInputChange,
    viewMode,
    showMinOrder,
    showMinOrderDisplay,
    errors,
    notificationElem,
    actionTypeText,
    onShowActionNameMultiLang,
    onShowMinOrderMultiLang,
    onBlockExternalLinkMultiLang,
    primaryCurrency,
    isActionActive
  } = props;

  if (!action.masterAction) {
    return null;
  }

  const defaultJoin = action.isDefault && action.masterAction === MASTER_ACTION_ENUM.JOIN;
  const defaultNoJoin = action.isDefault && !(action.masterAction === MASTER_ACTION_ENUM.JOIN);
  const isReferral = actionService.isReferralAction(action.masterAction);

  const hasMultiLang = useSelector((state) => selectPlanHasMultiLang(state));
  const hasMultiCurrency = useSelector((state) => selectPlanHasMultiCurrency(state));
  const hasMultiMarket = useSelector((state) => selectPlanHasMultiMarket(state));
  let referralExcludeMarkets = [];
  if (isReferral && hasMultiMarket) {
    const actions = useSelector((state) => selectActions(state));
    referralExcludeMarkets = actions
      .filter((action) => actionService.isReferralAction(action.masterAction))
      .map((action) => action.market);
  }

  const renderPromotionSettings = () => {
    return (
      <Fragment>
        <RadioButtonList
          label={getMessage("actionSetupGeneral.promotionSetting.promotionTypeRb.label")}
          name="promotion.usageType"
          value={action.promotion.usageType}
          options={props.promotionTypeOptions}
          onChange={props.onInputChange}
          disabled={viewMode || isActionActive}
          isRequired={true}
        />

        <div className="e-field">
          <label className="e-field__label">
            {getMessage("actionSetupGeneral.promotionSetting.automaticTargeting.label")}
            <e-tooltip
              content={getMessage("actionSetupGeneral.promotionSetting.automaticTargeting.tooltip")}
              type="helper"
            />
          </label>
          <CheckboxField
            label={getMessage("actionSetupGeneral.promotionSetting.automaticTargeting.showInWalletCb.label")}
            name={"promotion.showInWallet"}
            onChange={onInputChange}
            checked={action.promotion.showInWallet}
            disabled={viewMode || isActionActive}
          />
          {/* TODO: add link to "Learn more" */}
          <Notification content={getMessage("actionSetupGeneral.promotionSetting.automaticTargeting.notification")} />
        </div>
      </Fragment>
    );
  };

  const renderPromotionSection = () => {
    let comp = null;
    if (props.showPromotionSettings) {
      comp = renderPromotionSettings();
    }

    if (comp) {
      return (
        <Fragment>
          <div className="e-col-6 ">
            <h2>{getMessage("actionSetupGeneral.promotionSetting.title")}</h2>
            {comp}
          </div>
          <div className="e-col e-col__separator" />
        </Fragment>
      );
    }

    return null;
  };

  const getActionNameLabelAnTooltip = () => {
    if (defaultNoJoin) {
      return {
        nameLabel: getMessage("actionSetupGeneral.actionNameInput.defNoJoin.label"),
        nameTooltip: ""
      };
    }

    if (actionService.isReferralAction(action.masterAction)) {
      return {
        nameLabel: getMessage("actionSetupGeneral.actionNameInput.referral.label"),
        internalNameLabel: getMessage("actionSetupGeneral.actionInternalNameInput.referral.label"),
        nameTooltip: getMessage("actionSetupGeneral.actionNameInput.referral.tooltip")
      };
    }

    return {
      nameLabel: getMessage("actionSetupGeneral.actionNameInput.other.label", { actionTypeText }),
      internalNameLabel: getMessage("actionSetupGeneral.actionInternalNameInput.other.label", { actionTypeText }),
      nameTooltip: getMessage("actionSetupGeneral.actionNameInput.other.tooltip", {
        actionTypeText: actionTypeText.toLowerCase()
      })
    };
  };

  const showUseCaseField = actionService.isPromotionAction(action.masterAction) && !action.isDefault;
  const nameLabelAndTooltip = getActionNameLabelAnTooltip();

  return (
    <div className="e-box">
      {notificationElem}
      <div className="e-row">
        <div className="e-col-6">
          <h2>{getMessage("actionSetupGeneral.title")}</h2>

          {isReferral && (
            <MultiMarketSelect
              label={getMessage("actionSetupGeneral.multiMarketSelect.label")}
              name="market"
              value={action.market}
              excludeMarkets={referralExcludeMarkets}
              onChange={onInputChange}
              errorMsg={getErrorMessage(errors, "market")}
              disabled={viewMode}
              tooltip={getMessage("actionSetupGeneral.multiMarketSelect.tooltip")}
              isRequired={true}
            />
          )}

          {/* Action Type */}
          {showUseCaseField && (
            <SelectField
              label={getMessage("actionSetupGeneral.actionTypeSelect.label")}
              name="actionType"
              value={action.actionType || props.actionTypeOptions[0].value}
              options={props.actionTypeOptions}
              onChange={onInputChange}
              disabled={viewMode || isActionActive}
              isRequired={true}
            />
          )}
          {props.showCatalogSelection ? (
            <CatalogSelectorInput
              label={getMessage("actionSetupGeneral.catalogSelectorInput.label")}
              name="promotion.catalogSelection"
              title={getMessage("actionSetupGeneral.catalogSelectorInput.title")}
              tooltip={getMessage("actionSetupGeneral.catalogSelectorInput.tooltip")}
              disabled={viewMode}
              onChange={props.onCatalogSelectionChange}
              initialBrandIds={action.promotion.catalogSelection.brands}
              initialCategoryIds={action.promotion.catalogSelection.categories}
              initialItemIds={action.promotion.catalogSelection.items}
              allowItemsSelection={true}
              errorMsg={getErrorMessage(props.errors, "promotion.catalogSelection")}
            />
          ) : null}

          {!action.isDefault && (
            <InputField
              label={nameLabelAndTooltip.internalNameLabel}
              type="text"
              name="internalName"
              value={action.internalName}
              maxLength="255"
              onChange={onInputChange.bind(this)}
              isRequired={true}
              errorMsg={getErrorMessage(props.errors, "internalName")}
              disabled={viewMode}
            />
          )}

          {defaultJoin && (
            <InputField
              label={getMessage("actionSetupGeneral.defaultJoinInput.label")}
              type="text"
              name="defaultJoin"
              value={getMessage("actionSetupGeneral.defaultJoinInput.value")}
              maxLength="255"
              disabled={true}
            />
          )}

          {/* Action Name */}
          <InputField
            label={nameLabelAndTooltip.nameLabel}
            type="text"
            name="name"
            value={action.name}
            maxLength="255"
            onChange={onInputChange.bind(this)}
            isRequired={true}
            errorMsg={getErrorMessage(props.errors, "name")}
            postfixButtons={
              defaultNoJoin ? null : hasMultiLang && <MultiLangPopupOpener onClick={onShowActionNameMultiLang} />
            }
            disabled={viewMode || defaultNoJoin}
            tooltip={nameLabelAndTooltip.nameTooltip}
          />

          {/* Description */}
          {!action.isDefault && (
            <InputField
              label={getMessage("actionSetupGeneral.descriptionInput.label")}
              type="text"
              name="description"
              value={action.description}
              maxLength="255"
              onChange={onInputChange.bind(this)}
              errorMsg={getErrorMessage(props.errors, "description")}
              disabled={viewMode}
            />
          )}

          {!isReferral && (
            <IconSelectInput
              label={getMessage("actionSetupGeneral.iconSelectInput.label")}
              name="icon"
              displayName={action.icon && action.icon.displayName}
              fileName={action.icon && action.icon.fileName}
              iconPrefixUrl={getGeneralIconsUrl()}
              selectIconText={getMessage("actionSetupGeneral.iconSelectInput.iconText")}
              onChange={props.onIconInputChange}
              loadPromise={iconsApi.getGeneralIcons}
              loadCustomerPromise={iconsApi.getCustomerIcons}
              iconType="general"
              disabled={viewMode}
              tooltip={getMessage("actionSetupGeneral.iconSelectInput.tooltip")}
            />
          )}

          {showMinOrder ? (
            <div className="e-row">
              <div className={showMinOrderDisplay ? "e-col-6" : "e-col-12"}>
                <InputCurrencyField
                  label={getMessage("actionSetupGeneral.minOrderInput.label")}
                  currency={primaryCurrency}
                  value={action.minOrder}
                  name={"minOrder"}
                  onChange={onInputChange}
                  withEnableCheckbox={true}
                  allowDecimal={true}
                  errorMsg={getErrorMessage(props.errors, "minOrder") || getErrorMessage(props.errors, "minOrderMC")}
                  disabled={viewMode}
                  tooltip={
                    isReferral
                      ? getMessage("actionSetupGeneral.minOrderInput.tooltip.referral")
                      : getMessage("actionSetupGeneral.minOrderInput.tooltip.other")
                  }
                  postfixButtons={
                    !showMinOrderDisplay &&
                    hasMultiCurrency && <MultiCurrencyPopupOpener onClick={onShowMinOrderMultiLang} />
                  }
                />
              </div>
              {showMinOrderDisplay && (
                <div className="e-col-6">
                  <InputCurrencyField
                    label={getMessage("actionSetupGeneral.minOrderDisplayInput.label")}
                    currency={primaryCurrency}
                    value={action.minOrderDisplay}
                    name={"minOrderDisplay"}
                    onChange={onInputChange}
                    withEnableCheckbox={true}
                    allowDecimal={true}
                    errorMsg={getErrorMessage(props.errors, "minOrderDisplay")}
                    disabled={viewMode}
                    tooltip={getMessage("actionSetupGeneral.minOrderDisplayInput.tooltip")}
                    postfixButtons={hasMultiCurrency && <MultiCurrencyPopupOpener onClick={onShowMinOrderMultiLang} />}
                    hidePrefixIcon={true}
                  />
                </div>
              )}
            </div>
          ) : null}

          {actionService.hasExternalLink(action) && (
            <div className="e-row">
              <div className="e-col-6">
                <InputField
                  label={getMessage("actionSetupGeneral.blockExternalLink.url.label")}
                  type="text"
                  name="blockExternalLink.url"
                  value={action.blockExternalLink.url || "http://"}
                  onChange={onInputChange}
                  errorMsg={getErrorMessage(props.errors, "blockExternalLink.url")}
                  disabled={viewMode}
                  tooltip={getMessage("actionSetupGeneral.blockExternalLink.url.tooltip")}
                  prefixLabel={<e-icon icon="link" size="small"></e-icon>}
                />
              </div>
              <div className="e-col-6">
                <InputField
                  label={getMessage("actionSetupGeneral.blockExternalLink.text.label")}
                  type="text"
                  name="blockExternalLink.text"
                  value={action.blockExternalLink.text}
                  onChange={onInputChange}
                  errorMsg={getErrorMessage(props.errors, "blockExternalLink.text")}
                  disabled={viewMode}
                  tooltip={getMessage("actionSetupGeneral.blockExternalLink.text.tooltip")}
                  postfixButtons={hasMultiLang && <MultiLangPopupOpener onClick={onBlockExternalLinkMultiLang} />}
                />
              </div>
            </div>
          )}
        </div>
        {renderPromotionSection()}
      </div>
    </div>
  );
};

ActionSetupGeneral.propTypes = {
  action: actionType.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onCatalogSelectionChange: PropTypes.func,
  actionTypeOptions: PropTypes.array.isRequired,
  onIconInputChange: PropTypes.func,
  showPromotionSettings: PropTypes.bool,
  showCatalogSelection: PropTypes.bool,
  showMinOrder: PropTypes.bool,
  showMinOrderDisplay: PropTypes.bool,
  promotionTypeOptions: PropTypes.array,
  selectPromotionOptions: PropTypes.array,
  errors: PropTypes.object,
  viewMode: PropTypes.bool,
  notificationElem: PropTypes.element,
  actionTypeText: PropTypes.string,
  onShowActionNameMultiLang: PropTypes.func,
  onShowMinOrderMultiLang: PropTypes.func,
  onBlockExternalLinkMultiLang: PropTypes.func,
  primaryCurrency: PropTypes.string,
  isActionActive: PropTypes.bool
};

export default ActionSetupGeneral;
