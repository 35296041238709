import axios from "axios";
import sessionHelper from "../helpers/sessionHelper";

export default class exclusiveAccessApi {
  static async getAllExclusives() {
    const planId = sessionHelper.getPlanId();
    const response = await axios.get(`/plan/${planId}/exclusive-access`);
    return response.data;
  }

  static async createExclusiveAccess(data, dupId) {
    const planId = sessionHelper.getPlanId();
    let path = `/plan/${planId}/exclusive-access`;
    if (dupId) {
      path += `?dupId=${dupId}`;
    }
    const response = await axios.post(path, data);
    return response.data;
  }

  static async updateExclusiveAccess(data, id) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.put(`/plan/${planId}/exclusive-access/${id}`, data);
    return response.data;
  }

  static async deleteExclusiveAccess(id) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.delete(`/plan/${planId}/exclusive-access/${id}`);
    return response;
  }

  static async postExclusiveAccessHtml({ exclusiveAccessId, html, mode, lang }) {
    let url = `/exclusive-access/${exclusiveAccessId}/exclusive-access-html?mode=${mode}`;
    if (!!lang) {
      url += `&lang=${lang}`;
    }

    const response = await axios.post(url, html, {
      headers: { "content-type": "text/html" }
    });

    return response;
  }

  static async getExclusiveAccessHtml({ exclusiveAccessId, mode, lang }) {
    let url = `/exclusive-access/${exclusiveAccessId}/exclusive-access-html?mode=${mode}`;
    if (!!lang) {
      url += `&lang=${lang}`;
    }
    const response = await axios.get(url);

    return response;
  }
}
