import React, { Fragment } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import InputField from "../../components/InputField";
import CheckboxField from "../../components/CheckboxField";
import Notification from "../../components/Notification";
import SelectField from "../../components/SelectFieldV2";
import { openConfirmationDialog } from "../../helpers/dialogHelper";

import { ACTIVATE_API_OPTIONS, ACTIVATE_WALLET_OPTIONS, SECURITY_PAGE_TYPE } from "../../constants/securityConstants";
import { DELETE_CONTACT_API_HELP_LINK } from "../../constants/linkConstants";
import ReAuthPopup, { handleAuthError } from "./ReAuthPopup";
import securityApi from "../../api/securityApi";
import { getMessage } from "../../messages";

export default function SecurityPageSetup({
  pageType,
  id,
  secret,
  initialDesc,
  initialActive,
  onSave,
  onCancel,
  isEditMode,
  initialIsContactFullDelete,
  viewMode
}) {
  const [desc, setDesc] = useState(initialDesc);
  const [active, setActive] = useState(initialActive);
  const [isContactFullDelete, setIsContactFullDelete] = useState(initialIsContactFullDelete);
  const [showReAuthPopup, setShowReAuthPopup] = useState(false);
  const [authError, setAuthError] = useState("");
  const [newSecret, setNewSecret] = useState("");
  const [showReAuthPopupLoading, setShowReAuthPopupLoading] = useState(false);
  const messageKeyPrefix = `securitySettings.${pageType}`;

  function onSaveHandler({ id, desc, active, isContactFullDelete }) {
    let headline = "";
    let content = "";
    const confirmLabel = getMessage("confirmBtn.label");
    const confirmFunc = () => {
      onSave({ id, desc, active, isContactFullDelete });
    };

    if (initialActive == active) {
      confirmFunc();
      return;
    }

    if (active) {
      headline = getMessage(`${messageKeyPrefix}.statusPopup.activate.title`);
      content = getMessage(`${messageKeyPrefix}.statusPopup.activate.content`);
    } else {
      headline = getMessage(`${messageKeyPrefix}.statusPopup.deactivate.title`);
      content = getMessage(`${messageKeyPrefix}.statusPopup.deactivate.content`);
    }

    openConfirmationDialog({ confirmFunc, headline, content, confirmLabel });
  }

  function createSecret(password) {
    setShowReAuthPopupLoading(true);
    securityApi
      .createWalletAppSecret(id, password)
      .then(({ secret }) => {
        setNewSecret(secret);
        setShowReAuthPopup(false);
      })
      .catch((error) => {
        setAuthError(handleAuthError(error));
      })
      .finally(() => {
        setShowReAuthPopupLoading(false);
      });
  }

  function getIsContactFullDeleteTooltip() {
    const link =
      pageType === SECURITY_PAGE_TYPE.API_KEY
        ? `<a target="_blank" href="${DELETE_CONTACT_API_HELP_LINK}">${getMessage(
            "securitySettings.apiKey.setup.deleteContact.tooltipLinkText"
          )}</a>`
        : "";
    const tooltipContent = getMessage(`${messageKeyPrefix}.setup.deleteContact.tooltip`, { link });
    return tooltipContent;
  }

  function CreatedSection() {
    return (
      <Fragment>
        <h2>{getMessage(`${messageKeyPrefix}.setup.title`)}</h2>
        <Notification isSuccess={true} content={getMessage(`${messageKeyPrefix}.setup.createdSuccessfully`)} />
        {pageType === SECURITY_PAGE_TYPE.WALLET_APP && (
          <div>
            <p>{getMessage("securitySettings.setup.appId.label")}</p>
            <p>
              <strong>{id}</strong>
            </p>
          </div>
        )}
        <p>
          {pageType === SECURITY_PAGE_TYPE.API_KEY
            ? getMessage("securitySettings.setup.apiKey.label")
            : getMessage("securitySettings.setup.secretKey.label")}
        </p>
        <p>
          <strong>{secret || newSecret}</strong>
        </p>
        <Notification isWarning={true} content={getMessage(`${messageKeyPrefix}.setup.createdNotification`)} />
      </Fragment>
    );
  }

  function EditWalletAppSection() {
    if (pageType !== SECURITY_PAGE_TYPE.WALLET_APP) {
      return null;
    }

    return (
      <div className="e-field">
        <p>{getMessage("securitySettings.setup.appId.label")}</p>
        <p>
          <strong>{id}</strong>
        </p>
        <button
          disabled={viewMode}
          className="e-btn e-btn-primary"
          onClick={() => {
            setShowReAuthPopup(true);
          }}
        >
          {getMessage("securitySettings.setup.CreateNewSecret.label")}
        </button>
      </div>
    );
  }

  return (
    <div>
      {!isEditMode || newSecret ? <CreatedSection /> : <EditWalletAppSection />}
      <div className="e-cell e-cell-6">
        <InputField
          label={
            isEditMode
              ? getMessage("securitySettings.setup.updateDescription.label")
              : getMessage("securitySettings.setup.description.label")
          }
          value={desc}
          onChange={(event) => setDesc(event.target.value)}
          tooltip={getMessage(`${messageKeyPrefix}.setup.description.tooltip`)}
          disabled={viewMode}
        />
        <div className="e-field">
          <label htmlFor="isContactFullDeleteCheckbox">
            {getMessage(`${messageKeyPrefix}.setup.deleteContact.label`)}
            <e-tooltip content={getIsContactFullDeleteTooltip()} type="helper" has-action></e-tooltip>
          </label>
          <CheckboxField
            id="isContactFullDeleteCheckbox"
            label={getMessage(`${messageKeyPrefix}.setup.deleteContact.checkboxText`)}
            checked={isContactFullDelete}
            onChange={(event) => setIsContactFullDelete(event.target.checked)}
            disabled={viewMode}
          />
        </div>
      </div>
      {isEditMode && (
        <div className="e-cell e-cell-6">
          <SelectField
            label={getMessage("securitySettings.setup.activation.label")}
            value={active}
            options={pageType === SECURITY_PAGE_TYPE.API_KEY ? ACTIVATE_API_OPTIONS : ACTIVATE_WALLET_OPTIONS}
            onChange={(event, selectedValue) => setActive(selectedValue)}
            tooltip={getMessage(`${messageKeyPrefix}.setup.activation.tooltip`)}
            type="bool"
            disabled={viewMode}
          />
        </div>
      )}

      <div className="e-buttongroup">
        <button className="e-btn" onClick={() => onCancel()}>
          {getMessage("securitySettings.setup.backButton")}
        </button>
        <button
          disabled={viewMode}
          className="e-btn e-btn-primary"
          onClick={() => onSaveHandler({ id, desc, active, isContactFullDelete })}
        >
          {getMessage("saveBtn.label")}
        </button>
      </div>
      <ReAuthPopup
        id="createSecretReAuthPopup"
        show={showReAuthPopup}
        title={getMessage("securitySettings.setup.createNewSecret.popup.title")}
        subTitle={getMessage("securitySettings.setup.createNewSecret.popup.content")}
        onClose={() => setShowReAuthPopup(false)}
        onConfirm={createSecret}
        authError={authError}
        loading={showReAuthPopupLoading}
      />
    </div>
  );
}

SecurityPageSetup.propTypes = {
  pageType: PropTypes.string,
  id: PropTypes.string,
  secret: PropTypes.string,
  initialDesc: PropTypes.string,
  initialActive: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  isEditMode: PropTypes.bool,
  initialIsContactFullDelete: PropTypes.bool,
  viewMode: PropTypes.bool
};
