import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import InputNumberField from "./InputNumberField";
import { getCurrencySymbol } from "../helpers/currencyHelper";

import "../assets/styles/InputCurrencyField.scss";

const InputCurrencyField = (props) => {
  const additionalProps = { ...props };
  const currency = getCurrencySymbol(props.currency);

  const className = classNames("input-currency-icon", {
    "input-currency-icon_single": currency.length === 1
  });

  if (!props.hidePrefixIcon) {
    additionalProps.prefixLabel = <span className={className}>{currency}</span>;
  }

  return <InputNumberField {...additionalProps} />;
};

InputCurrencyField.propTypes = {
  currency: PropTypes.string,
  hidePrefixIcon: PropTypes.bool
};

export default InputCurrencyField;
