import React from "react";
import PropTypes from "prop-types";

import PopupContainer from "../../components/PopupContainer";
import Notification from "../../components/Notification";
import CheckboxField from "../../components/CheckboxField";
import { getMessage } from "../../messages";

const DeleteContactPopup = ({ show, onDelete, onPopupClose, externalId, contactId }) => {
  const [isFullDelete, setIsFullDelete] = React.useState(false);

  return (
    <PopupContainer
      id="deleteContact"
      title={getMessage("memberProfileOverview.deleteMemberDialog.headline")}
      width={"672px"}
      show={show}
      onClose={onPopupClose}
    >
      <Notification content={getMessage("memberProfileOverview.deleteMemberDialog.notification")} />

      <div className="e-margin-top-l">{getMessage("memberProfileOverview.deleteMemberDialog.memberToBeDeleted")}</div>
      <div className="font-weight-bold">{externalId}</div>

      <div className="e-margin-top-l">
        <CheckboxField
          id="isFullDeleteCheckbox"
          label={getMessage("memberProfileOverview.deleteMemberDialog.checkboxText")}
          checked={isFullDelete}
          onChange={(event) => setIsFullDelete(event.target.checked)}
        />
      </div>

      <hr className="e-separator e-separator-fullwidth e-margin-top-xl"></hr>

      <div className="e-buttongroup">
        <button className="e-btn" onClick={onPopupClose}>
          {getMessage("cancelBtn.label")}
        </button>
        <button className="e-btn e-dialog__confirmbutton-destructive" onClick={() => onDelete(contactId, isFullDelete)}>
          {getMessage("memberProfileOverview.deleteMemberDialog.deleteButton")}
        </button>
      </div>
    </PopupContainer>
  );
};

DeleteContactPopup.propTypes = {
  show: PropTypes.bool,
  onPopupClose: PropTypes.func,
  onDelete: PropTypes.func,
  externalId: PropTypes.string,
  contactId: PropTypes.string
};

export default DeleteContactPopup;
