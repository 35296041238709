import * as TYPES from "../actions/TYPES";

import { ALERT_STATUS } from "../constants/alertContants";
import actionService from "../services/actionService";
import rewardService from "../services/rewardService";

import { isDemoMode } from "../helpers/envHelper";
import exclusiveAccessService from "../services/exclusiveAccessService";

const initialState = {
  message: "",
  timestamp: "",
  status: "",
  time: 3000,
  isFixed: false,
  show: false
};

export default function reducer(state = initialState, action) {
  const reduceResult = (message, status) => {
    if (isDemoMode()) {
      return initialState;
    }

    if (action.payload && action.payload.disableAlert) {
      return { ...state };
    }

    return {
      ...state,
      message,
      status,
      show: true,
      time: initialState.time,
      isFixed: false,
      timestamp: Date.now()
    };
  };

  switch (action.type) {
    //#region custom Alerts
    case TYPES.SHOW_ALERT: {
      return {
        ...state,
        message: action.payload.message,
        time: action.payload.time || initialState.time,
        isFixed: !!action.payload.isFixed,
        status: action.payload.alertStatus,
        show: true,
        timestamp: Date.now()
      };
    }

    case TYPES.HIDE_ALERT: {
      if (state.isFixed) {
        return {
          ...state,
          show: false,
          timestamp: Date.now()
        };
      }

      return {
        ...state
      };
    }
    //#endregion custom Alerts

    //#region Tier Alerts
    case TYPES.GET_ALL_TIERS_FAILURE: {
      const message = "Failed to retrieve tier data.";
      return reduceResult(message, ALERT_STATUS.ERROR);
    }
    case TYPES.POST_TIER_SUCCESS: {
      const tier = action.payload.data;
      const message = action.payload.isNew
        ? `New tier successfully created: ${tier.name}.`
        : `Tier successfully updated: ${tier.name}.`;

      return reduceResult(message, ALERT_STATUS.SUCCESS);
    }
    case TYPES.POST_TIER_FAILURE: {
      const message = action.payload.isNew ? `Failed to create a new tier.` : `Failed to update tier.`;

      return reduceResult(message, ALERT_STATUS.ERROR);
    }
    case TYPES.DELETE_TIER_SUCCESS: {
      const message = `Tier has been deleted successfully.`;
      return reduceResult(message, ALERT_STATUS.SUCCESS);
    }
    case TYPES.DELETE_TIER_FAILURE: {
      const message = `Error as occurred while trying to delete tier.`;
      return reduceResult(message, ALERT_STATUS.ERROR);
    }
    //#endregion Tier Alerts

    //#region Action Alerts
    case TYPES.GET_ALL_ACTIONS_FAILURE: {
      const message = "Failed to retrieve actions data.";
      return reduceResult(message, ALERT_STATUS.ERROR);
    }
    case TYPES.POST_ACTION_SUCCESS: {
      const data = action.payload.data;
      const actionTitle = data.internalName;
      const message = action.payload.isNew
        ? `New action successfully created: ${actionTitle}.`
        : `Action successfully updated: ${actionTitle}.`;

      return reduceResult(message, ALERT_STATUS.SUCCESS);
    }
    case TYPES.POST_ACTION_FAILURE: {
      const message = action.payload.isNew ? `Failed to create a new action.` : `Failed to update action.`;

      return reduceResult(message, ALERT_STATUS.ERROR);
    }
    case TYPES.DELETE_ACTION_SUCCESS: {
      const message = `Action has been deleted successfully.`;
      return reduceResult(message, ALERT_STATUS.SUCCESS);
    }
    case TYPES.DELETE_ACTION_FAILURE: {
      const message = `Error as occurred while trying to delete action.`;
      return reduceResult(message, ALERT_STATUS.ERROR);
    }
    //#endregion Action Alerts

    //#region Reward Alerts
    case TYPES.GET_ALL_REWARDS_FAILURE: {
      const message = "Failed to retrieve rewards data.";
      return reduceResult(message, ALERT_STATUS.ERROR);
    }
    case TYPES.POST_REWARD_SUCCESS: {
      const data = action.payload.data;
      const rewardTitle = rewardService.getRewardTitle(data);
      const message = action.payload.isNew
        ? `New reward successfully created: ${rewardTitle}.`
        : `Reward successfully updated: ${rewardTitle}.`;

      return reduceResult(message, ALERT_STATUS.SUCCESS);
    }
    case TYPES.POST_REWARD_FAILURE: {
      const message =
        action.payload && action.payload.isNew ? `Failed to create a new reward.` : `Failed to update reward.`;

      return reduceResult(message, ALERT_STATUS.ERROR);
    }
    case TYPES.DELETE_REWARD_SUCCESS: {
      const message = `Reward has been deleted successfully.`;
      return reduceResult(message, ALERT_STATUS.SUCCESS);
    }
    case TYPES.DELETE_REWARD_FAILURE: {
      const message = `Error as occurred while trying to delete reward.`;
      return reduceResult(message, ALERT_STATUS.ERROR);
    }
    case TYPES.ADD_UPLOAD_TO_REWARD_POOL_SUCCESS: {
      const message = `Codes added to pool successfully.`;
      return reduceResult(message, ALERT_STATUS.SUCCESS);
    }
    case TYPES.ADD_UPLOAD_TO_REWARD_POOL_FAILURE: {
      const message = `Error as occurred while trying to add codes to pool.`;
      return reduceResult(message, ALERT_STATUS.ERROR);
    }
    case TYPES.DELETE_REWARD_POOL_SUCCESS: {
      const message = `Pool was deleted successfully.`;
      return reduceResult(message, ALERT_STATUS.SUCCESS);
    }
    case TYPES.DELETE_REWARD_POOL_FAILURE: {
      const message = `Error as occurred while trying to delete pool.`;
      return reduceResult(message, ALERT_STATUS.ERROR);
    }
    //#endregion Reward Alerts

    //#region Exclusive Access Alerts
    case TYPES.GET_ALL_EXCLUSIVES_FAILURE: {
      const message = "Failed to retrieve exclusive access data.";
      return reduceResult(message, ALERT_STATUS.ERROR);
    }
    case TYPES.POST_EXCLUSIVE_ACCESS_SUCCESS: {
      const data = action.payload.data;
      const exclusiveAccessTitle = exclusiveAccessService.getExclusiveAccessTitle(data);
      const message = action.payload.isNew
        ? `New Exclusive Access successfully created: ${exclusiveAccessTitle}.`
        : `Exclusive Access successfully updated: ${exclusiveAccessTitle}.`;

      return reduceResult(message, ALERT_STATUS.SUCCESS);
    }
    case TYPES.POST_EXCLUSIVE_ACCESS_FAILURE: {
      const message =
        action.payload && action.payload.isNew
          ? `Failed to create a new exclusive access.`
          : `Failed to update exclusive access.`;

      return reduceResult(message, ALERT_STATUS.ERROR);
    }
    case TYPES.DELETE_EXCLUSIVE_ACCESS_SUCCESS: {
      const message = `Exclusive access has been deleted successfully.`;
      return reduceResult(message, ALERT_STATUS.SUCCESS);
    }
    case TYPES.DELETE_EXCLUSIVE_ACCESS_FAILURE: {
      const message = `Error as occurred while trying to delete exclusive access.`;
      return reduceResult(message, ALERT_STATUS.ERROR);
    }
    //#endregion Exclusive Access Alerts

    //#region Plan Alerts
    case TYPES.UPDATE_ACCOUNT_SETTINGS_PLANS_SUCCESS: {
      const message = `Account settings have been updated successfully.`;
      return reduceResult(message, ALERT_STATUS.SUCCESS);
    }
    case TYPES.UPDATE_ACCOUNT_SETTINGS_PLANS_FAILURE: {
      const message = `Error as occurred while trying to update account settings.`;
      return reduceResult(message, ALERT_STATUS.ERROR);
    }
    case TYPES.UPDATE_PLAN_SUCCESS: {
      if (action.payload.disableAlert) {
        return { ...state };
      }

      const message = `Plan has been updated successfully.`;
      return reduceResult(message, ALERT_STATUS.SUCCESS);
    }
    case TYPES.UPDATE_PLAN_FAILURE: {
      const message = `Error as occurred while trying to update plan.`;
      return reduceResult(message, ALERT_STATUS.ERROR);
    }
    case TYPES.LAUNCH_PLAN_SUCCESS: {
      const message = `Plan has been launched successfully.`;
      return reduceResult(message, ALERT_STATUS.SUCCESS);
    }
    case TYPES.LAUNCH_PLAN_FAILURE: {
      const message = `Error as occurred while trying to launch plan.`;
      return reduceResult(message, ALERT_STATUS.ERROR);
    }
    case TYPES.ACTIVATE_PLAN_SUCCESS: {
      const message = `Your loyalty plan has been activated successfully.`;
      return reduceResult(message, ALERT_STATUS.SUCCESS);
    }
    case TYPES.ACTIVATE_PLAN_FAILURE: {
      const message = `Error as occurred while trying to activate your loyalty plan.`;
      return reduceResult(message, ALERT_STATUS.ERROR);
    }

    case TYPES.UPDATE_PLAN_LOCALE_SUCCESS: {
      const message = `Language and currency settings have been updated successfully.`;
      return reduceResult(message, ALERT_STATUS.SUCCESS);
    }
    case TYPES.UPDATE_PLAN_LOCALE_FAILURE: {
      const message = `Error as occurred while trying to update language and currency settings.`;
      return reduceResult(message, ALERT_STATUS.ERROR);
    }
    //#endregion Plan Alerts

    //#region Distribution
    case TYPES.UPDATE_PLAN_DISTRIBUTION_SUCCESS: {
      if (action.payload.disableAlert) {
        return { ...state };
      }

      const message = `Distribution settings have been updated successfully.`;
      return reduceResult(message, ALERT_STATUS.SUCCESS);
    }

    case TYPES.UPDATE_PLAN_DISTRIBUTION_FAILURE: {
      const message = `Error as occurred while trying to save distribution settings.`;
      return reduceResult(message, ALERT_STATUS.ERROR);
    }
    //#endregion Distribution

    //#region OnBoarding Alerts
    case TYPES.UPDATE_PLAN_DISTRIBUTION_SUCCESS: {
      // const message = `Tier creation settings have been updated successfully`;
      // return reduceResult(message, ALERT_STATUS.SUCCESS);
      return { ...state };
    }
    case TYPES.UPDATE_PLAN_DISTRIBUTION_FAILURE: {
      const message = `Error as occurred while trying to update tier creation settings.`;
      return reduceResult(message, ALERT_STATUS.ERROR);
    }
    //#endregion
    default:
      return state;
  }
}
