import axios from "axios";

import { isDev } from "../helpers/envHelper";

export default class actionApi {
  static async getBroadcastEmails() {
    if (isDev) {
      return ["1", "2", "3"].map((i) => {
        return {
          id: i,
          name: `mock email ${i}`
        };
      });
    }
    const response = await axios.get(`/emails-broadcast`);
    return response.data;
  }
}
