import validate from "validate.js";
import { UPDATE_POINTS_TYPE, POINTS_UPDATE_TYPE } from "../constants/memberProfileConstants";
import { getMessage } from "../messages";
import planService from "../services/planService";

validate.validators.minPointsForRemove = function (value, options, key, fields) {
  const { message, messageLoyaltyPoints, contactPoints } = options;

  let hasEnoughPoints = true;

  if (fields.pointsUpdateType === POINTS_UPDATE_TYPE.ADD_POINTS) {
    return undefined;
  }

  switch (fields.pointsType) {
    case UPDATE_POINTS_TYPE.LOYALTY:
      hasEnoughPoints = value <= contactPoints.status && value <= contactPoints.balance;
      break;
    case UPDATE_POINTS_TYPE.STATUS:
      hasEnoughPoints = value <= contactPoints.status;
      break;
    case UPDATE_POINTS_TYPE.BALANCE:
      hasEnoughPoints = value <= contactPoints.balance;
      break;
    default:
      hasEnoughPoints = true;
  }

  if (!hasEnoughPoints) {
    return fields.pointsType === UPDATE_POINTS_TYPE.LOYALTY ? messageLoyaltyPoints : message;
  }

  return undefined;
};

validate.validators.minSumForRemove = function (value, options, key, fields) {
  const { message, messageCashback, contactPoints } = options;

  if (fields.pointsUpdateType === POINTS_UPDATE_TYPE.ADD_POINTS) {
    return undefined;
  }

  let hasEnoughMoney = true;
  switch (fields.pointsType) {
    case UPDATE_POINTS_TYPE.CASHBACK:
      hasEnoughMoney = value <= contactPoints.cashback;
      break;
    case UPDATE_POINTS_TYPE.STATUS:
      hasEnoughMoney = value <= contactPoints.status;
      break;
    default:
      hasEnoughMoney = true;
  }

  if (!hasEnoughMoney) {
    return fields.pointsType === UPDATE_POINTS_TYPE.CASHBACK ? messageCashback : message;
  }

  return undefined;
};

export default class memberPointsUpdateValidator {
  constructor(contactPoints, plan) {
    this.constraints = {
      confirmed: {
        isTrue: {
          message: getMessage("memberPointsUpdateValidator.confirmed.isTrue")
        }
      },
      reason: {
        presence: {
          allowEmpty: false,
          message: getMessage("memberPointsUpdateValidator.reason.presence")
        }
      },
      pointsValue: {
        presence: {
          allowEmpty: false,
          message: getMessage("memberPointsUpdateValidator.pointsValue.presence")
        },
        numericality: {
          onlyInteger: !planService.isSpendPlan(plan) ? true : false,
          notInteger: getMessage("memberPointsUpdateValidator.pointsValue.numericality.notInteger"),
          greaterThan: 0,
          notGreaterThan: getMessage("memberPointsUpdateValidator.pointsValue.numericality.notGreaterThan"),
          lessThan: 99999,
          notLessThan: getMessage("memberPointsUpdateValidator.pointsValue.numericality.notLessThan")
        },
        minPointsForRemove: {
          contactPoints,
          messageLoyaltyPoints: getMessage(
            "memberPointsUpdateValidator.pointsValue.minPointsForRemove.messageLoyaltyPoints"
          ),
          message: getMessage("memberPointsUpdateValidator.pointsValue.minPointsForRemove.message")
        },
        minSumForRemove: {
          contactPoints,
          message: getMessage("memberPointsUpdateValidator.pointsValue.minSumForRemove.message"),
          messageCashback: getMessage("memberPointsUpdateValidator.pointsValue.minSumForRemove.messageCashback")
        }
      }
    };
  }

  validate = (fields) => {
    return validate(fields, this.constraints);
  };
}
