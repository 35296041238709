import xid from "xid-js";
import {
  BENEFIT_TYPE_ENUM,
  PURCHASE_CURRENCY_TOKEN,
  PURCHASE_POINTS_TOKEN,
  TIER_TYPE_ENUM
} from "../constants/tierConstants";

import { getNameByValueFromOptions } from "../helpers/formHelper";
import { getCurrencySymbol } from "../helpers/currencyHelper";
import planService from "./planService";
import { getMessage } from "../messages";

export default class tierService {
  static sortTiers = (tiers) => {
    const result = tiers
      .filter((tier) => tier.tierType !== TIER_TYPE_ENUM.INVITE)
      .sort((a, b) => {
        if (a.tierType === TIER_TYPE_ENUM.INVITE) {
          return 1;
        } else {
          return a.settings.points - b.settings.points;
        }
      });

    tiers.forEach((tier) => {
      if (tier.tierType === TIER_TYPE_ENUM.INVITE) {
        result.push(tier);
      }
    });

    return result;
  };

  static isRegularTier = (tier) => tier.tierType === TIER_TYPE_ENUM.REGULAR;

  static isInviteTier = (tier) => tier.tierType === TIER_TYPE_ENUM.INVITE;

  static getFixedBenefitDefaultData = () => {
    return {
      name: "",
      type: BENEFIT_TYPE_ENUM.ONE_TIME_VOUCHER,
      eventActionId: undefined,
      custom: undefined
    };
  };

  static getPurchaseBenefitsDefaultData = (tier, prevTier, currencies, plan) => {
    const symbol = getCurrencySymbol(currencies[0]);
    const isMultiCurrency = currencies.length > 1;

    let pointsBenefitTitle = !planService.isSpendPlan(plan)
      ? getMessage("benefitDefaultData.pointsBenefitTitle.points")
      : getMessage("benefitDefaultData.pointsBenefitTitle.spend");
    let singleCurrencyText = !planService.isSpendPlan(plan)
      ? getMessage("benefitDefaultData.singleCurrencyText.points", { symbol })
      : getMessage("benefitDefaultData.singleCurrencyText.spend");
    let pointsBenefitText = isMultiCurrency
      ? getMessage("benefitDefaultData.pointsBenefitText.multiCurrency")
      : singleCurrencyText;
    let pointsCatalogBenefitTitle = "";
    let pointsCatalogBenefitText = "";

    if (prevTier && prevTier.purchaseBenefits) {
      pointsBenefitTitle = prevTier.purchaseBenefits.pointsBenefit.title || pointsBenefitTitle;
      pointsBenefitText = prevTier.purchaseBenefits.pointsBenefit.text || pointsBenefitText;
      pointsCatalogBenefitTitle = prevTier.purchaseBenefits.pointsCatalogBenefits[0].title || pointsCatalogBenefitTitle;
      pointsCatalogBenefitText = prevTier.purchaseBenefits.pointsCatalogBenefits[0].text || pointsCatalogBenefitText;
    }

    let pointsRates = {};
    currencies.forEach((currency) => {
      if (!pointsRates[currency]) {
        pointsRates[currency] = {};
      }
    });

    if (planService.isSpendPlan(plan)) {
      pointsRates[currencies[0]] = { points: 1, spent: 1 };
    }

    return {
      pointsBenefit: {
        pointsRates,
        title: pointsBenefitTitle,
        text: pointsBenefitText,
        icon: tierService.getDefaultPurchaseBenefitIcon()
      },
      pointsCatalogBenefits: [
        tierService.getPurchaseCatalogBenefitData({
          title: pointsBenefitTitle,
          text: pointsBenefitText,
          icon: tierService.getDefaultPurchaseBenefitIcon(),
          currencies: currencies
        })
      ]
    };
  };

  static getPurchaseCatalogBenefitData = ({ pointsRates, title, text, icon, currencies }) => {
    if (!pointsRates) {
      pointsRates = {};
      currencies.forEach((currency) => {
        if (!pointsRates[currency]) {
          pointsRates[currency] = {};
        }
      });
    }

    return {
      id: xid.next(),
      pointsRates,
      title,
      text,
      icon
    };
  };

  static getBenefitsConnectedToReward = (rewardId, fixedBenefits) => {
    let benefits = [];

    fixedBenefits.forEach((benefit) => {
      const hasReward = Object.keys(benefit.tiers).some((tierId) => {
        const tier = benefit.tiers[tierId];
        return tier.rewardId === rewardId || (tier.exclusiveAccessIds && tier.exclusiveAccessIds.includes(rewardId));
      });
      if (hasReward) {
        benefits.push(benefit.name);
      }
    });

    return benefits;
  };

  static isBenefitsHaveAction = (actionId, fixedBenefits) => {
    return (
      fixedBenefits &&
      fixedBenefits.some(
        (benefit) => benefit.type === BENEFIT_TYPE_ENUM.EVENT_ACTION && benefit.eventActionId === actionId
      )
    );
  };

  static getDefaultPurchaseBenefitIcon = () => {
    return {
      displayName: "Shopping Cart",
      fileName: "shopping-cart"
    };
  };

  static getPointsRate = ({ purchaseBenefit, currencyCode }) => {
    if (!purchaseBenefit.pointsRates) {
      return null;
    }

    const pointsRate = purchaseBenefit.pointsRates[currencyCode];

    if (!pointsRate || !pointsRate.points || !pointsRate.spent) {
      return null;
    }

    return pointsRate;
  };

  static isPurchaseBenefitCatalogSelected = (catalogBenefit) => {
    const hasCategories = !!catalogBenefit.categories && catalogBenefit.categories.length > 0;
    const hasBrands = catalogBenefit.brands && catalogBenefit.brands.length > 0;

    return hasBrands || hasCategories;
  };

  static hasZeroPointsRate = (benefit, currencies) => {
    const pointsRates = benefit.pointsRates;
    return currencies.some((currency) => {
      return pointsRates[currency] && pointsRates[currency].points === 0;
    });
  };

  static hasPointsRateValue = (benefit, currencies) => {
    const pointsRates = benefit.pointsRates;
    return currencies.some((currency) => {
      return pointsRates[currency] && pointsRates[currency].points > 0;
    });
  };

  static areAllPurchasePointsZero(tier, currencies) {
    if (!tier.purchaseBenefits) {
      return false;
    }

    if (!tierService.hasZeroPointsRate(tier.purchaseBenefits.pointsBenefit, currencies)) {
      return false;
    }

    return !tier.purchaseBenefits.pointsCatalogBenefits.some((benefit) => {
      return tierService.hasPointsRateValue(benefit, currencies) && !tierService.hasZeroPointsRate(benefit, currencies);
    });
  }

  static hasPurchaseBenefitToken = (text) => {
    return !!text && text.indexOf(PURCHASE_POINTS_TOKEN.token) > -1;
  };

  static isLastRegularTier = (tierId, tiers) => {
    tiers = tiers.filter((tier) => tierService.isRegularTier(tier));
    return tiers.length > 0 && tiers[tiers.length - 1].id === tierId;
  };

  static isFirstRegularTier = (tierId, tiers) => {
    tiers = tiers.filter((tier) => tierService.isRegularTier(tier));
    return tierId ? tiers.length > 0 && tiers[0].id === tierId : tiers.length == 0;
  };

  static isInviteTierVisible = (tier) => tierService.isInviteTier(tier) && tier.visible;

  static getPointsPostfixLabel = (plan, tier) => {
    if (planService.isSpendPlan(plan)) return getMessage("planSettingsGeneral.pointsName.spend.placeholder");
    return getMessage(`tierSetupGeneral.tierPoints.postfixLabel.${tier.pointsType}`);
  };
}
