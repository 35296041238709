import * as actionsEn from "./actions/en.json";
import * as dashboardEn from "./dashboard/en.json";
import * as exclusiveAccessEn from "./exclusiveAccess/en.json";
import * as generalEn from "./general/en.json";
import * as loyaltyManagementEn from "./loyaltyManagement/en.json";
import * as memberProfileEn from "./memberProfile/en.json";
import * as securitySettingsEn from "./securitySettings/en.json";
import * as tiersEn from "./tiers/en.json";
import * as voucherEn from "./voucher/en.json";

import { actionsEnLegacy } from "./actions/en.legacy.js";
import { exclusiveAccessEnLegacy } from "./exclusiveAccess/en.legacy.js";
import { generalEnLegacy } from "./general/en.legacy.js";
import { memberProfileEnLegacy } from "./memberProfile/en.legacy.js";
import { securitySettingsEnLegacy } from "./securitySettings/en.legacy.js";
import { tiersEnLegacy } from "./tiers/en.legacy.js";
import { voucherEnLegacy } from "./voucher/en.legacy.js";

import * as actionsDe from "./actions/de.json";
import * as dashboardDe from "./dashboard/de.json";
import * as exclusiveAccessDe from "./exclusiveAccess/de.json";
import * as generalDe from "./general/de.json";
import * as loyaltyManagementDe from "./loyaltyManagement/de.json";
import * as memberProfileDe from "./memberProfile/de.json";
import * as securitySettingsDe from "./securitySettings/de.json";
import * as tiersDe from "./tiers/de.json";
import * as voucherDe from "./voucher/de.json";

import { actionsDeLegacy } from "./actions/de.legacy.js";
import { exclusiveAccessDeLegacy } from "./exclusiveAccess/de.legacy.js";
import { generalDeLegacy } from "./general/de.legacy.js";
import { memberProfileDeLegacy } from "./memberProfile/de.legacy.js";
import { securitySettingsDeLegacy } from "./securitySettings/de.legacy.js";
import { tiersDeLegacy } from "./tiers/de.legacy.js";
import { voucherDeLegacy } from "./voucher/de.legacy.js";

export const en = {
  ...actionsEnLegacy,
  ...actionsEn,
  ...dashboardEn,
  ...exclusiveAccessEnLegacy,
  ...exclusiveAccessEn,
  ...generalEnLegacy,
  ...generalEn,
  ...loyaltyManagementEn,
  ...memberProfileEnLegacy,
  ...memberProfileEn,
  ...securitySettingsEnLegacy,
  ...securitySettingsEn,
  ...tiersEnLegacy,
  ...tiersEn,
  ...voucherEnLegacy,
  ...voucherEn
};

export const de = {
  ...actionsDeLegacy,
  ...actionsDe,
  ...dashboardDe,
  ...exclusiveAccessDeLegacy,
  ...exclusiveAccessDe,
  ...generalDeLegacy,
  ...generalDe,
  ...loyaltyManagementDe,
  ...memberProfileDeLegacy,
  ...memberProfileDe,
  ...securitySettingsDeLegacy,
  ...securitySettingsDe,
  ...tiersDeLegacy,
  ...tiersDe,
  ...voucherDeLegacy,
  ...voucherDe
};
