import React, { Fragment } from "react";
import PropTypes from "prop-types";

import InputNumberField from "../components/InputNumberField";
import Switch from "../components/Switch";
import AccordionItem from "../components/AccordionItem";

import { getErrorMessage } from "../validators";

const RewardSetupValueForTiers = (props) => {
  const {
    tiers,
    tierValueProp,
    onRewardInputChange,
    fieldNamePrefix,
    maxPointsPerTier,
    renderEnableSwitch,
    errors,
    onAccordionStateChange,
    isRewardForFree,
    postfixLabel,
    disabled
  } = props;

  const renderSingleTierItem = (tier) => {
    let attr = {};
    if (maxPointsPerTier) {
      attr["max"] = maxPointsPerTier[tier.tierId] ? maxPointsPerTier[tier.tierId].points : 0;
    }

    return (
      <Fragment>
        <InputNumberField
          key={tier.tierId}
          value={tier[tierValueProp] && tier[tierValueProp].points}
          name={`${fieldNamePrefix}.${tier.tierId}.points`}
          onChange={(event, customValue) => onRewardInputChange(event, customValue, tier.tierId, "points")}
          postfixLabel={postfixLabel}
          noMargin={true}
          disabled={disabled}
          errorMsg={getErrorMessage(errors, `${fieldNamePrefix}.${tier.tierId}.points`)}
          {...attr}
        />
      </Fragment>
    );
  };

  const renderTierSwitch = (tier) => {
    return (
      <Switch
        name={`${fieldNamePrefix}.${tier.tierId}.pointsEnabled`}
        enabled={tier[tierValueProp].pointsEnabled}
        disabled={disabled}
        onChange={(event, customEvent) => onRewardInputChange(event, customEvent, tier.tierId, "pointsEnabled")}
      />
    );
  };

  const renderTierItems = () => {
    const tierItems = [];

    tiers.forEach((tier) => {
      const tierId = tier.tierId;

      let attr = {};
      if (renderEnableSwitch) {
        attr.renderSwitch = renderTierSwitch(tier);
      }

      if (!isRewardForFree) {
        tierItems.push(
          <AccordionItem
            title={tier.name}
            name="accordion-tier"
            id={tierId}
            key={`accordion-item-${tierId}`}
            open={tier.accordionOpenState}
            onStateChange={(id, newState) => {
              onAccordionStateChange(id, newState);
            }}
            {...attr}
          >
            {renderSingleTierItem(tier)}
          </AccordionItem>
        );
      } else {
        tierItems.push(
          <tr key={`tr-item-${tierId}`}>
            <td>{tier.name}</td>
            <td className="text-align-right">{attr.renderSwitch}</td>
          </tr>
        );
      }
    });

    return tierItems;
  };

  const renderTiers = () => {
    if (isRewardForFree) {
      return (
        <table className="e-table e-table-condensed e-table-baselinealigned e-table-bordered" data-e-version="2">
          <tbody>{renderTierItems()}</tbody>
        </table>
      );
    } else {
      return <div className="l-reward-setup-value e-accordion">{renderTierItems()}</div>;
    }
  };

  return renderTiers();
};

RewardSetupValueForTiers.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
  onInputChange: PropTypes.func,
  onRewardInputChange: PropTypes.func,
  tiers: PropTypes.array.isRequired,
  tierValueProp: PropTypes.string,
  maxPointsPerTier: PropTypes.object,
  renderEnableSwitch: PropTypes.bool,
  errors: PropTypes.object,
  onAccordionStateChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default RewardSetupValueForTiers;
