import React from "react";
import PropTypes from "prop-types";

import ExclusiveAccessCustomTemplateEditor from "./ExclusiveAccessCustomTemplateEditor";
import ExclusiveAccessCustomHtmlEditor from "./ExclusiveAccessCustomHtmlEditor";
import LoaderOverlay from "../../components/LoaderOverlay";
import SelectField from "../../components/SelectFieldV2";

import { EDITOR_TYPE_ENUM, BLOCKING_EDITOR_MODE_ENUM } from "../../constants/rewardConstants";
import { LANGUAGE_OPTIONS } from "../../constants/languageConstants";

import exclusiveAccessApi from "../../api/exclusiveAccessApi";
import MemberModeTabs from "./MemberModeTabs";
import DeviceModeTabs from "./DeviceModeTabs";

class ExclusiveAccessEditor extends React.Component {
  constructor(props) {
    super(props);

    const htmlContent = {};
    htmlContent[BLOCKING_EDITOR_MODE_ENUM.MEMBER] = "";
    htmlContent[BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER] = "";
    htmlContent[BLOCKING_EDITOR_MODE_ENUM.MEMBER_MOBILE] = "";
    htmlContent[BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER_MOBILE] = "";

    let htmlContentML;
    if (props.hasMultiLanguage) {
      htmlContentML = {};
      props.languages.forEach((lang) => {
        htmlContentML[lang] = {};
        htmlContentML[lang][BLOCKING_EDITOR_MODE_ENUM.MEMBER] = "";
        htmlContentML[lang][BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER] = "";
        htmlContentML[lang][BLOCKING_EDITOR_MODE_ENUM.MEMBER_MOBILE] = "";
        htmlContentML[lang][BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER_MOBILE] = "";
      });
    }

    const editMode =
      props.editorType === EDITOR_TYPE_ENUM.TEMPLATE
        ? {
            isForGeneral: true,
            isForMember: false,
            isForNonMember: false,
            isForMobile: false
          }
        : {
            isForMember: true,
            isForMobile: false
          };

    this.state = {
      htmlContent,
      htmlContentML,
      editMode,
      editorLinks: { ...props.initialEditorLinks },
      editorStyles: { ...props.initialEditorStyles },
      editorContent: { ...props.initialEditorContent },
      loading: false,
      selectedLang: props.hasMultiLanguage ? props.primaryLanguage : ""
    };
  }

  get currentMode() {
    if (this.props.editorType === EDITOR_TYPE_ENUM.TEMPLATE) {
      const { isForGeneral, isForMember, isForNonMember, isForMobile } = { ...this.state.editMode };

      if (isForGeneral && !isForMobile) return BLOCKING_EDITOR_MODE_ENUM.GENERAL;
      if (isForGeneral && isForMobile) return BLOCKING_EDITOR_MODE_ENUM.GENERAL_MOBILE;
      if (isForMember && !isForMobile) return BLOCKING_EDITOR_MODE_ENUM.MEMBER;
      if (isForMember && isForMobile) return BLOCKING_EDITOR_MODE_ENUM.MEMBER_MOBILE;
      if (isForNonMember && !isForMobile) return BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER;
      if (isForNonMember && isForMobile) return BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER_MOBILE;

      return "";
    }
    const { isForMember, isForMobile } = { ...this.state.editMode };

    if (isForMember && !isForMobile) return BLOCKING_EDITOR_MODE_ENUM.MEMBER;
    if (isForMember && isForMobile) return BLOCKING_EDITOR_MODE_ENUM.MEMBER_MOBILE;
    if (!isForMember && !isForMobile) return BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER;
    if (!isForMember && isForMobile) return BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER_MOBILE;

    return "";
  }

  componentDidMount() {
    if (this.props.editorType === EDITOR_TYPE_ENUM.HTML) {
      const { htmlContent, htmlContentML } = { ...this.state };
      if (this.props.hasMultiLanguage) {
        this.props.languages.forEach((lang) => {
          this.loadHtmlData({ exclusiveAccessId: this.props.exclusiveAccessId, htmlContent, htmlContentML, lang });
        });
      } else {
        this.loadHtmlData({ exclusiveAccessId: this.props.exclusiveAccessId, htmlContent, htmlContentML });
      }
    }
  }

  getInitialEditorContent() {
    const { selectedLang } = this.state;
    return !!selectedLang
      ? { ...this.props.initialEditorContentML[selectedLang] }
      : { ...this.props.initialEditorContent };
  }

  getInitialEditorLinks() {
    const { selectedLang } = this.state;
    return !!selectedLang ? { ...this.props.initialEditorLinksML[selectedLang] } : { ...this.props.initialEditorLinks };
  }

  getContentHtml() {
    const { htmlContent, htmlContentML, selectedLang } = { ...this.state };
    if (!!selectedLang) {
      return htmlContentML[selectedLang][this.currentMode];
    } else {
      return htmlContent[this.currentMode];
    }
  }

  showLoading() {
    this.setState({
      loading: true
    });
  }

  hideLoading() {
    this.setState({
      loading: false
    });
  }

  saveHtml(reward) {
    const { htmlContent, htmlContentML } = { ...this.state };
    this.props.postHtmlFunc({
      exclusiveAccess: reward,
      htmlContent,
      htmlContentML
      // /specificMode: this.currentMode
    });
  }

  validateHtmlContent() {
    if (this.props.editorType === EDITOR_TYPE_ENUM.TEMPLATE) {
      return true;
    }

    let { htmlContent, htmlContentML } = { ...this.state };
    const { hasMultiLanguage, primaryLanguage } = this.props;

    if (hasMultiLanguage) {
      htmlContent = htmlContentML[primaryLanguage];
    }

    return Object.keys(htmlContent).every((mode) => !!htmlContent[mode]);
  }

  async loadHtmlData({ exclusiveAccessId, htmlContent, htmlContentML, lang }) {
    if (exclusiveAccessId === undefined) {
      this.setState({
        htmlContent,
        htmlContentML
      });
      return;
    }
    this.showLoading();

    let promises = [];
    promises[0] = exclusiveAccessApi.getExclusiveAccessHtml({
      exclusiveAccessId,
      mode: BLOCKING_EDITOR_MODE_ENUM.MEMBER,
      lang
    });

    promises[1] = exclusiveAccessApi.getExclusiveAccessHtml({
      exclusiveAccessId,
      mode: BLOCKING_EDITOR_MODE_ENUM.MEMBER_MOBILE,
      lang
    });

    promises[2] = exclusiveAccessApi.getExclusiveAccessHtml({
      exclusiveAccessId,
      mode: BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER,
      lang
    });

    promises[3] = exclusiveAccessApi.getExclusiveAccessHtml({
      exclusiveAccessId,
      mode: BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER_MOBILE,
      lang
    });

    Promise.all(promises)
      .then((response) => {
        if (!!lang) {
          htmlContentML[lang][BLOCKING_EDITOR_MODE_ENUM.MEMBER] = response[0].data;
          htmlContentML[lang][BLOCKING_EDITOR_MODE_ENUM.MEMBER_MOBILE] = response[1].data;
          htmlContentML[lang][BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER] = response[2].data;
          htmlContentML[lang][BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER_MOBILE] = response[3].data;
        } else {
          htmlContent[BLOCKING_EDITOR_MODE_ENUM.MEMBER] = response[0].data;
          htmlContent[BLOCKING_EDITOR_MODE_ENUM.MEMBER_MOBILE] = response[1].data;
          htmlContent[BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER] = response[2].data;
          htmlContent[BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER_MOBILE] = response[3].data;
        }
      })
      .catch((e) => {})
      .finally(() => {
        this.hideLoading();
      });

    this.setState({
      htmlContent,
      htmlContentML
    });
  }

  onMemberModeChange(mode) {
    const { editMode } = this.state;
    const { editorType } = this.props;

    if (editorType === EDITOR_TYPE_ENUM.TEMPLATE) {
      this.setState({
        editMode: {
          ...editMode,
          isForGeneral:
            mode === BLOCKING_EDITOR_MODE_ENUM.GENERAL || mode === BLOCKING_EDITOR_MODE_ENUM.GENERAL_MOBILE
              ? true
              : false,
          isForMember:
            mode === BLOCKING_EDITOR_MODE_ENUM.MEMBER || mode === BLOCKING_EDITOR_MODE_ENUM.MEMBER_MOBILE
              ? true
              : false,
          isForNonMember:
            mode === BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER || mode === BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER_MOBILE
              ? true
              : false
        }
      });
    } else {
      this.setState({
        editMode: {
          ...editMode,
          isForMember:
            mode === BLOCKING_EDITOR_MODE_ENUM.MEMBER || mode === BLOCKING_EDITOR_MODE_ENUM.MEMBER_MOBILE ? true : false
        }
      });
    }
  }

  onDeviceModeChange(isMobile) {
    const { editMode } = { ...this.state };
    this.setState({
      editMode: {
        ...editMode,
        isForMobile: isMobile
      }
    });
  }

  onLanguageChange(event) {
    const selectedLang = event.target.value;
    if (this.props.editorType === EDITOR_TYPE_ENUM.TEMPLATE) {
      const editorContent = { ...this.props.initialEditorContentML[selectedLang] };
      const editorLinks = { ...this.props.initialEditorLinksML[selectedLang] };
      this.setState({
        selectedLang,
        editorContent,
        editorLinks
      });
    } else {
      this.setState({
        selectedLang
      });
    }
  }

  updateHtmlContent({ contentObj, lang, memberHtml, memberMobileHtml, nonMemberHtml, nonMemberMobileHtml }) {
    if (!!lang) {
      contentObj[lang][BLOCKING_EDITOR_MODE_ENUM.MEMBER] = memberHtml;
      contentObj[lang][BLOCKING_EDITOR_MODE_ENUM.MEMBER_MOBILE] = memberMobileHtml;
      contentObj[lang][BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER] = nonMemberHtml;
      contentObj[lang][BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER_MOBILE] = nonMemberMobileHtml;
    } else {
      contentObj[BLOCKING_EDITOR_MODE_ENUM.MEMBER] = memberHtml;
      contentObj[BLOCKING_EDITOR_MODE_ENUM.MEMBER_MOBILE] = memberMobileHtml;
      contentObj[BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER] = nonMemberHtml;
      contentObj[BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER_MOBILE] = nonMemberMobileHtml;
    }
  }

  onTemplateHtmlContentChange(memberHtml, memberMobileHtml, nonMemberHtml, nonMemberMobileHtml) {
    let { htmlContent, htmlContentML, selectedLang } = { ...this.state };
    if (!!selectedLang) {
      this.updateHtmlContent({
        contentObj: htmlContentML,
        lang: selectedLang,
        memberHtml,
        memberMobileHtml,
        nonMemberHtml,
        nonMemberMobileHtml
      });
      if (selectedLang === this.props.primaryLanguage) {
        this.updateHtmlContent({
          contentObj: htmlContent,
          memberHtml,
          memberMobileHtml,
          nonMemberHtml,
          nonMemberMobileHtml
        });
      }
    } else {
      this.updateHtmlContent({
        contentObj: htmlContent,
        memberHtml,
        memberMobileHtml,
        nonMemberHtml,
        nonMemberMobileHtml
      });
    }

    this.setState({
      htmlContent,
      htmlContentML
    });

    this.props.onHtmlChange();
  }

  onHtmlContentChange(content) {
    let { htmlContent, htmlContentML, selectedLang } = { ...this.state };

    if (!!selectedLang) {
      htmlContentML[selectedLang][this.currentMode] = content;
      if (selectedLang === this.props.primaryLanguage) {
        htmlContent[this.currentMode] = content;
      }
    } else {
      htmlContent[this.currentMode] = content;
    }

    this.setState({
      htmlContent,
      htmlContentML
    });

    this.props.onHtmlChange();
  }

  //if new exclusive - fill htmlContent object so after saving the api will create the html files
  onHtmlContentChangeNewExclusive(memberHtml, memberMobileHtml, nonMemberHtml, nonMemberMobileHtml) {
    let { htmlFiles } = { ...this.props };
    if (!htmlFiles) {
      return;
    }

    // if blocking html was already saved, return
    const hasContent = Object.keys(htmlFiles).some((key) => !!htmlFiles[key]);
    if (hasContent) {
      return;
    }

    let { htmlContent, htmlContentML } = { ...this.state };

    this.updateHtmlContent({
      contentObj: htmlContent,
      memberHtml,
      memberMobileHtml,
      nonMemberHtml,
      nonMemberMobileHtml
    });

    if (this.props.hasMultiLanguage) {
      this.props.languages.forEach((lang) => {
        this.updateHtmlContent({
          contentObj: htmlContentML,
          lang,
          memberHtml,
          memberMobileHtml,
          nonMemberHtml,
          nonMemberMobileHtml
        });
      });
    }

    this.setState({
      htmlContent,
      htmlContentML
    });
  }

  onEditorStyleChange(editorStyleId, value) {
    let { editorStyles } = { ...this.state };
    editorStyles[editorStyleId] = value;
    this.setState({
      editorStyles
    });

    this.props.onTemplateStyleChange(editorStyles);
  }

  onEditorContentTextChange(key, value) {
    let { editorContent } = { ...this.state };
    if (this.currentMode === BLOCKING_EDITOR_MODE_ENUM.GENERAL) {
      for (const mode in BLOCKING_EDITOR_MODE_ENUM) {
        editorContent[BLOCKING_EDITOR_MODE_ENUM[mode]].texts[key] = value;
      }
    } else {
      editorContent[this.currentMode].texts[key] = value;
      if (this.currentMode === BLOCKING_EDITOR_MODE_ENUM.MEMBER) {
        editorContent[BLOCKING_EDITOR_MODE_ENUM.MEMBER_MOBILE].texts[key] = value;
      }
      if (this.currentMode === BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER) {
        editorContent[BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER_MOBILE].texts[key] = value;
      }
    }
    this.setState({
      editorContent
    });

    this.props.onTemplateContentChange("content", editorContent, this.state.selectedLang);
  }

  onApplyLink(newLinks) {
    const { editorLinks, editMode } = this.state;
    const { isForGeneral, isForMember, isForNonMember } = editMode;

    let newEditorLinks = {};

    if (isForGeneral) {
      newEditorLinks = {
        ...editorLinks,
        [BLOCKING_EDITOR_MODE_ENUM.GENERAL]: newLinks,
        [BLOCKING_EDITOR_MODE_ENUM.GENERAL_MOBILE]: newLinks,
        [BLOCKING_EDITOR_MODE_ENUM.MEMBER]: newLinks,
        [BLOCKING_EDITOR_MODE_ENUM.MEMBER_MOBILE]: newLinks,
        [BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER]: newLinks,
        [BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER_MOBILE]: newLinks
      };
    }

    if (isForMember) {
      newEditorLinks = {
        ...editorLinks,
        [BLOCKING_EDITOR_MODE_ENUM.MEMBER]: newLinks,
        [BLOCKING_EDITOR_MODE_ENUM.MEMBER_MOBILE]: newLinks
      };
    }

    if (isForNonMember) {
      newEditorLinks = {
        ...editorLinks,
        [BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER]: newLinks,
        [BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER_MOBILE]: newLinks
      };
    }

    this.setState({
      editorLinks: newEditorLinks
    });

    this.props.onTemplateContentChange("links", newEditorLinks, this.state.selectedLang);
  }

  getMultiLangSelect() {
    if (!this.props.hasMultiLanguage) {
      return null;
    }
    return (
      <SelectField
        options={LANGUAGE_OPTIONS.filter((opt) => this.props.languages.includes(opt.value))}
        value={this.state.selectedLang}
        onChange={this.onLanguageChange.bind(this)}
        extraClassName="no-margin"
      />
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.props.editorType === EDITOR_TYPE_ENUM.TEMPLATE ? (
          <ExclusiveAccessCustomTemplateEditor
            editMode={this.state.editMode}
            currentMode={this.currentMode}
            editorStyles={this.state.editorStyles}
            editorContent={this.getInitialEditorContent()}
            editorLinks={this.getInitialEditorLinks()}
            onHtmlContentChange={this.onTemplateHtmlContentChange.bind(this)}
            onHtmlContentChangeNewExclusive={this.onHtmlContentChangeNewExclusive.bind(this)}
            onEditorStyleChange={this.onEditorStyleChange.bind(this)}
            onEditorContentTextChange={this.onEditorContentTextChange.bind(this)}
            onApplyLink={this.onApplyLink.bind(this)}
            memberModeTabsComp={
              <MemberModeTabs
                editorType={this.props.editorType}
                mode={this.currentMode}
                onChange={this.onMemberModeChange.bind(this)}
              />
            }
            deviceModeTabsComp={
              <DeviceModeTabs
                isMobile={this.state.editMode.isForMobile}
                onChange={this.onDeviceModeChange.bind(this)}
              />
            }
            selectedLang={this.state.selectedLang}
            multiLangSelect={this.getMultiLangSelect()}
            isViewOnly={this.props.isViewOnly}
          />
        ) : (
          <ExclusiveAccessCustomHtmlEditor
            exclusiveAccessId={this.props.exclusiveAccessId}
            onHtmlContentChange={this.onHtmlContentChange.bind(this)}
            content={this.getContentHtml()}
            isViewOnly={this.props.isViewOnly}
            memberModeTabsComp={
              <MemberModeTabs
                editorType={this.props.editorType}
                mode={this.currentMode}
                onChange={this.onMemberModeChange.bind(this)}
              />
            }
            deviceModeTabsComp={
              <DeviceModeTabs
                isMobile={this.state.editMode.isForMobile}
                onChange={this.onDeviceModeChange.bind(this)}
              />
            }
            multiLangSelect={this.getMultiLangSelect()}
          />
        )}
        <LoaderOverlay show={this.state.loading} />
      </React.Fragment>
    );
  }
}

ExclusiveAccessEditor.propTypes = {
  editorType: PropTypes.string.isRequired,
  htmlFiles: PropTypes.object,
  initialEditorStyles: PropTypes.object,
  initialEditorContent: PropTypes.object,
  initialEditorContentML: PropTypes.object,
  initialEditorLinks: PropTypes.object,
  initialEditorLinksML: PropTypes.object,
  exclusiveAccessId: PropTypes.string,
  postHtmlFunc: PropTypes.func.isRequired,
  onHtmlChange: PropTypes.func.isRequired,
  onTemplateStyleChange: PropTypes.func.isRequired,
  onTemplateContentChange: PropTypes.func.isRequired,
  hasMultiLanguage: PropTypes.bool,
  languages: PropTypes.array,
  primaryLanguage: PropTypes.string,
  isViewOnly: PropTypes.bool
};

export default ExclusiveAccessEditor;
