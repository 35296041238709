import React, { Fragment } from "react";
import PropTypes from "prop-types";

import InputField from "../../components/InputField";
import Alert from "../../components/Alert";

import { getMultiLangFieldLabel } from "../../helpers/multiLangHelper";

import { getErrorMessage } from "../../validators";
import { ALERT_STATUS } from "../../constants/alertContants";
import { getMessage } from "../../messages";

const MultiLangTierNameField = ({ onChange, lang, translations, disabled, errors, index }) => {
  return (
    <Fragment>
      {index === 0 && !!errors && (
        <Alert message="Tier name is required for all languages." status={ALERT_STATUS.ERROR} />
      )}

      <InputField
        label={getMultiLangFieldLabel({ label: getMessage("tierSetupGeneral.tierName.label"), lang })}
        value={translations.nameML[lang]}
        name={"nameML"}
        onChange={(event) => onChange(event, lang)}
        disabled={disabled}
        errorMsg={getErrorMessage(errors, `nameML.${lang}`)}
      />
    </Fragment>
  );
};

MultiLangTierNameField.propTypes = {
  onChange: PropTypes.func,
  lang: PropTypes.string,
  translations: PropTypes.object,
  disabled: PropTypes.bool,
  errors: PropTypes.object
};

export default MultiLangTierNameField;
