import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

import "../assets/styles/Card.scss";

import StatusLabel from "../components/StatusLabel";
import { getMessage } from "../messages";

const Card = (props) => {
  const CardIcon = (props) => {
    const { icon, onClick, link, tooltip, disabled, color } = props;

    let attr = {};
    if (disabled) {
      attr["disabled"] = true;
    }

    if (color) {
      attr["color"] = color;
    }

    const buttonClassName = "e-btn e-btn-onlyicon e-btn-borderless";
    let element = <e-icon className="e-clickable" icon={icon} size="small" {...attr} />;

    if (onClick && !disabled) {
      element = (
        <div className={buttonClassName} onClick={onClick}>
          {element}
        </div>
      );
    }

    if (link && !disabled) {
      element = (
        <NavLink to={link} className={buttonClassName}>
          {element}
        </NavLink>
      );
    }

    if (disabled) {
      element = <div className={`${buttonClassName} e-btn-disabled`}>{element}</div>;
    }

    if (tooltip) {
      element = <e-tooltip content={tooltip}>{element}</e-tooltip>;
    }

    return element;
  };

  const renderEditIcon = () => {
    if (!props.editIcon) {
      return null;
    }

    const { disabled, link, tooltip } = props.editIcon;

    return <CardIcon icon="ac-icon_edit" disabled={disabled} link={link} tooltip={tooltip} />;
  };

  const renderDeleteIcon = () => {
    if (!props.deleteIcon) {
      return null;
    }

    const { disabled, onClick, tooltip } = props.deleteIcon;

    return (
      <CardIcon
        icon="ac-icon_delete"
        disabled={disabled}
        onClick={() => !disabled && onClick(props.id, props.title)}
        tooltip={tooltip}
      />
    );
  };

  const renderDuplicateIcon = () => {
    if (!props.duplicateIcon) {
      return null;
    }

    const { disabled, link, tooltip } = props.duplicateIcon;

    return <CardIcon icon="ac-icon_copy" disabled={disabled} link={link} tooltip={tooltip} />;
  };

  const renderPreviewIcon = () => {
    if (!props.previewIcon) {
      return null;
    }

    const { disabled, link, tooltip } = props.previewIcon;

    return <CardIcon icon="eye" disabled={disabled} link={link} tooltip={tooltip} />;
  };

  const renderTopRightIcon = () => {
    if (!props.topRightIcon) {
      return null;
    }
    const { link, tooltip, icon } = props.topRightIcon;

    return <CardIcon icon={icon} link={link} tooltip={tooltip} color="primary" />;
  };

  const renderExtraLabels = (labels) => {
    if (!labels) {
      return null;
    }

    return labels.map((label) => {
      return (
        <div key={label.type} className={`e-label l-card-label-${label.type}`}>
          {label.text}
        </div>
      );
    });
  };

  return (
    <div
      className={classNames("l-card e-card e-card-no_title e-card-emptystate e-card-fluid", props.className, {
        default: props.isDefault
      })}
    >
      <div className="e-card__content text-align-left">
        <div className="e-emptystate__lead text-color-inherit">
          <div className="l-card-top-row e-grid e-grid-between e-grid-vertical_center">
            <div className="l-card-badges e-cell">
              {props.badge ? <div className="e-label">{props.badge}</div> : ""}
              {renderExtraLabels(props.extraLabels)}
            </div>
            {<div className="e-cell">{renderTopRightIcon()}</div>}
          </div>
          <div
            style={!!props.titleIconUrl ? { backgroundImage: `url("${props.titleIconUrl}")` } : null}
            className={classNames("e-cell e-cell-auto l-card-title m-t-10", {
              "has-icon": !!props.titleIconUrl
            })}
          >
            {props.title}
          </div>
        </div>
        <div className="e-emptystate__lead text-color-shade">
          <div>{props.subTitle}</div>
          <div>{props.middleText}</div>
        </div>

        <div className="l-card-actions e-grid e-grid-between">
          <div className="e-cell">
            {props.statusLabel ? (
              <StatusLabel
                label={props.statusLabel.label}
                className={props.statusLabel.className}
                tooltip={props.statusLabel.tooltip}
              />
            ) : (
              <StatusLabel status={props.status} />
            )}
          </div>

          <div className="e-cell">
            <div className="l-card-actions-icons">
              {renderPreviewIcon()}
              {renderEditIcon()}
              {renderDuplicateIcon()}
              {renderDeleteIcon()}
            </div>
          </div>
        </div>
      </div>

      {props.isRoadmap ? (
        <div className="l-roadmap-overlay">
          <div className="e-label e-label-secondary">{getMessage("card.isRoadmap.text")}</div>
        </div>
      ) : null}
    </div>
  );
};

Card.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  titleIconUrl: PropTypes.string,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  middleText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  badge: PropTypes.string,
  extraLabels: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      text: PropTypes.string
    })
  ),
  status: PropTypes.string,
  statusLabel: PropTypes.object,
  isRoadmap: PropTypes.bool,
  deleteIcon: PropTypes.object,
  isDefault: PropTypes.bool,
  editIcon: PropTypes.object,
  previewIcon: PropTypes.object,
  duplicateIcon: PropTypes.object,
  topRightIcon: PropTypes.shape({
    link: PropTypes.string,
    tooltip: PropTypes.string,
    icon: PropTypes.string
  })
};

export default Card;
