export const actionsEnLegacy = {
  "actions.notification.purchase":
    "To activate the purchase action, {helpLink} the action to your contacts. {learnMoreLink}.",
  "actions.notification.engagement":
    "To activate the engagement action, {helpLink} the action to your contacts. {learnMoreLink}.",
  "actions.notification.join":
    "The Default join action will work automatically after it is activated and the plan is launched. Additional Join actions require to be {helpLink}. {learnMoreLink}.",
  "actionSetup.alert.activeMustDeactivate.deactivateText": "deactivate it",
  "actionSetupContentCreation.editSide.title.tooltip.default":
    "A default message replaces the original message tokens if there are system or network errors. The defined {message} {cta text} {cta url} tokens display in the Smart Block and Loyalty Wallet.",
  "actionSetupContentCreation.editSide.title.tooltip.join":
    "The defined {message} {cta text} {cta url} tokens display in the Smart Block and Loyalty Wallet. Default message displays  when there is no defined join message.",
  "actionSetupContentCreation.editSide.title.tooltip.other":
    "The defined {message} {cta text} {cta url} tokens display in the Smart Block and Loyalty Wallet. You can use these tokens separately or with content block in the Campaign Editor.",
  "actionSetupContentCreation.previewSideTitleTooltip.tokens":
    "Example of how {loyalty tokens} displays in the email content block. The actual design can vary depending on your email design.",
  "actionSetupLoyaltyMessagePreviewEmail.notification.tokens":
    "Example of how {loyalty tokens} displays in the email content block. The actual design can vary depending on your email design.",
  "actionSetupRewards.pointsType.popup.message":
    'If you change the Point Type, the Multiple Point option will be removed because multiply reward for tiers {tiers} is not compatible with the points type you choose! If you want to give multiple points you can change the Point Type and select "Multiple Points" again.',
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.zero": "{day}th",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.one": "{day}st",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.two": "{day}nd",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.few": "{day}rd",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.many": "{day}th",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.other": "{day}th",
  "actionSetupScheduling.recurring.summary.week.name.1": "first",
  "actionSetupScheduling.recurring.summary.week.name.2": "second",
  "actionSetupScheduling.recurring.summary.week.name.3": "third",
  "actionSetupScheduling.recurring.summary.week.name.4": "fourth",
  "actionSetupScheduling.recurring.summary.week.name.5": "last"
};
