export const LANGUAGE_MAP = {
  af: "Afrikaans",
  "af-ZA": "Afrikaans (South Africa)",
  "am-ET": "Amharic (Ethiopia)",
  ar: "Arabic",
  "ar-AE": "Arabic (U.A.E.)",
  "ar-BH": "Arabic (Bahrain)",
  "ar-DZ": "Arabic (Algeria)",
  "ar-EG": "Arabic (Egypt)",
  "ar-IQ": "Arabic (Iraq)",
  "ar-JO": "Arabic (Jordan)",
  "ar-KW": "Arabic (Kuwait)",
  "ar-LB": "Arabic (Lebanon)",
  "ar-LY": "Arabic (Libya)",
  "ar-MA": "Arabic (Morocco)",
  "ar-OM": "Arabic (Oman)",
  "ar-QA": "Arabic (Qatar)",
  "ar-SA": "Arabic (Saudi Arabia)",
  "ar-SY": "Arabic (Syria)",
  "ar-TN": "Arabic (Tunisia)",
  "ar-YE": "Arabic (Yemen)",
  "as-IN": "Assamese (India)",
  az: "Azeri",
  be: "Belarusian",
  "be-BY": "Belarusian (Belarus)",
  bg: "Bulgarian",
  "bg-BG": "Bulgarian (Bulgaria)",
  "bn-BD": "Bengali (Bangladesh)",
  "bn-IN": "Bengali (India)",
  "bo-CN": "Tibetan (People's Republic of China)",
  "br-FR": "Breton (France)",
  ca: "Catalan",
  "ca-ES": "Catalan (Catalan)",
  cs: "Czech",
  "cs-CZ": "Czech (Czech Republic)",
  "cy-GB": "Welsh (United Kingdom)",
  da: "Danish",
  "da-DK": "Danish (Denmark)",
  de: "German",
  "de-AT": "German (Austria)",
  "de-CH": "German (Switzerland)",
  "de-DE": "German (Germany)",
  "de-LI": "German (Liechtenstein)",
  "de-LU": "German (Luxembourg)",
  "dsb-DE": "Lower Sorbian (Germany)",
  el: "Greek",
  "el-GR": "Greek (Greece)",
  en: "English",
  "en-AU": "English (Australia)",
  "en-BZ": "English (Belize)",
  "en-CA": "English (Canada)",
  "en-GB": "English (United Kingdom)",
  "en-IE": "English (Ireland)",
  "en-IN": "English (India)",
  "en-JM": "English (Jamaica)",
  "en-MY": "English (Malaysia)",
  "en-NZ": "English (New Zealand)",
  "en-PH": "English (Republic of the Philippines)",
  "en-SG": "English (Singapore)",
  "en-TT": "English (Trinidad and Tobago)",
  "en-US": "English (United States)",
  "en-ZA": "English (South Africa)",
  "en-ZW": "English (Zimbabwe)",
  es: "Spanish",
  "es-419": "Spanish (Latin American)",
  "es-AR": "Spanish (Argentina)",
  "es-BO": "Spanish (Bolivia)",
  "es-CL": "Spanish (Chile)",
  "es-CO": "Spanish (Colombia)",
  "es-CR": "Spanish (Costa Rica)",
  "es-DO": "Spanish (Dominican Republic)",
  "es-EC": "Spanish (Ecuador)",
  "es-ES": "Spanish (Spain)",
  "es-GT": "Spanish (Guatemala)",
  "es-HN": "Spanish (Honduras)",
  "es-MX": "Spanish (Mexico)",
  "es-NI": "Spanish (Nicaragua)",
  "es-PA": "Spanish (Panama)",
  "es-PE": "Spanish (Peru)",
  "es-PR": "Spanish (Puerto Rico)",
  "es-PY": "Spanish (Paraguay)",
  "es-SV": "Spanish (El Salvador)",
  "es-US": "Spanish (United States)",
  "es-UY": "Spanish (Uruguay)",
  "es-VE": "Spanish (Venezuela)",
  et: "Estonian",
  "et-EE": "Estonian (Estonia)",
  eu: "Basque",
  "eu-ES": "Basque (Basque)",
  fa: "Persian",
  "fa-IR": "Persian (Iran)",
  fi: "Finnish",
  "fi-FI": "Finnish (Finland)",
  "fil-PH": "Filipino (Philippines)",
  fo: "Faroese",
  "fo-FO": "Faroese (Faroe Islands)",
  fr: "French",
  "fr-BE": "French (Belgium)",
  "fr-CA": "French (Canada)",
  "fr-CH": "French (Switzerland)",
  "fr-FR": "French (France)",
  "fr-LU": "French (Luxembourg)",
  "fr-MC": "French (Principality of Monaco)",
  "fy-NL": "Frisian (Netherlands)",
  "ga-IE": "Irish (Ireland)",
  "gd-GB": "Scottish Gaelic (United Kingdom)",
  gl: "Galician",
  "gl-ES": "Galician (Galician)",
  "gsw-FR": "Alsatian (France)",
  gu: "Gujarati",
  "gu-IN": "Gujarati (India)",
  he: "Hebrew",
  "he-IL": "Hebrew (Israel)",
  hi: "Hindi",
  "hi-IN": "Hindi (India)",
  hr: "Croatian",
  "hr-BA": "Croatian (Latin) (Bosnia and Herzegovina)",
  "hr-HR": "Croatian (Croatia)",
  "hsb-DE": "Upper Sorbian (Germany)",
  hu: "Hungarian",
  "hu-HU": "Hungarian (Hungary)",
  hy: "Armenian",
  "hy-AM": "Armenian (Armenia)",
  id: "Indonesian",
  "id-ID": "Indonesian (Indonesia)",
  "ig-NG": "Igbo (Nigeria)",
  "ii-CN": "Yi (People's Republic of China)",
  is: "Icelandic",
  "is-IS": "Icelandic (Iceland)",
  it: "Italian",
  "it-CH": "Italian (Switzerland)",
  "it-IT": "Italian (Italy)",
  ja: "Japanese",
  "ja-JP": "Japanese (Japan)",
  ka: "Georgian",
  "ka-GE": "Georgian (Georgia)",
  kk: "Kazakh",
  "kk-KZ": "Kazakh (Kazakhstan)",
  "kl-GL": "Greenlandic (Greenland)",
  "km-KH": "Khmer (Cambodia)",
  kn: "Kannada",
  "kn-IN": "Kannada (India)",
  ko: "Korean",
  "ko-KR": "Korean (Korea)",
  kok: "Konkani",
  "kok-IN": "Konkani (India)",
  ky: "Kyrgyz",
  "ky-KG": "Kyrgyz (Kyrgyzstan)",
  "lb-LU": "Luxembourgish (Luxembourg)",
  "lo-LA": "Lao (Lao P.D.R.)",
  lt: "Lithuanian",
  "lt-LT": "Lithuanian (Lithuania)",
  lv: "Latvian",
  "lv-LV": "Latvian (Latvia)",
  "mi-NZ": "Maori (New Zealand)",
  mk: "Macedonian",
  "mk-MK": "Macedonian (Former Yugoslav Republic of Macedonia)",
  "ml-IN": "Malayalam (India)",
  mn: "Mongolian",
  "mn-MN": "Mongolian (Cyrillic) (Mongolia)",
  mr: "Marathi",
  "mr-IN": "Marathi (India)",
  ms: "Malay",
  "ms-BN": "Malay (Brunei Darussalam)",
  "ms-MY": "Malay (Malaysia)",
  "mt-MT": "Maltese (Malta)",
  no: "Norwegian",
  "nb-NO": "Norwegian, Bokmål (Norway)",
  "ne-NP": "Nepali (Nepal)",
  nl: "Dutch",
  "nl-BE": "Dutch (Belgium)",
  "nl-NL": "Dutch (Netherlands)",
  "nn-NO": "Norwegian, Nynorsk (Norway)",
  "or-IN": "Oriya (India)",
  pa: "Punjabi",
  pl: "Polish",
  "pl-PL": "Polish (Poland)",
  "ps-AF": "Pashto (Afghanistan)",
  pt: "Portuguese",
  "pt-BR": "Portuguese (Brazil)",
  "pt-PT": "Portuguese (Portugal)",
  "rm-CH": "Romansh (Switzerland)",
  ro: "Romanian",
  "ro-RO": "Romanian (Romania)",
  ru: "Russian",
  "ru-RU": "Russian (Russia)",
  "rw-RW": "Kinyarwanda (Rwanda)",
  "sah-RU": "Yakut (Russia)",
  "se-FI": "Sami (Northern) (Finland)",
  "se-NO": "Sami (Northern) (Norway)",
  "se-SE": "Sami (Northern) (Sweden)",
  "si-LK": "Sinhala (Sri Lanka)",
  sk: "Slovak",
  "sk-SK": "Slovak (Slovakia)",
  sl: "Slovenian",
  "sl-SI": "Slovenian (Slovenia)",
  "smn-FI": "Sami (Inari) (Finland)",
  sq: "Albanian",
  "sq-AL": "Albanian (Albania)",
  sr: "Serbian",
  sv: "Swedish",
  "sv-FI": "Swedish (Finland)",
  "sv-SE": "Swedish (Sweden)",
  sw: "Kiswahili",
  "sw-KE": "Kiswahili (Kenya)",
  ta: "Tamil",
  "ta-IN": "Tamil (India)",
  te: "Telugu",
  "te-IN": "Telugu (India)",
  th: "Thai",
  "th-TH": "Thai (Thailand)",
  "tk-TM": "Turkmen (Turkmenistan)",
  tr: "Turkish",
  "tr-TR": "Turkish (Turkey)",
  tt: "Tatar",
  "tt-RU": "Tatar (Russia)",
  "ug-CN": "Uyghur (People's Republic of China)",
  uk: "Ukrainian",
  "uk-UA": "Ukrainian (Ukraine)",
  ur: "Urdu",
  "ur-PK": "Urdu (Islamic Republic of Pakistan)",
  uz: "Uzbek",
  vi: "Vietnamese",
  "vi-VN": "Vietnamese (Vietnam)",
  "wo-SN": "Wolof (Senegal)",
  "xh-ZA": "isiXhosa (South Africa)",
  "yo-NG": "Yoruba (Nigeria)",
  zh: "Chinese",
  "zh-Hans": "Chinese (Simplified)",
  "zh-Hant": "Chinese (Traditional)",
  "zh-CN": "Chinese (People's Republic of China)",
  "zh-HK": "Chinese (Hong Kong S.A.R.)",
  "zh-MO": "Chinese (Macao S.A.R.)",
  "zh-SG": "Chinese (Singapore)",
  "zh-TW": "Chinese (Taiwan)",
  "zu-ZA": "isiZulu (South Africa)"
};

export const LANGUAGE_OPTIONS = Object.keys(LANGUAGE_MAP).map((key) => {
  return {
    value: key,
    name: LANGUAGE_MAP[key]
  };
});
