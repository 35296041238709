import { OPT_IN_ENUM, PLAN_TYPE_ENUM, CONTACT_DISTRIBUTION_CALC_TYPE_ENUM } from "../constants/planConstants";
import rewardService from "./rewardService";
export default class planService {
  static isOptInAutomatic = (value) => {
    return value === OPT_IN_ENUM.AUTOMATIC;
  };

  static getPointsNameObj = (plan) => {
    const planSettings = plan.planSettings;
    return {
      pointsName: planSettings.pointsName,
      pointsNameML: planSettings.pointsNameML,
      singlePointName: planSettings.singlePointName,
      singlePointNameML: planSettings.singlePointNameML,
      cashbackName: planSettings.cashbackName,
      cashbackNameML: planSettings.cashbackNameML
    };
  };

  static getPlanOptions = (plans) => {
    return plans.map((plan) => ({
      value: plan.id,
      name: `${plan.planSettings.name} / ${plan.id}`,
      planType: plan.planType
    }));
  };

  static hasContactDistributionPeriod = (plan) => {
    const { rules } = { ...plan.planSettings };
    return rules.contactDistributionCalcType == CONTACT_DISTRIBUTION_CALC_TYPE_ENUM.BY_PERIOD;
  };

  static hasRedemption = (plan) => {
    return plan.planType === PLAN_TYPE_ENUM.POINTS;
  };

  static isSpendPlan = (plan) => {
    return !!plan?.planType && planService.isSpendPlanType(plan.planType);
  };

  static isBenefitsOnly = (plan) => {
    return plan.planType === PLAN_TYPE_ENUM.POINTS_AND_BENEFITS;
  };

  static isSpendPlanType = (planType) => {
    return planType === PLAN_TYPE_ENUM.SPEND;
  };

  static areSamePlanType = (sourceType, destType) => {
    if (sourceType == destType) return true;
    if (sourceType === PLAN_TYPE_ENUM.POINTS_AND_BENEFITS && destType === PLAN_TYPE_ENUM.POINTS) return true;
    if (sourceType === PLAN_TYPE_ENUM.POINTS && destType === PLAN_TYPE_ENUM.POINTS_AND_BENEFITS) return true;
    return false;
  };

  static isCashbackEnabled = (plan) => {
    if (!this.isSpendPlan(plan)) return false;
    return plan.planSettings.rules?.cashback?.enabled;
  };

  static isLaunched = (plan) => !!plan.planLaunch;
}
