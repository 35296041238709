import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "../assets/styles/LoaderOverlay.scss";

const LoaderOverlay = ({ show, noOpacity }) => {
  return (
    <div
      className={classNames("l-loader-overlay", {
        "e-hidden": !show,
        "l-loader-overlay_no-opacity": noOpacity
      })}
    >
      <e-spinner />
    </div>
  );
};

LoaderOverlay.propTypes = {
  show: PropTypes.bool,
  noOpacity: PropTypes.bool
};

export default LoaderOverlay;
