import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import sanitizeHtml from "sanitize-html";

import { actionType } from "../../types";
import actionService from "../../services/actionService";
import { SPECIAL_TIER_ENUM } from "../../constants/actionConstants";
import "../../assets/styles/ActionSetupLoyaltyMessagePreviewEmail.scss";
import { getMessage } from "../../messages";

const ActionSetupLoyaltyMessagePreviewEmail = (props) => {
  const RewardSection = (props) => {
    const { rewardTier, ctaButton, tierName, contentMode, isFirstBox } = props;

    let { message } = props;
    if (!contentMode) {
      message = `{ ${message} }`;
    }

    return (
      <div
        className={classNames("l-email-loyalty-box", {
          "first-box": isFirstBox,
          "tier-preview-mode": contentMode
        })}
      >
        <table className="box-header">
          <tbody>
            <tr>
              <td>
                <span>{contentMode ? tierName.toUpperCase() : "{ tier }"}</span>
              </td>
              <td>{contentMode ? "STATUS POINTS" : "{ status points }"}</td>
              <td>{contentMode ? "POINTS TO SPEND" : "{ points to spend }"}</td>
            </tr>
          </tbody>
        </table>
        <table className="box-content">
          <tbody>
            <tr>
              <td>
                {contentMode ? (
                  <div className="l-action-message-icon">
                    <e-icon icon="ac-cart_outline" />
                  </div>
                ) : (
                  <span className="l-gray-color-text">{"{ icon }"}</span>
                )}
              </td>
              <td
                className="strech-cell p-r-0"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(props.replaceRewardToken(message, rewardTier))
                }}
              />
              <td className="cta-cell">
                {ctaButton ? contentMode ? <a className="e-btn">{ctaButton}</a> : `{ ${ctaButton} }` : ""}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const getContentByLang = (contentObj) => {
    const { selectedLang } = { ...props };

    if (!contentObj) {
      return {
        message: "",
        ctaButton: ""
      };
    }

    if (!selectedLang) {
      return {
        message: contentObj.blockMessage,
        ctaButton: contentObj.ctaButton
      };
    }

    const isBlockMessageMlEmpty =
      !contentObj.blockMessageML || actionService.isBlockMessageEmpty(contentObj.blockMessageML[selectedLang]);

    return {
      message: isBlockMessageMlEmpty ? contentObj.blockMessage : contentObj.blockMessageML[selectedLang],
      ctaButton: (!!contentObj.ctaButtonML && contentObj.ctaButtonML[selectedLang]) || contentObj.ctaButton
    };
  };

  const getContentForNonMember = () => {
    const { rewardTiers, contentNonMember, defaultJoinAction, masterAction, isForNewMembers } = props;

    let hasReward = false;
    let rewardTier, content;

    if (actionService.isRewardTierEnabled(masterAction, rewardTiers[SPECIAL_TIER_ENUM.NON])) {
      hasReward = true;
      rewardTier = rewardTiers[SPECIAL_TIER_ENUM.NON];
    }

    if (isForNewMembers && actionService.isRewardTierEnabled(masterAction, rewardTiers[SPECIAL_TIER_ENUM.ALL])) {
      hasReward = true;
      rewardTier = rewardTiers[SPECIAL_TIER_ENUM.ALL];
    }

    if (hasReward) {
      content = getContentByLang(contentNonMember);
    } else {
      rewardTier = defaultJoinAction.reward && defaultJoinAction.reward.value[SPECIAL_TIER_ENUM.NON];
      content = getContentByLang(defaultJoinAction.content.nonMember);
    }

    return { rewardTier, message: content.message, ctaButton: content.ctaButton };
  };

  const renderContentPreviewSections = () => {
    const { rewardTiers, contentMember, getTierNameById, defaultMemberContent, masterAction, renderNonMember } = props;
    const getSectionsForTiers = () => {
      let result = [];

      const isUniformEnabled = actionService.isRewardTierEnabled(masterAction, rewardTiers[SPECIAL_TIER_ENUM.ALL]);

      Object.keys(rewardTiers).forEach((tierId) => {
        // if (tierId === SPECIAL_TIER_ENUM.NON && !renderNonMember) return;
        if (tierId === SPECIAL_TIER_ENUM.NON || tierId === SPECIAL_TIER_ENUM.ALL) return;

        const rewardTier = isUniformEnabled ? rewardTiers[SPECIAL_TIER_ENUM.ALL] : rewardTiers[tierId];

        if (tierId !== SPECIAL_TIER_ENUM.ALL) {
          let content = getContentByLang(contentMember);

          // show default action if no reward exists
          if (!actionService.isRewardTierEnabled(masterAction, rewardTier)) {
            content = getContentByLang(defaultMemberContent);
          }

          result.push(
            <RewardSection
              key={tierId}
              tierId={tierId}
              tierName={getTierNameById(tierId)}
              rewardTier={rewardTier}
              message={content.message}
              ctaButton={content.ctaButton}
              {...props}
            />
          );
        }
      });
      return result;
    };

    const getSectionForNonMember = () => {
      if (!renderNonMember) return null;

      const tierId = SPECIAL_TIER_ENUM.NON;

      const { rewardTier, message, ctaButton } = getContentForNonMember();

      return (
        <RewardSection
          key={tierId}
          tierId={tierId}
          tierName={getTierNameById(tierId)}
          rewardTier={rewardTier}
          message={message}
          ctaButton={ctaButton}
          {...props}
        />
      );
    };

    let sections = getSectionsForTiers();

    const nonMemberSection = getSectionForNonMember();
    if (!!nonMemberSection) {
      sections.push(nonMemberSection);
    }

    return sections;
  };

  const renderBlocksPreviewSections = () => {
    const { renderMember, renderNonMember, contentMember, contentNonMember } = props;

    const { message, ctaButton } = getContentByLang(contentMember);
    const sections = [];
    if (renderMember) {
      sections.push(
        <RewardSection key="member" message={message} ctaButton={ctaButton} isFirstBox={true} {...props} />
      );
    }

    if (renderNonMember) {
      const { message, ctaButton } = getContentForNonMember();

      sections.push(
        <RewardSection
          key={"nonMember"}
          message={message}
          ctaButton={ctaButton}
          isFirstBox={!renderMember}
          {...props}
        />
      );
    }

    return sections;
  };

  const renderContentDefaultAction = () => {
    const { rewardTiers, contentMember, contentNonMember } = props;
    let sections = [];
    const key = contentMember ? "member" : "nonMember";

    const rewardTier = !contentMember ? rewardTiers[SPECIAL_TIER_ENUM.NON] : null;

    const { message, ctaButton } = getContentByLang(contentMember ? contentMember : contentNonMember);

    sections.push(
      <RewardSection
        key={key}
        tierId={""}
        isFirstBox={true}
        tierName={"Bronze"}
        rewardTier={rewardTier}
        message={message}
        ctaButton={ctaButton}
        {...props}
      />
    );
    return sections;
  };

  const renderPreviewContent = () => {
    if (props.renderDefault) {
      return renderContentDefaultAction();
    } else if (props.contentMode) {
      return <div className="content-preview__wrapper">{renderContentPreviewSections()}</div>;
    } else {
      return renderBlocksPreviewSections();
    }
  };

  const notificationContent = props.contentMode
    ? getMessage("actionSetupLoyaltyMessagePreviewEmail.notification.contentMode")
    : getMessage("actionSetupLoyaltyMessagePreviewEmail.notification.tokens");

  let style = {};
  if (props.marginTop) {
    style.paddingTop = props.marginTop;
  }

  return (
    <Fragment>
      <div className="l-preview-email">
        <div className="l-preview-email__body" style={style}>
          {renderPreviewContent()}
        </div>
      </div>
    </Fragment>
  );
};

ActionSetupLoyaltyMessagePreviewEmail.propTypes = {
  contentMember: PropTypes.object,
  contentNonMember: PropTypes.object,
  isForNewMembers: PropTypes.bool,
  replaceRewardToken: PropTypes.func,
  selectedLang: PropTypes.string,
  rewardTiers: PropTypes.object,
  getTierNameById: PropTypes.func,
  contentMode: PropTypes.bool,
  renderMember: PropTypes.bool,
  renderNonMember: PropTypes.bool,
  renderDefault: PropTypes.bool,
  defaultMemberContent: PropTypes.object,
  defaultJoinAction: actionType,
  masterAction: PropTypes.string,
  marginTop: PropTypes.number
};

export default ActionSetupLoyaltyMessagePreviewEmail;
