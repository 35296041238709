import React from "react";
import PropTypes from "prop-types";

import ActionMessagePreview from "../../components/ActionMessagePreview";

import "../../assets/styles/ActionSetupLoyaltyMessagePreviewWeb.scss";

const ActionSetupLoyaltyMessagePreviewWeb = (props) => {
  if (!props.contentMember) {
    return null;
  }

  const BlankSection = () => {
    return (
      <div className="l-action-message l-action-message-blank">
        <div />
        <div />
      </div>
    );
  };

  return (
    <div className="l-preview-web l-device_no-content text-align-center">
      <e-device>
        <e-device-push>
          <table className="preview-header">
            <tbody>
              <tr>
                <td>EARN</td>
                <td>REDEEM</td>
              </tr>
            </tbody>
          </table>

          <ActionMessagePreview
            message={props.contentMember.blockMessage}
            ctaButton={props.contentMember.ctaButton}
            replaceRewardToken={props.replaceRewardToken}
          />

          <BlankSection />
          <BlankSection />
        </e-device-push>
      </e-device>
    </div>
  );
};

ActionSetupLoyaltyMessagePreviewWeb.propTypes = {
  contentMember: PropTypes.object,
  replaceRewardToken: PropTypes.func,
  rewardTiers: PropTypes.object
};

export default ActionSetupLoyaltyMessagePreviewWeb;
