import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { uniqBy } from "lodash";

import { NavLink } from "react-router-dom";
import Card from "./Card";
import CardAdd from "./CardAdd";
import Pagination from "./Pagination";
import usePagination from "../hooks/usePagination";
import InputSearchField from "../components/InputSearchField";
import SelectField from "../components/SelectFieldV2";
import classNames from "classnames";
import { getMessage } from "../messages";

const Cards = (props) => {
  const cards = props.cards.map((card, key) => {
    return (
      <div key={key} className={`e-cell e-cell-3 e-cell-medium ${card.wrapperClassName}`}>
        <Card {...props} {...card} />
      </div>
    );
  });

  return cards;
};

const paginationPageSizeOptions = [16, 32, 60, 100];

const CardsFilterBar = ({
  cards,
  filters,
  onChange,
  searchPlaceholderText,
  addButtonText,
  addButtonLink,
  addButtonDisabled,
  addButtonOnClick,
  addButtonTooltip,
  addButtonHide,
  hideFilterBar,
  header
}) => {
  const allOptionValue = "all";
  let initialFilterValues = {};
  filters = filters || [];

  filters.forEach((filter) => {
    initialFilterValues[filter.key] = allOptionValue;
  });

  const [searchValue, setSearchValue] = useState("");
  const [filterValues, setFilterValues] = useState(initialFilterValues);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [numberOfCards, setNumberOfCards] = useState(cards.length);

  //apply all selected filters
  useEffect(() => {
    applyAllFilters();
  }, [numberOfCards, filterValues, searchValue]);

  useEffect(() => {
    setNumberOfCards(cards.length);
  }, [cards]);

  const addSelectAllOption = (options) => {
    const newOptions = [...options];
    newOptions.unshift({ value: allOptionValue, name: "Show All" });
    return newOptions;
  };

  const onSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const onFilterChange = (event, value, filterKey) => {
    const newFilterValues = { ...filterValues };
    newFilterValues[filterKey] = value;
    setFilterValues(newFilterValues);
  };

  const applyAllFilters = () => {
    let filteredCards = [...cards];

    if (searchValue) {
      filteredCards = filteredCards.filter((card) => card.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
    }

    filters.forEach((filter) => {
      if (filterValues[filter.key] !== allOptionValue) {
        filteredCards = filteredCards.filter((card) => {
          return card.filters[filter.key] === filterValues[filter.key];
        });
      }
    });

    onChange(filteredCards);
  };

  const FilterSelectField = ({ filter }) => {
    return (
      <SelectField
        key={`filter_${filter.key}`}
        label={filter.label}
        inline={true}
        size="large"
        noPadding={true}
        options={addSelectAllOption(filter.options)}
        value={filterValues[filter.key]}
        onChange={(event, customValue) => {
          onFilterChange(event, customValue, filter.key);
        }}
      />
    );
  };

  const AddButton = () => {
    if (addButtonHide) {
      return null;
    }

    let button = (
      <button className="e-btn e-btn-primary" disabled={addButtonDisabled} onClick={addButtonOnClick}>
        {addButtonText}
      </button>
    );

    if (addButtonLink) {
      button = <NavLink to={addButtonLink}>{button}</NavLink>;
    }

    if (addButtonTooltip) {
      button = <e-tooltip content={addButtonTooltip}>{button}</e-tooltip>;
    }

    return button;
  };

  return (
    <div className="e-margin-bottom-m">
      <div className="e-grid e-grid-between e-grid-vertical_center">
        {header ? <div className="e-cell">{header}</div> : null}
        <div className="e-cell">
          {!hideFilterBar && cards.length > 0 && (
            <Fragment>
              <div className="e-grid e-grid-wrap">
                <div className="e-margin-right-s">
                  <InputSearchField
                    placeholder={searchPlaceholderText}
                    value={searchValue}
                    onChange={onSearchChange}
                    noMargin={true}
                  />
                </div>
                {filters
                  .filter((filter) => !filter.showInMoreFilters)
                  .map((filter) => {
                    return <FilterSelectField key={`filter_${filter.key}`} filter={filter} />;
                  })}
                {filters.some((filter) => filter.showInMoreFilters) && (
                  <button
                    type="button"
                    className={classNames("e-btn e-btn-borderless", { "e-btn-active": showMoreFilters })}
                    onClick={() => setShowMoreFilters(!showMoreFilters)}
                  >
                    <e-icon icon="e-filter"></e-icon>
                    {getMessage("cards.filterBar.moreFiltersButton")}
                  </button>
                )}
              </div>
            </Fragment>
          )}
        </div>
        <div className="e-cell">
          <AddButton />
        </div>
      </div>
      {showMoreFilters && (
        <div className="e-grid e-grid-between e-margin-top-m">
          <div className="e-cell">
            {filters
              .filter((filter) => filter.showInMoreFilters)
              .map((filter) => {
                return <FilterSelectField filter={filter} />;
              })}
          </div>
        </div>
      )}
    </div>
  );
};

const CardsContainer = (props) => {
  const {
    addNewCardLink,
    addNewCardText,
    showDialog,
    disableAddNewCard,
    addNewCardTooltip,
    hideAddNewCard,
    cards,
    filters,
    searchPlaceholderText,
    isPreviewMode,
    hideFilterBar,
    header
  } = props;
  const className = disableAddNewCard ? "l-overlay-disabled" : null;

  const { currentPage, setCurrentPage, itemsPerPage, setItemsPerPage, setItemList, getItemList, totalItems } =
    usePagination(paginationPageSizeOptions[0]);

  const [filteredCards, setFilteredCards] = useState([]);

  useEffect(() => {
    setFilteredCards([...cards]);
  }, [cards]);

  useEffect(() => {
    setItemList(filteredCards);
  }, [filteredCards, itemsPerPage, currentPage]);

  const renderAddCard = () => {
    const addCardElm = (
      <div className={className}>
        {showDialog ? (
          <CardAdd addText={addNewCardText} onClick={showDialog} />
        ) : (
          <NavLink to={addNewCardLink}>
            <CardAdd addText={addNewCardText} />
          </NavLink>
        )}
      </div>
    );

    if (addNewCardTooltip) {
      return (
        <e-tooltip content={addNewCardTooltip} style={{ display: "block" }}>
          {addCardElm}
        </e-tooltip>
      );
    }
    return addCardElm;
  };

  return (
    <Fragment>
      <CardsFilterBar
        cards={[...cards]}
        filters={filters}
        searchPlaceholderText={searchPlaceholderText}
        onChange={(cards) => {
          setFilteredCards(cards);
          setCurrentPage(1);
        }}
        addButtonText={addNewCardText}
        addButtonLink={addNewCardLink}
        addButtonOnClick={showDialog}
        addButtonTooltip={addNewCardTooltip}
        addButtonDisabled={disableAddNewCard}
        addButtonHide={hideAddNewCard || isPreviewMode}
        hideFilterBar={hideFilterBar}
        header={header}
      />

      <div className="e-grid e-grid-medium e-grid-wrap">
        <Cards {...props} cards={getItemList()} />
      </div>
      <div className="e-margin-top-l">
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          onPageChange={setCurrentPage}
          onItemsPerPageChange={setItemsPerPage}
          pageSizeMessageId="memberProfile.activity.pagination.pageSize"
          itemsDisplayedMessageId="memberProfile.activity.pagination.recordsDisplayed"
          pageSizeOptions={paginationPageSizeOptions}
          hideIfLessThenFirstPageSize={true}
        />
      </div>
    </Fragment>
  );
};

CardsContainer.propTypes = {
  cards: PropTypes.array,
  addNewCardLink: PropTypes.string,
  addNewCardText: PropTypes.string,
  disableAddNewCard: PropTypes.bool,
  addNewCardTooltip: PropTypes.string,
  showDialog: PropTypes.func,
  isPreviewMode: PropTypes.bool,
  searchPlaceholderText: PropTypes.string,
  hideFilterBar: PropTypes.bool,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      options: PropTypes.array,
      showInMoreFilters: PropTypes.bool
    })
  ),
  header: PropTypes.string
};

export default CardsContainer;
