import axios from "axios";
import sessionHelper from "../helpers/sessionHelper";

export default class tierApi {
  static async getAllTiers() {
    const planId = sessionHelper.getPlanId();
    const response = await axios.get(`/plan/${planId}/tier`);
    return response.data;
  }

  static async createTier(data) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.post(`/plan/${planId}/tier`, data);
    return response.data;
  }

  static async updateTier(data, id) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.put(`/plan/${planId}/tier/${id}`, data);
    return response.data;
  }

  static async deleteTier(id) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.delete(`/plan/${planId}/tier/${id}`);
    return response;
  }
}
