import { getMessage } from "../messages";

export const ACTIONS_ACIVITY_DATA = {
  join: {
    title: "Join Loyalty",
    summary: {
      activeActions: {
        active: 2,
        total: 2
      },
      pools: {
        active: 1,
        total: 1
      },
      warnings: 0,
      errors: 0
    },
    actions: [
      {
        status: "success",
        name: "Join the plan",
        activatedOn: "10/10/18",
        shown: "1k",
        completed: "20.3%",
        started: "14.2%"
      },
      {
        status: "success",
        name: "Become a subscriber",
        activatedOn: "23/09/18",
        shown: "1.1k",
        completed: "30.5%",
        started: "16.3%"
      }
    ]
  },
  purchases: {
    title: "Purchases",
    summary: {
      activeActions: {
        active: 3,
        total: 4
      },
      pools: {
        active: 1,
        total: 1
      },
      warnings: 1,
      errors: 1
    },
    actions: [
      {
        status: "success",
        name: "Complete a purchase",
        activatedOn: "10/10/18",
        shown: "1k",
        completed: "20.3%",
        started: "14.2%"
      },
      {
        status: "success",
        name: "1st to 2nd purchase",
        activatedOn: "23/09/18",
        shown: "1.1k",
        completed: "30.5%",
        started: "16.3%"
      },
      {
        status: "warning",
        name: "Promote specific product",
        activatedOn: "23/09/18",
        shown: "1.1k",
        completed: "7.15%",
        started: "27.8%"
      },
      {
        status: "error",
        name: "Promote specific sale",
        activatedOn: "23/09/18",
        shown: "1.1k",
        completed: "30.5%",
        started: "16.3%"
      }
    ]
  },
  engagements: {
    title: "Engagements",
    summary: {
      activeActions: {
        active: 2,
        total: 2
      },
      pools: {
        active: 1,
        total: 1
      },
      warnings: 0,
      errors: 0
    },
    actions: [
      {
        status: "success",
        name: "Facebook like",
        activatedOn: "10/10/18",
        shown: "1k",
        completed: "20.3%",
        started: "14.2%"
      },
      {
        status: "success",
        name: "Product review",
        activatedOn: "23/09/18",
        shown: "1.1k",
        completed: "30.5%",
        started: "16.3%"
      }
    ]
  },
  events: {
    title: "Events",
    summary: {
      activeActions: {
        active: 2,
        total: 2
      },
      pools: {
        active: 1,
        total: 1
      },
      warnings: 0,
      errors: 0
    },
    actions: [
      {
        status: "success",
        name: "Birthday (event)",
        activatedOn: "10/10/18",
        shown: "1k",
        completed: "20.3%",
        started: "14.2%"
      },
      {
        status: "success",
        name: "One step from next tier (milestone)",
        activatedOn: "23/09/18",
        shown: "1.1k",
        completed: "30.5%",
        started: "16.3%"
      }
    ]
  },
  redemption: {
    title: "Redemption",
    summary: {
      activeActions: {
        active: 2,
        total: 2
      },
      pools: {
        active: 1,
        total: 1
      },
      warnings: 1,
      errors: 0
    },
    actions: [
      {
        status: "success",
        name: "Convert points to coupons",
        activatedOn: "10/10/18",
        shown: "1k",
        completed: "20.3%",
        started: "14.2%"
      },
      {
        status: "warning",
        name: "Promote a benefit",
        activatedOn: "23/09/18",
        shown: "1.1k",
        completed: "4.26%",
        started: "11.34%"
      }
    ]
  }
};

export const TIER_COLORS_ARRAY = ["#02C9B4", "#6E70C3", "#7993AC", "#A6AC6B", "#9F6E5D", "#ECA24B"];

export const VIEW_RANGE_ENUM = {
  DAY: "Day",
  MONTH: "Month",
  WEEK: "Week"
};
export const VIEW_RANGE_OPTIONS = [
  {
    value: VIEW_RANGE_ENUM.DAY,
    name: getMessage("viewRange.options.day")
  },
  {
    value: VIEW_RANGE_ENUM.WEEK,
    name: getMessage("viewRange.options.week")
  },
  {
    value: VIEW_RANGE_ENUM.MONTH,
    name: getMessage("viewRange.options.month")
  }
];

export const LOYALTY_MEMBER_REVENUE_HELP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4608196688913-Reporting-Loyalty-Impact";

export const AVERAGE_ORDER_VALUE_HELP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4608196688913-Reporting-Loyalty-Impact#average-order-value";

export const NUMBER_OF_PURCHASES_HELP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4608196688913-Reporting-Loyalty-Impact#number-of-purchases-in-a-period";

export const TOTAL_LOYALTY_MEMBERS_HELP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4608224917521-Reporting-Loyalty-Member-Growth#total-loyalty-members";

export const LOYALTY_ENROLLMENT_HELP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4608224917521-Reporting-Loyalty-Member-Growth#loyalty-enrollment";

export const ENGAGEMENT_RATES_HELP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4608224925969-Reporting-Loyalty-Engagement";

export const POINTS_ANALYTICS_HELP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4608233027857-Reporting-Reward-Analytics";

export const VOUCHERS_ANALYTICS_HELP_LINK =
  "https://help.emarsys.com/hc/en-us/articles/4608233027857-Reporting-Reward-Analytics#voucher-analytics";
