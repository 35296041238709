import React from "react";
import PropTypes from "prop-types";

const TrendArrow = ({ value }) => {
  if (value > 0) {
    return (
      <div className="e-legend__trend e-legend__trend-upwards">
        <svg height="24" viewBox="0 0 24 24" width="24">
          <path
            d="m18 6v8h-2l-.0001068-4.586-8.29278642 8.2931068-1.41421356-1.4142136 8.29199998-8.2928932h-4.5848932v-2z"
            fillRule="evenodd"
            fill="#fff"
          ></path>
        </svg>
      </div>
    );
  }

  if (value < 0) {
    return (
      <div className="e-legend__trend e-legend__trend-downwards">
        <svg height="24" viewBox="0 0 24 24" width="24">
          <path
            d="m17.997 17.997h-8v-2h4.585l-8.292-8.293 1.414-1.414 8.293 8.293v-4.586h2z"
            fillRule="evenodd"
            fill="#fff"
          ></path>
        </svg>
      </div>
    );
  }

  return null;
};

TrendArrow.propTypes = {
  value: PropTypes.number
};

export default TrendArrow;
