import React from "react";
import { NavLink } from "react-router-dom";
import { getMessage } from "../../messages";

const MemberProfileHeader = ({ title }) => {
  const isSupportMode = sessionStorage.getItem("isSupportMode");
  const overviewPath = isSupportMode ? "/member-profiles" : "/support";

  return (
    <header className="e-layout__header">
      <h1 className="e-layout__title">{title}</h1>
      <div className="e-layout__action">
        <div className="e-buttongroup">
          <NavLink to={overviewPath} className="e-btn e-btn-link">
            {getMessage("toOverviewBtn.label")}
          </NavLink>
        </div>
      </div>
    </header>
  );
};

export default MemberProfileHeader;
