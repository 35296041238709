import moment from "moment";

import { isDemoMode } from "../../helpers/envHelper";
import { VIEW_RANGE_ENUM } from "../../constants/dashboardConstants";
import { formatIsoTime } from "../../helpers/timeHelper";

export const convertDateForChart = (unixTime) => {
  return formatIsoTime(unixTime);
};

export const formatTrendText = (strings, valueExp, daysExp) => {
  let diffText = "";
  if (valueExp > 0) {
    diffText = "more";
  } else if (valueExp < 0) {
    diffText = "less";
  } else {
    const br = strings[1].indexOf("br />") > -1 ? "<br />" : "";
    return `Unchanged from the ${br} previous ${daysExp} days`;
  }

  if (diffText) {
    return `${strings[0]}${diffText}${strings[1]}${daysExp}${strings[2]}`;
  }

  return "";
};

export const transformDataDateForDemo = (data, daysRange) => {
  if (!isDemoMode()) {
    return data;
  }

  const currDate = moment().subtract(daysRange, "days");

  data.forEach((row) => {
    row.date = currDate.valueOf(); //get timestamp in milliseconds
    currDate.add(1, "days");
  });

  return data;
};

export const datesDiffStr = (startDateStr, endDateStr, dateAggStr) => {
  let divider = 1000 * 60 * 60 * 24; //Day
  if (dateAggStr == VIEW_RANGE_ENUM.WEEK) {
    divider *= 7;
  }
  if (dateAggStr == VIEW_RANGE_ENUM.MONTH) {
    divider *= 30;
  }
  let startDate = new Date(startDateStr);
  let endDate = new Date(endDateStr);
  var diff = (endDate.getTime() - startDate.getTime()) / divider;

  let diffVal = Math.abs(Math.round(diff));

  if (diffVal <= 1) {
    return dateAggStr.toLowerCase();
  }

  return `${diffVal} ${dateAggStr.toLowerCase()}s`;
};

export const formatInputDate = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const getDaysDiff = (start, end) => {
  return moment(end).diff(moment(start), "days") + 1;
};
