import { POINTS_ALLOCATION_DELAY_OPTIONS as POINTS_ALLOCATION_DELAY_OPTIONS_PLAN } from "../constants/planConstants";
import { getMessage } from "../messages";

export const ACTION_STATUS_ENUM = {
  READY_TO_USE: "readyToUse",
  ACTIVE: "active",
  ATTACHED: "attached",
  ATTACHED_AND_DEACTIVATED: "attachedDeactivated",
  INACTIVE: "inactive",
  DEACTIVATED: "deactivated"
};

export const MASTER_ACTION_ENUM = {
  JOIN: "join",
  PURCHASES: "purchases",
  ENGAGEMENTS: "engagements",
  EVENTS: "events",
  REDEMPTION: "redemption",
  PROMOTIONS: "promotions",
  REFERRAL: "referral"
};

export const MASTER_ACTIONS = {
  join: {
    largeTextMessage: "actions.masterActions.join.largeText",
    largeText: "Join Loyalty",
    smallText: "Become a member and start to earn",
    defaultIcon: {
      displayName: "Default join",
      fileName: "defaultJoin"
    }
  },
  purchases: {
    largeTextMessage: "actions.masterActions.purchases.largeText",
    largeText: "Purchase",
    smallText: "Spend to earn",
    defaultIcon: {
      displayName: "Shopping Cart",
      fileName: "shopping-cart"
    }
  },
  engagements: {
    largeTextMessage: "actions.masterActions.engagements.largeText",
    largeText: "Engagement",
    smallText: "Engage to earn",
    defaultIcon: {
      displayName: "Wishlist",
      fileName: "wishlist"
    }
  },
  events: {
    largeText: "Event",
    largeTextMessage: "actions.masterActions.events.largeText",
    smallText: "Additional events & milestones",
    defaultIcon: {
      displayName: "Presents",
      fileName: "presents"
    }
  },
  promotions: {
    largeText: "Promotion",
    largeTextMessage: "actions.masterActions.promotions.largeText",
    smallText: "Redeem your points and rewards",
    defaultIcon: {
      displayName: "Shopping Cart",
      fileName: "shopping-cart"
    }
  },
  referral: {
    largeTextMessage: "actions.masterActions.referral.largeText",
    largeText: "Referral",
    defaultIcon: {
      displayName: "Shopping Cart",
      fileName: "shopping-cart"
    }
  }
};

export const FORM_STEP_ENUM = {
  ACTION_SETTINGS: "actionSettings",
  REWARD_SETTINGS: "rewardSettings",
  CONTENT_CREATION: "contentCreation",
  SCHEDULING: "scheduling"
};

export const FORM_STEPS = [
  {
    id: FORM_STEP_ENUM.ACTION_SETTINGS,
    title: getMessage("form.steps.actionSettings.title")
  },
  {
    id: FORM_STEP_ENUM.REWARD_SETTINGS,
    title: getMessage("form.steps.rewardSettings.title")
  },
  {
    id: FORM_STEP_ENUM.CONTENT_CREATION,
    title: getMessage("form.steps.contentCreation.title")
  },
  {
    id: FORM_STEP_ENUM.SCHEDULING,
    title: getMessage("form.steps.scheduling.title")
  }
];

export const FORM_STEPS_DEFAULT_JOIN_ACTION = [
  {
    id: FORM_STEP_ENUM.ACTION_SETTINGS,
    title: getMessage("form.steps.actionSettings.defaultJoin.title")
  },
  {
    id: FORM_STEP_ENUM.REWARD_SETTINGS,
    title: getMessage("form.steps.rewardSettings.title")
  },
  {
    id: FORM_STEP_ENUM.CONTENT_CREATION,
    title: getMessage("form.steps.contentCreation.title")
  }
];

export const FORM_STEPS_DEFAULT_ACTION = [
  {
    id: FORM_STEP_ENUM.ACTION_SETTINGS,
    title: getMessage("form.steps.actionSettings.defaultJoin.title")
  },
  {
    id: FORM_STEP_ENUM.CONTENT_CREATION,
    title: getMessage("form.steps.contentCreation.title")
  }
];

// TODO: check usages
export const ACTION_TYPE_ENUM = {
  JOIN_THE_PROGRAM: "join-the-program",
  PURCHASES_DRIVE_PURCHASE: "drive-purchase",
  PROMOTIONS_EVENT: "promotions-event",
  PROMOTIONS_CATALOG: "promotions-catalog",
  ENGAGEMENTS_DRIVE_ENGAGEMENT: "drive-engagement",
  EVENTS_ANNIVERSARY_OR_BENEFIT: "anniversary-or-benefit",
  REDEMPTION_DRIVE_POINTS_REDEMPTION: "drive-points-redemption",
  REFERRAL: "referral"
};

export const ACTION_TYPE_OPTIONS = {
  join: [
    {
      name: getMessage("actionTypeOptions.join.name"),
      value: ACTION_TYPE_ENUM.JOIN_THE_PROGRAM
    }
  ],
  purchases: [
    {
      name: getMessage("actionTypeOptions.purchase.name"),
      value: ACTION_TYPE_ENUM.PURCHASES_DRIVE_PURCHASE
    }
  ],
  promotions: [
    {
      name: getMessage("actionTypeOptions.promotioEvent.name"),
      value: ACTION_TYPE_ENUM.PROMOTIONS_EVENT
    },
    {
      name: getMessage("actionTypeOptions.promotioCatalog.name"),
      value: ACTION_TYPE_ENUM.PROMOTIONS_CATALOG
    }
  ],
  engagements: [
    {
      name: getMessage("actionTypeOptions.engagement.name"),
      value: ACTION_TYPE_ENUM.ENGAGEMENTS_DRIVE_ENGAGEMENT
    }
  ],
  events: [
    {
      name: getMessage("actionTypeOptions.event.name"),
      value: ACTION_TYPE_ENUM.EVENTS_ANNIVERSARY_OR_BENEFIT
    }
  ],
  redemption: [
    {
      name: getMessage("actionTypeOptions.redemption.name"),
      value: ACTION_TYPE_ENUM.REDEMPTION_DRIVE_POINTS_REDEMPTION
    }
  ],
  referral: [
    {
      name: getMessage("actionTypeOptions.referral.name"),
      value: ACTION_TYPE_ENUM.REFERRAL
    }
  ]
};

export const DEFAULT_ACTION_TYPE_OPTIONS = {
  join: [
    {
      name: getMessage("defaultActionTypeOptions.join.name"),
      value: "default"
    }
  ],
  purchases: [
    {
      name: getMessage("defaultActionTypeOptions.purchases.name"),
      value: "default"
    }
  ],
  promotions: [
    {
      name: getMessage("defaultActionTypeOptions.promotionEvent.name"),
      value: ACTION_TYPE_ENUM.PROMOTIONS_EVENT
    },
    {
      name: getMessage("defaultActionTypeOptions.promotionCatalog.name"),
      value: ACTION_TYPE_ENUM.PROMOTIONS_CATALOG
    }
  ],
  engagements: [
    {
      name: getMessage("defaultActionTypeOptions.engagements.name"),
      value: "default"
    }
  ],
  events: [
    {
      name: getMessage("defaultActionTypeOptions.events.name"),
      value: "default"
    }
  ],
  redemption: [
    {
      name: getMessage("defaultActionTypeOptions.redemption.name"),
      value: "default"
    }
  ]
};

export const SPECIAL_TIER_ENUM = {
  ALL: "all",
  NON: "non",
  GUEST: "guest"
};

export const SPECIAL_TIER_REWARD_SETTINGS = {
  all: {
    tierId: SPECIAL_TIER_ENUM.ALL,
    name: getMessage("specialTierRewardSettings.all.name")
  },
  non: {
    tierId: SPECIAL_TIER_ENUM.NON,
    name: "Non",
    displaySettingsName: getMessage("specialTierRewardSettings.non.name")
  }
};

export const FRIEND_REWARD_SETTINGS = {
  guest: {
    tierId: SPECIAL_TIER_ENUM.GUEST,
    name: "Friend",
    displaySettingsName: getMessage("specialTierRewardSettings.guest.name")
  }
};

export const MULTIPLY_POINTS_OPTIONS = [
  {
    name: getMessage("multiplyPoints.options.double.name"),
    value: "double"
  },
  { name: getMessage("multiplyPoints.options.triple.name"), value: "triple" }
];

export const MULTIPLY_SPEND_OPTIONS = [
  {
    name: getMessage("multiplySpend.options.double.name"),
    value: "double"
  },
  { name: getMessage("multiplySpend.options.triple.name"), value: "triple" }
];

export const STATUS_UPGRADE_OPTIONS = [
  {
    name: getMessage("statusUpgrade.options.nextTier.name"),
    value: "nextTier"
  }
];

export const ENABLE_IN_SMART_BOX_ENUM = {
  YES: "yes",
  NO: "no"
};

export const ENABLE_IN_SMART_BOX_OPTIONS = [
  {
    name: getMessage("enableInSmartBox.options.yes.name"),
    value: ENABLE_IN_SMART_BOX_ENUM.YES
  },
  {
    name: getMessage("enableInSmartBox.options.no.name"),
    value: ENABLE_IN_SMART_BOX_ENUM.NO
  }
];

export const POINTS_ALLOCATION_DELAY_OPTIONS = [
  {
    name: "Instant",
    value: "0"
  },
  ...POINTS_ALLOCATION_DELAY_OPTIONS_PLAN
];

export const SMART_BOX_RESTRICTIONS_OPTIONS = [
  "Unlimited",
  "One-time action",
  "One time only",
  "Once a week",
  "Once a month",
  "Once a quarter",
  "Once a year"
];

export const VOUCHER_OBJECTIVE_ENUM = {
  REWARD: "reward",
  TRIGGER: "trigger"
};

export const VOUCHER_OBJECTIVE_OPTIONS = [
  {
    name: getMessage("voucherObjective.options.reward.name"),
    value: VOUCHER_OBJECTIVE_ENUM.REWARD
  },
  {
    name: getMessage("voucherObjective.options.trigger.name"),
    value: VOUCHER_OBJECTIVE_ENUM.TRIGGER
  }
];

export const ACTIVATE_OPTIONS = [
  {
    name: getMessage("activate.options.true"),
    value: true
  },
  {
    name: getMessage("activate.options.false"),
    value: false
  }
];

export const ACTIVATE_PROMOTION_OPTIONS = [
  {
    name: getMessage("activate.promotion.options.true"),
    value: true
  },
  {
    name: getMessage("activate.promotion.options.false"),
    value: false
  }
];

export const REWARD_TOKEN = {
  token: "{{rewardToken}}",
  display: "{ reward }"
};

export const DEFAULT_JOIN_MESSAGE_WITH_REWARD = getMessage("defaultJoinMessageWithReward");

export const DEFAULT_JOIN_MESSAGE_NO_REWARD = getMessage("defaultJoinMessageNoReward");

export const PROMOTION_USAGE_TYPE_ENUM = {
  ONE_TIME: "oneTime",
  MULTI_USE: "multiUse"
};

export const PROMOTION_USAGE_TYPE_OPTIONS = [
  {
    value: PROMOTION_USAGE_TYPE_ENUM.ONE_TIME,
    name: getMessage("promotionType.oneTime.name"),
    tooltip: getMessage("promotionType.oneTime.tooltip")
  },
  {
    value: PROMOTION_USAGE_TYPE_ENUM.MULTI_USE,
    name: getMessage("promotionType.multiUse.name"),
    tooltip: getMessage("promotionType.multiUse.tooltip")
  }
];

export const SELECTED_PROMOTION_ENUM = {
  EVENT: "event",
  CATALOG: "catalog"
};

export const SELECTED_PROMOTION_OPTIONS = [
  {
    value: SELECTED_PROMOTION_ENUM.EVENT,
    name: getMessage("selectedPromotion.options.event.name")
  },
  {
    value: SELECTED_PROMOTION_ENUM.CATALOG,
    name: getMessage("selectedPromotion.options.catalog.name")
  }
];

// TODO: use
export const ActionSetupNotification = {
  purchases: "Members of a tier with no reward defined will receive the default purchase action.",
  events: "Benefit action is triggered by automation center and rewards defined are automatically attached to members."
};

export const ACTIVE_STATUS_TOOLTIPS = {
  //"status.active.tooltip."
  attachable: `This action is active and its settings are locked. To edit
  this action, you must deactivate it and wait for the
  specified expiration date, or you can copy it, edit the
  copy and launch that as a new action.`,
  promotion: `This promotion is active and available to your members.`,
  event: `This action is active and can be connected to benefits or automation program.`
};

export const READY_TO_USE_STATUS_TOOLTIPS = {
  //"status.readyToUse.tooltip.promotion","status.readyToUse.tooltip.attachable"
  attachable: `The action is ready to use and can be attached to
              contacts via campaign editor using the loyalty tokens.`,
  promotion: `The promotion is ready to use and can be
              automatically displayed in the wallet or attached to
              members via campaign editor using the loyalty tokens. `
};

export const DEACTIVATED_STATUS_TOOLTIPS = {
  //"status.deactivated.tooltip."
  attachable: `The action has been deactivated by you. To edit or
              delete this action, you have to wait for the specified
              expiration date, or you can copy it, edit the copy and
              launch that as a new action.`,
  promotion: `The promotion has been deactivated by you and no longer available to members.`
};

export const INACTIVE_STATUS_TOOLTIPS = {
  //"status.inactive.tooltip","status.inactive.tooltip.proomotionEnded"
  default: "The settings have not been completed.",
  promotionEnded: `This promotion has ended and can no longer be edited. You can copy it or create a new one.`
};

export const DELETE_ACTION_TOOLTIPS = {
  attachable: `To delete an action you must deactivate it and wait for the specified expiration date.`,
  promotion: `To delete active promotion you must deactivate it first.`,
  event: `To delete active event action you must deactivate it first.`
};

export const DELETE_ACTION_DIALOG = {
  attachable: {
    title: "Are you sure you want to delete this action ?",
    text: `Once deleted, the action with all its setting will be permanently deleted. Make sure the action is not connected to any automation program.`
  },
  promotion: {
    title: "Are you sure you want to delete this promotion ?",
    text: `Once deleted, the promotion with all its setting will be permanently deleted. Make sure the promotion is not connected to any automation program.`
  },
  event: {
    title: "Are you sure you want to delete this action ?",
    text: `Once deleted, the action with all its setting will be permanently deleted. Make sure the action is not connected to any benefit or automation program.`
  },
  referral: {
    title: "Are you sure you want to delete this program ?",
    text: `Once deleted, the program with all its settings will be permanently deleted. Make sure to communicate the end of the program to your customers.`
  }
};

export const MULTI_LANG_PROPS_MAP = {
  nameML: "name",
  "content.member.blockMessageML": "content.member.blockMessage",
  "content.member.ctaButtonML": "content.member.ctaButton",
  "content.member.ctaUrlML": "content.member.ctaUrl",
  "content.nonMember.blockMessageML": "content.nonMember.blockMessage",
  "content.nonMember.ctaButtonML": "content.nonMember.ctaButton",
  "content.nonMember.ctaUrlML": "content.nonMember.ctaUrl",
  "blockExternalLink.urlML": "blockExternalLink.url",
  "blockExternalLink.textML": "blockExternalLink.text",
  "referralContent.referrer.titleML": "referralContent.referrer.title",
  "referralContent.referrer.textML": "referralContent.referrer.text",
  "referralContent.referrer.subtitleML": "referralContent.referrer.subtitle",
  "referralContent.referrer.landingPageUrlML": "referralContent.referrer.landingPageUrl",
  "referralContent.referrer.consentTextML": "referralContent.referrer.consentText",
  "referralContent.referrer.consentLinkTextML": "referralContent.referrer.consentLinkText",
  "referralContent.referrer.consentLinkUrlML": "referralContent.referrer.consentLinkUrl",
  "referralContent.referee.titleML": "referralContent.referee.title",
  "referralContent.referee.textML": "referralContent.referee.text",
  "referralContent.referee.secondaryTitleML": "referralContent.referee.secondaryTitle",
  "referralContent.referee.secondaryTextML": "referralContent.referee.secondaryText",
  "referralContent.referee.inputFieldPlaceholderML": "referralContent.referee.inputFieldPlaceholder",
  "referralContent.referee.consentTextML": "referralContent.referee.consentText",
  "referralContent.referee.consentLinkTextML": "referralContent.referee.consentLinkText",
  "referralContent.referee.consentLinkUrlML": "referralContent.referee.consentLinkUrl",
  "referralContent.referee.secondaryConsentEnabledML": "referralContent.referee.secondaryConsentEnabled",
  "referralContent.referee.secondaryConsentTextML": "referralContent.referee.secondaryConsentText",
  "referralContent.referee.secondaryConsentLinkTextML": "referralContent.referee.secondaryConsentLinkText",
  "referralContent.referee.secondaryConsentLinkUrlML": "referralContent.referee.secondaryConsentLinkUrlML",
  "referralContent.referee.ctaTextML": "referralContent.referee.ctaText",
  emailCampaignIdML: "emailCampaignId"
};

export const MULTI_CURRENCY_PROPS_MAP = {
  minOrderMC: "minOrder",
  minOrderDisplayMC: "minOrderDisplay"
};

export const VALIDITY_TYPE_ENUM = {
  LIMITED_FROM_EARN: "limitedFromEarn",
  FIXED_DATE: "fixedDate"
};

export const VALIDITY_TYPE_OPTIONS = [
  {
    name: getMessage("validityType.limitedFromEarn.name"),
    value: VALIDITY_TYPE_ENUM.LIMITED_FROM_EARN
  },
  {
    name: getMessage("validityType.fixedDate.name"),
    value: VALIDITY_TYPE_ENUM.FIXED_DATE
  }
];

export const REFERRAL_LIMIT_TYPE_ENUM = {
  NONE: "none",
  BEGINNING_OF_YEAR: "beginningOfYear"
};

export const REFERRAL_REWARDS_LIMIT_OPTIONS = [
  {
    name: getMessage("referralRewardLimitOptions.none.name"),
    value: REFERRAL_LIMIT_TYPE_ENUM.NONE
  },
  {
    name: getMessage("referralRewardLimitOptions.begOfYear.name"),
    value: REFERRAL_LIMIT_TYPE_ENUM.BEGINNING_OF_YEAR,
    tooltip: getMessage("referralRewardLimitOptions.begOfYear.tooltip")
  }
];

export const REFERRAL_CODES_LIMIT_OPTIONS = [
  {
    name: getMessage("referralCodesLinitOptions.none.name"),
    value: REFERRAL_LIMIT_TYPE_ENUM.NONE
  },
  {
    name: getMessage("referralCodesLinitOptions.begOfYear.name"),
    value: REFERRAL_LIMIT_TYPE_ENUM.BEGINNING_OF_YEAR,
    tooltip: getMessage("referralCodesLinitOptions.begOfYear.tooltip")
  }
];

export const REFERRAL_CLAIM_TYPE_ENUM = {
  SEND: "send",
  IMMEDIATE: "immediate"
};

export const REFERRAL_CLAIM_TYPE_OPTIONS = [
  {
    name: "Send the code via email",
    value: REFERRAL_CLAIM_TYPE_ENUM.SEND
  },
  {
    name: "Display the code in pop-up dialog and additionally send via email",
    value: REFERRAL_CLAIM_TYPE_ENUM.IMMEDIATE
  }
];

export const SCHEDULING_RECURRENCE_TYPE_ENUM = {
  ONE_TIME: "notRecurring",
  RECURRING: "recurring"
};

export const SCHEDULING_RECURRENCE_OPTIONS = [
  {
    name: getMessage("actionSetupScheduling.recurringType.oneTime"),
    value: SCHEDULING_RECURRENCE_TYPE_ENUM.ONE_TIME
  },
  {
    name: getMessage("actionSetupScheduling.recurringType.recurring"),
    value: SCHEDULING_RECURRENCE_TYPE_ENUM.RECURRING
  }
];

export const SCHEDULING_RECURRENCE_FREQUENCY_ENUM = {
  DAILY: "days",
  WEEKLY: "weeks",
  MONTHLY: "months"
};

export const SCHEDULING_RECURRENCE_FREQUENCY_OPTIONS = [
  {
    name: getMessage("actionSetupScheduling.recurring.repeatInterval.options.daily"),
    value: SCHEDULING_RECURRENCE_FREQUENCY_ENUM.DAILY,
    selected: "true"
  },
  {
    name: getMessage("actionSetupScheduling.recurring.repeatInterval.options.weekly"),
    value: SCHEDULING_RECURRENCE_FREQUENCY_ENUM.WEEKLY
  },
  {
    name: getMessage("actionSetupScheduling.recurring.repeatInterval.options.monthly"),
    value: SCHEDULING_RECURRENCE_FREQUENCY_ENUM.MONTHLY
  }
];

export const SCHEDULING_RECURRENCE_MONTHLY_WEEK_OPTIONS = [
  {
    name: getMessage("actionSetupScheduling.recurring.monthly.week.name.1"),
    value: 1
  },
  {
    name: getMessage("actionSetupScheduling.recurring.monthly.week.name.2"),
    value: 2
  },
  {
    name: getMessage("actionSetupScheduling.recurring.monthly.week.name.3"),
    value: 3
  },
  {
    name: getMessage("actionSetupScheduling.recurring.monthly.week.name.4"),
    value: 4
  },
  {
    name: getMessage("actionSetupScheduling.recurring.monthly.week.name.5"),
    value: 5
  }
];

export const SCHEDULING_RECURRENCE_MONTHLY_OPTIONS_ENUM = {
  WEEK: "week",
  MONTH: "month"
};
