import validate from "validate.js";
import { BENEFIT_TYPE_ENUM } from "../constants/tierConstants";
import { getMessage } from "../messages";

validate.validators.benefitSingleReward = function (value, options, key, benefit) {
  const { tierId, message } = options;

  const hasValue = Array.isArray(value) ? value.length > 0 : !!value;

  if (benefit.tiers[tierId].enabled && !hasValue) {
    return message;
  }

  return undefined;
};

validate.validators.atLeastOneTier = function (value, options, key, benefit) {
  const { message } = options;
  const tiers = benefit.tiers;
  if (Object.keys(tiers).some((tierId) => tiers[tierId].enabled)) {
    return undefined;
  }

  return message;
};

export default class benefitValidator {
  constructor(isMultiMarket, isAdditionalBenefit) {
    this.isAdditionalBenefit = isAdditionalBenefit;
    validate.options = {
      fullMessages: false
    };

    this.defaultConstraints = {
      name: {
        presence: {
          allowEmpty: false,
          message: getMessage("benefitValidator.defaultConstraints.name.presence")
        }
      }
    };

    if (isMultiMarket) {
      this.defaultConstraints["targetMarkets"] = {
        presence: {
          allowEmpty: false,
          message: getMessage("benefitValidator.defaultConstraints.targetMarkets.presence")
        }
      };
    }

    const eventActionConstraints = {
      eventActionId: {
        presence: {
          allowEmpty: false,
          message: getMessage("benefitValidator.eventActionId.presence")
        }
      }
    };

    const customRewardConstraints = {
      custom: {
        presence: {
          allowEmpty: false,
          message: getMessage("benefitValidator.custom.presence")
        }
      },
      tiers: {
        atLeastOneTier: {
          message: getMessage("benefitValidator.tiers.atLeastOneTier")
        }
      }
    };

    this.constraints = {};

    this.constraints[BENEFIT_TYPE_ENUM.EVENT_ACTION] = {
      ...this.defaultConstraints,
      ...eventActionConstraints
    };

    this.constraints[BENEFIT_TYPE_ENUM.CUSTOM] = {
      ...this.defaultConstraints,
      ...customRewardConstraints
    };
  }

  createRewardConstraints = (benefit) => {
    let rewardConstraints = {};

    if (this.isAdditionalBenefit) {
      rewardConstraints = {
        rewardId: {
          presence: {
            allowEmpty: false,
            message: getMessage("benefitValidator.rewardConstraints.rewardId.presence")
          }
        }
      };
    } else {
      rewardConstraints = {
        tiers: {
          atLeastOneTier: {
            message: getMessage("benefitValidator.rewardConstraints.tiers.atLeastOneTier")
          }
        }
      };
      Object.keys(benefit.tiers).forEach((tierId) => {
        rewardConstraints[`tiers.${tierId}.rewardId`] = {
          benefitSingleReward: {
            tierId,
            message: getMessage("benefitValidator.rewardConstraints.tiers.tierId.rewardId.benefitSingleReward")
          }
        };
      });
    }

    return { ...this.defaultConstraints, ...rewardConstraints };
  };

  createExclusiveAccessConstraints = (benefit) => {
    let rewardConstraints = {};

    if (this.isAdditionalBenefit) {
      rewardConstraints = {
        exclusiveAccessIds: {
          presence: {
            allowEmpty: false,
            message: getMessage("benefitValidator.rewardConstraints.exclusiveAccessIds.presence")
          }
        }
      };
    } else {
      rewardConstraints = {
        tiers: {
          atLeastOneTier: {
            message: getMessage("benefitValidator.rewardConstraints.tiers.atLeastOneTier")
          }
        }
      };
      Object.keys(benefit.tiers).forEach((tierId) => {
        rewardConstraints[`tiers.${tierId}.exclusiveAccessIds`] = {
          benefitSingleReward: {
            tierId,
            message: getMessage("benefitValidator.rewardConstraints.tiers.tierId.rewardId.benefitSingleReward")
          }
        };
      });
    }

    return { ...this.defaultConstraints, ...rewardConstraints };
  };

  validateBenefit = (benefit, benefitType) => {
    let constraints = {};

    switch (benefitType) {
      case BENEFIT_TYPE_ENUM.FIXED_YEARLY_VOUCHER:
      case BENEFIT_TYPE_ENUM.ONE_TIME_VOUCHER:
        constraints = this.createRewardConstraints(benefit);
        break;
      case BENEFIT_TYPE_ENUM.EXCLUSIVE_ACCESS:
        constraints = this.createExclusiveAccessConstraints(benefit);
        break;
      default:
        constraints = this.constraints[benefitType];
    }

    return constraints ? validate(benefit, constraints) : undefined;
  };
}
