import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const DashboardHeader = (props) => {
  const { title, overviewLink, editLink, statueLabelElement, overviewLinkText, editLinkText } = props;

  return (
    <Fragment>
      <header className="e-layout__header">
        <NavLink to={overviewLink} className="e-layout__back e-cell">
          <e-layout-back-button>{overviewLinkText}</e-layout-back-button>
        </NavLink>
        <h1 className="e-layout__title">
          {title} {statueLabelElement}
        </h1>
        <div className="e-layout__actions">
          <NavLink to={editLink} className="e-layout__action e-btn e-btn-primary">
            <e-icon className="e-clickable" icon="ac-icon_edit" size="small" />
            {editLinkText}
          </NavLink>
        </div>
      </header>
    </Fragment>
  );
};

DashboardHeader.propTypes = {
  title: PropTypes.string,
  overviewLink: PropTypes.string,
  editLink: PropTypes.string,
  overviewLinkText: PropTypes.string,
  editLinkText: PropTypes.string,
  statueLabelElement: PropTypes.element
};

export default DashboardHeader;
