import React from "react";
import PropTypes from "prop-types";

import ActionMessagePreview from "../../components/ActionMessagePreview";

import "../../assets/styles/ActionSetupNotificationPreviewWeb.scss";

const ActionSetupNotificationPreviewWeb = (props) => {
  return (
    <div className="l-notif-preview-web">
      <ActionMessagePreview {...props} />
      <div className="l-fab-preview m-t-10">
        <div id="loyalsys-button" className="loyalsys-button">
          <div className="button-left">Silver</div>
          <div className="button-center">
            <div className="button-text-larger">1300</div>
            &nbsp;
            <div className="button-text-smaller m-r-5">Points&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <div className="button-text-larger">25</div>
            &nbsp;
            <div className="button-text-smaller">Value</div>
          </div>
          <div className="button-right">
            <e-icon icon="plus" color="white" />
          </div>
        </div>
      </div>
    </div>
  );
};

ActionSetupNotificationPreviewWeb.propTypes = {
  message: PropTypes.string,
  ctaButton: PropTypes.string,
  replaceRewardToken: PropTypes.func,
  showClose: PropTypes.bool
};

export default ActionSetupNotificationPreviewWeb;
