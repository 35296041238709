import tierService from "./tierService";
import moment from "moment";

import {
  ACTION_TYPE_ENUM,
  MASTER_ACTION_ENUM,
  ENABLE_IN_SMART_BOX_ENUM,
  SPECIAL_TIER_ENUM,
  ACTION_TYPE_OPTIONS,
  DEFAULT_ACTION_TYPE_OPTIONS,
  ACTION_STATUS_ENUM,
  MASTER_ACTIONS,
  PROMOTION_USAGE_TYPE_ENUM,
  REWARD_TOKEN,
  INACTIVE_STATUS_TOOLTIPS,
  SCHEDULING_RECURRENCE_TYPE_ENUM
} from "../constants/actionConstants";

import planService from "../services/planService";
import { getMessage } from "../messages";

export default class actionService {
  static isRewardTierEnabled = (masterAction, obj) => {
    if (!obj) {
      return false;
    }

    if (actionService.isRedemptionAction(masterAction)) {
      return !!obj.points;
    }

    return (
      obj.multiplyPointsEnabled ||
      (obj.pointsEnabled && obj.points !== undefined) ||
      obj.rewardPoolEnabled ||
      obj.exclusiveAccessEnabled ||
      obj.statusUpgradeEnabled
    );
  };

  static isPromotionAction = (masterAction) => masterAction === MASTER_ACTION_ENUM.PROMOTIONS;

  static isReferralAction = (masterAction) => masterAction === MASTER_ACTION_ENUM.REFERRAL;

  // TODO: remove
  static isRedemptionAction = (masterAction) => {
    return masterAction === MASTER_ACTION_ENUM.REDEMPTION;
  };

  static isEngagementAction = (masterAction) => masterAction === MASTER_ACTION_ENUM.ENGAGEMENTS;

  static isEventAction = (masterAction) => masterAction === MASTER_ACTION_ENUM.EVENTS;

  static isJoinAction = (masterAction) => {
    return masterAction === MASTER_ACTION_ENUM.JOIN;
  };

  static isPurchasesAction = (masterAction) => {
    return masterAction === MASTER_ACTION_ENUM.PURCHASES;
  };

  static isAttachable = (action) =>
    (actionService.isPurchasesAction(action.masterAction) && !actionService.isPromotionAction(action.masterAction)) ||
    actionService.isEngagementAction(action.masterAction) ||
    actionService.isJoinAction(action.masterAction);

  static isPromotionEnded = (action) => {
    if (action.isDefault || !actionService.isPromotionAction(action.masterAction)) {
      return false;
    }

    const now = moment.utc();
    return now > moment(action.scheduling.promoValidity.end);
  };

  static isRecurring = (action) => {
    return (
      actionService.isPromotionAction(action.masterAction) &&
      action.scheduling?.recurringType === SCHEDULING_RECURRENCE_TYPE_ENUM.RECURRING
    );
  };

  static showAvailabilityfield = (action) => {
    return (
      action.scheduling.enableInSmartBox === ENABLE_IN_SMART_BOX_ENUM.YES &&
      (action.masterAction === MASTER_ACTION_ENUM.JOIN ||
        action.masterAction == MASTER_ACTION_ENUM.EVENTS ||
        actionService.isPromotionAction(action.masterAction))
    );
  };

  static getActionTitle = (action) => {
    return action.internalName ? `${action.internalName} / ${action.name}` : action.name;
  };

  static getActionTypeOptions = (action) => {
    const options = action.isDefault
      ? DEFAULT_ACTION_TYPE_OPTIONS[action.masterAction]
      : ACTION_TYPE_OPTIONS[action.masterAction];

    return options.map((opt) => {
      const name = opt.tactic ? `${opt.name} / ${opt.tactic}` : opt.name;
      return {
        name,
        value: opt.value
      };
    });
  };

  static getActionsConnectedToReward = (rewardId, actions) => {
    let actionNames = [];

    actions
      .filter((action) => !!action.reward)
      .map((action) => {
        const rewardTiers = action.reward.value;

        for (const tierId in rewardTiers) {
          const rewardTier = rewardTiers[tierId];
          if (
            (rewardTier.rewardPool === rewardId && rewardTier.rewardPoolEnabled) ||
            (rewardTier.exclusiveAccess === rewardId && rewardTier.exclusiveAccessEnabled)
          ) {
            actionNames.push(action.name);
            break;
          }
        }
      });

    return actionNames;
  };

  static getActionsByMaster = (actions, masterAction, excludeDefault) => {
    const result = actions.filter((action) => action.masterAction === masterAction);

    if (excludeDefault) {
      return result.filter((action) => !action.isDefault);
    }

    return result;
  };

  static getEventActionsForBenefits = (actions) =>
    actionService.getActionsByMaster(actions, MASTER_ACTION_ENUM.EVENTS, true).filter((action) => action.isValid);

  static getCardExtraLabels = (action) => {
    let extraLabels = [];
    if (action.tactic) {
      extraLabels.push({
        type: "tactic",
        text: action.tactic
      });
    } else if (action.isDefault) {
      extraLabels.push({
        type: "default",
        text: getMessage("status.default")
      });
    } else if (actionService.isPromotionAction(action.masterAction)) {
      extraLabels.push(
        {
          type: "promotion",
          text:
            action.promotion.usageType === PROMOTION_USAGE_TYPE_ENUM.MULTI_USE
              ? getMessage("promotionType.multiUse.name")
              : getMessage("promotionType.oneTime.name")
        },
        {
          type: "promotion-recurrence",
          text:
            action.scheduling.recurringType === SCHEDULING_RECURRENCE_TYPE_ENUM.ONE_TIME
              ? getMessage("actions.card.label.nonRecurring")
              : getMessage("actions.card.label.recurring")
        }
      );
    }

    return extraLabels;
  };

  static doShowCardDelete = (action) => {
    return !action.tactic && !action.isDefault;
  };

  static doShowCardDuplicate = (action) => {
    return !action.isDefault;
  };

  static getRewardRules = (tierId, masterAction) => {
    return {
      enablePoints: tierId !== SPECIAL_TIER_ENUM.GUEST,
      enableMultiplyPoints:
        masterAction === MASTER_ACTION_ENUM.PURCHASES || masterAction === MASTER_ACTION_ENUM.PROMOTIONS,
      enableVoucher: true,
      enableExclusiveAccess: masterAction !== MASTER_ACTION_ENUM.REFERRAL,
      enableStatusUpgrade: masterAction !== MASTER_ACTION_ENUM.REFERRAL
    };
  };

  static getEmptyContentObj = ({ token, primaryLanguage, storeUrlLangMap }) => {
    return {
      blockMessage: token,
      ctaButton: "",
      ctaUrl: storeUrlLangMap[primaryLanguage] || "http://",
      ctaUrlMl: storeUrlLangMap
    };
  };

  static isActionAttached = (action) => action.isAttachedToContact;

  static getActionStatus = (action) => {
    if (!action.isValid || action.hasInvalidPools) {
      return ACTION_STATUS_ENUM.INACTIVE;
    }

    if (!action.activated) {
      return ACTION_STATUS_ENUM.DEACTIVATED;
    }

    if (action.isDefault) {
      return ACTION_STATUS_ENUM.ACTIVE;
    } else if (actionService.isPromotionAction(action.masterAction)) {
      if (action.promotion.showInWallet || actionService.isActionAttached(action)) {
        return ACTION_STATUS_ENUM.ACTIVE;
      }
    } else if (
      actionService.isEventAction(action.masterAction) ||
      actionService.isReferralAction(action.masterAction)
    ) {
      return ACTION_STATUS_ENUM.ACTIVE;
    } else if (actionService.isAttachable(action)) {
      //check of action is attached to contact
      if (actionService.isActionAttached(action)) {
        return ACTION_STATUS_ENUM.ACTIVE;
      }
    }

    return ACTION_STATUS_ENUM.READY_TO_USE;
  };

  static isActionActive = (action) => {
    const status = actionService.getActionStatus(action);
    return status === ACTION_STATUS_ENUM.ACTIVE;
  };

  static hasRewardForNonMember = (action) => {
    const nonMemberRewardValue = action.reward.value[SPECIAL_TIER_ENUM.NON];
    const allRewardValue = action.reward.value[SPECIAL_TIER_ENUM.ALL];
    if (!nonMemberRewardValue && !allRewardValue) {
      return false;
    }

    return (
      actionService.isRewardTierEnabled(action.masterAction, nonMemberRewardValue) ||
      (actionService.isForNewMembers(action) && actionService.isRewardTierEnabled(action.masterAction, allRewardValue))
    );
  };

  static isForNewMembers = (action) => {
    return (
      !actionService.isPromotionAction(action.masterAction) &&
      !actionService.isEventAction(action.masterAction) &&
      !actionService.isReferralAction(action.masterAction) &&
      !(action.config && action.config.isOnlyForMembers)
    );
  };

  static hasRewardForMember = (action) => {
    const rewardTierValue = action.reward.value;
    return Object.keys(rewardTierValue).some(
      (key) => key !== SPECIAL_TIER_ENUM.NON && actionService.isRewardTierEnabled(action.masterAction, rewardTierValue)
    );
  };

  static hasRewardWithVoucher = (action) => {
    const rewardTierValue = action.reward.value;
    return Object.keys(rewardTierValue).some((key) => rewardTierValue[key].rewardPoolEnabled);
  };

  static getConnectedRewardVouchers = (action) => {
    const rewardTierValue = action.reward?.value;
    if (!rewardTierValue) {
      return [];
    }
    return Object.keys(rewardTierValue)
      .filter((key) => rewardTierValue[key].rewardPoolEnabled)
      .map((key) => rewardTierValue[key].rewardPool);
  };

  static getDefaultIcon = (masterAction) => {
    return MASTER_ACTIONS[masterAction].defaultIcon;
  };

  static hasCatalogSelection = (action) => {
    return (
      actionService.isPromotionAction(action.masterAction) && action.actionType === ACTION_TYPE_ENUM.PROMOTIONS_CATALOG
    );
  };

  static isPromotionDisplayedInWallet = (action) => {
    return actionService.isPromotionAction(action.masterAction) && !action.isDefault && action.promotion.showInWallet;
  };

  static getRewardsText = (action, tierId) => {
    if (!action) {
      return "";
    }

    let rewardValue = action.reward.value[SPECIAL_TIER_ENUM.ALL];
    if (rewardValue.rewardsText) {
      return rewardValue.rewardsText;
    }

    rewardValue = action.reward.value[tierId];
    if (rewardValue.rewardsText) {
      return rewardValue.rewardsText;
    }

    return "";
  };

  static isSupportApiTriggers = (action) => {
    return (
      !action.isDefault &&
      (actionService.isEngagementAction(action.masterAction) || actionService.isEventAction(action.masterAction))
    );
  };

  static isBlockMessageEmpty = (blockMessage) => {
    return !blockMessage || blockMessage === REWARD_TOKEN.token;
  };

  static getActionNameForDuplicated = (currentName, otherActionsNames) => {
    let newActionName = getMessage("copyOf", { value: currentName });
    let counter = 1;
    while (counter < 100) {
      if (!otherActionsNames.includes(newActionName)) {
        return newActionName;
      }
      newActionName = getMessage("numberedCopyOf", { index: ++counter, value: currentName });
    }
  };

  static hasMinOrder = (action) => {
    return (
      !action.isDefault &&
      [MASTER_ACTION_ENUM.PURCHASES, MASTER_ACTION_ENUM.PROMOTIONS, MASTER_ACTION_ENUM.REFERRAL].includes(
        action.masterAction
      )
    );
  };

  static hasMinOrderDisplay = (action) => {
    return (
      !action.isDefault && [MASTER_ACTION_ENUM.PURCHASES, MASTER_ACTION_ENUM.PROMOTIONS].includes(action.masterAction)
    );
  };

  static hasExternalLink = (action) => {
    return !action.isDefault && ![MASTER_ACTION_ENUM.JOIN, MASTER_ACTION_ENUM.REFERRAL].includes(action.masterAction);
  };

  static getRewardTiersIsZeroPoints = ({ action, tiersById, currencies }) => {
    const rewardTiers = action.reward.value;
    let result = {};

    let hasTierWithoutZeroPoints = false;

    Object.keys(rewardTiers).forEach((tierId) => {
      if (tierId != SPECIAL_TIER_ENUM.NON && tierId != SPECIAL_TIER_ENUM.ALL && tierId != SPECIAL_TIER_ENUM.GUEST) {
        result[tierId] = tierService.areAllPurchasePointsZero(tiersById[tierId], currencies);

        if (!result[tierId]) {
          hasTierWithoutZeroPoints = true;
        }
      }
    });

    result[SPECIAL_TIER_ENUM.ALL] = result[SPECIAL_TIER_ENUM.NON] = !hasTierWithoutZeroPoints;

    return result;
  };

  static getStatusLabel = (status, action) => {
    if (status === ACTION_STATUS_ENUM.INACTIVE) {
      let tooltip = INACTIVE_STATUS_TOOLTIPS.default;

      if (actionService.isPromotionEnded(action)) {
        tooltip = INACTIVE_STATUS_TOOLTIPS.promotionEnded;
      }

      return {
        label: getMessage("status.inactive"),
        className: "e-label-default background-color-placeholder",
        tooltip
      };
    } else if (status === ACTION_STATUS_ENUM.READY_TO_USE) {
      let tooltip;

      if (actionService.isPromotionAction(action.masterAction)) {
        tooltip = READY_TO_USE_STATUS_TOOLTIPS.promotion;
      } else if (actionService.isAttachable(action)) {
        tooltip = READY_TO_USE_STATUS_TOOLTIPS.attachable;
      }

      return {
        label: getMessage("status.readyToUse"),
        className: "e-label-warning",
        tooltip
      };
    } else if (status === ACTION_STATUS_ENUM.ACTIVE) {
      let tooltip;
      if (!action.isDefault && actionService.isPromotionAction(action.masterAction)) {
        tooltip = ACTIVE_STATUS_TOOLTIPS.promotion;
      } else if (!action.isDefault && actionService.isAttachable(action)) {
        tooltip = ACTIVE_STATUS_TOOLTIPS.attachable;
      } else if (!action.isDefault && actionService.isEventAction(action.masterAction)) {
        tooltip = ACTIVE_STATUS_TOOLTIPS.event;
      }

      return {
        label: getMessage("status.active"),
        className: "e-label-primary",
        tooltip
      };
    } else if (status === ACTION_STATUS_ENUM.DEACTIVATED) {
      let tooltip;

      if (actionService.isPromotionAction(action.masterAction)) {
        tooltip = DEACTIVATED_STATUS_TOOLTIPS.promotion;
      } else if (actionService.isAttachable(action)) {
        tooltip = DEACTIVATED_STATUS_TOOLTIPS.attachable;
      }

      return {
        label: getMessage("status.deactivated"),
        className: "e-label-danger",
        tooltip
      };
    }
  };

  static getMultiplyPointsLabel = (plan, action) => {
    if (planService.isSpendPlan(plan)) return getMessage("actionSetupTierFields.multiplyPointsEnabled.label.spend");
    return getMessage(`actionSetupTierFields.multiplyPointsEnabled.label.points.${action.pointsType}`);
  };

  static getBonusPointsLabel = (plan, action) => {
    if (planService.isSpendPlan(plan)) return getMessage("actionSetupTierFields.pointsEnabled.label.spend");
    return getMessage(`actionSetupTierFields.pointsEnabled.label.points.${action.pointsType}`);
  };
}
