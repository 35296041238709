import * as TYPES from "../actions/TYPES";

const initialState = {
  allIcons: [],
  icons: [],
  customerIcons: [],
  loading: false,
  searchValue: "",
  showUploadErrorDialog: false,
  errors: [],
  iconsLoaded: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.ICONS_LOAD_FINISHED: {
      return {
        ...state,
        iconsLoaded: true,
        loading: false
      };
    }
    case TYPES.ICONS_LOAD_REQUEST: {
      return {
        ...state,
        iconsLoaded: false,
        loading: true
      };
    }
    case TYPES.ICONS_LOAD_SUCCESS: {
      return {
        ...state,
        icons: action.icons,
        customerIcons: action.customerIcons,
        iconsLoaded: true,
        loading: false
      };
    }

    case TYPES.ICON_ADD_REQUEST: {
      return {
        ...state,
        loading: true,
        postSuccess: false
      };
    }
    case TYPES.ICON_ADD_SUCCESS: {
      let { customerIcons } = { ...state };
      let icons = customerIcons.filter((icon) => icon.name != action.payload.name);
      icons.unshift(action.payload);
      return {
        ...state,
        loading: false,
        postSuccess: true,
        customerIcons: icons
      };
    }
    case TYPES.ICON_ADD_FAILURE: {
      let { errors } = { ...state };

      errors.push(action.payload);
      return {
        ...state,
        showUploadErrorDialog: true,
        errors
      };
    }
    case TYPES.ICON_DELETE_SUCCESS: {
      let { customerIcons } = { ...state };
      const icons = customerIcons.filter((icon) => icon.name != action.payload);
      return {
        ...state,
        customerIcons: icons,
        showUploadErrorDialog: false,
        errors: []
      };
    }
    case TYPES.ICON_CLEAR_ERRORS: {
      return {
        ...state,
        showUploadErrorDialog: false,
        errors: []
      };
    }
    case TYPES.ICON_EDIT_MODE: {
      let { customerIcons } = { ...state };
      let index = customerIcons.findIndex((icon) => icon.name == action.payload);
      let iconToEdit = customerIcons[index];
      customerIcons[index] = { ...iconToEdit, editMode: true, originalName: action.payload };
      return {
        ...state,
        customerIcons
      };
    }
    case TYPES.ICON_EDIT_NAME: {
      let { customerIcons } = { ...state };
      let index = customerIcons.findIndex((icon) => icon.name == action.payload || !!icon.editMode);
      let iconToEdit = customerIcons[index];
      customerIcons[index] = { ...iconToEdit, name: action.newName };
      return {
        ...state,
        customerIcons
      };
    }
    case TYPES.ICON_EDIT_REQUEST: {
      let { customerIcons } = { ...state };
      let index = customerIcons.findIndex((icon) => !!icon.editMode);
      let iconToEdit = customerIcons[index];
      customerIcons[index] = { ...iconToEdit, editMode: false, inUpdate: true };
      return {
        ...state,
        customerIcons
      };
    }
    case TYPES.ICON_EDIT_SUCCESS: {
      let { customerIcons } = { ...state };
      let index = customerIcons.findIndex((icon) => !!icon.inUpdate);
      let iconToEdit = customerIcons[index];
      delete iconToEdit.originalName;
      customerIcons[index] = { ...iconToEdit, editMode: false, inUpdate: false };
      return {
        ...state,
        customerIcons
      };
    }
    case TYPES.ICON_EDIT_FAILURE: {
      let { customerIcons } = { ...state };
      let index = customerIcons.findIndex((icon) => !!icon.inUpdate || icon.originalName == action.payload);
      let iconToEdit = customerIcons[index];
      const { originalName } = { ...iconToEdit };
      customerIcons[index] = { ...iconToEdit, editMode: false, inUpdate: false, name: originalName };
      return {
        ...state,
        customerIcons
      };
    }
    default:
      return state;
  }
}

export const selectIcons = (state) => {
  const { icons } = { ...state };
  return icons;
};

export const selectCustomerIcons = (state) => {
  const { customerIcons } = { ...state };
  return customerIcons;
};

export const selectShowUploadErrorDialog = (state) => {
  const { showUploadErrorDialog } = { ...state };
  return showUploadErrorDialog;
};

export const selectErrors = (state) => {
  const { errors } = { ...state };
  return errors;
};

export const selectEditedIcon = (state) => {
  const { customerIcons } = { ...state };
  return customerIcons.find((icon) => !!icon.editMode);
};
