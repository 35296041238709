import React, { Fragment } from "react";
import PropTypes from "prop-types";

import InputField from "../../components/InputField";
import TextareaField from "../../components/TextareaField";
import MultiLangPopupOpener from "../../components/MultiLangPopupOpener";

import { getErrorMessage } from "../../validators";

import { actionType } from "../../types";

import { MASTER_ACTION_ENUM } from "../../constants/actionConstants";
import { getMessage } from "../../messages";
import actionService from "../../services/actionService";

const ActionSetupLoyaltyMessage = (props) => {
  const {
    action,
    isJoinAction,
    onInputChange,
    errors,
    viewMode,
    rewardToken,
    hasRewardForNonMember,
    defaultJoinAction,
    actionTypeText,
    onShowMemberMultiLang,
    onShowNonMemberMultiLang,
    hasMultiLanguage
  } = props;

  const renderFields = ({
    header,
    label,
    tooltipMessage,
    namePrefix,
    contentObj,
    isMember,
    disableAllFields,
    disableCtaText
  }) => {
    const token =
      contentObj.blockMessage.indexOf(rewardToken.token) > -1
        ? {
            token: rewardToken.token,
            display: rewardToken.display
          }
        : null;

    const ctaTooltip = disableCtaText ? getMessage("actionSetupLoyaltyMessage.ctaButton.tooltip") : null;

    const disabled = viewMode || disableAllFields;

    return (
      <div className={`e-row ${isMember === 0 ? "m-b-15" : ""}`}>
        <div className="e-col-12">
          <h2 className="no-margin">{header}</h2>
          {/* Block Message */}
          <TextareaField
            label={label}
            name={`${namePrefix}.blockMessage`}
            value={contentObj.blockMessage}
            onChange={onInputChange}
            tooltip={tooltipMessage}
            isRequired={true}
            errorMsg={getErrorMessage(errors, `${namePrefix}.blockMessage`)}
            disabled={disabled}
            tokenObj={token}
            height="70px"
            postfixButtons={
              hasMultiLanguage ? (
                <MultiLangPopupOpener
                  onClick={isMember ? onShowMemberMultiLang : onShowNonMemberMultiLang}
                  disabled={disableAllFields}
                />
              ) : null
            }
          />
        </div>
        <div className="e-col-6">
          {/* CTA Button */}
          <InputField
            label={getMessage("actionSetupLoyaltyMessage.ctaButton.label")}
            type="text"
            name={`${namePrefix}.ctaButton`}
            value={contentObj.ctaButton}
            maxLength="255"
            onChange={onInputChange.bind(this)}
            disabled={viewMode || disableAllFields || disableCtaText}
            errorMsg={getErrorMessage(errors, `${namePrefix}.ctaButton`)}
            tooltip={ctaTooltip}
            isRequired={true}
          />
        </div>
        <div className="e-col-6">
          {/* CTA Link */}
          <InputField
            label={getMessage("actionSetupLoyaltyMessage.ctaUrl.label")}
            type="text"
            name={`${namePrefix}.ctaUrl`}
            value={contentObj.ctaUrl || "http://"}
            maxLength="255"
            onChange={onInputChange.bind(this)}
            disabled={viewMode || disableAllFields}
            errorMsg={getErrorMessage(errors, `${namePrefix}.ctaUrl`)}
            isRequired={true}
            tooltip={
              actionService.isEventAction(action.masterAction)
                ? getMessage("actionSetupLoyaltyMessage.ctaUrl.tooltip.eventAction")
                : ""
            }
          />
        </div>
      </div>
    );
  };

  const label =
    action.isDefault && isJoinAction
      ? getMessage("actionSetupLoyaltyMessage.label.join")
      : getMessage("actionSetupLoyaltyMessage.label.other");

  const tooltipMessage = action.isDefault
    ? ""
    : getMessage("actionSetupLoyaltyMessage.tooltip.members.nonDefault", {
        actionTypeText: actionTypeText.toLowerCase()
      });

  const nonMembersTooltipMessage = hasRewardForNonMember
    ? getMessage("actionSetupLoyaltyMessage.tooltip.nonmembers.hasRewardForNonMembers")
    : getMessage("actionSetupLoyaltyMessage.tooltip.nonmembers.noRewardForNonMembers");

  const isRenderDefaultJoinContent = !isJoinAction && !hasRewardForNonMember;
  const nonMemberContent = isRenderDefaultJoinContent ? defaultJoinAction.content.nonMember : action.content.nonMember;

  const disableCtaText = action.masterAction && action.masterAction === MASTER_ACTION_ENUM.EVENTS ? true : false;

  return (
    <Fragment>
      {props.notificationElem}
      {props.renderMember
        ? renderFields({
            header: getMessage("actionSetupLoyaltyMessage.header.members"),
            isMember: true,
            label,
            tooltipMessage,
            namePrefix: "content.member",
            contentObj: action.content.member,
            disableCtaText
          })
        : null}
      {props.renderNonMember
        ? renderFields({
            header: getMessage("actionSetupLoyaltyMessage.header.nonMembers"),
            isMember: false,
            label,
            tooltipMessage: nonMembersTooltipMessage,
            namePrefix: "content.nonMember",
            contentObj: nonMemberContent,
            disableAllFields: isRenderDefaultJoinContent
          })
        : null}
    </Fragment>
  );
};

ActionSetupLoyaltyMessage.propTypes = {
  action: actionType.isRequired,
  defaultJoinAction: actionType,
  onInputChange: PropTypes.func.isRequired,
  renderMember: PropTypes.bool.isRequired,
  renderNonMember: PropTypes.bool.isRequired,
  isJoinAction: PropTypes.bool,
  hasRewardForNonMember: PropTypes.bool,
  errors: PropTypes.object,
  viewMode: PropTypes.bool,
  notificationElem: PropTypes.element,
  actionTypeText: PropTypes.string,
  onShowMemberMultiLang: PropTypes.func,
  onShowNonMemberMultiLang: PropTypes.func,
  hasMultiLanguage: PropTypes.bool
};

export default ActionSetupLoyaltyMessage;
