import validate from "validate.js";
import pluralize from "pluralize";
import { FORM_STEP_ENUM, TIER_DROPOUT_ENUM, TIER_TYPE_ENUM, POINTS_TYPE_ENUM } from "../constants/tierConstants";
import { TIERS_CALCULATION_TYPE_ENUM } from "../constants/planConstants";
import { SPECIAL_TIER_ENUM } from "../constants/actionConstants";

import tierService from "../services/tierService";
import planService from "../services/planService";
import { convertPathStrName } from ".";
import { getMessage, getPluralCardinalMessage } from "../messages";
import { actionType } from "../types";

validate.validators.pointTypeActionsConflict = function (value, options, key, attributes) {
  const { actions, tierId, message } = options;
  if (
    value != POINTS_TYPE_ENUM.LOYALTY &&
    actions.filter(
      (action) =>
        (action.masterAction == "purchases" || action.masterAction == "promotions") &&
        !action.isDefault &&
        action.isValid &&
        !!action.pointsType &&
        action.pointsType != value &&
        (action.reward.value[tierId]?.multiplyPointsEnabled ||
          action.reward.value[SPECIAL_TIER_ENUM.ALL]?.multiplyPointsEnabled)
    ).length > 0
  ) {
    let actionNames = actions
      .filter(
        (action) =>
          (action.masterAction == "purchases" || action.masterAction == "promotions") &&
          !action.isDefault &&
          action.isValid &&
          !!action.pointsType &&
          action.pointsType != value &&
          (action.reward.value[tierId]?.multiplyPointsEnabled ||
            action.reward.value[SPECIAL_TIER_ENUM.ALL]?.multiplyPointsEnabled)
      )
      .map((act) => act.masterAction.charAt(0).toUpperCase() + act.masterAction.slice(1) + "\\" + act.name)
      .join(", ");
    return getMessage("tierSetupPurchaseBenefits.pointsType.error", { actions: actionNames });
  } else {
    return undefined;
  }
};

validate.validators.rangeTierPoints = function (value, options, key, attributes) {
  const { prevTierPoints, nextTierPoints } = options;

  if (nextTierPoints && value > nextTierPoints - 1) {
    const nextPoints = nextTierPoints - 1;
    return getPluralCardinalMessage("rangeTierPoints.nextTierPoints", nextPoints, { number: nextPoints });
  }

  // Last tier - check minimun only
  if (value < prevTierPoints + 1) {
    const pervPoints = prevTierPoints + 1;
    return getPluralCardinalMessage("rangeTierPoints.prevTierPoints", pervPoints, { number: pervPoints });
  }

  return undefined;
};

validate.validators.purchaseBenefitCatalog = function (benefit, options, key, tier) {
  if (!tierService.isPurchaseBenefitCatalogSelected(benefit)) {
    return options.message;
  }

  return undefined;
};

export default class tierValidator {
  constructor({
    languages,
    currencies,
    prevTierPoints,
    nextTierPoints,
    tierNames,
    tiersCalculationType,
    plan,
    tierId,
    actions
  }) {
    this.stepConstraints = {};
    this.prevTierPoints = prevTierPoints;
    this.nextTierPoints = nextTierPoints;
    this.tierNames = tierNames;
    this.languages = languages;
    this.currencies = currencies;
    this.tiersCalculationType = tiersCalculationType;
    this.plan = plan;
    this.tierId = tierId;
    this.actions = actions;
  }

  createSettingsStepConstraints(tier) {
    let constraints = {
      name: {
        presence: {
          allowEmpty: false,
          message: getMessage("tierValidator.name.presence")
        },
        exclusionIgnoreCase: {
          within: this.tierNames,
          message: getMessage("tierValidator.name.exclusionIgnoreCase")
        }
      },
      nameML: {
        multiLangField: {
          languages: this.languages,
          message: getMessage("tierValidator.nameML.multiLangField")
        }
      }
    };

    if (tierService.isRegularTier(tier)) {
      constraints["settings.points"] = {
        presence: {
          allowEmpty: false,
          message: getMessage("tierValidator.points.presence")
        },
        rangeTierPoints: {
          prevTierPoints: this.prevTierPoints,
          nextTierPoints: this.nextTierPoints
        }
      };

      if (tier.calculation?.tierDropout === TIER_DROPOUT_ENUM.YES) {
        let tierExpirationFieldName = "";
        if (this.tiersCalculationType === TIERS_CALCULATION_TYPE_ENUM.END_OF_YEAR) {
          tierExpirationFieldName = "calculation.tiersCalculationYearlyInterval";
        } else if (this.tiersCalculationType === TIERS_CALCULATION_TYPE_ENUM.AFTER_JOIN) {
          tierExpirationFieldName = "calculation.tiersCalculationMonthlyInterval";
        }

        if (tierExpirationFieldName) {
          constraints[tierExpirationFieldName] = {
            presence: {
              allowEmpty: false,
              message: getMessage("planSettingsConstraints.planSettings.rules.tiersCalculationYearlyInterval.presence")
            },
            numericality: {
              onlyInteger: true,
              notInteger: getMessage(
                "planSettingsConstraints.planSettings.rules.tiersCalculationYearlyInterval.numericality.notInteger"
              ),
              greaterThan: 0,
              notGreaterThan: getMessage(
                "planSettingsConstraints.planSettings.rules.tiersCalculationYearlyInterval.numericality.notGreaterThan"
              )
            }
          };
        }
      }
    }

    return constraints;
  }

  createPurchaseBenefitsStepConstraints(tier) {
    let constraints = {};

    if (!tierService.hasZeroPointsRate(tier.purchaseBenefits.pointsBenefit, this.currencies)) {
      constraints = {
        "purchaseBenefits.pointsBenefit.title": {
          presence: {
            allowEmpty: false,
            message: getMessage("excluseiveAccesssValidator.redemptionConstriants.message.title")
          }
        },
        "purchaseBenefits.pointsBenefit.text": {
          presence: {
            allowEmpty: false,
            message: getMessage("excluseiveAccesssValidator.redemptionConstriants.message.text")
          }
        },
        "purchaseBenefits.pointsBenefit.titleML": {
          multiLangField: {
            languages: this.languages,
            message: getMessage("tierValidator.pointsBenefit.titleML.multiLangField")
          }
        },
        "purchaseBenefits.pointsBenefit.textML": {
          multiLangField: {
            languages: this.languages,
            message: getMessage("tierValidator.pointsBenefit.textML.multiLangField")
          }
        }
      };
    }

    constraints = {
      ...constraints,
      ...this.createPointsRateConstraints(tier.purchaseBenefits.pointsBenefit, "purchaseBenefits.pointsBenefit")
    };

    tier.purchaseBenefits.pointsCatalogBenefits.forEach((benefit, index) => {
      if (
        tierService.hasPointsRateValue(benefit, this.currencies) ||
        index > 0 ||
        tierService.isPurchaseBenefitCatalogSelected(benefit)
      ) {
        constraints = {
          ...constraints,
          ...this.createPointsRateConstraints(benefit, `purchaseBenefits.pointsCatalogBenefits.${index}`)
        };
      }

      //if additional points has value, validate catalog selection
      if (
        tierService.hasPointsRateValue(benefit, this.currencies) ||
        tierService.hasZeroPointsRate(benefit, this.currencies)
      ) {
        let catalogConstraints = {
          [`purchaseBenefits.pointsCatalogBenefits.${index}`]: {
            purchaseBenefitCatalog: {
              message: getMessage("tierValidator.purchaseBenefits.pointsCatalogBenefits.purchaseBenefitCatalog")
            }
          }
        };

        if (!tierService.hasZeroPointsRate(benefit, this.currencies)) {
          catalogConstraints = {
            ...catalogConstraints,
            [`purchaseBenefits.pointsCatalogBenefits.${index}.title`]: {
              presence: {
                allowEmpty: false,
                message: getMessage("excluseiveAccesssValidator.redemptionConstriants.message.title")
              }
            },
            [`purchaseBenefits.pointsCatalogBenefits.${index}.text`]: {
              presence: {
                allowEmpty: false,
                message: getMessage("excluseiveAccesssValidator.redemptionConstriants.message.text")
              }
            },
            [`purchaseBenefits.pointsCatalogBenefits.${index}.titleML`]: {
              multiLangField: {
                languages: this.languages,
                message: getMessage("tierValidator.pointsBenefit.titleML.multiLangField")
              }
            },
            [`purchaseBenefits.pointsCatalogBenefits.${index}.textML`]: {
              multiLangField: {
                languages: this.languages,
                message: getMessage("tierValidator.pointsBenefit.textML.multiLangField")
              }
            }
          };
        }

        constraints = { ...constraints, ...catalogConstraints };
      }
    });

    if (planService.hasRedemption(this.plan)) {
      let pointTypeConstraint = {
        ["pointsType"]: {
          pointTypeActionsConflict: {
            actions: this.actions,
            tierId: this.tierId,
            message: "bla"
          }
        }
      };
      constraints = { ...constraints, ...pointTypeConstraint };
    }
    return constraints;
  }

  createPointsRateConstraints(benefit, propNamePrefix) {
    let constraints = {};
    const hasZeroPoints = tierService.hasZeroPointsRate(benefit, this.currencies);
    const hasPointsValue = tierService.hasPointsRateValue(benefit, this.currencies);

    if (!hasZeroPoints || hasPointsValue) {
      this.currencies.map((currency) => {
        const pointsKey = `${propNamePrefix}.pointsRates.${currency}.points`;
        const spentKey = `${propNamePrefix}.pointsRates.${currency}.spent`;
        constraints[pointsKey] = {
          presence: {
            allowEmpty: false,
            message: getMessage("tierValidator.pointsRates.points.presence")
          },
          numericality: {
            onlyInteger: !planService.isSpendPlan(this.plan) ? true : false,
            notInteger: getMessage("excluseiveAccesssValidator.redemptionConstriants.value.points.notInteger"),
            greaterThan: hasPointsValue ? 0 : null,
            notGreaterThan: getMessage("excluseiveAccesssValidator.redemptionConstriants.value.points.notGreaterThan")
          }
        };
        constraints[spentKey] = {
          presence: {
            allowEmpty: false,
            message: getMessage("tierValidator.pointsRates.spent.presence")
          },
          numericality: {
            onlyInteger: !planService.isSpendPlan(this.plan) ? true : false,
            notInteger: getMessage("tierValidator.pointsRates.spent.numericality.notInteger"),
            greaterThan: hasPointsValue ? 0 : null,
            notGreaterThan: getMessage("tierValidator.pointsRates.spent.numericality.notGreaterThan")
          }
        };
      });
    }

    return constraints;
  }

  validateStep = (stepId, tier) => {
    let constraints = {};

    switch (stepId) {
      case FORM_STEP_ENUM.TIER_SETTINGS:
        constraints = this.createSettingsStepConstraints(tier);
        break;
      case FORM_STEP_ENUM.PURCHASE_BENEFITS:
        constraints = this.createPurchaseBenefitsStepConstraints(tier);
        break;
    }
    return constraints ? validate(tier, constraints) : undefined;
  };

  validateMultiLangPopup = (fields) => {
    let constraints = {};
    this.languages.forEach((lang) => {
      Object.keys(fields).forEach((key) => {
        let message;

        if (key == "nameML") {
          message = getMessage("tierValidator.name.presence");
        } else if (key.indexOf("titleML") > 0) {
          message = getMessage("excluseiveAccesssValidator.redemptionConstriants.message.title");
        } else if (key.indexOf("titleML") > 0) {
          message = getMessage("excluseiveAccesssValidator.redemptionConstriants.message.text");
        }
        const langKey = `${convertPathStrName(key)}.${lang}`;
        constraints[langKey] = {
          presence: {
            allowEmpty: false,
            message
          }
        };
      });
    });

    return validate(fields, constraints);
  };
}
