import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { BrowserRouter } from "react-router-dom";

import Main from "./pages/Main";
import Overlay from "./components/Overlay";
import AlertContainer from "./components/AlertContainer";
import TopMenuBar from "./Layouts/TopMenuBar";

import { getCustomer, getEmsConfig, getEmsAccountPermissions } from "./actions/customerActions";
import { getPlan } from "./actions/planActions";
import { getAppPlanData } from "./actions";
import { getJwt } from "./actions/sessionActions";
import { getTranslations } from "./actions/translationAction";

import { setApiDefaults } from "./api/api";
import { initValidators } from "./validators";
import { isDemoMode } from "./helpers/envHelper";
import { getNavConfirmation } from "./helpers/navHelper";
import sessionHelper from "./helpers/sessionHelper";
import { setDefaultTimezone } from "./helpers/timeHelper";

import {
  selectCustomerId,
  selectCustomerPlans,
  selectIsPlanActivated,
  selectJwtToken,
  isAppDataLoaded,
  isSessionDataLoaded,
  isCustomerLoaded,
  isPlanLoaded,
  selectIsBeforeFirstPlanLaunch,
  isEmsConfigLoaded,
  selectEmsAdminId,
  selectEmsAdminTimezone
} from "./reducers";

import "./assets/styles/App.scss";

class App extends Component {
  constructor(props) {
    super(props);
  }

  //if jwt token is already is session storage then load customer data
  componentWillMount() {
    this.props.getEmsConfig();
    initValidators();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isEmsConfigLoaded && this.props.isEmsConfigLoaded) {
      let urlToValidate = window.location.href;
      if (process.env.REACT_APP_HOST_MOCK) {
        urlToValidate = window.location.href.replace(window.location.origin, process.env.REACT_APP_HOST_MOCK);
      }

      this.props.getJwt(urlToValidate);
    }

    //wait until token is received via api then load customer data
    if (!prevProps.jwtToken && this.props.jwtToken) {
      this.loadCustomer();
    }

    //load rest of the data only after customer data received
    if (!prevProps.customerId && this.props.customerId && this.props.hasPlans) {
      this.props.getPlan();
    }

    if (
      (!prevProps.isPlanLoaded && this.props.isPlanLoaded) ||
      (!prevProps.isPlanActivated && this.props.isPlanActivated)
    ) {
      this.props.getAppPlanData();
      this.props.getTranslations();
    }
  }

  loadCustomer() {
    const queryParams = queryString.parse(document.location.search);
    let customerId = queryParams.customer_id || sessionHelper.getCustomerId();
    if (!!queryParams.showMenuBar) {
      sessionStorage.setItem("showMenuBar", true);
    }
    if (isDemoMode()) {
      customerId = "80000";
    }

    if (customerId) {
      setApiDefaults(customerId, this.props.emsAdminId, this.props.jwtToken);
      setDefaultTimezone(this.props.emsAdminTimezone);
      this.props.getEmsAccountPermissions();
      this.props.getCustomer();
    }
  }

  renderMain() {
    const { isCustomerLoaded, hasPlans, isPlanActivated, isPlanLoaded, isAppDataLoaded } = this.props;

    if (!isCustomerLoaded) {
      return null;
    }

    let showOnBoarding = false;

    if (!hasPlans || !isPlanActivated) {
      showOnBoarding = true;
    }

    if (hasPlans && !isPlanLoaded) {
      return null;
    }

    if (isPlanActivated && !isAppDataLoaded) {
      return null;
    }

    return (
      <Main
        onBoarding={showOnBoarding}
        isBeforeFirstPlanLaunch={this.props.isBeforeFirstPlanLaunch}
        isRedemptionActionConfigEnabled={this.props.isRedemptionActionConfigEnabled}
      />
    );
  }

  render() {
    const { jwtToken, sessionDataLoaded, customerId, isCustomerLoaded } = this.props;

    if (!jwtToken && sessionDataLoaded) {
      return <div>Not Authorized 401</div>;
    } else if (isCustomerLoaded && !customerId) {
      return <div>Customer does not exists</div>;
    }

    return (
      <BrowserRouter BrowserRouter getUserConfirmation={getNavConfirmation}>
        <Fragment>
          {!!sessionStorage.showMenuBar && <TopMenuBar />}
          <div className="app-main">
            {this.renderMain()}
            <Overlay />
            <AlertContainer />
            <div id="confirmNavDialog" />
          </div>
        </Fragment>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customerId: selectCustomerId(state),
    hasPlans: !!selectCustomerPlans(state),
    isCustomerLoaded: isCustomerLoaded(state),
    isPlanLoaded: isPlanLoaded(state),
    isPlanActivated: selectIsPlanActivated(state),
    sessionDataLoaded: isSessionDataLoaded(state),
    isAppDataLoaded: isAppDataLoaded(state),
    jwtToken: selectJwtToken(state),
    isBeforeFirstPlanLaunch: selectIsBeforeFirstPlanLaunch(state),
    isEmsConfigLoaded: isEmsConfigLoaded(state),
    emsAdminId: selectEmsAdminId(state),
    emsAdminTimezone: selectEmsAdminTimezone(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJwt: (url) => {
      dispatch(getJwt(url));
    },
    getAppPlanData: () => {
      dispatch(getAppPlanData());
    },
    getCustomer: () => {
      dispatch(getCustomer());
    },
    getPlan: () => {
      dispatch(getPlan());
    },
    getTranslations: () => {
      dispatch(getTranslations());
    },
    getEmsConfig: () => {
      dispatch(getEmsConfig());
    },
    getEmsAccountPermissions: () => {
      dispatch(getEmsAccountPermissions());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
