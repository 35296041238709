import React, { Component } from "react";
import PropTypes from "prop-types";

class Test extends Component {
  render() {
    return (
      <div className="e-container">
        <h1>Test Page</h1>
      </div>
    );
  }
}

Test.propTypes = {};

export default Test;
