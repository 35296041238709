import validate from "validate.js";
import _values from "lodash/values";
import moment, { lang } from "moment";

import {
  FORM_STEP_ENUM,
  REDEMPTION_VALUE_TOKEN,
  EXCLUSIVE_ACCESS_EXCLUSIVITY_TYPE_ENUM,
  REDEMPTION_LIMIT_TYPE_ENUM,
  VALIDITY_TYPE_ENUM,
  MASTER_REWARD_ENUM,
  VOUCHER_VALIDITY_TYPE_OPTIONS
} from "../constants/rewardConstants";
import rewardService from "../services/rewardService";
import { getMessage } from "../messages";

validate.validators.voucherValidity = function (value, options, key, attributes) {
  const { voucherValidity } = attributes.validity;

  if (voucherValidity.disabled) {
    return undefined;
  }

  const error = validate(attributes, {
    "validity.voucherValidity.duration": {
      presence: {
        allowEmpty: false,
        message: getMessage("validity.voucherValidity.duration.presence")
      },
      numericality: {
        onlyInteger: true,
        notInteger: getMessage("validity.voucherValidity.duration.numericality.notInteger"),
        greaterThan: 0,
        notGreaterThan: getMessage("validity.voucherValidity.duration.numericality.notGreaterThan")
      }
    }
  });

  if (error !== undefined) {
    return error[key][0];
  }

  return undefined;
};

validate.validators.voucherExpiryDate = function (value, options, key, reward) {
  const { voucherExpiryDate, poolValidity } = reward.validity;

  if (moment(voucherExpiryDate).diff(moment(poolValidity), "minutes") > 0) {
    return getMessage("validity.voucherValidity.voucherExpiryDate");
  }
  return undefined;
};

validate.validators.voucherOfferMaxDate = function (value, options, key, reward) {
  const { poolValidity, deactivationDate, voucherExpiryDate } = reward.validity;
  const { start, end } = value;

  if (reward.validity.voucherValidityType === VALIDITY_TYPE_ENUM.FIXED_DATE) {
    if (moment(end).diff(moment(voucherExpiryDate)) > 0)
      return getMessage("validity.voucherValidity.voucherExpiryDate.FIXED_DATE");
  } else if (reward.validity.voucherValidityType === VALIDITY_TYPE_ENUM.LIMITED_FROM_EARN) {
    if (moment(end).diff(moment(deactivationDate), "minutes") > 0)
      return getMessage("validity.voucherValidity.voucherExpiryDate.LIMITED_FROM_EARN");
  } else {
    if (moment(end).diff(moment(poolValidity)) > 0)
      return getMessage("validity.voucherValidity.voucherExpiryDate.other");
  }
  return undefined;
};

export default class rewardValidator {
  constructor({ rewardNames, hasMultiMarket, languages }) {
    this.stepConstraints = {};
    this.rewardNames = rewardNames;
    this.hasMultiMarket = hasMultiMarket;
    this.languages = languages;
  }

  createPoolSettingsStepConstraints(reward) {
    let constraints = {
      voucherName: {
        presence: {
          allowEmpty: false,
          message: getMessage("rewardValidator.poolSettingsStepConstraints.voucherName.presence")
        }
      }
    };
    if (!rewardService.isCashback(reward.masterReward)) {
      constraints["name"] = {
        presence: {
          allowEmpty: false,
          message: getMessage("rewardValidator.poolSettingsStepConstraints.name.presence")
        },
        exclusionIgnoreCase: {
          within: this.rewardNames,
          message: getMessage("rewardValidator.poolSettingsStepConstraints.name.exclusionIgnoreCase")
        }
      };

      if (reward.blockExternalLink.url) {
        constraints["blockExternalLink.text"] = {
          presence: {
            allowEmpty: false,
            message: getMessage("actionValidator.constraints.blockExternalLink.text.presence")
          }
        };
      }

      if (reward.blockExternalLink.text) {
        constraints["blockExternalLink.url"] = {
          presence: {
            allowEmpty: false,
            message: getMessage("actionValidator.constraints.blockExternalLink.url.presence")
          },
          url: {
            message: getMessage("actionValidator.constraints.blockExternalLink.url.url")
          }
        };
      }
    }

    return constraints;
  }

  createRedemptionValueConstraints(reward) {
    const redemptionValueTiers = reward.redemption.value;
    let constraints = {
      "redemption.message.text": {
        redemptionMessage: {
          message: getMessage("excluseiveAccesssValidator.redemptionConstriants.message.text"),
          token: REDEMPTION_VALUE_TOKEN.token
        }
      }
    };

    if (!rewardService.isCashback(reward.masterReward)) {
      constraints["redemption.message.title"] = {
        redemptionMessage: {
          message: getMessage("excluseiveAccesssValidator.redemptionConstriants.message.title")
        }
      };
    }

    for (const tierId in redemptionValueTiers) {
      if (!redemptionValueTiers.hasOwnProperty(tierId) || reward.masterReward === MASTER_REWARD_ENUM.VOUCHERS_FOR_FREE)
        continue;

      constraints[`redemption.value.${tierId}.points`] = {
        singleRedemptionValue: {
          tierId,
          enabledProp: "pointsEnabled"
        },
        numericality: {
          onlyInteger: true,
          notInteger: getMessage("excluseiveAccesssValidator.redemptionConstriants.value.points.notInteger"),
          greaterThan: 0,
          notGreaterThan: getMessage("excluseiveAccesssValidator.redemptionConstriants.value.points.notGreaterThan")
        }
      };
    }

    if (this.hasMultiMarket && rewardService.hasRedemptionValue(reward)) {
      constraints["redemption.targetMarkets"] = {
        presence: {
          allowEmpty: false,
          message: getMessage("rewardValidator.redemption.targetMarkets.presence")
        }
      };
    }

    if (rewardService.isCashback(reward.masterReward)) {
      if (reward.blockExternalLink.url) {
        constraints["blockExternalLink.text"] = {
          presence: {
            allowEmpty: false,
            message: getMessage("actionValidator.constraints.blockExternalLink.text.presence")
          }
        };
      }

      if (reward.blockExternalLink.text) {
        constraints["blockExternalLink.url"] = {
          presence: {
            allowEmpty: false,
            message: getMessage("actionValidator.constraints.blockExternalLink.url.presence")
          },
          url: {
            message: getMessage("actionValidator.constraints.blockExternalLink.url.url")
          }
        };
      }
    }

    return constraints;
  }

  createValidityConstraints(reward) {
    let constraints = {
      "validity.poolValidity": {
        presence: {
          allowEmpty: false,
          message: getMessage("rewardValidator.rvalidity.poolValidity.presence")
        }
      }
    };

    if (reward.validity.voucherValidityType === VALIDITY_TYPE_ENUM.LIMITED_FROM_EARN) {
      constraints["validity.voucherValidity.duration"] = {
        voucherValidity: {
          messageRequired: getMessage("rewardValidator.validity.voucherValidity.duration.messageRequired"),
          messageHigherThanZero: getMessage("rewardValidator.validity.voucherValidity.duration.messageHigherThanZero")
        }
      };
    } else if (reward.validity.voucherValidityType === VALIDITY_TYPE_ENUM.FIXED_DATE) {
      constraints["validity.voucherExpiryDate"] = {
        presence: {
          allowEmpty: false,
          message: getMessage("rewardValidator.validity.voucherExpiryDate.presence")
        },
        voucherExpiryDate: {
          message: getMessage("rewardValidator.validity.voucherExpiryDate.voucherExpiryDate")
        }
      };
    }

    if (
      !rewardService.isCashback(reward.masterReward) &&
      reward.masterReward != MASTER_REWARD_ENUM.VOUCHERS_FOR_REWARDS &&
      (reward.redemption.limitType === REDEMPTION_LIMIT_TYPE_ENUM.LIMIT_MEMBER ||
        reward.masterReward === MASTER_REWARD_ENUM.VOUCHERS_FOR_FREE)
    ) {
      constraints["redemption.limitValue"] = {
        presence: {
          allowEmpty: false,
          message: getMessage("excluseiveAccesssValidator.redemptionConstriants.limitValue.presence")
        },
        numericality: {
          onlyInteger: true,
          notInteger: getMessage("excluseiveAccesssValidator.redemptionConstriants.limitValue.numericality.notInteger"),
          greaterThan: 0,
          notGreaterThan: getMessage(
            "excluseiveAccesssValidator.redemptionConstriants.limitValue.numericality.notGreaterThan"
          )
        }
      };
    }
    if (rewardService.isRewardForFree(reward.masterReward) || rewardService.isRewardForPoints(reward.masterReward)) {
      constraints["validity.offerValidity"] = {
        dateRange: {
          messageEmpty: getMessage(
            "excluseiveAccesssValidator.redemptionConstriants.offerValidity.dateRange.messageEmpty"
          ),
          messageRangeNotValid: getMessage(
            "excluseiveAccesssValidator.redemptionConstriants.offerValidity.dateRange.messageRangeNotValid"
          ),
          minRangeMinutes: 1 * 60 //1 hour
        },
        voucherOfferMaxDate: {
          message: getMessage("excluseiveAccesssValidator.redemptionConstriants.exclusiveOfferMaxDate")
        }
      };
    }
    return constraints;
  }

  createUploadFileConstraints(reward) {
    let constraints = {};
    if (reward.pool?.availableCodes > 0) {
      constraints["validity.refillReminder"] = {
        presence: {
          allowEmpty: false,
          message: getMessage("rewardValidator.validity.refillReminder.presence")
        },
        numericality: {
          onlyInteger: true,
          notInteger: getMessage("rewardValidator.validity.refillReminder.numericality.notInteger"),
          greaterThan: -1,
          notGreaterThan: getMessage("rewardValidator.validity.refillReminder.numericality.notGreaterThan")
        }
      };
    }
    return constraints;
  }

  validateStep = (stepId, reward) => {
    let constraints = {};

    if (stepId === FORM_STEP_ENUM.POOL_SETTINGS) {
      constraints = this.createPoolSettingsStepConstraints(reward);
    } else if (stepId === FORM_STEP_ENUM.VALIDITY) {
      constraints = this.createValidityConstraints(reward);
    } else if (stepId === FORM_STEP_ENUM.REDEMPTION_VALUE) {
      constraints = this.createRedemptionValueConstraints(reward);
    } else if (stepId === FORM_STEP_ENUM.UPLOAD_FILE) {
      constraints = this.createUploadFileConstraints(reward);
    }

    return constraints ? validate(reward, constraints) : undefined;
  };

  validateMultiLangPopup = (fields) => {
    if (fields["blockExternalLink.urlML"]) {
      return this.validateMultiExternalLinkPopup(fields);
    }

    return;
  };

  validateMultiExternalLinkPopup = (fields) => {
    let constraints = {};

    const urlML = fields["blockExternalLink.urlML"];
    const textML = fields["blockExternalLink.textML"];
    const hasValues = _values(urlML).some((v) => !!v) || _values(textML).some((v) => !!v);

    if (hasValues) {
      this.languages.forEach((lang) => {
        if (urlML[lang]) {
          constraints[`blockExternalLink\\.textML.${lang}`] = {
            presence: {
              allowEmpty: false,
              message: getMessage("actionValidator.constraints.blockExternalLink.text.presence")
            }
          };
        }

        if (textML[lang]) {
          constraints[`blockExternalLink\\.urlML.${lang}`] = {
            presence: {
              allowEmpty: false,
              message: getMessage("actionValidator.constraints.blockExternalLink.url.presence")
            },
            url: {
              message: getMessage("actionValidator.constraints.blockExternalLink.url.url")
            }
          };
        }
      });
    }

    return validate(fields, constraints);
  };
}
