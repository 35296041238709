import React from "react";
import PropTypes from "prop-types";

import PopupContainer from "./PopupContainer";
import Notification from "./Notification";
import { getMessage } from "../messages";

const conditions = [
  {
    titleMessage: "preLaunchPopup.conditions.plan.title",
    textMessage: "preLaunchPopup.conditions.plan.text"
  },
  {
    titleMessage: "preLaunchPopup.conditions.tiers.title",
    textMessage: "preLaunchPopup.conditions.tiers.text"
  },
  {
    titleMessage: "preLaunchPopup.conditions.rewards.title",
    textMessage: "preLaunchPopup.conditions.rewards.text"
  },
  {
    titleMessage: "preLaunchPopup.conditions.actions.title",
    textMessage: "preLaunchPopup.conditions.actions.text"
  }
];

const PreLaunchPopup = ({ showPreLaunchPopup, toggleShowPreLaunchPopup, launchPlanFunc }) => {
  return (
    <PopupContainer
      id="PoolSampleCodedfdfds"
      title={getMessage("preLaunchPopup.title")}
      width="800px"
      show={showPreLaunchPopup}
      onClose={toggleShowPreLaunchPopup}
      buttons={[
        {
          text: getMessage("cancelBtn.label"),
          isClose: true,
          onClick: () => {}
        },
        {
          text: getMessage("launchPlanBtn.label"),
          className: "e-btn-primary",
          isClose: true,
          onClick: launchPlanFunc
        }
      ]}
    >
      <div>
        <div>{getMessage("preLaunchPopup.text")}</div>
        <ul className="e-list" style={{ margin: "20px 0" }}>
          {conditions.map((condition) => (
            <li key={getMessage(condition.titleMessage)} className="e-list__item e-list__item-has_icon">
              <div>
                <e-icon icon="e-check-circle" size="large" color="help" />
                <div>
                  <b style={{ fontSize: "15px" }}>{getMessage(condition.titleMessage)}</b>
                  <div>{getMessage(condition.textMessage)}</div>
                </div>
              </div>
            </li>
          ))}
        </ul>

        {/* <Notification
          content={`Once you launch your plan it will switch to live version mode. In order to edit and relaunch your plan, you must switch to edit version mode. Make sure to communicate your customers all plan changes clearly, as they may affect the tiers requirements and benefits of your members. Learn more about the process
          <a target="_blank" href="https://help.emarsys.com/hc/en-us/articles/360025323813-Before-you-start-with-Loyalty">
            Learn more.
          </a>`}
        /> */}
      </div>
    </PopupContainer>
  );
};

PreLaunchPopup.propTypes = {
  showPreLaunchPopup: PropTypes.bool,
  toggleShowPreLaunchPopup: PropTypes.func,
  launchPlanFunc: PropTypes.func
};

export default PreLaunchPopup;
