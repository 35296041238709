import React from "react";
import PropTypes from "prop-types";

import SelectField from "../../components/SelectFieldV2";
import { getMessage } from "../../messages";
import { getMultiLangFieldLabel } from "../../helpers/multiLangHelper";

const MultiLangEmailCampaignIdField = ({
  onChange,
  lang,
  translations,
  disabled,
  broadcastEmailOptions,
  loadingBroadcastEmailOptions
}) => {
  return (
    // <InputField
    //   label={getMultiLangFieldLabel({ label: "Action name", lang })}
    //   value={translations.nameML[lang]}
    //   name={"nameML"}
    //   onChange={event => onChange(event, lang)}
    //   disabled={disabled}
    // />

    <SelectField
      label={getMultiLangFieldLabel({ label: getMessage("multiLangEmailCampaignIdField.emailCampaignId.label"), lang })}
      name="emailCampaignIdML"
      value={translations.emailCampaignIdML[lang]}
      loading={loadingBroadcastEmailOptions}
      alwaysShowSearch={true}
      options={broadcastEmailOptions}
      placeholder={getMessage("multiLangEmailCampaignIdField.emailCampaignId.placeholder")}
      onChange={(event) => onChange(event, lang)}
      disabled={disabled}
      postfixButtons={
        <a className="e-btn e-btn-onlyicon" name="emailCampaignIdML" onClick={(event) => onChange(event, lang, "")}>
          <e-icon icon="reset-block" name="emailCampaignIdML" />
        </a>
      }
    />
  );
};

MultiLangEmailCampaignIdField.propTypes = {
  onChange: PropTypes.func,
  lang: PropTypes.string,
  translations: PropTypes.object,
  disabled: PropTypes.bool,
  broadcastEmailOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string
    })
  ),
  loadingBroadcastEmailOptions: PropTypes.bool
};

export default MultiLangEmailCampaignIdField;
