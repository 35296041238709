import React, { useState, useEffect, Fragment } from "react";
import { renderToString } from "react-dom/server";
import { useSelector } from "react-redux";
import actionReportApi from "../../api/actionReportApi";
import { convertDateForChart, formatTrendText, datesDiffStr } from "../Dashboard/dashboardHelper";
import Widget from "../../components/Widget";
import WidgetTotalsItem from "../Dashboard/WidgetTotalsItem";
import WidgetHelpLink from "../Dashboard/WidgetHelpLink";
import Time from "../../components/Time";
import LegendWithSelection from "../Dashboard/LegendWithSelection";
import DashboardEmptyState from "../Dashboard/DashboardEmptyState";
import PropTypes from "prop-types";
import cloneDeep from "lodash.clonedeep";
import { selectPlanPrimaryCurrency } from "../../reducers";
import { getMessage } from "../../messages";

const ITEM_TYPE = {
  REVENUE: "revenue",
  INVESTMENT: "investment",
  ROI: "roi"
};

const CHART_COLORS = {
  REVENUE: "#3AAAD3",
  INVESTMENT: "#B2D1F2",
  ROI: "#72D1F2"
};

const CHART_HEIGHT = 270;

const ReferralRevenueWidget = (props) => {
  const { actionId, startDateStr, endDateStr, dateAggStr } = props;

  const chartMetaData = [
    {
      id: ITEM_TYPE.REVENUE,
      displayName: getMessage("referralRevenueWidget.chartMetaData.displayName.revenue"),
      color: CHART_COLORS.REVENUE,
      data: [],
      showOnChart: true,
      titleTooltip: ""
    },
    {
      id: ITEM_TYPE.INVESTMENT,
      displayName: getMessage("referralRevenueWidget.chartMetaData.displayName.investment"),
      color: CHART_COLORS.INVESTMENT,
      data: [],
      showOnChart: true,
      titleTooltip: ""
    },
    {
      id: ITEM_TYPE.ROI,
      displayName: getMessage("referralRevenueWidget.chartMetaData.displayName.roi"),
      color: CHART_COLORS.ROI,
      data: [],
      showOnChart: false,
      titleTooltip: ""
    }
  ];

  let initialLegendSelected = { investment: true, revenue: true };
  const [loading, setLoading] = useState(true);
  const [chartSeriesArr, setChartSeriesArr] = useState(chartMetaData);
  const [legendSelection, setLegendSelection] = useState(initialLegendSelected);
  const [totals, setTotals] = useState({});
  const [chartCustomTooltip, setChartCustomTooltip] = useState("Blah Blah");
  const [showEmptyState, setShowEmptyState] = useState(false);
  const currency = useSelector((state) => selectPlanPrimaryCurrency(state));

  const getChartTooltipContent = (date, row) => {
    let content = [];

    const dateElm = renderToString(<Time value={date} onlyDate={true} />);

    content.push({ label: dateElm, type: "header" });

    chartSeriesArr.forEach((metaData) => {
      content.push({
        value: renderToString(
          <Fragment>
            <e-numeric value={row[metaData.id] || 0}></e-numeric>
          </Fragment>
        ),
        label: metaData.displayName,
        color: metaData.color
      });
    });

    return content;
  };

  const setChartSeriesArrState = (dataRows) => {
    if (!dataRows) {
      setShowEmptyState(true);
      return;
    }

    //dataRows = transformDataDateForDemo(dataRows, this.state.selectedDaysRange);
    dataRows = dataRows.data;

    let newChartSeriesArr = cloneDeep(chartSeriesArr);

    let chartCustomTooltip = {};

    //reset data on each series
    newChartSeriesArr.forEach((series) => {
      series.data = [];
    });

    dataRows.forEach((row) => {
      const date = convertDateForChart(row.date);
      newChartSeriesArr.forEach((series) => {
        if (series.showOnChart) {
          series.data.push({
            x: date,
            y: row[series.id] || 0
          });
        }
      });
      chartCustomTooltip[date] = { rows: getChartTooltipContent(date, row) };
    });

    setChartSeriesArr(newChartSeriesArr);
    setChartCustomTooltip(chartCustomTooltip);
  };

  const setTotalState = (data) => {
    if (!data) {
      setShowEmptyState(true);
      return;
    }

    const investmentTotals = data[ITEM_TYPE.INVESTMENT];
    const revenueTotals = data[ITEM_TYPE.REVENUE];
    const roiTotals = data[ITEM_TYPE.ROI];
    let totals = {
      investmentTotals: investmentTotals.value || 0,
      investmentTotalsPrevDiffPct: investmentTotals.prevDiffPct || 0,
      revenueTotals: revenueTotals.value || 0,
      revenueTotalsPrevDiffPct: revenueTotals.prevDiffPct || 0,
      roiTotals: roiTotals.value || 0,
      roiTotalsPrevDiffPct: roiTotals.prevDiffPct || 0
    };
    setTotals(totals);
  };

  const loadData = () => {
    const promises = [
      actionReportApi.getActionReferralRevenueChartData({ actionId, startDateStr, endDateStr, dateAggStr }),
      actionReportApi.getActionReferralRevenueChartTotals({ actionId, startDateStr, endDateStr, dateAggStr })
    ];
    setLoading(true);
    setShowEmptyState(false);
    Promise.all(promises)
      .then((response) => {
        setChartSeriesArrState(response[0]);
        setTotalState(response[1]);
      })
      .catch((error) => {
        setShowEmptyState(true);
        throw error;
      })
      .finally(() => setLoading(false));
  };
  const getNumberValueAttr = () => {
    return { humanize: "auto", precision: "2", "trim-fraction-zeros": "true" };
  };

  const getCurrencyValueAttr = (currency) => {
    return { humanize: "auto", precision: "2", "trim-fraction-zeros": "true", type: "currency", currency };
  };

  const getTrendText = (value) => {
    return formatTrendText`${value} than previous ${datesDiffStr(startDateStr, endDateStr, dateAggStr)}`;
  };

  const getItemsForLegend = () => {
    return chartMetaData
      .filter((item) => item.showOnChart === true)
      .map((item) => {
        return {
          id: item.id,
          displayName: item.displayName,
          color: item.color,
          isSelected: legendSelection[item.id]
        };
      });
  };

  useEffect(() => {
    loadData();
  }, [startDateStr, endDateStr, dateAggStr]);

  const onLegendSelectionChange = (selectionId) => {
    let newLegendSelection = cloneDeep(legendSelection);
    newLegendSelection[selectionId] = !newLegendSelection[selectionId];
    setLegendSelection(newLegendSelection);
  };

  const renderTotals = () => {
    const {
      investmentTotals,
      investmentTotalsPrevDiffPct,
      revenueTotals,
      revenueTotalsPrevDiffPct,
      roiTotals,
      roiTotalsPrevDiffPct
    } = {
      ...totals
    };
    return (
      <div className="e-grid e-grid-column">
        <div className="e-cell ">
          <WidgetTotalsItem
            title={getMessage("referralRevenueWidget.revenueTotals.title")}
            titleTooltip={getMessage("referralRevenueWidget.revenueTotals.titleTooltip")}
            value={revenueTotals}
            valueNumericAttr={getCurrencyValueAttr(currency)}
            valueColor={CHART_COLORS.REVENUE}
            trendValue={revenueTotalsPrevDiffPct}
            trendText={getTrendText(revenueTotalsPrevDiffPct)}
          />
        </div>

        <div className="e-cell ">
          <WidgetTotalsItem
            title={getMessage("referralRevenueWidget.investmentTotals.title")}
            titleTooltip={getMessage("referralRevenueWidget.investmentTotals.titleTooltip")}
            value={investmentTotals}
            valueNumericAttr={getCurrencyValueAttr(currency)}
            valueColor={CHART_COLORS.INVESTMENT}
            trendValue={investmentTotalsPrevDiffPct}
            trendText={getTrendText(investmentTotalsPrevDiffPct)}
          />
        </div>

        <div className="e-cell ">
          <WidgetTotalsItem
            title={getMessage("referralRevenueWidget.roiTotals.title")}
            titleTooltip={getMessage("referralRevenueWidget.roiTotals.titleTooltip")}
            value={roiTotals}
            valueNumericAttr={getCurrencyValueAttr(currency)}
            valueColor={CHART_COLORS.ROI}
            trendValue={roiTotalsPrevDiffPct}
            trendText={getTrendText(roiTotalsPrevDiffPct)}
          />
        </div>
      </div>
    );
  };

  const renderChart = () => {
    return (
      <ec-chart height={CHART_HEIGHT} domain-type="time" custom-tooltip={JSON.stringify(chartCustomTooltip)}>
        <ec-series-stack>
          {chartSeriesArr.map((series, index) => {
            if (legendSelection[series.id]) {
              return (
                <ec-series-stack-area
                  key={series.id}
                  order={index}
                  name={series.name}
                  data={JSON.stringify(series.data)}
                  color={series.color}
                ></ec-series-stack-area>
              );
            }
          })}
        </ec-series-stack>
      </ec-chart>
    );
  };

  const renderContent = () => {
    if (showEmptyState) {
      return <DashboardEmptyState />;
    }

    return (
      <Fragment>
        <div className="e-grid">
          <div className="e-cell e-cell-3">{renderTotals()}</div>
          <div className="e-cell e-cell-auto">{renderChart()}</div>
        </div>
        <div className="e-margin-top-m">
          <LegendWithSelection items={getItemsForLegend()} onChange={onLegendSelectionChange} visible={true} />
        </div>
      </Fragment>
    );
  };

  return (
    <Widget
      title={getMessage("referralRevenueWidget.title")}
      className="referral-revenue-widget"
      footerActions={<WidgetHelpLink />}
      content={renderContent()}
      loading={loading}
      isEmptyState={showEmptyState}
    />
  );
};

ReferralRevenueWidget.propTypes = {
  actionId: PropTypes.string,
  startDateStr: PropTypes.string,
  endDateStr: PropTypes.string,
  dateAggStr: PropTypes.string
};

export default ReferralRevenueWidget;
