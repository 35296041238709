import { getMessage } from "../messages";

export const MASTER_REWARD_ENUM = {
  VOUCHERS_FOR_REWARDS: "vouchersForRewards",
  VOUCHERS_FOR_FREE: "vouchersForFree",
  VOUCHERS_FOR_POINTS: "vouchersForPoints",
  VOUCHERS_AS_CASHBACK: "vouchersAsCashback"
};

export const MASTER_REWARDS = {
  vouchersForRewards: {
    //largeText: "Vouchers for rewards",
    name: "vouchersForRewards",
    masterRewardsOfSection: [MASTER_REWARD_ENUM.VOUCHERS_FOR_REWARDS],
    badgeMessage: "voucher.badge.vouchersForRewards"
  },
  vouchersForFree: {
    //largeText: "Vouchers for free",
    name: "vouchersForFree",
    masterRewardsOfSection: [MASTER_REWARD_ENUM.VOUCHERS_FOR_FREE],
    badgeMessage: "voucher.badge.vouchersForFree"
  },
  vouchersForPoints: {
    //largeText: "Vouchers for points",
    name: "vouchersForPoints",
    masterRewardsOfSection: [MASTER_REWARD_ENUM.VOUCHERS_FOR_POINTS],
    badgeMessage: "voucher.badge.vouchersForPoints"
  },
  vouchersAsCashback: {
    //largeText: "Vouchers As Cashback",
    name: "vouchersAsCashback",
    masterRewardsOfSection: [MASTER_REWARD_ENUM.VOUCHERS_AS_CASHBACK],
    badgeMessage: "voucher.badge.vouchersAsCashback"
  }
};

export const FORM_STEP_ENUM = {
  POOL_SETTINGS: "poolSettings",
  REDEMPTION_VALUE: "redemptionValue",
  UPLOAD_FILE: "uploadFile",
  VALIDITY: "validity",
  EXCLUSIVE_ACCESS_POOL_SETTINGS: "exclusiveAccessPoolSettings",
  EXCLUSIVE_ACCESS_EDITOR: "exclusiveAccessBlockingEditor",
  EXCLUSIVE_ACCESS_VALIDITY: "exclusiveAccessValidity"
};

// export const FORM_STEPS = [
//   {
//     id: FORM_STEP_ENUM.POOL_SETTINGS,
//     title: "Pool settings"
//   },
//   {
//     id: FORM_STEP_ENUM.REDEMPTION_VALUE,
//     title: "Pool values"
//   },
//   {
//     id: FORM_STEP_ENUM.UPLOAD_FILE,
//     title: "Upload file"
//   },
//   {
//     id: FORM_STEP_ENUM.VALIDITY,
//     title: "Validity"
//   }
// ];

export const FORM_STEPS_Rewards = [
  {
    id: FORM_STEP_ENUM.POOL_SETTINGS,
    title: getMessage("formStepRewards.options.poolSettings.title"),
    disabled: false
  },
  {
    id: FORM_STEP_ENUM.UPLOAD_FILE,
    title: getMessage("formStepRewards.options.uploadFile.title"),
    disabled: true
  },
  {
    id: FORM_STEP_ENUM.REDEMPTION_VALUE,
    title: getMessage("formStepRewards.options.redemptionValue.title"),
    disabled: true
  },
  {
    id: FORM_STEP_ENUM.VALIDITY,
    title: getMessage("formStepRewards.options.validity.title"),
    disabled: true
  }
];

export const FORM_STEPS_FREE = [
  {
    id: FORM_STEP_ENUM.POOL_SETTINGS,
    title: getMessage("formStepRewards.options.poolSettings.title"),
    disabled: false
  },
  {
    id: FORM_STEP_ENUM.UPLOAD_FILE,
    title: getMessage("formStepRewards.options.uploadFile.title"),
    disabled: true
  },
  {
    id: FORM_STEP_ENUM.REDEMPTION_VALUE,
    title: getMessage("formStepRewards.options.redemptionValue.title"),
    disabled: true
  },
  {
    id: FORM_STEP_ENUM.VALIDITY,
    title: getMessage("formStepRewards.options.validity.title"),
    disabled: true
  }
];

export const FORM_STEPS_POINTS = [
  {
    id: FORM_STEP_ENUM.POOL_SETTINGS,
    title: getMessage("formStepRewards.options.poolSettings.title"),
    disabled: false
  },
  {
    id: FORM_STEP_ENUM.UPLOAD_FILE,
    title: getMessage("formStepRewards.options.uploadFile.title"),
    disabled: true
  },
  {
    id: FORM_STEP_ENUM.REDEMPTION_VALUE,
    title: getMessage("formStepRewards.options.redemptionValue.title"),
    disabled: true
  },
  {
    id: FORM_STEP_ENUM.VALIDITY,
    title: getMessage("formStepRewards.options.validity.title"),
    disabled: true
  }
];

export const SPECIAL_TIER_ENUM = {
  ALL: "all"
};

export const SPECIAL_TIERS_REDEMPTION_SETTINGS = {
  all: {
    tierId: SPECIAL_TIER_ENUM.ALL,
    name: getMessage("specialTiers.redemptopmSettings.all.name")
  }
};

export const REWARD_STATUS_ENUM = {
  INACTIVE: "inactive",
  READY_TO_USE: "readyToUse",
  SCHEDULED: "scheduled",
  ACTIVE: "active",
  EMPTY: "empty",
  EXPIRED: "expired"
};

export const REWARD_STATUS_LABEL = {
  inactive: {
    label: getMessage("status.inactive"),
    className: "e-label-default background-color-placeholder"
  },
  readyToUse: {
    label: getMessage("status.readyToUse"),
    className: "e-label-success"
  },
  scheduled: {
    label: getMessage("status.scheduled"),
    className: "e-label-warning"
  },
  active: {
    label: getMessage("status.active"),
    className: "e-label-primary"
  },
  empty: {
    label: getMessage("status.empty"),
    className: "e-label-danger"
  },
  expired: {
    label: getMessage("status.expired"),
    className: "e-label-danger"
  }
};

export const REDEMPTION_VALUE_TOKEN = {
  token: "{{valueToken}}",
  display: "{ Value }"
};

export const POOL_DEACTIVATION_ALERT_DAYS = 14;

export const VOUCHERS_HELP_CENTER_URL =
  "https://help.emarsys.com/hc/en-us/articles/4608160065937-Rewards-Setting-up-vouchers-for-points#validity";

export const REWARD_HELP_CENTER_URL =
  "https://help.emarsys.com/hc/en-us/articles/4608060257681-Rewards-Voucher-overview";

export const POOL_TYPE_ENUM = {
  ACTION: "action",
  FIXED_BENEFIT: "fixedBenefit"
};

export const EXCLUSIVE_ACCESS_ACTIVATION_ENUM = {
  ACTIVATE: "activate",
  DEACTIVATE: "deactivate"
};

export const VOUCHER_TYPE_ENUM = {
  ONE_TIME: "oneTime",
  FIXED_YEARLY: "fixedYearly"
};

export const VOUCHER_TYPE_OPTIONS = [
  {
    message: "voucherType.oneTimeUse",
    value: VOUCHER_TYPE_ENUM.ONE_TIME
  },
  {
    message: "voucherType.multiUse",
    value: VOUCHER_TYPE_ENUM.FIXED_YEARLY
  }
];

export const VALIDITY_TYPE_ENUM = {
  UNLIMITED: "unlimited",
  LIMITED_FROM_EARN: "limitedFromEarn",
  FIXED_DATE: "fixedDate",
  DATE_RANGE: "dateRange",
  DISABLED: "disabled"
};

export const VOUCHER_VALIDITY_TYPE_OPTIONS = [
  {
    name: getMessage("voucherValidityType.options.unlimited.name"),
    value: VALIDITY_TYPE_ENUM.UNLIMITED
  },
  {
    name: getMessage("voucherValidityType.options.limitedFromEarn.name"),
    value: VALIDITY_TYPE_ENUM.LIMITED_FROM_EARN
  },
  {
    name: getMessage("voucherValidityType.options.fixedDate.name"),
    value: VALIDITY_TYPE_ENUM.FIXED_DATE
  }
];

export const REDEMPTION_LIMIT_TYPE_ENUM = {
  NO_LIMIT: "noLimit",
  LIMIT_MEMBER: "limitMember"
};

export const REDEMPTION_LIMIT_OPTIONS = [
  {
    name: getMessage("redemptionLimit.options.noLimit.name"),
    value: REDEMPTION_LIMIT_TYPE_ENUM.NO_LIMIT,
    tooltip: getMessage("redemptionLimit.options.noLimit.tooltip")
  },
  {
    name: getMessage("redemptionLimit.options.limitMember.name"),
    value: REDEMPTION_LIMIT_TYPE_ENUM.LIMIT_MEMBER
  }
];

export const EXCLUSIVE_ACCESS_EXCLUSIVITY_TYPE_ENUM = {
  LIMITED: "limited",
  PERMANENT: "fixed"
};

export const EXCLUSIVE_ACCESS_EXCLUSIVITY_TYPE_OPTIONS = [
  {
    name: getMessage("exclusiveAccessExclusivityType.options.name.fixed"),
    value: EXCLUSIVE_ACCESS_EXCLUSIVITY_TYPE_ENUM.PERMANENT
  },
  {
    name: getMessage("exclusiveAccessExclusivityType.options.name.limitedTerm"),
    value: EXCLUSIVE_ACCESS_EXCLUSIVITY_TYPE_ENUM.LIMITED
  }
];

export const EDITOR_TYPE_ENUM = {
  TEMPLATE: "template",
  HTML: "html"
};

export const BLOCKING_EDITOR_MODE_ENUM = {
  GENERAL: "general",
  MEMBER: "member",
  NON_MEMBER: "nonMember",
  GENERAL_MOBILE: "generalMobile",
  MEMBER_MOBILE: "memberMobile",
  NON_MEMBER_MOBILE: "nonMemberMobile"
};

export const PICKER_TYPE = {
  COLOR: "color",
  SELECTOR: "selector",
  NUM_INPUT: "numInput",
  LINK_DIALOG: "linkDialog"
};

export const LINK_BUTTON_ENUM = {
  RETURN: "return",
  REDIRECT: "redirect"
};

export const LINK_BUTTON_OPTIONS = [
  {
    name: getMessage("linkButtonOptions.return.name"),
    value: LINK_BUTTON_ENUM.RETURN
  },
  {
    name: getMessage("linkButtonOptions.redirect.name"),
    value: LINK_BUTTON_ENUM.REDIRECT
  }
];

export const EDITOR_STYLES_DEFAULT_VALUES = {
  overlayColor: "#000000",
  overlayOpacity: 50,
  contentBackgroundColor: "#FFFFFF",
  tagColor: "#C43A3A",
  tagRadius: 25,
  leftButtonBackgroundColor: "#617cb6",
  leftButtonLink: LINK_BUTTON_OPTIONS[0].name,
  rightButtonLink: LINK_BUTTON_OPTIONS[0].name,
  rightButtonBackgroundColor: "#FFFFFF",
  buttonRadius: 4
};

export const EDITOR_CONTENT_TEXTS_DEFAULT_VALUES = {
  tag: getMessage("editorContentTextDefaultValues.tag"),
  firstHeader: getMessage("editorContentTextDefaultValues.firstHeader"),
  secondHeader: getMessage("editorContentTextDefaultValues.secondHeader"),
  middleText: getMessage("editorContentTextDefaultValues.middleText"),
  actionTopText: getMessage("editorContentTextDefaultValues.actionTopText"),
  leftButton: getMessage("editorContentTextDefaultValues.leftButton"),
  rightButton: getMessage("editorContentTextDefaultValues.rightButton"),
  actionBottomText: getMessage("editorContentTextDefaultValues.actionBottomText")
};

export const EDITOR_LINKS_DEFAULT_VALUES = {
  leftButtonLink: {
    redirectOption: LINK_BUTTON_ENUM.RETURN,
    redirectUrl: "",
    lsOpenWallet: true
  },
  rightButtonLink: {
    redirectOption: LINK_BUTTON_ENUM.RETURN,
    redirectUrl: "",
    lsOpenWallet: true
  }
};

export const EDITOR_CONTENT_TEXTS_DEFAULT_VALUES_MOBILE = {
  ...EDITOR_CONTENT_TEXTS_DEFAULT_VALUES,
  ...{
    middleText: getMessage("editorContentTextDefaultValues.middleText.mobile"),
    actionTopText: getMessage("editorContentTextDefaultValues.actionTopText.mobile")
  }
};

export const ACCESS_URL_TYPE_ENUM = {
  EXACT_URL: "exactUrl",
  STARTS_WITH_URL: "startsWithUrl"
};

export const ACCESS_URL_TYPE_OPTIONS = [
  {
    value: ACCESS_URL_TYPE_ENUM.EXACT_URL,
    name: getMessage("accessUrlType.options.exactUrl.name")
  },
  {
    value: ACCESS_URL_TYPE_ENUM.STARTS_WITH_URL,
    name: getMessage("accessUrlType.options.startsWithUrl.name")
  }
];

export const REWARD_CREATE_OPTIONS_ENUM = {
  REWARD_CREATE: "create",
  REWARD_MODIFY: "modify"
};

export const REWARD_CREATE_OR_MODIFY_OPTIONS = [
  {
    name: getMessage("createOfModify.options.create.name"),
    value: REWARD_CREATE_OPTIONS_ENUM.REWARD_CREATE,
    tooltip: getMessage("createOfModify.options.create.tooltip2")
  },
  {
    name: getMessage("createOfModify.options.modify.name"),
    value: REWARD_CREATE_OPTIONS_ENUM.REWARD_MODIFY,
    tooltip: getMessage("createOfModify.options.modify.tooltip")
  }
];

export const MULTI_LANG_PROPS_MAP = {
  voucherNameML: "voucherName",
  accessNameML: "accessName",
  "redemption.message.titleML": "redemption.message.title",
  "redemption.message.textML": "redemption.message.text",
  "validity.defaultMessageML": "validity.defaultMessage",
  "blocking.exactAccessUrlML": "blocking.exactAccessUrl",
  "blocking.startsWithAccessUrlML": "blocking.startsWithAccessUrl",
  "blocking.landingPageUrlML": "blocking.landingPageUrl",
  "blockExternalLink.urlML": "blockExternalLink.url",
  "blockExternalLink.textML": "blockExternalLink.text",
  "redemptionForFree.message.titleML": "redemptionForFree.message.title",
  "redemptionForFree.message.textML": "redemptionForFree.message.text"
};
