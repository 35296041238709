import React, { Fragment } from "react";
import PropTypes from "prop-types";
import sanitizeHtml from "sanitize-html";

import TrendArrow from "../../components/TrendArrow";

const WidgetTotalsItem = ({
  title,
  titleTooltip,
  value,
  valueColor,
  valueNumericAttr,
  trendValue,
  trendValueNumericAttr,
  trendText
}) => {
  const renderTrendText = () => {
    if (trendValue === 0) {
      <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(trendText) }} />;
    }

    let attr = trendValueNumericAttr || {
      humanize: "auto",
      precision: "2",
      type: "percent",
      "trim-fraction-zeros": "true"
    };

    return (
      <Fragment>
        <e-numeric value={Math.abs(trendValue)} {...attr}></e-numeric>
        &nbsp;
        <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(trendText) }} />
      </Fragment>
    );
  };

  let valueColorStyle = {};
  if (valueColor) {
    valueColorStyle.color = valueColor;
  }

  return (
    <div className="e-legend">
      <div className="e-legend__value text-size-headline text-color-info">
        <e-numeric value={value} {...valueNumericAttr} style={valueColorStyle}></e-numeric>{" "}
        <TrendArrow value={trendValue} />
      </div>
      <div className="e-legend__title">
        {title} {titleTooltip && <e-tooltip content={titleTooltip} type="helper" />}
      </div>
      {trendValue != undefined && <div className="e-legend__description">{renderTrendText()}</div>}
    </div>
  );
};

WidgetTotalsItem.propTypes = {
  title: PropTypes.string,
  titleTooltip: PropTypes.string,
  value: PropTypes.any,
  valueColor: PropTypes.string,
  valueNumericAttr: PropTypes.object,
  trendValue: PropTypes.any,
  trendValueNumericAttr: PropTypes.object,
  trendText: PropTypes.string
};

export default WidgetTotalsItem;
