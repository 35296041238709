import React, { Fragment } from "react";
import moment from "moment";

import { actionType } from "../../types";
import { getMessage, getOrdinal, getPluralCardinalMessage, getPluralOrdinalMessage } from "../../messages";

import {
  SCHEDULING_RECURRENCE_FREQUENCY_ENUM,
  SCHEDULING_RECURRENCE_MONTHLY_OPTIONS_ENUM
} from "../../constants/actionConstants";
import actionService from "../../services/actionService";
import Time from "../../components/Time";

function formatHourText(seconds) {
  return moment.utc(seconds * 1000 * 60).format("HH:mm");
}

const DateText = ({ date, messageId }) => {
  return date ? (
    <BoldSelectedText>
      <Time value={date} onlyDate={true} />
    </BoldSelectedText>
  ) : (
    <GraySelectText>{messageId ? getMessage(messageId) : ""}</GraySelectText>
  );
};

const HourText = ({ seconds, messageId }) => {
  return isNaN(seconds) ? (
    <GraySelectText>{getMessage(messageId)}</GraySelectText>
  ) : (
    <BoldSelectedText>{formatHourText(seconds)}</BoldSelectedText>
  );
};

const GraySelectText = ({ children }) => {
  return (
    <span className="text-color-gray-400">
      <i>{children}</i>
    </span>
  );
};

const BoldSelectedText = ({ children }) => {
  return <span className="font-weight-bold">{children}</span>;
};

const ActionSetupRecurringSummary = ({ action }) => {
  const { startDate, endDate, startHour, endHour, isWholeDay, recurrenceRepeatInterval, repeat } =
    action.scheduling.recurring;

  function getDayName(value) {
    const { recurrenceRepeatInterval, monthlySelectionType } = action.scheduling.recurring;

    if (recurrenceRepeatInterval === SCHEDULING_RECURRENCE_FREQUENCY_ENUM.WEEKLY) {
      return getMessage(`actionSetupScheduling.recurring.monthly.weekday.name.${value}`);
    } else if (monthlySelectionType === SCHEDULING_RECURRENCE_MONTHLY_OPTIONS_ENUM.WEEK) {
      return getMessage(`actionSetupScheduling.recurring.monthly.weekday.name.${value}`);
    } else {
      if (value === "lastDayOfMonth") {
        return getMessage("actionSetupScheduling.recurring.summary.lastDayOfTheMonth");
      }
      return (
        <Fragment>
          {getPluralOrdinalMessage("actionSetupScheduling.recurring.summary.followingDays.daySuffix", value, {
            day: value
          })}
        </Fragment>
      );
    }
  }

  function getDaysText() {
    const { recurrenceRepeatInterval, monthlySelectionType, weekdays, monthdays, monthWeek, isLastMonthDay } =
      action.scheduling.recurring;

    let daysText;
    let weekNumberText;
    let selectedDays;

    const isDailySelection =
      recurrenceRepeatInterval === SCHEDULING_RECURRENCE_FREQUENCY_ENUM.MONTHLY &&
      monthlySelectionType === SCHEDULING_RECURRENCE_MONTHLY_OPTIONS_ENUM.MONTH;

    const hasWeekNumber =
      recurrenceRepeatInterval === SCHEDULING_RECURRENCE_FREQUENCY_ENUM.MONTHLY &&
      monthlySelectionType === SCHEDULING_RECURRENCE_MONTHLY_OPTIONS_ENUM.WEEK;

    if (isDailySelection) {
      selectedDays = [...monthdays];
    } else {
      selectedDays = [...weekdays];
    }

    const areAllDaysSelected = isDailySelection ? selectedDays.length == 31 : selectedDays.length == 7;

    //The text of the week number - (first, second, etc...)
    if (hasWeekNumber) {
      weekNumberText = getMessage(`actionSetupScheduling.recurring.summary.week.name.${monthWeek}`);
    }

    //if last day of the month is selected, push "lastDayOfMonth" value to the end of the array, in order to display relevant text at the end of the sentence
    if (isLastMonthDay) {
      selectedDays.push("lastDayOfMonth");
    }

    if (areAllDaysSelected) {
      const messageId = hasWeekNumber
        ? "actionSetupScheduling.recurring.summary.everyWeekNumber"
        : "actionSetupScheduling.recurring.summary.everyDay";

      daysText = <BoldSelectedText>{getMessage(messageId, { weekNumber: weekNumberText })}</BoldSelectedText>;
    } else if (selectedDays.length == 0) {
      daysText = getMessage("actionSetupScheduling.recurring.summary.followingDays.noSelection", {
        selectText: <GraySelectText>{getMessage("actionSetupScheduling.recurring.summary.selectDays")}</GraySelectText>,
        weekNumber: <BoldSelectedText>{weekNumberText}</BoldSelectedText>
      });
    } else {
      let selectedDaysText;
      let lastWeekdayText;

      //sort the selected days by number - ascending
      const sortedSelectedDays = [...selectedDays].sort((a, b) => a - b);

      if (sortedSelectedDays.length > 1) {
        lastWeekdayText = getDayName(sortedSelectedDays[sortedSelectedDays.length - 1]);
        selectedDaysText = [...sortedSelectedDays].splice(0, sortedSelectedDays.length - 1);
        selectedDaysText = selectedDaysText.map((weekdayNum, index) => (
          <Fragment>
            {getDayName(weekdayNum)}
            {index < selectedDaysText.length - 1 ? ", " : ""}
          </Fragment>
        ));
      } else {
        selectedDaysText = getDayName(sortedSelectedDays[0]);
      }

      daysText = getMessage(
        sortedSelectedDays.length > 1
          ? "actionSetupScheduling.recurring.summary.followingDays.multipleDays"
          : "actionSetupScheduling.recurring.summary.followingDays.singleDay",
        {
          days: <BoldSelectedText>{selectedDaysText}</BoldSelectedText>,
          lastDay: <BoldSelectedText>{lastWeekdayText}</BoldSelectedText>,
          count: sortedSelectedDays.length,
          weekNumber: <BoldSelectedText>{weekNumberText}</BoldSelectedText>
        }
      );
    }

    return daysText;
  }

  let messageId;
  let intervalText;
  if (recurrenceRepeatInterval === SCHEDULING_RECURRENCE_FREQUENCY_ENUM.DAILY) {
    messageId = "actionSetupScheduling.recurring.summary.daily";
    intervalText = getPluralCardinalMessage("actionSetupScheduling.recurring.summary.dailyInterval", repeat, {
      numberOfDays: repeat
    });
  } else if (recurrenceRepeatInterval === SCHEDULING_RECURRENCE_FREQUENCY_ENUM.WEEKLY) {
    messageId = "actionSetupScheduling.recurring.summary.weekly";
    intervalText = getPluralCardinalMessage("actionSetupScheduling.recurring.summary.weeklyInterval", repeat, {
      numberOfDays: repeat
    });
  } else if (recurrenceRepeatInterval === SCHEDULING_RECURRENCE_FREQUENCY_ENUM.MONTHLY) {
    messageId = "actionSetupScheduling.recurring.summary.monthly";
    intervalText = getPluralCardinalMessage("actionSetupScheduling.recurring.summary.monthlyInterval", repeat, {
      numberOfDays: repeat
    });
  }

  let hoursText;
  if (isWholeDay) {
    hoursText = <BoldSelectedText>{getMessage("actionSetupScheduling.recurring.summary.allDay")}</BoldSelectedText>;
  } else {
    hoursText = getMessage("actionSetupScheduling.recurring.summary.betweenHours", {
      startHour: <HourText seconds={startHour} messageId="actionSetupScheduling.recurring.summary.selectStartDate" />,
      endHour: <HourText seconds={endHour} messageId="actionSetupScheduling.recurring.summary.selectEndDate" />
    });
  }

  return getMessage(messageId, {
    startDate: <DateText date={startDate} messageId="actionSetupScheduling.recurring.summary.selectStartDate" />,
    endDate: <DateText date={endDate} messageId="actionSetupScheduling.recurring.summary.selectEndDate" />,
    intervalText: <BoldSelectedText>{intervalText}</BoldSelectedText>,
    hours: hoursText,
    selectedDays: recurrenceRepeatInterval !== SCHEDULING_RECURRENCE_FREQUENCY_ENUM.DAILY ? getDaysText() : ""
  });
};

const ActionSetupNonRecurringSummary = ({ action }) => {
  const { start, end } = action.scheduling.promoValidity;

  if (start && end) {
    return getMessage("actionSetupScheduling.nonRecurring.summary", {
      startDate: <DateText date={start} />,
      endDate: <DateText date={end} />
    });
  }

  return getMessage("actionSetupScheduling.nonRecurring.summary.noSelection", {
    specifyPromoValidity: (
      <GraySelectText>{getMessage("actionSetupScheduling.nonRecurring.summary.specifyPromoValidity")}</GraySelectText>
    )
  });
};

const ActionSetupSchedulingSummary = ({ action }) => {
  return actionService.isRecurring(action) ? (
    <ActionSetupRecurringSummary action={action} />
  ) : (
    <ActionSetupNonRecurringSummary action={action} />
  );
};

ActionSetupSchedulingSummary.propTypes = {
  action: actionType.isRequired
};

export default ActionSetupSchedulingSummary;
