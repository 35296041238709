import React, { Fragment } from "react";
import PropTypes from "prop-types";

import InputField from "../../components/InputField";
import { getMessage } from "../../messages";

import { getMultiLangFieldLabel } from "../../helpers/multiLangHelper";

const MultiLangBenefitFields = ({ onChange, lang, translations }) => {
  const customRewardField = !!translations.customML ? (
    <InputField
      label={getMultiLangFieldLabel({ label: getMessage("benefitSetupCustomFields.custom.label"), lang })}
      value={translations.customML[lang]}
      name={"customML"}
      onChange={(event) => onChange(event, lang)}
    />
  ) : null;

  return (
    <Fragment>
      <InputField
        label={getMultiLangFieldLabel({ label: getMessage("benefitSetup.benefitName.tooltip"), lang })}
        value={translations.nameML[lang]}
        name={"nameML"}
        onChange={(event) => onChange(event, lang)}
      />
      {customRewardField}
    </Fragment>
  );
};

MultiLangBenefitFields.propTypes = {
  onChange: PropTypes.func,
  lang: PropTypes.string,
  translations: PropTypes.object
};

export default MultiLangBenefitFields;
