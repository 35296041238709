import React, { useState, useEffect, useCallback, Fragment, useRef } from "react";
import { renderToString } from "react-dom/server";
import { useDispatch } from "react-redux";
import validate from "validate.js";
import { showErrorAlert, showSuccessAlert } from "../actions/alertActions";
import customerApi from "../api/customerApi";
import LoaderOverlay from "../components/LoaderOverlay";
import { getMessage } from "../messages";
import RadioButtonList from "../components/RadioButtonList";
import InputField from "../components/InputField";
import { TimeField } from "../components/TimeFields";
import SelectField from "../components/SelectFieldV2";
import EmptyState from "../components/EmptyState";

import "../assets/styles/SalesDataConfiguration.scss";
import { getErrorMessage, validateSingleValue } from "../validators";
import {
  SALES_DATA_CSV_API_LINK,
  SALES_DATA_IMPORT_LINK,
  SALES_DATA_ORIGINAL_PRICE_FIELD_LINK,
  SALES_DATA_SHOPIFY_PLUGIN_LINK
} from "../constants/linkConstants";
import Notification from "../components/Notification";
import { DateField } from "../components/DateFields";
import moment from "moment";
import { updateStateOnInputChange } from "../helpers/formHelper";
import useStateCallback from "../hooks/useStateCallback";
import useDefaultPlan from "../hooks/useDefaultPlan";

const SALES_SOURCE_ENUM = {
  SHOPIFY: "shopifyPlugin",
  CSV_API: "csvApi",
  STREAM: "stream"
};

const SALES_SOURCE_OPTIONS = [
  {
    name: getMessage("securitySettings.salesData.sourceField.shopifyPluginOption"),
    value: SALES_SOURCE_ENUM.SHOPIFY,
    tooltip: getMessage("securitySettings.salesData.sourceField.shopifyPluginOptionTooltip", {
      link: renderToString(
        <a target="_blank" href={SALES_DATA_SHOPIFY_PLUGIN_LINK}>
          {getMessage("securitySettings.salesData.sourceField.shopifyPluginOptionTooltipLinkText")}
        </a>
      )
    }),
    hoverableTooltip: true
  },
  {
    name: getMessage("securitySettings.salesData.sourceField.csvApiOption"),
    value: SALES_SOURCE_ENUM.CSV_API,
    tooltip: getMessage("securitySettings.salesData.sourceField.csvApiOptionTooltip", {
      link: renderToString(
        <a target="_blank" href={SALES_DATA_CSV_API_LINK}>
          {getMessage("securitySettings.salesData.sourceField.csvApiOptionTooltipLinkText")}
        </a>
      )
    }),
    hoverableTooltip: true
  },
  {
    name: getMessage("securitySettings.salesData.sourceField.streamOnboardingOption"),
    value: SALES_SOURCE_ENUM.STREAM,
    disabled: true
  }
];

const SALES_FIELD_ENUM = {
  PRICE: "price",
  ORIGINAL_PRICE: "originalPrice"
};

const SALES_FIELD_OPTIONS = [
  {
    name: getMessage("securitySettings.salesData.salesField.priceOption"),
    value: SALES_FIELD_ENUM.PRICE
  },
  {
    name: getMessage("securitySettings.salesData.salesField.originalPriceOption"),
    value: SALES_FIELD_ENUM.ORIGINAL_PRICE,
    tooltip: getMessage("securitySettings.salesData.salesField.originalPriceOption.tooltip", {
      link: renderToString(
        <a target="_blank" href={SALES_DATA_ORIGINAL_PRICE_FIELD_LINK}>
          {getMessage("securitySettings.salesData.salesField.originalPriceOption.tooltipLinkText")}
        </a>
      )
    }),
    hoverableTooltip: true
  }
];

export default function SalesDataConfiguration() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [errors, setErrors] = useState();
  const [salesConfig, setSalesConfig] = useStateCallback({
    salesSource: "",
    salesPriceField: "",
    markets: [],
    startDateImport: "",
    startTimeImportCET: 0,
    isImportActive: false
  });

  const { isDefaultPlanLaunched, defaultPlanError } = useDefaultPlan();
  const disableInputs = !isDefaultPlanLaunched;

  useEffect(() => {
    loadSalesDataSettings();
  }, []);

  async function loadSalesDataSettings() {
    setLoading(true);

    try {
      const salesDataSettings = await customerApi.getSalesDataConfiguration();
      salesDataSettings.markets.sort((a, b) => a.id - b.id); //sort according to ascending id
      setSalesConfig(salesDataSettings);
    } catch (error) {
      console.error(error);
      setLoadError(true);
    }

    setLoading(false);
  }

  function onInputChange(event, customValue) {
    if (event.persist) {
      event.persist();
    }

    setSalesConfig((currentSalesConfig) => {
      const newSalesConfig = { ...currentSalesConfig };
      const inputName = updateStateOnInputChange({ stateObj: newSalesConfig, event, customValue });
      let newErrors = validateSingleValue(() => validateForm(newSalesConfig), errors, inputName);
      setErrors(newErrors);
      return newSalesConfig;
    });
  }

  function onStartTimeInputChange(event, timeStr) {
    const hour = timeStr ? parseInt(timeStr.split(":")[0]) : 0;
    onInputChange(event, hour);
  }

  function onPriceFieldChange(event) {
    const { value } = event.target;
    let newSalesConfig = { ...salesConfig };

    newSalesConfig.salesPriceField = value;
    if (value === SALES_FIELD_ENUM.ORIGINAL_PRICE && newSalesConfig.markets.length === 0) {
      newSalesConfig.markets.push({
        name: "",
        id: null
      });
    }

    setSalesConfig(newSalesConfig);
  }

  function onEditMarket(event, index) {
    const markets = [...salesConfig.markets];
    markets[index].name = event.target.value;
    const newSalesConfig = { ...salesConfig, markets };
    setSalesConfig(newSalesConfig);

    let newErrors = validateSingleValue(() => validateForm(newSalesConfig), errors, `marketName${index}`);
    setErrors(newErrors);
  }

  function onAddMarket() {
    const markets = [...salesConfig.markets];
    markets.push({
      name: "",
      id: null
    });
    setSalesConfig({ ...salesConfig, markets });
  }

  function onDeleteMarket(index) {
    const markets = [...salesConfig.markets];
    markets.splice(index, 1);
    setSalesConfig({ ...salesConfig, markets });
  }

  function MarketDeleteButton({ index }) {
    const style = {};
    if (salesConfig.markets.length <= 1) {
      style.visibility = "hidden";
    }

    return (
      <a className="e-btn e-btn-onlyicon no-border" onClick={() => onDeleteMarket(index)} style={style}>
        <e-icon icon="trash-o" size="small" />
      </a>
    );
  }

  function validateForm(salesConfigToValidate) {
    const { salesSource, salesPriceField, markets, startDateImport } = { ...salesConfigToValidate };

    const fieldsToValidate = {
      salesSource,
      startDateImport
    };

    const constraints = {
      salesSource: {
        presence: {
          allowEmpty: false,
          message: getMessage("securitySettings.salesData.sourceField.isRequiredError")
        }
      },
      startDateImport: {
        presence: {
          allowEmpty: false,
          message: getMessage("securitySettings.salesData.startDateImport.isRequiredError")
        }
      }
    };

    let newErrors;
    if (salesSource === SALES_SOURCE_ENUM.CSV_API && salesPriceField === SALES_FIELD_ENUM.ORIGINAL_PRICE) {
      markets.forEach((market, index) => {
        fieldsToValidate[`marketName${index}`] = market.name;
        constraints[`marketName${index}`] = {
          presence: {
            allowEmpty: false,
            message: getMessage("securitySettings.salesData.marketsField.isRequiredError")
          },
          exclusionIgnoreCase: {
            within: markets
              .filter((m, j) => {
                return index != j;
              })
              .map((m) => m.name),
            message: getMessage("securitySettings.salesData.duplicatedNameError")
          }
        };
      });
    }

    newErrors = validate(fieldsToValidate, constraints);
    return newErrors;
  }

  async function onSave() {
    const newErrors = validateForm(salesConfig);
    setErrors(newErrors);
    if (!newErrors) {
      setLoading(true);
      try {
        const newSalesConfig = await customerApi.updateSalesDataConfiguration(salesConfig);
        setSalesConfig(newSalesConfig);
        dispatch(showSuccessAlert(getMessage("securitySettings.salesData.savedSuccessfully")));
      } catch (error) {
        dispatch(showErrorAlert(getMessage("securitySettings.salesData.savedError")));
        console.error(error);
      }
      setLoading(false);
    } else {
      dispatch(showErrorAlert(getMessage("form.missingSettingsAlert")));
    }
  }

  function getNotificationContent() {
    if (isDefaultPlanLaunched === null) {
      return null;
    }

    if (!isDefaultPlanLaunched) {
      return getMessage("securitySettings.salesData.planNotLaunched", {
        link: renderToString(
          <a target="_blank" href={SALES_DATA_IMPORT_LINK}>
            {getMessage("securitySettings.salesData.planNotLaunched.linkText")}
          </a>
        )
      });
    }

    return getMessage("securitySettings.salesData.topNotification", {
      link: renderToString(
        <a target="_blank" href={SALES_DATA_IMPORT_LINK}>
          {getMessage("securitySettings.salesData.topNotification.linkText")}
        </a>
      )
    });
  }

  if (loadError || defaultPlanError) {
    return (
      <EmptyState
        icon="exclamation"
        title={getMessage("securitySettings.error.general.title")}
        text={getMessage("securitySettings.error.general.text")}
      />
    );
  }

  return (
    <div className="box-padding sales-data-configuration">
      <Notification content={getNotificationContent()} />
      <div className="e-row">
        <div className="e-col-6">
          <h2>{getMessage("securitySettings.salesData.pageTitle")}</h2>
          <p>{getMessage("securitySettings.salesData.selectTheSourceOfSalesData")}</p>
          <RadioButtonList
            label={getMessage("securitySettings.salesData.sourceField.label")}
            options={SALES_SOURCE_OPTIONS}
            value={salesConfig.salesSource}
            name="salesSource"
            onChange={onInputChange}
            isRequired={true}
            errorMsg={getErrorMessage(errors, "salesSource")}
            disabled={disableInputs}
          />

          {salesConfig.salesSource === SALES_SOURCE_ENUM.CSV_API && (
            <Fragment>
              <RadioButtonList
                label={getMessage("securitySettings.salesData.salesField.label")}
                tooltip={getMessage("securitySettings.salesData.salesField.tooltip")}
                options={SALES_FIELD_OPTIONS}
                value={salesConfig.salesPriceField}
                onChange={onPriceFieldChange}
                isRequired={true}
                disabled={disableInputs}
              />
              {salesConfig.salesPriceField === SALES_FIELD_ENUM.ORIGINAL_PRICE && (
                <div className="e-row">
                  <div className="e-col-4">
                    <div className="e-field markets-selection">
                      <label className="e-field__label">
                        {getMessage("securitySettings.salesData.marketsField.label")}
                        <e-tooltip
                          content={getMessage("securitySettings.salesData.marketsField.tooltip")}
                          type="helper"
                        />
                      </label>
                      {salesConfig.markets.map((market, index) => {
                        return (
                          <InputField
                            key={`market${index}`}
                            value={market.name}
                            onChange={(event) => onEditMarket(event, index)}
                            postfixButtons={<MarketDeleteButton index={index} />}
                            errorMsg={getErrorMessage(errors, `marketName${index}`)}
                            disabled={disableInputs}
                          />
                        );
                      })}
                      <button
                        className="e-btn e-btn-fullwidth add-new-market"
                        onClick={onAddMarket}
                        disabled={
                          disableInputs ||
                          (salesConfig.markets.length > 0 && !salesConfig.markets[salesConfig.markets.length - 1].name)
                        }
                      >
                        {getMessage("securitySettings.salesData.marketsField.addMarketButton")}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </Fragment>
          )}
          {salesConfig.salesSource !== SALES_SOURCE_ENUM.STREAM && (
            <Fragment>
              <DateField
                label={getMessage("securitySettings.salesData.startDateImport.label")}
                value={salesConfig.startDateImport}
                name="startDateImport"
                maxDate={moment()}
                minDate={moment().subtract(30, "days")}
                tooltip={getMessage("securitySettings.salesData.startDateImport.tooltip")}
                onChange={onInputChange}
                errorMsg={getErrorMessage(errors, "startDateImport")}
                disabled={disableInputs}
              />

              <TimeField
                label={getMessage("securitySettings.salesData.startTimeImport.label")}
                value={salesConfig.startTimeImportCET || 0}
                name="startTimeImportCET"
                showOnlyHour={true}
                tooltip={getMessage("securitySettings.salesData.startTimeImport.tooltip")}
                onChange={onStartTimeInputChange}
                disabled={disableInputs}
              />

              <SelectField
                label={getMessage("securitySettings.salesData.activation.label")}
                type="bool"
                value={salesConfig.isImportActive}
                name={"isImportActive"}
                onChange={onInputChange}
                disabled={disableInputs}
                options={[
                  { name: getMessage("securitySettings.salesData.activation.options.activate"), value: true },
                  { name: getMessage("securitySettings.salesData.activation.options.deactivate"), value: false }
                ]}
              />
            </Fragment>
          )}
          <button className="e-btn e-btn-primary" onClick={onSave}>
            {getMessage("securitySettings.salesData.saveButton")}
          </button>
        </div>
        <div className="e-col-6"></div>
        <div className="e-col e-col__separator"></div>
      </div>
      <LoaderOverlay show={loading} />
    </div>
  );
}
