import * as TYPES from "./TYPES";
import tierApi from "../api/tierApi";
import { invalidateJwt } from "./sessionActions";
import { getCustomer } from "./customerActions";

export const getAllTiers = () => {
  return (dispatch) => {
    dispatch({
      type: TYPES.GET_ALL_TIERS_REQUEST
    });
    return tierApi
      .getAllTiers()
      .then((data) => {
        dispatch({
          type: TYPES.GET_ALL_TIERS_SUCCESS,
          payload: data
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.GET_ALL_TIERS_FAILURE
        });
      });
  };
};

export const updateTier = (tier, id, disableAlert) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.POST_TIER_REQUEST
    });

    const isNew = !id;
    const setMethod = isNew ? tierApi.createTier : tierApi.updateTier;
    return setMethod(tier, id)
      .then((data) => {
        dispatch({
          type: TYPES.POST_TIER_SUCCESS,
          payload: {
            data,
            isNew,
            disableAlert
          }
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.POST_TIER_FAILURE,
          payload: {
            isNew
          }
        });
      });
  };
};

export const deleteTier = (id) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.DELETE_TIER_REQUEST
    });

    return tierApi
      .deleteTier(id)
      .then(() => {
        dispatch({
          type: TYPES.DELETE_TIER_SUCCESS,
          payload: {
            id
          }
        });
        dispatch(getAllTiers());
        dispatch(getCustomer());
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.DELETE_TIER_FAILURE
        });
      });
  };
};

export const setTierEditState = (tier, stepId) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.SET_TIER_EDIT_STATE,
      payload: {
        tier,
        stepId
      }
    });
  };
};

export const resetTierEditState = () => {
  return function (dispatch) {
    dispatch({
      type: TYPES.RESET_TIER_EDIT_STATE
    });
  };
};

export const resetNewTierId = () => {
  return function (dispatch) {
    dispatch({
      type: TYPES.RESET_NEW_TIER_ID
    });
  };
};
