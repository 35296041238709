import validate from "validate.js";
import { getMessage } from "../messages";

export default class additionalBenefitsUpdateValidator {
  constructor() {}

  createConstraints() {
    let constraints = {
      confirmed: {
        isTrue: {
          message: getMessage("additionalBenefitsUpdateValidator.confirmed.isTrue")
        }
      },
      reason: {
        presence: {
          allowEmpty: false,
          message: getMessage("additionalBenefitsUpdateValidator.reason.presence")
        }
      }
    };

    return constraints;
  }

  validate = (fields) => {
    return validate(fields, this.createConstraints());
  };
}
