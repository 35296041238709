import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import MultiSelectField from "../../components/MultiSelectField";
import InputField from "../../components/InputField";
import CheckboxField from "../../components/CheckboxField";
import LoaderOverlay from "../../components/LoaderOverlay";
import PopupContainer from "../../components/PopupContainer";
import Notification from "../../components/Notification";
import EmptyState from "../../components/EmptyState";

import currentPlanVersionApi from "../../api/currentPlanVersionApi";
import memberProfileApi from "../../api/memberProfileApi";

import { updateStateOnInputChange, convertArrayToOptionsArray } from "../../helpers/formHelper";

import { validateSingleValue, getErrorMessage } from "../../validators";
import additionalBenefitsUpdateValidator from "../../validators/additionalBenefitsUpdateValidator";
import { getMessage } from "../../messages";

class MemberProfileAdditionalBenefitsPopup extends Component {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
    this.state.additionalBenefitsByPlan = {};
  }

  componentDidUpdate(prevProps) {
    if (this.props.isShow && !prevProps.isShow) {
      this.validator = new additionalBenefitsUpdateValidator();
      this.loadData();
    }
  }

  loadData() {
    let promises = [];
    const { contactPlanId, contactId } = this.props;

    promises.push(memberProfileApi.getMemberAdditionalBenefits(contactId));

    if (!this.state.additionalBenefitsByPlan[contactPlanId]) {
      promises.push(currentPlanVersionApi.getCurrentPlanVersion(contactPlanId));
    }

    this.setState({ loading: true });
    Promise.all(promises)
      .then((response) => {
        this.setMemberAdditionalBenefits(response[0]);
        response[1] && this.setAdditionalBenefitsFromPlan(response[1], contactPlanId);
      })
      .catch((error) => {
        this.setState({ loadError: true });
        throw error;
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  setAdditionalBenefitsFromPlan(plan, contactPlanId) {
    const additionalBenefits = plan.additionalBenefits;

    const { additionalBenefitsByPlan } = { ...this.state };
    additionalBenefitsByPlan[contactPlanId] = additionalBenefits;

    this.setState({
      additionalBenefitsByPlan
    });
  }

  setMemberAdditionalBenefits(contactAdditionalBenefits) {
    let contactBenefitIds = [];
    let redeemedBenefitIds = [];
    let benefitToTrackingMap = {};

    contactAdditionalBenefits.forEach((benefit) => {
      contactBenefitIds.push(benefit.benefitId);
      if (benefit.isRedeemed) {
        redeemedBenefitIds.push(benefit.benefitId);
      }
      benefitToTrackingMap[benefit.benefitId] = benefit.trackingId;
    });

    this.setState({
      contactBenefitIds,
      selectedBenefitIds: [...contactBenefitIds],
      redeemedBenefitIds,
      benefitToTrackingMap
    });
  }

  getDefaultState() {
    return {
      contactBenefitIds: [],
      selectedBenefitIds: [],
      redeemedBenefitIds: [],
      benefitToTrackingMap: {},
      confirmed: false,
      loading: false,
      reason: "",
      errors: undefined,
      loadError: false
    };
  }

  closePopup() {
    this.setState(this.getDefaultState());
    this.props.onClose();
  }

  onInputChange(event, customValue) {
    const state = { ...this.state };

    const inputName = updateStateOnInputChange({ stateObj: state, event, customValue });

    const errors = validateSingleValue(() => this.validator.validate(state), state.errors, inputName);
    state.errors = errors;

    this.setState(state);
    return state;
  }

  onAdditionalBenefitsUpdateClick() {
    const errors = this.validator.validate(this.state);
    if (errors) {
      this.setState({
        errors
      });

      return;
    }

    const { contactPlanId } = { ...this.props };
    const { contactBenefitIds, selectedBenefitIds, reason, benefitToTrackingMap, additionalBenefitsByPlan } = {
      ...this.state
    };

    const planAdditionalBenefits = additionalBenefitsByPlan[contactPlanId];

    const benefitIdsToAdd = selectedBenefitIds.filter((benefitId) => !contactBenefitIds.includes(benefitId));
    const benefitIdsToRemove = contactBenefitIds.filter((benefitId) => {
      return !selectedBenefitIds.includes(benefitId) && planAdditionalBenefits.some((b) => b.id === benefitId);
    });
    const benefitsWithTrackingToRemove = benefitIdsToRemove.map((benefitId) => {
      return { benefitId, trackingId: benefitToTrackingMap[benefitId] };
    });

    this.props.onUpdateAdditionalBenefits({ benefitIdsToAdd, benefitsWithTrackingToRemove, reason });
  }

  render() {
    const { additionalBenefitsByPlan, selectedBenefitIds, redeemedBenefitIds, reason, confirmed, errors, loadError } = {
      ...this.state
    };
    const { contactPlanId } = { ...this.props };

    const additionalBenefits = additionalBenefitsByPlan[contactPlanId];

    const options = additionalBenefits ? convertArrayToOptionsArray(additionalBenefits, "id", "name") : [];
    return (
      <PopupContainer
        width="880px"
        id="memberProfileAdditionalBenefitsPopup"
        title={getMessage("memberProfileAdditionalBenefitsPopup.title")}
        show={this.props.isShow}
        onClose={this.closePopup.bind(this)}
      >
        <Fragment>
          <LoaderOverlay show={this.state.loading} />

          {loadError ? (
            <EmptyState icon="exclamation" title="Error" text={getMessage("error.failToLoadData")} />
          ) : (
            <Fragment>
              <Notification content={getMessage("memberProfileAdditionalBenefitsPopup.notification")} />

              <MultiSelectField
                label={getMessage("memberProfileAdditionalBenefitsPopup.selectedBenefitIds.label")}
                name="selectedBenefitIds"
                options={options}
                value={selectedBenefitIds}
                disabledValues={redeemedBenefitIds}
                onChange={this.onInputChange.bind(this)}
                disableRemoveValue={true}
                tooltip={getMessage("memberProfileAdditionalBenefitsPopup.selectedBenefitIds.tooltip")}
              />

              <InputField
                label={getMessage("memberProfileAdditionalBenefitsPopup.reason.label")}
                name="reason"
                value={reason}
                onChange={this.onInputChange.bind(this)}
                errorMsg={getErrorMessage(errors, "reason")}
                tooltip={getMessage("memberProfileAdditionalBenefitsPopup.reason.tooltip")}
              />

              <CheckboxField
                name={`confirmed`}
                id="updateAdditionalBenefitsConfirmed"
                inline={true}
                label={getMessage("memberProfileAdditionalBenefitsPopup.confirmCb.label")}
                checked={confirmed}
                onChange={this.onInputChange.bind(this)}
                errorMsg={getErrorMessage(errors, "confirmed")}
              />

              <hr className="e-separator e-separator-fullwidth" />
              <div className="e-buttongroup">
                <a className="e-btn" onClick={this.closePopup.bind(this)}>
                  {getMessage("cancelBtn.label")}
                </a>
                <a className="e-btn e-btn-primary" onClick={this.onAdditionalBenefitsUpdateClick.bind(this)}>
                  {getMessage("updateAndSave.label")}
                </a>
              </div>
            </Fragment>
          )}
        </Fragment>
      </PopupContainer>
    );
  }
}

MemberProfileAdditionalBenefitsPopup.propTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  contactId: PropTypes.string,
  contactPlanId: PropTypes.string,
  onUpdateAdditionalBenefits: PropTypes.func
};

export default MemberProfileAdditionalBenefitsPopup;
