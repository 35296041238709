import React, { Fragment } from "react";
import PropTypes from "prop-types";

import SelectField from "../../components/SelectFieldV2";
import CheckboxField from "../../components/CheckboxField";

import rewardService from "../../services/rewardService";

import { getErrorMessage } from "../../validators";

import { benefitType } from "../../types";
import { getMessage } from "../../messages";

const BenefitSetupVoucherFields = ({
  benefit,
  tierList,
  options,
  allRewardOptions,
  onInputChange,
  onCheckboxChange,
  isAdditionalBenefit,
  errors
}) => {
  const tiersError = getErrorMessage(errors, "tiers");

  const getOptions = (value) => {
    return rewardService.getOptionsWithSelected({
      allOptions: allRewardOptions,
      validOptions: options,
      selectedValue: value
    });
  };

  return (
    <Fragment>
      {isAdditionalBenefit ? (
        <SelectField
          label={getMessage("benefitSetupVoucherFields.rewardId.label")}
          name={`rewardId`}
          value={benefit.rewardId}
          options={getOptions(benefit.rewardId)}
          onChange={onInputChange}
          placeholder={getMessage("benefitSetupVoucherFields.rewardId.placeholder")}
          errorMsg={getErrorMessage(errors, `rewardId`)}
          tooltip={getMessage("benefitSetupVoucherFields.rewardId.tooltip")}
        />
      ) : (
        <Fragment>
          <label className="e-field__label">
            {getMessage("benefitSetupVoucherFields.tiersAndRewards.title")}{" "}
            <e-tooltip content={getMessage("benefitSetupVoucherFields.tiersAndRewards.tooltip")} type="helper" />
          </label>
          <div className="benefit-setup-tiers">
            {tierList.map((tier) => {
              const benefitTier = benefit.tiers[tier.id];

              const value = benefitTier.enabled ? benefitTier.rewardId : "";

              options = getOptions(value);

              return (
                <div key={`voucherFields_${tier.id}`}>
                  <CheckboxField
                    name={`tiers.${tier.id}.enabled`}
                    noMargin={true}
                    label={tier.name}
                    checked={benefitTier.enabled}
                    onChange={(event) => onCheckboxChange(event, tier.id)}
                    errorMsg={tiersError}
                  />
                  <div className="m-t-5">
                    <SelectField
                      name={`tiers.${tier.id}.rewardId`}
                      noMargin={true}
                      value={value}
                      options={options}
                      onChange={onInputChange}
                      placeholder={getMessage("benefitSetupVoucherFields.rewardId.placeholder")}
                      disabled={!benefitTier.enabled}
                      errorMsg={getErrorMessage(errors, `tiers.${tier.id}.rewardId`)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div class="e-field__message e-field__message-error m-n-t-10">{tiersError}</div>
        </Fragment>
      )}
    </Fragment>
  );
};

BenefitSetupVoucherFields.propTypes = {
  benefit: benefitType,
  tierList: PropTypes.array,
  options: PropTypes.array,
  allRewardOptions: PropTypes.array,
  onCheckboxChange: PropTypes.func,
  onInputChange: PropTypes.func,
  isAdditionalBenefit: PropTypes.bool,
  errors: PropTypes.shape({})
};

export default BenefitSetupVoucherFields;
