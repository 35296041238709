import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const DeviceModeTabs = (props) => {
  const changeDeviceMode = (newValue) => {
    if ((newValue && props.isMobile) || (!newValue && !props.isMobile)) {
      return;
    }

    props.onChange(newValue);
  };

  const getButtonClassName = (isActive) => {
    return classNames("e-btn e-btn-onlyicon", {
      "e-btn-active": isActive
    });
  };

  return (
    <div className="e-buttongroup e-buttongroup-sticky e-fullheight__actions">
      <div className={getButtonClassName(!props.isMobile)} onClick={() => changeDeviceMode(false)}>
        <e-tooltip placement="bottom" content="Desktop">
          <e-icon icon="monitor" />
        </e-tooltip>
      </div>
      <div className={getButtonClassName(props.isMobile)} onClick={() => changeDeviceMode(true)}>
        <e-tooltip placement="bottom" content="Mobile">
          <e-icon icon="mobile" />
        </e-tooltip>
      </div>
    </div>
  );
};

DeviceModeTabs.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default DeviceModeTabs;
