import { Fragment } from "react";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";

import {
  ACTIVITY_TYPE,
  ACTIVITY_ELEMENT_TYPE,
  ACTIVITY_ELEMENT_MULTIPLY_POINTS_TEXT,
  ACTIVITY_ELEMENT_POINTS_TYPE_TEXT,
  ACTIVITY_STATUS_TYPE,
  ACTIVITY_STATUS_TYPE_TEXT,
  POINTS_TYPE_ENUM,
  EMPTY_CELL_TEXT,
  ACTIVITY_ELEMENT_LABEL,
  VOUCHER_TYPE,
  VOUCHER_TYPE_TEXT,
  EXCLUSIVE_ACCESS_TYPE_TEXT
} from "../../constants/memberProfileConstants";

import { MASTER_EXCLUSIVE_ACCESS_ENUM } from "../../constants/exclusiveAccessConstants";

import { POINTS_PLACEHOLDER } from "../../helpers/pointsHelper";

import { __RouterContext } from "react-router";
import { getMessage } from "../../messages";
import Time from "../../components/Time";

export const renderStatusLabel = (status) => {
  let className = "";

  switch (status) {
    case ACTIVITY_STATUS_TYPE.CONFIRMED:
      className = "text-color-success";
      break;
    case ACTIVITY_STATUS_TYPE.PENDING:
      className = "text-color-warning";
      break;
    case ACTIVITY_STATUS_TYPE.EXPIRED:
    case ACTIVITY_STATUS_TYPE.REMOVED:
    case ACTIVITY_STATUS_TYPE.CANCELED:
      className = "text-color-danger";
      break;
    default:
      className = "";
  }

  return <span className={className}>{ACTIVITY_STATUS_TYPE_TEXT[status]}</span>;
};

export const renderDateTimeText = ({ timestamp, onlyDate }) => {
  return timestamp ? <Time value={timestamp} onlyDate={onlyDate} /> : "";
};

export const getActivityName = (activity, getPointsNameText) => {
  switch (activity.activityType) {
    case ACTIVITY_TYPE.ONLINE_PURCHASE:
      return GetGroupActivityName(activity) || getMessage("activityType.online_purchase.name");
    case ACTIVITY_TYPE.ACTION:
      return GetGroupActivityName(activity) || activity.name;
    case ACTIVITY_TYPE.CUSTOMER_SUPPORT: {
      const element = activity.elements[0];
      if (element.type === ACTIVITY_ELEMENT_TYPE.SUPPORT_TIER_UPGRADE)
        return GetGroupActivityName(activity) || getMessage("activityType.customer_support.tierUpgrade.name");
      if (element.type === ACTIVITY_ELEMENT_TYPE.SUPPORT_TIER_DOWNGRADE)
        return GetGroupActivityName(activity) || getMessage("activityType.customer_support.tierDowngrade.name");
      if (element.type === ACTIVITY_ELEMENT_TYPE.SUPPORT_ADD_POINTS) {
        if (getElementPointsType(element) === POINTS_TYPE_ENUM.CASHBACK)
          return getMessage("activityType.customer_support.cashbackAdded.name");
        else
          return (
            GetGroupActivityName(activity) ||
            getPointsNameText(getMessage("activityType.customer_support.pointsAdded.name"))
          );
      }
      if (element.type === ACTIVITY_ELEMENT_TYPE.SUPPORT_REMOVE_POINTS) {
        if (getElementPointsType(element) === POINTS_TYPE_ENUM.CASHBACK)
          return getMessage("activityType.customer_support.cashbackRemoved.name");
        else
          return (
            GetGroupActivityName(activity) ||
            getPointsNameText(getMessage("activityType.customer_support.pointsRemoved.name"))
          );
      }
      if (element.type === ACTIVITY_ELEMENT_TYPE.SUPPORT_TIER_DOWNGRADE)
        ///TBD
        return GetGroupActivityName(activity) || getPointsNameText(`${POINTS_PLACEHOLDER} removed`);

      if (element.type === ACTIVITY_ELEMENT_TYPE.SUPPORT_PLAN_CHANGE) {
        return element.value
          ? getMessage("activityType.customer_support.planChange.name", {
              planName: element.value.planName,
              planId: element.value.planId
            })
          : "";
      }

      return activity.name;
    }
    case ACTIVITY_TYPE.REDEMPTION:
    case ACTIVITY_TYPE.VOUCHER_REDEMPTION:
    case ACTIVITY_TYPE.VOUCHER_REDEMPTION_FREE:
    case ACTIVITY_TYPE.VOUCHER_REDEMPTION_POINTS:
    case ACTIVITY_TYPE.EXCLUSIVE_REDEMPTION_FREE:
    case ACTIVITY_TYPE.EXCLUSIVE_REDEMPTION_POINTS:
    case ACTIVITY_TYPE.VOUCHER_REDEMPTION_CASHBACK:
      return getMessage("activityType.redemption.name");
    case ACTIVITY_TYPE.EXCLUSIVE_REDEMPTION:
      return getMessage("activityType.exclusive_redemption.name");
    case ACTIVITY_TYPE.POINTS_EXPIRATION:
      const pointsType = activity.elements[0].value.pointsType || Object.keys(activity.elements[0].value.pointsInfo)[0];
      if (pointsType === POINTS_TYPE_ENUM.STATUS)
        return (
          GetGroupActivityName(activity) || getPointsNameText(getMessage("activityType.points_expiration.status.name"))
        );
      if (pointsType === POINTS_TYPE_ENUM.POINTS_TO_SPEND)
        return getPointsNameText(getMessage("activityType.points_expiration.toSpend.name"));
      if (pointsType === POINTS_TYPE_ENUM.CASHBACK) return getMessage("activityType.points_expiration.cachback.name");
      return "";
    case ACTIVITY_TYPE.TIER_CALCULATION:
      return getMessage("activityType.tier_calculation.name");
    case ACTIVITY_TYPE.GROUP_CREATION:
    case ACTIVITY_TYPE.GROUP_CALCULATION:
      if (isGroupActivity(activity)) {
        return activity.activityType === ACTIVITY_TYPE.GROUP_CREATION
          ? getMessage("activityType.group_create.name")
          : getMessage("activityType.group_calculation.name");
      }

      const contactElement = activity.elements.find((element) => element.type === ACTIVITY_ELEMENT_TYPE.CONTACT_STATE);
      const label = contactElement?.value?.label;
      switch (label) {
        case ACTIVITY_ELEMENT_LABEL.JOINED:
          return getMessage("activityType.group_calculation.joinMember.name");
        case ACTIVITY_ELEMENT_LABEL.LEFT:
          return getMessage("activityType.group_calculation.leftMember.name");
        case ACTIVITY_ELEMENT_LABEL.NO_CHANGE:
          return getMessage("activityType.group_calculation.noChange.name");
      }
      return "";
    case ACTIVITY_TYPE.GROUP_DELETED:
      return getMessage("activityType.group_delete.name");
    case ACTIVITY_TYPE.ADDITIONAL_BENEFITS: {
      const label = activity.elements[0]?.value?.label;
      if (label == ACTIVITY_ELEMENT_LABEL.ADDED) return getMessage("activityType.additional_benefits.added.name");
      else if (label == ACTIVITY_ELEMENT_LABEL.REMOVED)
        return getMessage("activityType.additional_benefits.removed.name");
    }
    case ACTIVITY_TYPE.MEMBER_JOINED: {
      return getMessage("activityType.member_joined.name");
    }
    case ACTIVITY_TYPE.POINTS_MIGRATED: {
      return getMessage("activityType.points_migrated.name");
    }
    default:
      return "";
  }
};

export const GetGroupActivityName = (activity) => {
  if (!isGroupActivity(activity) && activity?.flags?.isFromGroup) {
    return getMessage("groupActivity");
  }
};

export const getActivityElementType = (activity, element, getPointsNameText) => {
  const activityType = activity.activityType;

  switch (element.type) {
    case ACTIVITY_ELEMENT_TYPE.PURCHASE_POINTS:
      if (element.value.points < 0) {
        if (activityType === ACTIVITY_TYPE.POINTS_EXPIRATION) {
          const pointsType = getElementPointsType(element);
          if (pointsType === POINTS_TYPE_ENUM.STATUS)
            return getPointsNameText(getMessage("activityType.points_expiration.status.name"));
          if (pointsType === POINTS_TYPE_ENUM.POINTS_TO_SPEND)
            return getPointsNameText(getMessage("activityType.points_expiration.toSpend.name"));
        }
        return getMessage("activityElementType.purchase_points.negative");
      } else {
        return element.value.isFromCatalog
          ? getMessage("activityElementType.purchase_points.positive.fromCatalog")
          : getMessage("activityElementType.purchase_points.positive.fromOnline");
      }
    case ACTIVITY_ELEMENT_TYPE.BONUS_POINTS: {
      if (activityType === ACTIVITY_TYPE.POINTS_EXPIRATION) {
        const pointsType = getElementPointsType(element);
        if (pointsType === POINTS_TYPE_ENUM.STATUS)
          return getPointsNameText(getMessage("activityType.points_expiration.status.name"));
        if (pointsType === POINTS_TYPE_ENUM.POINTS_TO_SPEND)
          return getPointsNameText(getMessage("activityType.points_expiration.toSpend.name"));
      }

      return element.name;
    }
    case ACTIVITY_ELEMENT_TYPE.MULTIPLY_POINTS:
    case ACTIVITY_ELEMENT_TYPE.STATUS_UPGRADE:
    case ACTIVITY_ELEMENT_TYPE.VOUCHER:
    case ACTIVITY_ELEMENT_TYPE.EXCLUSIVE_ACCESS:
    case ACTIVITY_ELEMENT_TYPE.STATUS_POINTS: {
      if (activityType === ACTIVITY_TYPE.ADDITIONAL_BENEFITS) {
        return getMessage("activityElementType.byCustomerSupport");
      }
      return element.name;
    }
    case ACTIVITY_ELEMENT_TYPE.PLAN_CHANGE_VOUCHER: {
      return getMessage("activityElementType.plan_change_voucher");
    }
    case ACTIVITY_ELEMENT_TYPE.REDEMPTION_POINTS:
      switch (activityType) {
        case ACTIVITY_TYPE.VOUCHER_REDEMPTION:
        case ACTIVITY_TYPE.VOUCHER_REDEMPTION_FREE:
        case ACTIVITY_TYPE.VOUCHER_REDEMPTION_POINTS:
          return getMessage("activityElementType.redemption_points.voucherRedemptionPoints");
        case ACTIVITY_TYPE.EXCLUSIVE_REDEMPTION:
        case ACTIVITY_TYPE.EXCLUSIVE_REDEMPTION_FREE:
        case ACTIVITY_TYPE.EXCLUSIVE_REDEMPTION_POINTS:
          return getMessage("activityElementType.redemption_points.exclusiveRedemptionPoints");
        case ACTIVITY_TYPE.VOUCHER_REDEMPTION_CASHBACK:
          return getMessage("activityElementType.redemption_points.voucherRedemptionCashback");
        default:
          return getMessage("activityElementType.redemption_points");
      }
    case ACTIVITY_ELEMENT_TYPE.REDEMPTION_FREE:
      switch (activityType) {
        case ACTIVITY_TYPE.VOUCHER_REDEMPTION:
        case ACTIVITY_TYPE.VOUCHER_REDEMPTION_FREE:
        case ACTIVITY_TYPE.VOUCHER_REDEMPTION_POINTS:
          return getMessage("activityElementType.redemption_free.voucher");
        case ACTIVITY_TYPE.EXCLUSIVE_REDEMPTION:
        case ACTIVITY_TYPE.EXCLUSIVE_REDEMPTION_FREE:
        case ACTIVITY_TYPE.EXCLUSIVE_REDEMPTION_POINTS:
          return getMessage("activityElementType.redemption_free.exclusive");
        default:
          return getMessage("activityElementType.redemption_free");
      }
    case ACTIVITY_ELEMENT_TYPE.SUPPORT_PLAN_CHANGE:
      return getMessage("activityElementType.support_plan_change");
    case ACTIVITY_ELEMENT_TYPE.PLAN_CHANGE_POINTS:
      return getMessage("activityElementType.plan_change_points");
    case ACTIVITY_ELEMENT_TYPE.SUPPORT_ADD_POINTS:
    case ACTIVITY_ELEMENT_TYPE.SUPPORT_REMOVE_POINTS:
    case ACTIVITY_ELEMENT_TYPE.SUPPORT_TIER_UPGRADE:
    case ACTIVITY_ELEMENT_TYPE.SUPPORT_TIER_DOWNGRADE:
      return activity.name || getMessage("activityElementType.byCustomerSupport");
    case ACTIVITY_ELEMENT_TYPE.TIER_CALC_REMOVE_POINTS:
      return getPointsNameText(getMessage("activityType.points_expiration.status.name"));
    case ACTIVITY_ELEMENT_TYPE.TIER_CALC_TIER_DOWNGRADE:
      return getMessage("activityType.tier_calculation.name");
    case ACTIVITY_ELEMENT_TYPE.CONTACT_STATE: {
      const { label } = { ...element.value };
      switch (label) {
        case ACTIVITY_ELEMENT_LABEL.JOINED:
          return getMessage("activityElementType.contact_state.joinedGroup");
        case ACTIVITY_ELEMENT_LABEL.LEFT:
          return isGroupActivity(activity)
            ? getMessage("activityElementType.contact_state.leftGroup.allGroupActivity")
            : getMessage("activityElementType.contact_state.leftGroup.onlyMemberLeft");
        case ACTIVITY_ELEMENT_LABEL.NO_CHANGE:
          return EMPTY_CELL_TEXT;
      }
    }
    case ACTIVITY_ELEMENT_TYPE.GROUP_TIER:
    case ACTIVITY_ELEMENT_TYPE.GROUP_STATUS_POINTS:
      return getMessage("activityType.group_calculation.name");
    case ACTIVITY_ELEMENT_TYPE.MIGTARION_POINTS:
      return activity.name;
    default:
      return "";
  }
};

const getElementPointsType = (element) => {
  if (!!element.value.pointsType) {
    return element.value.pointsType;
  }
  if (!element.value?.pointsInfo) return undefined;
  const pointTypes = Object.keys(element.value.pointsInfo);
  if (
    pointTypes.length == 2 &&
    element.value.pointsInfo[pointTypes[0]].amount == element.value.pointsInfo[pointTypes[1]].amount
  ) {
    return POINTS_TYPE_ENUM.LOYALTY;
  }
  return pointTypes[0];
};

export const getActivityElementRewardName = (
  activity,
  element,
  getPointsNameText,
  isSpendPlan,
  getCashbackNameText
) => {
  const activityType = activity.activityType;
  switch (element.type) {
    case ACTIVITY_ELEMENT_TYPE.PURCHASE_POINTS:
      if (getElementPointsType(element) === POINTS_TYPE_ENUM.CASHBACK) {
        return getMessage("rewardName.cashback");
      }

      if (isGroupActivity(activity) || activity?.flags?.isFromGroup) {
        return getPointsNameText(ACTIVITY_ELEMENT_POINTS_TYPE_TEXT.status);
      }
      return element.value.isFromCatalog
        ? getPointsNameText(getMessage("rewardName.pointsAdditional"))
        : getPointsNameText(getMessage("rewardName.pointsBasic"));
    case ACTIVITY_ELEMENT_TYPE.BONUS_POINTS:
      if (activityType === ACTIVITY_TYPE.POINTS_EXPIRATION) {
        return getPointsNameText(ACTIVITY_ELEMENT_POINTS_TYPE_TEXT[getElementPointsType(element)]);
      }
      return getPointsNameText(
        isSpendPlan ? getMessage("rewardName.pointsExtra") : getMessage("rewardName.pointsBonus")
      );
    case ACTIVITY_ELEMENT_TYPE.MULTIPLY_POINTS:
      return getPointsNameText(`${ACTIVITY_ELEMENT_MULTIPLY_POINTS_TEXT[element.value.label]} ${POINTS_PLACEHOLDER}`);
    case ACTIVITY_ELEMENT_TYPE.STATUS_POINTS:
      return getPointsNameText(ACTIVITY_ELEMENT_POINTS_TYPE_TEXT.status);
    case ACTIVITY_ELEMENT_TYPE.STATUS_UPGRADE:
      return getMessage("rewardName.statusUpgrade");
    case ACTIVITY_ELEMENT_TYPE.VOUCHER:
    case ACTIVITY_ELEMENT_TYPE.PLAN_CHANGE_VOUCHER:
      return getMessage("rewardName.voucher");
    case ACTIVITY_ELEMENT_TYPE.EXCLUSIVE_ACCESS:
      return getMessage("rewardName.exclusiveAccess");
    case ACTIVITY_ELEMENT_TYPE.REDEMPTION_POINTS:
    case ACTIVITY_ELEMENT_TYPE.REDEMPTION_FREE: {
      //if voucher
      if (
        activityType === ACTIVITY_TYPE.VOUCHER_REDEMPTION_FREE ||
        activityType === ACTIVITY_TYPE.VOUCHER_REDEMPTION_POINTS ||
        activityType === ACTIVITY_TYPE.VOUCHER_REDEMPTION_CASHBACK
      ) {
        switch (element?.value?.voucherType) {
          case VOUCHER_TYPE.ONE_TIME:
            return VOUCHER_TYPE_TEXT.ONE_TIME;
          case VOUCHER_TYPE.MULTI_USE:
            return VOUCHER_TYPE_TEXT.MULTI_USE;
          default:
            return getMessage("rewardName.voucher");
        }
        //if exclusive access
      } else {
        switch (element?.value?.exclusiveType) {
          case MASTER_EXCLUSIVE_ACCESS_ENUM.PERMANENT:
            return EXCLUSIVE_ACCESS_TYPE_TEXT.PERMANENT;
          case MASTER_EXCLUSIVE_ACCESS_ENUM.LIMITED:
            return EXCLUSIVE_ACCESS_TYPE_TEXT.LIMITED;
          default:
            return getMessage("rewardName.exclusiveAccess");
        }
      }
    }
    case ACTIVITY_ELEMENT_TYPE.PLAN_CHANGE_POINTS: {
      return getPointsNameText(ACTIVITY_ELEMENT_POINTS_TYPE_TEXT[getElementPointsType(element)]);
    }
    case ACTIVITY_ELEMENT_TYPE.SUPPORT_TIER_UPGRADE:
      return getMessage("activityType.customer_support.tierUpgrade.name");
    case ACTIVITY_ELEMENT_TYPE.SUPPORT_TIER_DOWNGRADE:
      return getMessage("activityType.customer_support.tierDowngrade.name");
    case ACTIVITY_ELEMENT_TYPE.SUPPORT_ADD_POINTS:
    case ACTIVITY_ELEMENT_TYPE.SUPPORT_REMOVE_POINTS:
      const pointType = getElementPointsType(element);
      if (pointType === POINTS_TYPE_ENUM.CASHBACK) {
        return ACTIVITY_ELEMENT_POINTS_TYPE_TEXT[pointType];
      }
      return getPointsNameText(ACTIVITY_ELEMENT_POINTS_TYPE_TEXT[pointType]);
    case ACTIVITY_ELEMENT_TYPE.CONTACT_STATE:
      return getPointsNameText(ACTIVITY_ELEMENT_POINTS_TYPE_TEXT[getElementPointsType(element)]);
    case ACTIVITY_ELEMENT_TYPE.GROUP_TIER:
      return getMessage("rewardName.tier");
    case ACTIVITY_ELEMENT_TYPE.GROUP_STATUS_POINTS:
      return getPointsNameText(ACTIVITY_ELEMENT_POINTS_TYPE_TEXT[getElementPointsType(element)]);
    case ACTIVITY_ELEMENT_TYPE.MIGTARION_POINTS:
      if (activity.version == "v1") {
        return getPointsNameText(ACTIVITY_ELEMENT_POINTS_TYPE_TEXT.loyalty);
      }

      if (!isSpendPlan) {
        return getPointsNameText(ACTIVITY_ELEMENT_POINTS_TYPE_TEXT.loyalty);
      }
      return (
        getPointsNameText(ACTIVITY_ELEMENT_POINTS_TYPE_TEXT.loyalty) + "/" + getMessage("rewardName.cashback")
        //getCashbackNameText(ACTIVITY_ELEMENT_POINTS_TYPE_TEXT.cashbackName)
      );

    default:
      return "";
  }
};

export const getActivityV2PointValues = (element, rounded, getPointsNameText) => {
  var results = [];
  Object.keys(element.value.pointsInfo).forEach((pointType) => {
    let pointValue = element.value.pointsInfo[pointType].amount;
    if (rounded) pointValue = getRoundedPoints(pointValue);
    results.push(
      getActivitiesPointsText({
        points: pointValue,
        pointsType: pointType,
        showZero: true,
        getPointsNameText,
        showPointsTypeName: true
      })
    );
  });
  return results.join("</br>");
};

export const getActivityElementRewardValue = (activity, element, getPointsNameText, rounded) => {
  switch (element.type) {
    case ACTIVITY_ELEMENT_TYPE.PURCHASE_POINTS:
    case ACTIVITY_ELEMENT_TYPE.BONUS_POINTS:
    case ACTIVITY_ELEMENT_TYPE.MULTIPLY_POINTS:
    case ACTIVITY_ELEMENT_TYPE.SUPPORT_ADD_POINTS:
    case ACTIVITY_ELEMENT_TYPE.SUPPORT_REMOVE_POINTS:
    case ACTIVITY_ELEMENT_TYPE.TIER_CALC_REMOVE_POINTS:
    case ACTIVITY_ELEMENT_TYPE.STATUS_POINTS:
      if (activity.version == "v1") {
        const points = rounded ? getRoundedPoints(element.value.points) : element.value.points;
        return getActivitiesPointsText({
          points,
          pointsType: element.value.pointsType,
          showZero: true,
          splitLoyaltyPoints: true,
          getPointsNameText,
          showPointsTypeName: false
        });
      } else {
        return getActivityV2PointValues(element, rounded, getPointsNameText);
      }
    case ACTIVITY_ELEMENT_TYPE.STATUS_UPGRADE:
    case ACTIVITY_ELEMENT_TYPE.SUPPORT_TIER_UPGRADE:
    case ACTIVITY_ELEMENT_TYPE.SUPPORT_TIER_DOWNGRADE:
    case ACTIVITY_ELEMENT_TYPE.TIER_CALC_TIER_DOWNGRADE:
      return element.value.tierName;
    case ACTIVITY_ELEMENT_TYPE.VOUCHER:
    case ACTIVITY_ELEMENT_TYPE.PLAN_CHANGE_VOUCHER: {
      if (!element.value) {
        return "";
      }

      let { text, code } = element.value;
      return getVoucherTextWithCode(text, code);
    }
    case ACTIVITY_ELEMENT_TYPE.EXCLUSIVE_ACCESS:
      return element.value.text;
    case ACTIVITY_ELEMENT_TYPE.REDEMPTION_POINTS:
    //   return getActivitiesPointsText({
    //    points: element.value.points,
    //   pointsType: element.value.pointsType,
    //   showZero: true,
    //  splitLoyaltyPoints: true,
    //  getPointsNameText
    //  });
    case ACTIVITY_ELEMENT_TYPE.REDEMPTION_FREE: {
      const { name, value } = element;
      const code = !!value ? value.code : "";
      return getVoucherTextWithCode(name, code);
    }
    case ACTIVITY_ELEMENT_TYPE.PLAN_CHANGE_POINTS:
      let points = element.value.points;
      if (!points) {
        if (element.value?.pointsInfo?.status?.amount) {
          points = element.value.pointsInfo.status.amount;
        } else if (element.value?.pointsInfo?.pointsToSpend) {
          points = element.value.pointsInfo.pointsToSpend.amount;
        } else points = element.value.pointsInfo.cashback.amount;
      }
      return points;
    case ACTIVITY_ELEMENT_TYPE.CONTACT_STATE:
      return getActivitiesPointsText({
        points: element.value.points ?? element.value.pointsInfo.status.amount,
        pointsType: element.value.pointsType ?? POINTS_TYPE_ENUM.STATUS,
        showZero: true,
        splitLoyaltyPoints: true,
        getPointsNameText,
        showPointsTypeName: false
      });
    case ACTIVITY_ELEMENT_TYPE.GROUP_TIER:
      return element?.value?.tierName;
    case ACTIVITY_ELEMENT_TYPE.GROUP_STATUS_POINTS:
      return getActivitiesPointsText({
        points: element.value.points ?? element.value.pointsInfo[Object.keys(element.value.pointsInfo)[0]].amount,
        pointsType: element.value.pointsType ?? Object.keys(element.value.pointsInfo)[0],
        showZero: true,
        splitLoyaltyPoints: true,
        getPointsNameText,
        withSign: false,
        showPointsTypeName: false
      });
    case ACTIVITY_ELEMENT_TYPE.MIGTARION_POINTS:
      let rewardValueTexts = [];
      activity.elements.forEach((element) => {
        if (activity.version == "v1") {
          const points = rounded ? getRoundedPoints(element.value.points) : element.value.points;
          rewardValueTexts.push(
            getActivitiesPointsText({
              points,
              pointsType: element.value.pointsType,
              showZero: true,
              splitLoyaltyPoints: true,
              getPointsNameText,
              showPointsTypeName: true
            })
          );
        } else {
          rewardValueTexts.push(getActivityV2PointValues(element, rounded, getPointsNameText));
        }
      });
      return rewardValueTexts.join("</br>");
    default:
      return "";
  }
};

export const getActivityElementTooltip = (activity, element) => {
  if (
    activity.description &&
    [
      ACTIVITY_ELEMENT_TYPE.SUPPORT_ADD_POINTS,
      ACTIVITY_ELEMENT_TYPE.SUPPORT_REMOVE_POINTS,
      ACTIVITY_ELEMENT_TYPE.MIGTARION_POINTS
    ].includes(element.type)
  ) {
    return activity.description;
  }

  return "";
};

export const getActivityElementsData = (activity, getPointsNameText, rounded, isSpendPlan, getCashbackNameText) => {
  let data = [];
  const elements = transformSpecialElements(activity, rounded);
  elements.sort((a, b) => {
    const momentA = moment(a.activityTime);
    const momentB = moment(b.activityTime);
    const diff = momentB.diff(momentA);
    const dayDiff = momentB.format("YYYYMMDD") - momentA.format("YYYYMMDD");

    if (dayDiff === 0 && activity.activityType === ACTIVITY_TYPE.ONLINE_PURCHASE) {
      if (b.type === ACTIVITY_ELEMENT_TYPE.PURCHASE_POINTS) {
        return 1;
      }
    }
    return diff;
  });

  for (const [key, element] of Object.entries(elements)) {
    //don't render plan migration element
    if (
      element.type === ACTIVITY_ELEMENT_TYPE.SUPPORT_PLAN_CHANGE ||
      element.type === ACTIVITY_ELEMENT_TYPE.STATUS_UPGRADE_POINTS
    ) {
      continue;
    }

    if (element.type === ACTIVITY_ELEMENT_TYPE.TIER_CALC_REMOVE_POINTS && element?.value?.points === 0) {
      continue;
    }

    if (
      element.type === ACTIVITY_ELEMENT_TYPE.TIER_CALC_REMOVE_POINTS &&
      element?.value?.pointsInfo?.status?.amount === 0
    ) {
      continue;
    }

    if (
      !isGroupActivity(activity) &&
      [ACTIVITY_TYPE.GROUP_CALCULATION, ACTIVITY_TYPE.GROUP_DELETED].includes(activity.activityType) &&
      element.type === ACTIVITY_ELEMENT_TYPE.GROUP_STATE
    ) {
      continue;
    }

    if (element.type === ACTIVITY_ELEMENT_TYPE.MIGTARION_POINTS && key != 0) {
      continue;
    }

    data.push({
      dateFormatUS: moment(element.activityTime).format("MM-DD-YYYY"),
      dateFormatEU: moment(element.activityTime).format("DD.MM.YYYY"),
      timestamp: element.activityTime,
      type: getActivityElementType(activity, element, getPointsNameText),
      nameTooltip: getActivityElementTooltip(activity, element),
      rewardName: getActivityElementRewardName(activity, element, getPointsNameText, isSpendPlan, getCashbackNameText),
      rewardValue: getActivityElementRewardValue(activity, element, getPointsNameText, rounded),
      isCanceled: element.isCanceled,
      isReturned: element.type === ACTIVITY_ELEMENT_TYPE.PURCHASE_POINTS && element.value.points < 0,
      expirationDateText: getActivityElementExpirationText(activity.activityType, element, activity),
      orderId: activity.orderId
    });
  }

  return data;
};

function transformSpecialElements(activity, rounded) {
  //personal activity - points expiration
  if (
    !isGroupActivity(activity) &&
    activity.activityType === ACTIVITY_TYPE.POINTS_EXPIRATION &&
    !activity?.flags?.isFromGroup
  ) {
    return [];
  }

  //group activity - purchase/action
  if (
    [ACTIVITY_TYPE.ONLINE_PURCHASE, ACTIVITY_TYPE.ACTION].includes(activity.activityType) &&
    (isGroupActivity(activity) || activity?.flags?.isFromGroup)
  ) {
    const tempElements = [...activity.elements];
    tempElements.forEach((element) => {
      if ([ACTIVITY_ELEMENT_TYPE.BONUS_POINTS, ACTIVITY_ELEMENT_TYPE.MULTIPLY_POINTS].includes(element.type)) {
        element.type = ACTIVITY_ELEMENT_TYPE.STATUS_POINTS;
      }
    });
    let newElements = [];
    for (let i = 0; i < tempElements.length; i++) {
      const element = tempElements[i];
      if (element.type !== ACTIVITY_ELEMENT_TYPE.STATUS_POINTS) {
        newElements.push(element);
      } else {
        if (
          newElements.some((elem) => elem.type === ACTIVITY_ELEMENT_TYPE.STATUS_POINTS && elem.name === element.name)
        ) {
          continue;
        }
        const totalPoints = tempElements
          .filter((elem1) => elem1.type === ACTIVITY_ELEMENT_TYPE.STATUS_POINTS && elem1.name === element.name)
          .reduce((accumulator, elem2) => {
            let points = elem2.value.points ?? elem2.value.pointsInfo.status.amount;
            if (rounded) points = getRoundedPoints(points);
            return accumulator + points;
          }, 0);

        let cloned = cloneDeep(element);
        cloned.value.points = totalPoints;
        newElements.push(cloned);
      }
    }

    return newElements;
  }

  //personal activity - group calculation (other member joined/left)
  if (
    [ACTIVITY_TYPE.GROUP_CALCULATION, ACTIVITY_TYPE.GROUP_CREATION].includes(activity.activityType) &&
    activity.elements.some(
      (elem) =>
        elem.type === ACTIVITY_ELEMENT_TYPE.CONTACT_STATE &&
        activity.contactId === elem.contactId &&
        [ACTIVITY_ELEMENT_LABEL.NO_CHANGE, ACTIVITY_ELEMENT_LABEL.JOINED].includes(elem?.value?.label)
    )
  ) {
    let newElements = [];
    const element = activity.elements.find((elem) => elem.type === ACTIVITY_ELEMENT_TYPE.GROUP_STATE);
    let tierElement = cloneDeep(element);
    tierElement.type = ACTIVITY_ELEMENT_TYPE.GROUP_TIER;
    let statusPointsElement = cloneDeep(element);
    statusPointsElement.type = ACTIVITY_ELEMENT_TYPE.GROUP_STATUS_POINTS;
    newElements.push(tierElement);
    newElements.push(statusPointsElement);
    return newElements;
  }
  return activity.elements;
}

export const getActivitiesTotalPoints = (activity, rounded) => {
  if (
    activity.activityType === ACTIVITY_TYPE.GROUP_CREATION ||
    activity.activityType === ACTIVITY_TYPE.GROUP_CALCULATION
  ) {
    const element = activity.elements.find((element) =>
      isGroupActivity(activity)
        ? element.type === ACTIVITY_ELEMENT_TYPE.GROUP_STATE
        : element.type === ACTIVITY_ELEMENT_TYPE.CONTACT_STATE
    );

    if (element) {
      if (activity.version == "v1") {
        const { points, pointsType } = { ...element.value };
        return [
          {
            totalPoints: points,
            pointsType
          }
        ];
      } else {
        const pointsType = Object.keys(element.value.pointsInfo)[0];
        const points = element.value.pointsInfo[pointsType].amount;
        return [
          {
            totalPoints: points,
            pointsType
          }
        ];
      }
    } else {
      return [
        {
          totalPoints: null,
          pointsType: null
        }
      ];
    }
  }
  if (activity.version == "v1") {
    if (activity.activityType === ACTIVITY_TYPE.POINTS_MIGRATED) {
      let result = [];
      activity.elements.forEach((element) => {
        result.push({
          totalPoints: element.value.points,
          pointsType: element.value.pointsType
        });
      });

      return result;
    }

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    const pointsElements = activity.elements.filter(
      (element) =>
        !!element.value?.pointsType &&
        element.type !== ACTIVITY_ELEMENT_TYPE.STATUS_UPGRADE_POINTS &&
        //activity.activityType != ACTIVITY_TYPE.POINTS_EXPIRATION &&
        !element.isCanceled
    );

    const pointTypes = pointsElements.map((element) => element.value.pointsType).filter(onlyUnique);
    let result = [];
    pointTypes.forEach((pointsType) => {
      const totalPoints = pointsElements
        .filter((element) => element.value.pointsType == pointsType)
        .reduce((accumulator, element) => {
          let points = 0;

          if (
            !!element.value?.pointsType &&
            !element.isCanceled &&
            element.type !== ACTIVITY_ELEMENT_TYPE.STATUS_UPGRADE_POINTS
          ) {
            points = element.value.points;
          }

          if (rounded) {
            points = getRoundedPoints(points);
          }

          return accumulator + points;
        }, 0);
      result.push({
        totalPoints,
        pointsType
      });
    });
    return result;
  } else {
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    let pointTypes = activity.elements
      .filter((element) => !!element.value.pointsInfo)
      .reduce((accumulator, element) => accumulator.concat(Object.keys(element.value.pointsInfo)), [])
      .filter(onlyUnique);

    let results = [];

    pointTypes.forEach((pointType) => {
      if (activity.activityType === ACTIVITY_TYPE.POINTS_MIGRATED) {
        results.push({
          totalPoints: activity.elements[0].value.pointsInfo[pointType].amount,
          pointsType: pointType
        });
      } else {
        const totalPoints = activity.elements.reduce((accumulator, element) => {
          let points = 0;

          if (
            activity.activityType != ACTIVITY_TYPE.POINTS_EXPIRATION &&
            element.type === ACTIVITY_ELEMENT_TYPE.PURCHASE_POINTS &&
            element?.value?.pointsType === POINTS_TYPE_ENUM.CASHBACK
          ) {
            return accumulator;
          }

          if (
            !!element.value?.pointsInfo &&
            element.value?.pointsInfo[pointType] != undefined &&
            !element.isCanceled &&
            element.type !== ACTIVITY_ELEMENT_TYPE.STATUS_UPGRADE_POINTS
          ) {
            points = element.value.pointsInfo[pointType].amount;
          }

          if (rounded) {
            points = getRoundedPoints(points);
          }

          return accumulator + points;
        }, 0);
        if (totalPoints != 0) {
          results.push({
            totalPoints: totalPoints,
            pointsType: pointType
          });
        }
      }
    });
    return results;
  }
};

export const getActivityTierDetails = (activity) => {
  let tierName,
    tierExp = "";

  const element = activity.elements.find((element) => !!element?.value?.tierName);
  if (element) {
    tierName = element.value.tierName;
    tierExp = element.expirationTime;
  }
  return {
    tierName,
    tierExp
  };
};

export const getActivitiesPointsText = ({
  points,
  pointsType,
  showZero,
  splitLoyaltyPoints,
  getPointsNameText,
  withSign = true,
  showPointsTypeName = true
}) => {
  if (points === 0 && !showZero) {
    return "-";
  }

  let sign = "";
  if (withSign) {
    if (points > 0) {
      sign = "+";
    } else if (points < 0) {
      sign = "-";
    }
  }
  let pointsText = "";
  if (Math.round(points) === points) pointsText = sign + Math.abs(points);
  else pointsText = sign + Math.abs((Math.round(points * 100) / 100).toFixed(2));

  if (splitLoyaltyPoints && pointsType === POINTS_TYPE_ENUM.LOYALTY) {
    return `${pointsText} ${getPointsNameText(
      ACTIVITY_ELEMENT_POINTS_TYPE_TEXT.pointsToSpend,
      points
    )} <br /> ${pointsText} ${getPointsNameText(ACTIVITY_ELEMENT_POINTS_TYPE_TEXT.status, points)}`;
  }
  return `${pointsText} ${getPointsNameText(
    showPointsTypeName ? ACTIVITY_ELEMENT_POINTS_TYPE_TEXT[pointsType] : null,
    points
  )}`;
};

export const getActivityNameTooltip = (activity) => {
  if (activity.reason) {
    return activity.reason;
  }

  // if (activity.activityType === ACTIVITY_TYPE.CUSTOMER_SUPPORT && activity.elements && activity.elements.length > 0) {
  //   return activity.elements[0].value.text;
  // }

  return "";
};

function isGroupActivity(activity) {
  return activity.type === "group_activity";
}

function getRoundedPoints(points) {
  return points > 0 ? Math.floor(points) : Math.ceil(points);
}

function getVoucherTextWithCode(voucherName, code) {
  let text = voucherName;
  if (code) {
    text = `${text} (code: ${code})`;
  }
  return text;
}

function getActivityElementExpirationText(activityType, element, activity) {
  if (activityType === ACTIVITY_TYPE.POINTS_EXPIRATION) {
    return "";
  }

  if (element.type === ACTIVITY_ELEMENT_TYPE.GROUP_TIER) {
    return renderDateTimeText({ timestamp: element.value.tierExpireAt, onlyDate: true }) || EMPTY_CELL_TEXT;
  }

  let onlyDate = true;
  // if (
  //   [
  //     ACTIVITY_ELEMENT_TYPE.REDEMPTION_POINTS,
  //     ACTIVITY_ELEMENT_TYPE.REDEMPTION_FREE,
  //     ACTIVITY_ELEMENT_TYPE.VOUCHER,
  //     ACTIVITY_ELEMENT_TYPE.BONUS_POINTS
  //   ].includes(element.type)
  // ) {
  //   onlyDate = false;
  // }

  if (activityType === ACTIVITY_TYPE.POINTS_MIGRATED) {
    if (activity.version == "v1") {
      let expirationTexts = [];

      let isFirst = true;

      activity.elements.forEach((element) => {
        let type = getElementPointsType(element);
        if (!isFirst) {
          expirationTexts.push(<br />);
        }
        expirationTexts.push(GetValueExpirationByPointType(type, element, onlyDate));
        isFirst = false;
      });
      return <Fragment>{expirationTexts}</Fragment>;
    } else {
      let expirationTexts = [];
      let isFirst = true;
      activity.elements.forEach((element) => {
        const pointTypes = Object.keys(element.value.pointsInfo);
        pointTypes.forEach((pointType) => {
          if (!isFirst) {
            expirationTexts.push(<br />);
          }
          expirationTexts.push(GetValueExpirationByPointType(pointType, element, onlyDate));
          isFirst = false;
        });
      });
      return <Fragment>{expirationTexts}</Fragment>;
    }
  }
  let pointsType = getElementPointsType(element);
  if (!!pointsType) {
    return GetValueExpirationByPointType(pointsType, element, onlyDate);
  }

  return renderDateTimeText({ timestamp: element.expirationTime, onlyDate }) || EMPTY_CELL_TEXT;
}

function GetValueExpirationByPointType(pointsType, element, onlyDate) {
  let spendExpirationDate = element.value.pointsToSpendExpireAt || element.expirationTime;
  let statusExpirationDate = element.value.statusExpireAt;
  let cashbackExpirationDate = element.value.cashbackExpireAt;

  switch (pointsType) {
    case POINTS_TYPE_ENUM.LOYALTY:
      if (!spendExpirationDate && !!element.value?.pointsInfo) {
        spendExpirationDate = element.value.pointsInfo[POINTS_TYPE_ENUM.POINTS_TO_SPEND]?.expirationTime;
      }
      if (!statusExpirationDate && !!element.value?.pointsInfo) {
        statusExpirationDate = element.value.pointsInfo[POINTS_TYPE_ENUM.STATUS]?.expirationTime;
      }
      return (
        <Fragment>
          {renderDateTimeText({ timestamp: spendExpirationDate, onlyDate }) || EMPTY_CELL_TEXT} <br />{" "}
          {renderDateTimeText({ timestamp: statusExpirationDate, onlyDate }) || EMPTY_CELL_TEXT}
        </Fragment>
      );
    case POINTS_TYPE_ENUM.STATUS:
      if (!statusExpirationDate && !!element.value.pointsInfo) {
        statusExpirationDate = element.value.pointsInfo[POINTS_TYPE_ENUM.STATUS]?.expirationTime;
      }
      return renderDateTimeText({ timestamp: statusExpirationDate, onlyDate }) || EMPTY_CELL_TEXT;
    case POINTS_TYPE_ENUM.POINTS_TO_SPEND:
      if (!spendExpirationDate && !!element.value.pointsInfo) {
        spendExpirationDate = element.value.pointsInfo[POINTS_TYPE_ENUM.POINTS_TO_SPEND]?.expirationTime;
      }
      return renderDateTimeText({ timestamp: spendExpirationDate, onlyDate }) || EMPTY_CELL_TEXT;
    case POINTS_TYPE_ENUM.CASHBACK:
      if (!cashbackExpirationDate && !!element.value.pointsInfo) {
        cashbackExpirationDate = element.value.pointsInfo[POINTS_TYPE_ENUM.CASHBACK]?.expirationTime;
      }
      return renderDateTimeText({ timestamp: cashbackExpirationDate, onlyDate }) || EMPTY_CELL_TEXT;
    default:
      return "";
  }
}
