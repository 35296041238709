import React from "react";
import PropTypes from "prop-types";
import sanitizeHtml from "sanitize-html";

import { getErrorMessage } from "../validators";

import { rewardType } from "../types";
import TimeValidityInput from "../components/TimeValidityInput";
import InputNumberField from "../components/InputNumberField";
import RadioButtonList from "../components/RadioButtonList";
import { DateTimeField, DateTimeRangeField } from "../components/DateFields";
import SelectField from "../components/SelectFieldV2";

import rewardService from "../services/rewardService";

import {
  REWARD_HELP_CENTER_URL,
  REWARD_STATUS_ENUM,
  VOUCHER_VALIDITY_TYPE_OPTIONS,
  VALIDITY_TYPE_ENUM,
  REDEMPTION_LIMIT_OPTIONS,
  REDEMPTION_LIMIT_TYPE_ENUM,
  REWARD_CREATE_OR_MODIFY_OPTIONS
} from "../constants/rewardConstants";
import { getMessage } from "../messages";
import Time from "../components/Time";

const RewardSetupValidity = (props) => {
  const {
    reward,
    isPoolValidityDateValid,
    onInputChange,
    onPoolValidityChange,
    onValidityTypeChange,
    updateState,
    errors,
    viewMode,
    rewardStatus
  } = props;

  const minDate =
    rewardStatus === REWARD_STATUS_ENUM.ACTIVE || rewardStatus === REWARD_STATUS_ENUM.EMPTY
      ? new Date(reward.validity.poolValidity)
      : new Date();

  const urlLink = `<a target="_blank" href="${REWARD_HELP_CENTER_URL}">${getMessage(
    "rewardSetupUpload.newUplad.learnMore"
  )}</a>`;

  const renderPoolDateValidity = () => {
    if (!reward.validity.deactivationDate) {
      return null;
    }

    if (!reward.validity.voucherValidity.duration) {
      return null;
    }

    if (reward.validity.voucherValidityType !== VALIDITY_TYPE_ENUM.LIMITED_FROM_EARN) {
      return null;
    }

    let alertText, message, alertClass, alertIcon, className;

    if (isPoolValidityDateValid) {
      className = "e-notice e-notice-withicon e-notice-success";
      alertText = getMessage("rewareSetupValidity.poolDateValidity.valid.alertText");
      let date = (
        <span className="word-wrap">
          <Time value={reward.validity.deactivationDate} onlyDate={true} />
        </span>
      );
      let textWithDate = getMessage(
        "rewareSetupValidity.poolDateValidity.valid.alertMessage",
        { datetext: date },
        false
      );

      message = textWithDate;
      alertClass = "text-color-success";
      alertIcon = "e-check-circle";
    } else {
      className = "e-notice e-notice-withicon e-notice-danger";
      alertText = getMessage("rewareSetupValidity.poolDateValidity.invalid.alertText");
      message = (
        <span
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(getMessage("rewareSetupValidity.poolDateValidity.invalid.alertMessage", { urlLink }))
          }}
        ></span>
      );
      alertClass = "text-color-danger";
      alertIcon = "warning";
    }

    return (
      <div className={className}>
        <span className="e-notice__icon">
          <e-icon icon={alertIcon} />
        </span>
        <div className="e-notice__title">{alertText}</div>
        {message}
      </div>
    );
  };

  const onLimitTypeChange = (event) => {
    const value = event.target.value;
    onInputChange(event);

    if (value === REDEMPTION_LIMIT_TYPE_ENUM.NO_LIMIT) {
      updateState("redemption.limitValue", undefined);
    }
  };

  const getValidityTypeOptions = () => {
    let options = VOUCHER_VALIDITY_TYPE_OPTIONS;
    if (reward.validity.voucherValidityType === VALIDITY_TYPE_ENUM.UNLIMITED) {
      options.forEach((option) => {
        if (option.value === VALIDITY_TYPE_ENUM.UNLIMITED) {
          option.disabled = true;
        }
      });
    } else {
      options = options.filter((option) => option.value !== VALIDITY_TYPE_ENUM.UNLIMITED);
    }

    return options;
  };

  const renderOfferStatusAnbValidity = (masterReward) => {
    let attr = {};
    if (reward.validity && reward.validity.deactivationDate) {
      attr["maxDate"] = new Date(reward.validity.deactivationDate);
    }

    if (reward.validity && reward.validity.poolValidity) {
      attr["maxDate"] = new Date(reward.validity.poolValidity);
    }

    attr["minDate"] = new Date();

    let tooltip = {};
    if (rewardService.isRewardForPoints(masterReward)) {
      tooltip["tooltip"] = getMessage("rewareSetupValidity.limitValue.tooltip");
    }

    return (
      <div>
        <h2>Offer</h2>
        <div>
          {rewardStatus === REWARD_STATUS_ENUM.ACTIVE ? (
            <RadioButtonList
              label={getMessage("rewareSetupValidity.rewardModifyOrNew.label")}
              name="validity.rewardModifyOrNew"
              value={reward.validity.rewardModifyOrNew}
              options={REWARD_CREATE_OR_MODIFY_OPTIONS}
              onChange={onInputChange}
              tooltip={getMessage("rewareSetupValidity.rewardModifyOrNew.tooltip")}
              isHorizontal={true}
              disabled={viewMode}
              isRequired={true}
            />
          ) : (
            <label className="e-field__label e-field__label-required">
              {getMessage("rewareSetupValidity.offerStatusAndValidity.title")}
              <e-tooltip
                content={getMessage("rewareSetupValidity.offerStatusAndValidity.tooltip")}
                type="helper"
              ></e-tooltip>
            </label>
          )}

          <DateTimeRangeField
            label=""
            name="validity.offerValidity"
            value={reward.validity.offerValidity}
            onChange={onInputChange}
            errorMsg={getErrorMessage(errors, "validity.offerValidity")}
            {...attr}
            disabled={viewMode}
          />
        </div>
        {!rewardService.isRewardForFree(masterReward) && (
          <RadioButtonList
            label={getMessage("rewareSetupValidity.redemption.limitType.label")}
            name="redemption.limitType"
            value={reward.redemption.limitType}
            options={REDEMPTION_LIMIT_OPTIONS}
            onChange={onLimitTypeChange}
            tooltip={getMessage("rewareSetupValidity.redemption.limitType.tooltip")}
            disabled={viewMode}
            isRequired={true}
          />
        )}
        {rewardService.isRewardForFree(masterReward) && (
          <label className="e-field__label">
            {getMessage("rewareSetupValidity.offerRestrictions.title")}
            <e-tooltip
              content={
                rewardStatus === REWARD_STATUS_ENUM.ACTIVE
                  ? getMessage("rewareSetupValidity.offerRestrictions.tooltip.rewardActive")
                  : getMessage("rewareSetupValidity.offerRestrictions.tooltip.rewardNotActive")
              }
              type="helper"
            ></e-tooltip>
          </label>
        )}
        {(reward.redemption.limitType === REDEMPTION_LIMIT_TYPE_ENUM.LIMIT_MEMBER ||
          rewardService.isRewardForFree(masterReward)) && (
          <InputNumberField
            label={getMessage("rewareSetupValidity.limitValue.label")}
            type="number"
            name="redemption.limitValue"
            value={reward.redemption.limitValue}
            onChange={onInputChange}
            errorMsg={getErrorMessage(props.errors, "redemption.limitValue")}
            {...tooltip}
            disabled={viewMode}
            isRequired={true}
          />
        )}
      </div>
    );
  };

  return (
    <div className="e-box">
      <div className="e-row">
        <div className="e-col-6">
          <h2>{getMessage("rewareSetupValidity.title")}</h2>

          <DateTimeField
            label={getMessage("rewareSetupValidity.poolValidity.label")}
            name="validity.poolValidity"
            value={reward.validity.poolValidity}
            minDate={minDate}
            onChange={onPoolValidityChange}
            errorMsg={getErrorMessage(props.errors, "validity.poolValidity")}
            tooltipHasAction={true}
            tooltip={sanitizeHtml(getMessage("rewareSetupValidity.poolValidity.tooltip", { urlLink }))}
            disabled={viewMode}
            isRequired={true}
          />

          <SelectField
            label={getMessage("rewareSetupValidity.voucherValidityType.label")}
            name="validity.voucherValidityType"
            value={reward.validity.voucherValidityType}
            options={getValidityTypeOptions()}
            onChange={onValidityTypeChange}
            tooltip={getMessage("rewareSetupValidity.voucherValidityType.tooltip")}
            disabled={viewMode}
            isRequired={true}
          />

          {reward.validity.voucherValidityType === VALIDITY_TYPE_ENUM.LIMITED_FROM_EARN && (
            <TimeValidityInput
              label={getMessage("rewareSetupValidity.voucherValidity.label")}
              name="validity.voucherValidity"
              validity={reward.validity.voucherValidity}
              onChange={onPoolValidityChange}
              changeStateFunc={updateState}
              errorMsg={
                !reward.validity.voucherValidity.disabled
                  ? getErrorMessage(errors, "validity.voucherValidity.duration")
                  : null
              }
              tooltip={getMessage("rewareSetupValidity.voucherValidity.tooltip")}
              disabled={viewMode}
              isRequired={true}
            />
          )}

          {reward.validity.voucherValidityType === VALIDITY_TYPE_ENUM.FIXED_DATE && (
            <DateTimeField
              label={getMessage("rewareSetupValidity.voucherExpiryDate.label")}
              name="validity.voucherExpiryDate"
              value={reward.validity.voucherExpiryDate}
              minDate={new Date()}
              onChange={onInputChange}
              errorMsg={getErrorMessage(errors, "validity.voucherExpiryDate")}
              disabled={viewMode}
              isRequired={true}
            />
          )}

          <div>{renderPoolDateValidity()}</div>
        </div>
        <div className="e-col-6">
          {!rewardService.isCashback(reward.masterReward) &&
            (!rewardService.isVoucherForRewards(reward.masterReward) ||
              rewardService.isRewardConnectedToTier(reward)) &&
            reward.validity.poolValidity &&
            renderOfferStatusAnbValidity(reward.masterReward)}
        </div>
        <div className="e-col e-col__separator" />
      </div>
    </div>
  );
};

RewardSetupValidity.propTypes = {
  reward: rewardType.isRequired,
  isPoolValidityDateValid: PropTypes.bool,
  isPoolValidityAmountValid: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  onPoolValidityChange: PropTypes.func.isRequired,
  onValidityTypeChange: PropTypes.func.isRequired,
  onShowDefaultTextMultiLang: PropTypes.func.isRequired,
  updateState: PropTypes.func.isRequired,
  errors: PropTypes.object,
  viewMode: PropTypes.bool,
  rewardStatus: PropTypes.string
};

export default RewardSetupValidity;
