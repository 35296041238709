import { getMessage } from "../messages";

export const SECURITY_PAGE_TYPE = {
  API_KEY: "apiKey",
  WALLET_APP: "walletApp"
};

export const ACTIVATE_API_OPTIONS = [
  {
    value: true,
    name: getMessage("securitySettings.apiKey.setup.activation.options.activate")
  },
  {
    value: false,
    name: getMessage("securitySettings.apiKey.setup.activation.options.deactivate")
  }
];

export const ACTIVATE_WALLET_OPTIONS = [
  {
    value: true,
    name: getMessage("securitySettings.walletApp.setup.activation.options.activate")
  },
  {
    value: false,
    name: getMessage("securitySettings.walletApp.setup.activation.options.deactivate")
  }
];

export const WALLET_APP_DOCUMENTATION_URL =
  "https://dev.emarsys.com/docs/loyalty-api-v4/h6q96eed79ly4-installing-the-loyalty-wallet";

export const API_KEY_DOCUMENTATION_URL =
  "https://dev.emarsys.com/docs/loyalty-api-v4/f2nl283kym2jf-loyalty-contact-api";

export const SECURITY_HELP_URL =
  "https://help.emarsys.com/hc/en-us/articles/4607666564625-Implementation-Installing-and-testing-the-Loyalty-Wallet-and-the-API";
