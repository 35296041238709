import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { SPECIAL_TIER_ENUM, REWARD_TOKEN } from "../../constants/actionConstants";
import { EnvelopeSvg, FacebookSvg, WhatsappSvg, TwitterSvg } from "../../components/ActionSVGs";

import { selectRewardById } from "../../reducers";

import { getGeneralIconsUrl } from "../../helpers/envHelper";
import { getIconName } from "../../helpers/iconHelper";

import { actionType } from "../../types";
import "../../assets/styles/ReferralContentReferrerPreview.scss";

const ReferralContentBase = ({ children }) => {
  return (
    <div className="l-device_no-content text-align-center">
      <e-device>
        <e-device-push>
          {" "}
          <div className="referral-content-preview">{children}</div>
        </e-device-push>
      </e-device>
    </div>
  );
};

function getFieldValueFunc({ contentObj, selectedLang, primaryLang }) {
  return (name) => {
    if (selectedLang && selectedLang !== primaryLang) {
      return contentObj[`${name}ML`] && contentObj[`${name}ML`][selectedLang]
        ? contentObj[`${name}ML`][selectedLang]
        : contentObj[name];
    }
    return contentObj[name];
  };
}

function getRefereeRewardsText(action, selectedLang) {
  const guestRewardTier = action.reward.value[SPECIAL_TIER_ENUM.GUEST];
  return guestRewardTier.rewardsTextML ? guestRewardTier.rewardsTextML[selectedLang] : guestRewardTier.rewardsText;
}

function getReward(action) {
  return useSelector((state) => selectRewardById(state, action.reward.value[SPECIAL_TIER_ENUM.GUEST].rewardPool));
}

export const ReferralContentReferrerPreview = ({ action, selectedLang, primaryLang }) => {
  const getFieldValue = getFieldValueFunc({ contentObj: action.referralContent.referrer, selectedLang, primaryLang });
  const [referrerRewardsText, setReferrerRewardsText] = useState("");
  const { sharingChannels } = { ...action.referralContent.referrer };

  useEffect(() => {
    let text = "";
    let tierWithRewards = action.reward.value[SPECIAL_TIER_ENUM.ALL];

    if (!tierWithRewards.rewardsText) {
      const tierWithRewardsId = Object.keys(action.reward.value).find(
        (tierId) => !!action.reward.value[tierId].rewardsText
      );
      tierWithRewards = action.reward.value[tierWithRewardsId];
    }

    if (tierWithRewards) {
      text = tierWithRewards?.rewardsTextML ? tierWithRewards.rewardsTextML[selectedLang] : tierWithRewards.rewardsText;
    }

    setReferrerRewardsText(text);
  }, [selectedLang]);

  return (
    <ReferralContentBase>
      <div className="m-t-60">
        <div className="text-size-title">{getFieldValue("title")}</div>
        <div className="text-size-headline m-t-20">{getRefereeRewardsText(action, selectedLang)}</div>
        <div className="multi-line color-grey text-align-center m-t-20">
          {getFieldValue("text") ? getFieldValue("text").replace(REWARD_TOKEN.token, referrerRewardsText) : ""}
        </div>
      </div>
      <div className="m-t-100 m-b-25">
        <div className="color-grey">{getFieldValue("subtitle")}</div>
        <div className="link-copy-area m-t-10">
          <div className="link-copy-url">{getFieldValue("landingPageUrl")}</div>
          <div className="link-copy-button">Copy</div>
        </div>
      </div>
      <div className="sharing-channels">
        {sharingChannels.showEmail && <EnvelopeSvg width={25} height={25} />}
        {sharingChannels.showFacebook && <FacebookSvg width={16} height={25} />}
        {sharingChannels.showWhatsapp && <WhatsappSvg width={25} height={25} />}
        {sharingChannels.showTwitter && <TwitterSvg width={25} height={25} />}
      </div>
      <div className="color-grey m-t-75">
        <div>{getFieldValue("consentText")}</div>
        <div className="consent-link">{getFieldValue("consentLinkText")}</div>
      </div>
    </ReferralContentBase>
  );
};

export const ReferralContentRefereePreview = ({ action, selectedLang, primaryLang }) => {
  const getFieldValue = getFieldValueFunc({ contentObj: action.referralContent.referee, selectedLang, primaryLang });
  const reward = getReward(action);

  return (
    <ReferralContentBase>
      <div className="m-t-20 m-b-20">
        <div className="text-size-headline">{getFieldValue("title")}</div>
        <div className="m-t-10">{getFieldValue("text")}</div>
      </div>
      <hr class="e-separator e-separator-fullwidth"></hr>
      <div>
        <div className="voucher-icon">
          {reward?.icon ? <img src={`${getGeneralIconsUrl()}/${getIconName(reward.icon.fileName, ".png")}`} /> : null}
        </div>
        <div className="text-size-headline font-weight-normal m-t-15">
          {getRefereeRewardsText(action, selectedLang)}
        </div>
      </div>
      <div className="m-t-30 p-10">
        <div>{getFieldValue("secondaryTitle")}</div>
        <input
          className="referral-input-preview color-grey m-t-5"
          readOnly={true}
          value={getFieldValue("inputFieldPlaceholder")}
        ></input>
        <div className="color-grey small-text multi-line text-align-center m-t-10">
          {getFieldValue("secondaryText")}
        </div>
        <div className="small-text color-grey m-t-30">
          {(getFieldValue("consentText") || getFieldValue("consentLinkText")) && (
            <div className="referral-checkbox-preview">
              <input type="checkbox" readOnly={true} />
              <label>
                {getFieldValue("consentText")}&nbsp;
                <span className="consent-link">{getFieldValue("consentLinkText")}</span>
              </label>
            </div>
          )}
          {getFieldValue("secondaryConsentEnabled") &&
            (getFieldValue("secondaryConsentText") || getFieldValue("secondaryConsentLinkText")) && (
              <div className="referral-checkbox-preview m-t-5">
                <input type="checkbox" readOnly={true} />
                <label>
                  {getFieldValue("secondaryConsentText")}&nbsp;
                  <span className="consent-link">{getFieldValue("secondaryConsentLinkText")}</span>
                </label>
              </div>
            )}
        </div>
        <div className="referral-input-preview m-t-30">{getFieldValue("ctaText")}</div>
      </div>
    </ReferralContentBase>
  );
};

const requiredPropTypes = {
  action: actionType.isRequired,
  errors: PropTypes.object,
  onInputChange: PropTypes.func,
  selectedLang: PropTypes.string,
  primaryLang: PropTypes.string
};

ReferralContentReferrerPreview.propTypes = requiredPropTypes;
ReferralContentRefereePreview.propTypes = requiredPropTypes;
