import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import MultiPlanSelect from "../components/MultiPlanSelect";
import { getMessage } from "../messages";

const SubmitFormHeader = (props) => {
  let submitAttr = {};
  if (props.disableSubmitButton) {
    submitAttr["disabled"] = true;
  }

  return (
    <header className="e-layout__header">
      <h1 className="e-layout__title">{props.title}</h1>
      <div className="e-layout__actions">
        {props.showMultiPlanSelect && <MultiPlanSelect />}
        {props.hideSubmitButton ? null : (
          <button type="submit" className="e-layout__action e-btn e-btn-primary" {...submitAttr}>
            {getMessage("saveBtn.label")}
          </button>
        )}

        {props.overviewLink ? (
          <NavLink to={props.overviewLink} className="e-layout__action e-btn e-btn-link">
            {getMessage("toOverviewBtn.label")}
          </NavLink>
        ) : null}
      </div>
      {props.alertElement ? <div className="l-alert-container">{props.alertElement}</div> : null}
    </header>
  );
};

SubmitFormHeader.propTypes = {
  title: PropTypes.string,
  overviewLink: PropTypes.string,
  alertElement: PropTypes.element,
  disableSubmitButton: PropTypes.bool,
  hideSubmitButton: PropTypes.bool,
  showMultiPlanSelect: PropTypes.bool
};

export default SubmitFormHeader;
