import * as TYPES from "./TYPES";
import rewardApi from "../api/rewardApi";
import { invalidateJwt } from "./sessionActions";

export const getAllRewards = () => {
  return (dispatch) => {
    dispatch({
      type: TYPES.GET_ALL_REWARDS_REQUEST
    });
    return rewardApi
      .getAllRewards()
      .then((data) => {
        dispatch({
          type: TYPES.GET_ALL_REWARDS_SUCCESS,
          payload: data
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.GET_ALL_REWARDS_FAILURE
        });
      });
  };
};

export const updateReward = (reward, id, disableAlert) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.POST_REWARD_REQUEST
    });

    return rewardApi
      .updateReward(reward, id)
      .then((data) => {
        dispatch({
          type: TYPES.POST_REWARD_SUCCESS,
          payload: {
            data,
            isNew: false,
            disableAlert,
            id
          }
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.POST_REWARD_FAILURE,
          payload: {
            isNew: false
          }
        });
      });
  };
};

export const createRewardFromExisting = (reward, id, disableAlert) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.POST_REWARD_REQUEST
    });

    return rewardApi
      .createNewFromExistingReward(reward, id)
      .then((data) => {
        dispatch({
          type: TYPES.SWAP_REWARD_SUCCESS,
          payload: {
            data,
            isNew: false,
            disableAlert,
            id
          }
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.POST_REWARD_FAILURE,
          payload: {
            isNew: false
          }
        });
      });
  };
};

export const createReward = (reward, disableAlert) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.POST_REWARD_REQUEST
    });

    return rewardApi
      .createReward(reward)
      .then((data) => {
        dispatch({
          type: TYPES.POST_REWARD_SUCCESS,
          payload: {
            data,
            isNew: true,
            disableAlert
          }
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.POST_REWARD_FAILURE,
          payload: {
            isNew: true
          }
        });
      });
  };
};

export const deleteReward = (id) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.DELETE_REWARD_REQUEST
    });

    return rewardApi
      .deleteReward(id)
      .then(() =>
        dispatch({
          type: TYPES.DELETE_REWARD_SUCCESS,
          payload: {
            id
          }
        })
      )
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.DELETE_REWARD_FAILURE
        });
      });
  };
};

export const addUploadToRewardPool = (rewardId, upload) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.ADD_UPLOAD_TO_REWARD_POOL_REQUEST
    });

    return rewardApi
      .addUploadToRewardPool(rewardId, upload)
      .then((reward) => {
        dispatch({
          type: TYPES.ADD_UPLOAD_TO_REWARD_POOL_SUCCESS,
          payload: {
            data: reward,
            id: rewardId
          }
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.ADD_UPLOAD_TO_REWARD_POOL_FAILURE
        });
      });
  };
};

export const deleteRewardPool = (id) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.DELETE_REWARD_POOL_REQUEST
    });

    return rewardApi
      .deleteRewardPool(id)
      .then(() =>
        dispatch({
          type: TYPES.DELETE_REWARD_POOL_SUCCESS,
          payload: {
            id
          }
        })
      )
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.DELETE_REWARD_POOL_FAILURE
        });
      });
  };
};

export const setRewardEditState = (reward, stepId) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.SET_REWARD_EDIT_STATE,
      payload: {
        reward,
        stepId
      }
    });
  };
};

export const resetRewardEditState = () => {
  return function (dispatch) {
    dispatch({
      type: TYPES.RESET_REWARD_EDIT_STATE
    });
  };
};

export const resetNewRewardId = () => {
  return function (dispatch) {
    dispatch({
      type: TYPES.RESET_NEW_REWARD_ID
    });
  };
};
