import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ColorPickerField from "../../../components/ColorPickerField";
import InputNumberField from "../../../components/InputNumberField";
import InputField from "../../../components/InputField";
import Notification from "../../../components/Notification";

import { PICKER_TYPE } from "../../../constants/rewardConstants";

import "../../../assets/styles/ExclusiveAccessCustomTemplateEditor.scss";
import { getMessage } from "../../../messages";

export const CLOSE_BUTTON_POSITION_OPTIONS = [
  {
    name: "TopRight",
    value: getMessage("stylePickerBar.closeButtonPoitionOptions.topRight")
  },
  {
    name: "TopLeft",
    value: getMessage("stylePickerBar.closeButtonPoitionOptions.topLeft")
  }
];

const StylePickerElement = ({ children, pickerId, disabled, onClear }) => {
  const anchorClassName = classNames("e-btn e-btn-onlyicon", { "e-btn-disabled": disabled });
  return (
    <div className="style-editor-element">
      {children}
      {!disabled && (
        <a
          className={anchorClassName}
          onClick={() => {
            onClear(pickerId);
          }}
        >
          <e-icon icon="reset-block" />
        </a>
      )}
    </div>
  );
};

const StylePickerBar = ({
  isGeneral,
  isMember,
  isNonMember,
  pickers,
  disabled,
  onShowLinkDialog,
  onColorChange,
  onInputChange,
  onClear,
  onHighlightPicker
}) => {
  let notificationContent = "";
  if (isMember) notificationContent = getMessage("stylePickerBar.notificationContent.Member");
  if (isNonMember) notificationContent = getMessage("stylePickerBar.notificationContent.nonMember");

  const onMouseEnter = (name) => {
    onHighlightPicker(name);
  };

  const onMouseLeave = () => {
    onHighlightPicker();
  };

  return (
    <div className="color-picker-container">
      {!isGeneral && <Notification content={notificationContent} isNeutral />}
      {Object.entries(pickers).map(([key, val]) => {
        const { type, label, name, value, pickerKey } = val;
        return (
          <React.Fragment key={key}>
            {type === PICKER_TYPE.COLOR && (
              <StylePickerElement pickerId={key} disabled={disabled} onClear={onClear}>
                {!disabled && (
                  <ColorPickerField
                    className="picker"
                    label={label}
                    name={name}
                    id={key}
                    pickerKey="exclusive_access_editor"
                    value={value}
                    disabled={disabled}
                    onChange={onColorChange}
                    onMouseEnter={() => onMouseEnter(name)}
                    onMouseLeave={onMouseLeave}
                  />
                )}
                {disabled && <InputField name={name} label={label} value={value} disabled={disabled} />}
              </StylePickerElement>
            )}
            {type === PICKER_TYPE.NUM_INPUT && (
              <StylePickerElement pickerId={key} disabled={disabled} onClear={onClear}>
                <InputNumberField
                  className="picker"
                  label={label}
                  type="number"
                  name={name}
                  id={key}
                  value={value}
                  disabled={disabled}
                  onChange={onInputChange}
                  onMouseEnter={() => onMouseEnter(name)}
                  onMouseLeave={onMouseLeave}
                />
              </StylePickerElement>
            )}
            {type === PICKER_TYPE.LINK_DIALOG && (
              <StylePickerElement pickerId={key} disabled={disabled} onClear={onClear}>
                <InputField
                  name={name}
                  label={label}
                  value={value}
                  readOnly={true}
                  disabled={disabled}
                  onClick={onShowLinkDialog}
                  onMouseEnter={() => onMouseEnter(name)}
                  onMouseLeave={onMouseLeave}
                  prefixIconName="link"
                  addPadding={true}
                />
              </StylePickerElement>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

StylePickerBar.propTypes = {
  isGeneral: PropTypes.bool,
  isMember: PropTypes.bool,
  isNonMember: PropTypes.bool,
  pickers: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onShowLinkDialog: PropTypes.func.isRequired,
  onColorChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onHighlightPicker: PropTypes.func.isRequired
};

export default StylePickerBar;
