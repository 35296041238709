import React, { Component } from "react";
import PropTypes from "prop-types";

class ColorPickerField extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.pickerElm.setAttribute("key", this.props.pickerKey);

    this.pickerInputElm.addEventListener("click", (event) => {
      if (this.pickerElm.isOpened) {
        this.pickerElm.close();
      } else {
        this.pickerElm.open(this.pickerInputElm, this.pickerInputElm.value);
      }
    });

    this.pickerElm.addEventListener("apply", (event) => {
      const value = event.detail.value;
      this.props.onChange(event, value);
    });
  }
  render() {
    const { label, name, id, value, onMouseEnter, onMouseLeave } = this.props;
    return (
      <div className="e-field">
        <label className="e-field__label">{label}</label>
        <e-colorinput
          id={`${id}_input`}
          name={name}
          value={value}
          readonly
          ref={(elem) => (this.pickerInputElm = elem)}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
        <e-colorpicker id={id} name={name} ref={(elem) => (this.pickerElm = elem)} />
      </div>
    );
  }
}

ColorPickerField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  pickerKey: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default ColorPickerField;
