import axios from "axios";

export default class sessionApi {
  static async getJwt(url) {
    const params = {
      iid: `${process.env.REACT_APP_IFRAME_HANDSHAKE_CLIENT_ID}`,
      url
    };

    const requestUrl = `${process.env.REACT_APP_LOYALTY_API}/iframe-handshake`;

    const response = await axios.get(requestUrl, { params });

    const { jwtToken } = response.data;

    return jwtToken;
  }
}
