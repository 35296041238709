import React, { Fragment } from "react";
import PropTypes from "prop-types";

import InputField from "../../components/InputField";
import SelectField from "../../components/SelectFieldV2";

import { actionType } from "../../types";
import { getMessage } from "../../messages";

const notificationLimitsOfUseOptions = [
  "Only once ever",
  "Once a day",
  "Once a week",
  "Every two weeks",
  "Once a month",
  "On every relevant page load"
];

const ActionSetupRealtimeNotification = (props) => {
  const { action, onInputChange, viewMode } = props;

  return (
    <Fragment>
      <div className="e-row">
        <div className="e-col-12">
          {/* Block Message */}
          <InputField
            label={getMessage("actionSetupRealtimeNotification.content.notification.url.label")}
            type="text"
            name="content.notification.url"
            value={action.content.notification.url}
            maxLength="255"
            onChange={onInputChange.bind(this)}
            tooltip={getMessage("actionSetupRealtimeNotification.content.notification.url.tooltip")}
            disabled={viewMode}
          />

          {/* Limits of Use */}
          <SelectField
            label={getMessage("actionSetupRealtimeNotification.content.notification.limitOfUse.label")}
            name="content.notification.limitsOfUse"
            value={action.content.notification.limitsOfUse || notificationLimitsOfUseOptions[2]}
            options={notificationLimitsOfUseOptions}
            onChange={onInputChange.bind(this)}
            tooltip={getMessage("actionSetupRealtimeNotification.content.notification.limitOfUse.tooltip")}
            disabled={viewMode}
          />
        </div>
      </div>
    </Fragment>
  );
};

ActionSetupRealtimeNotification.propTypes = {
  action: actionType.isRequired,
  onInputChange: PropTypes.func.isRequired,
  viewMode: PropTypes.bool
};

export default ActionSetupRealtimeNotification;
