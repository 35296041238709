export const actionsDeLegacy = {
  "actions.notification.purchase":
    "Um den Kaufvorgang zu aktivieren, {helpLink} die Aktion zu Ihren Kontakten {learnMoreLink}.",
  "actions.notification.engagement":
    "Um den Kaufvorgang zu aktivieren, {helpLink} die Aktion zu Ihren Kontakten {learnMoreLink}.",
  "actions.notification.join":
    "Die Standardbeitrittsaktion arbeitet automatisch, nachdem sie aktiviert und der Plan gestartet wurde.  Zusätzliche Beitrittsaktionen  müssen {helpLink}{learnMoreLink}.",
  "actionSetup.alert.activeMustDeactivate.deactivateText": "deaktivieren",
  "actionSetupContentCreation.editSide.title.tooltip.default":
    "Eine Standardnachricht ersetzt die ursprünglichen Nachrichten-Token, wenn System- oder Netzwerkfehler vorliegen. Die festgelegten Token {message} {cta text} {cta url}  werden im  Smart Block und der Loyalty Wallet angezeigt.",
  "actionSetupContentCreation.editSide.title.tooltip.join":
    "Die festgelegten Token {message} {cta text} {cta url}  werden im  Smart Block und der Loyalty Wallet angezeigt. Die Standardnachricht wird angezeigt, wenn keine definierte festgelegte Beitrittsnachricht vorliegt.",
  "actionSetupContentCreation.editSide.title.tooltip.other":
    "Die festgelegten Token {message}{cta text}{cta url} erscheinen im Smart Block und der Loyalty Wallet. Sie können diese Token einzeln oder mit dem Content-Block im Kampagneneditor verwenden.",
  "actionSetupContentCreation.previewSideTitleTooltip.tokens":
    "Beispiel wie {loyalty tokens} im E-Mail-Content-Block angezeigt wird. Das eigentliche Design kann je nach E-Mail-Design variieren.",
  "actionSetupLoyaltyMessagePreviewEmail.notification.tokens":
    "Beispiel wie {loyalty tokens} im E-Mail-Content-Block angezeigt wird. Das tatsächliche Design kann je nach E-Mail-Design variieren.",
  "actionSetupRewards.pointsType.popup.message":
    'Wenn Sie die Punktart ändern, wird die Option mehrere Belohnungen entfernt, denn mehrere Belohnungen für Stufen {tiers} ist mit der von Ihnen gewählten Punktart nicht kompatibel! Wenn Sie mehrere Punkte vergeben möchten, können Sie die Punktart ändern und erneut "Mehrere Punkte" auswählen.',
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.zero": "{day}e(r)",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.one": "{day}e(r)",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.two": "{day}e(r)",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.few": "{day}e(r)",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.many": "{day}e(r)",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.other": "{day}e(r)",
  "actionSetupScheduling.recurring.summary.week.name.1": "Erste(r)",
  "actionSetupScheduling.recurring.summary.week.name.2": "Zweite(r)",
  "actionSetupScheduling.recurring.summary.week.name.3": "Dritte(r)",
  "actionSetupScheduling.recurring.summary.week.name.4": "Vierte(r)",
  "actionSetupScheduling.recurring.summary.week.name.5": "Letzte(r)"
};
