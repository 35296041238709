import * as TYPES from "../actions/TYPES";
import { arrayToObject } from "../helpers/objectHelper";

const initialState = {
  rewardsById: {},
  loading: false,
  dataLoaded: false,
  postSuccess: false,
  newRewardId: null,
  rewardEditState: {
    reward: null,
    stepId: null
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.POST_REWARD_REQUEST: {
      return {
        ...state,
        loading: true,
        postSuccess: false
      };
    }

    case TYPES.DELETE_REWARD_REQUEST:
    case TYPES.ADD_UPLOAD_TO_REWARD_POOL_REQUEST:
    case TYPES.DELETE_REWARD_POOL_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case TYPES.GET_ALL_REWARDS_REQUEST: {
      return {
        ...state,
        loading: true,
        dataLoaded: false
      };
    }

    case TYPES.GET_ALL_REWARDS_SUCCESS: {
      const rewardsData = action.payload == null ? [] : action.payload;
      const rewardsById = arrayToObject(rewardsData);

      return {
        ...state,
        rewardsById,
        loading: false,
        dataLoaded: true
      };
    }

    case TYPES.POST_REWARD_SUCCESS: {
      const rewardToSet = action.payload.data;

      const { rewardsById } = { ...state };
      rewardsById[rewardToSet.id] = rewardToSet;

      let result = {
        ...state,
        rewardsById,
        loading: false,
        postSuccess: true
      };

      if (action.payload.isNew) {
        result.newRewardId = rewardToSet.id;
      }

      return result;
    }

    case TYPES.SWAP_REWARD_SUCCESS: {
      const rewardToSet = action.payload.data;
      const rewardId = action.payload.id;

      const { rewardsById } = { ...state };
      rewardsById[rewardToSet.id] = rewardToSet;
      delete rewardsById[rewardId];

      let result = {
        ...state,
        rewardsById,
        loading: false,
        postSuccess: true
      };

      return result;
    }
    case TYPES.DELETE_REWARD_SUCCESS: {
      const rewardId = action.payload.id;
      const { rewardsById } = { ...state };
      delete rewardsById[rewardId];

      return {
        ...state,
        rewardsById,
        loading: false
      };
    }

    case TYPES.ADD_UPLOAD_TO_REWARD_POOL_SUCCESS: {
      const rewardPool = action.payload.data;
      const rewardId = action.payload.id;

      const { rewardsById } = { ...state };
      rewardsById[rewardId].pool = rewardPool;

      return {
        ...state,
        rewardsById,
        loading: false
      };
    }

    case TYPES.DELETE_REWARD_POOL_SUCCESS: {
      const rewardId = action.payload.id;
      const { rewardsById } = { ...state };

      rewardsById[rewardId].pool = {
        uploads: []
      };

      return {
        ...state,
        rewardsById,
        loading: false
      };
    }

    case TYPES.GET_ALL_REWARDS_FAILURE:
    case TYPES.POST_REWARD_FAILURE:
    case TYPES.DELETE_REWARD_FAILURE:
    case TYPES.ADD_UPLOAD_TO_REWARD_POOL_FAILURE:
    case TYPES.DELETE_REWARD_POOL_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case TYPES.SET_REWARD_EDIT_STATE: {
      return {
        ...state,
        rewardEditState: {
          reward: { ...action.payload.reward },
          stepId: action.payload.stepId
        }
      };
    }

    case TYPES.RESET_REWARD_EDIT_STATE: {
      return {
        ...state,
        rewardEditState: { ...initialState.rewardEditState }
      };
    }

    case TYPES.RESET_NEW_REWARD_ID: {
      return {
        ...state,
        newRewardId: null
      };
    }

    default:
      return state;
  }
}

export const selectRewards = (state) => {
  const { rewardsById } = { ...state };
  const rewards = Object.keys(rewardsById).map((id) => rewardsById[id]);
  return rewards;
};

export const selectRewardNames = (state) => {
  const { rewardsById } = { ...state };
  return Object.keys(rewardsById).map((id) => rewardsById[id].name);
};

export const selectRewardsById = (state) => {
  const { rewardsById } = { ...state };
  return rewardsById;
};

export const selectRewardById = (state, id) => {
  const { rewardsById } = { ...state };
  return rewardsById[id];
};

export const selectRewardEditState = (state) => {
  return { ...state.rewardEditState };
};

export const selectNewRewardId = (state) => {
  return state.newRewardId;
};

export const isDataLoaded = (state) => {
  return state.dataLoaded;
};

export const isLoading = (state) => {
  return state.loading;
};

export const isPostSuccess = (state) => {
  return state.postSuccess;
};
