import React, { Component, Fragment } from "react";
import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";

import PopupContainer from "../components/PopupContainer";
import InputField from "../components/InputField";
import SelectField from "../components/SelectFieldV2";
import Notification from "../components/Notification";
import MultiLangPopup from "../components/MultiLangPopup";
import MultiLangPopupOpener from "../components/MultiLangPopupOpener";
import MultiMarketSelect from "../components/MultiMarketSelect";

import BenefitSetupVoucherFields from "./TierFixedBenefitSetup/BenefitSetupVoucherFields";
import BenefitSetupEventFields from "./TierFixedBenefitSetup/BenefitSetupEventFields";
import BenefitSetupCustomFields from "./TierFixedBenefitSetup/BenefitSetupCustomFields";
import BenefitSetupExclusiveAccessFields from "./TierFixedBenefitSetup/BenefitSetupExclusiveAccessFields";
import MultiLangBenefitFields from "./TierFixedBenefitSetup/MultiLangBenefitFields";

import { updateStateOnInputChange, getValueByType } from "../helpers/formHelper";
import { updateObjectByNameAndValue, filterObjectByKeys } from "../helpers/objectHelper";
import { setTranslationsOnInputChange, multiLangUpdateState, initTranslationsObj } from "../helpers/multiLangHelper";

import rewardService from "../services/rewardService";

import { getErrorMessage, validateSingleValue } from "../validators";
import benefitValidator from "../validators/benefitValidator";

import {
  FIXED_BENEFIT_TYPE_OPTIONS,
  ADDITIONAL_BENEFIT_TYPE_OPTIONS,
  BENEFIT_TYPE_ENUM,
  MULTI_LANG_BENEFITS_PROPS_MAP
} from "../constants/tierConstants";

import { EXCLUSIVE_ACCESS_OFFERS_ENUM } from "../constants/exclusiveAccessConstants";
import "../assets/styles/TierSetupFixedBenefits.scss";

import { benefitType } from "../types";
import exclusiveAccessService from "../services/exclusiveAccessService";
import { getMessage } from "../messages";

class BenefitSetup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      benefit: {},
      errors: {},
      showMultiLangPopup: false,
      translations: {}
    };

    this.validator = new benefitValidator(props.hasMultiMarket, props.isAdditionalBenefit);
  }

  componentDidUpdate(prevProps) {
    if (this.props.isShow && !prevProps.isShow) {
      this.setState({
        benefit: cloneDeep(this.props.benefit),
        errors: undefined
      });
    }
  }

  getBenefitTypeOptions() {
    let options = this.props.isAdditionalBenefit ? ADDITIONAL_BENEFIT_TYPE_OPTIONS : FIXED_BENEFIT_TYPE_OPTIONS;

    return options;
  }

  validate(benefit) {
    return this.validator.validateBenefit(benefit, benefit.type);
  }

  updateState(propertyPath, value) {
    const benefit = { ...this.state.benefit };
    updateObjectByNameAndValue(benefit, propertyPath, value);
  }

  /* updating the current benefit state */
  onBenefitInputChange(event, customValue) {
    const benefit = { ...this.state.benefit };

    const inputName = updateStateOnInputChange({ stateObj: benefit, event, customValue });

    const errors = validateSingleValue(() => this.validate(benefit), this.state.errors, inputName);

    this.setState({
      benefit,
      errors
    });

    return benefit;
  }

  onRewardCheckboxChange(event, tierId) {
    const benefit = this.onBenefitInputChange(event);
    let errors = validateSingleValue(() => this.validate(benefit), this.state.errors, "tiers");

    if (!benefit.tiers[tierId].enabled) {
      const inputNameToValidate = `tiers.${tierId}.rewardId`;
      errors = validateSingleValue(() => this.validate(benefit), this.state.errors, inputNameToValidate);
    }

    this.setState({
      errors
    });
  }

  onCustomCheckboxChange(event) {
    const benefit = this.onBenefitInputChange(event);
    let errors = validateSingleValue(() => this.validate(benefit), this.state.errors, "tiers");
    this.setState({
      errors
    });
  }

  onMarketsChange(event, customValue) {
    const benefitType = this.state.benefit?.type;

    if (benefitType === BENEFIT_TYPE_ENUM.EXCLUSIVE_ACCESS) {
      const value = getValueByType(event.target);

      if (value) {
        customValue = [];
        customValue.push(value);
      }
    }

    let benefit = this.onBenefitInputChange(event, customValue);

    //clear select box because exclusive access is configured to a single market
    if (benefitType === BENEFIT_TYPE_ENUM.EXCLUSIVE_ACCESS) {
      Object.keys(benefit.tiers).forEach((tierId) => {
        benefit.tiers[tierId] = { enabled: false };
      });
    }

    let errors = validateSingleValue(() => this.validate(benefit), this.state.errors, "targetMarkets");

    //clean tiers section errors
    if (errors) {
      delete errors["tiers"];
      Object.keys(errors).forEach((key) => {
        if (key.indexOf("tiers.") === 0) {
          delete errors[key];
        }
      });
    }

    this.setState({
      benefit,
      errors
    });
  }

  onBenefitTypeChange(event) {
    const benefit = { ...this.state.benefit };

    benefit.rewardId = undefined;
    benefit.eventActionId = undefined;
    benefit.custom = undefined;
    benefit.targetMarkets = undefined;

    if (benefit.tiers) {
      Object.keys(benefit.tiers).forEach((tierId) => {
        benefit.tiers[tierId] = { enabled: false };
      });
    }

    updateStateOnInputChange({ stateObj: benefit, event });

    this.setState({
      benefit,
      errors: {}
    });
  }

  /* save benefit by calling onUpdateBenefit function from props */
  onSaveBenefit() {
    const benefit = { ...this.state.benefit };

    const errors = this.validate(benefit);

    this.setState({
      errors
    });

    if (!errors) {
      this.props.onUpdateBenefit(benefit);
      this.closePopup();
    }
  }

  closePopup() {
    this.setState({
      benefit: {}
    });

    this.props.onClose();
  }

  getEventActionOptions() {
    return this.props.getEventActionOptions(this.state.benefit.id);
  }

  onLangInputChange(event, lang) {
    const translations = { ...this.state.translations };
    setTranslationsOnInputChange({ translations, event, lang });
    this.setState({
      translations
    });
  }

  onMultiLangPopupSave() {
    const benefit = { ...this.state.benefit };
    const translations = { ...this.state.translations };
    multiLangUpdateState({
      stateObj: benefit,
      translations,
      propsMap: MULTI_LANG_BENEFITS_PROPS_MAP,
      primaryLanguage: this.props.primaryLanguage
    });

    this.setState({
      benefit,
      showMultiLangPopup: false
    });
  }

  onShowMultiLangPopup() {
    const benefit = { ...this.state.benefit };
    let propList = ["nameML"];
    if (benefit.type === BENEFIT_TYPE_ENUM.CUSTOM) {
      propList.push("customML");
    }

    const propsMap = filterObjectByKeys(MULTI_LANG_BENEFITS_PROPS_MAP, propList);
    const translations = initTranslationsObj({
      stateObj: benefit,
      propsMap,
      primaryLanguage: this.props.primaryLanguage
    });

    this.setState({
      translations,
      showMultiLangPopup: true
    });
  }

  getMultiLangPopupFields() {
    const translations = { ...this.state.translations };

    if (!!translations.nameML) {
      return <MultiLangBenefitFields />;
    }

    return null;
  }

  renderEventBenefitFields(benefit, errors) {
    return (
      <BenefitSetupEventFields
        benefit={benefit}
        options={this.getEventActionOptions()}
        getActionRewardsTextFunc={this.props.getActionRewardsTextFunc}
        updateState={this.updateState.bind(this)}
        onBenefitInputChange={this.onBenefitInputChange.bind(this)}
        tierList={this.props.tierList}
        errors={errors}
      />
    );
  }

  renderCustomBenefitFields(benefit, errors) {
    return (
      <BenefitSetupCustomFields
        benefit={benefit}
        tierList={this.props.tierList}
        onInputChange={this.onBenefitInputChange.bind(this)}
        onCheckboxChange={this.onCustomCheckboxChange.bind(this)}
        errors={errors}
        onShowMultiLangPopup={this.onShowMultiLangPopup.bind(this)}
        hasMultiLang={this.props.hasMultiLang}
      />
    );
  }

  renderVoucherBenefitFields(benefit, errors) {
    let rewardOptions = [];

    if (benefit.type === BENEFIT_TYPE_ENUM.ONE_TIME_VOUCHER) {
      rewardOptions = this.props.rewardOneTimeOptions;
    } else if (benefit.type === BENEFIT_TYPE_ENUM.FIXED_YEARLY_VOUCHER) {
      rewardOptions = this.props.rewardFixedYearlyOptions;
    }

    return (
      <BenefitSetupVoucherFields
        benefit={benefit}
        tierList={this.props.tierList}
        options={rewardOptions}
        allRewardOptions={this.props.allRewardOptions}
        onCheckboxChange={this.onRewardCheckboxChange.bind(this)}
        onInputChange={this.onBenefitInputChange.bind(this)}
        isAdditionalBenefit={this.props.isAdditionalBenefit}
        errors={errors}
      />
    );
  }

  renderVoucherExclusiveAccessFields(benefit, errors) {
    return (
      <BenefitSetupExclusiveAccessFields
        benefit={benefit}
        tierList={this.props.tierList}
        options={exclusiveAccessService.getExclusiveAccessBenefitsOptions(
          this.props.exclusivesById,
          benefit.targetMarkets?.[0],
          this.props.isAdditionalBenefit
            ? EXCLUSIVE_ACCESS_OFFERS_ENUM.AS_ADDITIONALBENEFITS
            : EXCLUSIVE_ACCESS_OFFERS_ENUM.AS_FIXEDBENEFIT
        )}
        onCheckboxChange={this.onRewardCheckboxChange.bind(this)}
        onInputChange={this.onBenefitInputChange.bind(this)}
        errors={errors}
        disabled={this.props.hasMultiMarket && !benefit.targetMarkets}
        isAdditionalBenefit={this.props.isAdditionalBenefit}
      />
    );
  }

  renderBenefitFieldsByType() {
    const benefit = { ...this.state.benefit };
    const errors = this.state.errors;

    switch (benefit.type) {
      case BENEFIT_TYPE_ENUM.EVENT_ACTION:
        return this.renderEventBenefitFields(benefit, errors);
      case BENEFIT_TYPE_ENUM.CUSTOM:
        return this.renderCustomBenefitFields(benefit, errors);
      case BENEFIT_TYPE_ENUM.FIXED_YEARLY_VOUCHER:
      case BENEFIT_TYPE_ENUM.ONE_TIME_VOUCHER:
        return this.renderVoucherBenefitFields(benefit, errors);
      case BENEFIT_TYPE_ENUM.EXCLUSIVE_ACCESS:
        return this.renderVoucherExclusiveAccessFields(benefit, errors);
      default:
        return null;
    }
  }

  renderNotification() {
    if (this.state.benefit.type === BENEFIT_TYPE_ENUM.EVENT_ACTION) {
      return <Notification withBigIcon={true} content={getMessage("benefitSetup.notification")} />;
    }

    return null;
  }

  /* render benefit setup popup */
  render() {
    const { benefit, errors } = this.state;

    const title = this.props.isNewBenefit
      ? getMessage("benefitSetup.title.new")
      : getMessage("benefitSetup.title.edit");
    const saveButtonText = this.props.isNewBenefit ? getMessage("createBtn.label") : getMessage("updateBtn.label");

    return (
      <Fragment>
        <PopupContainer
          id="benefitSetupPopup"
          width="960px"
          title={title}
          show={this.props.isShow}
          onClose={this.closePopup.bind(this)}
        >
          <div className="benefits-setup">
            <SelectField
              label={getMessage("benefitSetup.type.title")}
              name="type"
              value={benefit.type}
              options={this.getBenefitTypeOptions()}
              onChange={this.onBenefitTypeChange.bind(this)}
            />

            {this.renderNotification()}

            <InputField
              label={getMessage("benefitSetup.benefitName.title")}
              name="name"
              value={benefit.name}
              onChange={this.onBenefitInputChange.bind(this)}
              tooltip={getMessage("benefitSetup.benefitName.tooltip")}
              errorMsg={getErrorMessage(errors, "name")}
              postfixButtons={
                this.props.hasMultiLang && <MultiLangPopupOpener onClick={this.onShowMultiLangPopup.bind(this)} />
              }
            />

            <MultiMarketSelect
              label={getMessage("benefitSetup.targetMarkets.label")}
              name="targetMarkets"
              value={benefit.targetMarkets}
              onChange={this.onMarketsChange.bind(this)}
              errorMsg={getErrorMessage(errors, "targetMarkets")}
              tooltip={getMessage("benefitSetup.targetMarkets.tooltip")}
              isMultiSelect={benefit.type !== BENEFIT_TYPE_ENUM.EXCLUSIVE_ACCESS}
            />

            {this.renderBenefitFieldsByType()}

            <hr className="e-separator e-separator-fullwidth" />
            <div className="e-buttongroup">
              <a className="e-btn" onClick={this.closePopup.bind(this)}>
                Cancel
              </a>
              <a className="e-btn e-btn-primary" onClick={this.onSaveBenefit.bind(this)}>
                {saveButtonText}
              </a>
            </div>
          </div>
        </PopupContainer>
        <MultiLangPopup
          id={"benefit_setup_multi_lang"}
          show={this.state.showMultiLangPopup}
          languages={this.props.languages}
          translations={this.state.translations}
          element={this.getMultiLangPopupFields()}
          showNoticeForDefault={true}
          onChange={this.onLangInputChange.bind(this)}
          onSave={this.onMultiLangPopupSave.bind(this)}
          onClose={() => {
            this.setState({
              showMultiLangPopup: false
            });
          }}
        />
      </Fragment>
    );
  }
}

BenefitSetup.propTypes = {
  benefit: benefitType,
  isNewBenefit: PropTypes.bool,
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdateBenefit: PropTypes.func,
  allRewardOptions: PropTypes.array,
  rewardOneTimeOptions: PropTypes.array,
  rewardFixedYearlyOptions: PropTypes.array,
  getEventActionOptions: PropTypes.func,
  getActionRewardsTextFunc: PropTypes.func,
  tierList: PropTypes.array,
  errors: PropTypes.object,
  isExclusiveAccessEnabled: PropTypes.bool,
  languages: PropTypes.array,
  primaryLanguage: PropTypes.string,
  hasMultiLang: PropTypes.bool,
  hasMultiMarket: PropTypes.bool,
  rewardsById: PropTypes.object,
  exclusivesById: PropTypes.object,
  isAdditionalBenefit: PropTypes.bool
};

export default BenefitSetup;
