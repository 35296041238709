import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import fileDownload from "js-file-download";

import DashboardHeader from "../components/DashboardHeader";
import DashboardOptions from "../components/DashboardOptions";
import StatusLabel from "../components/StatusLabel";
import Time from "../components/Time";
import ReferralVouchersWidget from "../views/ActionDashboard/ReferralVouchersWidget";
import ReferralRevenueWidget from "../views/ActionDashboard/ReferralRevenueWidget";
import ReferralSuccessWidget from "../views/ActionDashboard/ReferralSuccessWidget";
import actionService from "../services/actionService";
import { getActionStatusLabel } from "../helpers/statusHelper";
import { getMessage } from "../messages";
import { selectActionById } from "../reducers";
import { isDemoMode } from "../helpers/envHelper";
import { formatInputDate } from "../views/Dashboard/dashboardHelper";
import { VIEW_RANGE_ENUM } from "../constants/dashboardConstants";

import actionReportApi from "../api/actionReportApi";

const ActionDashboard = (props) => {
  const action = useSelector((state) => selectActionById(state, props.match.params.id));
  const actionId = action.id;
  const status = actionService.getActionStatus(action);
  const statusLabel = getActionStatusLabel(status, action);

  const initialStartMoment = isDemoMode() ? moment("2022-01-01") : moment().subtract(1, "months");
  const initialEndMoment = isDemoMode() ? moment("2022-04-10") : moment();

  const [dateRange, setDateRange] = useState({
    start: formatInputDate(initialStartMoment),
    end: formatInputDate(initialEndMoment)
  });
  const [dateAgg, setDateAgg] = useState(VIEW_RANGE_ENUM.DAY);
  const [loadingCsv, setLoadingCsv] = useState(false);

  function onDatesChange({ start, end }) {
    setDateRange({ start, end });
  }

  function onDateAggChange(value) {
    setDateAgg(value);
  }

  function onDownloadCsv() {
    setLoadingCsv(true);

    actionReportApi
      .getActionReferralReportCsv({
        actionId,
        startDateStr: dateRange.start,
        endDateStr: dateRange.end,
        dateAggStr: dateAgg
      })
      .then((data) => fileDownload(data, "program.csv"))
      .finally(() => setLoadingCsv(false));
  }

  return (
    <div className="e-layout e-layout-without_navigation">
      <DashboardHeader
        title={action.name}
        overviewLink={`/actions/${action.masterAction}`}
        editLink={`/action/${actionId}`}
        overviewLinkText={getMessage(`actionDashboard.overviewLinkText.${action.masterAction}`)}
        editLinkText={getMessage("actionDashboard.editLinkText")}
        statueLabelElement={
          <StatusLabel
            label={statusLabel.label}
            className={`${statusLabel.className} e-margin-left-s`}
            tooltip={statusLabel.tooltip}
          />
        }
      />
      <DashboardOptions
        dateRange={dateRange}
        dateAgg={dateAgg}
        startedAtElement={getMessage("actionDashboard.launchedAt", {
          time: <Time value={action.firstActivatedAt} onlyDate={true} />
        })}
        onDatesChange={onDatesChange}
        onDateAggChange={onDateAggChange}
        onDownloadCsv={onDownloadCsv}
        csvDownloadLoading={loadingCsv}
        csvDownloadButtonText={getMessage("actionReferralReportDownloadButton.label")}
        csvDownloadButtonTooltip={getMessage("actionReferralReportDownloadButton.tooltip")}
      />
      <div className="l-dashboard">
        <div className="e-row e-margin-top-l">
          <div className="e-col-12">
            <ReferralRevenueWidget
              actionId={actionId}
              startDateStr={dateRange.start}
              endDateStr={dateRange.end}
              dateAggStr={dateAgg}
            />
          </div>
        </div>
        <div className="e-row e-margin-top-l">
          <div className="e-col-6">
            <ReferralVouchersWidget
              actionId={actionId}
              startDateStr={dateRange.start}
              endDateStr={dateRange.end}
              dateAggStr={dateAgg}
            />
          </div>
          <div className="e-col-6">
            <ReferralSuccessWidget
              actionId={actionId}
              startDateStr={dateRange.start}
              endDateStr={dateRange.end}
              dateAggStr={dateAgg}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionDashboard;
