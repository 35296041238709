import React, { Component } from "react";
import PropTypes from "prop-types";

import SelectField from "../../components/SelectFieldV2";
import CheckboxField from "../../components/CheckboxField";
import LoaderOverlay from "../../components/LoaderOverlay";
import PopupContainer from "../../components/PopupContainer";

import planApi from "../../api/planApi";

import { updateStateOnInputChange } from "../../helpers/formHelper";
import { POINTS_PLACEHOLDER } from "../../helpers/pointsHelper";
import planService from "../../services/planService";

import currentPlanVersionApi from "../../api/currentPlanVersionApi";
import { getMessage } from "../../messages";

class MemberProfileChangePlanPopup extends Component {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isShow && !prevProps.isShow) {
      this.setStateByPlan();
    }
  }

  getDefaultState() {
    return {
      planOptions: [],
      loading: false,
      selectedPlanId: "",
      statusPointsChecked: false,
      balancePointsChecked: false,
      pendingPointsChecked: false,
      redeemedVouchersChecked: false,
      confirmed: false,
      confirmError: "",
      plan: undefined
    };
  }

  async setStateByPlan() {
    const { contactPlanId, plan } = this.props;
    this.setState({
      loading: true
    });

    const plans = await planApi.getAllPlans();

    const planOptions = planService
      .getPlanOptions(plans)
      .filter(
        (option) => option.value !== contactPlanId && planService.areSamePlanType(plan.planType, option.planType)
      );
    const contactPlan = await currentPlanVersionApi.getCurrentPlanVersion(contactPlanId);

    this.setState({
      planOptions,
      selectedPlanId: planOptions[0].value,
      planHasRedemption: planService.hasRedemption(contactPlan),
      loading: false
    });
  }

  onInputChange(event) {
    const state = { ...this.state };
    updateStateOnInputChange({ stateObj: state, event });
    this.setState(state);
  }

  onPlanUpdateClick() {
    const {
      selectedPlanId,
      statusPointsChecked,
      balancePointsChecked,
      pendingPointsChecked,
      redeemedVouchersChecked,
      confirmed,
      confirmError
    } = { ...this.state };

    if (!confirmed) {
      this.setState({
        confirmError: getMessage("pleaseConfirmBtn.label")
      });
      return;
    }

    this.props.onChangeMemberPlan({
      planId: selectedPlanId,
      balancePointsMigrate: balancePointsChecked,
      statusPointsMigrate: statusPointsChecked,
      pendingPointsMigrate: pendingPointsChecked,
      redeemedVouchersMigrate: redeemedVouchersChecked
    });
  }

  closePopup() {
    this.setState(this.getDefaultState());
    this.props.onClose();
  }
  render() {
    return (
      <PopupContainer
        id="MemberProfileChangePlanPopup"
        title={getMessage("memberProfileChangePlanPopup.title")}
        show={this.props.isShow}
        onClose={this.closePopup.bind(this)}
      >
        <LoaderOverlay show={this.state.loading} />
        <SelectField
          label={getMessage("memberProfileChangePlanPopup.selectedPlan.label")}
          name="selectedPlanId"
          options={this.state.planOptions}
          value={this.state.selectedPlanId}
          onChange={this.onInputChange.bind(this)}
        />

        <div className="m-b-5">
          <label>{getMessage("memberProfileChangePlanPopup.selectRewards.label")}</label>
          <e-tooltip content={getMessage("memberProfileChangePlanPopup.selectRewards.tooltip")} type="helper" />
        </div>

        <CheckboxField
          name="statusPointsChecked"
          label={this.props.getPointsText(
            getMessage("memberProfileChangePlanPopup.selectRewards.statusPointsCb.label")
          )}
          checked={this.state.statusPointsChecked}
          onChange={this.onInputChange.bind(this)}
          tooltip={getMessage("memberProfileChangePlanPopup.selectRewards.statusPointsCb.tooltip")}
        />

        {this.state.planHasRedemption && (
          <CheckboxField
            name="balancePointsChecked"
            label={this.props.getPointsText(
              getMessage("memberProfileChangePlanPopup.selectRewards.balancePointsCb.label")
            )}
            checked={this.state.balancePointsChecked}
            onChange={this.onInputChange.bind(this)}
            tooltip={getMessage("memberProfileChangePlanPopup.selectRewards.balancePointsCb.tooltip")}
          />
        )}

        <CheckboxField
          name="pendingPointsChecked"
          label={this.props.getPointsText(
            getMessage("memberProfileChangePlanPopup.selectRewards.pendingPointsCb.label")
          )}
          checked={this.state.pendingPointsChecked}
          onChange={this.onInputChange.bind(this)}
          tooltip={getMessage("memberProfileChangePlanPopup.selectRewards.pendingPointsCb.tooltip")}
        />

        <CheckboxField
          name="redeemedVouchersChecked"
          label={getMessage("memberProfileChangePlanPopup.selectRewards.redeemedVouchersCb.label")}
          checked={this.state.redeemedVouchersChecked}
          onChange={this.onInputChange.bind(this)}
          tooltip={getMessage("memberProfileChangePlanPopup.selectRewards.redeemedVouchersCb.tooltip")}
        />

        <div className="m-b-5 m-t-25">
          <label>{getMessage("memberProfileChangePlanPopup.migrationConfirmation.label")}</label>
        </div>

        <CheckboxField
          name="confirmed"
          label={getMessage("memberProfileChangePlanPopup.migrationConfirmationCb.label")}
          checked={this.state.confirmed}
          onChange={this.onInputChange.bind(this)}
          errorMsg={this.state.confirmError && !this.state.confirmed}
        />

        <hr className="e-separator e-separator-fullwidth" />
        <div className="e-buttongroup">
          <a className="e-btn" onClick={this.closePopup.bind(this)}>
            {getMessage("cancelBtn.label")}
          </a>
          <a className="e-btn e-btn-primary" onClick={this.onPlanUpdateClick.bind(this)}>
            {getMessage("updateBtn.label")}
          </a>
        </div>
      </PopupContainer>
    );
  }
}

MemberProfileChangePlanPopup.propTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  contactPlanId: PropTypes.string,
  onChangeMemberPlan: PropTypes.func,
  getPointsText: PropTypes.func
};

export default MemberProfileChangePlanPopup;
