import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const StatusLabel = (props) => {
  const labelComponent = <div className={classNames("e-label", props.className)}>{props.label}</div>;

  if (props.tooltip) {
    return <e-tooltip content={props.tooltip}>{labelComponent}</e-tooltip>;
  }

  if (props.label) {
    return labelComponent;
  }

  return null;
};

StatusLabel.propTypes = {
  status: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.string
};

export default StatusLabel;
