import React from "react";
import PropTypes from "prop-types";

import SelectField from "../../components/SelectFieldV2";
import InputField from "../../components/InputField";

import { getValueByType } from "../../helpers/formHelper";
import { getErrorMessage } from "../../validators";
import { benefitType } from "../../types";
import { getMessage } from "../../messages";

const BenefitSetupEventFields = ({
  benefit,
  options,
  onBenefitInputChange,
  errors,
  tierList,
  getActionRewardsTextFunc,
  updateState
}) => {
  const onChange = (event) => {
    onBenefitInputChange(event);

    const eventActionId = getValueByType(event.target);

    tierList.forEach((tier) => {
      const rewardsText = eventActionId ? getActionRewardsTextFunc(eventActionId, tier.id) : "";
      updateState(`tiers.${tier.id}.enabled`, !!rewardsText);
    });
  };

  return (
    <React.Fragment>
      <SelectField
        label={getMessage("benefitSetupEventFields.eventActionId.label")}
        name="eventActionId"
        value={benefit.eventActionId}
        options={options}
        onChange={onChange}
        placeholder={getMessage("benefitSetupEventFields.eventActionId.placeholder")}
        errorMsg={getErrorMessage(errors, "eventActionId")}
      />

      <label className="e-field__label">
        {getMessage("benefitSetupEventFields.tiersAndRewards.title")}
        <e-tooltip content={getMessage("benefitSetupEventFields.tiersAndRewards.tooltip")} type="helper" />
      </label>
      <div className="benefit-setup-tiers">
        {tierList.map((tier) => {
          return (
            <InputField
              label={tier.name}
              value={benefit.eventActionId ? getActionRewardsTextFunc(benefit.eventActionId, tier.id) : ""}
              disabled={true}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
};

BenefitSetupEventFields.propTypes = {
  benefit: benefitType,
  tierList: PropTypes.array,
  options: PropTypes.array,
  getActionRewardsTextFunc: PropTypes.func,
  updateState: PropTypes.func,
  onInputChange: PropTypes.func,
  errors: PropTypes.shape({})
};

export default BenefitSetupEventFields;
