import React, { Fragment } from "react";
import PropTypes from "prop-types";

import AccordionItem from "../../components/AccordionItem";
import RadioButtonList from "../../components/RadioButtonList";
import InputNumberField from "../../components/InputNumberField";
import ActionSetupTierFields from "./ActionSetupTierFields";
import SelectField from "../../components/SelectFieldV2";
import PopupContainer from "../../components/PopupContainer";

import rewardService from "../../services/rewardService";
import actionService from "../../services/actionService";
import planService from "../../services/planService";

import {
  REFERRAL_CODES_LIMIT_OPTIONS,
  REFERRAL_REWARDS_LIMIT_OPTIONS,
  SPECIAL_TIER_ENUM,
  REFERRAL_LIMIT_TYPE_ENUM
} from "../../constants/actionConstants";

import { POINTS_TYPE_OPTIONS_POINTS_PLAN } from "../../constants/tierConstants";

import { getErrorMessage } from "../../validators";

import { actionType } from "../../types";
import { getMessage } from "../../messages";

const ActionSetupRewards = (props) => {
  const {
    action,
    tiers,
    rewardVoucherOptions,
    rewardExclusiveAccessOptions,
    allVoucherOptions,
    allExclusiveAccessOptions,
    multiplyPointsOptions,
    onRewardInputChange,
    onRewardEnableInputChange,
    errors,
    viewMode,
    showOnlyNonMember,
    hideNonMember,
    onAccordionStateChange,
    onInputChange,
    notificationElem,
    tierOptions,
    isExclusiveAccessEnabled,
    plan,
    isPointSplitDisabled,
    onPointsTypeChange,
    showApproveDialog,
    approveDialogMessage,
    onPointTypeChangeApproved,
    onPointTypeChangeCanceled
  } = props;

  const isReferral = actionService.isReferralAction(action.masterAction);

  const getRewardOptions = (allOptions, validOptions, tierRewards, tierRewardAttr) => {
    if (!tierRewards) {
      return validOptions;
    }

    const selectedRewardId = tierRewards[tierRewardAttr];
    if (!selectedRewardId) {
      return validOptions;
    }

    validOptions = rewardService.getOptionsWithSelected({
      allOptions,
      validOptions,
      selectedValue: selectedRewardId
    });

    return validOptions;
  };

  const getUpgradeTierOptions = (currTierId) => {
    if (currTierId === SPECIAL_TIER_ENUM.ALL) {
      return [];
    }

    let currTierIndex = 0;

    if (currTierId !== SPECIAL_TIER_ENUM.NON) {
      currTierIndex = tierOptions.findIndex((option) => option.value === currTierId);
    }

    const options = tierOptions.slice(currTierIndex + 1).map((option) => {
      return {
        value: option.value,
        name: `Upgrade to ${option.name}`
      };
    });

    return options;
  };

  const onPointTypeChangeCancelInternal = () => {
    document.getElementById(`pointsType`).value = action.pointsType;
    onPointTypeChangeCanceled();
  };

  const onPointTypeChangeApproveInternal = () => {
    onPointTypeChangeApproved();
    document.getElementById(`pointsType`).value = action.pointsType;
  };

  const renderAccordionTierItems = (tierItems) => {
    const accordionItems = [];

    const tiersWithoutAllLength = tiers.filter((tier) => tier.tierId !== SPECIAL_TIER_ENUM.ALL).length;
    const hideUniformSettings = hideNonMember ? tiersWithoutAllLength === 2 : tiersWithoutAllLength === 1;

    tierItems.forEach((tier) => {
      const tierId = tier.tierId;

      if (
        (tierId !== SPECIAL_TIER_ENUM.NON && showOnlyNonMember) ||
        (tierId === SPECIAL_TIER_ENUM.NON && hideNonMember) ||
        (tierId === SPECIAL_TIER_ENUM.ALL && hideUniformSettings)
      ) {
        return;
      }

      let tooltip;
      if (tierId === SPECIAL_TIER_ENUM.NON) {
        tooltip = getMessage("actionSetupRewards.accordionTierItem.tooltip.non");
      }
      if (tierId === SPECIAL_TIER_ENUM.ALL) {
        tooltip = getMessage("actionSetupRewards.accordionTierItem.tooltip.all");
      }

      const accordionItem = (
        <AccordionItem
          title={tier.displaySettingsName}
          name="accordion-tier"
          id={tierId}
          key={`accordion-item-${tierId}`}
          open={tier.accordionOpenState}
          onStateChange={(id, newState) => onAccordionStateChange(id, newState)}
          tooltip={tooltip}
        >
          <div style={tierId === SPECIAL_TIER_ENUM.GUEST ? { width: "50%" } : null}>
            <ActionSetupTierFields
              tierRewards={tier.rewards}
              tierId={tierId}
              rewardVoucherOptions={getRewardOptions(
                allVoucherOptions,
                rewardVoucherOptions,
                tier.rewards,
                "rewardPool"
              )}
              rewardExclusiveAccessOptions={getRewardOptions(
                allExclusiveAccessOptions,
                rewardExclusiveAccessOptions,
                tier.rewards,
                "exclusiveAccess"
              )}
              multiplyPointsOptions={multiplyPointsOptions}
              statusUpgradeOptions={getUpgradeTierOptions(tier.tierId)}
              onInputChange={(event, customValue) => onRewardInputChange(event, customValue, tierId)}
              onEnableInputChange={(event, valueProp, emptyValue) =>
                onRewardEnableInputChange(event, tierId, valueProp, emptyValue)
              }
              showPoints={tier.rewards.pointsEnabled !== undefined}
              showMultiplyPoints={tier.rewards.multiplyPointsEnabled !== undefined}
              disableMultiplyPoints={tier.disableMultiplyPoints}
              showVoucher={tier.rewards.rewardPoolEnabled !== undefined}
              showExclusiveAccess={tier.rewards.exclusiveAccessEnabled !== undefined}
              showTierUpgrade={tier.rewards.statusUpgradeEnabled !== undefined}
              errors={errors}
              viewMode={viewMode}
              isExclusiveAccessEnabled={isExclusiveAccessEnabled}
              plan={plan}
              action={action}
              multiplyPointsEnabledTooltip={tier.multiplyPointsEnabledTooltip}
            />
          </div>
        </AccordionItem>
      );

      accordionItems.push(accordionItem);
    });

    return <div className="e-accordion">{accordionItems}</div>;
  };

  const renderReferralCodesLimitation = () => {
    return (
      <Fragment>
        <RadioButtonList
          label={getMessage("actionSetupRewards.referralLimitations.codes.type.title")}
          name="referralLimitations.codes.type"
          value={action.referralLimitations.codes.type}
          disabled={viewMode}
          options={REFERRAL_CODES_LIMIT_OPTIONS}
          tooltip={getMessage("actionSetupRewards.referralLimitations.codes.type.tooltip")}
          onChange={(event) => {
            if (event.target.value === REFERRAL_LIMIT_TYPE_ENUM.NONE) {
              action.referralLimitations.codes.amount = null;
            }
            onInputChange(event);
          }}
        />
        {action.referralLimitations.codes.type === REFERRAL_LIMIT_TYPE_ENUM.BEGINNING_OF_YEAR && (
          <div class="m-n-t-15">
            <InputNumberField
              name="referralLimitations.codes.amount"
              value={action.referralLimitations.codes.amount}
              disabled={viewMode}
              onChange={onInputChange}
              errorMsg={getErrorMessage(props.errors, "referralLimitations.codes.amount")}
            />
          </div>
        )}
      </Fragment>
    );
  };

  const renderReferralRewardsLimitation = () => {
    return (
      <Fragment>
        <RadioButtonList
          label={getMessage("actionSetupRewards.referralLimitations.rewards.type.label")}
          name="referralLimitations.rewards.type"
          value={action.referralLimitations.rewards.type}
          disabled={viewMode}
          options={REFERRAL_REWARDS_LIMIT_OPTIONS}
          tooltip={getMessage("actionSetupRewards.referralLimitations.rewards.type.tooltip")}
          onChange={(event) => {
            if (event.target.value === REFERRAL_LIMIT_TYPE_ENUM.NONE) {
              action.referralLimitations.rewards.amount = null;
            }
            onInputChange(event);
          }}
        />
        {action.referralLimitations.rewards.type === REFERRAL_LIMIT_TYPE_ENUM.BEGINNING_OF_YEAR && (
          <div class="m-n-t-15">
            <InputNumberField
              name="referralLimitations.rewards.amount"
              value={action.referralLimitations.rewards.amount}
              disabled={viewMode}
              onChange={onInputChange}
              errorMsg={getErrorMessage(props.errors, "referralLimitations.rewards.amount")}
            />
          </div>
        )}
      </Fragment>
    );
  };

  return (
    <div className="e-box">
      <div className="e-row">
        <div className="e-col-12">
          {notificationElem}
          {!isReferral ? (
            <Fragment>
              <h2>
                {getMessage("actionSetupRewards.title")}
                <e-tooltip content={getMessage("actionSetupRewards.tooltip")} type="helper" />
              </h2>

              {!isPointSplitDisabled && planService.hasRedemption(plan) && (
                <Fragment>
                  <div className="e-row">
                    <div className="e-col-6">
                      <h2>{getMessage("actionSetupRewards.pointsType.title")}</h2>
                      <SelectField
                        id="tierSetupPurchaseBenefits.pointsType"
                        label={getMessage("tierSetupPurchaseBenefits.pointsType.label")}
                        options={POINTS_TYPE_OPTIONS_POINTS_PLAN}
                        name={"pointsType"}
                        value={action.pointsType}
                        onChange={onPointsTypeChange}
                        disabled={viewMode}
                        tooltip={getMessage("actionSetupRewards.pointsType.tooltip")}
                      />
                    </div>
                  </div>
                </Fragment>
              )}

              {renderAccordionTierItems(tiers)}
            </Fragment>
          ) : (
            <div className="e-row">
              <div className="e-col-6">
                <h2>{getMessage("actionSetupRewards.subtitle.members")}</h2>
                <SelectField
                  id="tierSetupPurchaseBenefits.pointsType"
                  label={getMessage("tierSetupPurchaseBenefits.pointsType.label")}
                  options={POINTS_TYPE_OPTIONS_POINTS_PLAN}
                  name={"pointsType"}
                  value={action.pointsType}
                  onChange={onPointsTypeChange}
                  disabled={viewMode}
                  tooltip={getMessage("actionSetupRewards.pointsType.tooltip")}
                />
                {renderAccordionTierItems(tiers.filter((tier) => tier.tierId !== SPECIAL_TIER_ENUM.GUEST))}
                <div className="m-t-30">{renderReferralRewardsLimitation()}</div>
              </div>
              <div className="e-col-6">
                <h2>
                  {getMessage("actionSetupRewards.subtitle.friends")}{" "}
                  <e-tooltip content={getMessage("actionSetupRewards.subtitle.friends.tooltip")} type="helper" />
                </h2>
                {renderAccordionTierItems(tiers.filter((tier) => tier.tierId === SPECIAL_TIER_ENUM.GUEST))}
                <div className="m-t-30">{renderReferralCodesLimitation()}</div>
              </div>
              <div className="e-col e-col__separator" />
            </div>
          )}
        </div>
      </div>
      <PopupContainer
        id="popupApproveChange"
        title={getMessage("actionSetupRewards.pointsType.popup.title")}
        show={showApproveDialog}
        buttons={[
          {
            text: getMessage("cancelBtn.label"),
            isClose: true,
            onClick: onPointTypeChangeCancelInternal
          },
          {
            text: getMessage("confirmBtn.label"),
            isClose: true,
            onClick: onPointTypeChangeApproveInternal
          }
        ]}
        onClose={() => {
          onPointTypeChangeCanceled();
        }}
      >
        <div>{approveDialogMessage}</div>
      </PopupContainer>
    </div>
  );
};

ActionSetupRewards.propTypes = {
  onRewardInputChange: PropTypes.func.isRequired,
  onRewardEnableInputChange: PropTypes.func.isRequired,
  rewardVoucherOptions: PropTypes.array.isRequired,
  rewardExclusiveAccessOptions: PropTypes.array.isRequired,
  tierOptions: PropTypes.array.isRequired,
  allVoucherOptions: PropTypes.array.isRequired,
  allExclusiveAccessOptions: PropTypes.array.isRequired,
  multiplyPointsOptions: PropTypes.array.isRequired,
  action: actionType.isRequired,
  tiers: PropTypes.array.isRequired,
  errors: PropTypes.object,
  viewMode: PropTypes.bool,
  showOnlyNonMember: PropTypes.bool,
  hideNonMember: PropTypes.bool,
  onAccordionStateChange: PropTypes.func,
  onInputChange: PropTypes.func,
  notificationElem: PropTypes.element,
  isExclusiveAccessEnabled: PropTypes.bool
};

export default ActionSetupRewards;
