import React from "react";
import PropTypes from "prop-types";

import InputField from "../components/InputField";

const InputNumberField = (props) => {
  //on each char change - call the change function with value of string
  const onChange = (event) => {
    if (event.target.validity.valid) {
      props.onChange(event, event.target.value);
    }
  };

  //on blur change - call the change function with value of number
  const onBlur = (event) => {
    if (event.target.validity.valid) {
      let { value } = { ...event.target };
      if (value === "") {
        props.onChange(event, null);
        return;
      }
      props.onChange(event, Number(value));
    }
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      return onBlur(event);
    }
  };

  const pattern = props.allowDecimal ? "^\\d*(\\.\\d{0,2})?$" : "^\\d*";
  const additionalProps = { ...props };
  additionalProps.onChange = onChange;
  additionalProps.onBlur = onBlur;
  additionalProps.onKeyDown = onKeyDown;

  additionalProps.pattern = pattern;
  additionalProps.type = "custom-number";
  additionalProps.value = props.value === null ? undefined : props.value;

  return <InputField autoComplete={"off"} {...additionalProps} />;
};

InputNumberField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  onChange: PropTypes.func,
  inline: PropTypes.bool,
  postfixLabel: PropTypes.string,
  tooltip: PropTypes.string,
  errorMsg: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  noMargin: PropTypes.bool,
  allowDecimal: PropTypes.bool
};
export default InputNumberField;
