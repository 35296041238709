import React, { useState, useEffect, Fragment } from "react";
import { renderToString } from "react-dom/server";
import cloneDeep from "lodash.clonedeep";

import { convertDateForChart, formatTrendText, datesDiffStr } from "../Dashboard/dashboardHelper";
import Widget from "../../components/Widget";
import Time from "../../components/Time";
import WidgetTotalsItem from "../Dashboard/WidgetTotalsItem";
import WidgetHelpLink from "../Dashboard/WidgetHelpLink";
import LegendWithSelection from "../Dashboard/LegendWithSelection";
import DashboardEmptyState from "../Dashboard/DashboardEmptyState";

import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { selectIsCurrentPlanVersionExists, selectCurrentPlanVersion, selectTiers } from "../../reducers";

import { TIER_COLORS_ARRAY } from "../../constants/dashboardConstants";

const CHART_COLORS = {
  TOTAL: "#424E88"
};

const CHART_HEIGHT = 270;

const VoucherCodesTemplateWidget = ({
  rewardId,
  startDateStr,
  endDateStr,
  dateAggStr,
  apiChartFunc,
  apiKpiFunc,
  messages
}) => {
  let tiers = useSelector((state) => {
    const isCurrentPlanExists = selectIsCurrentPlanVersionExists(state);
    return isCurrentPlanExists ? selectCurrentPlanVersion(state).tiers : selectTiers(state);
  });

  const initChartMetaData = (tiers) => {
    let chartMetaData = [
      {
        id: "total",
        displayName: messages.chartTotalMetric,
        color: CHART_COLORS.TOTAL
      }
    ];

    tiers.forEach((tier, index) => {
      chartMetaData.push({ id: tier.id, displayName: tier.name, color: TIER_COLORS_ARRAY[index] });
    });

    return chartMetaData;
  };

  const initLegendSelection = (chartMetaData) => {
    let legendSelection = {};
    chartMetaData.forEach((metaData) => {
      legendSelection[metaData.id] = true;
    });

    return legendSelection;
  };

  const initChartSeriesArr = (chartMetaData) => {
    return chartMetaData.map((d) => {
      return {
        id: d.id,
        name: d.displayName,
        color: d.color,
        data: []
      };
    });
  };

  const chartMetaData = initChartMetaData(tiers);
  const [loading, setLoading] = useState(true);
  const [chartSeriesArr, setChartSeriesArr] = useState(initChartSeriesArr(chartMetaData));
  const [legendSelection, setLegendSelection] = useState(initLegendSelection(chartMetaData));
  const [totals, setTotals] = useState({});
  const [chartCustomTooltip, setChartCustomTooltip] = useState("Blah Blah");
  const [showEmptyState, setShowEmptyState] = useState(false);

  useEffect(() => {
    loadData();
  }, [startDateStr, endDateStr, dateAggStr]);

  const getNumberValueAttr = () => {
    return { humanize: "auto", precision: "2", "trim-fraction-zeros": "true" };
  };

  const getChartTooltipContent = (date, dataRow) => {
    let content = [];

    const dateElm = renderToString(<Time value={date} onlyDate={true} />);

    content.push({ label: dateElm, type: "header" });

    content.push({
      value: dataRow.total,
      label: messages.chartTotalMetric,
      color: CHART_COLORS.TOTAL,
      valueFormat: {
        type: "number",
        options: getNumberValueAttr()
      }
    });

    chartMetaData.forEach((metaData) => {
      const { tiers } = { ...dataRow };
      if (metaData.id === "total") {
        return;
      }

      const { num } = { ...tiers[metaData.id] };
      content.push({
        value: num || 0,
        label: metaData.displayName,
        color: metaData.color,
        valueFormat: {
          type: "number",
          options: getNumberValueAttr()
        }
      });
    });

    return content;
  };

  const setChartSeriesArrState = (dataRows) => {
    if (!dataRows) {
      setShowEmptyState(true);
      return;
    }

    //dataRows = transformDataDateForDemo(dataRows, this.state.selectedDaysRange);

    let newChartSeriesArr = [];
    let chartCustomTooltip = {};

    //reset data on each series
    newChartSeriesArr = cloneDeep(chartSeriesArr);
    newChartSeriesArr.forEach((series) => {
      series.data = [];
    });

    dataRows.forEach((row) => {
      const date = convertDateForChart(row.date);
      row.total = row.total || 0;

      newChartSeriesArr.forEach((series) => {
        let num = 0;
        if (series.id === "total") {
          num = row.total;
        } else {
          num = row.tiers[series.id] ? row.tiers[series.id].num || 0 : 0;
        }

        series.data.push({
          x: date,
          y: num
        });
      });

      chartCustomTooltip[date] = { rows: getChartTooltipContent(date, row) };
    });

    setChartSeriesArr(newChartSeriesArr);
    setChartCustomTooltip(chartCustomTooltip);
  };

  const setTotalState = (data) => {
    if (!data) {
      setShowEmptyState(true);
      return;
    }

    let totals = {
      total: data.total || 0,
      totalPrevDiffPct: data.totalPrevDiffPct || 0
    };
    setTotals(totals);
  };

  const loadData = () => {
    const promises = [
      apiChartFunc({ rewardId, startDateStr, endDateStr, dateAggStr }),
      apiKpiFunc({ rewardId, startDateStr, endDateStr, dateAggStr })
    ];
    setLoading(true);
    setShowEmptyState(false);
    Promise.all(promises)
      .then((response) => {
        setChartSeriesArrState(response[0]);
        setTotalState(response[1]);
      })
      .catch((error) => {
        setShowEmptyState(true);
        throw error;
      })
      .finally(() => setLoading(false));
  };

  const getTrendText = (value) => {
    return formatTrendText`${value} than previous ${datesDiffStr(startDateStr, endDateStr, dateAggStr)}`;
  };

  const getItemsForLegend = () => {
    return chartMetaData.map((item) => {
      return {
        id: item.id,
        displayName: item.displayName,
        color: item.color,
        isSelected: legendSelection[item.id]
      };
    });
  };

  const onLegendSelectionChange = (selectionId) => {
    let newLegendSelection = cloneDeep(legendSelection);
    newLegendSelection[selectionId] = !newLegendSelection[selectionId];
    setLegendSelection(newLegendSelection);
  };

  const renderTotals = () => {
    const { total, totalPrevDiffPct } = {
      ...totals
    };

    return (
      <div className="e-grid e-grid-column">
        <div className="e-cell">
          <WidgetTotalsItem
            title={messages.kpiTitle}
            titleTooltip={messages.kpiTooltip}
            value={total}
            valueNumericAttr={getNumberValueAttr()}
            valueColor={CHART_COLORS.TOTAL}
            trendValue={totalPrevDiffPct}
            trendText={getTrendText(totalPrevDiffPct)}
          />
        </div>
      </div>
    );
  };

  const renderChart = () => {
    return (
      <ec-chart height={CHART_HEIGHT} domain-type="time" custom-tooltip={JSON.stringify(chartCustomTooltip)}>
        {chartSeriesArr.map((series, index) => {
          if (legendSelection[series.id]) {
            return (
              <ec-series-line
                key={series.id}
                order={index}
                name={series.name}
                data={JSON.stringify(series.data)}
                color={series.color}
              ></ec-series-line>
            );
          }
        })}
      </ec-chart>
    );
  };

  const renderContent = () => {
    if (showEmptyState) {
      return <DashboardEmptyState />;
    }

    return (
      <Fragment>
        <div className="e-grid">
          <div className="e-cell e-cell-3">{renderTotals()}</div>
          <div className="e-cell e-cell-auto">{renderChart()}</div>
        </div>
        <div className="e-margin-top-m">
          <LegendWithSelection items={getItemsForLegend()} onChange={onLegendSelectionChange} visible={true} />
        </div>
      </Fragment>
    );
  };

  return (
    <Widget
      title={messages.widgetTitle}
      className="enrollments-widget"
      footerActions={<WidgetHelpLink />}
      content={renderContent()}
      loading={loading}
      isEmptyState={showEmptyState}
    />
  );
};

VoucherCodesTemplateWidget.propTypes = {
  rewardId: PropTypes.string.isRequired,
  startDateStr: PropTypes.string.isRequired,
  endDateStr: PropTypes.string.isRequired,
  dateAggStr: PropTypes.string.isRequired,
  apiChartFunc: PropTypes.func.isRequired,
  apiKpiFunc: PropTypes.func.isRequired,
  messages: PropTypes.shape({
    widgetTitle: PropTypes.string,
    kpiTitle: PropTypes.string,
    kpiTooltip: PropTypes.string,
    chartTotalMetric: PropTypes.string
  })
};

export default VoucherCodesTemplateWidget;
