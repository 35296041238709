import React, { Fragment, useState } from "react";
import { DateRangeField } from "./DateFields";
import { formatInputDate } from "../views/Dashboard/dashboardHelper";
import "../assets/styles/Widget.scss";

const WidgetDateRange = ({ dateRange, onChange, isDemoMode }) => {
  const onDateChange = (event, value) => {
    onChange({ start: formatInputDate(value.start), end: formatInputDate(value.end) });
  };

  return (
    <Fragment>
      <DateRangeField
        className="widget_header_daterange"
        maxDate={new Date()}
        value={dateRange}
        name="reportDateRange"
        onChange={onDateChange}
        noClear={true}
        disabled={!!isDemoMode}
        updateOnClose={true}
      />
    </Fragment>
  );
};

export default WidgetDateRange;
