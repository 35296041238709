import { POINTS_PLACEHOLDER } from "../helpers/pointsHelper";
import { getMessage } from "../messages";

export const UPDATE_POINTS_TYPE = {
  LOYALTY: "loyalty",
  STATUS: "status",
  BALANCE: "balance",
  CASHBACK: "cashback"
};

export const UPDATE_POINTS_TYPE_OPTIONS_POINTS_PLAN = [
  {
    name: getMessage("updatePointsType.points.options.loyalty"),
    value: UPDATE_POINTS_TYPE.LOYALTY
  },
  {
    name: getMessage("updatePointsType.points.options.status"),
    value: UPDATE_POINTS_TYPE.STATUS
  },
  {
    name: getMessage("updatePointsType.points.options.spend"),
    value: UPDATE_POINTS_TYPE.BALANCE
  }
];

export const UPDATE_POINTS_TYPE_OPTIONS_SPEND_PLAN = [
  {
    name: getMessage("updatePointsType.spend.options.status"),
    value: UPDATE_POINTS_TYPE.STATUS
  },
  {
    name: getMessage("updatePointsType.spend.options.cashback"),
    value: UPDATE_POINTS_TYPE.CASHBACK
  }
];

export const POINTS_UPDATE_TYPE = {
  ADD_POINTS: "add",
  REMOVE_POINTS: "remove"
};

export const TIER_UPDATE_TYPE = {
  UPGRADE_TIER: "upgrade",
  DOWNGRADE_TIER: "downgrade"
};

export const TIER_UPDATE_OPTIONS = [
  { name: getMessage("tierUpdate.options.tierUpgrade.name"), value: TIER_UPDATE_TYPE.UPGRADE_TIER },
  { name: getMessage("tierUpdate.options.tierDowngrade.name"), value: TIER_UPDATE_TYPE.DOWNGRADE_TIER }
];

export const TIER_DOWNGRADE_TYPE_ENUM = {
  STATUS_POINTS: "statusPoints",
  SPECIFIC_TIER: "specificTier"
};

export const TIER_DOWNGRADE_TYPE_OPTIONS = [
  {
    value: TIER_DOWNGRADE_TYPE_ENUM.STATUS_POINTS,
    name: getMessage("tierDowngrade.points.options.status.name")
  },
  {
    value: TIER_DOWNGRADE_TYPE_ENUM.SPECIFIC_TIER,
    name: getMessage("tierDowngrade.points.options.tier.name"),
    tooltip: getMessage("tierDowngrade.points.options.tier.tooltip")
  }
];

export const TIER_DOWNGRADE_TYPE_OPTIONS_SPEND = [
  {
    value: TIER_DOWNGRADE_TYPE_ENUM.STATUS_POINTS,
    name: getMessage("tierDowngrade.spend.options.status.name")
  },
  {
    value: TIER_DOWNGRADE_TYPE_ENUM.SPECIFIC_TIER,
    name: getMessage("tierDowngrade.spend.options.tier.name"),
    tooltip: getMessage("tierDowngrade.spend.options.tier.tooltip")
  }
];

export const ACTIVITY_TYPE = {
  ONLINE_PURCHASE: "online_purchase",
  ACTION: "action",
  REDEMPTION: "redemption",
  VOUCHER_REDEMPTION: "voucher_redemption",
  VOUCHER_REDEMPTION_FREE: "voucher_redemption_free",
  VOUCHER_REDEMPTION_POINTS: "voucher_redemption_points",
  VOUCHER_REDEMPTION_CASHBACK: "voucher_redemption_cashback",
  EXCLUSIVE_REDEMPTION: "exclusive_redemption",
  EXCLUSIVE_REDEMPTION_POINTS: "exclusive_redemption_points",
  EXCLUSIVE_REDEMPTION_FREE: "exclusive_redemption_free",
  CUSTOMER_SUPPORT: "customer_support",
  POINTS_EXPIRATION: "points_expiration",
  TIER_CALCULATION: "tier_calculation",
  GROUP_CREATION: "group_create",
  GROUP_CALCULATION: "group_calculation",
  GROUP_DELETED: "group_delete",
  ADDITIONAL_BENEFITS: "additional_benefits",
  MEMBER_JOINED: "member_joined",
  POINTS_MIGRATED: "points_migrated"
};

export const ACTIVITY_SUB_TYPE = {
  PLAN_MIGRATION: "plan_migration"
};

export const ACTIVITY_STATUS_TYPE = {
  PENDING: "pending",
  CONFIRMED: "confirmed",
  EXPIRED: "expired",
  CANCELED: "canceled",
  REMOVED: "removed"
};

export const ACTIVITY_STATUS_TYPE_TEXT = {
  pending: getMessage("status.activity.pending"),
  confirmed: getMessage("status.activity.confirmed"),
  expired: getMessage("status.activity.expired"),
  canceled: getMessage("status.activity.canceled"),
  removed: getMessage("status.activity.removed")
};

export const ACTIVITY_ELEMENT_TYPE = {
  PURCHASE_POINTS: "purchase_points",
  BONUS_POINTS: "bonus_points",
  MULTIPLY_POINTS: "multiply_points",
  STATUS_UPGRADE: "status_upgrade",
  STATUS_POINTS: "status_points",
  VOUCHER: "voucher",
  EXCLUSIVE_REDEMPTION_POINTS: "exclusive_redemption_points",
  EXCLUSIVE_REDEMPTION_FREE: "exclusive_redemption_free",
  REDEMPTION_POINTS: "redemption_points",
  REDEMPTION_FREE: "redemption_free",
  EXCLUSIVE_ACCESS: "exclusive_access",
  STATUS_UPGRADE_POINTS: "status_upgrade_points",
  SUPPORT_ADD_POINTS: "support_add_points",
  SUPPORT_REMOVE_POINTS: "support_remove_points",
  SUPPORT_TIER_UPGRADE: "support_tier_upgrade",
  SUPPORT_TIER_DOWNGRADE: "support_tier_downgrade",
  SUPPORT_PLAN_CHANGE: "support_plan_change",
  PLAN_CHANGE_POINTS: "plan_change_points",
  PLAN_CHANGE_VOUCHER: "plan_change_voucher",
  TIER_CALC_REMOVE_POINTS: "tier_calc_remove_points",
  TIER_CALC_TIER_DOWNGRADE: "tier_calc_tier_downgrade",
  GROUP_STATE: "group_state",
  CONTACT_STATE: "contact_state",
  GROUP_TIER: "group_tier",
  GROUP_STATUS_POINTS: "group_status_points",
  MIGTARION_POINTS: "migration_points"
};

export const POINTS_TYPE_ENUM = {
  LOYALTY: "loyalty",
  POINTS_TO_SPEND: "pointsToSpend",
  STATUS: "status",
  CASHBACK: "cashback"
};

export const ACTIVITY_ELEMENT_POINTS_TYPE_TEXT = {
  loyalty: getMessage("activityElementPointsText.loyalty"),
  pointsToSpend: getMessage("activityElementPointsText.pointsToSpend"),
  status: getMessage("activityElementPointsText.status"),
  cashback: getMessage("activityElementPointsText.cashback"),
  cashbackName: getMessage("activityElementPointsText.cashback.name")
};

export const ACTIVITY_ELEMENT_MULTIPLY_POINTS_TEXT = {
  double: getMessage("activityElementMultiuplyPointsText.double"),
  triple: getMessage("activityElementMultiuplyPointsText.triple")
};

export const ACTIVITY_ELEMENT_LABEL = {
  JOINED: "joined",
  LEFT: "left",
  NO_CHANGE: "no_change",
  ADDED: "added",
  REMOVED: "removed"
};

export const EMPTY_CELL_TEXT = "-";

export const VOUCHER_TYPE = {
  ONE_TIME: "one_time",
  MULTI_USE: "yearly"
};

export const VOUCHER_TYPE_TEXT = {
  ONE_TIME: getMessage("voucherTypeText.one_time"),
  MULTI_USE: getMessage("voucherTypeText.yearly")
};

export const EXCLUSIVE_ACCESS_TYPE_TEXT = {
  PERMANENT: getMessage("exclusiveAccessTypeText.permanent"),
  LIMITED: getMessage("exclusiveAccessTypeText.limitedTerm")
};

export const ACTIVITY_PAGE_SIZE_OPTIONS = [10, 25, 50, 100];
