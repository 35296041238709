import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import deepEqual from "deep-equal";

import LoaderOverlay from "../components/LoaderOverlay";
import Notification from "../components/Notification";
import InputField from "../components/InputField";
import RadioButtonList from "../components/RadioButtonList";

import { updateAccountSettingsPlans, deletePlan } from "../actions/planActions";
import { showErrorAlert, hideErrorAlert } from "../actions/alertActions";

import { selectCustomerPlans, isAccountViewOnly } from "../reducers";

import { PLAN_TYPE_OPTIONS } from "../constants/planConstants";

import { getValueByType } from "../helpers/formHelper";
import { openDeleteDialog } from "../helpers/dialogHelper";
import accountSettingsValidator from "../validators/accountSettingsValidator";

import planApi from "../api/planApi";
import { getErrorMessage, validateSingleValue } from "../validators";
import sessionHelper from "../helpers/sessionHelper";
import { getEmarsysSupportAnchor } from "../helpers/envHelper";
import { getMessage } from "../messages";
import { ACCOUNT_SETTINGS_HELP_LINK } from "../constants/linkConstants";

class AccountSettings extends Component {
  constructor(props) {
    super(props);

    this.validator = new accountSettingsValidator();

    this.state = {
      planDataArr: [],
      initialPlanDataArr: [],
      isPlanLaunchedMap: {},
      loading: false,
      errorsArr: []
    };
  }

  get currentPlanIds() {
    return [...this.props.planIds, ...this.state.planDataArr.map((planData) => planData.planId)];
  }

  componentDidMount() {
    this.props.registerSubmitFunc && this.props.registerSubmitFunc(this.onSubmit.bind(this));
    this.props.registerValidateFunc && this.props.registerValidateFunc(this.validate.bind(this));

    if (!this.props.planIds) {
      this.onAddNewPlan();
    } else {
      this.loadData();
    }
  }

  componentDidUpdate(prevProps) {
    if (!deepEqual(prevProps.planIds, this.props.planIds)) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    this.props.registerSubmitFunc && this.props.registerSubmitFunc(null);
    this.props.registerValidateFunc && this.props.registerValidateFunc(null);
  }

  loadData() {
    let planDataArr = [];
    let isPlanLaunchedMap = {};
    this.setState({
      loading: true
    });
    planApi
      .getAllPlans()
      .then((plans) => {
        this.props.planIds.forEach((planId) => {
          const plan = plans.find((p) => p.id === planId);
          planDataArr.push({
            planId,
            planName: plan.planSettings.name,
            planType: plan.planType
          });
          isPlanLaunchedMap[planId] = !!plan.planLaunch;
        });
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });

    this.setState({
      planDataArr,
      isPlanLaunchedMap,
      initialPlanDataArr: planDataArr
    });
  }

  onInputChange(event, index) {
    const target = event.target;
    const name = target.getAttribute("name");
    let value = getValueByType(target);
    const propName = name.split("_")[0];

    let planDataArr = [...this.state.planDataArr];
    let errorsArr = [...this.state.errorsArr];

    planDataArr[index][propName] = value;

    const errors = validateSingleValue(
      () => this.validateSinglePlan(planDataArr[index], index),
      errorsArr[index],
      propName
    );

    errorsArr[index] = errors;

    if (this.isErrorsArrEmpty(errorsArr)) {
      this.props.hideErrorAlert();
    }

    this.setState({
      planDataArr,
      errorsArr
    });
  }

  onAddNewPlan() {
    let planDataArr = [...this.state.planDataArr];
    planDataArr.push({
      planId: "",
      planName: "",
      planType: ""
    });

    this.setState({
      planDataArr
    });
  }

  onDelete(index) {
    const planDataArr = [...this.state.planDataArr];
    const { planIds } = this.props;

    const onDeletePlan = () => {
      sessionHelper.setPlanId(planIds[0]);
      this.props.deletePlan(planDataArr[index].planId);
    };

    //if plan already created
    if (planIds && planIds[index] === planDataArr[index].planId) {
      openDeleteDialog(
        getMessage("accountSettings.planDeleteDialog.header"),
        getMessage("accountSettings.planDeleteDialog.content"),
        onDeletePlan
      );
    } else {
      planDataArr.splice(index, 1);
      this.setState({
        planDataArr
      });
    }
  }

  onSubmit() {
    const isValid = this.validate();
    if (isValid) {
      this.props.updateAccountSettingsPlans({ data: this.state.planDataArr });
    } else {
      this.props.showErrorAlert(getMessage("form.missingSettingsAlert"));
    }
  }

  onCancel() {
    const planDataArr = [...this.state.initialPlanDataArr];
    this.setState({
      planDataArr
    });
  }

  validate() {
    const { planDataArr, errorsArr } = { ...this.state };
    planDataArr.forEach((planData, index) => {
      errorsArr[index] = this.validateSinglePlan(planData, index);
    });

    this.setState({ errorsArr });

    return this.isErrorsArrEmpty(errorsArr);
  }

  validateSinglePlan(planData, index) {
    const usedPlanIds = this.state.planDataArr
      .filter((p, i) => {
        return p.planId !== planData.planId || i !== index;
      })
      .map((p) => p.planId);

    return this.validator.validatePlanData(planData, usedPlanIds);
  }

  isErrorsArrEmpty(errorsArr) {
    return !errorsArr.some((errors) => !!errors);
  }

  disablePlanType(planData, index) {
    var options = [...PLAN_TYPE_OPTIONS];
    const { planIds } = { ...this.props };
    if (planIds?.[index] === planData.planId) {
      var result = options.map((element) => {
        let target = { ...element };
        target.disabled = true;
        return target;
      });
      options = [...result];
    }
    return options;
  }

  renderFields(planData, index) {
    let deleteBtnStyle = {};
    if (this.state.isPlanLaunchedMap[planData.planId] || index === 0) {
      deleteBtnStyle.visibility = "hidden";
    }

    const errors = this.state.errorsArr[index];
    const disablePlanIdField = this.props.planIds && this.props.planIds[index] === planData.planId;

    return (
      <Fragment key={index}>
        <InputField
          label={getMessage("accountSettings.input.planName.label")}
          tooltip={getMessage("accountSettings.input.planName.tooltip")}
          name={`planName_${index}`}
          value={planData.planName}
          onChange={(event) => this.onInputChange(event, index)}
          errorMsg={getErrorMessage(errors, "planName")}
          disabled={this.props.isAccountViewOnly}
          postfixButtons={
            <a className="e-btn e-btn-onlyicon no-border" onClick={() => this.onDelete(index)} style={deleteBtnStyle}>
              <e-icon icon="trash-o" size="small" />
            </a>
          }
          isRequired={true}
        />
        <InputField
          label={getMessage("accountSettings.input.planIdentifier.label")}
          name={`planId_${index}`}
          value={planData.planId}
          onChange={(event) => this.onInputChange(event, index)}
          errorMsg={disablePlanIdField ? "" : getErrorMessage(errors, "planId")}
          disabled={disablePlanIdField}
          postfixButtons={<a className="e-btn e-btn-onlyicon no-border" style={{ visibility: "hidden" }} />}
          tooltip={getMessage("accountSettings.input.planIdentifier.tooltip")}
          isRequired={true}
        />
        <RadioButtonList
          label={getMessage("accountSettings.input.planType.label")}
          name={`planType_${index}`}
          value={planData.planType}
          options={this.disablePlanType(planData, index)}
          onChange={(event) => this.onInputChange(event, index)}
          errorMsg={getErrorMessage(errors, "planType")}
          disabled={false}
          tooltip={getMessage("accountSettings.input.planType.tooltip")}
        />
      </Fragment>
    );
  }

  renderFooterButtons() {
    let attr = {};
    if (this.isAccountViewOnly) {
      attr["disabled"] = true;
    }

    return (
      <div className="e-buttongroup">
        <button type="button" className="e-btn" onClick={this.onCancel.bind(this)} {...attr}>
          {getMessage("cancelBtn.label")}
        </button>
        <button className="e-btn e-btn-primary" type="submit" onClick={this.onSubmit.bind(this)} {...attr}>
          {getMessage("saveBtn.label")}
        </button>
      </div>
    );
  }

  renderAddNewPlanButton() {
    let attr = {};

    const { fromOnboarding } = this.props;

    if (fromOnboarding) {
      return null;
    }

    let tooltipContent = getMessage("accountSettings.btn.AddNewPlan.tooltip");

    if (this.props.isAccountViewOnly) {
      attr.disabled = true;
      tooltipContent = getMessage("accountSettings.btn.AddNewPlan.disabled.tooltip", {
        text: getEmarsysSupportAnchor("Emarsys Support.")
      });
    }

    return (
      <e-tooltip class="e-btn-fullwidth" content={tooltipContent} has-action>
        <button type="button" className="e-btn e-btn-fullwidth" onClick={this.onAddNewPlan.bind(this)} {...attr}>
          {getMessage("addNewPlanBtn.label")}
        </button>
      </e-tooltip>
    );
  }

  renderNotification() {
    const { fromOnboarding, planIds } = this.props;
    const messageId =
      fromOnboarding && !!planIds && planIds.length < 2
        ? "accountSettings.notification.content.firstPlan"
        : "accountSettings.notification.content";

    const content = getMessage(messageId, {
      learnMoreLink: (
        <a target="_blank" href={ACCOUNT_SETTINGS_HELP_LINK}>
          {getMessage("accountSettings.notification.content.learnMoreLinkText")}
        </a>
      )
    });
    return <Notification content={content} />;
  }

  render() {
    return (
      <Fragment>
        <LoaderOverlay show={this.state.loading} />
        <div className="box-padding">
          {this.renderNotification()}
          <div className="e-row">
            <div className="e-col-6">
              <h2>
                {getMessage("accountSettings.header.text")}
                <e-tooltip content={getMessage("accountSettings.header.tooltip")} type="helper" />
              </h2>
              {this.state.planDataArr.map((planData, index) => {
                return this.renderFields(planData, index);
              })}
              <div className="e-row">
                <div className="e-col-4 m-b-30">{this.renderAddNewPlanButton()}</div>
              </div>
            </div>
          </div>
          {this.props.fromOnboarding ? null : (
            <Fragment>
              <hr className="e-separator e-separator-fullwidth" />
              {this.renderFooterButtons()}
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  planIds: selectCustomerPlans(state),
  isAccountViewOnly: isAccountViewOnly(state)
});

const mapDispatchToProps = (dispatch) => ({
  updateAccountSettingsPlans: ({ data }) => {
    dispatch(updateAccountSettingsPlans({ data }));
  },
  deletePlan: (planId) => {
    dispatch(deletePlan(planId));
  },
  showErrorAlert: (message) => {
    dispatch(showErrorAlert(message, undefined, true));
  },
  hideErrorAlert: () => {
    dispatch(hideErrorAlert());
  }
});

AccountSettings.propTypes = {
  registerSubmitFunc: PropTypes.func,
  registerValidateFunc: PropTypes.func,
  fromOnboarding: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
