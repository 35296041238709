import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import sanitizeHtml from "sanitize-html";

import { ALERT_STATUS } from "../constants/alertContants";

const Alert = (props) => {
  const { message, status } = props;

  const getStatusIcon = (status) => {
    switch (status) {
      case ALERT_STATUS.SUCCESS:
        return <e-icon icon="e-check-circle" />;
      case ALERT_STATUS.ERROR:
        return <e-icon icon="warning" />;
      case ALERT_STATUS.WARNING:
      case ALERT_STATUS.INFO:
        return <e-icon icon="e-info-circle" />;
      default:
        return null;
    }
  };

  const getStatusClassName = (status) => {
    switch (status) {
      case ALERT_STATUS.SUCCESS:
        return "e-alert-success";
      case ALERT_STATUS.ERROR:
        return "e-alert-danger";
      case ALERT_STATUS.WARNING:
        return "e-alert-warning";
      case ALERT_STATUS.INFO:
        return "e-alert-info";
      default:
        return "";
    }
  };

  return (
    <div
      className={classNames("e-alert e-alert-withicon", getStatusClassName(status), {
        multiline: props.isMultiline
      })}
    >
      <span className="e-alert__icon">{getStatusIcon(status)}</span>
      {typeof message == "string" ? (
        <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(message) }} />
      ) : (
        <span>{message}</span>
      )}
    </div>
  );
};

Alert.propTypes = {
  message: PropTypes.oneOf[(PropTypes.string.isRequired, PropTypes.element.isRequired)],
  status: PropTypes.string.isRequired,
  isMultiline: PropTypes.bool
};

export default Alert;
