import React, { Fragment } from "react";
import PropTypes from "prop-types";

import InputCurrencyField from "../../components/InputCurrencyField";
import Alert from "../../components/Alert";
import { getErrorMessage } from "../../validators";
import { getMultiCurrencyFieldLabel } from "../../helpers/currencyHelper";
import { ALERT_STATUS } from "../../constants/alertContants";
import { getMessage } from "../../messages";

const MultiCurrencyMinOrderFields = ({ onChange, currency, translations, disabled, errors, index }) => {
  return (
    <Fragment>
      {index === 0 && !!errors && (
        <Alert message={getMessage("multiCurrencyMinOrderFields.alert.message")} status={ALERT_STATUS.ERROR} />
      )}
      <InputCurrencyField
        label={getMultiCurrencyFieldLabel({
          label: getMessage("multiCurrencyMinOrderFields.minOrderMC.label"),
          currency
        })}
        currency={currency}
        value={translations.minOrderMC[currency]}
        name={"minOrderMC"}
        onChange={(event, customValue) => onChange(event, currency, customValue)}
        allowDecimal={true}
        disabled={disabled}
        errorMsg={getErrorMessage(errors, `minOrderMC.${currency}`)}
      />
      {translations.minOrderDisplayMC && (
        <InputCurrencyField
          label={getMultiCurrencyFieldLabel({
            label: getMessage("multiCurrencyMinOrderFields.minOrderDisplayMC.label"),
            currency
          })}
          currency={currency}
          value={translations.minOrderDisplayMC[currency]}
          name={"minOrderDisplayMC"}
          onChange={(event, customValue) => onChange(event, currency, customValue)}
          allowDecimal={true}
          disabled={disabled}
        />
      )}
    </Fragment>
  );
};

MultiCurrencyMinOrderFields.propTypes = {
  onChange: PropTypes.func,
  lang: PropTypes.string,
  translations: PropTypes.object,
  disabled: PropTypes.bool,
  errors: PropTypes.object
};

export default MultiCurrencyMinOrderFields;
