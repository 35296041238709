export const generalEnLegacy = {
  "catalogSelector.selected.single": "{items} selected",
  "catalogSelector.selected.multiple": "{items} and {lastItems} selected",
  "iconGallery.uploadResult.failMessage":
    "Something went wrong during the upload. Be sure the files meet the requirements. \n{showFileList,select,true{{failed} out of {total} failed: {failedDetails}} other{{failedDetails}}} ",
  "pointsRatesFields.perDescriptor.spend": "for every",
  "pointsRatesFields.perDescriptor.spend": "for every",
  "activityElementMultiuplyPointsText.double": "Double",
  "activityElementMultiuplyPointsText.triple": "Triple"
};
