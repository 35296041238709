import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Notification from "../../components/Notification";
import SelectField from "../../components/SelectFieldV2";

import ActionSetupLoyaltyMessage from "./ActionSetupLoyaltyMessage";
import ActionSetupRealtimeNotification from "./ActionSetupRealtimeNotification";

import ActionSetupLoyaltyMessagePreviewEmail from "./ActionSetupLoyaltyMessagePreviewEmail";
import ActionSetupLoyaltyMessagePreviewWeb from "./ActionSetupLoyaltyMessagePreviewWeb";
import ActionSetupNotificationPreviewWeb from "./ActionSetupNotificationPreviewWeb";

import {
  selectRewardsById,
  selectPlanLanguages,
  selectPlanHasMultiLang,
  selectActionRewardTiers
} from "../../reducers";

import { actionType } from "../../types";
import actionService from "../../services/actionService";

import { getLanguageOptions } from "../../helpers/multiLangHelper";
import { getMessage } from "../../messages";

class ActionSetupContentCreation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeEditTab: this.EDIT_TAB_ENUM.MESSAGE,
      activePreviewTab: props.previewWalletDefault ? this.PREVIEW_TAB_ENUM.WALLET : this.PREVIEW_TAB_ENUM.BLOCKS
    };

    if (props.hasMultiLang) {
      this.state.selectedLang = this.props.languages[0];
    }

    this.isDefault = props.action.isDefault;
  }

  get EDIT_TAB_ENUM() {
    return {
      MESSAGE: "message",
      REALTIME_NOTIFICATION: "realtimeNotification"
    };
  }

  get PREVIEW_TAB_ENUM() {
    return {
      BLOCKS: "blocks",
      CONTENT: "content",
      WALLET: "wallet"
    };
  }

  isContentPreviewMode() {
    return this.state.activePreviewTab === this.PREVIEW_TAB_ENUM.CONTENT;
  }

  renderEditSideNotification() {
    if (actionService.isEventAction(this.props.action.masterAction) && !this.props.action.isDefault) {
      return <Notification content={getMessage("actionSetupContentCreation.editSide.notification.contnet")} />;
    }

    return null;
  }

  getPreviewSideTopMargin() {
    if (actionService.isEventAction(this.props.action.masterAction) && !this.props.action.isDefault) {
      return 75;
    }

    return null;
  }

  renderEditSideContent() {
    if (this.state.activeEditTab === this.EDIT_TAB_ENUM.MESSAGE) {
      return <ActionSetupLoyaltyMessage {...this.props} notificationElem={this.renderEditSideNotification()} />;
    } else if (this.state.activeEditTab === this.EDIT_TAB_ENUM.REALTIME_NOTIFICATION) {
      return <ActionSetupRealtimeNotification {...this.props} />;
    }

    return null;
  }

  renderEditSideTitle() {
    if (this.state.activeEditTab === this.EDIT_TAB_ENUM.MESSAGE) {
      return this.isDefault
        ? getMessage("actionSetupContentCreation.editSide.title.text.default")
        : getMessage("actionSetupContentCreation.editSide.title.text.loyalty");
    } else if (this.state.activeEditTab === this.EDIT_TAB_ENUM.REALTIME_NOTIFICATION) {
      return getMessage("actionSetupContentCreation.editSide.title.text.realtime");
    }

    return null;
  }

  renderPreviewTab(tab, icon, tooltip) {
    if (this.isDefault && tab !== this.PREVIEW_TAB_ENUM.BLOCKS && tab !== this.PREVIEW_TAB_ENUM.CONTENT) {
      return null;
    }

    if (this.props.isJoinAction && tab === this.PREVIEW_TAB_ENUM.WALLET) {
      return null;
    }

    return (
      <div
        onClick={() => this.onPreviewSideChange(tab)}
        className={`e-btn e-btn-onlyicon ${this.getActivePreviewStatus(tab)}`}
      >
        <e-tooltip placement="bottom" content={tooltip}>
          <e-icon icon={icon} />
        </e-tooltip>
      </div>
    );
  }

  onEditSideTabChange(tab) {
    this.setState({
      activeEditTab: tab,
      activePreviewTab:
        tab == this.EDIT_TAB_ENUM.REALTIME_NOTIFICATION ? this.PREVIEW_TAB_ENUM.WALLET : this.PREVIEW_TAB_ENUM.CONTENT
    });
  }

  getEditTabStatus(tab) {
    if (this.state.activeEditTab == tab) {
      return "active";
    }

    return "";
  }

  onPreviewSideChange(tab) {
    this.setState({
      activePreviewTab: tab
    });
  }

  getActivePreviewStatus(tab) {
    if (this.state.activeEditTab == this.EDIT_TAB_ENUM.REALTIME_NOTIFICATION && tab !== this.PREVIEW_TAB_ENUM.WALLET) {
      return "e-btn-disabled";
    } else if (this.state.activePreviewTab === tab) {
      return "e-btn-active";
    }

    return "";
  }

  replaceRewardToken = (text, rewardTier) => {
    const { rewardToken } = this.props;

    let rewardText = rewardTier ? rewardTier.rewardsText : rewardToken.display;

    if (rewardTier && this.props.hasMultiLang && this.state.selectedLang) {
      rewardText = (!!rewardTier.rewardsTextML && rewardTier.rewardsTextML[this.state.selectedLang]) || rewardText;
    }

    return text.replace(
      rewardToken.token,
      this.isContentPreviewMode() ? `<span class="text-color-primary">${rewardText}</span>` : rewardText
    );
  };

  renderPreviewContent = () => {
    const { activePreviewTab } = this.state;
    const { action } = this.props;

    if (this.state.activeEditTab == this.EDIT_TAB_ENUM.REALTIME_NOTIFICATION) {
      return (
        <ActionSetupNotificationPreviewWeb
          message={action.content.member.blockMessage}
          ctaButton={action.content.member.ctaButton}
          replaceRewardToken={this.replaceRewardToken.bind(this)}
          showClose={true}
        />
      );
    } else {
      switch (activePreviewTab) {
        case this.PREVIEW_TAB_ENUM.BLOCKS:
        case this.PREVIEW_TAB_ENUM.CONTENT: {
          return (
            <ActionSetupLoyaltyMessagePreviewEmail
              contentMember={action.content.member}
              contentNonMember={action.content.nonMember}
              isForNewMembers={actionService.isForNewMembers(action)}
              replaceRewardToken={this.replaceRewardToken.bind(this)}
              selectedLang={this.state.selectedLang}
              rewardTiers={this.isDefault && !this.props.isJoinAction ? null : this.props.rewardTiers}
              getTierNameById={this.props.getTierNameById}
              contentMode={activePreviewTab === this.PREVIEW_TAB_ENUM.CONTENT}
              renderMember={this.props.renderMember}
              renderNonMember={this.props.renderNonMember}
              renderDefault={this.isDefault && !this.props.isJoinAction}
              defaultMemberContent={this.props.defaultMemberContent}
              defaultJoinAction={this.props.defaultJoinAction}
              masterAction={action.masterAction}
              marginTop={this.getPreviewSideTopMargin()}
            />
          );
        }
        case this.PREVIEW_TAB_ENUM.WALLET: {
          return (
            <ActionSetupLoyaltyMessagePreviewWeb
              contentMember={this.props.action.content.member}
              replaceRewardToken={this.replaceRewardToken.bind(this)}
            />
          );
        }
      }
    }
  };

  getEditSideTitleTooltip() {
    const { action, isJoinAction } = this.props;

    if (action.isDefault) {
      return isJoinAction
        ? getMessage("actionSetupContentCreation.editSide.title.tooltip.join")
        : getMessage("actionSetupContentCreation.editSide.title.tooltip.default");
    }

    if (actionService.isEventAction(action.masterAction)) {
      return getMessage("actionSetupContentCreation.editSide.title.tooltip.event");
    }

    return getMessage("actionSetupContentCreation.editSide.title.tooltip.other");
  }

  render() {
    const { activePreviewTab } = this.state;

    const editSideTitleTooltip = this.getEditSideTitleTooltip();

    const previewSideTitleTooltip =
      activePreviewTab === this.PREVIEW_TAB_ENUM.CONTENT
        ? getMessage("actionSetupContentCreation.previewSideTitleTooltip.conent")
        : getMessage("actionSetupContentCreation.previewSideTitleTooltip.tokens");

    return (
      <main className="e-layout__content">
        <section className="e-layout__section">
          <div className="e-verticalnav e-verticalnav-fullheight">
            <e-verticalnav-menu id="verticalnav-unique-id" no-toggle="no-toggle">
              <e-verticalnav-item
                icon="e-tag-add"
                value={getMessage("actionSetupContentCreation.nav.loyaltyMessage")}
                status={this.getEditTabStatus(this.EDIT_TAB_ENUM.MESSAGE)}
                onClick={() => {
                  this.onEditSideTabChange(this.EDIT_TAB_ENUM.MESSAGE);
                }}
              />

              {this.isDefault || this.props.isJoinAction ? null : (
                <e-verticalnav-item
                  class="e-hidden"
                  icon="e-device-selector"
                  value={getMessage("actionSetupContentCreation.nav.realtimeMessage")}
                  status={this.getEditTabStatus(this.EDIT_TAB_ENUM.REALTIME_NOTIFICATION)}
                  onClick={() => {
                    this.onEditSideTabChange(this.EDIT_TAB_ENUM.REALTIME_NOTIFICATION);
                  }}
                />
              )}
            </e-verticalnav-menu>

            <div className="e-verticalnav__content e-verticalnav__content-nopadding e-section">
              <div className="e-section__header">
                <div className="e-section__title">
                  <span>{this.renderEditSideTitle()}</span>
                  <e-tooltip
                    content={editSideTitleTooltip}
                    type="helper"
                    style={{ marginTop: "2px", marginLeft: "6px" }}
                  />
                </div>
              </div>
              <div className="e-section__content">{this.renderEditSideContent()}</div>
            </div>
          </div>
        </section>

        <section className="e-layout__section">
          <div className="e-section">
            <div className="e-section__header">
              <div className="e-section__title">
                {getMessage("actionSetupContentCreation.preview.title")}
                <e-tooltip
                  content={previewSideTitleTooltip}
                  type="helper"
                  style={{ marginTop: "2px", marginLeft: "6px" }}
                />
              </div>
              <div className="e-section__actions">
                <div className="e-buttongroup e-buttongroup-sticky float-left">
                  {this.renderPreviewTab(
                    this.PREVIEW_TAB_ENUM.BLOCKS,
                    "bolt",
                    getMessage("actionSetupContentCreation.previewTabs.tooltip.message")
                  )}
                  {this.renderPreviewTab(
                    this.PREVIEW_TAB_ENUM.CONTENT,
                    "e-blocks",
                    getMessage("actionSetupContentCreation.previewTabs.tooltip.tiers")
                  )}
                  {this.renderPreviewTab(
                    this.PREVIEW_TAB_ENUM.WALLET,
                    "e-device-selector",
                    getMessage("actionSetupContentCreation.previewTabs.tooltip.wallet")
                  )}
                </div>
                {this.props.hasMultiLang && (
                  <div className="m-l-10">
                    <SelectField
                      options={getLanguageOptions({ languages: this.props.languages })}
                      value={this.state.selectedLang}
                      onChange={(event) => {
                        this.setState({
                          selectedLang: event.target.value
                        });
                      }}
                      extraClassName="no-margin"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="e-section__content">{this.renderPreviewContent()}</div>
          </div>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const actionId = ownProps.action.id;
  return {
    rewardsById: selectRewardsById(state),
    languages: selectPlanLanguages(state),
    hasMultiLang: selectPlanHasMultiLang(state),
    rewardTiers: selectActionRewardTiers(state, actionId)
  };
};

ActionSetupContentCreation.propTypes = {
  action: actionType.isRequired,
  defaultJoinAction: actionType,
  getTierNameById: PropTypes.func,
  onInputChange: PropTypes.func.isRequired,
  renderMember: PropTypes.bool,
  renderNonMember: PropTypes.bool,
  errors: PropTypes.object,
  viewMode: PropTypes.bool,
  rewardToken: PropTypes.object,
  defaultMemberContent: PropTypes.object,
  previewWalletDefault: PropTypes.bool,
  isJoinAction: PropTypes.bool,
  hasRewardForNonMember: PropTypes.bool,
  onShowMemberMultiLang: PropTypes.func,
  onShowNonMemberMultiLang: PropTypes.func,
  hasMultiLanguage: PropTypes.bool
};

export default connect(mapStateToProps, null)(ActionSetupContentCreation);
