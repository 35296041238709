import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import InputNumberField from "../components/InputNumberField";
import CheckboxField from "../components/CheckboxField";
import { getMessage } from "../messages";

const TimeValidityInput = (props) => {
  const onRadioButtonChange = (event) => {
    props.changeStateFunc(`${props.name}.duration`, "");
    props.onChange(event);
  };

  const onDisableCheckboxChange = (event) => {
    props.changeStateFunc(`${props.name}.duration`, undefined);
    props.onChange(event, !props.validity.disabled);
  };

  const renderSingleValidityInput = (radioLabel, type, props) => {
    const id = type;
    let duration = undefined;
    let attr = {};
    if (props.validity.type === type && !props.validity.disabled) {
      duration = props.validity.duration;
    } else {
      attr["disabled"] = true;
    }

    const errorMsg = props.errorMsg && props.validity.type === type ? props.errorMsg : "";

    let attrRadio = {};

    if (props.disabled || props.validity.disabled) {
      attrRadio["disabled"] = true;
      attr["disabled"] = true;
    }

    return (
      <div className="e-col-4">
        <input
          className="e-radio"
          type="radio"
          name={`${props.name}.type`}
          value={type}
          id={`${props.name}_${type}`}
          onChange={onRadioButtonChange}
          checked={props.validity.type === type && !props.validity.disabled}
          {...attrRadio}
        />
        <label htmlFor={`${props.name}_${type}`}>{radioLabel}</label>
        <div className="m-t-5">
          <InputNumberField
            value={duration}
            name={`${props.name}.duration`}
            onChange={props.onChange}
            errorMsg={errorMsg}
            {...attr}
          />
        </div>
      </div>
    );
  };

  const renderDisableCheckbox = () => {
    if (!props.withDisableCheckbox) {
      return null;
    }

    let attr = {};
    if (props.disabled) {
      attr["disabled"] = true;
    }

    return (
      <span className="m-r-5">
        <CheckboxField
          renderOnlyCheckbox={true}
          className="e-checkbox-onlycheckbox"
          name={`${props.name}.disabled`}
          checked={!props.validity.disabled}
          onChange={onDisableCheckboxChange}
          {...attr}
        />
      </span>
    );
  };

  const labelClassNames = classNames("e-field__label", "e-col-12", {
    "e-field__label-required": props.isRequired,
    "e-field__label-inline": props.inline
  });

  return (
    <div className="e-row">
      <div className="e-field">
        <label className={labelClassNames}>
          {renderDisableCheckbox()}
          {props.label}
          {props.tooltip ? <e-tooltip content={props.tooltip} type="helper" /> : null}
        </label>
        {renderSingleValidityInput(getMessage("timeValidityInput.radioLabel.days"), "days", props)}
        {renderSingleValidityInput(getMessage("timeValidityInput.radioLabel.weeks"), "weeks", props)}
        {renderSingleValidityInput(getMessage("timeValidityInput.radioLabel.months"), "months", props)}
      </div>
    </div>
  );
};

TimeValidityInput.propTypes = {
  label: PropTypes.string,
  tooltip: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  changeStateFunc: PropTypes.func,
  validity: PropTypes.shape({
    type: PropTypes.string,
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool
  }),
  withDisableCheckbox: PropTypes.bool,
  errorMsg: PropTypes.string,
  disabled: PropTypes.bool
};

export default TimeValidityInput;
