import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment";

import SelectField from "../../components/SelectFieldV2";
import InputNumberField from "../../components/InputNumberField";
import CheckboxField from "../../components/CheckboxField";
import MultiLangPopupOpener from "../../components/MultiLangPopupOpener";
import { DateField, DateTimeField, DateTimeRangeField } from "../../components/DateFields";
import { TimeField } from "../../components/TimeFields";

import actionService from "../../services/actionService";

import {
  VALIDITY_TYPE_OPTIONS,
  VALIDITY_TYPE_ENUM,
  SCHEDULING_RECURRENCE_OPTIONS,
  SCHEDULING_RECURRENCE_FREQUENCY_OPTIONS,
  SCHEDULING_RECURRENCE_FREQUENCY_ENUM,
  SCHEDULING_RECURRENCE_MONTHLY_OPTIONS_ENUM,
  SCHEDULING_RECURRENCE_MONTHLY_WEEK_OPTIONS
} from "../../constants/actionConstants";

import { getErrorMessage } from "../../validators";

import { selectPlanHasMultiLang } from "../../reducers";

import { actionType } from "../../types";
import { getMessage } from "../../messages";
import ButtonGrid from "../../components/ButtonGrid";
import RadioButtonList from "../../components/RadioButtonList";
import ActionSetupSchedulingSummary from "./ActionSetupSchedulingSummary";

const ActionSetupScheduling = (props) => {
  const {
    action,
    initialAction,
    onInputChange,
    onInputError,
    viewMode,
    showVoucherObjective,
    showRewardsAllocationDelay,
    disableRewardsAllocationDelay,
    isPromotionDisplayedInWallet,
    notificationElem,
    onValidityTypeChange,
    onReferralCtaChange,
    errors,
    loadingBroadcastEmailOptions,
    broadcastEmailOptions,
    onShowEmailCampaignIdMultiLang,
    disableActivate,
    onRecurringTypeChange,
    onRecurringIntervalChange,
    onRecurringDaysOfChange,
    onRecurringMonthlySelectionTypeChange,
    onRecurringTimeChange,
    onRecurringIsWholeDayChange,
    isActionActive
  } = props;

  const weekdaysButtons = () => {
    let weekArr = [];
    for (let i = 1; i <= 7; i++) {
      weekArr.push({
        name: `scheduling.recurring.weekdays.${i}`,
        label: getMessage(`actionSetupScheduling.recurring.monthly.weekday.short.${i}`),
        value: i
      });
    }

    return weekArr.map((day) => {
      return {
        ...day,
        active: action.scheduling.recurring.weekdays.includes(day.value)
      };
    });
  };

  const getMonthdaysTooltip = (day) => {
    if (day < 28) {
      return undefined;
    }
    const daySelected = action.scheduling.recurring.monthdays.includes(day);
    const lastDayOfMonthSelected = action.scheduling.recurring.isLastMonthDay;
    if (lastDayOfMonthSelected && !daySelected) {
      return getMessage("actionSetupScheduling.recurring.monthly.calendarDates.lastDaysTooltipActive");
    }
    if (lastDayOfMonthSelected && daySelected) {
      return getMessage("actionSetupScheduling.recurring.monthly.calendarDates.lastDaysTooltipActiveSelected");
    }
    if (daySelected && day > 28) {
      return getMessage("actionSetupScheduling.recurring.monthly.calendarDates.lastDaysTooltip");
    }
    return undefined;
  };

  const monthdaysButtons = () => {
    let daysArray = [];
    for (let i = 1; i <= 31; i++) {
      daysArray.push({
        name: `scheduling.recurring.monthdays.${i}`,
        label: `${i}`,
        value: i,
        active: action.scheduling.recurring.monthdays.includes(i),
        tooltip: getMonthdaysTooltip(i),
        style:
          action.scheduling.recurring.isLastMonthDay && !action.scheduling.recurring.monthdays.includes(i) && i >= 28
            ? "e-btn-active"
            : undefined
      });
    }
    return daysArray;
  };
  const hasMultiLang = useSelector((state) => selectPlanHasMultiLang(state));

  const allocationDelayTooltip = disableRewardsAllocationDelay
    ? getMessage("actionSetupScheduling.rewardsAllocationDelay.duration.tooltip.NoDelay")
    : getMessage("actionSetupScheduling.rewardsAllocationDelay.duration.tooltip.withDelay");

  let activationTooltip = "";
  if (isPromotionDisplayedInWallet) {
    activationTooltip = getMessage("actionSetupScheduling.activationTooltip.promotionDispalyedInWallet");
  } else if (actionService.isReferralAction(action.masterAction)) {
    activationTooltip = getMessage("actionSetupScheduling.activationTooltip.referral");
  } else {
    activationTooltip = getMessage("actionSetupScheduling.activationTooltip.default");
  }

  const getRecurringRepeatOptions = () => {
    let repeatLimit = 31;
    if (action.scheduling.recurring.recurrenceRepeatInterval == SCHEDULING_RECURRENCE_FREQUENCY_ENUM.WEEKLY) {
      repeatLimit = 52;
    }
    if (action.scheduling.recurring.recurrenceRepeatInterval == SCHEDULING_RECURRENCE_FREQUENCY_ENUM.MONTHLY) {
      repeatLimit = 12;
    }
    let recurringRepeatOptions = [];

    for (let i = 1; i <= repeatLimit; i++) {
      let option = { name: i, value: i };
      if (i == action.scheduling.recurring.repeat) option["selected"] = "true";
      recurringRepeatOptions.push(option);
    }
    return recurringRepeatOptions;
  };

  const getRecurringIntervalOptions = () => {
    let recurringIntervalOptions = SCHEDULING_RECURRENCE_FREQUENCY_OPTIONS;

    return recurringIntervalOptions.map((opt) => {
      return {
        ...opt,
        selected: action.scheduling.recurring.recurrenceRepeatInterval === opt.value
      };
    });
  };

  const getMonthWeekOptions = () => {
    let monthWeekOptions = SCHEDULING_RECURRENCE_MONTHLY_WEEK_OPTIONS;

    return monthWeekOptions.map((opt) => {
      return {
        ...opt,
        selected: action.scheduling.recurring.monthWeek === opt.value
      };
    });
  };

  const onGridButtonClickedHahndler = (event, gridName, value) => {
    onRecurringDaysOfChange(gridName, value);
  };

  const timeChangeHandler = (event, timeStr) => {
    onRecurringTimeChange(event, parseInt(timeStr.substring(0, 2)) * 60 + parseInt(timeStr.substring(3)));
  };

  const timeIntToStr = (time) => {
    if (!time) return "00:00";
    return ("00" + Math.floor(time / 60).toString()).slice(-2) + ":" + ("00" + (time % 60).toString()).slice(-2);
  };

  const renderWeekdays = (disabled) => {
    return (
      <div className="e-margin-top-s">
        <SelectField
          name="scheduling.recurring.monthWeek"
          value={action.scheduling.recurring.monthWeek}
          options={getMonthWeekOptions()}
          disabled={viewMode || disabled}
          errorMsg={getErrorMessage(errors, "scheduling.recurring.monthWeek")}
          onChange={onInputChange}
          size="small"
          inline={true}
          isNumber={true}
        />

        <ButtonGrid
          items={weekdaysButtons()}
          rowLength="7"
          onClick={onGridButtonClickedHahndler}
          gridName="weekdays"
          inline="true"
          disabled={viewMode || disabled}
          errorMsg={getErrorMessage(errors, "scheduling.recurring.weekdays")}
        ></ButtonGrid>
      </div>
    );
  };

  const renderMonthdays = (disabled) => {
    return (
      <div className="e-margin-top-s">
        <ButtonGrid
          items={monthdaysButtons()}
          rowLength="7"
          onClick={onGridButtonClickedHahndler}
          gridName="monthdays"
          disabled={viewMode || disabled}
          errorMsg={getErrorMessage(errors, "scheduling.recurring.monthdays")}
        ></ButtonGrid>
        <div className="e-margin-top-xs">
          <CheckboxField
            name="scheduling.recurring.isLastMonthDay"
            checked={action.scheduling.recurring.isLastMonthDay}
            onChange={onInputChange}
            label={getMessage("actionSetupScheduling.recurring.monthly.isLastDayCb.label")}
            disabled={viewMode || disabled}
            errorMsg={getErrorMessage(errors, "scheduling.recurring.isLastMonthDay")}
          />
        </div>
      </div>
    );
  };

  const renderTimeRange = () => {
    const splitMessage = getMessage("actionSetupScheduling.recurring.timeRange").split("{hour}");

    return (
      <div>
        <div className="e-grid e-grid-medium">
          <div className="e-cell e-cell-medium">
            <TimeField
              label={splitMessage[0]}
              tooltip={getMessage("actionSetupScheduling.recurring.timeRange.tooltip")}
              name="scheduling.recurring.startHour"
              value={timeIntToStr(action.scheduling.recurring.startHour)}
              onChange={timeChangeHandler}
              disabled={viewMode}
            />
          </div>
          <div className="e-cell e-cell-medium e-padding-left-none">
            <TimeField
              label={splitMessage?.[1]}
              name="scheduling.recurring.endHour"
              value={timeIntToStr(action.scheduling.recurring.endHour)}
              onChange={timeChangeHandler}
              disabled={viewMode}
              errorMsg={getErrorMessage(errors, "scheduling.recurring.endHour")}
            />
          </div>
        </div>
        <div className="e-margin-left-m ">
          <div className="e-margin-left-4xl">
            <CheckboxField
              name="scheduling.recurring.isWholeDay"
              checked={action.scheduling.recurring.isWholeDay}
              onChange={onRecurringIsWholeDayChange}
              label={getMessage("actionSetupScheduling.recurring.wholeDayCb.label")}
              tooltip={getMessage("actionSetupScheduling.recurring.wholeDayCb.tooltip")}
              disabled={viewMode}
              errorMsg={getErrorMessage(errors, "scheduling.recurring.isWholeDay")}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderMonthlyOptions = () => {
    const monthlyRadioListOptions = [
      {
        value: SCHEDULING_RECURRENCE_MONTHLY_OPTIONS_ENUM.MONTH,
        name: getMessage("actionSetupScheduling.recurring.monthly.calendarDates.label"),
        tooltip: getMessage("actionSetupScheduling.recurring.monthly.calendarDates.tooltip"),
        extraElement: renderMonthdays(
          action.scheduling.recurring.monthlySelectionType != SCHEDULING_RECURRENCE_MONTHLY_OPTIONS_ENUM.MONTH
        )
      },
      {
        value: SCHEDULING_RECURRENCE_MONTHLY_OPTIONS_ENUM.WEEK,
        name: getMessage("actionSetupScheduling.recurring.monthly.weekDays.label"),
        tooltip: getMessage("actionSetupScheduling.recurring.monthly.weekDays.tooltip"),
        extraElement: renderWeekdays(
          action.scheduling.recurring.monthlySelectionType != SCHEDULING_RECURRENCE_MONTHLY_OPTIONS_ENUM.WEEK
        )
      }
    ];

    return (
      <div className="e-margin-left-m">
        <div className="e-margin-left-4xl">
          <RadioButtonList
            options={monthlyRadioListOptions}
            name="scheduling.recurring.monthlySelectionType"
            value={action.scheduling.recurring.monthlySelectionType}
            onChange={onRecurringMonthlySelectionTypeChange}
            disabled={viewMode}
            className
          />
        </div>
      </div>
    );
  };

  const renderWeeklyOptions = () => {
    return (
      <div className="e-margin-left-m ">
        <div className="e-margin-left-4xl e-margin-bottom-s">
          <ButtonGrid
            items={weekdaysButtons()}
            rowLength="7"
            onClick={onGridButtonClickedHahndler}
            gridName="weekdays"
            inline="true"
            errorMsg={getErrorMessage(errors, "scheduling.recurring.weekdays")}
          ></ButtonGrid>
        </div>
      </div>
    );
  };

  const renderRecurring = () => {
    let attr = {};

    const date = new Date();
    const dateCopy = new Date(date.getTime());
    dateCopy.setFullYear(date.getFullYear() + 3);
    attr["maxDate"] = dateCopy;

    if (
      initialAction.scheduling.recurring.startDate &&
      moment(initialAction.scheduling.recurring.startDate) < moment.now()
    ) {
      var minDate = new Date();
      minDate.setDate(date.getDate() + 1);
      attr["minDate"] = minDate;
    } else if (action.scheduling.recurring.startDate && moment(action.scheduling.recurring.startDate) < moment.now()) {
      var minDate = new Date(action.scheduling.recurring.startDate);
      minDate.setDate(minDate.getDate() + 1);
      attr["minDate"] = minDate;
    } else {
      var minDate = new Date();
      minDate.setDate(date.getDate() + 1);
      attr["minDate"] = minDate;
    }

    return (
      <Fragment>
        {/*start date*/}
        <DateField
          label={getMessage("actionSetupScheduling.recurring.startDate.label")}
          name="scheduling.recurring.startDate"
          value={action.scheduling.recurring.startDate}
          onChange={onInputChange}
          disabled={viewMode || (isActionActive && moment(initialAction.scheduling.recurring.startDate) < moment.now())}
          errorMsg={getErrorMessage(errors, "scheduling.recurring.startDate")}
          isRequired={true}
          tooltip={getMessage("actionSetupScheduling.recurring.startDate.tooltip")}
          minDate={
            initialAction.scheduling.recurring.startDate &&
            moment(initialAction.scheduling.recurring.startDate) < moment.now()
              ? new Date(initialAction.scheduling.recurring.startDate)
              : new Date()
          }
        />
        <div className="e-field">
          <label className="e-field__label font-weight-bold">
            {getMessage("actionSetupScheduling.recurring.recurrenceSettings.label")}
          </label>
          {/*every count time period*/}{" "}
          <div>
            <SelectField
              name="scheduling.recurring.repeat"
              value={action.scheduling.recurring.repeat}
              labelClassName="e-margin-right-m"
              label={getMessage("actionSetupScheduling.recurring.repeatInterval.label")}
              tooltip={getMessage("actionSetupScheduling.recurring.repeatInterval.tooltip")}
              options={getRecurringRepeatOptions()}
              disabled={viewMode}
              errorMsg={getErrorMessage(errors, "scheduling.recurring.repeat")}
              onChange={onInputChange}
              size="small"
              inline={true}
              searchHidden={true}
              isNumber={true}
            />
            <SelectField
              name="scheduling.recurring.recurrenceRepeatInterval"
              value={action.scheduling.recurring.recurrenceRepeatInterval}
              options={getRecurringIntervalOptions()}
              disabled={viewMode}
              errorMsg={getErrorMessage(errors, "scheduling.recurring.recurrenceRepeatInterval")}
              onChange={onRecurringIntervalChange}
              size="medium"
              inline={true}
            />
          </div>
          {/* cunter drop list or int box ? same counter for all or different? day(s) or daily/week(s)/monthly? */}
          {action.scheduling.recurring.recurrenceRepeatInterval == SCHEDULING_RECURRENCE_FREQUENCY_ENUM.MONTHLY &&
            renderMonthlyOptions()}
          {action.scheduling.recurring.recurrenceRepeatInterval == SCHEDULING_RECURRENCE_FREQUENCY_ENUM.WEEKLY &&
            renderWeeklyOptions()}
          {/*from time to time - 0000 and 23:59*/}
          {renderTimeRange()}
          {/*end date*/}
          <DateField
            label={getMessage("actionSetupScheduling.recurring.endDate.label")}
            tooltip={getMessage("actionSetupScheduling.recurring.endDate.tooltip")}
            name="scheduling.recurring.endDate"
            value={action.scheduling.recurring.endDate}
            onChange={onInputChange}
            disabled={viewMode}
            errorMsg={getErrorMessage(errors, "scheduling.recurring.endDate")}
            isRequired={true}
            {...attr}
          />
        </div>
        {/*summary sentence - from when ? specify end date ? */}
      </Fragment>
    );
  };

  return (
    <div className="e-box">
      {notificationElem}
      <div className="e-row">
        <div className="e-col-6">
          <h2>{getMessage("actionSetupScheduling.title")}</h2>

          {showRewardsAllocationDelay ? (
            <InputNumberField
              label={getMessage("actionSetupScheduling.rewardsAllocationDelay.duration.label")}
              tooltip={allocationDelayTooltip}
              name="scheduling.rewardsAllocationDelay.duration"
              value={action.scheduling.rewardsAllocationDelay.duration}
              onChange={onInputChange}
              postfixLabel={getMessage("days")}
              errorMsg={getErrorMessage(errors, "scheduling.rewardsAllocationDelay.duration")}
              labelClassName="no-margin"
              disabled={viewMode || disableRewardsAllocationDelay}
            />
          ) : null}

          {showVoucherObjective ? (
            <SelectField
              label={getMessage("actionSetupScheduling.voucherObjective.label")}
              name="scheduling.voucherObjective"
              value={action.scheduling.voucherObjective}
              options={props.voucherObjectiveOptions}
              onChange={onInputChange}
              tooltip={getMessage("actionSetupScheduling.voucherObjective.tooltip")}
              disabled={viewMode}
            />
          ) : null}

          {props.hideValidity ? null : props.isPromotionAction ? (
            <Fragment>
              <SelectField
                label={getMessage("actionSetupScheduling.recurringType.label")}
                type="bool"
                name="scheduling.recurringType"
                value={action.scheduling.recurringType}
                options={SCHEDULING_RECURRENCE_OPTIONS}
                onChange={onRecurringTypeChange}
                tooltip={getMessage("actionSetupScheduling.recurringType.tooltip")}
                disabled={viewMode || isActionActive}
                isRequired={true}
              />

              {!actionService.isRecurring(action) && (
                <DateTimeRangeField
                  label={getMessage("actionSetupScheduling.promoValidity.label")}
                  name="scheduling.promoValidity"
                  value={action.scheduling.promoValidity}
                  minDate={
                    initialAction.scheduling.promoValidity.start &&
                    moment(initialAction.scheduling.promoValidity.start) < moment.now()
                      ? initialAction.scheduling.promoValidity.start
                      : moment().add(3, "minutes").toDate()
                  }
                  onChange={onInputChange}
                  onError={() =>
                    onInputError(
                      "scheduling.promoValidity",
                      getMessage("actionValidator.constraints.scheduling.promoValidity.dateRange.selectedDateNotValid")
                    )
                  }
                  disabled={viewMode}
                  errorMsg={getErrorMessage(errors, "scheduling.promoValidity")}
                  tooltip={getMessage("actionSetupScheduling.promoValidity.tooltip")}
                  isRequired={true}
                />
              )}
              {actionService.isRecurring(action) && renderRecurring()}
            </Fragment>
          ) : (
            <Fragment>
              <SelectField
                label={getMessage("actionSetupScheduling.validityType.label")}
                name="scheduling.validityType"
                value={action.scheduling.validityType}
                options={VALIDITY_TYPE_OPTIONS}
                onChange={onValidityTypeChange}
                disabled={viewMode}
                tooltip={getMessage("actionSetupScheduling.validityType.tooltip")}
                isRequired={true}
              />
              {action.scheduling.validityType === VALIDITY_TYPE_ENUM.LIMITED_FROM_EARN && (
                <InputNumberField
                  label={getMessage("actionSetupScheduling.validity.duration.label")}
                  tooltip={getMessage("actionSetupScheduling.validity.duration.tooltip")}
                  name="scheduling.validity.duration"
                  value={action.scheduling.validity.duration}
                  onChange={onInputChange}
                  postfixLabel="Days"
                  errorMsg={getErrorMessage(errors, "scheduling.validity.duration")}
                  labelClassName="no-margin"
                  disabled={viewMode}
                  isRequired={true}
                />
              )}
              {action.scheduling.validityType === VALIDITY_TYPE_ENUM.FIXED_DATE && (
                <Fragment>
                  <DateTimeField
                    label={getMessage("actionSetupScheduling.validityFixedDate.label")}
                    name="scheduling.validityFixedDate"
                    value={action.scheduling.validityFixedDate}
                    minDate={new Date()}
                    onChange={onInputChange}
                    disabled={viewMode}
                    errorMsg={getErrorMessage(errors, "scheduling.validityFixedDate")}
                    isRequired={true}
                  />
                </Fragment>
              )}
            </Fragment>
          )}

          {actionService.isReferralAction(action.masterAction) && (
            <Fragment>
              <div className="e-field">
                <label className="e-field__label">
                  {getMessage("actionSetupScheduling.referralCta.label")}
                  <e-tooltip type="helper" content={getMessage("actionSetupScheduling.referralCta.tooltip")} />
                </label>
                <CheckboxField
                  label={getMessage("actionSetupScheduling.referralCta.sendViaEmailCb.label")}
                  renderOnlyCheckbox={true}
                  checked={action.scheduling.referralCta.sendViaEmail}
                  disabled={viewMode}
                  name="scheduling.referralCta.sendViaEmail"
                  onChange={onReferralCtaChange}
                  errorMsg={getErrorMessage(errors, "scheduling.referralCta")}
                />
                <div className="m-t-5">
                  <CheckboxField
                    label={getMessage("actionSetupScheduling.referralCta.displayInPopupCb.label")}
                    renderOnlyCheckbox={true}
                    checked={action.scheduling.referralCta.displayInPopup}
                    disabled={viewMode}
                    name="scheduling.referralCta.displayInPopup"
                    onChange={onReferralCtaChange}
                    errorMsg={getErrorMessage(errors, "scheduling.referralCta")}
                  />
                </div>
              </div>
              {action.scheduling.referralCta.sendViaEmail && (
                <SelectField
                  label={getMessage("actionSetupScheduling.referralCta.sendViaEmail.emailCampaignId.label")}
                  name="emailCampaignId"
                  loading={loadingBroadcastEmailOptions}
                  value={action.emailCampaignId}
                  options={broadcastEmailOptions}
                  placeholder="- Select template -"
                  onChange={onInputChange}
                  errorMsg={getErrorMessage(errors, "emailCampaignId")}
                  disabled={viewMode}
                  tooltip={getMessage("actionSetupScheduling.referralCta.sendViaEmail.emailCampaignId.tooltip")}
                  postfixButtons={
                    hasMultiLang ? <MultiLangPopupOpener onClick={onShowEmailCampaignIdMultiLang} /> : null
                  }
                  isRequired={true}
                />
              )}
            </Fragment>
          )}

          {!props.isPromotionAction && (
            <SelectField
              label={getMessage("actionSetupScheduling.activation.label")}
              type="bool"
              name="activated"
              value={action.activated}
              options={props.activateOptions}
              onChange={onInputChange}
              dontRender={props.hideActivate}
              tooltip={activationTooltip}
              disabled={disableActivate}
              isRequired={true}
            />
          )}
        </div>
        <div className="e-col-6 l-overlay-disabled">
          {action.scheduling &&
            false && ( //// Added false to hide the Smart Block - consider removing in the future - convert text to Messages when doing so!!!
              <Fragment>
                <h2>Smart Block</h2>
                <SelectField
                  label="Enable in smart box"
                  name="scheduling.enableInSmartBox"
                  value={action.scheduling.enableInSmartBox}
                  options={props.enableInSmartBoxOptions}
                  onChange={onInputChange}
                  tooltip="Make this action available in the smart loyalty block of the VCE email editor"
                  disabled={viewMode}
                />
                {/* Restrictions */}
                <SelectField
                  label="Restrictions"
                  name="scheduling.smartBoxRestrictions"
                  value={action.scheduling.smartBoxRestrictions}
                  options={props.smartBoxRestrictionsOptions}
                  onChange={onInputChange}
                  tooltip="Select how often this action is available to a customer"
                  disabled={viewMode}
                />
                {actionService.showAvailabilityfield(action) ? (
                  <DateTimeRangeField
                    label="Availability"
                    name="scheduling.availability"
                    value={action.scheduling.availability}
                    onChange={onInputChange}
                    tooltip="Set the availability dates of this action, if required"
                    disabled={viewMode}
                  />
                ) : null}
              </Fragment>
            )}
        </div>
        {props.isPromotionAction && (
          <div className="e-col-6">
            <SelectField
              extraClassName="e-margin-top-3xl"
              label={getMessage("actionSetupScheduling.activation.label")}
              type="bool"
              name="activated"
              value={action.activated}
              options={props.activateOptions}
              onChange={onInputChange}
              dontRender={props.hideActivate}
              tooltip={activationTooltip}
              disabled={disableActivate}
              isRequired={true}
            />
            <div className="e-field">
              <label className="e-field__label font-weight-bold">
                {getMessage("actionSetupScheduling.recurring.summary.label")}
              </label>
              {<ActionSetupSchedulingSummary action={action} />}
            </div>
          </div>
        )}
        <div className="e-col e-col__separator" />
      </div>
    </div>
  );
};

ActionSetupScheduling.propTypes = {
  action: actionType.isRequired,
  initialAction: actionType.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onInputError: PropTypes.func.isRequired,
  showRewardsAllocationDelay: PropTypes.bool.isRequired,
  disableRewardsAllocationDelay: PropTypes.bool.isRequired,
  voucherObjectiveOptions: PropTypes.array.isRequired,
  showVoucherObjective: PropTypes.bool.isRequired,
  smartBoxRestrictionsOptions: PropTypes.array.isRequired,
  enableInSmartBoxOptions: PropTypes.array.isRequired,
  activateOptions: PropTypes.array.isRequired,
  errors: PropTypes.object,
  viewMode: PropTypes.bool,
  isPromotionAction: PropTypes.bool,
  isPromotionDisplayedInWallet: PropTypes.bool,
  hideValidity: PropTypes.bool,
  hideActivate: PropTypes.bool,
  notificationElem: PropTypes.element,
  onValidityTypeChange: PropTypes.func,
  onShowEmailCampaignIdMultiLang: PropTypes.func,
  onReferralCtaChange: PropTypes.func,
  loadingBroadcastEmailOptions: PropTypes.bool,
  broadcastEmailOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string
    })
  ),
  disableActivate: PropTypes.bool
};

export default ActionSetupScheduling;
