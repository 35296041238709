import { getMessage } from "../messages";

export const FORM_STEP_ENUM = {
  ACCOUNT_SETTINGS: "accountSettings",
  LANGUAGE_AND_CURRENCY: "languageAndCurrency",
  PLAN_SETTINGS: "planSettings",
  MEMBERSHIP_SETTINGS: "membershipSettings",
  CASHBACK: "cashbackSettings",
  ACTIVATION: "activation"
};

export const FORM_STEPS = [
  {
    id: FORM_STEP_ENUM.ACCOUNT_SETTINGS,
    title: getMessage("onboardingTabs.accountSettings.title"),
    disabled: false
  },
  {
    id: FORM_STEP_ENUM.LANGUAGE_AND_CURRENCY,
    title: getMessage("managementTabs.languageAndCurrency.title"),
    disabled: false
  },
  {
    id: FORM_STEP_ENUM.PLAN_SETTINGS,
    title: getMessage("managementTabs.planSettings.title"),
    disabled: false
  },
  {
    id: FORM_STEP_ENUM.MEMBERSHIP_SETTINGS,
    title: getMessage("managementTabs.membershipSettings.title"),
    disabled: false
  },
  {
    id: FORM_STEP_ENUM.ACTIVATION,
    title: getMessage("onboardingTabs.activation.title"),
    disabled: false
  }
];

export const FORM_STEPS_SPEND = [
  {
    id: FORM_STEP_ENUM.ACCOUNT_SETTINGS,
    title: getMessage("onboardingTabs.accountSettings.title"),
    disabled: false
  },
  {
    id: FORM_STEP_ENUM.LANGUAGE_AND_CURRENCY,
    title: getMessage("managementTabs.languageAndCurrency.title"),
    disabled: false
  },
  {
    id: FORM_STEP_ENUM.PLAN_SETTINGS,
    title: getMessage("managementTabs.planSettings.title"),
    disabled: false
  },
  {
    id: FORM_STEP_ENUM.CASHBACK,
    title: getMessage("managementTabs.cashbackSettings.title"),
    disabled: false
  },
  {
    id: FORM_STEP_ENUM.MEMBERSHIP_SETTINGS,
    title: getMessage("managementTabs.membershipSettings.title"),
    disabled: false
  },
  {
    id: FORM_STEP_ENUM.ACTIVATION,
    title: getMessage("onboardingTabs.activation.title"),
    disabled: false
  }
];

export const CUSTOMER_DISTRIBUTION_ENUM = {
  DISTRIBUTE: "distribute",
  FIRST_TIER: "firstTier"
};

export const CUSTOMER_CALC_PERIOD_OPTIONS = [
  {
    name: getMessage("customerCalcPeriod.options.1.name"),
    value: "1"
  },
  {
    name: getMessage("customerCalcPeriod.options.2.name"),
    value: "2"
  },
  {
    name: getMessage("customerCalcPeriod.options.3.name"),
    value: "3"
  },
  {
    name: getMessage("customerCalcPeriod.options.6.name")
  },
  {
    name: getMessage("customerCalcPeriod.options.12.name"),
    value: "12"
  }
  // {
  //   name: "All historical data",
  //   value: "-1"
  // }
];

export const MAX_NUMBER_OF_TIERS = 10;
