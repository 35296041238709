import React, { Component, Fragment } from "react";
import classNames from "classnames";
import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";

import Widget from "../../components/Widget";
import WidgetDateRange from "../../components/WidgetDateRange";
import DashboardEmptyState from "./DashboardEmptyState";
import WidgetHelpLink from "./WidgetHelpLink";
import LegendWithSelection from "./LegendWithSelection";
import WidgetTotalsItem from "./WidgetTotalsItem";
import WidgetCollapsedContentElement from "./WidgetCollapsedContentElement";

import { ENGAGEMENT_RATES_HELP_LINK, TIER_COLORS_ARRAY } from "../../constants/dashboardConstants";
import reportApi from "../../api/reportApi";

import { convertDateForChart, formatTrendText, transformDataDateForDemo, getDaysDiff } from "./dashboardHelper";
import planService from "../../services/planService";
import { getMessage } from "../../messages";

const CHART_HEIGHT = 252;
const MAIN_SERIES_ID = "main";
const CHART_MODE = {
  ENGAGEMENT: "engagement",
  REDEMPTION: "redemption"
};

const CHART_COLORS = {
  LOYALTY_RATE: "#424E88"
};

class EngagementRatesWidget extends Component {
  constructor(props) {
    super(props);

    let legendSelection = {};
    legendSelection[MAIN_SERIES_ID] = true;

    this.chartMetaData = [
      {
        id: MAIN_SERIES_ID,
        displayName: getMessage("engagementRatesWidget.chartMetaData.main.displayName"),
        color: CHART_COLORS.LOYALTY_RATE
      }
    ];

    props.tiers.forEach((tier, index) => {
      this.chartMetaData.push({ id: tier.id, displayName: tier.name, color: TIER_COLORS_ARRAY[index] });
      legendSelection[tier.id] = false;
    });

    const chartSeriesArr = this.chartMetaData.map((d) => {
      return {
        id: d.id,
        name: d.displayName,
        color: d.color,
        data: []
      };
    });

    this.state = {
      loading: true,
      selectedDaysRange: getDaysDiff(props.initialStartDate, props.initialEndDate),
      dateRange: {
        startDateStr: props.initialStartDate,
        endDateStr: props.initialEndDate
      },
      chartSeriesArrEngagement: cloneDeep(chartSeriesArr),
      chartSeriesArrRedemption: cloneDeep(chartSeriesArr),
      totalsEngagement: {},
      totalsRedemption: {},
      legendSelection,
      chartMode: CHART_MODE.ENGAGEMENT,
      showEmptyState: false,
      showRedemptionWidget: planService.hasRedemption(props.plan)
    };
  }

  componentDidMount() {
    this.loadData(this.state.dateRange);
  }

  loadData(daysRange) {
    const { showRedemptionWidget } = { ...this.state };

    let promises = [reportApi.getEngagementRatesData(daysRange), reportApi.getEngagementRatesTotals(daysRange)];

    if (showRedemptionWidget) {
      promises = [
        ...promises,
        reportApi.getRedemptionRatesData(daysRange),
        reportApi.getRedemptionRatesTotals(daysRange)
      ];
    }

    this.setState({ loading: true, showEmptyState: false });

    Promise.all(promises)
      .then((response) => {
        this.setChartSeriesEngagement(response[0]);
        this.setTotalsEngagementState(CHART_MODE.ENGAGEMENT, response[1]);
        if (showRedemptionWidget) {
          this.setChartSeriesRedemption(response[2]);
          this.setTotalsEngagementState(CHART_MODE.REDEMPTION, response[3]);
        }
      })
      .catch((error) => {
        this.setState({ showEmptyState: true });
        throw error;
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  getNumberValueAttr() {
    return { humanize: "auto", precision: "2", "trim-fraction-zeros": "true" };
  }

  getPctValueAttr() {
    return { humanize: "auto", precision: "1", type: "percent", "trim-fraction-zeros": "true" };
  }

  getTrendText(value, daysRange) {
    return formatTrendText`${value} than previous ${daysRange} days`;
  }

  getItemsForLegend() {
    const { chartMode } = { ...this.state };
    return this.chartMetaData.map((item) => {
      let displayName = item.displayName;
      if (item.id === MAIN_SERIES_ID && chartMode === CHART_MODE.REDEMPTION) {
        displayName = getMessage("engagementRatesWidget.mainSeries.redemtion.name");
      }

      return {
        id: item.id,
        displayName,
        color: item.color,
        isSelected: this.state.legendSelection[item.id]
      };
    });
  }

  setChartSeriesEngagement(dataRows) {
    let { chartSeriesArrEngagement } = { ...this.state };
    chartSeriesArrEngagement = this.setChartSeriesArrState(chartSeriesArrEngagement, dataRows);
    this.setState({ chartSeriesArrEngagement });
  }

  setChartSeriesRedemption(dataRows) {
    let { chartSeriesArrRedemption } = { ...this.state };
    chartSeriesArrRedemption = this.setChartSeriesArrState(chartSeriesArrRedemption, dataRows);
    this.setState({ chartSeriesArrRedemption });
  }

  setChartSeriesArrState(chartSeriesArr, dataRows) {
    if (!dataRows) {
      this.setState({ showEmptyState: true });
      return;
    }

    dataRows = transformDataDateForDemo(dataRows, this.state.selectedDaysRange);

    //reset data on each series
    chartSeriesArr.forEach((series) => {
      series.data = [];
    });

    dataRows.forEach((row) => {
      const date = convertDateForChart(row.date);

      chartSeriesArr.forEach((series) => {
        let pct = 0;
        if (series.id === MAIN_SERIES_ID) {
          pct = row.ratePct || 0;
        } else {
          const tierData = row.dataPerTier[series.id];
          pct = tierData ? tierData.ratePct || 0 : 0;
        }

        series.data.push({
          x: date,
          y: pct
        });
      });
    });

    return chartSeriesArr;
  }

  setTotalsEngagementState(chartMode, { ratePct, ratePrevDiffPct }) {
    let totals = {
      ratePct: ratePct || 0,
      ratePrevDiffPct: ratePrevDiffPct || 0
    };

    if (chartMode == CHART_MODE.REDEMPTION) {
      this.setState({ totalsRedemption: totals });
    } else {
      this.setState({ totalsEngagement: totals });
    }
  }

  onTimeRangeChange(value) {
    const { start, end } = value;
    let dateRange = {
      startDateStr: start,
      endDateStr: end
    };
    this.setState({ dateRange, selectedDaysRange: getDaysDiff(start, end) });
    this.loadData(dateRange);
  }

  onLegendSelectionChange(selectionId) {
    let { legendSelection } = { ...this.state };

    legendSelection[selectionId] = !legendSelection[selectionId];

    this.setState({ legendSelection });
  }

  onTabChange(chartMode) {
    this.setState({
      chartMode
    });
  }

  renderTotals() {
    const { selectedDaysRange, chartMode, totalsEngagement, totalsRedemption, showRedemptionWidget } = {
      ...this.state
    };

    const engagementRateWidget = (
      <WidgetTotalsItem
        title={getMessage("engagementRatesWidget.engagementRateWidget.title")}
        titleTooltip={getMessage("engagementRatesWidget.engagementRateWidget.titleTooltip")}
        value={totalsEngagement.ratePct}
        valueNumericAttr={this.getPctValueAttr()}
        valueColor={CHART_COLORS.LOYALTY_RATE}
        trendValue={totalsEngagement.ratePrevDiffPct}
        trendText={this.getTrendText(totalsEngagement.ratePrevDiffPct, selectedDaysRange)}
      />
    );

    const redemptionRateWidget = (
      <WidgetTotalsItem
        title={getMessage("engagementRatesWidget.redemptionRateWidget.title")}
        titleTooltip={getMessage("engagementRatesWidget.redemptionRateWidget.titleTooltip")}
        value={totalsRedemption.ratePct}
        valueNumericAttr={this.getPctValueAttr()}
        valueColor={CHART_COLORS.LOYALTY_RATE}
        trendValue={totalsRedemption.ratePrevDiffPct}
        trendText={this.getTrendText(totalsRedemption.ratePrevDiffPct, selectedDaysRange)}
      />
    );

    if (!showRedemptionWidget) {
      return engagementRateWidget;
    }

    return (
      <div className="e-grid">
        <div className="e-cell e-cell-6">
          <div
            className={classNames("e-tabs__title", { "e-tabs__title-active": chartMode === CHART_MODE.ENGAGEMENT })}
            onClick={() => this.onTabChange(CHART_MODE.ENGAGEMENT)}
          >
            {engagementRateWidget}
          </div>
        </div>
        <div className="e-cell e-cell-6">
          <div
            className={classNames("e-tabs__title", { "e-tabs__title-active": chartMode === CHART_MODE.REDEMPTION })}
            onClick={() => this.onTabChange(CHART_MODE.REDEMPTION)}
          >
            {redemptionRateWidget}
          </div>
        </div>
      </div>
    );
  }

  renderChart() {
    const { chartSeriesArrEngagement, chartSeriesArrRedemption, legendSelection, chartMode } = { ...this.state };

    const chartSeriesArr = chartMode === CHART_MODE.REDEMPTION ? chartSeriesArrRedemption : chartSeriesArrEngagement;

    if (!chartSeriesArr) {
      return null;
    }

    return (
      <ec-chart height={CHART_HEIGHT} domain-type="time">
        {chartSeriesArr.map((series) => {
          let attr = {};
          if (!legendSelection[series.id]) {
            attr["visible"] = false;
            attr["hidden-in-tooltip"] = true;
          }

          let name = series.name;
          if (series.id === MAIN_SERIES_ID && chartMode === CHART_MODE.REDEMPTION) {
            name = getMessage("engagementRatesWidget.mainSeries.redemtion.name");
          }

          return (
            <ec-series-line
              key={series.id}
              name={name}
              data={JSON.stringify(series.data)}
              color={series.color}
              format-y=".2%"
              {...attr}
            ></ec-series-line>
          );
        })}
      </ec-chart>
    );
  }

  renderContent() {
    const { showEmptyState } = { ...this.state };

    if (showEmptyState) {
      return <DashboardEmptyState />;
    }

    return (
      <Fragment>
        <div className={classNames({ "l-widget-tabs": this.state.showRedemptionWidget })}>{this.renderTotals()}</div>
        <div className="e-margin-top-m">{this.renderChart()}</div>
        <div className="e-margin-top-m">
          <LegendWithSelection
            items={this.getItemsForLegend()}
            visible={true}
            onChange={this.onLegendSelectionChange.bind(this)}
          />
        </div>
      </Fragment>
    );
  }

  getCollapsedContentElements() {
    const { selectedDaysRange, totalsEngagement, totalsRedemption, showRedemptionWidget } = { ...this.state };

    const elements = [
      <WidgetCollapsedContentElement
        value={totalsEngagement.ratePct}
        valueNumericAttr={this.getPctValueAttr()}
        valueColor={CHART_COLORS.LOYALTY_RATE}
        title={getMessage("engagementRatesWidget.totalsEngagement.ratePct.title")}
      />,
      showRedemptionWidget ? (
        <WidgetCollapsedContentElement
          value={totalsRedemption.ratePct}
          valueNumericAttr={this.getPctValueAttr()}
          valueColor={CHART_COLORS.LOYALTY_RATE}
          title={getMessage("engagementRatesWidget.totalsRedemption.ratePct.title")}
        />
      ) : null,
      <Fragment>{getMessage("inThePastDays", { selectedDaysRange })}</Fragment>
    ];

    return elements;
  }

  render() {
    return (
      <Widget
        title={getMessage("engagementRatesWidget.title")}
        className="engagement-rates-widget"
        headerActions={
          <Fragment>
            <WidgetDateRange
              onChange={this.onTimeRangeChange.bind(this)}
              isDemoMode={false}
              dateRange={{ start: this.state.dateRange.startDateStr, end: this.state.dateRange.endDateStr }}
            />
          </Fragment>
        }
        footerActions={<WidgetHelpLink linkUrl={ENGAGEMENT_RATES_HELP_LINK} />}
        content={this.renderContent()}
        collapsedContentElements={this.getCollapsedContentElements()}
        loading={this.state.loading}
        isEmptyState={this.state.showEmptyState}
      />
    );
  }
}

EngagementRatesWidget.propTypes = {
  plan: PropTypes.object,
  tiers: PropTypes.array,
  initialStartDate: PropTypes.string,
  initialEndDate: PropTypes.string
};

export default EngagementRatesWidget;
