import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { uniqBy } from "lodash";

import CardsContainer from "../components/CardsContainer";
import Notification from "../components/Notification";
import Time from "../components/Time";

import {
  selectActions,
  selectRewardsById,
  selectIsBeforeFirstPlanLaunch,
  selectCurrentPlanVersion,
  selectShowCurrentPlan,
  selectPlanOptInValue,
  selectPlanMarkets,
  selectPlanHasMultiMarket,
  isAccountViewOnly
} from "../reducers";

import { deleteAction } from "../actions/actionActions";

import {
  MASTER_ACTIONS,
  ACTION_STATUS_ENUM,
  MASTER_ACTION_ENUM,
  PROMOTION_USAGE_TYPE_OPTIONS,
  SCHEDULING_RECURRENCE_OPTIONS
} from "../constants/actionConstants";

import { openDeleteDialog } from "../helpers/dialogHelper";
import { getActionStatusLabel } from "../helpers/statusHelper";
import actionService from "../services/actionService";
import planService from "../services/planService";
import {
  ACTIONS_PURCHASE_SETUP_LINK,
  ACTIONS_OVERVIEW_HELP_LINK,
  ACTIONS_PROMOTION_SETUP_LINK,
  ACTIONS_ENGAGEMENT_SETUP_LINK,
  ACTIONS_EVENT_SETUP_LINK,
  ACTIONS_REFERRAL_SETUP_LINK,
  ACTIONS_JOIN_SETUP_LINK
} from "../constants/linkConstants";

import { getMessage } from "../messages";

class Actions extends Component {
  constructor(props) {
    super(props);

    const currentMasterAction = props.match.params.masterAction || MASTER_ACTION_ENUM.PURCHASES;

    this.state = {
      currentMasterAction
    };
  }

  getItemLinkUrl(id, isDup) {
    if (!id) {
      return `/action-new/${this.state.currentMasterAction}`;
    } else if (isDup) {
      return `/action-new/${this.state.currentMasterAction}/${id}`;
    } else {
      return `/action/${id}`;
    }
  }

  getReportsLinkUrl(id) {
    return `/action-dashboard/${id}`;
  }

  getDeleteIcon(status, action) {
    if (action.isDefault || action.tactic) {
      return null;
    }

    let tooltip = "";
    let disabled = false;

    if (actionService.isPromotionAction(action.masterAction)) {
      if (status === ACTION_STATUS_ENUM.ACTIVE) {
        disabled = true;
        tooltip = getMessage("actions.deleteAction.tooltip.promotion");
      }
    } else if (
      actionService.isAttachable(action) &&
      actionService.isActionAttached(action) &&
      (status === ACTION_STATUS_ENUM.ACTIVE || status === ACTION_STATUS_ENUM.DEACTIVATED)
    ) {
      disabled = true;
      tooltip = getMessage("actions.deleteAction.tooltip.attachable");
    } else if (actionService.isEventAction(action.masterAction)) {
      if (status === ACTION_STATUS_ENUM.ACTIVE) {
        disabled = true;
        tooltip = getMessage("actions.deleteAction.tooltip.event");
      }
    }

    if (this.props.isAccountViewOnly) {
      disabled = true;
    }

    return {
      onClick: this.onDeleteActionClick.bind(this),
      tooltip,
      disabled
    };
  }

  getEditIcon(actionId) {
    let tooltip = "";

    return {
      link: this.getItemLinkUrl(actionId, false),
      tooltip
    };
  }

  getReportsIcon(action) {
    let tooltip = getMessage("actions.reportsIcon.tooltip");
    if (actionService.isReferralAction(this.state.currentMasterAction) && action.firstActivatedAt) {
      return {
        link: this.getReportsLinkUrl(action.id),
        tooltip,
        icon: "bar-chart"
      };
    }

    return null;
  }

  isActionCardDisabled() {
    return (
      !actionService.isEventAction(this.state.currentMasterAction) &&
      !this.props.showCurrentVersion &&
      !this.props.isBeforeFirstPlanLaunch
    );
  }

  isAddNewCardDisabled() {
    const { currentMasterAction, disableAddReferral } = { ...this.state };

    if (this.props.isAccountViewOnly) {
      return true;
    }

    if (actionService.isJoinAction(currentMasterAction)) {
      return planService.isOptInAutomatic(this.props.planOptInValue);
    }

    if (actionService.isReferralAction(currentMasterAction)) {
      return this.isDisableNewReferral();
    }

    return false;
  }

  isDisableNewReferral() {
    const { currentMasterAction } = { ...this.state };
    if (actionService.isReferralAction(currentMasterAction)) {
      const referralActions = actionService.getActionsByMaster(this.props.actions, currentMasterAction);
      return this.props.hasMultiMarket
        ? referralActions.length === this.props.markets.length
        : referralActions.length === 1;
    }

    return false;
  }

  sortActionsToDisplay(actions) {
    const sortedActions = actions.sort((a1, a2) => {
      if (a1.isDefault) {
        return -1;
      } else if (a2.isDefault) {
        return 1;
      }

      if (!!a1.tactic && !a2.tactic) {
        return -1;
      } else if (!a1.tactic && !!a2.tactic) {
        return 1;
      } else {
        return 0;
      }
    });

    return sortedActions;
  }

  getCurrentCardActions() {
    const currentMasterAction = this.state.currentMasterAction;
    const badgeText = MASTER_ACTIONS[currentMasterAction].largeText;

    const actionsToDisplay = actionService.getActionsByMaster(this.props.actions, currentMasterAction);
    const sortedActions = this.sortActionsToDisplay(actionsToDisplay);

    let cards = [];

    for (let i = 0; i < sortedActions.length; i++) {
      const action = sortedActions[i];

      const status = actionService.getActionStatus(action);

      const extraLabels = actionService.getCardExtraLabels(action);

      const wrapperClassName = classNames({
        "l-overlay-disabled": this.isActionCardDisabled()
      });

      const rewardIds = actionService.getConnectedRewardVouchers(action);
      const connectedRewards = rewardIds.map((id) => this.props.rewardsById[id]);

      let card = {
        id: action.id,
        title: actionService.getActionTitle(action),
        subTitle: this.renderCardSubtitle(action),
        badge: !!action.tactic ? "" : badgeText,
        className: action.masterAction,
        wrapperClassName,
        status,
        extraLabels,
        statusLabel: getActionStatusLabel(status, action, connectedRewards),
        deleteIcon: this.getDeleteIcon(status, action),
        editIcon: this.getEditIcon(action.id),
        isDefault: action.isDefault,
        duplicateIcon: action.isDefault
          ? null
          : {
              link: this.getItemLinkUrl(action.id, true),
              disabled: this.isDisableNewReferral() || this.props.isAccountViewOnly
            },
        topRightIcon: this.getReportsIcon(action)
      };

      card.filters = {
        statusLabel: card.statusLabel.label
      };
      if (actionService.isPromotionAction(action.masterAction)) {
        card.filters = {
          ...card.filters,
          promotionUsageType: action.isDefault ? "" : action.promotion.usageType,
          recurringType: action.isDefault ? "" : action.scheduling.recurringType
        };
      }

      cards.push(card);
    }

    return cards;
  }

  onDeleteActionClick(actionId, actionName) {
    const onDeleteAction = () => {
      this.props.deleteAction(actionId);
    };

    const action = this.props.actions.find((a) => a.id === actionId);
    let actionType = "";
    if (actionService.isPromotionAction(action.masterAction)) {
      actionType = "promotion";
    } else if (actionService.isReferralAction(action.masterAction)) {
      actionType = "referral";
    } else if (actionService.isAttachable(action)) {
      actionType = "attachable";
    } else if (actionService.isEventAction(action.masterAction)) {
      actionType = "event";
    }

    openDeleteDialog(
      getMessage(`actions.deleteDialog.${actionType}.title`),
      getMessage(`actions.deleteDialog.${actionType}.text`),
      onDeleteAction
    );
  }

  renderCardSubtitle(action) {
    const description = <div className="l-card-desc">{action.description}</div>;

    if (actionService.isPromotionAction(action.masterAction) && !action.isDefault && action.isValid) {
      const { startDate, endDate } = actionService.isRecurring(action)
        ? { startDate: action.scheduling.recurring.startDate, endDate: action.scheduling.recurring.endDate }
        : { startDate: action.scheduling.promoValidity.start, endDate: action.scheduling.promoValidity.end };

      let startTimeElement = getMessage("actions.card.subtitle.starttime", {
        startTime: <Time value={startDate} onlyDate={true} />
      });
      let endTimeElement = getMessage("actions.card.subtitle.endtime", {
        endTime: <Time value={endDate} onlyDate={true} />
      });
      return (
        <Fragment>
          {description}
          <div className="m-t-10">
            <span>{startTimeElement}</span>
            <br />
            <span>{endTimeElement}</span>
          </div>
        </Fragment>
      );
    }
    return description;
  }

  getNotificationContent() {
    const getLink = (messageId, link) => {
      return `<a target="_blank" href="${link}">${getMessage(messageId)}</a>`;
    };

    switch (this.state.currentMasterAction) {
      case MASTER_ACTION_ENUM.PURCHASES: {
        const helpLink = getLink("actions.notification.purchase.helpLinkText", ACTIONS_OVERVIEW_HELP_LINK);
        const learnMoreLink = getLink("actions.notification.purchase.learnMoreLinkText", ACTIONS_PURCHASE_SETUP_LINK);
        return getMessage("actions.notification.purchase", { helpLink, learnMoreLink });
      }
      case MASTER_ACTION_ENUM.PROMOTIONS: {
        const learnMoreLink = getLink("actions.notification.promotion.learnMoreLinkText", ACTIONS_PROMOTION_SETUP_LINK);
        return getMessage("actions.notification.promotion", { learnMoreLink });
      }
      case MASTER_ACTION_ENUM.ENGAGEMENTS: {
        const helpLink = getLink("actions.notification.engagement.helpLinkText", ACTIONS_OVERVIEW_HELP_LINK);
        const learnMoreLink = getLink(
          "actions.notification.engagement.learnMoreLinkText",
          ACTIONS_ENGAGEMENT_SETUP_LINK
        );
        return getMessage("actions.notification.engagement", { helpLink, learnMoreLink });
      }
      case MASTER_ACTION_ENUM.EVENTS: {
        const learnMoreLink = getLink("actions.notification.event.learnMoreLinkText", ACTIONS_EVENT_SETUP_LINK);
        return getMessage("actions.notification.event", { learnMoreLink });
      }
      case MASTER_ACTION_ENUM.REFERRAL: {
        const learnMoreLink = getLink("actions.notification.event.learnMoreLinkText", ACTIONS_REFERRAL_SETUP_LINK);
        return getMessage("actions.notification.referral", { learnMoreLink });
      }
      case MASTER_ACTION_ENUM.JOIN: {
        const helpLink = getLink("actions.notification.join.helpLinkText", ACTIONS_OVERVIEW_HELP_LINK);
        const learnMoreLink = getLink("actions.notification.join.learnMoreLinkText", ACTIONS_JOIN_SETUP_LINK);
        return getMessage("actions.notification.join", { helpLink, learnMoreLink });
      }
      default: {
        return "";
      }
    }
  }

  getFilters(cards) {
    let filters = [
      {
        label: getMessage("actions.filters.status.label"),
        key: "statusLabel",
        options: uniqBy(cards, (card) => card.filters.statusLabel).map((card) => card.filters.statusLabel)
      }
    ];

    if (actionService.isPromotionAction(this.state.currentMasterAction)) {
      filters.push({
        label: getMessage("actions.filters.usageType.label"),
        key: "promotionUsageType",
        options: PROMOTION_USAGE_TYPE_OPTIONS,
        showInMoreFilters: true
      });
      filters.push({
        label: getMessage("actions.filters.recurringType.label"),
        key: "recurringType",
        options: SCHEDULING_RECURRENCE_OPTIONS,
        showInMoreFilters: true
      });
    }

    return filters;
  }

  render() {
    const isPromotionAction = actionService.isPromotionAction(this.state.currentMasterAction);
    const actionType = getMessage(MASTER_ACTIONS[this.state.currentMasterAction].largeTextMessage);
    const currentCards = this.getCurrentCardActions();

    return (
      <div className="box-padding">
        <Notification content={this.getNotificationContent()} />
        <div>
          <CardsContainer
            cards={currentCards}
            addNewCardLink={this.getItemLinkUrl()}
            // addNewCardText={`Create new ${actionText} ${suffixText}`}
            addNewCardText={
              isPromotionAction
                ? getMessage("actions.addButton.promotion.text")
                : getMessage("actions.addButton.text", { actionType })
            }
            disableAddNewCard={this.isActionCardDisabled() || this.isAddNewCardDisabled()}
            filters={this.getFilters(currentCards)}
            searchPlaceholderText={getMessage("actions.filters.search.label")}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const isBeforeFirstPlanLaunch = selectIsBeforeFirstPlanLaunch(state);
  const showCurrentVersion = selectShowCurrentPlan(state);
  return {
    actions: selectActions(state),
    rewardsById: selectRewardsById(state),
    showCurrentVersion: showCurrentVersion,
    isBeforeFirstPlanLaunch: isBeforeFirstPlanLaunch,
    currentPlanFixedBenefit: selectCurrentPlanVersion(state).fixedBenefits,
    planOptInValue: selectPlanOptInValue(state),
    markets: selectPlanMarkets(state),
    hasMultiMarket: selectPlanHasMultiMarket(state),
    isAccountViewOnly: isAccountViewOnly(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteAction: (id) => {
      dispatch(deleteAction(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
