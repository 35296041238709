import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import sanitizeHtml from "sanitize-html";

const Notification = ({ content, withBigIcon, isSticky, isNeutral, isSuccess, isWarning, icon }) => {
  if (!content) {
    return null;
  }

  const className = classNames("e-notice", {
    "e-notice-neutral": isNeutral,
    "e-notice-withicon": !withBigIcon,
    "e-notice-withbigicon": withBigIcon,
    "e-notice-sticky": isSticky,
    "e-notice-success": isSuccess,
    "e-notice-warning": isWarning
  });
  return (
    <div className={className}>
      <span className="e-notice__icon">
        <e-icon icon={icon || "e-info-circle"} />
      </span>
      {typeof content === "string" ? <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(content) }} /> : content}
    </div>
  );
};

Notification.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  withBigIcon: PropTypes.bool,
  isSticky: PropTypes.bool,
  isNeutral: PropTypes.bool,
  icon: PropTypes.string,
  isSuccess: PropTypes.bool,
  isWarning: PropTypes.bool
};

export default Notification;
