export const getElementInnerHeight = (elmId) => {
  const elm = document.getElementById(elmId);
  const clientHeight = elm.clientHeight;

  const style = window.getComputedStyle ? getComputedStyle(elm, null) : elm.currentStyle;

  const paddingTop = parseInt(style.paddingTop) || 0;
  const paddingBottom = parseInt(style.paddingBottom) || 0;

  return clientHeight - paddingTop - paddingBottom;
};
