import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import GeneratedRevenueWidget from "./GeneratedRevenueWidget";
import AverageOrderValueWidget from "./AverageOrderValueWidget";
import PurchaseFrequencyWidget from "./PurchaseFrequencyWidget";
import NumOfMembersWidget from "./NumOfMembersWidget";
import EnrollmentsWidget from "./EnrollmentsWidget";
import EngagementRatesWidget from "./EngagementRatesWidget";
import PointsAnalyticsWidget from "./PointsAnalyticsWidget";
import VouchersAnalyticsWidget from "./VouchersAnalyticsWidget";

import { isDemoMode } from "../../helpers/envHelper";
import { formatInputDate } from "./dashboardHelper";

import {
  selectPlanPrimaryCurrency,
  selectIsBeforeFirstPlanLaunch,
  selectCurrentPlanVersion,
  selectIsCurrentPlanVersionExists,
  selectPlan
} from "../../reducers";

import { getMessage } from "../../messages";

class DashboardView extends Component {
  constructor(props) {
    super(props);
    const momentEnd = isDemoMode() ? moment("2020-12-24") : moment();
    this.initialEndDate = formatInputDate(momentEnd.clone().subtract(1, "days"));
    this.initialStartDate = formatInputDate(momentEnd.clone().subtract(7, "days"));
  }

  getTimeRangeDates(daysRange) {
    const initialMoment = isDemoMode() ? moment("2020-12-24") : moment();

    const endDate = initialMoment.clone().subtract(1, "days");
    const startDate = initialMoment.clone().subtract(daysRange, "days");

    return {
      startDateStr: startDate.format("YYYY-MM-DD"),
      endDateStr: endDate.format("YYYY-MM-DD")
    };
  }

  render() {
    return (
      <div className="l-dashboard">
        <h2>{getMessage("dashboardView.loyaltyImpact.title")}</h2>
        <div className="e-row">
          <div className="e-col-12">
            <GeneratedRevenueWidget
              currency={this.props.currency}
              tiers={this.props.tiers}
              // getTimeRangeDates={this.getTimeRangeDates}
              initialStartDate={this.initialStartDate}
              initialEndDate={this.initialEndDate}
            />
          </div>
        </div>
        <div className="e-row e-margin-top-l">
          <div className="e-col-6">
            <AverageOrderValueWidget
              currency={this.props.currency}
              tiers={this.props.tiers}
              initialStartDate={this.initialStartDate}
              initialEndDate={this.initialEndDate}
            />
          </div>
          <div className="e-col-6">
            <PurchaseFrequencyWidget
              tiers={this.props.tiers}
              initialStartDate={this.initialStartDate}
              initialEndDate={this.initialEndDate}
            />
          </div>
        </div>
        <h2 className="e-margin-top-2xl">{getMessage("dashboardView.numOfMembersWidget.title")}</h2>
        <div className="e-row">
          <div className="e-col-6">
            <NumOfMembersWidget
              tiers={this.props.tiers}
              initialStartDate={this.initialStartDate}
              initialEndDate={this.initialEndDate}
            />
          </div>
          <div className="e-col-6">
            <EnrollmentsWidget initialStartDate={this.initialStartDate} initialEndDate={this.initialEndDate} />
          </div>
        </div>
        <h2 className="e-margin-top-2xl">{getMessage("dashboardView.engagementRatesWidget.title")}</h2>
        <div className="e-row">
          <div className="e-col-12">
            <EngagementRatesWidget
              tiers={this.props.tiers}
              initialStartDate={this.initialStartDate}
              initialEndDate={this.initialEndDate}
              plan={this.props.plan}
            />
          </div>
        </div>
        <h2 className="e-margin-top-2xl">{getMessage("dashboardView.pointsAnalyticsWidget.title")}</h2>
        <div className="e-row">
          <div className="e-col-6">
            <PointsAnalyticsWidget
              initialStartDate={this.initialStartDate}
              initialEndDate={this.initialEndDate}
              plan={this.props.plan}
            />
          </div>
          <div className="e-col-6">
            <VouchersAnalyticsWidget
              initialStartDate={this.initialStartDate}
              initialEndDate={this.initialEndDate}
              plan={this.props.plan}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const isBeforeFirstPlanLaunch = selectIsBeforeFirstPlanLaunch(state);

  const isCurrentPlanExists = selectIsCurrentPlanVersionExists(state);
  const plan = isCurrentPlanExists ? selectCurrentPlanVersion(state) : selectPlan(state);

  return {
    plan,
    currency: selectPlanPrimaryCurrency(state),
    tiers: isBeforeFirstPlanLaunch ? [] : selectCurrentPlanVersion(state).tiers
  };
};

DashboardView.propTypes = {};

export default connect(mapStateToProps, null)(DashboardView);
