import { CURRENCY_SYMBOL_MAP, CURRENCY_TITLE_MAP } from "../constants/currencyConstants";

const DEFAULT_CURRENCY_CODE = "EUR";

export const getCurrencySymbol = (currencyCode) => {
  const currencyKey = currencyCode || DEFAULT_CURRENCY_CODE;
  return CURRENCY_SYMBOL_MAP[currencyKey] || CURRENCY_SYMBOL_MAP[DEFAULT_CURRENCY_CODE];
};

export const getCurrencyTitle = (currencyCode) => {
  const currencyKey = currencyCode || DEFAULT_CURRENCY_CODE;
  return CURRENCY_TITLE_MAP[currencyKey] || CURRENCY_TITLE_MAP[DEFAULT_CURRENCY_CODE];
};

export const getMultiCurrencyFieldLabel = ({ label, currency }) => {
  return `${label} (${getCurrencyTitle(currency)})`;
};
