import React, { Fragment } from "react";
import PropTypes from "prop-types";

import InputField from "../../components/InputField";

import { getMultiLangFieldLabel } from "../../helpers/multiLangHelper";

import { getErrorMessage } from "../../validators";

const MultiLangExternalBlockLinkFields = ({ onChange, lang, translations, disabled, errors }) => {
  return (
    <Fragment>
      <InputField
        label={getMultiLangFieldLabel({ label: "External URL", lang })}
        type="text"
        name="blockExternalLink.urlML"
        value={translations["blockExternalLink.urlML"][lang] || "http://"}
        onChange={(event) => onChange(event, lang)}
        prefixLabel={<e-icon icon="link" size="small"></e-icon>}
        disabled={disabled}
        errorMsg={getErrorMessage(errors, `blockExternalLink\\.urlML.${lang}`)}
      />
      <InputField
        label={getMultiLangFieldLabel({ label: "URL name", lang })}
        type="text"
        name="blockExternalLink.textML"
        value={translations["blockExternalLink.textML"][lang] || ""}
        onChange={(event) => onChange(event, lang)}
        disabled={disabled}
        errorMsg={getErrorMessage(errors, `blockExternalLink\\.textML.${lang}`)}
      />
    </Fragment>
  );
};

MultiLangExternalBlockLinkFields.propTypes = {
  onChange: PropTypes.func,
  lang: PropTypes.string,
  translations: PropTypes.object,
  disabled: PropTypes.bool
};

export default MultiLangExternalBlockLinkFields;
