import { getMessage } from "../messages";

export const YEARLY_CALCULATION_AFTER_JOIN = "afterJoin";
export const YEARLY_CALCULATION_END_OF_YEAR = "endOfYear";
export const YEARLY_CALCULATION_NO_DROPOUT = "noDropout";

export const PLAN_TYPE_ENUM = {
  POINTS: "points",
  POINTS_AND_BENEFITS: "points_and_benefits",
  SPEND: "spend"
};

export const PLAN_TYPE_OPTIONS = [
  {
    value: PLAN_TYPE_ENUM.POINTS,
    name: getMessage("planType.options.points.name"),
    tooltip: getMessage("planType.options.points.tooltip")
  },
  {
    value: PLAN_TYPE_ENUM.POINTS_AND_BENEFITS,
    name: getMessage("planType.options.points_and_benefits.name"),
    tooltip: getMessage("planType.options.points_and_benefits.tooltip")
  },
  {
    value: PLAN_TYPE_ENUM.SPEND,
    name: getMessage("planType.options.spend.name"),
    tooltip: getMessage("planType.options.spend.tooltip")
  }
];

export const POINTS_ALLOCATION_DELAY_OPTIONS = [
  {
    name: getMessage("pointsAllocatopmDelay.options.1.name"),
    value: "1"
  },
  {
    name: getMessage("pointsAllocatopmDelay.options.2.name"),
    value: "2"
  },
  {
    name: getMessage("pointsAllocatopmDelay.options.3.name"),
    value: "3"
  },
  {
    name: getMessage("pointsAllocatopmDelay.options.7.name"),
    value: "7"
  },
  {
    name: getMessage("pointsAllocatopmDelay.options.14.name"),
    value: "14"
  },
  {
    name: getMessage("pointsAllocatopmDelay.options.21.name"),
    value: "21"
  },
  {
    name: getMessage("pointsAllocatopmDelay.options.30.name"),
    value: "30"
  },
  {
    name: getMessage("pointsAllocatopmDelay.options.40.name"),
    value: "40"
  }
];

// export const YEARLY_CALCULATION_OPTIONS = [
//   {
//     name: "Yearly total status points is reset every December 31st",
//     value: `${YEARLY_CALCULATION_END_OF_YEAR}_12`
//   },
//   {
//     name: "Yearly total status points is reset every 12 months from the date of joining",
//     value: `${YEARLY_CALCULATION_AFTER_JOIN}_12`
//   },
//   {
//     name: "None, status points are not calculated or reset (no dropout scenario)",
//     value: YEARLY_CALCULATION_NO_DROPOUT
//   }
// ];

export const TIERS_CALCULATION_TYPE_ENUM = {
  END_OF_YEAR: "endOfYear",
  AFTER_JOIN: "afterJoin",
  NO_DROPOUT: "noDropout"
};

export const TIERS_CALCULATION_TYPE_OPTIONS = [
  {
    name: getMessage("tierCalculationType.options.endOfYear.name"),
    value: TIERS_CALCULATION_TYPE_ENUM.END_OF_YEAR,
    tooltip: getMessage("tierCalculationType.options.endOfYear.tooltip")
  },
  {
    name: getMessage("tierCalculationType.options.afterJoin.name"),
    value: TIERS_CALCULATION_TYPE_ENUM.AFTER_JOIN,
    tooltip: getMessage("tierCalculationType.options.afterJoin.tooltip")
  },
  {
    name: getMessage("tierCalculationType.options.noDropout.name"),
    value: TIERS_CALCULATION_TYPE_ENUM.NO_DROPOUT,
    tooltip: getMessage("tierCalculationType.options.noDropout.tooltip")
  }
];

export const TIERS_CALCULATION_TYPE_OPTIONS_SPEND = [
  {
    name: getMessage("tierCalculationType.options.endOfYear.name"),
    value: TIERS_CALCULATION_TYPE_ENUM.END_OF_YEAR,
    tooltip: getMessage("tierCalculationType.options.endOfYear.spend.tooltip")
  },
  {
    name: getMessage("tierCalculationType.options.afterJoin.name"),
    value: TIERS_CALCULATION_TYPE_ENUM.AFTER_JOIN,
    tooltip: getMessage("tierCalculationType.options.afterJoin.tooltip")
  },
  {
    name: getMessage("tierCalculationType.options.noDropout.name"),
    value: TIERS_CALCULATION_TYPE_ENUM.NO_DROPOUT,
    tooltip: getMessage("tierCalculationType.options.noDropout.tooltip")
  }
];

export const STATUS_POINTS_EXPIRATION_TYPE_ENUM = {
  TIERS_CALC: "tiersCalc",
  FROM_EARN: "fromEarn",
  NEVER: "never"
};

export const STATUS_POINTS_EXPIRATION_TYPE_OPTIONS = [
  {
    name: getMessage("statusPointsExpirationType.options.tierCalc.name"),
    value: STATUS_POINTS_EXPIRATION_TYPE_ENUM.TIERS_CALC,
    tooltip: getMessage("statusPointsExpirationType.options.tierCalc.tooltip")
  },
  {
    name: getMessage("statusPointsExpirationType.options.fromEarn.name"),
    value: STATUS_POINTS_EXPIRATION_TYPE_ENUM.FROM_EARN,
    tooltip: getMessage("statusPointsExpirationType.options.fromEarn.tooltip")
  },
  {
    name: getMessage("statusPointsExpirationType.options.never.name"),
    value: STATUS_POINTS_EXPIRATION_TYPE_ENUM.NEVER
  }
];

export const LOYALTY_CREDIT_EXPIRATION_TYPE_OPTIONS = [
  {
    name: getMessage("loyaltyCreditExpirationType.options.tiersCalc.name"),
    value: STATUS_POINTS_EXPIRATION_TYPE_ENUM.TIERS_CALC,
    tooltip: getMessage("loyaltyCreditExpirationType.options.tiersCalc.tooltip")
  },
  {
    name: getMessage("loyaltyCreditExpirationType.options.fromEarn.name"),
    value: STATUS_POINTS_EXPIRATION_TYPE_ENUM.FROM_EARN,
    tooltip: getMessage("loyaltyCreditExpirationType.options.fromEarn.tooltip")
  }
];

export const OPT_IN_ENUM = {
  AUTOMATIC: "automatic",
  INVITATION: "invitation"
};

export const OPT_IN_OPTIONS = [
  {
    name: getMessage("optIn.options.invitation.name"),
    value: OPT_IN_ENUM.INVITATION
  },
  {
    name: getMessage("optIn.options.automatic.name"),
    value: OPT_IN_ENUM.AUTOMATIC
  }
];

// export const LAUNCH_TYPE_ENUM = {
//   SINGLE_TIER: "singleTier",
//   MULTI_TIER: "multiTier"
// };

// export const LAUNCH_TYPE_OPTIONS = [
//   {
//     name: "Start with one single tier and add all new customers to it. You can add more tiers later.",
//     value: LAUNCH_TYPE_ENUM.SINGLE_TIER,
//     tooltip: `The ideal option for a quick start. As the customers begin to form cohorts within the tier you can organically extend your plan by creating additional tiers.`
//   },
//   {
//     name: "Start with tiers you created in advance and manage your contacts distribution among them, before launching your plan.",
//     value: LAUNCH_TYPE_ENUM.MULTI_TIER,
//     tooltip: `The ideal option to properly treat customers who are already loyal and distribute them among tiers based on their purchase history.`
//   }
// ];

export const MODE_TYPE_ENUM = {
  EDIT_NEXT: "editNextVersion",
  LIVE_VERSION: "liveVersion"
};

export const MODE_TYPE_OPTIONS = [
  {
    name: getMessage("updateAndLaunch.selectVersionMode.options.editNext.name"),
    value: MODE_TYPE_ENUM.EDIT_NEXT
  },
  {
    name: getMessage("updateAndLaunch.selectVersionMode.options.liveVersion.name"),
    value: MODE_TYPE_ENUM.LIVE_VERSION
  }
];

export const CONTACT_DISTRIBUTION_ENUM = {
  FIRST_TIER: "firstTier",
  TIER_BY_PERIOD: "tierByPeriod"
};

export const CONTACT_DISTRIBUTION_OPTIONS = [
  {
    name: getMessage("membershipSettings.contactDistributionRb.options.firstTier.name"),
    value: CONTACT_DISTRIBUTION_ENUM.FIRST_TIER,
    tooltip: getMessage("membershipSettings.contactDistributionRb.options.firstTier.tooltip")
  },
  {
    name: getMessage("membershipSettings.contactDistributionRb.options.tierByPeriod.name"),
    value: CONTACT_DISTRIBUTION_ENUM.TIER_BY_PERIOD,
    tooltip: getMessage("membershipSettings.contactDistributionRb.options.tierByPeriod.tooltip")
  }
  // {
  //   name: "Add new members to the bottom tier.",
  //   value: CONTACT_DISTRIBUTION_ENUM.FIRST_TIER_NO_POINTS,
  //   tooltip: `Ideal option to start a new Loyalty plan from scratch with an equal starting line for all members. `
  // },
  // {
  //   name: `Add existing contacts to a tier according their historical spend, and new ones to the bottom tier. </br> No points are awarded to either.`,
  //   value: CONTACT_DISTRIBUTION_ENUM.TIERS_NO_POINTS,
  //   tooltip: `The ideal option to incentivise and reward contacts who were already loyal before the start of the plan.`
  // },
  // {
  //   name:
  //     "Add existing and new contacts to a tier according their historical spend so far this calendar year, and award additional points to spend.",
  //   value: CONTACT_DISTRIBUTION_ENUM.TIERS_AND_POINTS,
  //   tooltip: `The most stimulating and rewarding option to encourage contacts to join the plan.`
  // }
];

export const CONTACT_DISTRIBUTION_CALC_TYPE_ENUM = {
  BEGINNING_OF_YEAR: "beginningOfYear",
  BY_PERIOD: "byPeriod"
};

export const CONTACT_DISTRIBUTION_CALC_TYPE_OPTIONS = [
  {
    name: getMessage("membershipSettings.calculationPeriodRb.options.beginOfYear.name"),
    value: CONTACT_DISTRIBUTION_CALC_TYPE_ENUM.BEGINNING_OF_YEAR
  },
  {
    name: getMessage("membershipSettings.calculationPeriodRb.options.byPeriod.name"),
    value: CONTACT_DISTRIBUTION_CALC_TYPE_ENUM.BY_PERIOD
  }
];

export const HISTORICAL_BALANCE_POINTS_OPTIONS = [
  {
    value: "keep",
    name: getMessage("historicalBalancePoints.options.keep.name"),
    tooltip: getMessage("historicalBalancePoints.options.keep.tooltip")
  },
  {
    value: "remove",
    name: getMessage("historicalBalancePoints.options.remove.name"),
    tooltip: getMessage("historicalBalancePoints.options.remove.tooltip")
  }
];

export const MULTI_LANG_PROPS_MAP = {
  "planSettings.nameML": "planSettings.name",
  "planSettings.pointsNameML": "planSettings.pointsName",
  "planSettings.singlePointNameML": "planSettings.singlePointName",
  "planSettings.cashbackNameML": "planSettings.cashbackName"
};

export const LOYALTY_CREDIT_NAME = "Loyalty credit";

export const CURRENCY_IDENTIFIER_SIGN_ENUM = {
  CURRENCY_SYMBOL: "symbol",
  ISO_IDENTIFIER: "code",
  NONE: "none"
};

export const CURRENCY_IDENTIFIER_SIGN_OPTIONS = [
  {
    name: getMessage("currencyIdentifierSigh.options.symbol.name"),
    value: CURRENCY_IDENTIFIER_SIGN_ENUM.CURRENCY_SYMBOL,
    tooltip: getMessage("currencyIdentifierSigh.options.symbol.tooltip")
  },
  {
    name: getMessage("currencyIdentifierSigh.options.code.name"),
    value: CURRENCY_IDENTIFIER_SIGN_ENUM.ISO_IDENTIFIER,
    tooltip: getMessage("currencyIdentifierSigh.options.code.tooltip")
  },
  {
    name: getMessage("currencyIdentifierSigh.options.none.name"),
    value: CURRENCY_IDENTIFIER_SIGN_ENUM.NONE
  }
];

export const CASHBACK_LIMITATION_ENUM = {
  CASHBACK_LIMITATION_LIMITED: "limited",
  CASHBACK_LIMITATION_UNLIMITED: "unlimited"
};

export const CASHBACK_LIMITATION_OPTIONS = [
  {
    name: getMessage("cashbackSettings.cashbackLimit.rb.option.limited"),
    value: CASHBACK_LIMITATION_ENUM.CASHBACK_LIMITATION_LIMITED
  },
  {
    name: getMessage("cashbackSettings.cashbackLimit.rb.option.unlimited"),
    value: CASHBACK_LIMITATION_ENUM.CASHBACK_LIMITATION_UNLIMITED
  }
];
