import React, { useState } from "react";
import classNames from "classnames";
import fileDownload from "js-file-download";

const DownloadButton = (props) => {
  const { apiParams, apiFunc, buttonTxt, tooltip, csvName } = props;
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    setLoading(true);

    apiFunc(apiParams)
      .then((data) => fileDownload(data, csvName))
      .finally(() => setLoading(false));
  };

  let btnAttr = {};
  if (loading) {
    btnAttr.disabled = true;
  }

  const renderTooltip = (content) => {
    if (!!tooltip) {
      return (
        <e-tooltip content={tooltip} block>
          {content}
        </e-tooltip>
      );
    } else return content;
  };

  const renderContent = () => {
    return (
      <button className="e-btn" onClick={onClick} {...btnAttr}>
        <div className={classNames("e-btn__loading", { "e-btn__loading-active": loading })}>
          <e-spinner data-size="small"></e-spinner>
          <e-icon icon="e-download"></e-icon>
        </div>
        {buttonTxt}
      </button>
    );
  };

  return renderTooltip(renderContent());
};

export default DownloadButton;

DownloadButton.propTypes = {};
