import React from "react";
import PropTypes from "prop-types";

import InputField from "../../components/InputField";
import TextareaField from "../../components/TextareaField";
import CheckboxField from "../../components/CheckboxField";
import { EnvelopeSvg, FacebookSvg, WhatsappSvg, TwitterSvg } from "../../components/ActionSVGs";

import { getErrorMessage } from "../../validators";

import { REWARD_TOKEN } from "../../constants/actionConstants";
import { actionType } from "../../types";

import "../../assets/styles/ReferralContentForm.scss";
import { getMessage } from "../../messages";

function getFieldNameFunc({ pathPrefix, selectedLang, primaryLang }) {
  return (name) => {
    // if (selectedLang && selectedLang !== primaryLang) {
    //   // return `referralContent.referrer.${name}ML.${selectedLang}`;
    //   return `${pathPrefix}.${name}ML.${selectedLang}`;
    // }
    return `${pathPrefix}.${name}`;
  };
}

function getFieldValueFunc({ contentObj, selectedLang, primaryLang }) {
  return (name) => {
    if (selectedLang && selectedLang !== primaryLang) {
      return contentObj[`${name}ML`] ? contentObj[`${name}ML`][selectedLang] : "";
    }
    return contentObj[name];
  };
}

export const ReferralContentReferrerForm = ({ action, onInputChange, selectedLang, primaryLang, errors, viewMode }) => {
  const getFieldName = getFieldNameFunc({ pathPrefix: "referralContent.referrer", selectedLang, primaryLang });
  const getFieldValue = getFieldValueFunc({ contentObj: action.referralContent.referrer, selectedLang, primaryLang });

  function onChange(event, customValue) {
    onInputChange(event, selectedLang, customValue);
  }

  return (
    <div className="content-referrer e-row">
      <div className="e-col-12">
        <h2>
          {getMessage("referralContentReferrerForm.header")}{" "}
          <e-tooltip type="helper" content={getMessage("referralContentReferrerForm.tooltip")} />
        </h2>
      </div>
      <div className="e-col-12">
        <InputField
          label={getMessage("referralContentReferrerForm.title1")}
          type="text"
          name={getFieldName("title")}
          value={getFieldValue("title")}
          disabled={viewMode}
          onChange={onChange}
          errorMsg={getErrorMessage(errors, getFieldName("title"))}
        />
      </div>
      <div className="e-col-12">
        <TextareaField
          label={getMessage("referralContentReferrerForm.text")}
          name={getFieldName("text")}
          value={getFieldValue("text") || REWARD_TOKEN.token}
          disabled={viewMode}
          onChange={onChange}
          errorMsg={getErrorMessage(errors, getFieldName("text"))}
          tokenObj={REWARD_TOKEN}
          height="70px"
        />
      </div>
      <div className="e-col-6">
        <InputField
          label={getMessage("referralContentReferrerForm.title2")}
          type="text"
          name={getFieldName("subtitle")}
          value={getFieldValue("subtitle")}
          disabled={viewMode}
          onChange={onChange}
          errorMsg={getErrorMessage(errors, getFieldName("subtitle"))}
        />
      </div>
      <div className="e-col-6">
        <InputField
          label={getMessage("referralContentReferrerForm.landingPageUrl.label")}
          type="text"
          name={getFieldName("landingPageUrl")}
          value={getFieldValue("landingPageUrl") || "http://"}
          disabled={viewMode}
          onChange={onChange}
          errorMsg={getErrorMessage(errors, getFieldName("landingPageUrl"))}
          tooltip={getMessage("referralContentReferrerForm.landingPageUrl.tooltip")}
        />
      </div>
      <div className="e-col-12">
        <div className="e-field">
          <label className="e-field__label">
            {getMessage("referralContentReferrerForm.header.sharingChannels")}{" "}
            <e-tooltip
              type="helper"
              content={getMessage("referralContentReferrerForm.header.sharingChannels.tooltip")}
            />
          </label>
          <div className="sharing-channels">
            <CheckboxField
              inline={true}
              onlyCheckbox={true}
              name={getFieldName("sharingChannels.showEmail")}
              checked={action.referralContent.referrer.sharingChannels.showEmail}
              onChange={onChange}
              disabled={viewMode || primaryLang != selectedLang}
            />
            <EnvelopeSvg width={25} height={25} />
            <CheckboxField
              inline={true}
              onlyCheckbox={true}
              name={getFieldName("sharingChannels.showFacebook")}
              checked={action.referralContent.referrer.sharingChannels.showFacebook}
              onChange={onChange}
              disabled={viewMode || primaryLang != selectedLang}
            />
            <FacebookSvg width={16} height={25} />
            <CheckboxField
              inline={true}
              onlyCheckbox={true}
              name={getFieldName("sharingChannels.showWhatsapp")}
              checked={action.referralContent.referrer.sharingChannels.showWhatsapp}
              onChange={onChange}
              disabled={viewMode || primaryLang != selectedLang}
            />
            <WhatsappSvg width={25} height={25} />
            <CheckboxField
              inline={true}
              onlyCheckbox={true}
              name={getFieldName("sharingChannels.showTwitter")}
              checked={action.referralContent.referrer.sharingChannels.showTwitter}
              onChange={onChange}
              disabled={viewMode || primaryLang != selectedLang}
            />
            <TwitterSvg width={25} height={25} />
          </div>
        </div>
      </div>
      <div className="e-col-12">
        <InputField
          label={getMessage("referralContentReferrerForm.consentText.label")}
          type="text"
          name={getFieldName("consentText")}
          value={getFieldValue("consentText")}
          disabled={viewMode}
          onChange={onChange}
          errorMsg={getErrorMessage(errors, getFieldName("consentText"))}
          tooltip={getMessage("referralContentReferrerForm.consentText.tooltip")}
        />
      </div>
      <div className="e-col-6">
        <InputField
          label={getMessage("referralContentReferrerForm.consentLinkText.label")}
          type="text"
          name={getFieldName("consentLinkText")}
          value={getFieldValue("consentLinkText")}
          disabled={viewMode}
          onChange={onChange}
          errorMsg={getErrorMessage(errors, getFieldName("consentLinkText"))}
        />
      </div>
      <div className="e-col-6">
        <InputField
          label={getMessage("referralContentReferrerForm.consentLinkUrl.label")}
          type="text"
          name={getFieldName("consentLinkUrl")}
          value={getFieldValue("consentLinkUrl") || "http://"}
          disabled={viewMode}
          onChange={onChange}
          errorMsg={getErrorMessage(errors, getFieldName("consentLinkUrl"))}
        />
      </div>
    </div>
  );
};

export const ReferralContentRefereeForm = ({ action, onInputChange, selectedLang, primaryLang, errors, viewMode }) => {
  const getFieldName = getFieldNameFunc({ pathPrefix: "referralContent.referee", selectedLang, primaryLang });
  const getFieldValue = getFieldValueFunc({ contentObj: action.referralContent.referee, selectedLang, primaryLang });

  function onChange(event) {
    onInputChange(event, selectedLang);
  }

  return (
    <div className="e-row">
      <div className="e-col-12">
        <h2>
          {getMessage("referralContentRefereeForm.header")}{" "}
          <e-tooltip type="helper" content={getMessage("referralContentRefereeForm.header.tooltip")} />
        </h2>
      </div>
      <div className="e-col-12">
        <InputField
          label={getMessage("referralContentRefereeForm.title1")}
          type="text"
          name={getFieldName("title")}
          value={getFieldValue("title")}
          disabled={viewMode}
          onChange={onChange}
          errorMsg={getErrorMessage(errors, getFieldName("title"))}
        />
      </div>
      <div className="e-col-12">
        <InputField
          label={getMessage("referralContentRefereeForm.text1")}
          type="text"
          name={getFieldName("text")}
          value={getFieldValue("text")}
          disabled={viewMode}
          onChange={onChange}
          errorMsg={getErrorMessage(errors, getFieldName("text"))}
        />
      </div>
      <div className="e-col-12">
        <InputField
          label={getMessage("referralContentRefereeForm.title2")}
          type="text"
          name={getFieldName("secondaryTitle")}
          value={getFieldValue("secondaryTitle")}
          disabled={viewMode}
          onChange={onChange}
          errorMsg={getErrorMessage(errors, getFieldName("secondaryTitle"))}
        />
      </div>
      <div className="e-col-12">
        <InputField
          label={getMessage("referralContentRefereeForm.inputFieldPlaceholder")}
          type="text"
          name={getFieldName("inputFieldPlaceholder")}
          value={getFieldValue("inputFieldPlaceholder")}
          disabled={viewMode}
          onChange={onChange}
          errorMsg={getErrorMessage(errors, getFieldName("inputFieldPlaceholder"))}
        />
      </div>
      <div className="e-col-12">
        <TextareaField
          label={getMessage("referralContentRefereeForm.text2")}
          type="text"
          name={getFieldName("secondaryText")}
          value={getFieldValue("secondaryText")}
          disabled={viewMode}
          onChange={onChange}
          errorMsg={getErrorMessage(errors, getFieldName("secondaryText"))}
        />
      </div>
      <div className="e-col-12">
        <InputField
          label={getMessage("referralContentRefereeForm.consentText.label")}
          type="text"
          name={getFieldName("consentText")}
          value={getFieldValue("consentText")}
          disabled={viewMode}
          onChange={onChange}
          errorMsg={getErrorMessage(errors, getFieldName("consentText"))}
          tooltip={getMessage("referralContentRefereeForm.consentText.tooltip")}
        />
      </div>
      <div className="e-col-6">
        <InputField
          label={getMessage("referralContentRefereeForm.consentLinkText")}
          type="text"
          name={getFieldName("consentLinkText")}
          value={getFieldValue("consentLinkText")}
          disabled={viewMode}
          onChange={onChange}
          errorMsg={getErrorMessage(errors, getFieldName("consentLinkText"))}
        />
      </div>
      <div className="e-col-6">
        <InputField
          label={getMessage("referralContentRefereeForm.consentLinkUrl")}
          type="text"
          name={getFieldName("consentLinkUrl")}
          value={getFieldValue("consentLinkUrl") || "http://"}
          disabled={viewMode}
          onChange={onChange}
          errorMsg={getErrorMessage(errors, getFieldName("consentLinkUrl"))}
        />
      </div>
      {/* <div className="e-col-12">
        <CheckboxField
          label="Secondary statement of consent"
          name={getFieldName("secondaryConsentEnabled")}
          checked={getFieldValue("secondaryConsentEnabled")}
          onChange={onChange}
          tooltip="You can add an optional second consent request, for example pointing to a different document or asking to subscribe to marketing communications."
        />
      </div>
      {getFieldValue("secondaryConsentEnabled") && (
        <Fragment>
          <div className="e-col-12">
            <InputField
              type="text"
              name={getFieldName("secondaryConsentText")}
              value={getFieldValue("secondaryConsentText")}
              onChange={onChange}
              errorMsg={getErrorMessage(errors, getFieldName("secondaryConsentText"))}
            />
          </div>
          <div className="e-col-6">
            <InputField
              label="Link text"
              type="text"
              name={getFieldName("secondaryConsentLinkText")}
              value={getFieldValue("secondaryConsentLinkText")}
              onChange={onChange}
              errorMsg={getErrorMessage(errors, getFieldName("secondaryConsentLinkText"))}
            />
          </div>
          <div className="e-col-6">
            <InputField
              label="Link URL"
              type="text"
              name={getFieldName("secondaryConsentLinkUrl")}
              value={getFieldValue("secondaryConsentLinkUrl") || "http://"}
              onChange={onChange}
              errorMsg={getErrorMessage(errors, getFieldName("secondaryConsentLinkUrl"))}
            />
          </div>
        </Fragment>
      )} */}
      <div className="e-col-12">
        <InputField
          label={getMessage("ctaText")}
          type="text"
          name={getFieldName("ctaText")}
          value={getFieldValue("ctaText")}
          disabled={viewMode}
          onChange={onChange}
          errorMsg={getErrorMessage(errors, getFieldName("ctaText"))}
        />
      </div>
    </div>
  );
};

const requiredPropTypes = {
  action: actionType.isRequired,
  errors: PropTypes.object,
  onInputChange: PropTypes.func,
  selectedLang: PropTypes.string,
  primaryLang: PropTypes.string,
  viewMode: PropTypes.bool
};

ReferralContentReferrerForm.propTypes = requiredPropTypes;
ReferralContentRefereeForm.propTypes = requiredPropTypes;
