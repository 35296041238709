import React from "react";
import PropTypes from "prop-types";
import sanitizeHtml from "sanitize-html";

import "../assets/styles/ActionMessagePreview.scss";

const ActionMessagePreview = (props) => {
  const { message, ctaButton } = props;

  const renderCloseButton = () => {
    if (!props.showClose) {
      return null;
    }

    return (
      <div className="l-action-message-close">
        <e-icon icon="close" color="shade" />
      </div>
    );
  };

  return (
    <div className="l-action-message">
      {renderCloseButton()}
      <table className={`l-action-message-content ${props.showClose ? "has-close" : ""}`}>
        <tbody>
          <tr>
            <td>
              <div className="l-action-message-icon">
                <e-icon icon="ac-cart_outline" />
              </div>
            </td>
            <td>
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(props.replaceRewardToken(message))
                }}
              />
              <a> {ctaButton}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

ActionMessagePreview.propTypes = {
  message: PropTypes.string,
  ctaButton: PropTypes.string,
  replaceRewardToken: PropTypes.func,
  showClose: PropTypes.bool
};

export default ActionMessagePreview;
