import { useState, useEffect } from "react";
import planApi from "../api/planApi";
import planService from "../services/planService";

const useDefaultPlan = () => {
  const [defaultPlan, setDefaultPlan] = useState({});
  const [isDefaultPlanLaunched, setIsDefaultPlanLaunched] = useState(null);
  const [error, setError] = useState(null);

  async function loadData() {
    try {
      const plans = await planApi.getAllPlans();
      const firstPlan = plans[0];
      setDefaultPlan(firstPlan);
      setIsDefaultPlanLaunched(planService.isLaunched(firstPlan));
    } catch (e) {
      setError(e);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  return {
    defaultPlan,
    isDefaultPlanLaunched,
    defaultPlanError: error
  };
};

export default useDefaultPlan;
