import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useInterval from "react-useinterval";

import Notification from "../components/Notification";
import LoaderOverlay from "../components/LoaderOverlay";

import MemberProfileHeader from "../views/MemberProfile/MemberProfileHeader";
import MemberProfileDetails from "../views/MemberProfile/MemberProfileDetails";
import GroupMembersTable from "../views/MemberProfile/GroupMembersTable";
import CreateGroupPopup from "../views/MemberProfile/CreateGroupPopup";
import GroupProfileActivity from "../views/MemberProfile/GroupProfileActivity";
import MemberProfileUpdatePointsPopup from "../views/MemberProfile/MemberProfileUpdatePointsPopup";
import MemberProfileUpdateTierPopup from "../views/MemberProfile/MemberProfileUpdateTierPopup";

import { showErrorAlert } from "../actions/alertActions";

import memberProfileApi from "../api/memberProfileApi";
import planApi from "../api/planApi";
import customerApi from "../api/customerApi";
import planService from "../services/planService";

import { openDeleteDialog } from "../helpers/dialogHelper";
import { formatPointsNameText } from "../helpers/pointsHelper";
import { navigateToMemberProfile } from "../helpers/navHelper";
import { isEmsSuite } from "../helpers/emsHelper";

import { isAccountViewOnlySupport } from "../reducers";
import { getMaxListeners } from "process";
import { getMessage } from "../messages";

const MemberGroupProfile = (props) => {
  const TABS = {
    MEMBERS: "members",
    ACTIVITY: "activity"
  };

  const dispatch = useDispatch();
  const [groupDetails, setGroupDetails] = useState({});
  const [groupMembers, setGroupMembers] = useState([]);
  const [externalIdsMap, setExternalIdsMap] = useState(null);
  const [groupActivities, setGroupActivities] = useState([]);
  const [pointsNameObj, setPointsNameObj] = useState(undefined);
  const [showPointsUpdatePopup, setShowPointsUpdatePopup] = useState(false);
  const [showTierUpdatePopup, setShowTierUpdatePopup] = useState(false);
  const [showGroupPopup, setShowGroupPopup] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [loadingMembers, setLoadingMembers] = useState(true);
  const [currentTab, setCurrentTab] = useState(TABS.MEMBERS);
  const [plan, setPlan] = useState(undefined);
  const [emsAccountsMap, setEmsAccountsMap] = useState({});

  const groupProfileDisplayId = sessionStorage.getItem("groupProfileDisplayId");
  const groupId = props.match.params.id;
  const isViewOnly = useSelector((state) => isAccountViewOnlySupport(state));

  let unmounted = useRef(false);

  useEffect(() => {
    async function fetchData() {
      loadData({ setLoading: true, doGetPlan: true });
      return () => {
        unmounted.current = true;
      };
    }

    fetchData();
  }, []);

  async function loadData({ setLoading, doGetPlan }) {
    if (setLoading) {
      setLoadingDetails(true);
      setLoadingMembers(true);
    }

    if (await isEmsSuite()) {
      await loadEmsAccountsMap();
    }
    loadGroupDetails(doGetPlan);
    loadMembersAndActivities(true);
  }

  async function loadEmsAccountsMap() {
    try {
      const emsAccountsMap = await customerApi.getEmsAccountsMap();
      setEmsAccountsMap(emsAccountsMap);
    } catch (error) {
      console.error(error);
    }
  }

  async function loadGroupDetails(doGetPlan) {
    try {
      const details = await memberProfileApi.getGroup(groupId);
      if (unmounted.current) {
        return;
      }
      setGroupDetails(details);

      if (details && doGetPlan) {
        const groupPlan = await planApi.getPlan(details.plan.planId);
        const pointsNameObj = planService.getPointsNameObj(groupPlan);
        setPointsNameObj(pointsNameObj);
        setPlan(groupPlan);
      }
    } catch (error) {
      throw error;
    } finally {
      if (unmounted.current) {
        return;
      }
      setLoadingDetails(false);
    }
  }

  async function loadMembersAndActivities(resolveExternalIds) {
    const promises = [memberProfileApi.getGroupMembers(groupId), memberProfileApi.getGroupActivities(groupId)];

    await Promise.all(promises)
      .then(async (response) => {
        if (unmounted.current) {
          return;
        }

        const members = response[0];
        const activities = response[1];

        if (resolveExternalIds) {
          let contactIdsMap = {};
          members.forEach((member) => (contactIdsMap[member.contactId] = true));
          activities.forEach((activity) =>
            activity.elements.forEach((elem) => {
              if (elem.contactId) {
                contactIdsMap[elem.contactId] = true;
              }
            })
          );

          const contactIdsArr = Object.keys(contactIdsMap).map((contactId) => contactId);
          try {
            const map = await memberProfileApi.resolveContactIds(contactIdsArr);
            setExternalIdsMap(map || {});
          } catch (error) {
            setExternalIdsMap({});
          }
        }

        setGroupMembers(members);
        setGroupActivities(activities);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        if (unmounted.current) {
          return;
        }
        setLoadingMembers(false);
      });
  }

  async function removeFromGroup(contactId) {
    const func = async () => {
      try {
        await memberProfileApi.removeContactFromGroup(groupId, contactId);
      } catch (error) {
        dispatch(showErrorAlert(getMessage("memberGroupProfile.removeFromGroup.error")));
        throw error;
      }
    };

    updateApiAndRefresh(func);
  }

  async function onUpdateGroupPoints({ pointsType, pointsUpdateType, pointsValue, reason, description }) {
    const func = async () => {
      setShowPointsUpdatePopup(false);

      try {
        await memberProfileApi.updateGroupPoints(groupId, {
          pointsType,
          pointsUpdateType,
          pointsValue,
          reason,
          description
        });
      } catch (error) {
        dispatch(showErrorAlert(getMessage("memberGroupProfile.updateGroupPoints.error")));
        console.error(error);
      }
    };

    updateApiAndRefresh(func);
  }

  async function onUpdateGroupTier({ tierUpdateType, selectedTierId, reason, activityName }) {
    const func = async () => {
      setShowTierUpdatePopup(false);
      try {
        const data = {
          tierUpdateType,
          selectedTierId,
          reason,
          activityName,
          currentTierId: groupDetails.tier.tierId
        };
        await memberProfileApi.updateGroupTier(groupId, data);
      } catch (error) {
        dispatch(showErrorAlert(getMessage("memberGroupProfile.updateGroupTier.error")));
        throw error;
      }
    };

    updateApiAndRefresh(func);
  }

  async function updateApiAndRefresh(updateFunc) {
    setLoadingDetails(true);
    setLoadingMembers(true);

    try {
      if (updateFunc) {
        await updateFunc();
      }
      let interval;
      let intervalCounter = 0;
      {
        interval = setInterval(() => {
          if (intervalCounter < 3) {
            loadData({ setLoading: false });
            intervalCounter++;
          } else if (interval) {
            clearInterval(interval);
          }
        }, 3000);
      }
    } catch (error) {
      throw error;
    }
  }

  function getPointsText(text, pointsValue) {
    return formatPointsNameText({ text, pointsValue, namesObj: pointsNameObj });
  }

  function onMemberProfile(contactId, externalId) {
    const { history, supportMode, location } = { ...props };
    navigateToMemberProfile({ history, supportMode, contactId, externalId });
  }

  function onRemoveFromGroup(contactId) {
    openDeleteDialog(
      getMessage("memberGroupProfile.removeFromGroup.deleteDialog.title"),
      getMessage("memberGroupProfile.removeFromGroup.deleteDialog.content", {
        pointType: !planService.isSpendPlan(plan) ? "status points" : "loyalty credit"
      }),
      () => removeFromGroup(contactId)
    );
  }

  function onAddNewMembers() {
    updateApiAndRefresh();
  }

  function renderGroupDetails() {
    // const contactDetails = this.state.contactDetails || {};
    // const tier = contactDetails.tier || {};
    // const plan = contactDetails.plan || {};
    return (
      <MemberProfileDetails
        tier={groupDetails.tier}
        nextTierPoints={groupDetails.nextTier ? groupDetails.nextTier.points - groupDetails.points.status : 0}
        nextTierPurchases={groupDetails.nextTier ? groupDetails.nextTier.purchases - groupDetails.purchases : 0}
        planId={groupDetails?.plan?.planId}
        createdAt={groupDetails.createdAt}
        tierExp={groupDetails.tierExp}
        groupMembersNum={groupDetails.members?.length}
        pointsObj={groupDetails.points}
        groupName={groupDetails.name}
        pointsNameObj={pointsNameObj}
        loading={loadingDetails}
        plan={plan}
      />
    );
  }

  function renderBottomSection() {
    return (
      <div className="e-box e-box-nopadding e-margin-top-l">
        <div className="member-activity">
          <div className="e-tabs">
            <input
              type="radio"
              name="tabs"
              id="tabs-member"
              checked={currentTab === TABS.MEMBERS}
              onChange={() => setCurrentTab(TABS.MEMBERS)}
            />
            <label className="e-tabs__title" htmlFor="tabs-member">
              <span className="e-tabs__separator">{getMessage("memberGroupProfile.sperator.members")}</span>
            </label>
            <input
              type="radio"
              name="tabs"
              id="tabs-activity"
              checked={currentTab === TABS.ACTIVITY}
              onChange={() => setCurrentTab(TABS.ACTIVITY)}
            />
            <label className="e-tabs__title" htmlFor="tabs-activity">
              <span className="e-tabs__separator">{getMessage("memberGroupProfile.sperator.activity")}</span>
            </label>
            <div className="e-tabs__panel" style={{ position: "relative" }}>
              <div className="e-tabs__panel__content">
                <GroupMembersTable
                  members={groupMembers}
                  onMemberProfile={onMemberProfile}
                  onRemoveFromGroup={onRemoveFromGroup}
                  externalIdsMap={externalIdsMap}
                  disableRemoveFromGroup={isViewOnly}
                />
              </div>
              <div className="e-tabs__panel__content">
                {groupActivities && externalIdsMap && (
                  <GroupProfileActivity
                    originalActivities={groupActivities}
                    pointsNameObj={pointsNameObj}
                    externalIdsMap={externalIdsMap}
                    plan={plan}
                    emsAccountsMap={emsAccountsMap}
                  />
                )}
              </div>
              <LoaderOverlay show={loadingMembers} noOpacity={true} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  let buttonsAttr = {};
  if (!groupDetails || isViewOnly) {
    buttonsAttr["disabled"] = true;
  }

  return (
    <div className="member-profile e-layout e-layout-without_navigation">
      <MemberProfileHeader title={getMessage("memberGroupProfile.title")} />
      <main className="e-layout__content">
        <section className="e-layout__section l-member-profile-section">
          <Fragment>
            <div className="e-header">
              <h2 className="e-header__title">
                {getMessage("memberGroupProfile.headerTitle", { groupId: groupProfileDisplayId })}
              </h2>
              <div className="e-header__button">
                <div className="e-buttongroup">
                  <button className="e-btn e-btn-onlyicon" onClick={() => loadData({ setLoading: true })}>
                    <e-icon icon="e-refresh"></e-icon>
                  </button>
                  <button
                    className="e-btn e-btn-primary"
                    onClick={() => setShowPointsUpdatePopup(true)}
                    {...buttonsAttr}
                  >
                    {getMessage("memberGroupProfile.updatePointsBtn.label", {
                      isPoints: !planService.isSpendPlan(plan).toString()
                    })}
                  </button>
                  <button
                    className="e-btn e-btn-secondary"
                    onClick={() => setShowTierUpdatePopup(true)}
                    {...buttonsAttr}
                  >
                    {getMessage("memberGroupProfile.updateTierBtn.label")}
                  </button>
                  <button className="e-btn e-btn-primary" onClick={() => setShowGroupPopup(true)} {...buttonsAttr}>
                    {getMessage("memberGroupProfile.addNewMembersBtn.label")}
                  </button>
                </div>
              </div>
            </div>
            <Notification content={getMessage("memberGroupProfile.notification")} />

            {renderGroupDetails()}

            {renderBottomSection()}
          </Fragment>

          <Fragment>
            {groupDetails.points && (
              <MemberProfileUpdatePointsPopup
                isShow={showPointsUpdatePopup}
                isGroup={true}
                isGroupTooltip={true}
                onClose={() => setShowPointsUpdatePopup(false)}
                contactPoints={groupDetails.points}
                onUpdatePoints={onUpdateGroupPoints}
                getPointsText={getPointsText}
                contactPlanId={groupDetails.plan.planId}
                plan={plan}
              />
            )}
            {groupDetails.plan && (
              <CreateGroupPopup
                show={showGroupPopup}
                onPopupClose={() => setShowGroupPopup(false)}
                groupPlanId={groupDetails.plan.planId}
                groupId={groupId}
                onSave={onAddNewMembers}
                planType={groupDetails.plan.planType}
              />
            )}
            {groupDetails.plan && groupDetails.tier && (
              <MemberProfileUpdateTierPopup
                isShow={showTierUpdatePopup}
                isGroupTooltip={true}
                onClose={() => setShowTierUpdatePopup(false)}
                contactPlanId={groupDetails.plan.planId}
                contactTierId={groupDetails.tier.tierId}
                onUpdateTier={onUpdateGroupTier}
              />
            )}
          </Fragment>
        </section>
      </main>
    </div>
  );
};

MemberGroupProfile.propTypes = {};

export default MemberGroupProfile;
