import React, { Fragment, useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { useSelector, useDispatch } from "react-redux";

import PropTypes from "prop-types";

import {
  selectCurrentPlanVersion,
  selectPlan,
  selectPlanPrimaryCurrency,
  selectShowCurrentPlan,
  selectPlanLanguages,
  selectPlanHasMultiLang,
  selectPlanPrimaryLanguage
} from "../reducers";

import Notification from "../components/Notification";
import CheckboxField from "../components/CheckboxField";
import InputField from "../components/InputField";
import InputCurrencyField from "../components/InputCurrencyField";
import MultiLangPopupOpener from "../components/MultiLangPopupOpener";
import RadioButtonList from "../components/RadioButtonList";
import { DateField } from "../components/DateFields";
import MultiLangPopup from "../components/MultiLangPopup";
import MonthDayField from "../components/MonthDayField";

import {
  CASHBACK_LIMITATION_OPTIONS,
  CASHBACK_LIMITATION_ENUM,
  MULTI_LANG_PROPS_MAP
} from "../constants/planConstants";

import { updatePlan } from "../actions/planActions";
import { showErrorAlert, hideErrorAlert } from "../actions/alertActions";

import { validateSingleValue } from "../validators";
import planValidator from "../validators/planValidator";
import { getErrorMessage } from "../validators";
import { getMessage } from "../messages";

import { updateStateOnInputChange } from "../helpers/formHelper";
import {
  multiLangUpdateState,
  initTranslationsObj,
  setTranslationsOnInputChange,
  getMultiLangFieldLabel
} from "../helpers/multiLangHelper";
import { filterObjectByKeys } from "../helpers/objectHelper";
import MultiLangCashbackSettingsFields from "../views/MultiLangCashbackSettingsFields";

const CashbackSettings = ({ fromOnboarding, registerSubmitFunc, registerValidateFunc }) => {
  const dispatch = useDispatch();
  const showCurrentVersion = useSelector((state) => selectShowCurrentPlan(state));
  const initialPlan = useSelector((state) =>
    showCurrentVersion ? selectCurrentPlanVersion(state) : selectPlan(state)
  );

  const primaryCurrency = useSelector((state) => selectPlanPrimaryCurrency(state));

  const [plan, setPlan] = useState(cloneDeep(initialPlan));
  const [errors, setErrors] = useState(undefined);
  const [cashbackLimitation, setCashbackLimitation] = useState(CASHBACK_LIMITATION_ENUM.UNLIMITED);
  const [nextResetDate, setNextResetDate] = useState(undefined);
  const [showMultiLangPopup, setShowMultiLangPopup] = useState(false);
  const [translations, setTranslations] = useState({});

  const languages = useSelector((state) => selectPlanLanguages(state));
  const primaryLanguage = useSelector((state) => selectPlanPrimaryLanguage(state));
  const hasMultiLanguage = useSelector((state) => selectPlanHasMultiLang(state));

  const viewMode = showCurrentVersion && !fromOnboarding;
  const validator = new planValidator();

  useEffect(() => {
    const newPlan = cloneDeep(plan);
    if (!newPlan.planSettings.rules.cashback) {
      newPlan.planSettings.rules.cashback = {
        enabled: false,
        maxAmount: undefined,
        resetAt: {
          day: null,
          month: null
        }
      };
      setPlan(newPlan);
    }

    const initialLimitation = !!initialPlan.planSettings.rules.cashback?.maxAmount
      ? CASHBACK_LIMITATION_ENUM.CASHBACK_LIMITATION_LIMITED
      : CASHBACK_LIMITATION_ENUM.CASHBACK_LIMITATION_UNLIMITED;

    setCashbackLimitation(initialLimitation);
  }, []);

  useEffect(() => {
    registerSubmitFunc && registerSubmitFunc(onSubmit);
    registerValidateFunc && registerValidateFunc(validateStep);
  }, []);

  function validate() {
    return validator.validateCashbackSettings(plan, cashbackLimitation);
  }

  function onInputChange(event, customValue, customName) {
    const newPlan = cloneDeep(plan);
    const inputName = updateStateOnInputChange({
      stateObj: newPlan,
      event: event,
      customValue: customValue,
      hasMultiLanguage: hasMultiLanguage,
      primaryLanguage: primaryLanguage,
      multiLangPropsMap: MULTI_LANG_PROPS_MAP,
      customName: customName
    });
    setPlan(newPlan);

    const newErrors = validateSingleValue(
      () => validator.validateCashbackSettings(newPlan, cashbackLimitation),
      cloneDeep(errors),
      inputName
    );

    if (!newErrors) {
      dispatch(hideErrorAlert());
    }

    setErrors(newErrors);

    return newPlan;
  }

  const onCashbackIsLimitedChange = (event) => {
    if (event.target.value === CASHBACK_LIMITATION_ENUM.CASHBACK_LIMITATION_UNLIMITED) {
      const newPlan = cloneDeep(plan);
      delete newPlan.planSettings.rules.cashback.maxAmount;
      setPlan(newPlan);

      setCashbackLimitation(CASHBACK_LIMITATION_ENUM.CASHBACK_LIMITATION_UNLIMITED);
    } else {
      const newPlan = cloneDeep(plan);
      newPlan.planSettings.rules.cashback.maxAmount = undefined;
      setPlan(newPlan);
      setCashbackLimitation(CASHBACK_LIMITATION_ENUM.CASHBACK_LIMITATION_LIMITED);
    }
  };

  const validateStep = () => {
    const hasErrors = validate();
    if (hasErrors) {
      dispatch(showErrorAlert(getMessage("form.missingSettingsAlert")));
    }
    setErrors(hasErrors);
    return hasErrors === undefined;
  };

  const onCancel = () => {
    setPlan(initialPlan);
  };

  const onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    const newErrors = validate(plan, cashbackLimitation);

    if (newErrors === undefined) {
      dispatch(updatePlan({ id: plan.id, data: plan, disableAlert: fromOnboarding }));
    } else {
      dispatch(showErrorAlert(getMessage("form.missingSettingsAlert")));
    }

    setErrors(newErrors);
  };

  const renderFooterButtons = () => {
    return (
      <div className="e-buttongroup">
        <button className="e-btn" onClick={onCancel}>
          {getMessage("cancelBtn.label")}
        </button>
        <button className="e-btn e-btn-primary" onClick={onSubmit} type="submit" disabled={viewMode}>
          {getMessage("saveBtn.label")}
        </button>
      </div>
    );
  };

  function onMultiLangPopupSave() {
    const newPlan = cloneDeep(plan);
    const newTranslations = cloneDeep(translations);
    multiLangUpdateState({
      stateObj: newPlan,
      translations: newTranslations,
      propsMap: MULTI_LANG_PROPS_MAP,
      primaryLanguage: primaryLanguage
    });

    setShowMultiLangPopup(false);
    setPlan(newPlan);
    setTranslations(newTranslations);
  }

  function onShowPlanSettingsMultiLang() {
    const newPlan = cloneDeep(plan);
    const propsMap = filterObjectByKeys(MULTI_LANG_PROPS_MAP, ["planSettings.cashbackNameML"]);
    const translations = initTranslationsObj({
      stateObj: newPlan,
      propsMap,
      primaryLanguage: primaryLanguage
    });
    setTranslations(translations);
    setShowMultiLangPopup(true);
  }

  function onLangInputChange(event, lang) {
    const newTranslations = cloneDeep(translations);
    setTranslationsOnInputChange({ translations: newTranslations, event, lang });
    setTranslations(newTranslations);
  }

  if (!plan || !cashbackLimitation) {
    return null;
  }

  const rules = plan.planSettings.rules;

  return (
    <div className="box-padding">
      <div className="e-row">
        {fromOnboarding && <Notification content={getMessage("cashbackSettings.notification.content")} />}
        <div className="e-col-6">
          <CheckboxField
            tooltip={getMessage("cashbackSettings.cashbackEnabled.cb.tooltip")}
            name="planSettings.rules.cashback.enabled"
            checked={rules.cashback?.enabled}
            onChange={onInputChange}
            label={getMessage("cashbackSettings.cashbackEnabled.cb.label")}
            labelClassName="text-size-title"
            disabled={viewMode}
            errorMsg={getErrorMessage(errors, "planSettings.rules.cashback.enabled")}
          />
          <InputField
            label={getMessage("cashbackSettings.cashbackName.input.label")}
            tooltip={getMessage("cashbackSettings.cashbackName.input.tooltip")}
            placeholder={getMessage("cashbackSettings.cashbackName.input.placeholder")}
            name="planSettings.cashbackName"
            value={plan.planSettings.cashbackName}
            onChange={onInputChange}
            disabled={viewMode || !rules.cashback.enabled}
            errorMsg={getErrorMessage(errors, "planSettings.cashbackName")}
            postfixButtons={
              hasMultiLanguage && (
                <MultiLangPopupOpener
                  onClick={onShowPlanSettingsMultiLang}
                  disabled={viewMode || !rules.cashback.enabled}
                />
              )
            }
          />

          <RadioButtonList
            label={getMessage("cashbackSettings.cashbackLimit.rb.label")}
            tooltip={getMessage("cashbackSettings.cashbackLimit.rb.tooltip")}
            options={CASHBACK_LIMITATION_OPTIONS}
            value={cashbackLimitation}
            onChange={onCashbackIsLimitedChange}
            isHorizontal={true}
            disabled={viewMode || !rules.cashback.enabled}
          />

          {cashbackLimitation === CASHBACK_LIMITATION_ENUM.CASHBACK_LIMITATION_LIMITED && (
            <InputCurrencyField
              label={getMessage("cashbackSettings.cashbackLimitValue.input.label")}
              currency={primaryCurrency}
              allowDecimal={true}
              prefixLabel={plan.planSettings.currencyIdentifierSign}
              name="planSettings.rules.cashback.maxAmount"
              value={rules.cashback.maxAmount}
              onChange={onInputChange}
              disabled={viewMode || !rules.cashback.enabled}
              errorMsg={getErrorMessage(errors, "planSettings.rules.cashback.maxAmount")}
            />
          )}

          <MonthDayField
            name="planSettings.rules.cashback.resetAt"
            value={rules.cashback.resetAt}
            disabled={viewMode || !rules.cashback.enabled}
            onChange={onInputChange}
            label={getMessage("cashbackSettings.resetAt.label")}
            tooltip={
              cashbackLimitation != CASHBACK_LIMITATION_ENUM.CASHBACK_LIMITATION_LIMITED
                ? getMessage("cashbackSettings.resetAt.tooltip.unlimited")
                : getMessage("cashbackSettings.resetAt.tooltip.limited")
            }
            errorMsg={getErrorMessage(errors, "planSettings.rules.cashback.resetAt.day")}
            isMandatory={cashbackLimitation === CASHBACK_LIMITATION_ENUM.CASHBACK_LIMITATION_LIMITED}
          />
        </div>
      </div>
      {fromOnboarding ? null : (
        <Fragment>
          <hr className="e-seperator e-seperator-fullwidth" />
          {renderFooterButtons()}
        </Fragment>
      )}
      <MultiLangPopup
        id={"cashback_settings_multi_lang"}
        show={showMultiLangPopup}
        languages={languages}
        translations={translations}
        element={<MultiLangCashbackSettingsFields disabled={viewMode} />}
        showNoticeForDefault={true}
        onChange={onLangInputChange}
        onSave={onMultiLangPopupSave}
        onClose={() => {
          setShowMultiLangPopup(false);
        }}
      />
    </div>
  );
};

CashbackSettings.propTypes = {
  fromOnboarding: PropTypes.bool,
  registerSubmitFunc: PropTypes.func,
  registerValidateFunc: PropTypes.func
};

export default CashbackSettings;
