import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "../assets/styles/SelectField.scss";

const SelectField = (props) => {
  if (props.dontRender) {
    return null;
  }

  let attr = {};

  if (props.type) {
    attr["type"] = props.type;
  }

  if (props.disabled) {
    attr["disabled"] = props.disabled;
  }

  if (props.isNumber) {
    attr["type"] = "custom-number";
  }

  let options = [...props.options];
  if (props.placeholder) {
    options.unshift({
      name: props.placeholder,
      value: ""
    });
  }

  const fieldClassNames = classNames("e-field", props.extraClassName, {
    "e-hidden": props.hidden,
    "e-field-inline": props.inline
  });

  const labelClassNames = classNames("e-field__label", { "e-field__label-required": props.isRequired });

  const selectWrapperClassNames = classNames({
    "l-select-with-button": props.isWithButton,
    "e-field__postfix": !!props.postfixButtons
  });

  const selectClassNames = classNames("e-select", props.className, {
    "e-select-error": !!props.errorMsg
  });

  let tooltipAttr = {};
  if (props.tooltipHasAction) {
    tooltipAttr["has-action"] = true;
  }

  return (
    <div className={fieldClassNames}>
      {props.label ? (
        <label className={labelClassNames}>
          {props.label}

          {props.tooltip ? <e-tooltip content={props.tooltip} type="helper" {...tooltipAttr} /> : null}
        </label>
      ) : null}
      <div className={selectWrapperClassNames}>
        <e-select>
          <select
            className={selectClassNames}
            style={props.style}
            {...attr}
            id={props.name}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            placeholder={props.placeholder}
          >
            {options.map((opt) => {
              let name,
                value = "";
              if (typeof opt === "object") {
                value = opt.value;
                name = opt.name;
              } else {
                value = name = opt;
              }

              let attr = {};
              if (opt.disabled) {
                attr.disabled = true;
              }

              return (
                <option key={value} value={value} {...attr}>
                  {name}
                </option>
              );
            })}
          </select>
        </e-select>
        {!!props.postfixButtons && (
          <span className="e-field__postfix__cell e-field__postfix__cell-short e-field__postfix__cell-shortsticky">
            {props.postfixButtons}
          </span>
        )}
      </div>

      {props.errorMsg ? <span className="e-field__message e-field__message-error">{props.errorMsg}</span> : null}
    </div>
  );
};

SelectField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  inline: PropTypes.bool,
  tooltip: PropTypes.string,
  type: PropTypes.string,
  hidden: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMsg: PropTypes.string,
  dontRender: PropTypes.bool,
  style: PropTypes.object,
  extraClassName: PropTypes.string,
  isNumber: PropTypes.bool,
  isRequired: PropTypes.bool
};

export default SelectField;
