import axios from "axios";
import sessionHelper from "../helpers/sessionHelper";

export default class externalEventsApi {
  static async getAllExternalEvents() {
    const planId = sessionHelper.getPlanId();
    const response = await axios.get(`/plan/${planId}/external-events`);
    return response.data;
  }

  static async createExternalEvent(data) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.post(`/plan/${planId}/external-event`, data);
    return response.data;
  }

  static async updateExternalEvent(data, id) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.put(`/plan/${planId}/external-event/${id}`, data);
    return response.data;
  }

  static async deleteExternalEvent(id) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.delete(`/plan/${planId}/external-event/${id}`);
    return response;
  }
}
