import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import SelectField from "../../components/SelectFieldV2";
import InputField from "../../components/InputField";
import InputNumberField from "../../components/InputNumberField";
import CheckboxField from "../../components/CheckboxField";
import PopupContainer from "../../components/PopupContainer";

import currentPlanVersionApi from "../../api/currentPlanVersionApi";

import { updateStateOnInputChange } from "../../helpers/formHelper";
import { POINTS_PLACEHOLDER } from "../../helpers/pointsHelper";

import { validateSingleValue, getErrorMessage } from "../../validators";

import memberPointsUpdateValidator from "../../validators/memberPointsUpdateValidator";

import {
  UPDATE_POINTS_TYPE,
  UPDATE_POINTS_TYPE_OPTIONS_POINTS_PLAN,
  POINTS_UPDATE_TYPE,
  UPDATE_POINTS_TYPE_OPTIONS_SPEND_PLAN
} from "../../constants/memberProfileConstants";
import LoaderOverlay from "../../components/LoaderOverlay";
import planService from "../../services/planService";
import { getMessage } from "../../messages";

class MemberProfileUpdatePointsPopup extends Component {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
    this.state.errors = undefined;
  }

  componentDidUpdate(prevProps) {
    const { plan } = this.props;
    if (this.props.isShow && !prevProps.isShow) {
      this.validator = new memberPointsUpdateValidator(this.props.contactPoints, plan);
      this.setStateByPlan();
    }
  }

  async setStateByPlan() {
    const { contactPlanId } = this.props;
    this.setState({
      loading: true
    });

    const plan = await currentPlanVersionApi.getCurrentPlanVersion(contactPlanId);
    const planHasRedemption = planService.hasRedemption(plan);

    this.setState({
      planHasRedemption,
      loading: false,
      plan: plan,
      pointsType:
        planService.isSpendPlan(plan) || this.props.isGroup ? UPDATE_POINTS_TYPE.STATUS : UPDATE_POINTS_TYPE.LOYALTY
    });
  }

  getDefaultState() {
    return {
      pointsType: UPDATE_POINTS_TYPE.LOYALTY,
      pointsUpdateType: POINTS_UPDATE_TYPE.ADD_POINTS,
      pointsValue: undefined,
      reason: "",
      description: "",
      confirmed: false,
      planHasRedemption: "",
      loading: false
    };
  }

  getPointsTypeOptions() {
    const { planHasRedemption, plan } = { ...this.state };

    let pointsTypeOptions = planService.isSpendPlan(plan)
      ? UPDATE_POINTS_TYPE_OPTIONS_SPEND_PLAN.filter(
          (opt) => planService.isCashbackEnabled(plan) || opt.value != UPDATE_POINTS_TYPE.CASHBACK
        )
      : UPDATE_POINTS_TYPE_OPTIONS_POINTS_PLAN;

    pointsTypeOptions = this.props.isGroup
      ? pointsTypeOptions.filter((opt) => opt.value === UPDATE_POINTS_TYPE.STATUS)
      : pointsTypeOptions;

    let result = [];

    pointsTypeOptions.forEach((option) => {
      let newOption = { ...option };
      newOption.name = this.props.getPointsText(option.name);

      if (option.value === UPDATE_POINTS_TYPE.BALANCE && !planHasRedemption) {
        return;
      }

      result.push(newOption);
    });
    return result;
  }

  onInputChange(event, customValue) {
    const state = { ...this.state };

    const inputName = updateStateOnInputChange({ stateObj: state, event, customValue });

    const errors = validateSingleValue(() => this.validator.validate(state), state.errors, inputName);
    state.errors = errors;

    this.setState(state);
  }

  onUpdateTypeChange(event) {
    const state = { ...this.state };

    state.pointsValue = undefined;

    //reset points value error message
    if (state.errors) {
      state.errors.pointsValue = undefined;
    }

    updateStateOnInputChange({ stateObj: state, event });
    this.setState(state);
  }

  onPointsTypeChange(event) {
    const state = { ...this.state };

    updateStateOnInputChange({ stateObj: state, event });

    if (!!state.pointsValue) {
      const errors = validateSingleValue(() => this.validator.validate(state), state.errors, "pointsValue");
      state.errors = errors;
    }

    this.setState(state);
  }

  setPointType(value) {
    const state = { ...this.state };

    state.pointsType = value;

    this.setState(state);
  }

  onPointsUpdateClick() {
    const errors = this.validator.validate(this.state);
    if (errors) {
      this.setState({
        errors
      });

      return;
    }

    this.props.onUpdatePoints({
      pointsType: this.state.pointsType,
      pointsUpdateType: this.state.pointsUpdateType,
      pointsValue: this.state.pointsValue,
      reason: this.state.reason,
      description: this.state.description
    });
  }

  closePopup() {
    this.setState(this.getDefaultState());

    this.props.onClose();
  }

  getAddRemoveLabel(updateType) {
    const { plan } = this.props;
    if (!planService.isSpendPlan(plan)) {
      return updateType === POINTS_UPDATE_TYPE.ADD_POINTS
        ? `${this.props.getPointsText(getMessage("memberProfileUpdatePointsPopup.add.points.label"))}`
        : `${this.props.getPointsText(getMessage("memberProfileUpdatePointsPopup.remove.points.label"))}`;
    } else {
      return updateType === POINTS_UPDATE_TYPE.ADD_POINTS
        ? getMessage("memberProfileUpdatePointsPopup.add.spend.label")
        : getMessage("memberProfileUpdatePointsPopup.remove.spend.label");
    }
  }

  renderPointsUpdateTypeInput(updateType) {
    const id = updateType;
    const radioLabel = this.getAddRemoveLabel(updateType);
    const isCurrentType = this.state.pointsUpdateType === updateType;
    return (
      <div className="e-col-6">
        <input
          className="e-radio"
          type="radio"
          name={"pointsUpdateType"}
          value={updateType}
          id={id}
          onChange={this.onUpdateTypeChange.bind(this)}
          checked={isCurrentType}
        />
        <label style={{ fontSize: 15 }} htmlFor={id}>
          {radioLabel}
        </label>
        <div className="m-t-5">
          <InputNumberField
            value={isCurrentType ? this.state.pointsValue : undefined}
            name={"pointsValue"}
            readOnly={!isCurrentType}
            onChange={this.onInputChange.bind(this)}
            errorMsg={isCurrentType ? getErrorMessage(this.state.errors, "pointsValue") : ""}
            allowDecimal={planService.isSpendPlan(this.state.plan)}
          />
        </div>
      </div>
    );
  }

  render() {
    const pointsTypeOptions = this.getPointsTypeOptions();

    return (
      <PopupContainer
        id="memberProfilePointsUpdatePopup"
        title={
          planService.isSpendPlan(this.state.plan)
            ? getMessage("memberProfileUpdatePointsPopup.title.spend")
            : getMessage("memberProfileUpdatePointsPopup.title.points")
        }
        show={this.props.isShow}
        onClose={this.closePopup.bind(this)}
      >
        <Fragment>
          <LoaderOverlay show={this.state.loading} />
          <SelectField
            label={
              planService.isSpendPlan(this.state.plan)
                ? getMessage("memberProfileUpdatePointsPopup.pointsType.spend")
                : getMessage("memberProfileUpdatePointsPopup.pointsType.points")
            }
            name="pointsType"
            value={this.state.pointsType}
            options={pointsTypeOptions}
            disabled={pointsTypeOptions.length === 1}
            onChange={this.onPointsTypeChange.bind(this)}
          />

          <div className="e-row">
            {this.renderPointsUpdateTypeInput(POINTS_UPDATE_TYPE.ADD_POINTS)}
            {this.renderPointsUpdateTypeInput(POINTS_UPDATE_TYPE.REMOVE_POINTS)}
          </div>

          <InputField
            label={getMessage("memberProfileAdditionalBenefitsPopup.reason.label")}
            name="reason"
            value={this.state.reason}
            onChange={this.onInputChange.bind(this)}
            tooltip={getMessage("memberProfileUpdatePointsPopup.reason.tooltip")}
            errorMsg={getErrorMessage(this.state.errors, "reason")}
          />

          {!this.props.isGroup && (
            <InputField
              label={getMessage("actionSetupGeneral.descriptionInput.label")}
              name="description"
              value={this.state.description}
              onChange={this.onInputChange.bind(this)}
              tooltip={getMessage("memberProfileUpdatePointsPopup.description.tooltip")}
            />
          )}

          <CheckboxField
            name={`confirmed`}
            id="updatePointsConfirmed"
            inline={true}
            label={getMessage("memberProfileUpdatePointsPopup.confirmed.label", {
              text: this.props.isGroupTooltip ? "group's" : "member's"
            })}
            checked={this.state.confirmed}
            onChange={this.onInputChange.bind(this)}
            errorMsg={getErrorMessage(this.state.errors, "confirmed")}
          />

          <hr className="e-separator e-separator-fullwidth" />
          <div className="e-buttongroup">
            <a className="e-btn" onClick={this.closePopup.bind(this)}>
              {getMessage("cancelBtn.label")}
            </a>
            <a className="e-btn e-btn-primary" onClick={this.onPointsUpdateClick.bind(this)}>
              {getMessage("updateBtn.label")}
            </a>
          </div>
        </Fragment>
      </PopupContainer>
    );
  }
}

MemberProfileUpdatePointsPopup.propTypes = {
  isShow: PropTypes.bool,
  isGroup: PropTypes.bool,
  contactPlanId: PropTypes.string,
  isGroupTooltip: PropTypes.bool,
  onClose: PropTypes.func,
  contactPoints: PropTypes.shape({
    status: PropTypes.number,
    balance: PropTypes.number,
    pending: PropTypes.number,
    expiresSoon: PropTypes.number,
    cashback: PropTypes.number
  }),
  onUpdatePoints: PropTypes.func,
  getPointsText: PropTypes.func
};

export default MemberProfileUpdatePointsPopup;
