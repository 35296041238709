import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";

import { selectPlanCurrencies } from "../reducers";

class MultiCurrencyPopupOpener extends Component {
  render() {
    if (!this.props.currencies || this.props.currencies.length == 1) {
      return null;
    }

    const className = classNames("e-btn e-btn-onlyicon", {
      "e-btn-disabled": this.props.disabled
    });
    return (
      <a className={className} onClick={() => !this.props.disabled && this.props.onClick()}>
        <e-icon icon="e-language" />
      </a>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currencies: selectPlanCurrencies(state)
  };
};

MultiCurrencyPopupOpener.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default connect(mapStateToProps, null)(MultiCurrencyPopupOpener);
