import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getMessage } from "../../messages";
import { formatIsoTime } from "../../helpers/timeHelper";

const GroupMembersTable = ({ members, onMemberProfile, externalIdsMap, onRemoveFromGroup, disableRemoveFromGroup }) => {
  const memberProfileItemAction = useRef(null);
  const removeFromGroupItemAction = useRef(null);
  const [gridData, setGridData] = useState([]);

  useEffect(() => {
    memberProfileItemAction.current.addEventListener("trigger", onMemberProfileItemAction);
    removeFromGroupItemAction.current.addEventListener("trigger", onRemoveFromGroupItemAction);
  }, []);

  useEffect(() => {
    if (!!externalIdsMap) {
      let data = [];
      members.forEach((member) => {
        const item = { ...member };
        item.externalId = externalIdsMap[item.contactId] || item.contactId;
        item.addedAt = formatIsoTime(item.addedAt);
        item.lastActivityDate = formatIsoTime(item.lastActivityDate);
        data.push(item);
      });
      data.sort((a, b) => {
        if (a.addedAt != b.addedAt) {
          return b.addedAt - a.addedAt;
        }

        if (a.externalId < b.externalId) {
          return -1;
        }
        if (a.externalId > b.externalId) {
          return 1;
        }
        return 0;
      });
      setGridData(data);
    }
  }, [members]);

  function onMemberProfileItemAction(event) {
    const data = event.detail.data;
    onMemberProfile(data.contactId, data.externalId);
  }

  function onRemoveFromGroupItemAction(event) {
    const data = event.detail.data;
    onRemoveFromGroup(data.contactId);
  }

  return (
    <e-datagrid data={JSON.stringify(gridData)} bordered hide-pagination>
      <e-datagrid-column
        head={getMessage("memberProfileOverview.tableHeader.memberID")}
        content-key="externalId"
        sortable="false"
      ></e-datagrid-column>
      <e-datagrid-column-time
        head={getMessage("groupMemebersTable.header.dateAdded")}
        content-key="addedAt"
        format="date"
        sortable="false"
      ></e-datagrid-column-time>
      <e-datagrid-column-time
        head={getMessage("groupMemebersTable.header.lastActivityDate")}
        content-key="lastActivityDate"
        format="date"
        sortable="false"
      ></e-datagrid-column-time>
      <e-datagrid-item-action
        icon="e-profile"
        tooltip={getMessage("memberProfile.title")}
        ref={memberProfileItemAction}
      ></e-datagrid-item-action>
      <e-datagrid-item-action
        icon="e-sign-out"
        tooltip={getMessage("groupMemebersTable.header.removeFromGroup")}
        ref={removeFromGroupItemAction}
        disabled={disableRemoveFromGroup || members.length <= 1}
      ></e-datagrid-item-action>
    </e-datagrid>
  );
};

GroupMembersTable.propTypes = {
  members: PropTypes.array,
  onMemberProfile: PropTypes.func,
  onRemoveFromGroup: PropTypes.func,
  externalIdsMap: PropTypes.object,
  loading: PropTypes.bool,
  disableRemoveFromGroup: PropTypes.bool
};

export default GroupMembersTable;
