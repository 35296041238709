import * as TYPES from "../actions/TYPES";
import { arrayToObject } from "../helpers/objectHelper";

const initialState = {
  exclusivesById: {},
  loading: false,
  dataLoaded: false,
  postSuccess: false,
  newExclusiveAccessId: null,
  exclusiveAccessEditState: {
    exclusiveAccess: null,
    stepId: null
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.POST_EXCLUSIVE_ACCESS_REQUEST: {
      return {
        ...state,
        loading: true,
        postSuccess: false
      };
    }

    case TYPES.DELETE_EXCLUSIVE_ACCESS_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case TYPES.GET_ALL_EXCLUSIVES_REQUEST: {
      return {
        ...state,
        loading: true,
        dataLoaded: false
      };
    }

    case TYPES.GET_ALL_EXCLUSIVES_SUCCESS: {
      const rewardsData = action.payload == null ? [] : action.payload;
      const exclusivesById = arrayToObject(rewardsData);

      return {
        ...state,
        exclusivesById,
        loading: false,
        dataLoaded: true
      };
    }

    case TYPES.POST_EXCLUSIVE_ACCESS_SUCCESS: {
      const exclusiveAccessToSet = action.payload.data;

      const { exclusivesById } = { ...state };
      exclusivesById[exclusiveAccessToSet.id] = exclusiveAccessToSet;

      let result = {
        ...state,
        exclusivesById,
        loading: false,
        postSuccess: true
      };

      if (action.payload.isNew) {
        result.newExclusiveAccessId = exclusiveAccessToSet.id;
      }

      return result;
    }

    case TYPES.DELETE_EXCLUSIVE_ACCESS_SUCCESS: {
      const rewardId = action.payload.id;
      const { exclusivesById } = { ...state };
      delete exclusivesById[rewardId];

      return {
        ...state,
        exclusivesById,
        loading: false
      };
    }

    case TYPES.GET_ALL_EXCLUSIVES_FAILURE:
    case TYPES.POST_EXCLUSIVE_ACCESS_FAILURE:
    case TYPES.DELETE_EXCLUSIVE_ACCESS_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case TYPES.SET_EXCLUSIVE_ACCESS_EDIT_STATE: {
      return {
        ...state,
        exclusiveAccessEditState: {
          exclusiveAccess: { ...action.payload.exclusiveAccess },
          stepId: action.payload.stepId
        }
      };
    }

    case TYPES.RESET_EXCLUSIVE_ACCESS_EDIT_STATE: {
      return {
        ...state,
        exclusiveAccessEditState: { ...initialState.exclusiveAccessEditState }
      };
    }

    case TYPES.RESET_NEW_EXCLUSIVE_ACCESS_ID: {
      return {
        ...state,
        newExclusiveAccessId: null
      };
    }

    default:
      return state;
  }
}

export const selectExclusives = (state) => {
  const { exclusivesById } = { ...state };
  const exclusives = Object.keys(exclusivesById).map((id) => exclusivesById[id]);
  return exclusives;
};

export const selectExclusiveAccessNames = (state) => {
  const { exclusivesById } = { ...state };
  return Object.keys(exclusivesById).map((id) => exclusivesById[id].name);
};

export const selectExclusivesById = (state) => {
  const { exclusivesById } = { ...state };
  return exclusivesById;
};

export const selectExclusiveAccessById = (state, id) => {
  const { exclusivesById } = { ...state };
  return exclusivesById[id];
};

export const selectExclusiveAccessEditState = (state) => {
  return { ...state.exclusiveAccessEditState };
};

export const selectNewExclusiveAccessId = (state) => {
  return state.newExclusiveAccessId;
};

export const isDataLoaded = (state) => {
  return state.dataLoaded;
};

export const isLoading = (state) => {
  return state.loading;
};

export const isPostSuccess = (state) => {
  return state.postSuccess;
};
