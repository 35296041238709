import moment from "moment-timezone";

export async function isEmsSuite() {
  const topUrl = await window.e.utils.getTopUrl();
  return topUrl.indexOf(".emarsys.") > 0;
}

export async function getEmsSuiteConfig() {
  let adminId = "";
  let timezone = moment.tz.guess();

  if (await isEmsSuite()) {
    const config = await window.e.utils.getConfig();
    adminId = config.adminId;
    timezone = config.timezone;
  }

  return {
    adminId,
    timezone
  };
}
