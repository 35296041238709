import React, { useState, useEffect, useRef } from "react";
import remove from "lodash/remove";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import PopupContainer from "../../components/PopupContainer";
import InputField from "../../components/InputField";
import SelectField from "../../components/SelectFieldV2";
import LoaderOverlay from "../../components/LoaderOverlay";
import Alert from "../../components/Alert";
import InputSearchField from "../../components/InputSearchField";

import memberProfileApi from "../../api/memberProfileApi";
import planApi from "../../api/planApi";
import planService from "../../services/planService";

import { showSuccessAlert } from "../../actions/alertActions";
import { ALERT_STATUS } from "../../constants/alertContants";
import { PLAN_TYPE_ENUM } from "../../constants/planConstants";
import { getMessage } from "../../messages";

const CreateGroupPopup = ({ show, groupId, groupPlanId, onPopupClose, hasMultiPlan, onSave, groupPlanType }) => {
  const dispatch = useDispatch();

  const [planId, setPlanId] = useState(groupPlanId || "");
  const [planType, setPlanType] = useState(groupPlanType || PLAN_TYPE_ENUM.POINTS);
  const [allPlans, setAllPlans] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [groupNameError, setGroupNameError] = useState("");
  const [alertError, setAlertError] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [contactToAdd, setContactToAdd] = useState(null);
  const [planOptions, setPlanOptions] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [loading, setLoading] = useState(false);

  const isCreateMode = !groupId;

  let unmounted = useRef(false);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    if (show) {
      if (isCreateMode && allPlans.length === 0) {
        setLoading(true);
        loadPlans().finally(() => setLoading(false));
      }

      resetState();
    }
  }, [show]);

  function resetState() {
    setSearchValue("");
    setPlanId(isCreateMode ? planOptions[0]?.value : groupPlanId || PLAN_TYPE_ENUM.POINTS);
    setGroupName("");
    setContactToAdd(null);
    setContactList([]);
  }

  function onClose() {
    onPopupClose();
  }

  async function loadPlans() {
    const plans = await planApi.getAllPlans();
    if (unmounted.current) {
      return;
    }
    setPlanId(plans[0].id);
    setPlanType(plans[0].planType);
    setPlanOptions(planService.getPlanOptions(plans));
    setAllPlans(plans);
  }

  function onPlanChanged(event) {
    setPlanId(event.target.value);
    let currPlanType = allPlans.filter((x) => x.id === event.target.value)[0].planType;
    setPlanType(currPlanType);
  }

  function onAddToGroup() {
    let newContactList = [...contactList];
    newContactList.push(contactToAdd);
    setContactList(newContactList);
    setContactToAdd(null);
    setSearchValue("");
  }

  function onRemoveFromGroup(contactId) {
    let newContactList = [...contactList];
    remove(newContactList, (contact) => {
      return contact.contactId === contactId;
    });

    setContactList(newContactList);
  }

  async function onSaveGroup() {
    const contactIds = contactList.map((contact) => contact.contactId);
    const apiPromise = isCreateMode
      ? memberProfileApi.createGroup({ name: groupName, contactIds })
      : memberProfileApi.addContactsToGroup({ groupId, contactIds });

    setLoading(true);

    apiPromise
      .then((data) => {
        onClose();
        dispatch(
          showSuccessAlert(isCreateMode ? getMessage("success.createGroup") : getMessage("success.addMembersToGroup"))
        );
        onSave && onSave(data);
      })
      .catch((error) => {
        if (error?.response?.status === 409) {
          setGroupNameError(getMessage("error.groupNameAlreadyExist"));
          setAlertError(getMessage("error.groupNameAlreadyExist.WithName", { groupName }));
        } else {
          setAlertError(
            isCreateMode ? getMessage("error.failedToCreateGroup") : getMessage("error.failedToAddMembersToGroup")
          );
          throw error;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function onSearchChange(event) {
    const value = event.target.value;
    setSearchValue(value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const t = setTimeout(() => {
      searchContact(value);
    }, 250);

    setSearchTimeout(t);
  }

  async function searchContact(searchValue) {
    const contact = await memberProfileApi.searchMember({ planId, searchValue });
    setContactToAdd(contact);
  }

  function renderTable() {
    return (
      <table className="e-table e-table-overview e-table-verticalcentered" data-e-version="2">
        <thead>
          <tr>
            <td className="e-table__col e-table__col-filter" colSpan={4}>
              <InputSearchField
                label={getMessage("searchBtn.label")}
                onChange={onSearchChange}
                value={searchValue}
                inline={true}
                noMargin={true}
                size={"large"}
              />
            </td>
          </tr>
          <tr>
            <td className="e-table__col e-table__col e-table__col-medium">
              {getMessage("memberProfileOverview.tableHeader.memberID")}
            </td>
            <td className="e-table__col e-table__col e-table__col-medium">
              {getMessage("memberProfileOverview.tableHeader.tier")}
            </td>
            <td className="e-table__col e-table__col e-table__col-medium">
              {planType != PLAN_TYPE_ENUM.SPEND ? getMessage("statusPoints") : getMessage("loyaltyCredit")}
            </td>
            <td className="e-table__col e-table__col e-table__col-medium"></td>
          </tr>
        </thead>
        <tbody>
          {contactToAdd && !contactList.some((contact) => contact.contactId === contactToAdd.contactId) && (
            <tr key={contactToAdd.contactId}>
              <td>{contactToAdd.externalId}</td>
              <td>{contactToAdd.tier}</td>
              <td>{contactToAdd.statusPoints}</td>
              <td className="e-table__col e-table__col-actions">
                <button className="e-btn e-btn-primary" onClick={onAddToGroup}>
                  Add member
                </button>
              </td>
            </tr>
          )}
          {contactList.map((row) => {
            return (
              <tr key={`row_${row.contactId}`}>
                <td>{row.externalId}</td>
                <td>{row.tier}</td>
                <td>{row.statusPoints}</td>
                <td className="e-table__col e-table__col-actions">
                  <e-tooltip valign="baseline" content="Clear selection">
                    <a className="e-svgclickfix" onClick={() => onRemoveFromGroup(row.contactId)}>
                      <e-icon icon="e-clear" type="table"></e-icon>
                    </a>
                  </e-tooltip>
                </td>
              </tr>
            );
          })}
          {!contactToAdd && contactList.length === 0 && (
            <tr>
              <td colSpan={4}>&nbsp;</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  function renderSaveButton() {
    const disabled = isCreateMode ? contactList.length < 2 || !groupName : contactList.length === 0;

    const btn = (
      <button className="e-btn e-btn-primary" onClick={onSaveGroup} disabled={disabled}>
        {isCreateMode ? getMessage("createBtn.label") : getMessage("saveBtn.label")}
      </button>
    );

    if (disabled) {
      let tooltipContent = isCreateMode
        ? getMessage("createGroupPopup.saveBtn.disabled.tooltip.create")
        : getMessage("createGroupPopup.saveBtn.disabled.tooltip.modify");
      if (isCreateMode && !groupName) {
        tooltipContent = getMessage("createGroupPopup.saveBtn.disabled.tooltip.create.nameMissing");
      }

      return <e-tooltip content={tooltipContent}>{btn}</e-tooltip>;
    }

    return btn;
  }

  return (
    <PopupContainer
      id="createMemberGroup"
      title={isCreateMode ? getMessage("createGroupPopup.title.create") : getMessage("createGroupPopup.title.modify")}
      width={"972px"}
      show={show}
      onClose={onClose}
    >
      {alertError && <Alert message={alertError} status={ALERT_STATUS.ERROR} />}

      <e-notification type="default">
        <e-notification-content>{getMessage("createGroupPopup.notification")}</e-notification-content>
      </e-notification>

      <div className="e-margin-top-l">
        {isCreateMode && hasMultiPlan && (
          <SelectField
            label={getMessage("createGroupPopup.selectPlan.label")}
            name="planId"
            options={planOptions}
            value={planId}
            onChange={onPlanChanged}
            tooltip={getMessage("createGroupPopup.selectPlan.tooltip")}
            disabled={contactToAdd || contactList.length > 0}
          />
        )}

        {isCreateMode && (
          <InputField
            label={getMessage("createGroupPopup.groupName.label")}
            value={groupName}
            name={groupName}
            errorMsg={groupNameError}
            tooltip={getMessage("createGroupPopup.groupName.tooltip")}
            onChange={(event) => {
              setGroupName(event.target.value);
              setGroupNameError();
            }}
          />
        )}
      </div>

      <div className="e-margin-top-l e-margin-bottom-s">
        <label>
          {getMessage("createGroupPopup.addMembers.label")}
          <e-tooltip content={getMessage("createGroupPopup.addMembers.tooltip")} type="helper"></e-tooltip>{" "}
        </label>
      </div>
      {renderTable()}

      <hr className="e-separator e-separator-fullwidth e-margin-top-xl"></hr>

      <div className="e-buttongroup">
        <button className="e-btn" onClick={onClose}>
          {getMessage("cancelBtn.label")}
        </button>
        {renderSaveButton()}
      </div>

      <LoaderOverlay show={loading} />
    </PopupContainer>
  );
};

CreateGroupPopup.propTypes = {
  show: PropTypes.bool,
  hasMultiPlan: PropTypes.bool,
  groupId: PropTypes.string,
  groupPlanId: PropTypes.string,
  onPopupClose: PropTypes.func,
  onSave: PropTypes.func
};

export default CreateGroupPopup;
