import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import RewardSetupValueForTiers from "../views/RewardSetupValueForTiers";

import InputField from "../components/InputField";
import InputFieldWithTokens from "../components/InputFieldWithTokens";
import MultiLangPopupOpener from "../components/MultiLangPopupOpener";
import MultiMarketSelect from "../components/MultiMarketSelect";
import Notification from "../components/Notification";

import { REDEMPTION_VALUE_TOKEN, MASTER_REWARD_ENUM } from "../constants/rewardConstants.js";

import RedemptionVoucherMessageImg from "../assets/images/reward/points_voucher.png";
import CashbackVoucherMessageImg from "../assets/images/reward/cashback_voucher.png";
import FreeVoucherMessageImg from "../assets/images/reward/free_voucher.png";

import { getErrorMessage } from "../validators";

import { rewardType } from "../types";

import rewardService from "../services/rewardService";

import { getCurrencySymbol } from "../helpers/currencyHelper";
import { getMessage } from "../messages";

const RewardSetupRedemptionValue = (props) => {
  const {
    reward,
    onInputChange,
    onRedemptionValueChange,
    tiers,
    errors,
    onShowRedemptionMessageMultiLang,
    notificationElem,
    hasMultiLanguage,
    onBlockExternalLinkMultiLang,
    currency,
    viewMode
  } = props;

  const filteredTiers = tiers;

  const valueInPointsHeader = () => {
    switch (reward.masterReward) {
      case MASTER_REWARD_ENUM.VOUCHERS_FOR_FREE:
        return `Tiers`;
      default:
        return `Tiers & Value`;
    }
  };

  const valueInPointsHeaderToolTip = () => {
    switch (reward.masterReward) {
      case MASTER_REWARD_ENUM.VOUCHERS_FOR_FREE:
        return getMessage("rewardSetupRedemptionValue.valueInPointsHeaderToolTip.free");
      case MASTER_REWARD_ENUM.VOUCHERS_AS_CASHBACK:
        return getMessage("rewardSetupRedemptionValue.valueInPointsHeaderToolTip.cashback");
      default:
        return getMessage("rewardSetupRedemptionValue.valueInPointsHeaderToolTip.points");
    }
  };

  // const redemptionOfferToolTip = () => {
  //   switch (reward.masterReward) {
  //     case MASTER_REWARD_ENUM.VOUCHERS_FOR_FREE:
  //       return `Define the offer for this voucher that will be displayed in the Loyalty Wallet and allow customers to earn it for free.`;
  //     case MASTER_REWARD_ENUM.VOUCHERS_AS_CASHBACK:
  //       return `Here you select the markets and define the offer for this voucher, that will be displayed in the Loyalty Wallet.`;
  //     default:
  //       return `Define the redemption offer for this voucher that will be displayed in the Loyalty Wallet and will allow customers to earn it for points.`;
  //   }
  // };

  const redemptionMessageTooltip = () => {
    switch (reward.masterReward) {
      case MASTER_REWARD_ENUM.VOUCHERS_FOR_FREE:
        return getMessage("rewardSetupRedemptionValue.redemptionMessageTooltip.free");
      default:
        return getMessage("rewardSetupRedemptionValue.redemptionMessageTooltip.points");
    }
  };

  const voucherMessageImg = () => {
    switch (reward.masterReward) {
      case MASTER_REWARD_ENUM.VOUCHERS_FOR_FREE:
        return FreeVoucherMessageImg;
      case MASTER_REWARD_ENUM.VOUCHERS_AS_CASHBACK:
        return CashbackVoucherMessageImg;
      default:
        return RedemptionVoucherMessageImg;
    }
  };
  // const showRedemptionTargeting = !isExclusiveAccess;
  const showRedemptionTargeting = true;

  return (
    <div className="e-box">
      {notificationElem}
      <div className="e-row">
        <div className="e-col-6">
          <h2>
            {valueInPointsHeader()}
            <e-tooltip content={valueInPointsHeaderToolTip()} type="helper" />
          </h2>
          <RewardSetupValueForTiers
            fieldNamePrefix="redemption.value"
            tiers={filteredTiers}
            tierValueProp="promotion"
            onRewardInputChange={onRedemptionValueChange}
            disabled={viewMode}
            errors={errors}
            renderEnableSwitch={true}
            onAccordionStateChange={props.onAccordionStateChange}
            isRewardForFree={rewardService.isRewardForFree(reward.masterReward)}
            postfixLabel={
              rewardService.isCashback(reward.masterReward)
                ? getCurrencySymbol(currency)
                : getMessage("rewardSetupRedemptionValue.points")
            }
          />
          {rewardService.isCashback(reward.masterReward) && (
            <div className="e-margin-top-l">
              <Notification content={getMessage("rewardSetupRedemptionValue.notification.cashback")}></Notification>
            </div>
          )}
        </div>
        <div className="e-col-6">
          <h2>
            {rewardService.isCashback(reward.masterReward)
              ? getMessage("rewardSetupRedemptionValue.offerTitle.cashback")
              : getMessage("rewardSetupRedemptionValue.offerTitle.other")}
          </h2>

          {showRedemptionTargeting && (
            <MultiMarketSelect
              label={
                rewardService.isCashback(reward.masterReward)
                  ? getMessage("rewardSetupRedemptionValue.targetMarkets.cashback.label")
                  : getMessage("rewardSetupRedemptionValue.targetMarkets.other.label")
              }
              name="redemption.targetMarkets"
              value={reward.redemption.targetMarkets}
              onChange={onInputChange}
              errorMsg={getErrorMessage(props.errors, "redemption.targetMarkets")}
              tooltip={
                rewardService.isCashback(reward.masterReward)
                  ? getMessage("rewardSetupRedemptionValue.targetMarkets.cashback.tooltip")
                  : getMessage("rewardSetupRedemptionValue.targetMarkets.other.tooltip")
              }
              isMultiSelect={true}
            />
          )}

          <div className="e-grid e-grid-between" style={{ alignItems: "center" }}>
            <div className="e-cell">
              <label className="e-field__label">
                {getMessage("rewardSetupRedemptionValue.offerMessage.title")}&nbsp;
                <e-tooltip content={redemptionMessageTooltip()} type="helper" />
              </label>
            </div>
            <div className="e-cell">
              <e-tooltip content={`<img src="${voucherMessageImg()}"/>`}>
                <a>{getMessage("rewardSetupRedemptionValue.offerMessage.tooltip")}</a>
              </e-tooltip>
            </div>
          </div>

          <InputField
            label={getMessage("exclusiveAccessAndOffer.offerMessage.title.label")}
            type="text"
            name="redemption.message.title"
            value={reward.redemption.message.title}
            onChange={onInputChange}
            disabled={viewMode}
            errorMsg={getErrorMessage(props.errors, "redemption.message.title")}
            postfixButtons={hasMultiLanguage && <MultiLangPopupOpener onClick={onShowRedemptionMessageMultiLang} />}
            isRequired={true}
          />

          <InputFieldWithTokens
            label={getMessage("exclusiveAccessAndOffer.offerMessage.text.label")}
            type="text"
            name="redemption.message.text"
            value={reward.redemption.message.text}
            tokens={rewardService.isRewardForFree(reward.masterReward) ? null : REDEMPTION_VALUE_TOKEN}
            onChange={onInputChange}
            disabled={viewMode}
            errorMsg={getErrorMessage(props.errors, "redemption.message.text")}
            postfixButtons={hasMultiLanguage && <MultiLangPopupOpener onClick={onShowRedemptionMessageMultiLang} />}
            isRequired={true}
          />
          {!rewardService.isVoucherForRewards(reward.masterReward) && (
            <div className="e-row">
              <div className="e-col-6">
                <InputField
                  label={getMessage("rewardSetupRedemptionValue.blockExternalLink.url.label")}
                  type="text"
                  name="blockExternalLink.url"
                  value={reward.blockExternalLink.url || "http://"}
                  onChange={onInputChange}
                  disabled={viewMode}
                  errorMsg={getErrorMessage(props.errors, "blockExternalLink.url")}
                  tooltip={getMessage("rewardSetupRedemptionValue.blockExternalLink.url.tooltip")}
                  prefixLabel={<e-icon icon="link" size="small"></e-icon>}
                />
              </div>
              <div className="e-col-6">
                <InputField
                  label={getMessage("rewardSetupRedemptionValue.blockExternalLink.text.label")}
                  type="text"
                  name="blockExternalLink.text"
                  value={reward.blockExternalLink.text}
                  onChange={onInputChange}
                  disabled={viewMode}
                  errorMsg={getErrorMessage(props.errors, "blockExternalLink.text")}
                  tooltip={getMessage("rewardSetupRedemptionValue.blockExternalLink.text.tooltip")}
                  postfixButtons={hasMultiLanguage && <MultiLangPopupOpener onClick={onBlockExternalLinkMultiLang} />}
                />
              </div>
            </div>
          )}
        </div>
        <div className="e-col e-col__separator e-col__separator-fullheight" />
      </div>
    </div>
  );
};

RewardSetupRedemptionValue.propTypes = {
  onRedemptionValueChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  reward: rewardType.isRequired,
  tiers: PropTypes.array.isRequired,
  viewMode: PropTypes.bool,
  onAccordionStateChange: PropTypes.func,
  onShowRedemptionMessageMultiLang: PropTypes.func,
  updateState: PropTypes.func.isRequired,
  notificationElem: PropTypes.element,
  hasMultiLanguage: PropTypes.bool,
  errors: PropTypes.object,
  viewMode: PropTypes.bool
};

export default RewardSetupRedemptionValue;
