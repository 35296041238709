import React, { Component, Fragment } from "react";
import { renderToString } from "react-dom/server";
import PropTypes from "prop-types";

import Widget from "../../components/Widget";
import Time from "../../components/Time";
import WidgetDateRange from "../../components/WidgetDateRange";
import DashboardEmptyState from "./DashboardEmptyState";
import WidgetHelpLink from "./WidgetHelpLink";
import WidgetTotalsItem from "./WidgetTotalsItem";
import WidgetCollapsedContentElement from "./WidgetCollapsedContentElement";

import { LOYALTY_MEMBER_REVENUE_HELP_LINK, TIER_COLORS_ARRAY } from "../../constants/dashboardConstants";
import reportApi from "../../api/reportApi";

import { convertDateForChart, formatTrendText, transformDataDateForDemo, getDaysDiff } from "./dashboardHelper";
import { getMessage } from "../../messages";

const CHART_HEIGHT = 332;

const CHART_COLORS = {
  TOTAL_REVENUE: "#A2B4C6",
  LOYALTY_REVENUE: "#759ECC",
  LOYALTY_IMPACT: "#759ECC"
};

class GeneratedRevenueWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      selectedDaysRange: getDaysDiff(props.initialStartDate, props.initialEndDate),
      dateRange: {
        startDateStr: props.initialStartDate,
        endDateStr: props.initialEndDate
      },
      chartDataTotalRevenue: [],
      chartDataLoyaltyRevenue: [],
      chartCustomTooltip: {},
      totals: {
        totalRevenue: 0,
        loyaltyRevenue: 0,
        loyaltyImpactPct: 0,
        loyaltyRevenuePrevDiff: 0,
        loyaltyImpactPctPrevDiff: 0
      },
      showEmptyState: false
    };
  }

  componentDidMount() {
    this.loadData(this.state.dateRange);
  }

  getCurrencyValueAttr(currency) {
    return { humanize: "auto", precision: "2", "trim-fraction-zeros": "true", type: "currency", currency };
  }

  getPctValueAttr() {
    return { humanize: "auto", precision: "2", type: "percent", "trim-fraction-zeros": "true" };
  }

  loadData(dateRange) {
    const promises = [reportApi.getGeneratedRevenueData(dateRange), reportApi.getGeneratedRevenueTotals(dateRange)];

    this.setState({ loading: true, showEmptyState: false });

    Promise.all(promises)
      .then((response) => {
        this.setChartDataState(response[0]);
        this.setTotalsState(response[1]);
      })
      .catch((error) => {
        this.setState({ showEmptyState: true });
        throw error;
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  getChartTooltipContent(date, { totalRevenue, loyaltyRevenue, loyaltyImpactPct, tiersImpactMap, totalActionRevenue }) {
    let content = [];
    const { currency, tiers } = { ...this.props };

    const dateElm = renderToString(<Time value={date} onlyDate={true} />);

    content.push({ label: dateElm, type: "header" });

    content.push({
      value: totalRevenue || "0",
      label: getMessage("generatedRevenueWidget.chartTooltipContent.totalRevenue.label"),
      color: CHART_COLORS.TOTAL_REVENUE,
      valueFormat: {
        type: "number",
        options: this.getCurrencyValueAttr(currency)
      }
    });

    content.push({
      value: loyaltyRevenue || "0",
      label: getMessage("generatedRevenueWidget.chartTooltipContent.loyaltyRevenue.label"),
      color: CHART_COLORS.LOYALTY_REVENUE,
      valueFormat: {
        type: "number",
        options: this.getCurrencyValueAttr(currency)
      }
    });

    content.push({
      value: totalActionRevenue || "0",
      label: getMessage("generatedRevenueWidget.chartTooltipContent.totalActionRevenue.label"),
      color: CHART_COLORS.LOYALTY_REVENUE,
      valueFormat: {
        type: "number",
        options: this.getCurrencyValueAttr(currency)
      }
    });

    content.push({
      value: loyaltyImpactPct || "0",
      label: getMessage("generatedRevenueWidget.chartTooltipContent.loyaltyImpactPct.label"),
      color: CHART_COLORS.LOYALTY_IMPACT,
      valueFormat: {
        type: "number",
        options: this.getPctValueAttr(currency)
      }
    });

    if (tiers.length > 1) {
      tiers.forEach((tier, index) => {
        const tierImpact = tiersImpactMap[tier.id];

        let pct = tierImpact?.impactPct || 0;

        content.push({
          value: pct.toString(),
          label: tier.name,
          color: TIER_COLORS_ARRAY[index],
          valueFormat: {
            type: "number",
            options: this.getPctValueAttr(currency)
          }
        });
      });
    }

    return content;
  }

  setChartDataState(dataRows) {
    if (!dataRows) {
      this.setState({ showEmptyState: true });
      return;
    }

    dataRows = transformDataDateForDemo(dataRows, this.state.selectedDaysRange);

    const chartDataTotalRevenue = [];
    const chartDataLoyaltyRevenue = [];
    let chartCustomTooltip = {};

    dataRows.forEach((row) => {
      const date = convertDateForChart(row.date);
      chartDataTotalRevenue.push({
        x: date,
        y: row.totalRevenue || 0
      });

      chartDataLoyaltyRevenue.push({
        x: date,
        y: row.loyaltyRevenue || 0
      });

      chartCustomTooltip[date] = { rows: this.getChartTooltipContent(date, row) };
    });

    this.setState({ chartDataTotalRevenue, chartDataLoyaltyRevenue, chartCustomTooltip });
  }

  setTotalsState({
    totalRevenue,
    loyaltyRevenue,
    loyaltyImpactPct,
    loyaltyRevenuePrevDiff,
    loyaltyImpactPctPrevDiff,
    loyaltyActionRevenue,
    loyaltyActionRevenueDiff
  }) {
    const totals = {
      totalRevenue,
      loyaltyRevenue,
      loyaltyImpactPct,
      loyaltyRevenuePrevDiff,
      loyaltyImpactPctPrevDiff,
      loyaltyActionRevenue,
      loyaltyActionRevenueDiff
    };

    this.setState({ totals });
  }

  onTimeRangeChange(value) {
    const { start, end } = value;
    let dateRange = {
      startDateStr: start,
      endDateStr: end
    };
    this.setState({ dateRange, selectedDaysRange: getDaysDiff(start, end) });
    this.loadData(dateRange);
  }

  getTrendText(value, daysRange) {
    return formatTrendText`${value} than previous ${daysRange} days`;
  }

  renderTotals() {
    let {
      totalRevenue,
      loyaltyRevenue,
      loyaltyImpactPct,
      loyaltyRevenuePrevDiff,
      loyaltyImpactPctPrevDiff,
      loyaltyActionRevenue,
      loyaltyActionRevenueDiff
    } = {
      ...this.state.totals
    };
    const daysRange = this.state.selectedDaysRange;
    const { currency } = { ...this.props };
    loyaltyActionRevenue = loyaltyActionRevenue || 0;
    loyaltyActionRevenueDiff = loyaltyActionRevenueDiff || 0;

    return (
      <Fragment>
        <div className="p-b-10">
          <WidgetTotalsItem
            title={getMessage("generatedRevenueWidget.totals.totalRevenue.title")}
            titleTooltip={getMessage("generatedRevenueWidget.totals.totalRevenue.titleTooltip")}
            value={totalRevenue}
            valueNumericAttr={this.getCurrencyValueAttr(currency)}
            valueColor={CHART_COLORS.TOTAL_REVENUE}
          />
        </div>

        <WidgetTotalsItem
          title={getMessage("generatedRevenueWidget.totals.loyaltyRevenue.title")}
          titleTooltip={getMessage("generatedRevenueWidget.totals.loyaltyRevenue.titleTooltip")}
          value={loyaltyRevenue}
          valueNumericAttr={this.getCurrencyValueAttr(currency)}
          valueColor={CHART_COLORS.LOYALTY_REVENUE}
          trendValue={loyaltyRevenuePrevDiff}
          trendValueNumericAttr={this.getCurrencyValueAttr(currency)}
          trendText={this.getTrendText(loyaltyRevenuePrevDiff, daysRange)}
        />

        <WidgetTotalsItem
          title={getMessage("generatedRevenueWidget.totals.loyaltyActionRevenue.title")}
          titleTooltip={getMessage("generatedRevenueWidget.totals.loyaltyActionRevenue.titleTooltip")}
          value={loyaltyActionRevenue}
          valueNumericAttr={this.getCurrencyValueAttr(currency)}
          valueColor={CHART_COLORS.LOYALTY_REVENUE}
          trendValue={loyaltyActionRevenueDiff}
          trendValueNumericAttr={this.getCurrencyValueAttr(currency)}
          trendText={this.getTrendText(loyaltyActionRevenueDiff, daysRange)}
        />

        <WidgetTotalsItem
          title={getMessage("generatedRevenueWidget.totals.loyaltyImpactPct.title")}
          titleTooltip={getMessage("generatedRevenueWidget.totals.loyaltyImpactPct.titleTooltip")}
          value={loyaltyImpactPct}
          valueNumericAttr={this.getPctValueAttr()}
          valueColor={CHART_COLORS.LOYALTY_IMPACT}
          trendValue={loyaltyImpactPctPrevDiff}
          trendText={this.getTrendText(loyaltyImpactPctPrevDiff, daysRange)}
        />
      </Fragment>
    );
  }

  renderChart() {
    const { chartDataTotalRevenue, chartDataLoyaltyRevenue, chartCustomTooltip } = { ...this.state };
    return (
      <ec-chart domain-type="time" height={CHART_HEIGHT} custom-tooltip={JSON.stringify(chartCustomTooltip)}>
        <ec-series-line
          name={getMessage("generatedRevenueWidget.chartDataTotalRevenue.name")}
          data={JSON.stringify(chartDataTotalRevenue)}
          color={CHART_COLORS.TOTAL_REVENUE}
        ></ec-series-line>
        <ec-series-area
          name={getMessage("generatedRevenueWidget.chartDataLoyaltyRevenue.name")}
          data={JSON.stringify(chartDataLoyaltyRevenue)}
          color={CHART_COLORS.LOYALTY_REVENUE}
        ></ec-series-area>
      </ec-chart>
    );
  }

  renderContent() {
    const { showEmptyState } = { ...this.state };

    if (showEmptyState) {
      return <DashboardEmptyState />;
    }

    return (
      <Fragment>
        <div className="e-grid">
          <div className="e-cell e-cell-3">{this.renderTotals()}</div>
          <div className="e-cell e-cell-auto">{this.renderChart()}</div>
        </div>
      </Fragment>
    );
  }

  getCollapsedContentElements() {
    const { totalRevenue, loyaltyRevenue, loyaltyImpactPct } = { ...this.state.totals };
    const { selectedDaysRange } = { ...this.state };
    const { currency } = { ...this.props };

    const elements = [
      <WidgetCollapsedContentElement
        value={totalRevenue}
        valueNumericAttr={this.getCurrencyValueAttr(currency)}
        valueColor={CHART_COLORS.TOTAL_REVENUE}
        title={getMessage("generatedRevenueWidget.widgetCollapsedContentElement.totalRevenue.title")}
      />,
      <WidgetCollapsedContentElement
        value={loyaltyRevenue}
        valueNumericAttr={this.getCurrencyValueAttr(currency)}
        valueColor={CHART_COLORS.LOYALTY_REVENUE}
        title={getMessage("generatedRevenueWidget.widgetCollapsedContentElement.loyaltyRevenue.title")}
      />,
      <WidgetCollapsedContentElement
        value={loyaltyImpactPct}
        valueNumericAttr={this.getPctValueAttr()}
        valueColor={CHART_COLORS.LOYALTY_IMPACT}
        title={getMessage("generatedRevenueWidget.widgetCollapsedContentElement.loyaltyImpactPct.title")}
      />,
      <Fragment>{getMessage("inThePastDays", { selectedDaysRange })}</Fragment>
    ];

    return elements;
  }

  render() {
    return (
      <Widget
        title={getMessage("generatedRevenueWidget.title")}
        className="generated-revenue-widget"
        headerActions={
          <Fragment>
            <WidgetDateRange
              onChange={this.onTimeRangeChange.bind(this)}
              isDemoMode={false}
              dateRange={{ start: this.state.dateRange.startDateStr, end: this.state.dateRange.endDateStr }}
            />
          </Fragment>
        }
        footerActions={<WidgetHelpLink linkUrl={LOYALTY_MEMBER_REVENUE_HELP_LINK} />}
        content={this.renderContent()}
        loading={this.state.loading}
        collapsedContentElements={this.getCollapsedContentElements()}
        isEmptyState={this.state.showEmptyState}
      />
    );
  }
}

GeneratedRevenueWidget.propTypes = {
  currency: PropTypes.string.isRequired,
  tiers: PropTypes.array,
  initialStartDate: PropTypes.string,
  initialEndDate: PropTypes.string
};

export default GeneratedRevenueWidget;
