import moment from "moment-timezone";

export const formatIsoTime = (value) => {
  if (!value) {
    return "";
  }

  const valueIso = moment(value).toISOString(true); //for example: 2022-10-18T12:51:47.636+03:00
  const valueIsoWithoutOffset = valueIso.split("+")[0]; //for example: 2022-10-18T12:51:47.636
  return valueIsoWithoutOffset;
};

export const setDefaultTimezone = (timezone) => {
  moment.tz.setDefault(timezone);
};
