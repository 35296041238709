import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import InputField from "./InputField";
import { getMessage } from "../messages";

const InputSearchField = (props) => {
  //on each char change - call the change function with value of string

  const additionalProps = { ...props };
  additionalProps.type = "search";
  additionalProps.className = classNames(additionalProps.className, "e-input-search");

  return <InputField {...additionalProps} />;
};

export default InputSearchField;
