import React from "react";
import PropTypes from "prop-types";

import InputField from "../../components/InputField";

import { getMultiLangFieldLabel } from "../../helpers/multiLangHelper";

const MultiLangActionNameField = ({ onChange, lang, translations, disabled, label }) => {
  return (
    <InputField
      label={getMultiLangFieldLabel({ label, lang })}
      value={translations.nameML[lang]}
      name={"nameML"}
      onChange={(event) => onChange(event, lang)}
      disabled={disabled}
    />
  );
};

MultiLangActionNameField.propTypes = {
  onChange: PropTypes.func,
  lang: PropTypes.string,
  translations: PropTypes.object,
  disabled: PropTypes.bool
};

export default MultiLangActionNameField;
