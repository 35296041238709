import axios from "axios";

export default class securityApi {
  static async getApiKeys() {
    const response = await axios.get(`/api-keys`);
    return response.data;
  }

  static async createApiKey(password) {
    const response = await axios.post(`/api-key`, {
      password
    });
    return response.data;
  }

  static async updateApiKey({ id, desc, active, isContactFullDelete }) {
    const response = await axios.put(`/api-key/${id}`, {
      desc,
      active,
      isContactFullDelete
    });
    return response.data;
  }

  static async deleteApiKey(apiKeyId, password) {
    const response = await axios.delete(`/api-key/${apiKeyId}`, {
      data: {
        password
      }
    });
    return response.data;
  }

  static async getWalletApps() {
    const response = await axios.get(`/wallet-app`);
    return response.data;
  }

  static async createWalletApp(password) {
    const response = await axios.post(`/wallet-app`, {
      password
    });
    return response.data;
  }

  static async updateWalletApp({ id, desc, active, isContactFullDelete }) {
    const response = await axios.put(`/wallet-app/${id}`, {
      desc,
      active,
      isContactFullDelete
    });
    return response.data;
  }

  static async deleteWalletApp(walletAppId, password) {
    const response = await axios.delete(`/wallet-app/${walletAppId}`, {
      data: {
        password
      }
    });
    return response.data;
  }

  static async createWalletAppSecret(walletAppId, password) {
    const response = await axios.post(`/wallet-app/${walletAppId}/secret`, {
      password
    });
    return response.data;
  }

  static async getRdsSettings() {
    const response = await axios.get(`/rds-settings`);
    return response.data;
  }

  static async createRdsCredentials(password) {
    const response = await axios.post(`/rds-credentials`, {
      password
    });
    return response.data;
  }
}
