import React, { Component } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash.isequal";

import StylePickerBar from "./StylePickerBar";
import LinkDialog from "./LinkDialog";
import TemplateHtmlContent from "./TemplateHtmlContent";
import Switch from "../../../components/Switch";

import { validateSingleValue } from "../../../validators";
import exclusiveAccessPopupValidator from "../../../validators/exclusiveAccessPopupValidator";

import {
  PICKER_TYPE,
  EDITOR_STYLES_DEFAULT_VALUES,
  LINK_BUTTON_ENUM,
  LINK_BUTTON_OPTIONS,
  BLOCKING_EDITOR_MODE_ENUM,
  EDITOR_LINKS_DEFAULT_VALUES
} from "../../../constants/rewardConstants";

import TemplateHtmlPreview from "./TemplateHtmlPreview";

import { getValueByType } from "../../../helpers/formHelper";

import "../../../assets/styles/ExclusiveAccessCustomTemplateEditor.scss";
import { Fragment } from "react";
import { getMessage } from "../../../messages";

const stylePickers = {
  overlayColor: {
    type: PICKER_TYPE.COLOR,
    label: getMessage("stylePicker.overlayColor.label"),
    name: "overlayColor",
    id: "overlayColor",
    value: "#000000",
    pickerKey: ""
  },
  overlayOpacity: {
    type: PICKER_TYPE.NUM_INPUT,
    label: getMessage("stylePicker.overlayOpacity.label"),
    name: "overlayOpacity",
    id: "overlayOpacity",
    value: "50",
    pickerKey: ""
  },
  contentBackgroundColor: {
    type: PICKER_TYPE.COLOR,
    label: getMessage("stylePicker.contentBackgroundColor.label"),
    name: "contentBackgroundColor",
    id: "contentBackgroundColor",
    value: "#FFFFFF",
    pickerKey: ""
  },
  tagColor: {
    type: PICKER_TYPE.COLOR,
    label: getMessage("stylePicker.tagColor.label"),
    name: "tagColor",
    id: "tagColor",
    value: "#C43A3A",
    pickerKey: ""
  },
  tagRadius: {
    type: PICKER_TYPE.NUM_INPUT,
    label: getMessage("stylePicker.tagRadius.label"),
    name: "tagRadius",
    id: "tagRadius",
    value: "25",
    pickerKey: ""
  },
  leftButtonBackgroundColor: {
    type: PICKER_TYPE.COLOR,
    label: getMessage("stylePicker.leftButtonBackgroundColor.label"),
    name: "leftButtonBackgroundColor",
    id: "leftButtonBackgroundColor",
    value: "#617cb6",
    pickerKey: ""
  },
  rightButtonBackgroundColor: {
    type: PICKER_TYPE.COLOR,
    label: getMessage("stylePicker.rightButtonBackgroundColor.label"),
    name: "rightButtonBackgroundColor",
    id: "rightButtonBackgroundColor",
    value: "#FFFFFF",
    pickerKey: ""
  },
  buttonRadius: {
    type: PICKER_TYPE.NUM_INPUT,
    label: getMessage("stylePicker.buttonRadius.label"),
    name: "buttonRadius",
    id: "buttonRadius",
    value: "4",
    pickerKey: ""
  }
};

const linkPickers = {
  leftButtonLink: {
    type: PICKER_TYPE.LINK_DIALOG,
    label: getMessage("linkPickers.leftButtonLink.label"),
    name: "leftButtonLink",
    id: "leftButtonLink",
    pickerKey: ""
  },
  rightButtonLink: {
    type: PICKER_TYPE.LINK_DIALOG,
    label: getMessage("linkPickers.rightButtonLink.label"),
    name: "rightButtonLink",
    id: "rightButtonLink",
    pickerKey: ""
  }
};

class ExclusiveAccessCustomTemplateEditor extends Component {
  constructor(props) {
    super(props);

    const editorLinksValues = this.getEditorLinkValues();

    const stylePickersWithValues = this.getPickersWithValues(stylePickers, this.props.editorStyles);
    const linkPickersWithValues = this.getPickersWithValues(linkPickers, editorLinksValues);

    this.state = {
      stylePickers: stylePickersWithValues,
      linkPickers: linkPickersWithValues,
      editorLinks: this.props.editorLinks[this.props.currentMode],
      currentHtml: "",
      chosenButton: null,
      loading: false,
      highlightEditable: false,
      showLinkDialog: false,
      highlightPickerName: "",
      errors: {}
    };

    this.validator = new exclusiveAccessPopupValidator();

    this.updateStateOnPickerChange = this.updateStateOnPickerChange.bind(this);
    this.onColorChange = this.onColorChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount() {
    let currentHtml = this.getCurrentHtml();

    this.setState({
      currentHtml
    });

    this.props.onHtmlContentChangeNewExclusive(
      this.contentMemberElm.innerHTML,
      this.contentMemberMobileElm.innerHTML,
      this.contentNonMemberElm.innerHTML,
      this.contentNonMemberMobileElm.innerHTML
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentMode !== this.props.currentMode || !isEqual(prevProps.editorLinks, this.props.editorLinks)) {
      const editorLinksValues = this.getEditorLinkValues();
      const linkPickersWithValues = this.getPickersWithValues(linkPickers, editorLinksValues);
      this.setState({
        editorLinks: this.props.editorLinks[this.props.currentMode],
        linkPickers: linkPickersWithValues
      });

      this.triggerHtmlContentChange();
    }

    if (prevProps.selectedLang !== this.props.selectedLang) {
      this.triggerHtmlContentChange();
    }
  }

  getCurrentHtml() {
    switch (this.props.currentMode) {
      case BLOCKING_EDITOR_MODE_ENUM.GENERAL:
        return this.contentGeneralElm.innerHTML;
      case BLOCKING_EDITOR_MODE_ENUM.GENERAL_MOBILE:
        return this.contentGeneralMobileElm.innerHTML;
      case BLOCKING_EDITOR_MODE_ENUM.MEMBER:
        return this.contentMemberElm.innerHTML;
      case BLOCKING_EDITOR_MODE_ENUM.MEMBER_MOBILE:
        return this.contentMemberMobileElm.innerHTML;
      case BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER:
        return this.contentNonMemberElm.innerHTML;
      case BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER_MOBILE:
        return this.contentNonMemberMobileElm.innerHTML;
    }
  }

  getPickersWithValues(pickers, editorElements) {
    let pickersWithValue = {};

    Object.keys(pickers).forEach((key) => {
      pickersWithValue[key] = pickers[key];
      pickersWithValue[key].value = editorElements[key];
    });

    return pickersWithValue;
  }

  getEditorLinkValues() {
    let editorLinksValues = {};
    const editorLinks = this.props.editorLinks[this.props.currentMode];

    for (let key in editorLinks) {
      if (editorLinks.hasOwnProperty(key)) {
        editorLinksValues[key] =
          editorLinks[key].redirectOption === LINK_BUTTON_ENUM.RETURN
            ? LINK_BUTTON_OPTIONS[0].name
            : LINK_BUTTON_OPTIONS[1].name;
      }
    }
    return editorLinksValues;
  }

  onColorChange(event) {
    const target = event.target;
    const targetId = target.id;
    const choosedPicker = this.state.stylePickers[targetId];
    const newValue = event.detail.value;

    this.updateStateOnPickerChange(targetId, choosedPicker, newValue);
  }

  onInputChange(event, customValue) {
    const target = event.target;
    const targetName = target.getAttribute("name");
    const choosedPicker = this.state.stylePickers[targetName];
    const newValue = customValue || getValueByType(target);

    this.updateStateOnPickerChange(targetName, choosedPicker, newValue);
  }

  onStylePickerClear(pickerId) {
    const choosedPicker = this.state.stylePickers[pickerId];
    this.updateStateOnPickerChange(pickerId, choosedPicker, EDITOR_STYLES_DEFAULT_VALUES[pickerId]);
  }

  onLinkPickerClear(pickerId) {
    const { linkPickers, editorLinks } = this.state;
    const choosedPicker = linkPickers[pickerId];

    const newEditorLinks = {
      ...editorLinks,
      [pickerId]: EDITOR_LINKS_DEFAULT_VALUES[pickerId]
    };

    this.setState({
      linkPickers: {
        ...linkPickers,
        [pickerId]: {
          ...choosedPicker,
          value: LINK_BUTTON_OPTIONS[0].name
        }
      },
      editorLinks: newEditorLinks
    });
    this.props.onApplyLink(newEditorLinks);
    this.triggerHtmlContentChange();
  }

  validate(exclusiveAccessPopup) {
    return this.validator.validateExclusiveAccessPopup(exclusiveAccessPopup);
  }

  onLinkDialogChange(event, customValue) {
    const { editorLinks, chosenButton, linkPickers } = this.state;
    const target = event.target;
    const name = target.getAttribute("name");
    let value = target.value;

    if (name === "redirectOption") {
      this.setState({
        linkPickers: {
          ...linkPickers,
          [chosenButton]: {
            ...linkPickers[chosenButton],
            value: value === LINK_BUTTON_ENUM.RETURN ? LINK_BUTTON_OPTIONS[0].name : LINK_BUTTON_OPTIONS[1].name
          }
        },
        editorLinks: {
          ...editorLinks,
          [chosenButton]: {
            ...editorLinks[chosenButton],
            redirectOption: value
          }
        }
      });
    }

    if (name === "redirectUrl") {
      const newEditorLinksChoosenButton = {
        ...editorLinks[chosenButton],
        redirectUrl: value
      };
      const errors = validateSingleValue(() => this.validate(newEditorLinksChoosenButton), this.state.errors, name);
      this.setState({
        editorLinks: {
          ...editorLinks,
          [chosenButton]: {
            ...editorLinks[chosenButton],
            redirectUrl: value
          }
        },
        errors
      });
    }

    if (name === "lsOpenWallet") {
      this.setState({
        editorLinks: {
          ...editorLinks,
          [chosenButton]: {
            ...editorLinks[chosenButton],
            lsOpenWallet: customValue
          }
        }
      });
    }
  }

  onCancelLinkDialog() {
    const { chosenButton } = this.state;
    const { editorLinks, currentMode } = this.props;
    const initialEditorLinksCurrentMode = editorLinks[currentMode];
    const initialEditorButtonLink = initialEditorLinksCurrentMode[chosenButton];

    if (chosenButton) {
      this.setState({
        editorLinks: {
          ...this.state.editorLinks,
          [chosenButton]: { ...initialEditorButtonLink }
        },
        linkPickers: {
          ...this.state.linkPickers,
          [chosenButton]: {
            ...this.state.linkPickers[chosenButton],
            value:
              initialEditorButtonLink.redirectOption === LINK_BUTTON_ENUM.RETURN
                ? LINK_BUTTON_OPTIONS[0].name
                : LINK_BUTTON_OPTIONS[1].name
          }
        },
        showLinkDialog: false,
        chosenButton: null,
        errors: {}
      });
    }
  }

  onApplyLinkDialog() {
    const { editorLinks, chosenButton } = this.state;

    const errors = this.validate(editorLinks[chosenButton]);

    if (errors) {
      this.setState({
        errors
      });
      return;
    }

    this.setState({
      showLinkDialog: false,
      chosenButton: null
    });

    this.props.onApplyLink(editorLinks);
    this.triggerHtmlContentChange();
  }

  onContentTextChange(key, text) {
    this.props.onEditorContentTextChange(key, text);
    this.triggerHtmlContentChange();
  }

  onHighlightSwitchChange(event) {
    const value = getValueByType(event.target);
    this.setState({
      highlightEditable: value
    });
    this.triggerHtmlContentChange();
  }

  updateStateOnPickerChange(pickerId, picker, value) {
    this.setState({
      stylePickers: {
        ...this.state.stylePickers,
        [pickerId]: {
          ...picker,
          value
        }
      }
    });

    this.props.onEditorStyleChange(pickerId, value);
    this.triggerHtmlContentChange();
  }

  updateHtmlChange() {
    let currentHtml = this.getCurrentHtml();

    this.setState({
      currentHtml
    });

    this.props.onHtmlContentChange(
      this.contentMemberElm.innerHTML,
      this.contentMemberMobileElm.innerHTML,
      this.contentNonMemberElm.innerHTML,
      this.contentNonMemberMobileElm.innerHTML
    );
  }

  triggerHtmlContentChange() {
    setTimeout(this.updateHtmlChange.bind(this), 100);
  }

  onShowLinkDialog(event) {
    const target = event.target;
    const targetName = target.getAttribute("name");

    this.setState({
      showLinkDialog: true,
      chosenButton: targetName
    });
  }

  onHighlightPicker(name) {
    this.setState({
      highlightPickerName: name
    });

    this.triggerHtmlContentChange();
  }

  renderSidebarBody() {
    const { stylePickers, linkPickers } = this.state;
    const { isForGeneral, isForMember, isForNonMember, isForMobile } = this.props.editMode;

    const pickers = isForGeneral ? { ...stylePickers, ...linkPickers } : linkPickers;
    const onClear = isForGeneral ? this.onStylePickerClear.bind(this) : this.onLinkPickerClear.bind(this);

    return (
      <Fragment>
        <div className="e-section__header">
          <div className="e-section__title">
            <div className="text-size-button">
              {getMessage("exclusiveAccessCustomTemplateEditor.styleEditorSidebar.sidebarBody.title")}
            </div>
          </div>
          <div className="e-section__actions">{this.props.deviceModeTabsComp}</div>
        </div>
        <div className="e-section__content">
          <StylePickerBar
            isGeneral={isForGeneral}
            isMember={isForMember}
            isNonMember={isForNonMember}
            pickers={pickers}
            disabled={isForMobile || this.props.isViewOnly}
            onShowLinkDialog={this.onShowLinkDialog.bind(this)}
            onColorChange={this.onColorChange.bind(this)}
            onInputChange={this.onInputChange.bind(this)}
            onClear={onClear}
            onHighlightPicker={this.onHighlightPicker.bind(this)}
          />
        </div>
      </Fragment>
    );
  }

  renderLinkDialog() {
    const { showLinkDialog, chosenButton, editorLinks } = this.state;

    if (!editorLinks) return;

    const chosenButtonParameters = editorLinks[chosenButton];
    return (
      <LinkDialog
        showLinkDialog={showLinkDialog}
        redirectOption={chosenButtonParameters ? chosenButtonParameters.redirectOption : ""}
        redirectUrl={chosenButtonParameters ? chosenButtonParameters.redirectUrl : ""}
        lsOpenWallet={chosenButtonParameters ? chosenButtonParameters.lsOpenWallet : true}
        onLinkDialogChange={this.onLinkDialogChange.bind(this)}
        onApply={this.onApplyLinkDialog.bind(this)}
        onCancel={this.onCancelLinkDialog.bind(this)}
        errors={this.state.errors}
      />
    );
  }

  renderStyleEditorSidebar() {
    return (
      <div className="e-verticalnav e-verticalnav-fullheight">
        {this.renderLinkDialog()}
        {this.props.memberModeTabsComp}
        <div class="e-verticalnav__content e-verticalnav__content-nopadding e-verticalnav__content-fullheight">
          <div className="e-section">{this.renderSidebarBody()}</div>
        </div>
      </div>
    );
  }

  render() {
    const pickerStyle = {
      overlayColor: this.state.stylePickers.overlayColor.value,
      overlayOpacity: this.state.stylePickers.overlayOpacity.value,
      contentBackgroundColor: this.state.stylePickers.contentBackgroundColor.value,
      tagBackgourdColor: this.state.stylePickers.tagColor.value,
      tagRadius: this.state.stylePickers.tagRadius.value,
      leftButtonBackgroundColor: this.state.stylePickers.leftButtonBackgroundColor.value,
      // leftButtonLink: this.state.pickers.leftButtonLink.value,
      rightButtonBackgroundColor: this.state.stylePickers.rightButtonBackgroundColor.value,
      // rightButtonLink: this.state.pickers.rightButtonLink.value,
      buttonRadius: this.state.stylePickers.buttonRadius.value
    };

    return (
      <main className="e-layout__content exclusive-access-template-editor">
        <section className="style-editor-sidebar e-layout__section e-contentblocks-navigation_section e-layout__section-sidebar_with_verticalnav">
          {this.renderStyleEditorSidebar()}
        </section>
        <section className="e-layout__section">
          <div className="e-fullheight__content e-fullheight__content-box no-margin">
            <div className="e-fullheight__header">
              <Switch
                label={getMessage("exclusiveAccessCustomTemplateEditor.highlightEditable.switch.label")}
                enabled={this.state.highlightEditable}
                onChange={this.onHighlightSwitchChange.bind(this)}
                disabled={this.props.isViewOnly}
              />
              <div className="e-fullheight__actions">{this.props.multiLangSelect}</div>
            </div>

            <div className="e-fullheight__body no-padding" style={{ position: "relative" }}>
              <TemplateHtmlPreview
                content={this.state.currentHtml}
                isMobile={this.props.editMode.isMobile}
                isMember={this.props.editMode.isForMember}
                onContentTextChange={this.onContentTextChange.bind(this)}
                isViewOnly={this.props.isViewOnly}
              />
            </div>
          </div>

          <div ref={(elem) => (this.contentGeneralElm = elem)} style={{ display: "none" }}>
            <TemplateHtmlContent
              pickerStyle={pickerStyle}
              contentTexts={this.props.editorContent[BLOCKING_EDITOR_MODE_ENUM.GENERAL].texts}
              highlightEditable={this.state.highlightEditable}
              highlightPickerName={this.state.highlightPickerName}
            />
          </div>

          <div ref={(elem) => (this.contentGeneralMobileElm = elem)} style={{ display: "none" }}>
            <TemplateHtmlContent
              pickerStyle={pickerStyle}
              contentTexts={this.props.editorContent[BLOCKING_EDITOR_MODE_ENUM.GENERAL_MOBILE].texts}
              highlightEditable={this.state.highlightEditable}
              isMobile={true}
              highlightPickerName={this.state.highlightPickerName}
            />
          </div>

          <div ref={(elem) => (this.contentMemberElm = elem)} style={{ display: "none" }}>
            <TemplateHtmlContent
              pickerStyle={pickerStyle}
              contentTexts={this.props.editorContent[BLOCKING_EDITOR_MODE_ENUM.MEMBER].texts}
              contentLinks={
                this.props.currentMode === BLOCKING_EDITOR_MODE_ENUM.MEMBER
                  ? this.state.editorLinks
                  : this.props.editorLinks[BLOCKING_EDITOR_MODE_ENUM.MEMBER]
              }
              highlightEditable={this.state.highlightEditable}
              highlightPickerName={this.state.highlightPickerName}
            />
          </div>

          <div ref={(elem) => (this.contentMemberMobileElm = elem)} style={{ display: "none" }}>
            <TemplateHtmlContent
              pickerStyle={pickerStyle}
              contentTexts={this.props.editorContent["memberMobile"].texts}
              contentLinks={
                this.props.currentMode === BLOCKING_EDITOR_MODE_ENUM.MEMBER_MOBILE
                  ? this.state.editorLinks
                  : this.props.editorLinks[BLOCKING_EDITOR_MODE_ENUM.MEMBER_MOBILE]
              }
              isMobile={true}
              highlightEditable={this.state.highlightEditable}
              highlightPickerName={this.state.highlightPickerName}
            />
          </div>

          <div ref={(elem) => (this.contentNonMemberElm = elem)} style={{ display: "none" }}>
            <TemplateHtmlContent
              pickerStyle={pickerStyle}
              contentTexts={this.props.editorContent["nonMember"].texts}
              contentLinks={
                this.props.currentMode === BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER
                  ? this.state.editorLinks
                  : this.props.editorLinks[BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER]
              }
              highlightEditable={this.state.highlightEditable}
              highlightPickerName={this.state.highlightPickerName}
            />
          </div>

          <div ref={(elem) => (this.contentNonMemberMobileElm = elem)} style={{ display: "none" }}>
            <TemplateHtmlContent
              pickerStyle={pickerStyle}
              contentTexts={this.props.editorContent["nonMemberMobile"].texts}
              contentLinks={
                this.props.currentMode === BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER_MOBILE
                  ? this.state.editorLinks
                  : this.props.editorLinks[BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER_MOBILE]
              }
              isMobile={true}
              highlightEditable={this.state.highlightEditable}
              highlightPickerName={this.state.highlightPickerName}
            />
          </div>
        </section>
      </main>
    );
  }
}

ExclusiveAccessCustomTemplateEditor.propTypes = {
  editMode: PropTypes.shape({
    isForMobile: PropTypes.bool.isRequired,
    isForGeneral: PropTypes.bool.isRequired,
    isForMember: PropTypes.bool.isRequired,
    isForNonMember: PropTypes.bool.isRequired
  }),
  currentMode: PropTypes.string.isRequired,
  editorStyles: PropTypes.object.isRequired,
  editorLinks: PropTypes.object.isRequired,
  editorContent: PropTypes.object.isRequired,
  onHtmlContentChange: PropTypes.func.isRequired,
  onHtmlContentChangeNewExclusive: PropTypes.func.isRequired,
  onEditorStyleChange: PropTypes.func.isRequired,
  onEditorContentTextChange: PropTypes.func.isRequired,
  onApplyLink: PropTypes.func.isRequired,
  memberModeTabsComp: PropTypes.element.isRequired,
  deviceModeTabsComp: PropTypes.element.isRequired,
  selectedLang: PropTypes.string,
  multiLangSelect: PropTypes.element,
  isViewOnly: PropTypes.bool
};

export default ExclusiveAccessCustomTemplateEditor;
