import React, { Component } from "react";
import { connect } from "react-redux";
import isEqual from "lodash.isequal";
import PropTypes from "prop-types";
import classNames from "classnames";
import uniqBy from "lodash/uniqBy";

import InputSearchField from "./InputSearchField";
import CheckboxField from "./CheckboxField";
import SelectField from "./SelectFieldV2";
import EmptyState from "./EmptyState";

import catalogApi from "../api/catalogApi";

import { showErrorAlert } from "../actions/alertActions";

import { isEmptyObject } from "../helpers/objectHelper";

import {
  CATALOG_ITEM_TYPE_OPTIONS,
  CATALOG_ITEM_TYPE_OPTIONS_NO_PRODUCT,
  CATALOG_ITEM_TYPE_ENUM
} from "../constants/catalogConstants";

import "../assets/styles/CatalogSelector.scss";
import { getMessage } from "../messages";
import ConditionalWrapper from "./ConditionalWrapper";

const isItemType = (type) => {
  return [CATALOG_ITEM_TYPE_ENUM.ITEM, CATALOG_ITEM_TYPE_ENUM.ITEM_ID].includes(type);
};

class CatalogSelector extends Component {
  constructor(props) {
    super(props);

    let itemsPerLevel = [];
    this.state = {
      itemsPerLevel,
      selectedItems: {},
      showAllSelections: false,
      checkAllItems: false,
      searchValue: "",
      catalogSearchResultLoading: false,
      searchType: CATALOG_ITEM_TYPE_OPTIONS[0].value,
      noSearchResultsMessage: null
    };

    this.searchChangeTimeout = null;
    this.disabledItemIds = {
      [CATALOG_ITEM_TYPE_ENUM.CATEGORY]: props.disabledCategoryIds || [],
      [CATALOG_ITEM_TYPE_ENUM.BRAND]: props.disabledBrandIds || [],
      [CATALOG_ITEM_TYPE_ENUM.ITEM]: props.disabledItemIds || []
    };
  }

  async componentDidMount() {
    const initialBrandIds = this.props.initialBrandIds || [];
    const initialCategoryIds = this.props.initialCategoryIds || [];
    const initialItemIds = this.props.initialItemIds || [];

    if (initialCategoryIds.length === 0 && initialItemIds.length === 0 && initialBrandIds.length === 0) {
      return;
    }

    const items = await catalogApi.getCatalogRecordsById({
      brands: initialBrandIds,
      categories: initialCategoryIds,
      items: initialItemIds
    });

    const selectedItems = {};
    items.forEach((item) => {
      selectedItems[item.id] = {
        id: item.id,
        name: item.name,
        level: item.level,
        type: item.type
      };

      item.checked = true;
    });

    this.setState({
      selectedItems,
      showAllSelections: true,
      checkAllItems: true,
      itemsPerLevel: this.resetItemsPerLevel(items)
    });
  }

  generateData(level, text, selectedId, isChecked) {
    const items = Array.from(Array(30).keys()).map((index) => {
      const id = selectedId ? `${selectedId}_${index}` : `${level}_${index}`;
      const name = `category ${text}-${id}`;
      return {
        id,
        name,
        checked: !!isChecked
      };
    });

    return items;
  }

  resetItemsPerLevel(items) {
    const itemsPerLevel = [];

    items.sort((a, b) => {
      if (a.type === CATALOG_ITEM_TYPE_ENUM.CATEGORY && isItemType(b.type)) {
        return -1;
      }

      if (isItemType(a.type) && b.type === CATALOG_ITEM_TYPE_ENUM.CATEGORY) {
        return 1;
      }

      return 0;
    });

    itemsPerLevel[0] = {
      selectedItemId: "",
      items
    };

    return itemsPerLevel;
  }

  isItemChecked(itemId, level) {
    const { itemsPerLevel } = this.state;
    const isChecked = itemsPerLevel[level].items.some((item) => item.id === itemId && item.checked);
    return isChecked;
  }

  getSelectedItemIds(selectedItems) {
    let result = {
      brands: [],
      categories: [],
      items: []
    };

    Object.keys(selectedItems).forEach((itemId) => {
      const item = selectedItems[itemId];
      if (item.type === CATALOG_ITEM_TYPE_ENUM.CATEGORY) {
        result.categories.push(itemId);
      } else if (isItemType(item.type)) {
        result.items.push(itemId);
      } else if (item.type === CATALOG_ITEM_TYPE_ENUM.BRAND) {
        result.brands.push(itemId);
      }
    });

    return result;
  }

  getNoResultsMessage(responseObj) {
    if (responseObj.tooManyResults) {
      return {
        title: getMessage("catalogSelector.tooManyResults.title"),
        text: getMessage("catalogSelector.tooManyResults.text")
      };
    }

    if (responseObj.results.length === 0) {
      const text = getMessage("noResults.text", { text: this.state.searchValue });

      return { title: getMessage("noResults.title"), text };
    }

    return null;
  }

  async getSearchResults(query, type) {
    try {
      let items = [];
      let noSearchResultsMessage = null;

      if (query.length > 2) {
        const res = await catalogApi.searchCatalog(query, type);

        //if the search value in the response is different from the current search value - it's an old request - return
        if (res.query.toLowerCase() !== this.state.searchValue.toLowerCase()) {
          return;
        }

        noSearchResultsMessage = this.getNoResultsMessage(res);

        if (!noSearchResultsMessage) {
          items = res.results;
          items.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            const indexOfA = nameA.indexOf(query.toLowerCase());
            const indexOfB = nameB.indexOf(query.toLowerCase());

            //sort order:
            //1. if indexOf query equal to -1
            //2. the indexOf query in lower
            //3. sort alphabetically
            if (indexOfA === -1) return 1;
            if (indexOfB === -1) return -1;

            if (indexOfA > indexOfB) return 1;
            if (indexOfA < indexOfB) return -1;

            if (nameA > nameB) return 1;
            if (nameA < nameB) return -1;

            return 0;
          });
        }
      }

      items = uniqBy(items, "id");
      const itemsPerLevel = this.resetItemsPerLevel(items);
      this.setState({
        noSearchResultsMessage,
        itemsPerLevel
      });
    } catch (err) {
      this.props.showErrorAlert(getMessage("catalogSelector.errorAlert"));
      throw err;
    }
  }

  //check if all items are checked in a given level
  areAllItemsChecked(level) {
    const { itemsPerLevel } = this.state;
    const items = itemsPerLevel[level].items;

    const hasOneNotChecked = items.some((item) => !item.checked);
    return !hasOneNotChecked;
  }

  onSearchChange(event) {
    const searchValue = event.target.value;

    clearTimeout(this.searchChangeTimeout);
    this.searchChangeTimeout = setTimeout(() => {
      this.setState({ catalogSearchResultLoading: true });
      this.getSearchResults(searchValue, this.state.searchType).finally(() =>
        this.setState({ catalogSearchResultLoading: false })
      );
    }, 100);

    this.setState({
      searchValue,
      showAllSelections: false,
      checkAllItems: false
    });
  }

  onSearchTypeChange(event) {
    const searchType = event.target.value;
    const { searchValue } = this.state;

    this.getSearchResults(searchValue, searchType).finally(() => this.setState({ catalogSearchResultLoading: false }));

    this.setState({
      searchType,
      showAllSelections: false,
      catalogSearchResultLoading: true,
      checkAllItems: false
    });
  }

  onItemCheck(id, level) {
    let itemsPerLevel = [...this.state.itemsPerLevel];

    const foundIndex = itemsPerLevel[level].items.findIndex((item) => item.id === id);

    const isCheckedNewState = !itemsPerLevel[level].items[foundIndex].checked;
    itemsPerLevel[level].items[foundIndex].checked = isCheckedNewState;

    //itertate lower level and update checked status
    for (let i = level + 1; i < itemsPerLevel.length; i++) {
      const nextLevel = i;
      if (itemsPerLevel[nextLevel] && id === itemsPerLevel[level].selectedItemId) {
        itemsPerLevel[nextLevel].items.forEach((item) => {
          item.checked = isCheckedNewState;
        });
      }
    }

    //iterate higer levels and update checked status
    if (level > 0) {
      const areAllChecked = this.areAllItemsChecked(level);

      for (let i = level - 1; i >= 0; i--) {
        const selectedParentId = itemsPerLevel[i].selectedItemId;
        const foundIndex = itemsPerLevel[i].items.findIndex((item) => item.id === selectedParentId);
        itemsPerLevel[i].items[foundIndex].checked = areAllChecked;
      }
    }

    this.setState({
      itemsPerLevel
    });
  }

  async onItemClick(item, level) {
    if (isItemType(item.type)) {
      return;
    }

    const itemId = item.id;
    const nextLevel = level + 1;
    try {
      let items;

      if (item.type === CATALOG_ITEM_TYPE_ENUM.BRAND) {
        items = await catalogApi.getBrandItems(itemId);
      } else if (item.type === CATALOG_ITEM_TYPE_ENUM.CATEGORY) {
        items = await catalogApi.getCategoryItems(itemId);
      }

      if (this.isItemChecked(itemId, level)) {
        items.forEach((item) => (item.checked = true));
      }

      let itemsPerLevel = [...this.state.itemsPerLevel];

      itemsPerLevel.splice(nextLevel);
      itemsPerLevel[level].selectedItemId = itemId;
      itemsPerLevel[nextLevel] = {
        selectedItemId: "",
        items
      };

      this.setState({
        itemsPerLevel
      });
    } catch (err) {
      this.props.showErrorAlert(getMessage("catalogSelector.errorAlert"));
    }
  }

  onShowAllSelectionsChange() {
    const showAllSelections = !this.state.showAllSelections;
    const { selectedItems } = { ...this.state };

    let items = [];
    if (showAllSelections) {
      items = Object.values(selectedItems);
      items.forEach((item) => (item.checked = true));
    } else {
      items = [];
    }

    this.setState({
      itemsPerLevel: this.resetItemsPerLevel(items),
      showAllSelections,
      searchValue: "",
      noSearchResultsMessage: null,
      checkAllItems: showAllSelections
    });
  }

  doCheckAllItems(checkValue) {
    let { itemsPerLevel } = { ...this.state };
    for (let i = 0; i < itemsPerLevel.length; i++) {
      const level = i;
      if (itemsPerLevel[level]) {
        itemsPerLevel[level].items.forEach((item) => {
          item.checked = checkValue;
        });
      }
    }

    this.setState({
      itemsPerLevel
    });
  }

  onCheckAllItemsChange() {
    const checkAllItems = !this.state.checkAllItems;
    this.doCheckAllItems(checkAllItems);

    this.setState({
      checkAllItems
    });
  }

  onCancelClick() {
    this.props.onCancel && this.props.onCancel();
  }

  getAllCheckedItems() {
    const { itemsPerLevel, showAllSelections } = this.state;
    const checkedItems = {};

    for (let i = 0; i < itemsPerLevel.length; i++) {
      const items = itemsPerLevel[i].items;
      if (i === 0 || !this.areAllItemsChecked(i)) {
        items.forEach((item) => {
          if (item.checked) {
            checkedItems[item.id] = {
              id: item.id,
              name: item.name,
              level: i,
              type: item.type
            };
          }
        });
      }
    }
    return checkedItems;
  }

  onApplyAndSaveClick() {
    const { showAllSelections } = this.state;
    let { selectedItems } = { ...this.state };

    if (showAllSelections) {
      selectedItems = {};
    }

    selectedItems = Object.assign(selectedItems, this.getAllCheckedItems());

    if (!showAllSelections) {
      this.doCheckAllItems(false);
    }

    this.setState({
      selectedItems
    });

    const selectedItemsIds = this.getSelectedItemIds(selectedItems);

    this.props.onSave({
      brands: selectedItemsIds.brands,
      categories: selectedItemsIds.categories,
      items: selectedItemsIds.items
    });
  }

  isApplyDisabled() {
    const { showAllSelections } = this.state;
    const checkItems = this.getAllCheckedItems();

    if (showAllSelections) {
      return isEqual(Object.keys(checkItems), Object.keys(this.state.selectedItems));
    }
    return isEmptyObject(checkItems);
  }

  isSaveDisabled() {
    const selectedItemIds = this.getSelectedItemIds(this.state.selectedItems);

    return (
      isEqual(selectedItemIds.categories, this.props.initialCategoryIds) &&
      isEqual(selectedItemIds.items, this.props.initialItemIds)
    );
  }

  isShowAllSelectionsDisabled() {
    return isEmptyObject(this.state.selectedItems);
  }

  isCheckAllItemsDisabled() {
    return this.state.itemsPerLevel.length === 0 || this.state.itemsPerLevel[0].items.length === 0;
  }

  getCatalogItemTypeOptions() {
    return this.props.allowItemsSelection ? CATALOG_ITEM_TYPE_OPTIONS : CATALOG_ITEM_TYPE_OPTIONS_NO_PRODUCT;
  }

  renderTotalSelected() {
    const { selectedItems } = this.state;

    if (isEmptyObject(selectedItems)) {
      return null;
    }

    const selectedItemIds = this.getSelectedItemIds(selectedItems);

    return this.props.getSelectedTextFunc({
      brands: selectedItemIds.brands,
      categories: selectedItemIds.categories,
      items: selectedItemIds.items
    });
  }

  renderSelectionCheckbox(item, level) {
    const { allowItemsSelection } = this.props;

    if (!allowItemsSelection && isItemType(item.type)) {
      return;
    }

    return (
      <span>
        <CheckboxField
          renderOnlyCheckbox={true}
          className="e-checkbox-onlycheckbox"
          name={item.id}
          checked={!!item.checked}
          onChange={() => {
            this.onItemCheck(item.id, level);
          }}
          disabled={this.props.disabled}
        />
      </span>
    );
  }

  renderSingleLevelList({ items, level, selectedItemId }) {
    return items.map((item) => {
      const selectedClassName = classNames("l-catalog-item", {
        category: !isItemType(item.type),
        "l-item-selected": item.id === selectedItemId
      });
      const disabled = this.disabledItemIds[item.type].some((disabledItemId) => item.id === disabledItemId);
      const element = (
        <div key={item.id} className={selectedClassName}>
          {this.renderSelectionCheckbox(item, level)}
          <ConditionalWrapper
            condition={isItemType(item.type)}
            wrapper={(children) => <e-tooltip content={item.id}>{children}</e-tooltip>}
          >
            <label onClick={() => this.onItemClick(item, level)}>
              {item.name}
              {!isItemType(item.type) && <e-icon icon="e-caret-right" />}
            </label>
          </ConditionalWrapper>
        </div>
      );

      if (disabled) {
        return (
          <e-tooltip class="l-overlay-disabled" content={this.props.disabledItemsTooltip}>
            {element}
          </e-tooltip>
        );
      }

      return element;
    });
  }

  renderList() {
    const { itemsPerLevel } = this.state;

    return itemsPerLevel.map((elm, level) => {
      const className = classNames("l-catalog-level", "e-border-right");
      const { items, selectedItemId } = itemsPerLevel[level];
      if (items.length === 0) {
        return null;
      }

      return (
        <div key={`level${level}`} className={className}>
          {this.renderSingleLevelList({ items, level, selectedItemId })}
        </div>
      );
    });
  }

  renderFinderContent() {
    if (this.state.noSearchResultsMessage) {
      const { title, text } = this.state.noSearchResultsMessage;
      return <EmptyState icon="search" title={title} text={text} />;
    }

    return this.renderList();
  }

  render() {
    const searchPlaceholder = isItemType(this.state.searchType)
      ? getMessage("catalogSelector.searchPlaceholder.byItem")
      : getMessage("catalogSelector.searchPlaceholder.byCatOrBrand");

    let applyBtnAttr = {};
    if (this.props.disabled || this.isApplyDisabled()) {
      applyBtnAttr["disabled"] = true;
    }

    return (
      <div className="l-catalog-search">
        <div className="e-grid">
          <div className="e-cell e-cell-6">
            <InputSearchField
              name="searchValue"
              value={this.state.searchValue}
              labelClassName="no-margin"
              placeholder={searchPlaceholder}
              autoFocus={true}
              onChange={this.onSearchChange.bind(this)}
              disabled={this.props.disabled}
            />
          </div>
          <div className="e-cell e-cell-3">
            <div className="m-l-15">
              <SelectField
                name="searchType"
                options={this.getCatalogItemTypeOptions()}
                value={this.state.searchType}
                onChange={this.onSearchTypeChange.bind(this)}
                disabled={this.props.disabled}
              />
            </div>
          </div>
        </div>
        <div className="e-grid e-grid-between">
          <div className="e-cell">
            <CheckboxField
              name="checkAllItems"
              label={getMessage("catalogSelector.checkAllItems.label")}
              checked={this.state.checkAllItems}
              onChange={this.onCheckAllItemsChange.bind(this)}
              disabled={this.props.disabled || this.isCheckAllItemsDisabled()}
            />
          </div>
          <div className="e-cell">{this.renderTotalSelected()}</div>
          <div className="e-cell">
            <CheckboxField
              name="showAllSelections"
              label={getMessage("catalogSelector.showAllSelections.label")}
              checked={this.state.showAllSelections}
              onChange={this.onShowAllSelectionsChange.bind(this)}
              disabled={this.props.disabled || this.isShowAllSelectionsDisabled()}
            />
          </div>
        </div>
        <div>
          <div className="l-catalog-search-finder e-border-all">
            {this.state.catalogSearchResultLoading ? (
              <e-spinner data-size="large" data-fullscreen="true"></e-spinner>
            ) : (
              this.renderFinderContent()
            )}
          </div>
        </div>
        <div className="e-buttongroup e-buttongroup-flex m-t-25">
          <button className="e-btn e-btn" onClick={this.onCancelClick.bind(this)}>
            {getMessage("catalogSelector.cancelBtn.label")}
          </button>
          <button onClick={this.onApplyAndSaveClick.bind(this)} className="e-btn e-btn-primary" {...applyBtnAttr}>
            {getMessage("catalogSelector.saveBtn.label")}
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showErrorAlert: (message) => {
      dispatch(showErrorAlert(message));
    }
  };
};

CatalogSelector.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  initialBrandIds: PropTypes.array.isRequired,
  initialCategoryIds: PropTypes.array.isRequired,
  initialItemIds: PropTypes.array,
  disabledBrandIds: PropTypes.array,
  disabledCategoryIds: PropTypes.array,
  disabledItemsTooltip: PropTypes.string,
  getSelectedTextFunc: PropTypes.func,
  allowItemsSelection: PropTypes.bool,
  disabled: PropTypes.bool
};

export default connect(null, mapDispatchToProps)(CatalogSelector);
