import React, { Fragment } from "react";
import PropTypes from "prop-types";

import PopupContainer from "./PopupContainer";

import { isEmptyObject } from "../helpers/objectHelper";

import "../assets/styles/MultiLangPopup.scss";
import Notification from "./Notification";
import { getMessage } from "../messages";

const MultiLangPopup = ({
  id,
  title,
  show,
  onClose,
  onSave,
  element,
  languages,
  currencies,
  translations,
  onChange,
  isForCurrency,
  noticeText,
  showNoticeForDefault,
  disableSave
}) => {
  const renderList = (listMulti, propMultiKey) => {
    return listMulti.map((key, index) => {
      return (
        <Fragment key={`${id}_${key}`}>
          {" "}
          {React.cloneElement(element, { [propMultiKey]: key, translations: { ...translations }, onChange, index })}
          {index < listMulti.length - 1 && <hr className="e-separator" />}
        </Fragment>
      );
    });
  };

  const renderContent = () => {
    if (isEmptyObject(translations)) {
      return null;
    }
    const list = isForCurrency ? renderList(currencies, "currency") : renderList(languages, "lang");
    const defaultNoticeText = showNoticeForDefault ? getMessage("multiLangPopup.defaultNoticeText") : "";
    noticeText = noticeText || defaultNoticeText;

    return (
      <Fragment>
        <Notification content={noticeText} />
        {list}
      </Fragment>
    );
  };

  if (!title) {
    title = isForCurrency
      ? getMessage("multiLangPopup.defaultTitle.currency")
      : getMessage("multiLangPopup.defaultTitle.language");
  }

  return (
    <PopupContainer
      id={id}
      className="multi-lang-popup"
      width="840px"
      title={title}
      show={show}
      onClose={onClose}
      buttons={[
        {
          text: getMessage("cancelBtn.label"),
          isClose: true
        },
        {
          text: getMessage("saveBtn.label"),
          className: "e-btn-secondary",
          onClick: onSave,
          disabled: disableSave
        }
      ]}
    >
      {renderContent()}
    </PopupContainer>
  );
};

MultiLangPopup.propTypes = {
  id: PropTypes.string,
  show: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  element: PropTypes.element,
  translations: PropTypes.object,
  languages: PropTypes.array,
  noticeText: PropTypes.string,
  showNoticeForDefault: PropTypes.bool,
  disableSave: PropTypes.bool
};

export default MultiLangPopup;
