import React from "react";
import PropTypes from "prop-types";
//import { useSelector } from "react-redux";

import PopupContainer from "./PopupContainer";
import { getMessage } from "../messages";
//import { selectNavigationConfirmMessage } from "../reducers";

const ConfirmNavDialog = (props) => {
  const { onConfirm, onCancel, onClose, message } = props;
  let alertMessage = "";
  if (message === undefined || message.length === 0) alertMessage = getMessage("confirmNavDialog.discardChanges.text");
  //useSelector((state) => selectNavigationConfirmMessage(state));
  else alertMessage = message;
  return (
    <PopupContainer
      id="popupConfirmNavigation"
      title="Confirm navigation"
      openOnLoad={true}
      buttons={[
        {
          text: getMessage("confirmNavDialog.cancelBtn.label"),
          isClose: true,
          onClick: onCancel
        },
        {
          text: getMessage("confirmNavDialog.approveBtn.label"),
          isClose: true,
          onClick: onConfirm
        }
      ]}
      onClose={onClose}
    >
      <div>{alertMessage}</div>
    </PopupContainer>
  );
};

ConfirmNavDialog.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func
};

export default ConfirmNavDialog;
