import validate from "validate.js";
import { getMessage } from "../messages";

export default class externalEventsValidator {
  constructor() {
    this.constraints = {
      name: {
        presence: {
          allowEmpty: false,
          message: getMessage("externalEventsValidator.name.presence")
        }
      }
    };
  }

  validateExternalEvent = (externalEvent) => {
    return validate(externalEvent, this.constraints);
  };
}
