import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import deepEqual from "deep-equal";
import classNames from "classnames";

import planValidator from "../validators/planValidator";
import PlanSettingsGeneral from "../views/PlanSettings/PlanSettingsGeneral";
import PreLaunchPopup from "../components/PreLaunchPopup";
import MultiLangPlanSettingsFields from "../views/PlanSettings/MultiLangPlanSettingsFields";

import Notification from "../components/Notification";
import MultiLangPopup from "../components/MultiLangPopup";

import { convertPathStrName, validateSingleValue } from "../validators";

import { getInputNameAndValue, updateStateOnInputChange } from "../helpers/formHelper";
import { multiLangUpdateState, initTranslationsObj, setTranslationsOnInputChange } from "../helpers/multiLangHelper";
import { filterObjectByKeys } from "../helpers/objectHelper";

import { launchPlan, updatePlan } from "../actions/planActions";
import { showErrorAlert, hideErrorAlert } from "../actions/alertActions";
import { setPlanLaunchInProgress } from "../actions/currentPlanVersionActions";
import { getMessage } from "../messages";
import planService from "../services/planService";

import {
  selectPlan,
  selectIsBeforeFirstPlanLaunch,
  selectPlanLanguages,
  selectPlanPrimaryLanguage,
  selectPlanHasMultiLang,
  selectShowCurrentPlan,
  selectCurrentPlanVersion,
  isAccountViewOnly
} from "../reducers";

import { updateObjectByNameAndValue } from "../helpers/objectHelper";

import {
  MULTI_LANG_PROPS_MAP,
  TIERS_CALCULATION_TYPE_ENUM,
  STATUS_POINTS_EXPIRATION_TYPE_ENUM,
  PLAN_TYPE_ENUM
} from "../constants/planConstants";

import PlanSettingsCalculationSettings from "../views/PlanSettings/PlanSettingsCalculationSettings";
import { PLAN_TYPE_OPTIONS } from "../constants/planConstants";
import { PLAN_SETTING_HELP_LINK } from "../constants/linkConstants";

class PlanSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      plan: cloneDeep(props.plan),
      errors: {},
      showPreLaunchPopup: false,
      showMultiLangPopup: false,
      translations: {},
      translationErrors: undefined
    };

    this.validator = new planValidator(props.languages);
  }

  componentDidMount() {
    this.props.registerSubmitFunc && this.props.registerSubmitFunc(this.onSubmit.bind(this));

    this.props.registerValidateFunc && this.props.registerValidateFunc(this.validateStep.bind(this));
  }

  get viewMode() {
    return (this.props.showCurrentVersion && !this.props.fromOnboarding) || this.props.isAccountViewOnly;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showCurrentVersion != this.props.showCurrentVersion || !deepEqual(prevProps.plan, this.props.plan)) {
      this.setState({
        plan: this.props.plan
      });
    }
  }

  componentWillUnmount() {
    this.props.registerSubmitFunc && this.props.registerSubmitFunc(null);
    this.props.registerValidateFunc && this.props.registerValidateFunc(null);
    if (this.pullInterval) {
      clearInterval(this.pullInterval);
    }
  }

  validate(plan) {
    return this.validator.validatePlanSettings(plan, this.showHistoricalBalancePoints(plan));
  }

  showHistoricalBalancePoints(plan) {
    const { currentPlan, isBeforeFirstPlanLaunch } = { ...this.props };

    return (
      !isBeforeFirstPlanLaunch &&
      currentPlan.planType === PLAN_TYPE_ENUM.POINTS_AND_BENEFITS &&
      plan.planType === PLAN_TYPE_ENUM.POINTS
    );
  }

  onInputChange(event, customValue) {
    const plan = { ...this.state.plan };
    const inputName = updateStateOnInputChange({
      stateObj: plan,
      event,
      customValue,
      hasMultiLanguage: this.props.hasMultiLanguage,
      primaryLanguage: this.props.primaryLanguage,
      multiLangPropsMap: MULTI_LANG_PROPS_MAP
    });

    let errors = validateSingleValue(() => this.validate(plan), this.state.errors, inputName);

    if (inputName === "rules.balancePointsExpiration.duration" || inputName === "rules.balancePointsExpiration.type") {
      errors = validateSingleValue(() => this.validate(plan), errors, "rules.pointsAboutToExpire.duration");
    }

    if (!errors) {
      this.props.hideErrorAlert();
    }

    this.setState({
      plan,
      errors
    });

    return plan;
  }

  onPlanTypeChange(event) {
    const plan = this.onInputChange(event);

    if (plan.planType === PLAN_TYPE_ENUM.POINTS_AND_BENEFITS) {
      plan.planSettings.rules.balancePointsExpiration.disabled = true;
      this.setState({ plan });
    }
  }

  onPointsNameChange(event) {
    const plan = this.onInputChange(event);

    if (planService.isSpendPlan(plan)) {
      const inputName = updateStateOnInputChange({
        stateObj: plan,
        event,
        hasMultiLanguage: this.props.hasMultiLanguage,
        primaryLanguage: this.props.primaryLanguage,
        multiLangPropsMap: MULTI_LANG_PROPS_MAP,
        customName: "plan.planSettings.singlePointName"
      });

      //plan.planSettings.singlePointName = plan.planSettings.pointsName;
      this.setState({ plan });
    }
  }

  onTiersCalculationChange(event) {
    let plan = this.onInputChange(event);

    const { tiersCalculationType, statusPointsExpirationType } = { ...plan.planSettings.rules };

    if (
      (tiersCalculationType !== TIERS_CALCULATION_TYPE_ENUM.NO_DROPOUT &&
        statusPointsExpirationType === STATUS_POINTS_EXPIRATION_TYPE_ENUM.NEVER) ||
      (tiersCalculationType === TIERS_CALCULATION_TYPE_ENUM.NO_DROPOUT &&
        statusPointsExpirationType === STATUS_POINTS_EXPIRATION_TYPE_ENUM.TIERS_CALC)
    ) {
      plan.planSettings.rules.statusPointsExpirationType = "";
      this.setState({ plan });
    }

    if (tiersCalculationType === TIERS_CALCULATION_TYPE_ENUM.END_OF_YEAR && planService.isSpendPlan(plan)) {
      plan.planSettings.rules.statusPointsExpirationType = STATUS_POINTS_EXPIRATION_TYPE_ENUM.TIERS_CALC;
      this.setState({ plan });
    }
  }

  onLangInputChange(event, lang) {
    const translations = { ...this.state.translations };
    setTranslationsOnInputChange({ translations, event, lang });

    const { name } = getInputNameAndValue(event);
    const nameToValidate = `${convertPathStrName(name)}.${lang}`;

    const translationErrors = validateSingleValue(
      () => this.validator.validateMultiLangPopup(this.state.plan, translations),
      this.state.translationErrors,
      nameToValidate
    );

    this.setState({
      translations,
      translationErrors
    });
  }

  onMultiLangPopupSave() {
    const plan = { ...this.state.plan };
    const translations = { ...this.state.translations };
    if (planService.isSpendPlan(this.state.plan)) {
      translations["planSettings.singlePointNameML"] = { ...translations["planSettings.pointsNameML"] };
    }

    const translationErrors = this.validator.validateMultiLangPopup(this.state.plan, translations);

    if (!translationErrors) {
      multiLangUpdateState({
        stateObj: plan,
        translations,
        propsMap: MULTI_LANG_PROPS_MAP,
        primaryLanguage: this.props.primaryLanguage
      });

      this.setState({
        plan,
        showMultiLangPopup: false
      });
    }

    this.setState({
      translationErrors
    });
  }

  onShowPlanSettingsMultiLang() {
    const plan = { ...this.state.plan };
    const propsMap = filterObjectByKeys(MULTI_LANG_PROPS_MAP, [
      "planSettings.nameML",
      "planSettings.pointsNameML",
      "planSettings.singlePointNameML"
    ]);
    const translations = initTranslationsObj({
      stateObj: plan,
      propsMap,
      primaryLanguage: this.props.primaryLanguage
    });

    this.setState({
      translations,
      showMultiLangPopup: true,
      translationErrors: undefined,
      errors: undefined
    });
  }

  updateState(propertyPath, value) {
    const plan = { ...this.state.plan };
    updateObjectByNameAndValue(plan, propertyPath, value);

    const errors = validateSingleValue(() => this.validate(plan), this.state.errors, propertyPath);

    if (!errors) {
      this.props.hideErrorAlert();
    }

    this.setState({
      errors
    });
  }

  validateStep() {
    const { plan } = this.state;
    const errors = this.validate(plan);

    this.setState({
      errors
    });

    if (errors) {
      this.props.showErrorAlert(getMessage("form.missingSettingsAlert"));
    }

    return errors === undefined;
  }

  onSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    const { plan } = this.state;

    const errors = this.validate(plan);

    if (errors === undefined) {
      this.props.updatePlan({
        id: plan.id,
        data: plan,
        disableAlert: this.props.fromOnboarding
      });
    } else {
      this.props.showErrorAlert(getMessage("form.missingSettingsAlert"));
    }

    this.setState({
      errors
    });
  }

  onCancel() {
    this.setState({
      plan: this.props.plan
    });
  }

  toggleShowPreLaunchPopup() {
    const errors = this.validate(this.state.plan);

    if (errors === undefined) {
      this.setState({
        showPreLaunchPopup: !this.state.showPreLaunchPopup
      });
    } else {
      this.props.showErrorAlert(getMessage("form.missingSettingsAlert"));
    }
  }

  disablePlanType(planData) {
    var options = [...PLAN_TYPE_OPTIONS];
    if (!planService.isBenefitsOnly(planData)) {
      var result = options.map((element) => {
        let target = { ...element };
        target.disabled = true;
        return target;
      });
      options = [...result];
    } else {
      var result = options.map((element) => {
        let target = { ...element };
        if (element.value === PLAN_TYPE_ENUM.SPEND) {
          target.disabled = true;
        } else {
          target.disabled = false;
        }
        return target;
      });
      options = [...result];
    }
    return options;
  }

  renderFooterButtons() {
    return (
      <div className="e-buttongroup">
        <button className="e-btn" onClick={this.onCancel.bind(this)}>
          {getMessage("cancelBtn.label")}
        </button>
        <button
          className="e-btn e-btn-primary"
          type="submit"
          onClick={this.onSubmit.bind(this)}
          disabled={this.viewMode}
        >
          {getMessage("saveBtn.label")}
        </button>
      </div>
    );
  }

  render() {
    const { plan, errors } = this.state;
    const { fromOnboarding } = this.props;

    const generalSectionClassNames = classNames("e-col-6", {
      "m-b-30": !fromOnboarding
    });

    return (
      <div className="box-padding">
        <PreLaunchPopup
          showPreLaunchPopup={this.state.showPreLaunchPopup}
          toggleShowPreLaunchPopup={this.toggleShowPreLaunchPopup.bind(this)}
          launchPlanFunc={this.props.launchPlan}
        />
        <Notification
          content={getMessage("planSettings.notification", {
            learnMoreLink: (
              <a target={"_blank"} href={PLAN_SETTING_HELP_LINK}>
                {getMessage("planSettings.notification.learnMoreLinkText")}
              </a>
            )
          })}
        />
        <div className="e-row">
          <div className={generalSectionClassNames}>
            <PlanSettingsGeneral
              plan={plan}
              onInputChange={this.onInputChange.bind(this)}
              disabled={this.viewMode}
              onShowPlanSettingsMultiLang={this.onShowPlanSettingsMultiLang.bind(this)}
              hasMultiLanguage={this.props.hasMultiLanguage}
              onPlanTypeChange={this.onPlanTypeChange.bind(this)}
              errors={errors}
              planTypeOptions={this.disablePlanType(plan)}
              onPointsNameChange={this.onPointsNameChange.bind(this)}
            />
          </div>

          <div className="e-col-6">
            <PlanSettingsCalculationSettings
              plan={plan}
              onInputChange={this.onInputChange.bind(this)}
              fromOnboarding={fromOnboarding}
              errors={errors}
              updateState={this.updateState.bind(this)}
              disabled={this.viewMode}
              showPointsAboutToExpire={!plan.planSettings.rules.balancePointsExpiration.disabled}
              onTiersCalculationChange={this.onTiersCalculationChange.bind(this)}
              isBeforeFirstPlanLaunch={this.props.isBeforeFirstPlanLaunch}
              showHistoricalBalancePoints={this.showHistoricalBalancePoints(plan)}
            />
          </div>

          <div className="e-col e-col__separator" />
        </div>

        {fromOnboarding ? null : (
          <Fragment>
            <hr className="e-separator e-separator-fullwidth" />
            {this.renderFooterButtons()}
          </Fragment>
        )}
        <MultiLangPopup
          id={"plan_settings_multi_lang"}
          show={this.state.showMultiLangPopup}
          languages={this.props.languages}
          translations={this.state.translations}
          element={
            <MultiLangPlanSettingsFields
              disabled={this.viewMode}
              plan={this.state.plan}
              errors={this.state.translationErrors}
            />
          }
          showNoticeForDefault={true}
          onChange={this.onLangInputChange.bind(this)}
          onSave={this.onMultiLangPopupSave.bind(this)}
          onClose={() => {
            this.setState({
              showMultiLangPopup: false
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const isBeforeFirstPlanLaunch = selectIsBeforeFirstPlanLaunch(state);
  const showCurrentVersion = selectShowCurrentPlan(state);
  const nextPlan = selectPlan(state);
  const currentPlan = selectCurrentPlanVersion(state);
  return {
    showCurrentVersion,
    currentPlan,
    plan: showCurrentVersion ? currentPlan : nextPlan,
    isBeforeFirstPlanLaunch,
    languages: selectPlanLanguages(state),
    primaryLanguage: selectPlanPrimaryLanguage(state),
    hasMultiLanguage: selectPlanHasMultiLang(state),
    isAccountViewOnly: isAccountViewOnly(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  updatePlan: ({ id, data, disableAlert }) => {
    dispatch(updatePlan({ id, data, disableAlert }));
  },
  launchPlan: () => {
    dispatch(launchPlan());
  },
  showErrorAlert: (message) => {
    dispatch(showErrorAlert(message, undefined, true));
  },
  hideErrorAlert: () => {
    dispatch(hideErrorAlert());
  },
  setPlanLaunchInProgress: (isInProgress) => {
    dispatch(setPlanLaunchInProgress(isInProgress));
  }
});

PlanSettings.propTypes = {
  registerSubmitFunc: PropTypes.func,
  registerValidateFunc: PropTypes.func,
  fromOnboarding: PropTypes.bool,
  showCurrentVersion: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanSettings);
