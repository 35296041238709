import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const CheckboxField = (props) => {
  let attr = [];
  if (props.disabled) {
    attr["disabled"] = true;
  }

  const renderInput = () => {
    const inputClassName = classNames("e-checkbox", props.className, {
      "e-input-error": !!props.errorMsg,
      "e-checkbox-onlycheckbox": props.onlyCheckbox
    });
    const id = props.id || props.name;
    return (
      <Fragment>
        <input
          className={inputClassName}
          type="checkbox"
          id={id}
          name={props.name}
          checked={props.checked}
          onChange={props.onChange}
          {...attr}
        />
        <label htmlFor={id} className={props.labelClassName}>
          {props.label}
          {props.tooltip ? <e-tooltip content={props.tooltip} type="helper" /> : null}
        </label>
      </Fragment>
    );
  };

  if (props.renderOnlyCheckbox) {
    return renderInput();
  }

  const fieldClassName = classNames("e-field", { "e-field-inline": props.inline, "no-margin": props.noMargin });
  return <div className={fieldClassName}>{renderInput()}</div>;
};

CheckboxField.propTypes = {
  renderOnlyCheckbox: PropTypes.bool,
  label: PropTypes.string,
  checked: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  noMargin: PropTypes.bool,
  onlyCheckbox: PropTypes.bool,
  errorMsg: PropTypes.string
};

export default CheckboxField;
