import objectPath from "object-path";

// export const getObjectByPath = (path, object) => {
//   return path.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), object);
// };

export const updateObjectByNameAndValue = (obj, path, value) => {
  // if (name.indexOf(".") == -1) {
  //   obj[name] = value;
  // } else {
  //   const splitName = name.split(".");
  //   const lastProp = splitName[splitName.length - 1];
  //   splitName.splice(-1, 1);
  //   let lastObject = getObjectByPath(splitName, obj);
  //   lastObject[lastProp] = value;
  // }
  objectPath.set(obj, path, value);
};

export const getObjectByNamePath = (obj, path) => {
  // if (name.indexOf(".") == -1) {
  //   return obj[name];
  // } else {
  //   const splitName = name.split(".");
  //   const lastProp = splitName[splitName.length - 1];
  //   splitName.splice(-1, 1);
  //   let lastObject = getObjectByPath(splitName, obj);
  //   if (!lastObject) {
  //     return null;
  //   }
  //   return lastObject[lastProp];
  // }
  return objectPath.get(obj, path);
};

export const arrayToObject = (array) =>
  array.reduce((obj, item) => {
    obj[item.id] = item;
    return obj;
  }, {});

export const isEmptyObject = (obj) => {
  if (obj === undefined) {
    return true;
  }
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const distinctArray = (array) => array.filter((value, index, self) => self.indexOf(value) === index);

export const filterObjectByKeys = (obj, allowedKeys) => {
  return Object.keys(obj)
    .filter((key) => allowedKeys.includes(key))
    .reduce((result, key) => {
      result[key] = obj[key];
      return result;
    }, {});
};

export const flipObject = (obj) => {
  const ret = {};
  Object.keys(obj).forEach((key) => {
    ret[obj[key]] = key;
  });
  return ret;
};
