import React from "react";
import PropTypes from "prop-types";
import { BLOCKING_EDITOR_MODE_ENUM, EDITOR_TYPE_ENUM } from "../../constants/rewardConstants";
import { getMessage } from "../../messages";

const MemberModeTabs = (props) => {
  const { mode } = props;
  const changeMemberMode = (newValue) => {
    // if ((newValue && props.isMember) || (!newValue && !props.isMember)) {
    //     return;
    // }

    props.onChange(newValue);
  };

  return (
    <e-verticalnav-menu id="verticalnav_custom_html" no-toggle="no-toggle">
      {props.editorType === EDITOR_TYPE_ENUM.TEMPLATE && (
        <e-verticalnav-item
          icon="users"
          value={getMessage("memberModTabs.template.value")}
          status={
            mode === BLOCKING_EDITOR_MODE_ENUM.GENERAL || mode === BLOCKING_EDITOR_MODE_ENUM.GENERAL_MOBILE
              ? "active"
              : ""
          }
          onClick={() => changeMemberMode(BLOCKING_EDITOR_MODE_ENUM.GENERAL)}
        />
      )}
      <e-verticalnav-item
        icon="user-verify"
        value={getMessage("memberModTabs.member.value")}
        status={
          mode === BLOCKING_EDITOR_MODE_ENUM.MEMBER || mode === BLOCKING_EDITOR_MODE_ENUM.MEMBER_MOBILE ? "active" : ""
        }
        onClick={() => changeMemberMode(BLOCKING_EDITOR_MODE_ENUM.MEMBER)}
      />
      <e-verticalnav-item
        icon="user-plus"
        value={getMessage("memberModTabs.nonMember.value")}
        status={
          mode === BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER || mode === BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER_MOBILE
            ? "active"
            : ""
        }
        onClick={() => changeMemberMode(BLOCKING_EDITOR_MODE_ENUM.NON_MEMBER)}
      />
    </e-verticalnav-menu>
  );
};

MemberModeTabs.propTypes = {
  editorType: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default MemberModeTabs;
