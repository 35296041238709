import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import MultiPlanSelect from "../components/MultiPlanSelect";

import DashboardView from "../views/Dashboard/DashboardView";

import "../assets/styles/Dashboard.scss";

import { selectCustomerDashboardUrl } from "../reducers";
import { getMessage } from "../messages";

class Dashboard extends Component {
  renderContent() {
    if (!!this.props.dashboardUrl) {
      return (
        <section className="e-layout__section box-padding">
          <div className="l-dashboard-iframe-wrapper">
            <iframe src={this.props.dashboardUrl} width="100%" height="100%" />
          </div>
        </section>
      );
    }

    return <DashboardView />;
  }

  render() {
    return (
      <Fragment>
        <header className="e-layout__header">
          <h1 className="e-layout__title">{getMessage("dashboard.title")}</h1>
          <div className="e-layout__actions">
            <MultiPlanSelect />
          </div>
        </header>
        <main className="e-layout__content">
          <section className="e-layout__section e-layout__section-transparent">{this.renderContent()}</section>
        </main>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboardUrl: selectCustomerDashboardUrl(state)
});

Dashboard.propTypes = {};

export default connect(mapStateToProps, null)(Dashboard);
