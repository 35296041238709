import { Fragment } from "react";
import { VALIDITY_TYPE_ENUM } from "../../constants/rewardConstants";
import SelectField from "../../components/SelectFieldV2";
import TimeValidityInput from "../../components/TimeValidityInput";
import { DateTimeRangeField } from "../../components/DateFields";
import { getErrorMessage } from "../../validators";
import { getMessage } from "../../messages";

const ValidityPeriodSetup = (props) => {
  const {
    dataObject,
    fieldNamePrefix,
    mainLabel,
    mainTooltip,
    validityDurationTooltip,
    validityTypeOptions,
    onValidityTypeChange,
    updateState,
    onInputChange,
    disabled,
    errors
  } = props;

  return (
    <Fragment>
      <SelectField
        label={mainLabel}
        name={`validity.accessValidity.${fieldNamePrefix}.accessValidityType`}
        value={dataObject.validity.accessValidity[fieldNamePrefix].accessValidityType}
        options={validityTypeOptions}
        onChange={onValidityTypeChange}
        tooltip={mainTooltip}
        disabled={disabled}
        placeholder={getMessage("validityPeriodSetup.accessValidityType.placeholder")}
        errorMsg={getErrorMessage(errors, `validity.accessValidity.${fieldNamePrefix}.accessValidityType`)}
      />

      {dataObject.validity.accessValidity[fieldNamePrefix].accessValidityType ===
        VALIDITY_TYPE_ENUM.LIMITED_FROM_EARN && (
        <TimeValidityInput
          label={getMessage("validityPeriodSetup.accessValidity.duration.label")}
          name={`validity.accessValidity.${fieldNamePrefix}.accessValidity`}
          validity={dataObject.validity.accessValidity[fieldNamePrefix].accessValidity}
          onChange={onInputChange}
          changeStateFunc={updateState}
          errorMsg={getErrorMessage(errors, `validity.accessValidity.${fieldNamePrefix}.accessValidity.duration`)}
          tooltip={validityDurationTooltip}
          disabled={disabled}
        />
      )}

      {dataObject.validity.accessValidity[fieldNamePrefix].accessValidityType === VALIDITY_TYPE_ENUM.DATE_RANGE && (
        <DateTimeRangeField
          label={getMessage("validityPeriodSetup.accessValidity.duration.label")}
          name={`validity.accessValidity.${fieldNamePrefix}.accessDateRange`}
          value={dataObject.validity.accessValidity[fieldNamePrefix].accessDateRange}
          minDate={new Date()}
          onChange={onInputChange}
          errorMsg={getErrorMessage(errors, `validity.accessValidity.${fieldNamePrefix}.accessDateRange`)}
          disabled={disabled}
        />
      )}
    </Fragment>
  );
};

export default ValidityPeriodSetup;
