import React, { Fragment } from "react";
import PropTypes from "prop-types";

import InputNumberField from "../../components/InputNumberField";
import SelectField from "../../components/SelectFieldV2";
import TimeValidityInput from "../../components/TimeValidityInput";
import RadioButtonList from "../../components/RadioButtonList";

import {
  TIERS_CALCULATION_TYPE_OPTIONS,
  TIERS_CALCULATION_TYPE_OPTIONS_SPEND,
  STATUS_POINTS_EXPIRATION_TYPE_OPTIONS,
  STATUS_POINTS_EXPIRATION_TYPE_ENUM,
  TIERS_CALCULATION_TYPE_ENUM,
  HISTORICAL_BALANCE_POINTS_OPTIONS,
  LOYALTY_CREDIT_EXPIRATION_TYPE_OPTIONS
} from "../../constants/planConstants";

import { getErrorMessage } from "../../validators";

import { getEmarsysSupportAnchor } from "../../helpers/envHelper";
import { pointNameText, titleCase } from "../../helpers/pointsHelper";
import planService from "../../services/planService";
import { getMessage } from "../../messages";

const PlanSettingsCalculationSettings = ({
  plan,
  onInputChange,
  updateState,
  errors,
  disabled,
  isBeforeFirstPlanLaunch,
  showPointsAboutToExpire,
  onTiersCalculationChange,
  showHistoricalBalancePoints
}) => {
  const getStatusPointsExpOptions = () => {
    const tiersCalculationType = plan.planSettings.rules.tiersCalculationType;
    if (planService.isSpendPlan(plan)) {
      return LOYALTY_CREDIT_EXPIRATION_TYPE_OPTIONS;
    } else
      return STATUS_POINTS_EXPIRATION_TYPE_OPTIONS.filter((option) => {
        if (tiersCalculationType === TIERS_CALCULATION_TYPE_ENUM.NO_DROPOUT) {
          return (
            option.value === STATUS_POINTS_EXPIRATION_TYPE_ENUM.FROM_EARN ||
            option.value === STATUS_POINTS_EXPIRATION_TYPE_ENUM.NEVER
          );
        }

        return (
          option.value === STATUS_POINTS_EXPIRATION_TYPE_ENUM.FROM_EARN ||
          option.value === STATUS_POINTS_EXPIRATION_TYPE_ENUM.TIERS_CALC
        );
      });
  };

  const printStatus = () => {
    if (!planService.isSpendPlan(plan)) return getMessage("planSettingsCalculationSettings.status");
    return "";
  };

  const getStatusPointsExpirationTypeLabel = () => {
    if (planService.isSpendPlan(plan))
      return getMessage("planSettingsCalculationSettings.statusPointsExpirationType.label.spend");
    return getMessage("planSettingsCalculationSettings.statusPointsExpirationType.label.points");
  };

  const getStatusPointsExpirationTypeTooltip = (params) => {
    if (planService.isSpendPlan(plan))
      return getMessage("planSettingsCalculationSettings.statusPointsExpirationType.tooltip.spend", params);
    return getMessage("planSettingsCalculationSettings.statusPointsExpirationType.tooltip.points", params);
  };

  const getStatusPointsValidityDurationLabel = () => {
    if (planService.isSpendPlan(plan))
      return getMessage("planSettingsCalculationSettings.statusPointsValidity.duration.label.spend");
    return getMessage("planSettingsCalculationSettings.statusPointsValidity.duration.label.points");
  };

  const getStatusPointsValidityDurationTooltip = () => {
    if (planService.isSpendPlan(plan))
      return getMessage("planSettingsCalculationSettings.statusPointsValidity.duration.tooltip.spend");
    return getMessage("planSettingsCalculationSettings.statusPointsValidity.duration.tooltip.points");
  };

  const getStartYearOptions = () => {
    let year = new Date().getFullYear();
    const value = plan.planSettings.rules.tiersCalculationStartYear;

    let options = [];
    for (let i = 0; i < 5; i++) {
      options.push(year++);
    }

    //if there's already a selected value and it is not in the options, push it in the first place
    if (value && !options.includes(value)) {
      options.unshift(value);
    }

    return options;
  };
  return (
    <Fragment>
      <h2>{getMessage("planSettingsCalculationSettings.title")}</h2>
      <RadioButtonList
        label={getMessage("planSettingsCalculationSettings.tiersCalculationType.label")}
        name="planSettings.rules.tiersCalculationType"
        value={plan.planSettings.rules.tiersCalculationType}
        options={!planService.isSpendPlan(plan) ? TIERS_CALCULATION_TYPE_OPTIONS : TIERS_CALCULATION_TYPE_OPTIONS_SPEND}
        onChange={onTiersCalculationChange}
        tooltip={
          !planService.isSpendPlan(plan)
            ? getMessage("planSettingsCalculationSettings.tiersCalculationType.tooltip.points", {
                linkToCustomerSupport: getEmarsysSupportAnchor("Emarsys Support.")
              })
            : getMessage("planSettingsCalculationSettings.tiersCalculationType.tooltip.spend", {
                linkToCustomerSupport: getEmarsysSupportAnchor("Emarsys Support.")
              })
        }
        tooltipHasAction={true}
        disabled={disabled || !isBeforeFirstPlanLaunch}
      />

      {plan.planSettings.rules.tiersCalculationType === TIERS_CALCULATION_TYPE_ENUM.END_OF_YEAR && (
        <Fragment>
          <InputNumberField
            label={getMessage("planSettingsCalculationSettings.tiersCalculationYearlyInterval.label")}
            tooltip={getMessage("planSettingsCalculationSettings.tiersCalculationYearlyInterval.tooltip", {
              linkToCustomerSupport: getEmarsysSupportAnchor("Emarsys Support.")
            })}
            tooltipHasAction={true}
            name="planSettings.rules.tiersCalculationYearlyInterval"
            value={plan.planSettings.rules.tiersCalculationYearlyInterval}
            onChange={onInputChange}
            postfixLabel={getMessage("planSettingsCalculationSettings.tiersCalculationYearlyInterval.prefix")}
            errorMsg={getErrorMessage(errors, "planSettings.rules.tiersCalculationYearlyInterval")}
            labelClassName="no-margin"
            disabled={disabled || !isBeforeFirstPlanLaunch}
          />

          <SelectField
            label={getMessage("planSettingsCalculationSettings.tiersCalculationStartYear.label")}
            name="planSettings.rules.tiersCalculationStartYear"
            value={plan.planSettings.rules.tiersCalculationStartYear}
            isNumber={true}
            onChange={onInputChange}
            options={getStartYearOptions()}
            disabled={disabled || !isBeforeFirstPlanLaunch}
          />
        </Fragment>
      )}

      {plan.planSettings.rules.tiersCalculationType === TIERS_CALCULATION_TYPE_ENUM.AFTER_JOIN && (
        <InputNumberField
          label={getMessage("planSettingsCalculationSettings.tiersCalculationMonthlyInterval.label")}
          tooltip={getMessage("planSettingsCalculationSettings.tiersCalculationMonthlyInterval.tooltip", {
            linkToCustomerSupport: getEmarsysSupportAnchor("Emarsys Support.")
          })}
          tooltipHasAction={true}
          name="planSettings.rules.tiersCalculationMonthlyInterval"
          value={plan.planSettings.rules.tiersCalculationMonthlyInterval}
          onChange={onInputChange}
          postfixLabel="months"
          errorMsg={getErrorMessage(errors, "planSettings.rules.tiersCalculationMonthlyInterval")}
          labelClassName="no-margin"
          disabled={disabled || !isBeforeFirstPlanLaunch}
        />
      )}
      {(!planService.isSpendPlan(plan) ||
        plan.planSettings.rules.tiersCalculationType != TIERS_CALCULATION_TYPE_ENUM.END_OF_YEAR) && (
        <RadioButtonList
          label={getStatusPointsExpirationTypeLabel()}
          name="planSettings.rules.statusPointsExpirationType"
          value={plan.planSettings.rules.statusPointsExpirationType}
          options={getStatusPointsExpOptions()}
          onChange={onInputChange}
          tooltip={getStatusPointsExpirationTypeTooltip({
            linkToCustomerSupport: getEmarsysSupportAnchor("Emarsys Support.")
          })}
          tooltipHasAction={true}
          errorMsg={getErrorMessage(errors, "planSettings.rules.statusPointsExpirationType")}
          disabled={disabled || !isBeforeFirstPlanLaunch}
        />
      )}

      {(!planService.isSpendPlan(plan) ||
        plan.planSettings.rules.tiersCalculationType != TIERS_CALCULATION_TYPE_ENUM.END_OF_YEAR) &&
        plan.planSettings.rules.statusPointsExpirationType === STATUS_POINTS_EXPIRATION_TYPE_ENUM.FROM_EARN && (
          <InputNumberField
            label={getStatusPointsValidityDurationLabel()}
            tooltip={getStatusPointsValidityDurationTooltip()}
            name="planSettings.rules.statusPointsValidity.duration"
            value={plan.planSettings.rules.statusPointsValidity.duration}
            onChange={onInputChange}
            postfixLabel={getMessage("planSettingsCalculationSettings.statusPointsValidity.duration.prefix")}
            errorMsg={getErrorMessage(errors, "planSettings.rules.statusPointsValidity.duration")}
            labelClassName="no-margin"
            disabled={disabled}
          />
        )}

      {showHistoricalBalancePoints && (
        <RadioButtonList
          label={getMessage("planSettingsCalculationSettings.historicalBalancePoints.label")}
          name="planSettings.rules.historicalBalancePoints"
          value={plan.planSettings.rules.historicalBalancePoints}
          options={HISTORICAL_BALANCE_POINTS_OPTIONS}
          onChange={onInputChange}
          tooltip={getMessage("planSettingsCalculationSettings.historicalBalancePoints.tooltip")}
          errorMsg={getErrorMessage(errors, "planSettings.rules.historicalBalancePoints")}
          disabled={disabled}
        />
      )}

      {planService.hasRedemption(plan) && (
        <TimeValidityInput
          label={getMessage("planSettingsCalculationSettings.balancePointsExpiration.label")}
          tooltip={getMessage("planSettingsCalculationSettings.balancePointsExpiration.tooltip")}
          name="planSettings.rules.balancePointsExpiration"
          validity={plan.planSettings.rules.balancePointsExpiration}
          withDisableCheckbox={true}
          onChange={onInputChange}
          changeStateFunc={updateState}
          errorMsg={getErrorMessage(errors, "planSettings.rules.balancePointsExpiration.duration")}
          disabled={disabled}
        />
      )}

      {showPointsAboutToExpire && (
        <InputNumberField
          label={getMessage("planSettingsCalculationSettings.pointsAboutToExpire.duration.label")}
          tooltip={getMessage("planSettingsCalculationSettings.pointsAboutToExpire.duration.tooltip")}
          placeholder=""
          name="planSettings.rules.pointsAboutToExpire.duration"
          value={plan.planSettings.rules.pointsAboutToExpire.duration}
          onChange={onInputChange}
          postfixLabel={getMessage("planSettingsCalculationSettings.pointsAboutToExpire.duration.postfix")}
          errorMsg={getErrorMessage(errors, "planSettings.rules.pointsAboutToExpire.duration")}
          labelClassName="no-margin"
          disabled={disabled}
        />
      )}
    </Fragment>
  );
};

PlanSettingsCalculationSettings.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
  isBeforeFirstPlanLaunch: PropTypes.bool,
  showPointsAboutToExpire: PropTypes.bool,
  onTiersCalculationChange: PropTypes.func,
  updateState: PropTypes.func,
  showHistoricalBalancePoints: PropTypes.bool
};

export default PlanSettingsCalculationSettings;
