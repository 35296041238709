import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { convertValueToBool } from "../helpers/formHelper";
import { getMessage } from "../messages";
import ConditionalWrapper from "./ConditionalWrapper";
import "../assets/styles/SelectField.scss";

class SelectField extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const self = this;
    this.selectElem.addEventListener("change", self.handleSelectChange.bind(self));
  }

  handleSelectChange(event) {
    const { props } = this;

    let selectedValue = "";
    if (props.isNumber) {
      selectedValue = Number(event.detail.value);
    } else if (props.type === "bool") {
      selectedValue = convertValueToBool(event.detail.value);
    } else {
      selectedValue = event.detail.value;
    }
    props.onChange(event, selectedValue);
  }

  render() {
    const { props } = this;

    if (props.dontRender) {
      return null;
    }

    let attr = {};

    if (props.type) {
      attr["type"] = props.type;
    }

    if (props.disabled) {
      attr["disabled"] = props.disabled;
    }

    if (props.isNumber) {
      attr["type"] = "custom-number";
    }

    if (!!props.errorMsg) {
      attr["error"] = true;
    }

    if (!!props.size) {
      attr["size"] = props.size;
    }

    if (!!props.searchHidden) {
      attr["search-hidden"] = true;
    }

    const fieldClassNames = classNames("e-field", props.extraClassName, {
      "e-hidden": props.hidden,
      "e-field-inline": props.inline,
      "no-padding": props.noPadding
    });

    const labelClassNames = classNames("e-field__label", props.labelClassName, {
      "e-field__label-required": props.isRequired,
      "e-field__label-inline": props.inline
    });

    const selectWrapperClassNames = classNames({
      "l-select-with-button": props.isWithButton,
      "e-field__postfix": !!props.postfixButtons
    });

    let tooltipAttr = {};
    if (props.tooltipHasAction) {
      tooltipAttr["has-action"] = true;
    }

    return (
      <div className={fieldClassNames}>
        {props.label ? (
          <label className={labelClassNames}>
            {props.label}

            {props.tooltip ? <e-tooltip content={props.tooltip} type="helper" {...tooltipAttr} /> : null}
          </label>
        ) : null}
        <ConditionalWrapper
          condition={!props.inline}
          wrapper={(children) => <div className={selectWrapperClassNames}>{children}</div>}
        >
          <e-select
            ref={(elem) => (this.selectElem = elem)}
            className={props.className}
            style={props.style}
            {...attr}
            id={props.name}
            name={props.name}
            // onChange={props.onChange}
            placeholder={props.loading ? "" : props.placeholder}
            always-show-search={props.alwaysShowSearch}
            inline={props.inline}
          >
            {props.options.map((opt) => {
              let name,
                value = "";
              let selected = {};
              if (typeof opt === "object") {
                value = opt.value;
                name = opt.message ? getMessage(opt.message) : opt.name;
                if (opt.selected && opt.selected == "true") selected["selected"] = "true";
              } else {
                value = name = opt;
              }

              let attr = {};
              if (opt.disabled) {
                attr.disabled = true;
              }
              if (value === props.value) {
                attr.selected = true;
              }

              return (
                <e-select-option key={value} value={value} {...attr} {...selected}>
                  {name}
                </e-select-option>
              );
            })}
          </e-select>
          {!!props.postfixButtons && (
            <span className="e-field__postfix__cell e-field__postfix__cell-short e-field__postfix__cell-shortsticky">
              {props.postfixButtons}
            </span>
          )}
          {props.loading && (
            <div className="text-color-gray-400" style={{ position: "absolute", bottom: "8px", left: "8px" }}>
              <e-spinner data-size="small" data-text="loading..."></e-spinner>
            </div>
          )}
        </ConditionalWrapper>
        {props.errorMsg ? <span className="e-field__message e-field__message-error">{props.errorMsg}</span> : null}
      </div>
    );
  }
}

SelectField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  inline: PropTypes.bool,
  tooltip: PropTypes.string,
  type: PropTypes.string,
  hidden: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMsg: PropTypes.string,
  dontRender: PropTypes.bool,
  style: PropTypes.object,
  extraClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  isNumber: PropTypes.bool,
  loading: PropTypes.bool,
  alwaysShowSearch: PropTypes.bool,
  size: PropTypes.string,
  isRequired: PropTypes.bool,
  noPadding: PropTypes.bool
};

export default SelectField;
