import { renderToString } from "react-dom/server";
import actionService from "../services/actionService";
import rewardService from "../services/rewardService";
import { getMessage } from "../messages";
import { ACTION_STATUS_ENUM } from "../constants/actionConstants";
import { REWARD_STATUS_ENUM, REWARD_STATUS_LABEL } from "../constants/rewardConstants";
import Time from "../components/Time";

export const getActionStatusLabel = (status, action, connectedRewards) => {
  if (status === ACTION_STATUS_ENUM.INACTIVE) {
    let tooltip = getMessage("status.inactive.tooltip");

    if (actionService.isPromotionEnded(action)) {
      tooltip = getMessage("status.inactive.tooltip.proomotionEnded");
    } else {
      if (connectedRewards?.length > 0) {
        const invalidRewardMessages = connectedRewards
          .map((reward) => {
            const { status } = rewardService.getRewardStatus(reward);
            if ([REWARD_STATUS_ENUM.INACTIVE, REWARD_STATUS_ENUM.EMPTY, REWARD_STATUS_ENUM.EXPIRED].includes(status)) {
              return getMessage("status.inactive.tooltip.connectedToRewardPools", {
                name: rewardService.getRewardTitle(reward, true),
                status: getMessage(`status.${status}`).toLowerCase()
              });
            }
            return null;
          })
          .filter((message) => !!message);
        tooltip = invalidRewardMessages.join("<br />");
      }
    }

    return {
      label: getMessage("status.inactive"),
      className: "e-label-default background-color-placeholder",
      tooltip
    };
  } else if (status === ACTION_STATUS_ENUM.READY_TO_USE) {
    let tooltip;

    if (actionService.isPromotionAction(action.masterAction)) {
      tooltip = getMessage("status.readyToUse.tooltip.promotion");
    } else if (actionService.isAttachable(action)) {
      tooltip = getMessage("status.readyToUse.tooltip.attachable");
    }

    return {
      label: getMessage("status.readyToUse"),
      className: "e-label-warning",
      tooltip
    };
  } else if (status === ACTION_STATUS_ENUM.ACTIVE) {
    let tooltip;

    if (actionService.isRecurring(action) && !action.scheduling?.recurring.hasRecurrences) {
      return {
        label: getMessage("status.inactive"),
        className: "e-label-default background-color-placeholder",
        tooltip: getMessage("status.inactive.tooltip.invalidPromotionRecurringDates")
      };
    }

    if (!action.isDefault && actionService.isPromotionAction(action.masterAction)) {
      tooltip = getMessage("status.active.tooltip.promotion");
    } else if (!action.isDefault && actionService.isAttachable(action)) {
      tooltip = getMessage("status.active.tooltip.attachable");
    } else if (!action.isDefault && actionService.isEventAction(action.masterAction)) {
      tooltip = getMessage("status.active.tooltip.event");
    }

    return {
      label: getMessage("status.active"),
      className: "e-label-primary",
      tooltip
    };
  } else if (status === ACTION_STATUS_ENUM.DEACTIVATED) {
    let tooltip;
    if (actionService.isPromotionAction(action.masterAction)) {
      tooltip = getMessage("status.deactivated.tooltip.promotion");
    } else if (actionService.isAttachable(action)) {
      tooltip = getMessage("status.deactivated.tooltip.attachable");
    }

    return {
      label: getMessage("status.inactive"),
      className: "e-label-default background-color-placeholder",
      tooltip
    };
  }
};

export const getRewardStatusLabel = (statusObj, reward) => {
  let tooltip = "";
  const { label, className } = REWARD_STATUS_LABEL[statusObj.status];

  if (statusObj.status === REWARD_STATUS_ENUM.ACTIVE) {
    if (rewardService.isVoucherForRewards(reward.masterReward)) {
      const connected = [
        ...statusObj.connectedFixedBenefitNames,
        ...statusObj.connectedAdditionalBenefitsNames,
        ...statusObj.connectedActionNames
      ];

      if (connected.length > 0 || statusObj.redemptionOn) {
        let tooltipContent = getConnectedTooltipText(statusObj);

        tooltip = `${getMessage("voucherOverviewPage.card.activeAndConnectedTooltip.rewards")} ${tooltipContent}`;
      }
    } else {
      let tierList = statusObj.connectedRedemptionTierNames
        .map((name) => {
          return `- ${name} <br/>`;
        })
        .join("");

      tooltip = `${getMessage("voucherOverviewPage.card.activeAndConnectedTooltip.tiers")} ${tierList}`;
    }
  } else if (statusObj.status === REWARD_STATUS_ENUM.READY_TO_USE) {
    tooltip = rewardService.isVoucherForRewards(reward.masterReward)
      ? getMessage("voucherOverviewPage.card.readyToConnectTooltip.rewards")
      : getMessage("voucherOverviewPage.card.readyToConnectTooltip.tiers");
  } else if (statusObj.status === REWARD_STATUS_ENUM.SCHEDULED) {
    var textObject = {
      text: renderToString(<Time value={reward.validity.offerValidity.start} />)
    };
    tooltip = getMessage("voucherOverviewPage.card.scheduledTooltip", textObject);
  } else if (statusObj.status === REWARD_STATUS_ENUM.INACTIVE) {
    tooltip = getMessage("voucherOverviewPage.card.inactiveTooltip");
  } else if (statusObj.status === REWARD_STATUS_ENUM.EMPTY) {
    tooltip = getMessage("voucherOverviewPage.card.emptyTooltip");
  } else if (statusObj.status === REWARD_STATUS_ENUM.EXPIRED) {
    tooltip = getMessage("voucherOverviewPage.card.expiredTooltip");
  }

  return {
    label,
    className,
    tooltip
  };
};

export const getConnectedTooltipText = (statusObj) => {
  if (
    !statusObj.connectedActionNames &&
    !statusObj.connectedFixedBenefitNames &&
    !statusObj.connectedAdditionalBenefitsNames &&
    !statusObj.redemptionOn
  ) {
    return "";
  }

  const connected = [
    ...statusObj.connectedFixedBenefitNames,
    ...statusObj.connectedAdditionalBenefitsNames,
    ...statusObj.connectedActionNames
  ];

  if (connected.length === 0 && !statusObj.redemptionOn) {
    return "";
  }

  const tooltipBenefitsContent =
    statusObj.connectedFixedBenefitNames.length > 0 || statusObj.connectedAdditionalBenefitsNames.length > 0
      ? [...statusObj.connectedFixedBenefitNames, ...statusObj.connectedAdditionalBenefitsNames]
          .map((name) => {
            return `- Benefit / ${name} <br/>`;
          })
          .join("")
      : "";

  const connectedActionNames =
    statusObj.connectedActionNames.length > 0
      ? statusObj.connectedActionNames
          .map((name) => {
            return `- Actions / ${name} <br/>`;
          })
          .join("")
      : "";

  const connectedToWallet = statusObj.redemptionOn ? `- Wallet <br/>` : "";

  return `${connectedToWallet} ${tooltipBenefitsContent} ${connectedActionNames}`;
};
