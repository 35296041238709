import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import Notification from "../components/Notification";
import LoaderOverlay from "../components/LoaderOverlay";
import DownloadButton from "../components/DownloadButton";
import Alert from "../components/Alert";

import MemberProfileUpdatePointsPopup from "../views/MemberProfile/MemberProfileUpdatePointsPopup";
import MemberProfileUpdateTierPopup from "../views/MemberProfile/MemberProfileUpdateTierPopup";
import MemberProfileChangePlanPopup from "../views/MemberProfile/MemberProfileChangePlanPopup";
import MemberProfileAdditionalBenefitsPopup from "../views/MemberProfile/MemberProfileAdditionalBenefitsPopup";
import MemberProfileDetails from "../views/MemberProfile/MemberProfileDetails";
import MemberProfileActivity from "../views/MemberProfile/MemberProfileActivity";
import MemberProfileHeader from "../views/MemberProfile/MemberProfileHeader";

import memberProfileApi from "../api/memberProfileApi";
import planApi from "../api/planApi";
import customerApi from "../api/customerApi";

import { formatPointsNameText } from "../helpers/pointsHelper";
import { isEmsSuite } from "../helpers/emsHelper";
import planService from "../services/planService";

import { showErrorAlert } from "../actions/alertActions";
import { selectCustomerHasMultiPlan, isAccountViewOnlySupport } from "../reducers";

import { ALERT_STATUS } from "../constants/alertContants";

import "../assets/styles/MemberProfile.scss";
import { getMessage } from "../messages";

class MemberProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactId: "",
      contactDetails: null,
      contactPersonalInfo: null,
      contactActivities: null,
      loadingDetails: true,
      loadingPersonalInfo: true,
      loadingActivities: true,
      loadingPlan: true,
      showPointsUpdatePopup: false,
      showTierUpdatePopup: false,
      showChangePlanPopup: false,
      showAdditionalBenefitsPopup: false,
      pointsNameObj: null
    };

    this.memberProfileDisplayId = sessionStorage.getItem("memberProfileDisplayId");
  }

  async componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  async loadData() {
    const contactId = this.props.match.params.id;

    this.setState({
      loadingDetails: true,
      loadingActivities: true,
      loadingPersonalInfo: true
    });

    if (await isEmsSuite()) {
      await this.loadEmsAccountsMap();
    }
    this.loadMemberProfileDetails(contactId, true);
    this.loadMemberProfilePersonalInfo(contactId);
    this.loadMemberProfileActivities(contactId);
  }

  async loadMemberProfileDetails(contactId, doGetPlan) {
    const contactDetails = await memberProfileApi.getMemberProfileDetails(contactId);
    if (this._isMounted) {
      this.setState({
        contactId,
        contactDetails,
        loadingDetails: false
      });

      if (contactDetails && doGetPlan) {
        const contactPlan = await planApi.getPlan(contactDetails.plan.planId);
        const pointsNameObj = planService.getPointsNameObj(contactPlan);
        const plans = await planApi.getAllPlans();
        this.setState({ pointsNameObj, plan: contactPlan, customerPlans: plans, loadingPlan: false });
      }
    }
  }

  async loadMemberProfileActivities(contactId) {
    const contactActivities = await memberProfileApi.getMemberProfileActivities(contactId);

    if (this._isMounted) {
      this.setState({
        contactId,
        contactActivities,
        loadingActivities: false
      });
    }
  }

  async loadMemberProfilePersonalInfo(contactId) {
    let contactPersonalInfo = {};

    //if the api resolves in an error, ignore and don't display this info
    try {
      contactPersonalInfo = await memberProfileApi.getMemberProfilePersonalInfo(contactId);
    } catch (error) {}

    if (this._isMounted) {
      this.setState({
        contactPersonalInfo,
        loadingPersonalInfo: false
      });
    }
  }

  async loadEmsAccountsMap() {
    let emsAccountsMap = {};
    try {
      emsAccountsMap = await customerApi.getEmsAccountsMap();
    } catch (error) {
      console.error(error);
    }

    if (this._isMounted) {
      this.setState({
        emsAccountsMap
      });
    }
  }

  showPointsUpdatePopup() {
    this.setState({
      showPointsUpdatePopup: true
    });
  }

  closePointsUpdatePopup() {
    this.setState({
      showPointsUpdatePopup: false
    });
  }

  showTierUpdatePopup() {
    this.setState({
      showTierUpdatePopup: true
    });
  }

  closeTierUpdatePopup() {
    this.setState({
      showTierUpdatePopup: false
    });
  }

  showChangePlanPopup() {
    this.setState({
      showChangePlanPopup: true
    });
  }

  closeChangePlanPopup() {
    this.setState({
      showChangePlanPopup: false
    });
  }

  showAdditionalBenefitsPopup() {
    this.setState({
      showAdditionalBenefitsPopup: true
    });
  }

  closeAdditionalBenefitsPopup() {
    this.setState({
      showAdditionalBenefitsPopup: false
    });
  }

  async updateApiAndRefresh(updateFunc) {
    try {
      let interval;
      let intervalCounter = 0;
      this.setState({
        loadingDetails: true,
        loadingActivities: true
      });

      await updateFunc();

      interval = setInterval(() => {
        if (intervalCounter < 3) {
          this.loadMemberProfileDetails(this.state.contactId);
          this.loadMemberProfileActivities(this.state.contactId);
          intervalCounter++;
        } else if (interval) {
          clearInterval(interval);
        }
      }, 3000);
    } catch (e) {
      this.setState({
        loadingDetails: false,
        loadingActivities: false
      });
    }
  }

  async onUpdateMemberPoints({ pointsType, pointsUpdateType, pointsValue, reason, description }) {
    const func = async () => {
      this.closePointsUpdatePopup();

      try {
        await memberProfileApi.updateMemberPoints(this.state.contactId, {
          pointsType,
          pointsUpdateType,
          pointsValue,
          reason,
          description
        });
      } catch (error) {
        this.props.showErrorAlert(getMessage("memberProfile.updateMembeerPoints.error"));
        console.error(error);
      }
    };

    this.updateApiAndRefresh(func);
  }

  async onUpdateMemberTier({ tierUpdateType, selectedTierId, reason, activityName, tierDowngradeType }) {
    const func = async () => {
      this.closeTierUpdatePopup();

      try {
        await memberProfileApi.updateMemberTier(this.state.contactId, {
          tierUpdateType,
          selectedTierId,
          reason,
          activityName,
          currentTierId: this.state.contactDetails.tier.tierId,
          tierDowngradeType
        });
      } catch (error) {
        this.props.showErrorAlert(getMessage("memberProfile.updateMemberTier.error"));
        throw error;
      }
    };
    this.updateApiAndRefresh(func);
  }

  async onChangeMemberPlan({
    planId,
    balancePointsMigrate,
    statusPointsMigrate,
    pendingPointsMigrate,
    redeemedVouchersMigrate
  }) {
    const func = async () => {
      this.closeChangePlanPopup();
      const data = {
        planId,
        balancePointsMigrate,
        statusPointsMigrate,
        pendingPointsMigrate,
        redeemedVouchersMigrate
      };
      try {
        await memberProfileApi.changeMemberPlan(this.state.contactId, data);
      } catch (error) {
        this.props.showErrorAlert(getMessage("memberProfile.updateMemberPlan.error"));
        throw error;
      }
    };
    this.updateApiAndRefresh(func);
  }

  async onUpdateMemberAdditionalBenefits({ benefitIdsToAdd, benefitsWithTrackingToRemove, reason }) {
    const func = async () => {
      this.closeAdditionalBenefitsPopup();

      try {
        if (benefitIdsToAdd.length > 0) {
          await memberProfileApi.addMemberAdditionalBenefits(this.state.contactId, {
            planId: this.getContactPlanId(),
            benefitIds: benefitIdsToAdd,
            reason
          });
        }
        if (benefitsWithTrackingToRemove.length > 0) {
          await memberProfileApi.removeMemberAdditionalBenefits(this.state.contactId, {
            planId: this.getContactPlanId(),
            benefitsWithTrackingToRemove,
            reason
          });
        }
      } catch (error) {
        this.props.showErrorAlert(getMessage("memberProfile.updateMemberAdditionalBenefits.error"));
        throw error;
      }
    };

    this.updateApiAndRefresh(func);
  }

  getContactPointsObj() {
    const contactDetails = this.state.contactDetails || {};
    return contactDetails.points || {};
  }

  getContactTierId() {
    const contactDetails = this.state.contactDetails || {};
    const contactTier = contactDetails.tier || {};
    return contactTier.tierId;
  }

  getContactPlanId() {
    const contactDetails = this.state.contactDetails || {};
    const contactPlan = contactDetails.plan || {};
    return contactPlan.planId;
  }

  getContactPlanType() {
    return this.state.plan.planType;
  }

  getPointsText(text, pointsValue) {
    return formatPointsNameText({ text, pointsValue, namesObj: this.state.pointsNameObj });
  }

  isPartOfGroup() {
    return !!this.state.contactDetails?.groupName;
  }

  isMemberMultiPlan() {
    if (!this.state.customerPlans) return false;
    const plans = this.state.customerPlans.filter((p) =>
      planService.areSamePlanType(p.planType, this.getContactPlanType())
    );
    return plans.length > 1;
  }

  renderMemberDetails() {
    const contactDetails = this.state.contactDetails || {};
    const { contactPersonalInfo } = { ...this.state };
    const tier = contactDetails.tier || {};
    const plan = contactDetails.plan || {};
    const pointsObj = this.getContactPointsObj();

    let nextTierPoints,
      nextTierPurchases = 0;

    if (contactDetails.nextTier) {
      const statusPoints = !!contactDetails.groupPoints ? contactDetails.groupPoints.status : pointsObj.status;
      nextTierPoints = contactDetails.nextTier.points - statusPoints;
      nextTierPurchases = contactDetails.nextTier.purchases - contactDetails.purchases;
    }

    return (
      <MemberProfileDetails
        tier={tier}
        nextTier={contactDetails.nextTier}
        nextTierPoints={nextTierPoints}
        nextTierPurchases={nextTierPurchases}
        planId={plan.planId}
        joinDate={contactDetails.joinDate}
        tierExp={contactDetails.tierExp}
        pointsObj={pointsObj}
        groupPointsObj={contactDetails.groupPoints}
        purchases={contactDetails.purchases}
        groupName={contactDetails.groupName}
        hasMultiPlan={this.props.hasMultiPlan}
        pointsNameObj={this.state.pointsNameObj}
        loading={this.state.loadingDetails || this.state.loadingPlan || this.state.loadingPersonalInfo}
        plan={this.state.plan}
        showPersonalInfo={true}
        firstName={contactPersonalInfo?.firstName}
        lastName={contactPersonalInfo?.lastName}
        email={contactPersonalInfo?.email}
      />
    );
  }

  renderMemberActivities() {
    if (!this.state.contactActivities) {
      return null;
    }

    return (
      <MemberProfileActivity
        initialActivities={this.state.contactActivities}
        pointsNameObj={this.state.pointsNameObj}
        plan={this.state.plan}
        emsAccountsMap={this.state.emsAccountsMap}
      />
    );
  }

  apiParams = () => {
    return { contactId: this.state.contactId };
  };

  render() {
    const { isViewOnly } = { ...this.props };
    const { contactPersonalInfo } = { ...this.state };
    return (
      <div className="member-profile e-layout e-layout-without_navigation">
        <MemberProfileHeader title={getMessage("memberProfile.title")} />
        <main className="e-layout__content">
          <section className="e-layout__section l-member-profile-section">
            <Fragment>
              <div className="e-header">
                <h2 className="e-header__title">
                  {getMessage("memberProfile.headerTitle", { memberId: this.memberProfileDisplayId })}
                </h2>
                <div className="e-header__button">
                  <div className="e-buttongroup">
                    <button className="e-btn e-btn-onlyicon" onClick={this.loadData.bind(this)}>
                      <e-icon icon="e-refresh"></e-icon>
                    </button>
                    <button
                      className="e-btn e-btn-primary"
                      onClick={this.showPointsUpdatePopup.bind(this)}
                      disabled={isViewOnly || !this.state.contactDetails}
                    >
                      {getMessage("memberProfile.updatePointsBtn.label", {
                        isPoints: !planService.isSpendPlan(this.state.plan).toString()
                      })}
                    </button>

                    <button
                      className="e-btn e-btn-secondary"
                      onClick={this.showTierUpdatePopup.bind(this)}
                      disabled={isViewOnly || !this.state.contactDetails}
                    >
                      {getMessage("memberProfile.updateTierBtn.label")}
                    </button>

                    <button
                      className="e-btn e-btn-secondary"
                      onClick={this.showAdditionalBenefitsPopup.bind(this)}
                      disabled={isViewOnly || !this.state.contactDetails}
                    >
                      {getMessage("memberProfile.updateBenefitsBtn.label")}
                    </button>

                    {this.props.hasMultiPlan && (
                      <button
                        className="e-btn e-btn-primary"
                        disabled={
                          isViewOnly || !this.state.contactDetails || this.isPartOfGroup() || !this.isMemberMultiPlan()
                        }
                        onClick={this.showChangePlanPopup.bind(this)}
                      >
                        {this.isPartOfGroup() ? (
                          <e-tooltip
                            content={this.isPartOfGroup() ? getMessage("memberProfile.updatePlanBtn.tootip") : null}
                          >
                            {getMessage("memberProfile.updatePlanBtn.label")}
                          </e-tooltip>
                        ) : (
                          getMessage("memberProfile.updatePlanBtn.label")
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <Notification content={getMessage("memberProfile.notification")} />

              {this.renderMemberDetails()}

              <div className="e-box member-activity-wrapper">
                <div className="e-header">
                  <h2 className="e-header__title">{getMessage("memberProfile.activity.title")}</h2>
                  <div className="e-header__button">
                    <DownloadButton
                      apiParams={this.apiParams()}
                      apiFunc={memberProfileApi.getMemberVouchersCsv}
                      buttonTxt={getMessage("memberProfile.activity.downloadBtn.label")}
                      tooltip={getMessage("memberProfile.activity.downloadBtn.tooltip")}
                      csvName="Vouchers.csv"
                    />
                  </div>
                </div>

                <div className="member-activity">
                  {this.renderMemberActivities()}{" "}
                  <LoaderOverlay show={this.state.loadingActivities || this.state.loadingPlan} />
                </div>
              </div>
            </Fragment>

            <Fragment>
              {" "}
              <MemberProfileUpdatePointsPopup
                isShow={this.state.showPointsUpdatePopup}
                isGroupTooltip={this.isPartOfGroup()}
                contactPlanId={this.getContactPlanId()}
                onClose={this.closePointsUpdatePopup.bind(this)}
                contactPoints={this.getContactPointsObj()}
                onUpdatePoints={this.onUpdateMemberPoints.bind(this)}
                getPointsText={this.getPointsText.bind(this)}
                plan={this.state.plan}
              />
              <MemberProfileUpdateTierPopup
                isShow={this.state.showTierUpdatePopup}
                isGroupTooltip={this.isPartOfGroup()}
                onClose={this.closeTierUpdatePopup.bind(this)}
                contactPlanId={this.getContactPlanId()}
                contactTierId={this.getContactTierId()}
                onUpdateTier={this.onUpdateMemberTier.bind(this)}
              />
              <MemberProfileAdditionalBenefitsPopup
                isShow={this.state.showAdditionalBenefitsPopup}
                onClose={this.closeAdditionalBenefitsPopup.bind(this)}
                contactId={this.state.contactId}
                contactPlanId={this.getContactPlanId()}
                onUpdateAdditionalBenefits={this.onUpdateMemberAdditionalBenefits.bind(this)}
              />
              <MemberProfileChangePlanPopup
                isShow={this.state.showChangePlanPopup}
                onClose={this.closeChangePlanPopup.bind(this)}
                contactPlanId={this.getContactPlanId()}
                onChangeMemberPlan={this.onChangeMemberPlan.bind(this)}
                getPointsText={this.getPointsText.bind(this)}
                plan={this.state.plan}
              />
            </Fragment>
          </section>
        </main>
        {contactPersonalInfo?.hasDuplicates && (
          <div className="l-alert-container m-t-5" style={{ width: "760px" }}>
            <Alert message={getMessage("memberProfile.duplicateAlert")} status={ALERT_STATUS.WARNING} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hasMultiPlan: selectCustomerHasMultiPlan(state),
    isViewOnly: isAccountViewOnlySupport(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showErrorAlert: (message) => {
      dispatch(showErrorAlert(message));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberProfile);
