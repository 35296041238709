import { getMessage } from "../messages";

export const PRICE_FIELD_ENUM = {
  PRICE: "price",
  ORIGINAL_PRICE: "originalPrice"
};

export const PRICE_FIELD_OPTIONS = [
  {
    name: "Price",
    value: PRICE_FIELD_ENUM.PRICE,
    tooltip: getMessage("priceField.options.price")
  },
  {
    name: "Original_price",
    value: PRICE_FIELD_ENUM.ORIGINAL_PRICE,
    tooltip: getMessage("priceField.options.originalPrice")
  }
];
