import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import Notification from "../../components/Notification";
import {
  API_KEY_DOCUMENTATION_URL,
  SECURITY_HELP_URL,
  SECURITY_PAGE_TYPE,
  WALLET_APP_DOCUMENTATION_URL
} from "../../constants/securityConstants";
import { getMessage } from "../../messages";
import { formatIsoTime } from "../../helpers/timeHelper";

export default function SecurityPageGrid({
  pageType,
  data,
  emsAccountMap,
  onCreate,
  onEdit,
  onDelete,
  maxItems,
  disableEdit,
  disableDelete,
  disabledCreateMessage
}) {
  const createItemAction = useRef(null);
  const editItemAction = useRef(null);
  const deleteItemAction = useRef(null);

  const messageKeyPrefix = `securitySettings.${pageType}`;

  useEffect(() => {
    editItemAction.current.addEventListener("trigger", onEditHandler);
    deleteItemAction.current.addEventListener("trigger", onDeleteHandler);
    return () => {
      editItemAction.current.removeEventListener("trigger", onEditHandler);
      deleteItemAction.current.removeEventListener("trigger", onDeleteHandler);
    };
  }, [data]);

  useEffect(() => {
    createItemAction.current.addEventListener("trigger", onCreateItem);
    return () => {
      createItemAction.current.removeEventListener("trigger", onCreateItem);
    };
  }, []);

  function onCreateItem(event) {
    onCreate(event);
  }

  function onEditHandler(event) {
    const rowData = event.detail.data;
    onEdit(rowData.id);
  }

  function onDeleteHandler(event) {
    const rowData = event.detail.data;
    onDelete(rowData.id);
  }

  function getNotificationMessage() {
    let messageParams = {};
    const link = pageType === SECURITY_PAGE_TYPE.API_KEY ? API_KEY_DOCUMENTATION_URL : WALLET_APP_DOCUMENTATION_URL;
    const notificationMessageLink = `<a target="_blank" href="${link}">${getMessage(
      `${messageKeyPrefix}.notificationLinkText`
    )}</a>`;
    messageParams["link"] = notificationMessageLink;

    const notificationMessageHelpLink = `<a target="_blank" href="${SECURITY_HELP_URL}">${getMessage(
      `${messageKeyPrefix}.notificationHelpLinkText`
    )}</a>`;
    messageParams["helpLink"] = notificationMessageHelpLink;

    return getMessage(`${messageKeyPrefix}.notificationMessage`, messageParams);
  }

  const gridData = data
    ? data.map((item) => {
        return {
          id: item.id,
          admin: emsAccountMap[item.adminId]?.userName || item.adminId,
          desc: item.desc,
          created: formatIsoTime(item.createdAt),
          status: item.active
            ? getMessage("securitySettings.grid.data.activated")
            : getMessage("securitySettings.grid.data.deactivated")
        };
      })
    : [];

  return (
    <div className="box-padding">
      <Notification content={getNotificationMessage()} />
      <e-datagrid bordered data={JSON.stringify(gridData)}>
        <e-datagrid-action
          label={getMessage(`${messageKeyPrefix}.createButton`)}
          ref={createItemAction}
          disabled={disabledCreateMessage}
          disabled-tooltip={disabledCreateMessage}
        ></e-datagrid-action>
        <e-datagrid-column
          head={getMessage("securitySettings.grid.column.admin")}
          content-key="admin"
        ></e-datagrid-column>
        {pageType === SECURITY_PAGE_TYPE.WALLET_APP && (
          <e-datagrid-column
            head={getMessage("securitySettings.grid.column.appId")}
            content-key="id"
          ></e-datagrid-column>
        )}
        <e-datagrid-column
          head={getMessage("securitySettings.grid.column.description")}
          content-key="desc"
        ></e-datagrid-column>
        <e-datagrid-column-time
          head={getMessage("securitySettings.grid.column.created")}
          content-key="created"
        ></e-datagrid-column-time>
        <e-datagrid-column
          head={getMessage("securitySettings.grid.column.status")}
          content-key="status"
        ></e-datagrid-column>
        <e-datagrid-item-action
          icon="pencil"
          tooltip={getMessage("securitySettings.grid.editTooltip")}
          ref={editItemAction}
          disabled={disableEdit}
        ></e-datagrid-item-action>
        <e-datagrid-item-action
          icon="trash-o"
          tooltip={getMessage("securitySettings.grid.deleteTooltip")}
          ref={deleteItemAction}
          disabled={disableDelete}
        ></e-datagrid-item-action>
      </e-datagrid>
    </div>
  );
}

SecurityPageGrid.propTypes = {
  pageType: PropTypes.string.isRequired,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  data: PropTypes.array,
  emsAccountMap: PropTypes.object,
  maxItems: PropTypes.number,
  disabledCreateMessage: PropTypes.string,
  disableDelete: PropTypes.bool,
  disableEdit: PropTypes.bool
};
