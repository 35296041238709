import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import classNames from "classnames";

import LoaderOverlay from "../../components/LoaderOverlay";

import { getTierIconsUrl } from "../../helpers/envHelper";
import { getIconName } from "../../helpers/iconHelper";
import { formatPointsNameText, POINTS_PLACEHOLDER } from "../../helpers/pointsHelper";

import { selectCustomerHasMultiPlan } from "../../reducers";
import planService from "../../services/planService";
import { getMessage, getPluralCardinalMessage } from "../../messages";
import Time from "../../components/Time";

const MemberProfileDetails = ({
  tier,
  planId,
  joinDate,
  tierExp,
  pointsObj,
  groupPointsObj,
  nextTierPoints,
  nextTierPurchases,
  groupName,
  pointsNameObj,
  createdAt,
  groupMembersNum,
  loading,
  plan,
  showPersonalInfo,
  firstName,
  lastName,
  email
}) => {
  tier = tier || {};

  const hasMultiPlan = useSelector((state) => selectCustomerHasMultiPlan(state));
  const numberOfPurchases = nextTierPurchases && nextTierPurchases > 0 ? nextTierPurchases : 0;

  function renderTierDetails() {
    joinDate = joinDate ? new Date(joinDate).toISOString() : "";
    const tierEntryDate = tier.tierEntryAt ? new Date(tier.tierEntryAt).toISOString() : "";
    const tierExpDate = tierExp ? new Date(tierExp).toISOString() : "";
    return (
      <Fragment>
        <div className="member-details-tier-top e-grid e-grid-vertical_center">
          <div className="e-cell e-cell-6">
            <div className="e-grid e-grid-vertical_center">
              <div className="tier-icon">
                {tier.icon ? <img src={`${getTierIconsUrl()}/${getIconName(tier.icon, ".png")}`} /> : null}
              </div>
              <div>
                <div className="text-size-title">{tier.name}</div>
                {planId && hasMultiPlan && (
                  <div className="font-weight-thin">
                    {planId ? getMessage("memberProfileDetails.tierDetails.plan", { planId }) : null}
                  </div>
                )}
                {groupName && (
                  <div className="font-weight-thin">
                    {getMessage("memberProfileDetails.tierDetails.group", { groupName })}
                  </div>
                )}
                {joinDate && (
                  <div className="font-weight-thin">
                    {getMessage("memberProfileDetails.tierDetails.joinedOn")}
                    <Time value={joinDate} onlyDate={true} />
                  </div>
                )}
                {!groupName && <div className="font-weight-thin">&nbsp;</div>}
                {createdAt && (
                  <div className="font-weight-thin">
                    {getMessage("memberProfileDetails.tierDetails.createdOn")}{" "}
                    <Time value={createdAt} onlyDate={true} />
                  </div>
                )}
                {groupMembersNum && (
                  <div className="font-weight-thin">
                    {getMessage("memberProfileDetails.tierDetails.groupMembers", { groupMembersNum })}
                  </div>
                )}
              </div>
            </div>
          </div>
          {showPersonalInfo && (
            <div className="e-cell e-cell-6 p-l-15">
              <div className="text-size-title">&nbsp;</div>
              <div className="font-weight-thin">
                {getMessage("memberProfileDetails.tierDetails.personalInfo.firstName", { firstName })}
              </div>
              <div className="font-weight-thin">
                {getMessage("memberProfileDetails.tierDetails.personalInfo.lastName", { lastName })}
              </div>
              <div className="font-weight-thin">
                {getMessage("memberProfileDetails.tierDetails.personalInfo.email", { email })}
              </div>
            </div>
          )}
        </div>
        <div
          className={classNames("member-details-tier-bottom", {
            "two-cells": !tierExpDate
          })}
        >
          <div>
            <div className="font-weight-light">{getMessage("memberProfileDetails.tierDetails.tierEntryDate")}</div>
            <div>
              <Time value={tierEntryDate} onlyDate={true} />
            </div>
          </div>
          {tierExpDate && (
            <div>
              <div className="font-weight-light">{getMessage("groupProfileActivity.header.tierExpiration")}</div>
              <div>
                <Time value={tierExpDate} onlyDate={true} />
              </div>
            </div>
          )}
          <div>
            <div className="font-weight-light">
              {getMessage("memberProfileDetails.tierDetails.nextTierRequirements")}
            </div>
            <div>
              <e-numeric
                value={nextTierPoints && nextTierPoints > 0 ? nextTierPoints : 0}
                precision="2"
                trim-fraction-zeros="true"
              ></e-numeric>{" "}
              {getPointsText(POINTS_PLACEHOLDER, nextTierPoints)} /{" "}
              {getPluralCardinalMessage("tiers.numberOfPurchases", numberOfPurchases, {
                number: numberOfPurchases
              })}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  function renderPointsHistory() {
    const { status, balance, pending, expiresSoon, cashback } = { ...pointsObj };
    return (
      <Fragment>
        <div className="member-details-points-top e-grid">
          <div className="member-details-points-title">
            {getMessage("memberProfileDetails.pointsHistory.currentBalance.title")}
          </div>
        </div>
        <div className="member-details-points-bottom two-cells">
          <div
            className={classNames("member-details-points-content", {
              "member-details-points-content_space-between":
                !isNaN(status) &&
                !isNaN(balance) &&
                !isNaN(pending) &&
                !isNaN(expiresSoon) &&
                !planService.isSpendPlan(plan)
            })}
          >
            {!isNaN(status) && (
              <div>
                <div>
                  {getPointsText(getMessage("memberProfileChangePlanPopup.selectRewards.statusPointsCb.label"))}
                </div>
                <div>{status}</div>
              </div>
            )}
            {!isNaN(balance) && !planService.isSpendPlan(plan) && (
              <div>
                <div>
                  {getPointsText(getMessage("memberProfileDetails.pointsHistory.currentBalance.pointsToSpend"))}
                </div>
                <div>{balance}</div>
              </div>
            )}
            {!isNaN(pending) && (
              <div>
                <div>
                  {getPointsText(getMessage("memberProfileChangePlanPopup.selectRewards.pendingPointsCb.label"))}
                </div>
                <div>{pending}</div>
              </div>
            )}
            {!isNaN(expiresSoon) && !planService.isSpendPlan(plan) && (
              <div>
                <div>{getMessage("memberProfileDetails.pointsHistory.currentBalance.expiresSoon")}</div>
                <div>{expiresSoon}</div>
              </div>
            )}
            {!isNaN(cashback) && planService.isSpendPlan(plan) && planService.isCashbackEnabled(plan) && (
              <div>
                <div>{getMessage("rewardName.cashback")}</div>
                <div>{cashback}</div>
              </div>
            )}
          </div>
          <div className="member-details-points-content">
            {groupPointsObj && (
              <Fragment>
                <div>
                  <div>
                    {getPointsText(getMessage("memberProfileDetails.pointsHistory.currentBalance.groupStatus"))}
                  </div>
                  <div>{groupPointsObj.status}</div>
                </div>
                <div>
                  <div>
                    {getPointsText(getMessage("memberProfileDetails.pointsHistory.currentBalance.groupPending"))}
                  </div>
                  <div>{groupPointsObj.pending}</div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    );
  }

  function getPointsText(text, pointsValue) {
    return formatPointsNameText({ text, pointsValue, namesObj: pointsNameObj });
  }

  return (
    <div className="member-details e-grid e-grid-wrap e-grid-small">
      <div className="e-cell e-cell-6 e-cell-small">
        <div className="e-box member-details-tier">{renderTierDetails()}</div>
      </div>
      <div className="e-cell e-cell-6 e-cell-small">
        <div className="e-box member-details-points">{renderPointsHistory()}</div>
      </div>
      {/* <div className="e-cell e-cell-4 e-cell-small disabled-section">
            <div className="e-box member-details-history">{this.renderEngagementHistory()}</div>
          </div> */}
      <LoaderOverlay show={loading} />
    </div>
  );
};

MemberProfileDetails.propTypes = {
  tier: PropTypes.object,
  nextTier: PropTypes.object,
  nextTierPoints: PropTypes.number,
  nextTierPurchases: PropTypes.number,
  planId: PropTypes.string,
  joinDate: PropTypes.number,
  tierExp: PropTypes.number,
  pointsObj: PropTypes.object,
  groupPointsObj: PropTypes.object,
  groupName: PropTypes.string,
  pointsNameObj: PropTypes.object,
  createdAt: PropTypes.number,
  groupMembersNum: PropTypes.number,
  loading: PropTypes.bool,
  showPersonalInfo: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  emailName: PropTypes.string
};

export default MemberProfileDetails;
