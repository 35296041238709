import * as TYPES from "../actions/TYPES";

const initialState = {
  externalEvents: {},
  loading: false,
  dataLoaded: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.POST_EXTERNAL_EVENTS_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case TYPES.GET_ALL_EXTERNAL_EVENTS_REQUEST: {
      return {
        ...state,
        loading: true,
        dataLoaded: false
      };
    }

    case TYPES.GET_ALL_EXTERNAL_EVENTS_SUCCESS: {
      const externalEvents = action.payload;
      return {
        ...state,
        externalEvents,
        loading: false,
        dataLoaded: true
      };
    }

    case TYPES.POST_EXTERNAL_EVENTS_SUCCESS: {
      const externalEvent = action.payload;
      return {
        ...state,
        externalEvents: {
          ...state.externalEvents,
          [externalEvent.id]: externalEvent
        },
        loading: false
      };
    }
    case TYPES.GET_ALL_EXTERNAL_EVENTS_FAILURE:
    case TYPES.POST_EXTERNAL_EVENTS_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    default:
      return state;
  }
}

export const isDataLoaded = (state) => {
  return state.dataLoaded;
};

export const selectExternalEvents = (state) => {
  return { ...state.externalEvents };
};
