import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

import PopupContainer from "../../components/PopupContainer";
import InputField from "../../components/InputField";
import Alert from "../../components/Alert";
import { ALERT_STATUS } from "../../constants/alertContants";
import sanitize from "sanitize-html";
import LoaderOverlay from "../../components/LoaderOverlay";
import { getMessage } from "../../messages";

export default function ReAuthPopup({ id, show, title, subTitle, onConfirm, onClose, authError, loading }) {
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");

  function onConfirmHandler() {
    const errorMsg = password ? "" : getMessage("securitySettings.authPopup.accountPasswordFieldError");
    if (!errorMsg) {
      onConfirm(password);
      return;
    }

    setError(errorMsg);
  }

  function onCloseHandler() {
    setPassword("");
    setError("");
    onClose();
  }

  function onChange(event) {
    const value = event.target.value;
    setPassword(value);
    if (value) {
      setError("");
    }
  }

  return (
    <PopupContainer
      id={id}
      show={show}
      title={title}
      noClose={true}
      buttons={[
        {
          text: getMessage("closeBtn.label"),
          isClose: true,
          onClick: onCloseHandler
        },
        {
          text: getMessage("confirmBtn.label"),
          onClick: onConfirmHandler,
          className: "e-btn e-btn-primary"
        }
      ]}
      onClose={onCloseHandler}
      footerElement={
        <div className="text-align-center e-margin-top-xl">{getMessage("securitySettings.authPopup.footerText")}</div>
      }
    >
      <Fragment>
        {authError && <Alert message={authError} status={ALERT_STATUS.ERROR} />}
        <p dangerouslySetInnerHTML={{ __html: sanitize(subTitle) }} />
        <div className="e-cell e-cell-6">
          <InputField
            label={getMessage("securitySettings.authPopup.accountPasswordField")}
            value={password}
            onChange={onChange}
            errorMsg={error}
            type="password"
          />
        </div>
      </Fragment>
      <LoaderOverlay show={loading} />
    </PopupContainer>
  );
}

ReAuthPopup.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  authError: PropTypes.string,
  loading: PropTypes.bool
};

export function handleAuthError(error) {
  console.error(error);
  if (error.response && error.response.status === 401) {
    return getMessage("securitySettings.error.authentication");
  } else {
    return getMessage("securitySettings.error.general.text");
  }
}
