import axios from "axios";
import fetchIntercept from "fetch-intercept";
import { generateUUID } from "../helpers/uuidHelper";
import { isDemoMode, getApiBaseUrl } from "../helpers/envHelper";

export const setApiDefaults = (customerId, emsAdminId, jwtToken) => {
  const getAuthHeaderValue = () => "Bearer " + jwtToken;

  axios.defaults.baseURL = getApiBaseUrl(customerId);
  axios.defaults.headers.common["Authorization"] = getAuthHeaderValue();

  const demoModeAllowedUrls = ["/distribution-preview"];

  axios.interceptors.request.use(
    function (config) {
      if (
        isDemoMode() &&
        ["put", "post", "delete"].includes(config.method) &&
        !demoModeAllowedUrls.includes(config.url)
      ) {
        throw new axios.Cancel("demoMode");
      }

      config.headers.common["x-correlation-id"] = generateUUID();
      config.headers.common["x-ems-admin-id"] = emsAdminId;

      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  fetchIntercept.register({
    request: function (url, config) {
      if (url.indexOf("/member-profiles-overview") > -1) {
        config.headers["Authorization"] = getAuthHeaderValue();
        config.headers["x-correlation-id"] = generateUUID();
        config.headers["x-ems-admin-id"] = emsAdminId;
      }

      return [url, config];
    }
  });
};

export const invalidateJwt = (response, func) => {
  if (response.status === 401 && func) {
    func();
  }
};
