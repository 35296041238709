import validate from "validate.js";
import _values from "lodash/values";
import moment, { lang } from "moment";

import {
  FORM_STEP_ENUM,
  REDEMPTION_VALUE_TOKEN,
  EXCLUSIVE_ACCESS_EXCLUSIVITY_TYPE_ENUM,
  REDEMPTION_LIMIT_TYPE_ENUM,
  VALIDITY_TYPE_ENUM,
  MASTER_REWARD_ENUM,
  VOUCHER_VALIDITY_TYPE_OPTIONS
} from "../constants/rewardConstants";

import { EXCLUSIVE_ACCESS_OFFERS_ENUM, MASTER_EXCLUSIVE_ACCESS_ENUM } from "../constants/exclusiveAccessConstants";
import rewardService from "../services/rewardService";
import exclusiveAccessService from "../services/exclusiveAccessService";
import { getMessage } from "../messages";

validate.validators.accessValidity = (value, options, key, attributes) => {
  const { accessValidity } = options.exclusiveAccessValidity;

  if (accessValidity.disabled) {
    return undefined;
  }

  var constraints = {};
  constraints[key] = {
    presence: {
      allowEmpty: false,
      message: getMessage("excluseiveAccesssValidator.accessValidity.presence")
    },
    numericality: {
      onlyInteger: true,
      notInteger: getMessage("excluseiveAccesssValidator.accessValidity.numericality.notInteger"),
      greaterThan: 0,
      notGreaterThan: getMessage("excluseiveAccesssValidator.accessValidity.numericality.notGreaterThan")
    }
  };
  const error = validate(attributes, constraints);

  if (error !== undefined) {
    return error[key][0];
  }

  return undefined;
};

validate.validators.exclusiveOfferMaxDate = function (value, options, key, exclusiveAccess) {
  const { end } = value;
  if (!!options.validityRedemptionNode.accessValidityType && options.validityRedemptionNode.accessDateRange?.end) {
    if (options.validityRedemptionNode.accessValidityType === VALIDITY_TYPE_ENUM.DATE_RANGE) {
      if (moment(end).diff(moment(options.validityRedemptionNode.accessDateRange.end)) > 0)
        return getMessage("excluseiveAccesssValidator.exclusiveOfferMaxDate");
    }
  }
  if (
    exclusiveAccess.masterExclusiveAccess === EXCLUSIVE_ACCESS_EXCLUSIVITY_TYPE_ENUM.LIMITED &&
    !!exclusiveAccess.validity.blockingValidity.end
  ) {
    if (moment(end).diff(moment(exclusiveAccess.validity.blockingValidity.end)) > 0)
      return getMessage("excluseiveAccesssValidator.exclusiveOfferMaxDate");
  }

  return undefined;
};

validate.validators.redemptionMessageExclusiveAccess = function (value, options, key, reward) {
  const { message, token } = options;

  if (token) {
    value = value.replace(token, "").trim();
  }

  if (!value) {
    return message;
  }

  return undefined;
};

export default class exclusiveAccessValidator {
  constructor({ names, hasMultiMarket, languages, blockingURLs, startsWithURLs }) {
    this.stepConstraints = {};
    this.names = names;
    this.hasMultiMarket = hasMultiMarket;
    this.languages = languages;
    this.blockingURLs = blockingURLs;
    this.startsWithURLs = startsWithURLs;
    // const exclusiveAccessValidityConstraints = {
    //   "exclusiveAccessValidity.exclusivityType": {
    //     presence: {
    //       allowEmpty: false,
    //       message: "Exclusivity type is required."
    //     }
    //   },
    //   "exclusiveAccessValidity.accessValidity.duration": {
    //     accessValidity: {
    //       messageRequired: "Access validity must be an integer.",
    //       messageHigherThanZero: "Access validity must be greater then 0."
    //     }
    //   }
    // };

    // this.stepConstraints[FORM_STEP_ENUM.EXCLUSIVE_ACCESS_VALIDITY] = exclusiveAccessValidityConstraints;
  }

  AddRedemptionConstriants(
    redemptionNode,
    redemtionPathName,
    isForFree,
    validityRedemptionNode,
    masterExclusiveAccess
  ) {
    let constraints = {};

    (constraints[`${redemtionPathName}.message.title`] = {
      redemptionMessageExclusiveAccess: {
        message: getMessage("excluseiveAccesssValidator.redemptionConstriants.message.title")
      }
    }),
      (constraints[`${redemtionPathName}.message.text`] = {
        redemptionMessageExclusiveAccess: {
          message: getMessage("excluseiveAccesssValidator.redemptionConstriants.message.text"),
          token: REDEMPTION_VALUE_TOKEN.token
        }
      });
    if (masterExclusiveAccess === MASTER_EXCLUSIVE_ACCESS_ENUM.PERMANENT) {
      constraints[`validity.accessValidity.${redemtionPathName}.accessValidityType`] = {
        presence: {
          allowEmpty: false,
          message: getMessage("excluseiveAccesssValidator.redemptionConstriants.message.accessValidityType")
        }
      };
    }

    if (
      validityRedemptionNode.accessValidityType === VALIDITY_TYPE_ENUM.DATE_RANGE ||
      validityRedemptionNode.accessValidityType === VALIDITY_TYPE_ENUM.LIMITED_FROM_EARN
    ) {
      constraints[`${redemtionPathName}.limitValue`] = {
        presence: {
          allowEmpty: false,
          message: getMessage("excluseiveAccesssValidator.redemptionConstriants.limitValue.presence")
        },
        numericality: {
          onlyInteger: true,
          notInteger: getMessage("excluseiveAccesssValidator.redemptionConstriants.limitValue.numericality.notInteger"),
          greaterThan: 0,
          notGreaterThan: getMessage(
            "excluseiveAccesssValidator.redemptionConstriants.limitValue.numericality.notGreaterThan"
          )
        }
      };
    }

    constraints[`${redemtionPathName}.offerValidity`] = {
      dateRange: {
        messageEmpty: getMessage(
          "excluseiveAccesssValidator.redemptionConstriants.offerValidity.dateRange.messageEmpty"
        ),
        messageRangeNotValid: getMessage(
          "excluseiveAccesssValidator.redemptionConstriants.offerValidity.dateRange.messageRangeNotValid"
        ),
        minRangeMinutes: 1 * 60 //1 hour
      },
      exclusiveOfferMaxDate: {
        message: getMessage("excluseiveAccesssValidator.redemptionConstriants.exclusiveOfferMaxDate"),
        validityRedemptionNode: validityRedemptionNode
      }
    };

    for (const tierId in redemptionNode.value) {
      if (!redemptionNode.value.hasOwnProperty(tierId) || isForFree) continue;

      constraints[`${redemtionPathName}.value.${tierId}.points`] = {
        singleRedemptionValue: {
          tierId,
          enabledProp: "pointsEnabled"
        },
        numericality: {
          onlyInteger: true,
          notInteger: getMessage("excluseiveAccesssValidator.redemptionConstriants.value.points.notInteger"),
          greaterThan: 0,
          notGreaterThan: getMessage("excluseiveAccesssValidator.redemptionConstriants.value.points.notGreaterThan")
        }
      };
    }
    return constraints;
  }

  createExclusiveAccessPoolSettingsConstraints(exclusiveAccess) {
    let constraints = {
      name: {
        presence: {
          allowEmpty: false,
          message: getMessage("excluseiveAccesssValidator.poolSettingsConstriants.name.presence")
        },
        exclusionIgnoreCase: {
          within: this.names,
          message: getMessage("excluseiveAccesssValidator.poolSettingsConstriants.name.exclusionIgnoreCase")
        }
      },
      accessName: {
        presence: {
          allowEmpty: false,
          message: getMessage("excluseiveAccesssValidator.poolSettingsConstriants.accessName.presence")
        }
      }
    };

    if (exclusiveAccess.blocking.isStartsWith) {
      constraints["blocking.landingPageUrl"] = {
        presence: {
          allowEmpty: false,
          message: getMessage("excluseiveAccesssValidator.poolSettingsConstriants.blocking.landingPageUrl.presence")
        },
        url: {
          message: getMessage("excluseiveAccesssValidator.poolSettingsConstriants.blocking.landingPageUrl.url")
        }
      };
      constraints["blocking.startsWithAccessUrl"] = {
        presence: {
          allowEmpty: false,
          message: getMessage(
            "excluseiveAccesssValidator.poolSettingsConstriants.blocking.startsWithAccessUrl.presence"
          )
        },
        url: {
          message: getMessage("excluseiveAccesssValidator.poolSettingsConstriants.blocking.startsWithAccessUrl.url")
        },
        exclusionOrStartWithIgnoreCase: {
          within: this.blockingURLs,
          message: getMessage(
            "excluseiveAccesssValidator.poolSettingsConstriants.blocking.startsWithAccessUrl.exclusionOrStartWithIgnoreCase"
          )
        }
      };
    } else {
      constraints["blocking.exactAccessUrl"] = {
        presence: {
          allowEmpty: false,
          message: getMessage(
            "excluseiveAccesssValidator.poolSettingsConstriants.blocking.startsWithAccessUrl.presence"
          )
        },
        url: {
          message: getMessage("excluseiveAccesssValidator.poolSettingsConstriants.blocking.startsWithAccessUrl.url")
        },
        exclusionOrStartWithIgnoreCase: {
          within: this.blockingURLs,
          message: getMessage(
            "excluseiveAccesssValidator.poolSettingsConstriants.blocking.startsWithAccessUrl.exclusionOrStartWithIgnoreCase"
          ),
          startsWith: this.startsWithURLs
        }
      };
    }

    if (this.hasMultiMarket) {
      constraints["market"] = {
        presence: {
          allowEmpty: false,
          message: getMessage("excluseiveAccesssValidator.poolSettingsConstriants.market.presence")
        }
      };
    }

    if (exclusiveAccessService.isLimitedExclusiveAccess(exclusiveAccess.masterExclusiveAccess)) {
      constraints["validity.blockingValidity"] = {
        dateRange: {
          messageEmpty: getMessage(
            "excluseiveAccesssValidator.poolSettingsConstriants.validity.blockingValidity.dateRange.messageEmpty"
          ),
          messageRangeNotValid: getMessage(
            "excluseiveAccesssValidator.poolSettingsConstriants.validity.blockingValidity.dateRange.messageRangeNotValid"
          ),
          minRangeMinutes: 12 * 60 //12 hours
        }
      };
      constraints["validity.blockingValidity.end"] = {
        presence: {
          allowEmpty: false,
          message: getMessage(
            "excluseiveAccesssValidator.poolSettingsConstriants.validity.blockingValidity.dateRange.messageEmpty"
          )
        }
      };
    }

    return constraints;
  }

  createExclusiveAccessValidityConstraints(exclusiveAccess, offerType) {
    let constraints = {};
    if (exclusiveAccess.masterExclusiveAccess === MASTER_EXCLUSIVE_ACCESS_ENUM.PERMANENT) {
      let offerBranch = { ...exclusiveAccess.validity.accessValidity[offerType] };
      let offerBranchName = `validity.accessValidity.${offerType}`;
      if (offerBranch.accessValidityType === VALIDITY_TYPE_ENUM.LIMITED_FROM_EARN) {
        constraints[offerBranchName + ".accessValidity.duration"] = {
          accessValidity: {
            messageRequired: getMessage(
              "excluseiveAccesssValidator.accessValidityConstriants.duration.accessValidity.messageRequired"
            ),
            messageHigherThanZero: getMessage(
              "excluseiveAccesssValidator.accessValidityConstriants.duration.accessValidity.messageHigherThanZero"
            ),
            exclusiveAccessValidity: offerBranch
          }
        };
      } else if (offerBranch.accessValidityType === VALIDITY_TYPE_ENUM.DATE_RANGE) {
        constraints[offerBranchName + ".accessDateRange"] = {
          dateRange: {
            messageEmpty: getMessage(
              "excluseiveAccesssValidator.accessValidityConstriants.accessDateRange.messageEmpty"
            ),
            messageRangeNotValid: getMessage(
              "excluseiveAccesssValidator.accessValidityConstriants.accessDateRange.messageRangeNotValid"
            ),
            minRangeMinutes: 12 * 60 //12 hours
          }
        };
      }
    }
    return constraints;
  }

  createRedemptionValueConstraints(exclusiveAccess) {
    const redemptionValueTiers = exclusiveAccess.redemption;
    const redemptionForFreeValueTiers = exclusiveAccess.redemptionForFree;
    let constraints = {};

    if (exclusiveAccessService.hasEnabledTier(redemptionValueTiers)) {
      constraints = {
        ...this.AddRedemptionConstriants(
          redemptionValueTiers,
          "redemption",
          false,
          exclusiveAccess.validity.accessValidity.redemption,
          exclusiveAccess.masterExclusiveAccess
        )
      };
    }
    if (exclusiveAccessService.hasEnabledTier(redemptionForFreeValueTiers)) {
      constraints = {
        ...constraints,
        ...this.AddRedemptionConstriants(
          redemptionForFreeValueTiers,
          "redemptionForFree",
          true,
          exclusiveAccess.validity.accessValidity.redemptionForFree,
          exclusiveAccess.masterExclusiveAccess
        )
      };
    }

    Object.values(EXCLUSIVE_ACCESS_OFFERS_ENUM).forEach((offer) => {
      constraints = { ...constraints, ...this.createExclusiveAccessValidityConstraints(exclusiveAccess, offer) };
    });
    return constraints;
  }

  validateStep = (stepId, exclusiveAccess) => {
    let constraints = {};
    if (stepId === FORM_STEP_ENUM.REDEMPTION_VALUE) {
      constraints = this.createRedemptionValueConstraints(exclusiveAccess);
    } else if (stepId === FORM_STEP_ENUM.EXCLUSIVE_ACCESS_POOL_SETTINGS) {
      constraints = this.createExclusiveAccessPoolSettingsConstraints(exclusiveAccess);
    } else if (stepId === FORM_STEP_ENUM.EXCLUSIVE_ACCESS_VALIDITY) {
      //constraints = this.createExclusiveAccessValidityConstraints(reward);
    } else {
      constraints = this.stepConstraints[stepId];
    }
    return constraints ? validate(exclusiveAccess, constraints) : undefined;
  };

  validateMultiLangPopup = (fields) => {
    if (fields["blocking.startsWithAccessUrlML"]) {
      return this.validateMultiStartsWithAccessUrlPopup(fields);
    }
    if (fields["blocking.exactAccessUrlML"]) {
      return this.validateMultiExactAccessUrlPopup(fields);
    }

    return;
  };

  validateMultiStartsWithAccessUrlPopup = (fields) => {
    let constraints = {};

    const urlML = fields["blocking.startsWithAccessUrlML"];
    const landingPageUrlML = fields["blocking.landingPageUrlML"];
    const hasValues = _values(urlML).some((v) => !!v) || _values(landingPageUrlML).some((v) => !!v);

    if (hasValues) {
      this.languages.forEach((lang) => {
        if (urlML[lang]) {
          constraints[`blocking\\.landingPageUrlML.${lang}`] = {
            presence: {
              allowEmpty: false,
              message: getMessage("excluseiveAccesssValidator.poolSettingsConstriants.blocking.landingPageUrl.presence")
            },
            url: {
              message: getMessage("excluseiveAccesssValidator.poolSettingsConstriants.blocking.landingPageUrl.url")
            }
          };
        }

        if (landingPageUrlML[lang]) {
          constraints[`blocking\\.startsWithAccessUrlML.${lang}`] = {
            presence: {
              allowEmpty: false,
              message: getMessage(
                "excluseiveAccesssValidator.poolSettingsConstriants.blocking.startsWithAccessUrl.presence"
              )
            },
            url: {
              message: getMessage("excluseiveAccesssValidator.poolSettingsConstriants.blocking.startsWithAccessUrl.url")
            },
            exclusionOrStartWithIgnoreCase: {
              within: this.blockingURLs,
              message: getMessage(
                "excluseiveAccesssValidator.poolSettingsConstriants.blocking.startsWithAccessUrl.exclusionOrStartWithIgnoreCase"
              )
            }
          };
        }
      });
    }

    return validate(fields, constraints);
  };

  validateMultiExactAccessUrlPopup = (fields) => {
    let constraints = {};

    const urlML = fields["blocking.exactAccessUrlML"];

    const hasValues = _values(urlML).some((v) => !!v);

    if (hasValues) {
      this.languages.forEach((lang) => {
        if (urlML[lang]) {
          constraints[`blocking\\.exactAccessUrlML.${lang}`] = {
            presence: {
              allowEmpty: false,
              message: getMessage(
                "excluseiveAccesssValidator.poolSettingsConstriants.blocking.startsWithAccessUrl.presence"
              )
            },
            url: {
              message: getMessage("excluseiveAccesssValidator.poolSettingsConstriants.blocking.startsWithAccessUrl.url")
            },
            exclusionOrStartWithIgnoreCase: {
              within: this.blockingURLs,
              message: getMessage(
                "excluseiveAccesssValidator.poolSettingsConstriants.blocking.startsWithAccessUrl.exclusionOrStartWithIgnoreCase"
              ),
              startsWith: this.startsWithURLs
            }
          };
        }
      });
    }

    return validate(fields, constraints);
  };
}
