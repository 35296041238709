import React, { Component, Fragment } from "react";
import some from "lodash/some";
import every from "lodash/every";
import _values from "lodash/values";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import deepEqual from "deep-equal";
import queryString from "query-string";
import classNames from "classnames";
import xid from "xid-js";
import moment from "moment";

import MultiLangPopup from "../components/MultiLangPopup";

import { updateStateOnInputChange, getInputNameAndValue } from "../helpers/formHelper";
import { updateObjectByNameAndValue, filterObjectByKeys, getObjectByNamePath } from "../helpers/objectHelper";
import { getNavigationCanceled } from "../helpers/navHelper";
import { multiLangUpdateState, initTranslationsObj, setTranslationsOnInputChange } from "../helpers/multiLangHelper";

import FormStepsContainer from "../components/FormStepsContainer";
import Notification from "../components/Notification";

import ExclusiveAccessSetupSettings from "../views/ExclusiveAccessSetupSettings";
import ExclusiveAccessAndOffer from "../views/ExclusiveAccessAndOffer";
import ExclusiveAccessEditor from "../views/ExclusiveAccessEditor";
import ExclusiveAccessValidity from "../views/ExclusiveAccessValidity";
import MultiLangAccessNameField from "../views/RewardSetup/MultiLangAccessNameField";
import MultiLangRedemptionMessageFields from "../views/RewardSetup/MultiLangRedemptionMessageFields";
import MultiLangExactAccessUrlField from "../views/RewardSetup/MultiLangExactAccessUrlField";
import MultiLangStartsWithAccessUrlFields from "../views/RewardSetup/MultiLangStartsWithAccessUrlFields";
import MultiLangExternalBlockLinkFields from "../views/Shared/MultiLangExternalBlockLinkFields";

import { validateSingleValue, validateSingleMultiLangValue } from "../validators";
import exclusiveAccessValidator from "../validators/exclusiveAccessValidator";

import {
  selectTiersMapById,
  selectExclusives,
  selectExclusiveAccessNames,
  selectActions,
  selectTiers,
  selectFixedBenefits,
  selectRewardEditState,
  selectNewExclusiveAccessId,
  selectIsCurrentPlanVersionExists,
  selectCurrentPlanVersion,
  selectCurrentPlanTiersMapById,
  selectPlanLanguages,
  selectPlanPrimaryLanguage,
  selectPlanHasMultiLang,
  selectPlanHasMultiMarket,
  selectPlan,
  selectAdditionalBenefits,
  selectExclusiveAccessById,
  isExclusivePostSuccess,
  isAccountViewOnly
} from "../reducers";

import {
  createExclusiveAccess,
  resetExclusiveAccessEditState,
  resetNewExclusiveAccessId,
  setExclusiveAccessEditState,
  updateExclusiveAccess,
  updateRewardWithExclusiveAccessHtml
} from "../actions/exclusiveAccessActions";

import {
  SPECIAL_TIERS_REDEMPTION_SETTINGS,
  SPECIAL_TIER_ENUM,
  FORM_STEP_ENUM,
  REDEMPTION_VALUE_TOKEN,
  EXCLUSIVE_ACCESS_EXCLUSIVITY_TYPE_OPTIONS,
  EDITOR_TYPE_ENUM,
  EDITOR_STYLES_DEFAULT_VALUES,
  MULTI_LANG_PROPS_MAP,
  VALIDITY_TYPE_ENUM
} from "../constants/rewardConstants";

import {
  EXCLUSIVE_ACCESS_STATUS_ENUM,
  EXCLUSIVE_ACCESS_OFFERS_ENUM,
  EXCLUSIVE_ACCESS_ACTIVATION_OPTIONS,
  EXCLUSIVE_ACCESS_NO_REDEMPTION_FORM_STEPS,
  EXCLUSIVE_ACCESS_FORM_STEPS,
  CREATE_OPTIONS_ENUM,
  MASTER_EXCLUSIVE_ACCESS_ENUM
} from "../constants/exclusiveAccessConstants";

import { openConfirmationDialog } from "../helpers/dialogHelper";
import planService from "../services/planService";
import exclusiveAccessService from "../services/exclusiveAccessService";
import Alert from "../components/Alert";
import { ALERT_STATUS } from "../constants/alertContants";
import { getMessage } from "../messages";

class ExclusiveAccessSetup extends Component {
  constructor(props) {
    super(props);

    this.isStatusActive = false;

    this.isNavigationCanceled = getNavigationCanceled();

    let tiersForAutoPromotion = {
      ...this.props.tiersById,
      ...SPECIAL_TIERS_REDEMPTION_SETTINGS
    };

    this.masterExclusiveAccess = props.editExclusiveAccessId
      ? props.exclusiveAccess.masterExclusiveAccess
      : props.match.params.masterExclusiveAccess;

    this.statusObj = exclusiveAccessService.getExclusiveAccessStatus(
      props.exclusiveAccess,
      props.actions,
      props.fixedBenefits,
      props.additionalBenefits
    );

    let exclusiveAccessDetails = {};
    if (props.dupExclusiveAccessId) {
      exclusiveAccessDetails = this.getDuplicatedData();
    } else if (props.editExclusiveAccessId) {
      exclusiveAccessDetails = this.getEditRewardData(tiersForAutoPromotion);
      this.isStatusActive = this.statusObj.status === EXCLUSIVE_ACCESS_STATUS_ENUM.ACTIVE;
    } else {
      exclusiveAccessDetails = this.getDefaultData(this.masterExclusiveAccess, tiersForAutoPromotion);
    }

    const firstStep = this.getStartStepId(exclusiveAccessDetails);
    this.startStepId = firstStep.id;

    this.editorRef = React.createRef(); //ref to ExclusiveAccessCustomHtmlEditor

    const emptyHtmlContentMsg = this.validateExclusiveAccessEditorStep();

    this.state = {
      exclusiveAccessDetails,
      redirectToOverview: false,
      triggerSubmit: false,
      currentStep: firstStep,
      errors: {},
      tiersListAccordion: this.initTiersListValueSetup(
        exclusiveAccessDetails,
        tiersForAutoPromotion,
        EXCLUSIVE_ACCESS_OFFERS_ENUM.FOR_POINTS
      ),
      tiersListAccordionForFree: this.initTiersListValueSetup(
        exclusiveAccessDetails,
        tiersForAutoPromotion,
        EXCLUSIVE_ACCESS_OFFERS_ENUM.FOR_FREE
      ),
      emptyHtmlContentMsg,
      showMultiLangPopup: false,
      translations: {},
      translationErrors: undefined,
      mlUrlsConfirmShown: false,
      isNewOffer: {
        redemption: false,
        redemptionForFree: false
      }
    };

    this.validator = new exclusiveAccessValidator({
      names: props.rewardNames.filter((name) => name !== exclusiveAccessDetails.name),
      hasMultiMarket: props.hasMultiMarket,
      languages: props.languages,
      blockingURLs: this.getURLs(),
      startsWithURLs: this.getStartWithURLs()
    });

    this.overviewLink = `/exclusive-access/${this.masterExclusiveAccess}`;
  }

  get isMultiLangUrlField() {
    return (
      !!this.state.translations["blocking.exactAccessUrlML"] ||
      !!this.state.translations["blocking.startsWithAccessUrlML"]
    );
  }
  //save the current edited exclusiveAccess in case of back in browser
  componentDidMount() {
    window.onpopstate = () => {
      if (this.shouldBlockNavigation()) {
        this.props.setExclusiveAccessEditState(this.state.exclusiveAccessDetails, this.state.currentStep.id);
      }
    };
  }

  componentWillUnmount() {
    this.props.resetExclusiveAccessEditState();
    this.props.resetNewExclusiveAccessId();
    window.onpopstate = () => {};
  }

  componentDidUpdate(prevProps) {
    //if exclusiveAccess was saved init the rewrad state with the saved exclusiveAccess data
    if (!prevProps.postSuccess && this.props.postSuccess) {
      this.initEditReward();
    }

    if (this.props.postSuccess && this.state.redirectToOverview) {
      this.props.history.push(this.overviewLink);
    }
  }

  getURLs() {
    const { rewards } = this.props;
    const { exclusiveAccessDetails } = this.state;
    let urls = [];

    for (let i = 0; i < rewards.length; i++) {
      const exclusiveAccess = rewards[i];
      if (exclusiveAccess.id === exclusiveAccessDetails.id) continue;
      const url = exclusiveAccess.blocking.isStartsWith
        ? exclusiveAccess.blocking.startsWithAccessUrl
        : exclusiveAccess.blocking.exactAccessUrl;
      if (
        !exclusiveAccessService.isLimitedExclusiveAccess(exclusiveAccess.masterExclusiveAccess) ||
        moment().isBefore(exclusiveAccess.validity.blockingValidity.end)
      ) {
        urls.push(url);
      }
    }

    return urls;
  }

  getStartWithURLs() {
    const { rewards } = this.props;
    const { exclusiveAccessDetails } = this.state;
    let urls = [];

    for (let i = 0; i < rewards.length; i++) {
      const exclusiveAccess = rewards[i];
      if (exclusiveAccess.id === exclusiveAccessDetails.id) continue;
      if (
        exclusiveAccess.blocking.isStartsWith &&
        (!exclusiveAccessService.isLimitedExclusiveAccess(exclusiveAccess.masterExclusiveAccess) ||
          moment().isBefore(exclusiveAccess.validity.blockingValidity.end))
      ) {
        urls.push(exclusiveAccess.blocking.startsWithAccessUrl);
      }
    }

    return urls;
  }

  getStartStepId(exclusiveAccessDetails) {
    let firstStep = this.getFormSteps()[0];
    if (this.isStatusActive) {
      const firstStepId = FORM_STEP_ENUM.EXCLUSIVE_ACCESS_EDITOR;
      firstStep = this.getFormSteps().find((s) => s.id === firstStepId);
    }

    return firstStep;
  }

  getEditRewardData(tiersForAutoPromotion) {
    if (this.isNavigationCanceled) {
      return this.props.rewardEditState.exclusiveAccess;
    } else {
      let exclusiveAccess = cloneDeep(this.props.exclusiveAccess);
      let doUpdate = false;

      if (!exclusiveAccess.icon) {
        exclusiveAccess.icon = exclusiveAccessService.getDefaultIcon();
        doUpdate = true;
      }

      if (this.updateRedemptionTiersState(exclusiveAccess, tiersForAutoPromotion)) {
        doUpdate = true;
      }

      if (!exclusiveAccess.validity.accessValidity.additionalBenefits.accessValidity) {
        exclusiveAccess.validity.accessValidity.additionalBenefits = {
          accessValidityType: "",
          accessValidity: {
            type: "days",
            duration: undefined
          },
          accessDateRange: {
            start: null,
            end: null
          }
        };
        doUpdate = true;
      }

      //update multi lang fields in case customer changed multi lang settings
      if (this.props.hasMultiLanguage) {
        if (!exclusiveAccess.editor.htmlFilesML) {
          exclusiveAccess.editor.htmlFilesML = {};
        }

        this.props.languages.forEach((lang) => {
          if (!exclusiveAccess.editor.htmlFilesML[lang]) {
            exclusiveAccess.editor.htmlFilesML[lang] = {};
            doUpdate = true;
          }
        });

        if (exclusiveAccess.editor.editorType === EDITOR_TYPE_ENUM.TEMPLATE) {
          if (!exclusiveAccess.editor.contentML) {
            exclusiveAccess.editor.contentML = {};
          }
          if (!exclusiveAccess.editor.linksML) {
            exclusiveAccess.editor.linksML = {};
          }

          const content = exclusiveAccessService.getDefaultExclusiveContentText();
          const links = exclusiveAccessService.getDefaultExclusiveContentLinks();
          this.props.languages.forEach((lang) => {
            if (!exclusiveAccess.editor.contentML[lang]) {
              exclusiveAccess.editor.contentML[lang] = { ...content };
              doUpdate = true;
            }
            if (!exclusiveAccess.editor.linksML[lang]) {
              exclusiveAccess.editor.linksML[lang] = { ...links };
              doUpdate = true;
            }
          });
        }
      }

      if (doUpdate) {
        this.saveRewardChanges(exclusiveAccess, exclusiveAccess.id, true);
      }

      return exclusiveAccess;
    }
  }

  initEditReward() {
    const exclusiveAccessDetails = cloneDeep(this.props.exclusiveAccess);
    this.setState({
      exclusiveAccessDetails
    });
  }

  getDuplicatedData() {
    let exclusiveAccess = cloneDeep(this.props.exclusiveAccess);
    exclusiveAccess.name = getMessage("copyOf", { value: exclusiveAccess.name });
    exclusiveAccess.blocking = exclusiveAccessService.getDefaultBlockingData();
    exclusiveAccess.validity.blockingValidity = {
      start: null,
      end: null
    };

    //update the stateObj according the duplicated exclusive access
    this.statusObj = exclusiveAccessService.getExclusiveAccessStatus(
      exclusiveAccess,
      this.props.actions,
      this.props.fixedBenefits,
      this.props.additionalBenefits
    );

    return exclusiveAccess;
  }

  getDefaultData(masterExclusiveAccess, tiersForAutoPromotion) {
    const queryParams = queryString.parse(this.props.location.search);
    const editorType = queryParams.editor;

    const defaultValues = {
      masterExclusiveAccess,
      name: "",
      accessName: "",
      market: "",
      icon: exclusiveAccessService.getDefaultIcon(),
      blocking: exclusiveAccessService.getDefaultBlockingData(),
      redemption: {
        message: {
          title: "",
          text: REDEMPTION_VALUE_TOKEN.token,
          valueToken: REDEMPTION_VALUE_TOKEN.token
        },
        value: this.initPromotionTiersDefaultValues(tiersForAutoPromotion),
        limitValue: undefined,
        offerValidity: {
          start: null,
          end: null
        },
        offerId: xid.next()
      },
      redemptionForFree: {
        message: {
          title: "",
          text: "",
          valueToken: ""
        },
        value: this.initPromotionTiersDefaultValues(tiersForAutoPromotion),
        limitValue: undefined,
        offerValidity: {
          start: null,
          end: null
        },
        offerId: xid.next()
      },
      editor: {
        editorType,
        htmlFiles: exclusiveAccessService.getDefaultHtmlFilesObject()
      },
      validity: {
        accessValidity: {
          action: {
            accessValidityType: "",
            accessValidity: {
              type: "days",
              duration: undefined
            },
            accessDateRange: {
              start: null,
              end: null
            },
            showOnAction: true
          },
          fixedBenefit: {
            accessValidityType: "",
            accessValidity: {
              type: "days",
              duration: undefined
            },
            accessDateRange: {
              start: null,
              end: null
            }
          },
          additionalBenefits: {
            accessValidityType: "",
            accessValidity: {
              type: "days",
              duration: undefined
            },
            accessDateRange: {
              start: null,
              end: null
            }
          },
          redemption: {
            accessValidityType: "",
            accessValidity: {
              type: "days",
              duration: undefined
            },
            accessDateRange: {
              start: null,
              end: null
            }
          },
          redemptionForFree: {
            accessValidityType: "",
            accessValidity: {
              type: "days",
              duration: undefined
            },
            accessDateRange: {
              start: null,
              end: null
            }
          }
        },
        isActive: false,
        blockingValidity: {
          start: null,
          end: null
        }
      }
    };

    if (this.props.hasMultiLanguage) {
      defaultValues.editor.htmlFilesML = {};
      this.props.languages.forEach((lang) => {
        defaultValues.editor.htmlFilesML[lang] = {};
      });
    }

    if (defaultValues.editor.editorType === EDITOR_TYPE_ENUM.TEMPLATE) {
      defaultValues.editor.styles = EDITOR_STYLES_DEFAULT_VALUES;

      const content = exclusiveAccessService.getDefaultExclusiveContentText();
      const links = exclusiveAccessService.getDefaultExclusiveContentLinks();
      defaultValues.editor.content = { ...content };
      defaultValues.editor.links = { ...links };
      if (this.props.hasMultiLanguage) {
        defaultValues.editor.contentML = {};
        defaultValues.editor.linksML = {};
        this.props.languages.forEach((lang) => {
          defaultValues.editor.contentML[lang] = { ...content };
          defaultValues.editor.linksML[lang] = { ...links };
        });
      }
    }

    return defaultValues;
  }

  //update redemption tiers according to tiers set for plan
  updateRedemptionTiersState(exclusiveAccessDetails, tiersForAutoPromotion) {
    let didUpdate = false;
    Object.keys(tiersForAutoPromotion).forEach((tierId) => {
      if (!exclusiveAccessDetails.redemption.value[tierId]) {
        exclusiveAccessDetails.redemption.value[tierId] = {};
        didUpdate = true;
      }
    });

    // delete from action exclusiveAccess old tiers that been deleted
    const redemptionTiers = exclusiveAccessDetails.redemption.value;
    Object.keys(redemptionTiers).forEach((tierId) => {
      if (!tiersForAutoPromotion[tierId]) {
        delete redemptionTiers[tierId];
        didUpdate = true;
      }
    });

    return didUpdate;
  }

  // init tier default values object
  initPromotionTiersDefaultValues(tiersForAutoPromotion, masterExclusiveAccess) {
    const tiersDefaultValues = {};
    Object.keys(tiersForAutoPromotion).forEach((tierId) => {
      tiersDefaultValues[tierId] = {
        pointsEnabled: false,
        points: undefined
      };
    });

    return tiersDefaultValues;
  }

  clearPromotionTiersByALL(tiersValues) {
    const nodifiedTiersValues = {};
    for (const tierId in tiersValues) {
      if (tierId != "all") {
        nodifiedTiersValues[tierId] = { pointsEnabled: false };
      } else {
        nodifiedTiersValues[tierId] = tiersValues[tierId];
      }
    }

    return nodifiedTiersValues;
  }

  initTiersListValueSetup(exclusiveAccessDetails, tiersForAutoPromotion, path) {
    let tiers = {};

    Object.keys(tiersForAutoPromotion).forEach((tierId) => {
      const tier = tiersForAutoPromotion[tierId];

      if (tier.settings && tier.settings.subscr) {
        delete tiers[tierId];
        return;
      }

      let promotion;
      if (!!exclusiveAccessDetails[path].value[tierId]) {
        promotion = cloneDeep(exclusiveAccessDetails[path].value[tierId]);
      } else {
        const tierForPromotion = {};
        tierForPromotion[tierId] = tier;
        const tierPromotion = this.initPromotionTiersDefaultValues(tierForPromotion);
        promotion = tierPromotion[tierId];
      }

      tiers[tierId] = {
        tierId,
        name: tier.name,
        promotion
      };
    });

    //sort tiers
    let sortedTiers = [];
    for (const tierId in tiers) {
      if (tierId != SPECIAL_TIER_ENUM.ALL) {
        sortedTiers.push(tiers[tierId]);
      }
    }

    if (tiers[SPECIAL_TIER_ENUM.ALL]) {
      sortedTiers.push(tiers[SPECIAL_TIER_ENUM.ALL]);
    }

    sortedTiers.forEach((tier, index) => {
      if (tier.promotion.pointsEnabled) {
        sortedTiers[index].accordionOpenState = true;
      }
    });
    //sortedTiers[0].accordionOpenState = true;
    return sortedTiers;
  }

  hasRedemptionValueEnabled() {
    const redemptionValue = this.state.exclusiveAccessDetails.redemption.value;
    return Object.keys(redemptionValue).some((key) => redemptionValue[key].pointsEnabled);
  }

  validate(exclusiveAccessDetails) {
    const stepId = this.state.currentStep.id;
    return this.validator.validateStep(stepId, exclusiveAccessDetails);
  }

  validateExclusiveAccessEditorStep() {
    const isValid = this.editorRef.current && this.editorRef.current.validateHtmlContent();
    if (!isValid) {
      return getMessage("exclusiveSetupPage.errors.editorStep");
    }

    return undefined;
  }

  onStepChange(step) {
    this.setState({
      currentStep: step,
      triggerSubmit: false,
      errors: undefined
    });
  }

  getFormSteps() {
    return EXCLUSIVE_ACCESS_FORM_STEPS;
  }

  validateStep() {
    const { exclusiveAccessDetails } = this.state;
    const errors = this.validate(exclusiveAccessDetails);

    if (this.state.currentStep.id === FORM_STEP_ENUM.EXCLUSIVE_ACCESS_EDITOR) {
      const errorMsg = this.validateExclusiveAccessEditorStep();

      this.setState({
        emptyHtmlContentMsg: errorMsg
      });

      return errorMsg === undefined;
    }

    this.setState({
      errors
    });

    return errors === undefined;
  }

  onInputChange(event, customValue) {
    const exclusiveAccessDetails = { ...this.state.exclusiveAccessDetails };
    const inputName = updateStateOnInputChange({
      stateObj: exclusiveAccessDetails,
      event,
      customValue,
      hasMultiLanguage: this.props.hasMultiLanguage,
      primaryLanguage: this.props.primaryLanguage,
      multiLangPropsMap: MULTI_LANG_PROPS_MAP
    });

    //redemption value change - special validation
    let inputNameValidate = inputName;
    if (inputName.indexOf("redemption.value") === 0 && inputName.endsWith("Enabled")) {
      inputNameValidate = inputName.replace("Enabled", "");
    }

    let errors = { ...this.state.errors };
    if (!inputNameValidate.endsWith(".accessValidityType")) {
      errors = validateSingleValue(() => this.validate(exclusiveAccessDetails), this.state.errors, inputNameValidate);
    }

    this.setState({
      exclusiveAccessDetails,
      errors
    });

    return {
      exclusiveAccessDetails,
      errors
    };
  }

  onClearUrlValues() {
    const exclusiveAccessDetails = { ...this.state.exclusiveAccessDetails };
    exclusiveAccessDetails.blocking.exactAccessUrl = "";
    exclusiveAccessDetails.blocking.startsWithAccessUrl = "";
    exclusiveAccessDetails.blocking.landingPageUrl = "";
    this.setState({
      exclusiveAccessDetails
    });
  }

  onValidityTypeChange(event) {
    const { exclusiveAccessDetails } = this.onInputChange(event);
    let validity, validityType, validityName, expiryDateName, validityDurationName;

    // validity = exclusiveAccessDetails.validity;
    validityType = getObjectByNamePath(exclusiveAccessDetails, event.target.name);

    // validityName = "exclusiveAccessValidity";
    // expiryDateName = "accessExpiryDate";
    // validityDurationName = "accessValidity";
    let targetPath = event.target.name.slice(0, event.target.name.lastIndexOf(".") + 1);
    let errors;
    if (validityType === VALIDITY_TYPE_ENUM.LIMITED_FROM_EARN) {
      updateObjectByNameAndValue(exclusiveAccessDetails, targetPath.concat("accessDateRange"), {
        start: null,
        end: null
      });

      // errors = validateSingleValue(
      //   () => this.validate(exclusiveAccessDetails),
      //   this.state.errors,
      //   `${validityName}.${expiryDateName}`
      // );
    } else if (validityType === VALIDITY_TYPE_ENUM.DATE_RANGE) {
      updateObjectByNameAndValue(exclusiveAccessDetails, targetPath.concat("accessValidity.duration"), undefined);

      //   errors = validateSingleValue(
      //     () => this.validate(exclusiveAccessDetails),
      //     this.state.errors,
      //     `${validityName}.${validityDurationName}.duration`
      //   );
    } else if (validityType === VALIDITY_TYPE_ENUM.UNLIMITED) {
      updateObjectByNameAndValue(exclusiveAccessDetails, targetPath.concat("accessDateRange"), {
        start: null,
        end: null
      });
      updateObjectByNameAndValue(exclusiveAccessDetails, targetPath.concat("accessValidity.duration"), undefined);

      //   errors = validateSingleValue(
      //     () => this.validate(exclusiveAccessDetails),
      //     this.state.errors,
      //     `${validityName}.${expiryDateName}`
      //   );
      //   errors = validateSingleValue(
      //     () => this.validate(exclusiveAccessDetails),
      //     errors,
      //     `${validityName}.${validityDurationName}.duration`
      //   );
    }

    this.setState({
      exclusiveAccessDetails,
      errors
    });
  }

  onExclusiveAccessHtmlChange() {
    const emptyHtmlContentMsg = this.validateExclusiveAccessEditorStep();
    this.setState({
      emptyHtmlContentMsg
    });
  }

  onExclusiveAccessTemplateStyleChange(value) {
    const { exclusiveAccessDetails } = { ...this.state };

    exclusiveAccessDetails.editor.styles = {
      ...exclusiveAccessDetails.editor.styles,
      ...value
    };
    this.setState({
      exclusiveAccessDetails
    });
  }

  onExclusiveAccessTemplateContentChange(propName, value, lang) {
    const { exclusiveAccessDetails } = { ...this.state };

    const multiLangPropsMap = {
      content: "contentML",
      links: "linksML"
    };

    value = cloneDeep(value);

    if (!!lang) {
      exclusiveAccessDetails.editor[multiLangPropsMap[propName]][lang] = value;
    } else {
      exclusiveAccessDetails.editor[propName] = value;
    }

    if (this.props.primaryLanguage === lang) {
      exclusiveAccessDetails.editor[propName] = value;
    }

    this.setState({
      exclusiveAccessDetails
    });
  }

  onRedemptionValueChange(event, customValue, tierId, property) {
    const exclusiveAccessDetails = { ...this.state.exclusiveAccessDetails };
    let errors = { ...this.state.errors };
    const { value, name } = getInputNameAndValue(event);
    const currentPath = event.target.name.split(".")[0];
    let redemptionValue = getObjectByNamePath(exclusiveAccessDetails, currentPath.concat(".value"));
    //let redemptionValue = { ...exclusiveAccessDetails.redemption.value };

    if (property === "points" && !redemptionValue[tierId].points && !!value) {
      redemptionValue[tierId].pointsEnabled = true;
      if (tierId === "all") {
        redemptionValue = this.clearPromotionTiersByALL(redemptionValue);
      }
    }

    if (property == "pointsEnabled") {
      if (event.target.checked === true) {
        if (tierId === "all") {
          redemptionValue = this.clearPromotionTiersByALL(redemptionValue);
        }
      } else {
        delete redemptionValue[tierId].points;
        delete redemptionValue[tierId].pointsEnabled;
      }
    }

    //update the points field value
    updateStateOnInputChange({
      stateObj: exclusiveAccessDetails,
      event,
      customValue
    });

    exclusiveAccessDetails[currentPath].value = redemptionValue;

    const inputNameValidate = name.endsWith(".pointsEnabled") ? name.replace(".pointsEnabled", ".points") : name;
    errors = validateSingleValue(() => this.validate(exclusiveAccessDetails), this.state.errors, inputNameValidate);

    if (!exclusiveAccessService.hasEnabledTier(exclusiveAccessDetails[currentPath]) && errors) {
      errors = undefined;
    }

    this.setState({
      exclusiveAccessDetails,
      errors
    });

    if (tierId === "all") {
      var undefinedTier = false;
      this.setTiersListAccordionRewards(undefinedTier, exclusiveAccessDetails, currentPath);
    } else {
      this.setTiersListAccordionRewards(tierId, exclusiveAccessDetails, currentPath);
    }
  }

  onIconInputChange(icon) {
    let { exclusiveAccessDetails } = { ...this.state };

    exclusiveAccessDetails.icon = icon;
    this.setState({
      exclusiveAccessDetails
    });
  }

  onIsNewOfferChange(event, path) {
    let { isNewOffer } = { ...this.state };
    isNewOffer[path] = event.target.value === CREATE_OPTIONS_ENUM.CREATE;
    this.setState({
      isNewOffer
    });
  }

  setTiersListAccordionRewards(tierId, exclusiveAccessDetails, path) {
    if (tierId) {
      this.setTierListAccordionState(
        tierId,
        "promotion",
        {
          ...exclusiveAccessDetails[path].value[tierId]
        },
        path
      );
    } else {
      if (path != EXCLUSIVE_ACCESS_OFFERS_ENUM.FOR_POINTS) {
        const tiersListAccordionForFree = [...this.state.tiersListAccordionForFree];

        tiersListAccordionForFree.forEach((tier, index) => {
          tiersListAccordionForFree[index].promotion = {
            ...exclusiveAccessDetails[path].value[tier.tierId]
          };
        });
        this.setState({
          tiersListAccordionForFree
        });
      } else {
        const tiersListAccordion = [...this.state.tiersListAccordion];
        tiersListAccordion.forEach((tier, index) => {
          tiersListAccordion[index].promotion = {
            ...exclusiveAccessDetails[path].value[tier.tierId]
          };
        });
        this.setState({
          tiersListAccordion
        });
      }
    }
  }

  updateState(propertyPath, value) {
    const exclusiveAccessDetails = { ...this.state.exclusiveAccessDetails };
    updateObjectByNameAndValue(exclusiveAccessDetails, propertyPath, value);
  }

  setOfferListAccordionOpenState(id, newState) {
    this.updateState(`${id}.accordionOpenState`, newState);
  }

  setTierListAccordionState(tierId, property, value, path) {
    let tierListAccordionName = this.getTierListAccordionName(path);
    const tiersListAccordion = [...this.state[tierListAccordionName]];
    const index = tiersListAccordion.findIndex((tier) => tier.tierId === tierId);
    tiersListAccordion[index][property] = value;
  }

  getTierListAccordionName(path) {
    let tierListAccordionName = "tiersListAccordion";
    if (path != EXCLUSIVE_ACCESS_OFFERS_ENUM.FOR_POINTS) tierListAccordionName = "tiersListAccordionForFree";
    return tierListAccordionName;
  }

  onSubmit() {
    if (this.props.isAccountViewOnly) {
      return;
    }

    //TBD deal with duplicate/create new option
    const { exclusiveAccessDetails } = this.state;
    let exclusiveAccess = { ...this.props.exclusiveAccess, ...exclusiveAccessDetails };
    const exclusiveAccessId = this.props.editExclusiveAccessId;

    if (this.state.currentStep.id === FORM_STEP_ENUM.EXCLUSIVE_ACCESS_EDITOR) {
      const errorMsg = this.validateExclusiveAccessEditorStep();
      if (!!errorMsg) {
        this.setState({
          emptyHtmlContentMsg: errorMsg
        });
        return;
      }

      //Give time for the htmlContent to be updated in the state
      setTimeout(() => {
        this.editorRef.current.saveHtml(exclusiveAccess);
      }, 200);

      return;
    }

    if (this.state.currentStep.id === FORM_STEP_ENUM.REDEMPTION_VALUE) {
      let { isNewOffer } = { ...this.state };
      if (this.state.isNewOffer.redemption.toString() === CREATE_OPTIONS_ENUM.CREATE) {
        exclusiveAccess.redemption.offerId = xid.next();
        isNewOffer.redemption = false;
      }
      if (this.state.isNewOffer.redemptionForFree.toString() === CREATE_OPTIONS_ENUM.CREATE) {
        exclusiveAccess.redemptionForFree.offerId = xid.next();
        isNewOffer.redemptionForFree = false;
      }
      this.setState({
        isNewOffer
      });

      if (exclusiveAccess.validity.accessValidity.action.accessValidityType === VALIDITY_TYPE_ENUM.DISABLED) {
        exclusiveAccess.validity.accessValidity.action.accessValidityType = undefined;
      }
      if (exclusiveAccess.validity.accessValidity.fixedBenefit.accessValidityType === VALIDITY_TYPE_ENUM.DISABLED) {
        exclusiveAccess.validity.accessValidity.fixedBenefit.accessValidityType = undefined;
      }

      if (
        exclusiveAccess.validity.accessValidity.redemption.accessValidityType === VALIDITY_TYPE_ENUM.UNLIMITED ||
        exclusiveAccess.masterExclusiveAccess === MASTER_EXCLUSIVE_ACCESS_ENUM.LIMITED
      ) {
        exclusiveAccess.redemption.limitValue = 1;
      }
      if (
        exclusiveAccess.validity.accessValidity.redemptionForFree.accessValidityType === VALIDITY_TYPE_ENUM.UNLIMITED ||
        exclusiveAccess.masterExclusiveAccess === MASTER_EXCLUSIVE_ACCESS_ENUM.LIMITED
      ) {
        exclusiveAccess.redemptionForFree.limitValue = 1;
      }
    }

    const steps = this.getFormSteps();
    this.saveRewardChanges(exclusiveAccess, exclusiveAccessId);
    if (this.state.currentStep.id === steps[steps.length - 1].id) {
      this.setState({
        redirectToOverview: true
      });
    }
  }

  shouldBlockNavigation() {
    if (this.props.editExclusiveAccessId) {
      let exclusiveAccessDetails = { ...this.state.exclusiveAccessDetails };
      let exclusiveAccess = { ...this.props.exclusiveAccess };
      return !deepEqual(exclusiveAccessDetails, exclusiveAccess);
    }

    return false;
  }

  onCustomConfirmNavigation() {
    this.initEditReward();
  }

  onLangInputChange(event, lang, customValue) {
    let translationErrors;
    const translations = { ...this.state.translations };
    const { name } = getInputNameAndValue(event);

    setTranslationsOnInputChange({ translations, event, lang, customValue });

    const hasValue = _values(translations[name]).some((v) => !!v);
    const validateFunc = this.validator.validateMultiLangPopup;

    if (hasValue) {
      translationErrors = validateSingleMultiLangValue(
        () => validateFunc(translations),
        this.state.translationErrors,
        name,
        lang
      );
    } else {
      translationErrors = validateFunc(translations);
    }

    this.setState({
      translations,
      translationErrors
    });
  }

  showConfirmMultiLang(exclusiveAccessDetails, translations) {
    const { mlUrlsConfirmShown } = this.state;

    if (mlUrlsConfirmShown) {
      return false;
    }

    let show = false;

    const checkMlValues = (propName) => {
      const mlMap = translations[propName];
      const atLeastOne = some(mlMap, (value) => !!value && value !== "http://");
      const all = every(this.props.languages, (lang) => !!mlMap[lang] && mlMap[lang] !== "http://");

      if (atLeastOne && !all) {
        return true;
      }

      return false;
    };

    if (!!translations["blocking.exactAccessUrlML"]) {
      show = checkMlValues("blocking.exactAccessUrlML");
    } else if (!!translations["blocking.startsWithAccessUrlML"]) {
      show = checkMlValues("blocking.startsWithAccessUrlML");
      if (!show) {
        show = checkMlValues("blocking.landingPageUrlML");
      }
    }

    if (show) {
      openConfirmationDialog({
        headline: getMessage("exclusiveSetupPage.confirmMultilang.headline"),
        content: getMessage("exclusiveSetupPage.confirmMultilang.content"),
        confirmFunc: () => {
          this.setState({
            exclusiveAccessDetails,
            showMultiLangPopup: false
          });
        }
      });

      this.setState({ mlUrlsConfirmShown: (prevState) => !prevState.mlUrlsConfirmShown });
      return true;
    }

    return false;
  }

  onMultiLangPopupSave() {
    let { exclusiveAccessDetails } = { ...this.state };
    const translations = { ...this.state.translations };
    const translationErrors = this.validator.validateMultiLangPopup(translations);

    if (!translationErrors) {
      multiLangUpdateState({
        stateObj: exclusiveAccessDetails,
        translations,
        propsMap: MULTI_LANG_PROPS_MAP,
        primaryLanguage: this.props.primaryLanguage
      });

      if (this.showConfirmMultiLang(exclusiveAccessDetails, translations)) {
        return;
      }

      this.setState({
        exclusiveAccessDetails,
        showMultiLangPopup: false
      });
    }

    this.setState({
      translationErrors
    });
  }

  onShowMultiLangPopup(propList) {
    let { exclusiveAccessDetails } = { ...this.state };
    const propsMap = filterObjectByKeys(MULTI_LANG_PROPS_MAP, propList);
    const translations = initTranslationsObj({
      stateObj: exclusiveAccessDetails,
      propsMap,
      primaryLanguage: this.props.primaryLanguage
    });
    this.setState({
      translations,
      translationErrors: undefined,
      showMultiLangPopup: true
    });
  }

  getMultiLangPopupFields() {
    const translations = { ...this.state.translations };

    if (!!translations.accessNameML) {
      return <MultiLangAccessNameField disabled={this.props.isAccountViewOnly} />;
    } else if (!!translations["redemption.message.titleML"]) {
      return (
        <MultiLangRedemptionMessageFields
          disabled={this.props.isAccountViewOnly}
          masterReward={this.masterExclusiveAccess}
          path={"redemption"}
        />
      );
    } else if (!!translations["redemptionForFree.message.titleML"]) {
      return (
        <MultiLangRedemptionMessageFields
          disabled={this.props.isAccountViewOnly}
          masterReward={this.masterExclusiveAccess}
          path={"redemptionForFree"}
        />
      );
    } else if (!!translations["blocking.exactAccessUrlML"]) {
      return (
        <MultiLangExactAccessUrlField
          disabled={this.isStatusActive || this.props.isAccountViewOnly}
          errors={this.state.translationErrors}
        />
      );
    } else if (!!translations["blocking.startsWithAccessUrlML"]) {
      return (
        <MultiLangStartsWithAccessUrlFields
          disabled={this.isStatusActive || this.props.isAccountViewOnly}
          errors={this.state.translationErrors}
        />
      );
    } else if (!!translations["blockExternalLink.urlML"]) {
      return (
        <MultiLangExternalBlockLinkFields
          disabled={this.isStatusActive || this.props.isAccountViewOnly}
          errors={this.state.translationErrors}
        />
      );
    }

    return null;
  }

  getNotificationMessage() {
    return <Notification content={getMessage("exclusiveSetupPage.exclusiveAccessAndOffer.notification")} />;
  }

  renderFormStepsComponents(exclusiveAccess) {
    const masterExclusiveAccess = this.masterExclusiveAccess;
    const exclusiveAccessForStatus = this.props.exclusiveAccess;
    let result = [];

    const steps = this.getFormSteps(exclusiveAccess);
    steps.forEach((step) => {
      if (step.id === FORM_STEP_ENUM.REDEMPTION_VALUE || step.id === FORM_STEP_ENUM.VOUCHERS_FOR_POINTS)
        result.push(
          <ExclusiveAccessAndOffer
            key={step.id}
            exclusiveAccess={exclusiveAccess}
            onRedemptionValueChange={this.onRedemptionValueChange.bind(this)}
            tiers={this.state.tiersListAccordion}
            tiersForFree={this.state.tiersListAccordionForFree}
            hasRedemptionValueEnabled={this.hasRedemptionValueEnabled()}
            onAccordionStateChange={this.setOfferListAccordionOpenState.bind(this)}
            onShowRedemptionMessageMultiLang={(path) =>
              this.onShowMultiLangPopup([`${path}.message.titleML`, `${path}.message.textML`])
            }
            notificationElem={this.getNotificationMessage()}
            hasMultiLanguage={this.props.hasMultiLanguage}
            errors={this.state.errors}
            onInputChange={this.onInputChange.bind(this)}
            onValidityTypeChange={this.onValidityTypeChange.bind(this)}
            updateState={this.updateState.bind(this)}
            exclusiveAccessStatus={this.statusObj.status}
            isNewOffer={this.state.isNewOffer}
            isNewOfferChange={this.onIsNewOfferChange.bind(this)}
            isPointsPlan={this.props.planHasRedemption}
            connectedFixedBenefitNames={this.statusObj.connectedFixedBenefitNames}
            connectedActionNames={this.statusObj.connectedActionNames}
            connectedAdditionalBenefitsNames={this.statusObj.connectedAdditionalBenefitsNames}
            exclusiveAccessForStatus={exclusiveAccessForStatus}
            isViewOnly={this.props.isAccountViewOnly}
          />
        );
      if (step.id === FORM_STEP_ENUM.EXCLUSIVE_ACCESS_POOL_SETTINGS)
        result.push(
          <ExclusiveAccessSetupSettings
            key={`${step.id}_${masterExclusiveAccess}`}
            exclusiveAccess={exclusiveAccess}
            onInputChange={this.onInputChange.bind(this)}
            onIconInputChange={this.onIconInputChange.bind(this)}
            onShowAccessNameMultiLang={() => this.onShowMultiLangPopup(["accessNameML"])}
            onShowExactAccessUrlMultiLang={() => this.onShowMultiLangPopup(["blocking.exactAccessUrlML"])}
            onShowStartsWithAccessUrlMultiLang={() =>
              this.onShowMultiLangPopup(["blocking.startsWithAccessUrlML", "blocking.landingPageUrlML"])
            }
            errors={this.state.errors}
            isStatusActive={this.isStatusActive}
            isViewOnly={this.props.isAccountViewOnly}
            hasMultiLanguage={this.props.hasMultiLanguage}
            status={this.statusObj.status}
            onClearUrlValues={this.onClearUrlValues.bind(this)}
          />
        );
      if (step.id === FORM_STEP_ENUM.EXCLUSIVE_ACCESS_EDITOR)
        result.push(
          <ExclusiveAccessEditor
            key={step.id}
            ref={this.editorRef}
            exclusiveAccessId={exclusiveAccess.id}
            editorType={exclusiveAccess.editor.editorType}
            htmlFiles={exclusiveAccess.editor.htmlFiles}
            initialEditorStyles={exclusiveAccess.editor.styles}
            initialEditorContent={exclusiveAccess.editor.content}
            initialEditorContentML={exclusiveAccess.editor.contentML}
            initialEditorLinks={exclusiveAccess.editor.links}
            initialEditorLinksML={exclusiveAccess.editor.linksML}
            postHtmlFunc={this.props.updateRewardWithExclusiveAccessHtml}
            onHtmlChange={this.onExclusiveAccessHtmlChange.bind(this)}
            onTemplateStyleChange={this.onExclusiveAccessTemplateStyleChange.bind(this)}
            onTemplateContentChange={this.onExclusiveAccessTemplateContentChange.bind(this)}
            hasMultiLanguage={this.props.hasMultiLanguage}
            languages={this.props.languages}
            primaryLanguage={this.props.primaryLanguage}
            isViewOnly={this.props.isAccountViewOnly}
          />
        );

      if (step.id === FORM_STEP_ENUM.EXCLUSIVE_ACCESS_VALIDITY)
        result.push(
          <ExclusiveAccessValidity
            key={step.id}
            exclusiveAccess={exclusiveAccess}
            exclusiveAccessActivationOptions={EXCLUSIVE_ACCESS_ACTIVATION_OPTIONS}
            onInputChange={this.onInputChange.bind(this)}
            isViewOnly={this.props.isAccountViewOnly}
          />
        );
    });

    return result;
  }

  saveRewardChanges(exclusiveAccess, id, disableAlert) {
    id
      ? this.props.updateExclusiveAccess(exclusiveAccess, id, disableAlert)
      : this.props.createExclusiveAccess(exclusiveAccess, false, this.props.dupExclusiveAccessId);
  }

  render() {
    const exclusiveAccess = this.state.exclusiveAccessDetails;

    if (!exclusiveAccess) {
      return null;
    }

    let title;
    if (this.props.isNewReward) {
      title = getMessage("exclusiveSetupPage.title.new", {
        value: this.masterExclusiveAccess.charAt(0).toUpperCase() + this.masterExclusiveAccess.slice(1)
      });
    } else {
      title = `${this.props.exclusiveAccess.name}`;
    }

    const formClassName = classNames("exclusiveAccess-setup-form", {
      "l-layout-fullheight e-layout-with_steps": this.state.currentStep.id === FORM_STEP_ENUM.EXCLUSIVE_ACCESS_EDITOR
    });

    const multiLangPopupTitle = this.isMultiLangUrlField ? getMessage("exclusiveSetupPage.multiLangPopupTitle") : "";

    const nextStepOnSubmit = this.state.currentStep.id !== FORM_STEP_ENUM.EXCLUSIVE_ACCESS_EDITOR;

    return (
      <Fragment>
        <FormStepsContainer
          id={this.props.editExclusiveAccessId}
          title={title}
          steps={this.getFormSteps()}
          onSubmit={this.onSubmit.bind(this)}
          onStepChange={this.onStepChange.bind(this)}
          nextStepOnSubmit={nextStepOnSubmit} //{true} //
          applySubmitOnNextStep={true}
          overviewLink={this.overviewLink}
          className={formClassName}
          triggerSubmit={this.state.triggerSubmit}
          validateStepFunc={this.validateStep.bind(this)}
          shouldBlockNavigationFunc={this.shouldBlockNavigation.bind(this)}
          onCustomConfirmNavigation={this.onCustomConfirmNavigation.bind(this)}
          startStepId={this.startStepId}
          disableSubmitButton={this.isAccountViewOnly}
          hasErrors={
            !!this.state.errors ||
            (!!this.state.emptyHtmlContentMsg && this.state.currentStep.id === FORM_STEP_ENUM.EXCLUSIVE_ACCESS_EDITOR)
          }
          customErrorAlertMessage={
            this.state.currentStep.id === FORM_STEP_ENUM.EXCLUSIVE_ACCESS_EDITOR ? this.state.emptyHtmlContentMsg : ""
          }
          alertElement={
            this.statusObj.status === EXCLUSIVE_ACCESS_STATUS_ENUM.ACTIVE && (
              <Alert
                status={ALERT_STATUS.WARNING}
                isMultiline={true}
                message={getMessage("exclusiveSetupPage.activeEditAlert")}
              />
            )
          }
        >
          {this.renderFormStepsComponents(exclusiveAccess)}
        </FormStepsContainer>
        <MultiLangPopup
          id={"reward_setup_multi_lang"}
          show={this.state.showMultiLangPopup}
          languages={this.props.languages}
          translations={this.state.translations}
          hasErrors={!!this.state.translationErrors}
          element={this.getMultiLangPopupFields()}
          showNoticeForDefault={!this.isMultiLangUrlField}
          onChange={this.onLangInputChange.bind(this)}
          onSave={this.onMultiLangPopupSave.bind(this)}
          disableSave={this.props.isAccountViewOnly}
          onClose={() => {
            this.setState({
              showMultiLangPopup: false
            });
          }}
          title={multiLangPopupTitle}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const newExclusiveAccessId = selectNewExclusiveAccessId(state);
  const editExclusiveAccessId = ownProps.match.params.id || newExclusiveAccessId;
  const dupExclusiveAccessId = ownProps.match.params.dupId;

  const exclusiveAccessId = editExclusiveAccessId || dupExclusiveAccessId;

  const isCurrentPlanExists = selectIsCurrentPlanVersionExists(state);
  const plan = isCurrentPlanExists ? selectCurrentPlanVersion(state) : selectPlan(state);

  return {
    planHasRedemption: planService.hasRedemption(plan),
    tiersById: isCurrentPlanExists ? selectCurrentPlanTiersMapById(state) : selectTiersMapById(state),
    tiers: selectTiers(state),
    exclusiveAccess: exclusiveAccessId ? selectExclusiveAccessById(state, exclusiveAccessId) : {},
    editExclusiveAccessId,
    dupExclusiveAccessId,
    newExclusiveAccessId,
    isNewReward: !ownProps.match.params.id,
    postSuccess: isExclusivePostSuccess(state),
    rewardNames: selectExclusiveAccessNames(state),
    rewards: selectExclusives(state),
    actions: selectActions(state),
    fixedBenefits: selectFixedBenefits(state),
    additionalBenefits: selectAdditionalBenefits(state),
    rewardEditState: selectRewardEditState(state),
    languages: selectPlanLanguages(state),
    primaryLanguage: selectPlanPrimaryLanguage(state),
    hasMultiLanguage: selectPlanHasMultiLang(state),
    hasMultiMarket: selectPlanHasMultiMarket(state),
    plan,
    isAccountViewOnly: isAccountViewOnly(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createExclusiveAccess: (exclusiveAccess, disableAlert, duplicateFromId) => {
      dispatch(createExclusiveAccess(exclusiveAccess, disableAlert, duplicateFromId));
    },
    updateExclusiveAccess: (exclusiveAccess, id, disableAlert) => {
      dispatch(updateExclusiveAccess(exclusiveAccess, id, disableAlert));
    },
    setExclusiveAccessEditState: (exclusiveAccess, stepId) => {
      dispatch(setExclusiveAccessEditState(exclusiveAccess, stepId));
    },
    resetExclusiveAccessEditState: () => {
      dispatch(resetExclusiveAccessEditState());
    },
    resetNewExclusiveAccessId: () => {
      dispatch(resetNewExclusiveAccessId());
    },
    updateRewardWithExclusiveAccessHtml: ({ exclusiveAccess, htmlContent, htmlContentML, specificMode }) => {
      dispatch(updateRewardWithExclusiveAccessHtml({ exclusiveAccess, htmlContent, htmlContentML, specificMode }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExclusiveAccessSetup);
