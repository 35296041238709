import * as TYPES from "./TYPES";
import exclusiveAccessApi from "../api/exclusiveAccessApi";
import { invalidateJwt } from "./sessionActions";

export const getAllExclusives = () => {
  return (dispatch) => {
    dispatch({
      type: TYPES.GET_ALL_EXCLUSIVES_REQUEST
    });
    return exclusiveAccessApi
      .getAllExclusives()
      .then((data) => {
        dispatch({
          type: TYPES.GET_ALL_EXCLUSIVES_SUCCESS,
          payload: data
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.GET_ALL_EXCLUSIVES_FAILURE
        });
      });
  };
};

export const updateExclusiveAccess = (exclusiveAccess, id, disableAlert) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.POST_EXCLUSIVE_ACCESS_REQUEST
    });

    return exclusiveAccessApi
      .updateExclusiveAccess(exclusiveAccess, id)
      .then((data) => {
        dispatch({
          type: TYPES.POST_EXCLUSIVE_ACCESS_SUCCESS,
          payload: {
            data,
            isNew: false,
            disableAlert,
            id
          }
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.POST_EXCLUSIVE_ACCESS_FAILURE,
          payload: {
            isNew: false
          }
        });
      });
  };
};

export const createExclusiveAccess = (exclusiveAccess, disableAlert, duplicateFromId) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.POST_EXCLUSIVE_ACCESS_REQUEST
    });

    return exclusiveAccessApi
      .createExclusiveAccess(exclusiveAccess, duplicateFromId)
      .then((data) => {
        dispatch({
          type: TYPES.POST_EXCLUSIVE_ACCESS_SUCCESS,
          payload: {
            data,
            isNew: true,
            disableAlert
          }
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.POST_EXCLUSIVE_ACCESS_FAILURE,
          payload: {
            isNew: true
          }
        });
      });
  };
};

export const deleteExclusiveAccess = (id) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.DELETE_EXCLUSIVE_ACCESS_REQUEST
    });

    return exclusiveAccessApi
      .deleteExclusiveAccess(id)
      .then(() =>
        dispatch({
          type: TYPES.DELETE_EXCLUSIVE_ACCESS_SUCCESS,
          payload: {
            id
          }
        })
      )
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.DELETE_EXCLUSIVE_ACCESS_FAILURE
        });
      });
  };
};

export const setExclusiveAccessEditState = (exclusiveAccess, stepId) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.SET_EXCLUSIVE_ACCESS_EDIT_STATE,
      payload: {
        exclusiveAccess,
        stepId
      }
    });
  };
};

export const resetExclusiveAccessEditState = () => {
  return function (dispatch) {
    dispatch({
      type: TYPES.RESET_EXCLUSIVE_ACCESS_EDIT_STATE
    });
  };
};

export const resetNewExclusiveAccessId = () => {
  return function (dispatch) {
    dispatch({
      type: TYPES.RESET_NEW_EXCLUSIVE_ACCESS_ID
    });
  };
};

export const updateRewardWithExclusiveAccessHtml = ({ exclusiveAccess, htmlContent, htmlContentML, specificMode }) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.POST_EXCLUSIVE_ACCESS_REQUEST
    });

    let promises = createUpdateHtmlPromises({ exclusiveAccessId: exclusiveAccess.id, htmlContent, specificMode });
    if (!!htmlContentML) {
      Object.keys(htmlContentML).forEach((lang) => {
        const promisesML = createUpdateHtmlPromises({
          exclusiveAccessId: exclusiveAccess.id,
          htmlContent: htmlContentML[lang],
          specificMode,
          lang
        });

        promises = promises.concat(promisesML);
      });
    }

    Promise.all(promises)
      .then(() => {
        exclusiveAccess.editor.htmlFilesML = null; //do not override the htmlFilesML is server
        return exclusiveAccessApi.updateExclusiveAccess(exclusiveAccess, exclusiveAccess.id);
      })
      .then((data) => {
        dispatch({
          type: TYPES.POST_EXCLUSIVE_ACCESS_SUCCESS,
          payload: {
            data,
            isNew: false,
            disableAlert: false
          }
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.POST_EXCLUSIVE_ACCESS_FAILURE
        });
      });
  };
};

const createUpdateHtmlPromises = ({ exclusiveAccessId, htmlContent, specificMode, lang }) => {
  let promises = Object.keys(htmlContent)
    .filter((mode) => {
      if (!specificMode) {
        return true;
      }
      return specificMode === mode;
    })
    .map((mode) => {
      if (htmlContent[mode]) {
        return exclusiveAccessApi.postExclusiveAccessHtml({
          exclusiveAccessId,
          html: htmlContent[mode],
          mode,
          lang
        });
      }
    });

  return promises;
};
