import React, { Fragment, useState, useEffect } from "react";
import { getMessage } from "../messages";
import SelectField from "./SelectFieldV2";

const MonthDayField = (props) => {
  const MonthsOptions = [
    {
      value: 1,
      message: "months.1"
    },
    {
      value: 2,
      message: "months.2"
    },
    {
      value: 3,
      message: "months.3"
    },
    {
      value: 4,
      message: "months.4"
    },
    {
      value: 5,
      message: "months.5"
    },
    {
      value: 6,
      message: "months.6"
    },
    {
      value: 7,
      message: "months.7"
    },
    {
      value: 8,
      message: "months.8"
    },
    {
      value: 9,
      message: "months.9"
    },
    {
      value: 10,
      message: "months.10"
    },
    {
      value: 11,
      message: "months.11"
    },
    {
      value: 12,
      message: "months.12"
    }
  ];

  const [isEnabled, setIsEnabled] = useState(props.isMandatory || !!props.value.month);
  const [showDay, SetShowDay] = useState(!!props.value.month);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!!props.value.month) {
      let newOptions = CreateDayOptions(props.value.month);
      setOptions(newOptions);
    } else {
      let newOptions = CreateDayOptions(1);
      setOptions(newOptions);
    }
  }, [props.value.month]);

  const OnMonthChange = (event) => {
    var maxDay = new Date(2021, event.target.value, 0).getDate();
    var currDay = !!props.value.day ? (currDay > maxDay ? maxDay : props.value.day) : 1;
    props.onChange(event, { month: Number(event.target.value), day: currDay }, "planSettings.rules.cashback.resetAt");
    SetShowDay(true);
  };

  const OnDayChange = (event) => {
    let currMonth = !!props.value.month ? props.value.month : 1;
    props.onChange(event, { month: currMonth, day: Number(event.target.value) }, "planSettings.rules.cashback.resetAt");
  };
  function CreateDayOptions(month) {
    var days = new Date(2021, month, 0).getDate();
    const newDays = [];
    for (var d = 1; d <= days; d++) {
      var dayElem = { value: d, name: d };
      newDays.push(dayElem);
    }
    return newDays;
  }

  const onDisableMonthDate = (event) => {
    setIsEnabled(!isEnabled);
    if (isEnabled) {
      props.onChange(event, { month: null, day: null }, "planSettings.rules.cashback.resetAt");
    }
  };

  const daysOptions = [];

  return (
    <Fragment>
      <div>
        <div className="e-row e-field">
          {!props.isMandatory && (
            <input
              id={`${props.Name}_cb`}
              className="e-checkbox"
              type="checkbox"
              checked={isEnabled}
              onChange={onDisableMonthDate}
              disabled={props.disabled}
            />
          )}
          <label htmlFor={`${props.Name}_cb`}>
            {props.label}
            {props.tooltip ? <e-tooltip content={props.tooltip} type="helper" /> : null}
          </label>
        </div>
        {(isEnabled || props.isMandatory) && (
          <div className="e-row e-grid e-col-5 e-grid-between">
            <div className="e-cell">
              <SelectField
                size="large"
                label={getMessage("month")}
                name={`${props.name}_Month`}
                options={MonthsOptions}
                value={props.value.month}
                onChange={OnMonthChange}
                disabled={props.disabled}
              />
            </div>

            {
              <div className="e-cell">
                <SelectField
                  size="small"
                  label={getMessage("dayInMonth")}
                  name={`${props.name}_Day`}
                  options={options}
                  value={props.value.day}
                  onChange={OnDayChange}
                  disabled={props.disabled}
                />
              </div>
            }
          </div>
        )}
        {props.errorMsg ? <span className="e-field__message e-field__message-error">{props.errorMsg}</span> : null}
      </div>
    </Fragment>
  );
};

export default MonthDayField;
