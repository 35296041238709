import React from "react";
import PropTypes from "prop-types";

import SelectField from "../../components/SelectFieldV2";
import Notification from "../../components/Notification";

import { VISIBILITY_OPTIONS } from "../../constants/tierConstants";

import { tierType } from "../../types";
import tierService from "../../services/tierService";
import { getMessage } from "../../messages";

const TierSetupVisibility = ({ tier, onInputChange, disabled, isFirstTier }) => {
  const isDefaultInviteTier = isFirstTier && tierService.isInviteTier(tier);

  return (
    <div className="e-box">
      <div className="e-row">
        <div className="e-col-6">
          <h2>{getMessage("tierSetupVisibility.title")}</h2>
          <Notification content={getMessage("tierSetupVisibility.tooltip")} />
          <SelectField
            label={getMessage("tierSetupVisibility.visible.label")}
            type="bool"
            name="visible"
            value={tier.visible}
            options={VISIBILITY_OPTIONS}
            onChange={onInputChange}
            disabled={disabled || isDefaultInviteTier}
          />
        </div>
      </div>
    </div>
  );
};

TierSetupVisibility.propTypes = {
  tier: tierType.isRequired,
  onInputChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isFirstTier: PropTypes.bool
};

export default TierSetupVisibility;
