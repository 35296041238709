//customer
export const GET_CUSTOMER_REQUEST = "GET_CUSTOMER_REQUEST";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAILURE = "GET_CUSTOMER_FAILURE";
export const UPDATE_PLAN_DISTRIBUTION_REQUEST = "UPDATE_PLAN_DISTRIBUTION_REQUEST";
export const UPDATE_PLAN_DISTRIBUTION_SUCCESS = "UPDATE_PLAN_DISTRIBUTION_SUCCESS";
export const UPDATE_PLAN_DISTRIBUTION_FAILURE = "UPDATE_PLAN_DISTRIBUTION_FAILURE";
export const ACTIVATE_PLAN_REQUEST = "ACTIVATE_PLAN_REQUEST";
export const ACTIVATE_PLAN_SUCCESS = "ACTIVATE_PLAN_SUCCESS";
export const ACTIVATE_PLAN_FAILURE = "ACTIVATE_PLAN_FAILURE";
export const GET_EMS_CONFIG_REQUEST = "GET_EMS_CONFIG_REQUEST";
export const GET_EMS_CONFIG_SUCCESS = "GET_EMS_CONFIG_SUCCESS";
export const GET_EMS_CONFIG_FAILURE = "GET_EMS_CONFIG_FAILURE";
export const GET_EMS_ACCOUNT_PERMISSIONS_REQUEST = "GET_EMS_ACCOUNT_PERMISSIONS_REQUEST";
export const GET_EMS_ACCOUNT_PERMISSIONS_SUCCESS = "GET_EMS_ACCOUNT_PERMISSIONS_SUCCESS";
export const GET_EMS_ACCOUNT_PERMISSIONS_FAILURE = "GET_EMS_ACCOUNT_PERMISSIONS_FAILURE";

//plan
export const GET_PLAN_REQUEST = "GET_PLAN_REQUEST";
export const GET_PLAN_SUCCESS = "GET_PLAN_SUCCESS";
export const GET_PLAN_FAILURE = "GET_PLAN_FAILURE";
export const UPDATE_ACCOUNT_SETTINGS_PLANS_REQUEST = "UPDATE_ACCOUNT_SETTINGS_PLANS_REQUEST";
export const UPDATE_ACCOUNT_SETTINGS_PLANS_SUCCESS = "UPDATE_ACCOUNT_SETTINGS_PLANS_SUCCESS";
export const UPDATE_ACCOUNT_SETTINGS_PLANS_FAILURE = "UPDATE_ACCOUNT_SETTINGS_PLANS_FAILURE";
export const DELETE_PLAN_REQUEST = "DELETE_PLAN_REQUEST";
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS";
export const DELETE_PLAN_FAILURE = "DELETE_PLAN_FAILURE";
export const UPDATE_PLAN_REQUEST = "UPDATE_PLAN_REQUEST";
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const UPDATE_PLAN_FAILURE = "UPDATE_PLAN_FAILURE";
export const LAUNCH_PLAN_REQUEST = "LAUNCH_PLAN_REQUEST";
export const LAUNCH_PLAN_SUCCESS = "LAUNCH_PLAN_SUCCESS";
export const LAUNCH_PLAN_FAILURE = "LAUNCH_PLAN_FAILURE";
export const UPDATE_PLAN_LOCALE_REQUEST = "UPDATE_PLAN_LOCALE_REQUEST";
export const UPDATE_PLAN_LOCALE_SUCCESS = "UPDATE_PLAN_LOCALE_SUCCESS";
export const UPDATE_PLAN_LOCALE_FAILURE = "UPDATE_PLAN_LOCALE_FAILURE";

//tiers
export const GET_ALL_TIERS_REQUEST = "GET_ALL_TIERS_REQUEST";
export const GET_ALL_TIERS_SUCCESS = "GET_ALL_TIERS_SUCCESS";
export const GET_ALL_TIERS_FAILURE = "GET_ALL_TIERS_FAILED";
export const POST_TIER_REQUEST = "POST_TIER_REQUEST";
export const POST_TIER_SUCCESS = "POST_TIER_SUCCESS";
export const POST_TIER_FAILURE = "POST_TIER_FAILURE";
export const DELETE_TIER_REQUEST = "DELETE_TIER_REQUEST";
export const DELETE_TIER_SUCCESS = "DELETE_TIER_SUCCESS";
export const DELETE_TIER_FAILURE = "DELETE_TIER_FAILURE";
export const SET_TIER_EDIT_STATE = "SET_TIER_EDIT_STATE";
export const RESET_TIER_EDIT_STATE = "RESET_TIER_EDIT_STATE";
export const RESET_NEW_TIER_ID = "RESET_NEW_TIER_ID";

//actions
export const GET_ALL_ACTIONS_REQUEST = "GET_ALL_ACTIONS_REQUEST";
export const GET_ALL_ACTIONS_SUCCESS = "GET_ALL_ACTIONS_SUCCESS";
export const GET_ALL_ACTIONS_FAILURE = "GET_ALL_ACTIONS_FAILURE";
export const POST_ACTION_REQUEST = "POST_ACTION_REQUEST";
export const POST_ACTION_SUCCESS = "POST_ACTION_SUCCESS";
export const POST_ACTION_FAILURE = "POST_ACTION_FAILURE";
export const DELETE_ACTION_REQUEST = "DELETE_ACTION_REQUEST";
export const DELETE_ACTION_SUCCESS = "DELETE_ACTION_SUCCESS";
export const DELETE_ACTION_FAILURE = "DELETE_ACTION_FAILURE";
export const SET_ACTION_EDIT_STATE = "SET_ACTION_EDIT_STATE";
export const RESET_ACTION_EDIT_STATE = "RESET_ACTION_EDIT_STATE";
export const RESET_NEW_ACTION_ID = "RESET_NEW_ACTION_ID";

//rewards
export const GET_ALL_REWARDS_REQUEST = "GET_ALL_REWARDS_REQUEST";
export const GET_ALL_REWARDS_SUCCESS = "GET_ALL_REWARDS_SUCCESS";
export const GET_ALL_REWARDS_FAILURE = "GET_ALL_REWARDS_FAILURE";
export const POST_REWARD_REQUEST = "POST_REWARD_REQUEST";
export const POST_REWARD_SUCCESS = "POST_REWARD_SUCCESS";
export const POST_REWARD_FAILURE = "POST_REWARD_FAILURE";
export const DELETE_REWARD_REQUEST = "DELETE_REWARD_REQUEST";
export const DELETE_REWARD_SUCCESS = "DELETE_REWARD_SUCCESS";
export const DELETE_REWARD_FAILURE = "DELETE_REWARD_FAILURE";
export const SET_REWARD_EDIT_STATE = "SET_REWARD_EDIT_STATE";
export const RESET_REWARD_EDIT_STATE = "RESET_REWARD_EDIT_STATE";
export const RESET_NEW_REWARD_ID = "RESET_NEW_REWARD_ID";
export const SWAP_REWARD_SUCCESS = "SWAP_REWARD_SUCCCESS";

export const ADD_UPLOAD_TO_REWARD_POOL_REQUEST = "ADD_UPLOAD_TO_REWARD_POOL_REQUEST";
export const ADD_UPLOAD_TO_REWARD_POOL_SUCCESS = "ADD_UPLOAD_TO_REWARD_POOL_SUCCESS";
export const ADD_UPLOAD_TO_REWARD_POOL_FAILURE = "ADD_UPLOAD_TO_REWARD_POOL_FAILURE";
export const DELETE_REWARD_POOL_REQUEST = "DELETE_REWARD_POOL_REQUEST";
export const DELETE_REWARD_POOL_SUCCESS = "DELETE_REWARD_POOL_SUCCESS";
export const DELETE_REWARD_POOL_FAILURE = "DELETE_REWARD_POOL_FAILURE";

//exclusive access
export const GET_ALL_EXCLUSIVES_REQUEST = "GET_ALL_EXCLUSIVES_REQUEST";
export const GET_ALL_EXCLUSIVES_SUCCESS = "GET_ALL_EXCLUSIVES_SUCCESS";
export const GET_ALL_EXCLUSIVES_FAILURE = "GET_ALL_EXCLUSIVES_FAILURE";
export const POST_EXCLUSIVE_ACCESS_REQUEST = "POST_EXCLUSIVE_ACCESS_REQUEST";
export const POST_EXCLUSIVE_ACCESS_SUCCESS = "POST_EXCLUSIVE_ACCESS_SUCCESS";
export const POST_EXCLUSIVE_ACCESS_FAILURE = "POST_EXCLUSIVE_ACCESS_FAILURE";
export const DELETE_EXCLUSIVE_ACCESS_REQUEST = "DELETE_EXCLUSIVE_ACCESS_REQUEST";
export const DELETE_EXCLUSIVE_ACCESS_SUCCESS = "DELETE_EXCLUSIVE_ACCESS_SUCCESS";
export const DELETE_EXCLUSIVE_ACCESS_FAILURE = "DELETE_EXCLUSIVE_ACCESS_FAILURE";
export const SET_EXCLUSIVE_ACCESS_EDIT_STATE = "SET_EXCLUSIVE_ACCESS_EDIT_STATE";
export const RESET_EXCLUSIVE_ACCESS_EDIT_STATE = "RESET_EXCLUSIVE_ACCESS_EDIT_STATE";
export const RESET_NEW_EXCLUSIVE_ACCESS_ID = "RESET_NEW_EXCLUSIVE_ACCESS_ID";

//benefit
export const GET_FIXED_BENEFITS_REQUEST = "GET_FIXED_BENEFITS_REQUEST";
export const GET_FIXED_BENEFITS_SUCCESS = "GET_FIXED_BENEFITS_SUCCESS";
export const GET_FIXED_BENEFITS_FAILURE = "GET_FIXED_BENEFITS_FAILURE";
export const UPDATE_FIXED_BENEFITS_REQUEST = "UPDATE_FIXED_BENEFITS_REQUEST";
export const UPDATE_FIXED_BENEFITS_SUCCESS = "UPDATE_FIXED_BENEFITS_SUCCESS";
export const UPDATE_FIXED_BENEFITS_FAILURE = "UPDATE_FIXED_BENEFITS_FAILURE";
export const GET_ADDITIONAL_BENEFITS_REQUEST = "GET_ADDITIONAL_BENEFITS_REQUEST";
export const GET_ADDITIONAL_BENEFITS_SUCCESS = "GET_ADDITIONAL_BENEFITS_SUCCESS";
export const GET_ADDITIONAL_BENEFITS_FAILURE = "GET_ADDITIONAL_BENEFITS_FAILURE";
export const UPDATE_ADDITIONAL_BENEFITS_REQUEST = "UPDATE_ADDITIONAL_BENEFITS_REQUEST";
export const UPDATE_ADDITIONAL_BENEFITS_SUCCESS = "UPDATE_ADDITIONAL_BENEFITS_SUCCESS";
export const UPDATE_ADDITIONAL_BENEFITS_FAILURE = "UPDATE_ADDITIONAL_BENEFITS_FAILURE";

//external events
export const GET_ALL_EXTERNAL_EVENTS_REQUEST = "GET_ALL_EXTERNAL_EVENTS_REQUEST";
export const GET_ALL_EXTERNAL_EVENTS_SUCCESS = "GET_ALL_EXTERNAL_EVENTS_SUCCESS";
export const GET_ALL_EXTERNAL_EVENTS_FAILURE = "GET_ALL_EXTERNAL_EVENTS_FAILURE";
export const POST_EXTERNAL_EVENTS_REQUEST = "POST_EXTERNAL_EVENTS_REQUEST";
export const POST_EXTERNAL_EVENTS_SUCCESS = "POST_EXTERNAL_EVENTS_SUCCESS";
export const POST_EXTERNAL_EVENTS_FAILURE = "POST_EXTERNAL_EVENTS_FAILURE";
export const DELETE_EXTERNAL_EVENTS_REQUEST = "DELETE_EXTERNAL_EVENTS_REQUEST";
export const DELETE_EXTERNAL_EVENTS_SUCCESS = "DELETE_EXTERNAL_EVENTS_SUCCESS";
export const DELETE_EXTERNAL_EVENTS_FAILURE = "DELETE_EXTERNAL_EVENTS_FAILURE";

//current plan
export const GET_CURRENT_PLAN_REQUEST = "GET_CURRENT_PLAN_REQUEST";
export const GET_CURRENT_PLAN_SUCCESS = "GET_CURRENT_PLAN_SUCCESS";
export const GET_CURRENT_PLAN_FAILURE = "GET_CURRENT_PLAN_FAILURE";
export const UPDATE_CURRENT_PLAN_REQUEST = "UPDATE_CURRENT_PLAN_REQUEST";
export const UPDATE_CURRENT_PLAN_SUCCESS = "UPDATE_CURRENT_PLAN_SUCCESS";
export const UPDATE_CURRENT_PLAN_FAILURE = "UPDATE_CURRENT_PLAN_FAILURE";
export const CHANGE_PLAN_EDIT_MODE = "CHANGE_PLAN_EDIT_MODE";
export const SET_PLAN_LAUNCH_IN_PROGRESS = "SET_PLAN_LAUNCH_IN_PROGRESS";

//session
export const GET_JWT_REQUEST = "GET_JWT_REQUEST";
export const GET_JWT_SUCCESS = "GET_JWT_SUCCESS";
export const GET_JWT_FAILED = "GET_JWT_FAILED";
export const INVALIDATE_JWT = "INVALIDATE_JWT";

//notification
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

//translations
export const GET_TRANSLATIONS_REQUEST = "GET_TRANSLATIONS_REQUEST";
export const GET_TRANSLATIONS_SUCCESS = "GET_TRANSLATIONS_SUCCESS";
export const GET_TRANSLATIONS_FAILURE = "GET_TRANSLATIONS_FAILURE";

//Navigation confirm
export const NAV_CONFIRM_DEFAULT = "NAV_CONFIRM_DEFAULT";
export const NAV_CONFIRM_DISREGARD_LOADED = "NAV_CONFIRM_DISREGARD_LOADED";

//icons
export const ICONS_LOAD_REQUEST = "ICONS_LOAD_REQUEST";
export const ICONS_LOAD_SUCCESS = "ICONS_LOAD_SUCCESS";
export const ICONS_LOAD_FAILURE = "ICONS_LOAD_FAILURE";
export const ICONS_LOAD_FINISHED = "ICONS_LOAD_FINISHED";

export const ICON_ADD_REQUEST = "ICON_ADD_REQUEST";
export const ICON_ADD_SUCCESS = "ICON_ADD_SUCCESS";
export const ICON_ADD_FAILURE = "ICON_ADD_FAILURE";

export const ICON_DELETE_REQUEST = "ICON_DELETE_REQUEST";
export const ICON_DELETE_SUCCESS = "ICON_DELETE_SUCCESS";
export const ICON_DELETE_FAILURE = "ICON_DELETE_FAILURE";

export const ICON_EDIT_REQUEST = "ICON_EDIT_REQUEST";
export const ICON_EDIT_SUCCESS = "ICON_EDIT_SUCCESS";
export const ICON_EDIT_FAILURE = "ICON_EDIT_FAILURE";

export const ICON_CLEAR_ERRORS = "ICON_CLEAR_ERRORS";
export const ICON_EDIT_MODE = "ICON_EDIT_MODE";
export const ICON_EDIT_NAME = "ICON_EDIT_NAME";
