import { defaultMessages, getMessage } from "../messages";

export const TAB_ENUM = {
  DASHBOARD: "dashboard",
  ACTIONS: "actions",
  REWARDS: "rewards",
  TIERS: "tiers",
  LOYALTY_WALLET: "loyaltyWallet",
  MEMBER_PROFILES: "memberProfiles",
  EXTERNAL_EVENTS: "externalEvents",
  PLAN_SETTINGS: "planSettings"
};

export const MAIN_TABS = [
  {
    id: TAB_ENUM.DASHBOARD,
    title: getMessage("mainTabs.dashboard.title"),
    route: "/dashboard"
  },
  {
    id: TAB_ENUM.ACTIONS,
    title: getMessage("mainTabs.actions.title"),
    route: "/actions"
  },
  {
    id: TAB_ENUM.REWARDS,
    title: getMessage("mainTabs.rewards.title"),
    route: "/rewards"
  },
  {
    id: TAB_ENUM.TIERS,
    title: getMessage("mainTabs.tiers.title"),
    route: "/tiers"
  },
  {
    id: TAB_ENUM.LOYALTY_WALLET,
    title: getMessage("mainTabs.loyaltyWallet.title"),
    route: "/portal",
    disabled: true
  },
  {
    id: TAB_ENUM.MEMBER_PROFILES,
    title: getMessage("mainTabs.memberProfiles.title"),
    route: "/member-profiles"
  },
  {
    id: TAB_ENUM.EXTERNAL_EVENTS,
    title: getMessage("mainTabs.externalEvents.title"),
    route: "/external-loyalty-events"
  },
  {
    id: TAB_ENUM.PLAN_SETTINGS,
    title: getMessage("mainTabs.planSettings.title"),
    route: "/plan"
  }
];

export const ACTIONS_TAB_ENUM = {
  PURCHASES: "purchases",
  PROMOTIONS: "promotions",
  ENGAGEMENTS: "engagements",
  EVENTS: "events",
  REFERRAL: "referral",
  JOIN: "join"
};

export const ACTIONS_TABS = [
  {
    id: ACTIONS_TAB_ENUM.PURCHASES,
    title: getMessage("actionTabs.purchases.title"),
    route: "/actions"
  },
  {
    id: ACTIONS_TAB_ENUM.PROMOTIONS,
    title: getMessage("actionTabs.promotions.title"),
    route: "/actions/promotions"
  },
  {
    id: ACTIONS_TAB_ENUM.ENGAGEMENTS,
    title: getMessage("actionTabs.engagements.title"),
    route: "/actions/engagements"
  },
  {
    id: ACTIONS_TAB_ENUM.EVENTS,
    title: getMessage("actionTabs.events.title"),
    route: "/actions/events"
  },
  {
    id: ACTIONS_TAB_ENUM.REFERRAL,
    title: getMessage("actionTabs.referral.title"),
    route: "/actions/referral"
  },
  {
    id: ACTIONS_TAB_ENUM.JOIN,
    title: getMessage("actionTabs.join.title"),
    route: "/actions/join"
  }
];

export const TIERS_TAB_ENUM = {
  TIERS: "tiers",
  FIXED_BENEFITS: "fixedBenefits",
  ADDITIONAL_BENEFITS: "additionalBenefits",
  CONTACT_DISTRIBUTION: "contactDistribution"
};

export const TIERS_TABS = [
  {
    id: TIERS_TAB_ENUM.TIERS,
    title: getMessage("tierTabs.tiers.title"),
    route: "/tiers"
  },
  {
    id: TIERS_TAB_ENUM.FIXED_BENEFITS,
    title: getMessage("tierTabs.fixedBenefits.title"),
    route: "/tiers/fixed-benefits"
  },
  {
    id: TIERS_TAB_ENUM.ADDITIONAL_BENEFITS,
    title: getMessage("tierTabs.additionalBenefits.title"),
    route: "/tiers/additional-benefits"
  }
];

export const REWARDS_TAB_ENUM = {
  VOUCHERS_FOR_REWARDS: "vouchersForRewards",
  VOUCHERS_FOR_POINTS: "vouchersForPoints",
  VOUCHERS_FOR_FREE: "vouchersForFree",
  EXCLUSIVE_ACCESS: "exclusiveAccess",
  VOUCHERS_AS_CASHBACK: "vouchersAsCashback"
};

export const REWARDS_VOUCHER_TABS = [
  {
    id: REWARDS_TAB_ENUM.VOUCHERS_FOR_REWARDS,
    titleMessage: `voucherOverviewPage.tabs.${REWARDS_TAB_ENUM.VOUCHERS_FOR_REWARDS}`,
    route: "/vouchers"
  },
  {
    id: REWARDS_TAB_ENUM.VOUCHERS_FOR_POINTS,
    titleMessage: `voucherOverviewPage.tabs.${REWARDS_TAB_ENUM.VOUCHERS_FOR_POINTS}`,
    route: "/vouchers/vouchersForPoints"
  },
  {
    id: REWARDS_TAB_ENUM.VOUCHERS_AS_CASHBACK,
    titleMessage: `voucherOverviewPage.tabs.${REWARDS_TAB_ENUM.VOUCHERS_AS_CASHBACK}`,
    route: "/vouchers/vouchersAsCashback"
  },
  {
    id: REWARDS_TAB_ENUM.VOUCHERS_FOR_FREE,
    titleMessage: `voucherOverviewPage.tabs.${REWARDS_TAB_ENUM.VOUCHERS_FOR_FREE}`,
    route: "/vouchers/vouchersForFree"
  }
];

export const EXCLUSIVE_ACCESS_TAB_ENUM = {
  PERMANENT: "permanent",
  LIMITED: "limited"
};

export const EXCLUSIVE_ACCESS_TABS = [
  {
    id: EXCLUSIVE_ACCESS_TAB_ENUM.PERMANENT,
    titleMessage: "exclusiveAccessExclusivityType.options.name.fixed",
    route: "/exclusive-access"
  },
  {
    id: EXCLUSIVE_ACCESS_TAB_ENUM.LIMITED,
    titleMessage: "exclusiveAccessExclusivityType.options.name.limited",
    route: "/exclusive-access/limited"
  }
];

export const MANAGEMENT_TAB_ENUM = {
  ACCOUNT_SETTINGS: "accountSettings",
  LANGUAGE_AND_CURRENCY: "languageAndCurrency",
  PLAN_SETTINGS: "planSettings",
  CASHBACK_SETTINGS: "cashbackSettings",
  MEMBERSHIP_SETTINGS: "membershipSettings",
  UPDATE_AND_LAUNCH: "updateAndLaunch"
};

export const MANAGEMENT_TABS = [
  {
    id: MANAGEMENT_TAB_ENUM.ACCOUNT_SETTINGS,
    title: getMessage("managementTabs.accountSettings.title"),
    route: "/account-settings"
  },
  {
    id: MANAGEMENT_TAB_ENUM.ACCOUNT_SETTINGS,
    title: getMessage("managementTabs.languageAndCurrency.title"),
    route: "/language-and-currency"
  },
  {
    id: MANAGEMENT_TAB_ENUM.PLAN_SETTINGS,
    title: getMessage("managementTabs.planSettings.title"),
    route: "/plan-settings"
  },
  {
    id: MANAGEMENT_TAB_ENUM.CASHBACK_SETTINGS,
    title: getMessage("managementTabs.cashbackSettings.title"),
    route: "/cashback-settings"
  },
  {
    id: MANAGEMENT_TAB_ENUM.MEMBERSHIP_SETTINGS,
    title: getMessage("managementTabs.membershipSettings.title"),
    route: "/membership-settings"
  },
  {
    id: MANAGEMENT_TAB_ENUM.UPDATE_AND_LAUNCH,
    title: getMessage("managementTabs.updateAndLaunch.title"),
    route: "/plan-launch"
  }
];

export const CONFIGURATION_SETTINGS_TAB_ENUM = {
  WALLET: "wallet",
  API_KEY: "apiKey",
  RDS: "rds",
  SALES_DATA: "salesData",
  PRODUCT_CATALOG: "productCatalog",
  DATA_LOAD: "dataLoad"
};

export const CONFIGURATION_SETTINGS_TABS = [
  {
    id: CONFIGURATION_SETTINGS_TAB_ENUM.WALLET,
    title: getMessage("securitySettings.menuTab.walletApp"),
    route: "/configuration/loyalty-wallet"
  },
  {
    id: CONFIGURATION_SETTINGS_TAB_ENUM.API_KEY,
    title: getMessage("securitySettings.menuTab.apiKey"),
    route: "/configuration/api-key"
  },
  {
    id: CONFIGURATION_SETTINGS_TAB_ENUM.RDS,
    title: getMessage("securitySettings.menuTab.rds"),
    route: "/configuration/rds-configuration"
  },
  {
    id: CONFIGURATION_SETTINGS_TAB_ENUM.SALES_DATA,
    title: getMessage("securitySettings.menuTab.salesData"),
    route: "/configuration/sales-data"
  },
  {
    id: CONFIGURATION_SETTINGS_TAB_ENUM.PRODUCT_CATALOG,
    title: getMessage("securitySettings.menuTab.productCatalog"),
    route: "/configuration/product-catalog"
  },
  {
    id: CONFIGURATION_SETTINGS_TAB_ENUM.DATA_LOAD,
    title: getMessage("securitySettings.menuTab.dataLoad"),
    route: "/configuration/data-load"
  }
];
