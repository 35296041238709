import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Alert from "./Alert";

import { ALERT_STATUS } from "../constants/alertContants";

import { selectIsBeforeFirstPlanLaunch, selectShowCurrentPlan } from "../reducers";

import "../assets/styles/AlertContainer.scss";
import { getMessage } from "../messages";

class AlertContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      currentMessage: ""
    };

    this.timeout = null;
  }

  componentDidUpdate(prevProps) {
    const { timestamp, time, isFixed, show } = this.props.alert;

    if (prevProps.alert.timestamp == timestamp) {
      return;
    }

    if (!show) {
      this.setState({
        show
      });
      return;
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.setState({
      show: true
    });

    //if isFixed is true, don't set timeout for hiding
    if (isFixed) {
      return;
    }

    this.timeout = setTimeout(() => {
      this.setState({
        show: false
      });
    }, time);
  }

  renderStateAlert() {
    if (!this.state.show) {
      return null;
    }

    const { message, status } = this.props.alert;

    return (
      <div className="l-alert-container m-t-5">
        <Alert message={message} status={status} />
      </div>
    );
  }

  renderPlanEditModeAlert() {
    const { isBeforeFirstPlanLaunch, showCurrentPlan } = this.props;

    if (isBeforeFirstPlanLaunch || showCurrentPlan || this.state.show) {
      return null;
    }
    return (
      <div className="l-alert-container m-t-5" style={{ width: "772px" }}>
        <Alert message={getMessage("alertContainer.editModeAlert.message")} status={ALERT_STATUS.INFO} />
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderPlanEditModeAlert()}
        {this.renderStateAlert()}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    alert: state.alert,
    isBeforeFirstPlanLaunch: selectIsBeforeFirstPlanLaunch(state),
    showCurrentPlan: selectShowCurrentPlan(state)
  };
};

AlertContainer.propTypes = {};

export default connect(mapStateToProps, null)(AlertContainer);
