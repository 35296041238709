import * as TYPES from "./TYPES";
import { ALERT_STATUS } from "../constants/alertContants";

export const showErrorAlert = (message, time, isFixed) => {
  return (dispatch) => {
    dispatch({
      type: TYPES.SHOW_ALERT,
      payload: {
        message,
        time,
        isFixed,
        alertStatus: ALERT_STATUS.ERROR
      }
    });
  };
};

export const showWarningAlert = (message, time, isFixed) => {
  return (dispatch) => {
    dispatch({
      type: TYPES.SHOW_ALERT,
      payload: {
        message,
        time,
        isFixed,
        alertStatus: ALERT_STATUS.WARNING
      }
    });
  };
};

export const showSuccessAlert = (message, time, isFixed) => {
  return (dispatch) => {
    dispatch({
      type: TYPES.SHOW_ALERT,
      payload: {
        message,
        time,
        isFixed,
        alertStatus: ALERT_STATUS.SUCCESS
      }
    });
  };
};

export const hideErrorAlert = () => {
  return (dispatch) => {
    dispatch({
      type: TYPES.HIDE_ALERT
    });
  };
};
