import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { isLoading } from "../reducers";

class Overlay extends Component {
  render() {
    return (
      <div className={`overlaycontainer ${this.props.stateLoading || this.props.loading ? "" : "e-hidden"}`}>
        <div className="e-overlay e-overlay-centered">
          <div className="e-overlay__center">
            <e-spinner />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    stateLoading: isLoading(state)
  };
};

Overlay.propTypes = {
  loading: PropTypes.bool
};

export default connect(mapStateToProps, null)(Overlay);
