import thunkMiddleware from "redux-thunk";
import { compose, createStore, applyMiddleware } from "redux";
import reducer from "./reducers";

export const configureStore = () => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(reducer, composeEnhancers(applyMiddleware(thunkMiddleware)));

  return store;
};
