import validate from "validate.js";
import { getMessage } from "../messages";

export default class exclusiveAccessPopupValidator {
  constructor() {
    this.constraints = {
      redirectUrl: {
        presence: {
          allowEmpty: false,
          message: getMessage("exclusiveAccessPopupValidator.redirectUrl.presence")
        },
        url: {
          message: getMessage("exclusiveAccessPopupValidator.redirectUrl.preurlsence")
        }
      }
    };
  }

  validateExclusiveAccessPopup = (exclusiveAccessPopup) => {
    return validate(exclusiveAccessPopup, this.constraints);
  };
}
