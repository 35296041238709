import axios from "axios";

const getParams = ({ startDateStr, endDateStr, dateAggStr }) => {
  return {
    startDate: startDateStr,
    endDate: endDateStr,
    dateAgg: dateAggStr
  };
};

async function getReport({ name, actionId, startDateStr, endDateStr, dateAggStr }) {
  const response = await axios.get(`/report/action/${actionId}/${name}`, {
    params: {
      startDate: startDateStr,
      endDate: endDateStr,
      dateAgg: dateAggStr
    }
  });
  return response.data;
}

export default class actionReportApi {
  static async getActionReferralRevenueChartData({ actionId, startDateStr, endDateStr, dateAggStr }) {
    return getReport({ name: "action-referral-revenue-data", actionId, startDateStr, endDateStr, dateAggStr });
  }

  static async getActionReferralRevenueChartTotals({ actionId, startDateStr, endDateStr, dateAggStr }) {
    return getReport({ name: "action-referral-revenue-totals", actionId, startDateStr, endDateStr, dateAggStr });
  }

  static async getActionReferralVouchersChartData({ actionId, startDateStr, endDateStr, dateAggStr }) {
    return getReport({ name: "action-referral-vouchers-data", actionId, startDateStr, endDateStr, dateAggStr });
  }

  static async getActionReferralVouchersChartTotals({ actionId, startDateStr, endDateStr, dateAggStr }) {
    return getReport({ name: "action-referral-vouchers-totals", actionId, startDateStr, endDateStr, dateAggStr });
  }

  static async getActionReferralSuccessChartData({ actionId, startDateStr, endDateStr, dateAggStr }) {
    return getReport({ name: "action-referral-success-data", actionId, startDateStr, endDateStr, dateAggStr });
  }

  static async getActionReferralSuccessChartTotals({ actionId, startDateStr, endDateStr, dateAggStr }) {
    return getReport({ name: "action-referral-success-totals", actionId, startDateStr, endDateStr, dateAggStr });
  }

  static async getActionReferralReportCsv({ actionId, startDateStr, endDateStr, dateAggStr }) {
    const params = getParams({ startDateStr, endDateStr, dateAggStr });
    const response = await axios.get(`/report/action/${actionId}/action-referral-csv`, {
      params: params,
      responseType: "blob"
    });
    return response.data;
  }
}
