import React, { Fragment } from "react";
import PropTypes from "prop-types";

import InputField from "../components/InputField";

import { getMultiLangFieldLabel } from "../helpers/multiLangHelper";
import { getMessage } from "../messages";

const MultiLangCashbackSettingsFields = ({ onChange, lang, translations, disabled }) => {
  return (
    <InputField
      label={getMultiLangFieldLabel({ label: getMessage("cashbackSettings.cashbackName.input.label"), lang })}
      value={translations["planSettings.cashbackNameML"][lang]}
      name={"planSettings.cashbackNameML"}
      onChange={(event) => onChange(event, lang)}
      disabled={disabled}
    />
  );
};

MultiLangCashbackSettingsFields.propTypes = {
  onChange: PropTypes.func,
  lang: PropTypes.string,
  translations: PropTypes.object,
  disabled: PropTypes.bool
};

export default MultiLangCashbackSettingsFields;
