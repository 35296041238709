import validate from "validate.js";
import _isEmpty from "lodash/isEmpty";

import { PRICE_FIELD_ENUM } from "../constants/accountSettingsConstants";
import { getMessage } from "../messages";

validate.validators.array = (arrayItems, itemConstraints) => {
  const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
    const error = validate(item, itemConstraints);
    if (error) errors[index] = { error: error };
    return errors;
  }, {});

  return _isEmpty(arrayItemErrors) ? null : { errors: arrayItemErrors };
};

export default class accountSettingsValidator {
  createConstraints(currentPlanIds) {
    return {
      planName: {
        presence: {
          allowEmpty: false,
          message: getMessage("accountSettingsValidator.planName.presence")
        }
      },
      planId: {
        presence: {
          allowEmpty: false,
          message: getMessage("accountSettingsValidator.planId.presence")
        },
        format: {
          pattern: "[a-z0-9]+",
          message: getMessage("accountSettingsValidator.planId.format")
        },
        exclusionIgnoreCase: {
          within: currentPlanIds,
          message: getMessage("accountSettingsValidator.planId.exclusionIgnoreCase")
        }
      },
      planType: {
        presence: {
          allowEmpty: false,
          message: getMessage("accountSettingsValidator.planType.presence")
        }
      }
    };
  }

  validatePlanData(planData, currentPlanIds) {
    return validate(planData, this.createConstraints(currentPlanIds));
  }
}
