import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import PopupContainer from "../components/PopupContainer";
import InputField from "../components/InputField";

import { updateStateOnInputChange } from "../helpers/formHelper";

import { getErrorMessage, validateSingleValue } from "../validators";
import externalEventsValidator from "../validators/externalEventsValidator";
import { getMessage } from "../messages";

class ExternalEventSetup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      externalEvent: {},
      errors: {}
    };

    this.validator = new externalEventsValidator();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isShow && !prevProps.isShow) {
      this.setState({
        externalEvent: this.props.externalEvent || { name: "", description: "" }
      });
    }
  }

  validate(externalEvent) {
    return this.validator.validateExternalEvent(externalEvent);
  }

  onInputChange(event) {
    const externalEvent = { ...this.state.externalEvent };

    const inputName = updateStateOnInputChange({ stateObj: externalEvent, event });

    const errors =
      inputName === "name"
        ? validateSingleValue(() => this.validate(externalEvent), this.state.errors, inputName)
        : undefined;

    this.setState({
      externalEvent,
      errors
    });

    return externalEvent;
  }

  onSave() {
    const externalEvent = { ...this.state.externalEvent };
    const { isNew } = this.props;

    const id = isNew ? undefined : externalEvent.id;

    const errors = this.validate(externalEvent);

    this.setState({
      errors
    });

    if (!errors) {
      this.props.onUpdate(id, externalEvent);
      this.props.onClose();
    }
  }

  render() {
    const { externalEvent, errors } = this.state;
    const saveButtonText = this.props.isNew ? getMessage("createBtn.label") : getMessage("updateBtn.label");

    return (
      <PopupContainer
        id="dialog-action"
        title={getMessage("externalEventSetup.title")}
        show={this.props.isShow}
        onClose={this.props.onClose}
      >
        <Fragment>
          <InputField
            label={getMessage("externalEventSetup.name.label")}
            name="name"
            value={externalEvent.name}
            onChange={this.onInputChange.bind(this)}
            errorMsg={getErrorMessage(errors, "name")}
          />
          <InputField
            label={getMessage("externalEventSetup.description.label")}
            name="description"
            value={externalEvent.description}
            onChange={this.onInputChange.bind(this)}
            tooltip={getMessage("externalEventSetup.description.tooltip")}
          />

          <hr className="e-separator e-separator-fullwidth"></hr>

          <div className="e-buttongroup">
            <a className="e-btn" onClick={this.props.onClose}>
              {getMessage("cancelBtn.label")}
            </a>
            <a className="e-btn e-btn-primary" onClick={this.onSave.bind(this)}>
              {saveButtonText}
            </a>
          </div>
        </Fragment>
      </PopupContainer>
    );
  }
}

ExternalEventSetup.propTypes = {
  externalEvent: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string,
    created: PropTypes.instanceOf(Date)
  }),
  isShow: PropTypes.bool,
  isNew: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ExternalEventSetup;
