import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import xid from "xid-js";
import pluralize from "pluralize";
import sanitizeHtml from "sanitize-html";

import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table/react-table.css";
import "react-table-hoc-fixed-columns/lib/styles.css";

import BenefitSetup from "../views/BenefitSetup";

import MultiMarketSelect from "../components/MultiMarketSelect";
import LoaderOverlay from "../components/LoaderOverlay";

import tierService from "../services/tierService";
import actionService from "../services/actionService";
import rewardService from "../services/rewardService";
import exclusiveAccessService from "../services/exclusiveAccessService";

import { updateFixedBenefits, updateAdditionalBenefits } from "../actions/benefitActions";
import { showErrorAlert } from "../actions/alertActions";
import benefitApi from "../api/benefitApi";

import { convertArrayToOptionsArray, convertObjectToOptionsArray } from "../helpers/formHelper";
import { openConfirmationDialog, openDeleteDialog } from "../helpers/dialogHelper";
import { getCurrencySymbol } from "../helpers/currencyHelper";
import { formatPointsNameText, POINTS_PLACEHOLDER } from "../helpers/pointsHelper";

import {
  selectTiers,
  selectRewardsById,
  selectActions,
  selectActionsById,
  selectFixedBenefits,
  selectAdditionalBenefits,
  selectCurrentPlanVersion,
  selectPlanPrimaryCurrency,
  selectPlanLanguages,
  selectPlanPrimaryLanguage,
  selectPlanHasMultiLang,
  selectPlanPointsNames,
  selectShowCurrentPlan,
  selectPlanHasMultiMarket,
  selectExclusivesById
} from "../reducers";

import { BENEFIT_TYPE_ENUM } from "../constants/tierConstants";
import { CONFIG_OBJ_ENUM } from "../constants/configConstants";

import "../assets/styles/Benefits.scss";
import { getMessage, getPluralCardinalMessage } from "../messages";
import { ADDITIONAL_BENEFITS_HELP_LINK, FIXED_BENEFITS_HELP_LINK } from "../constants/linkConstants";
import TiersNavigation from "../views/TiersNavigation";

class Benefits extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showBenefitSetupPopup: false,
      benefitToSetup: null,
      isNewBenefit: true,
      targetMarket: ""
    };
  }

  getPointsText(text, pointsValue) {
    return formatPointsNameText({ text, pointsValue, namesObj: this.props.pointsNamesObj });
  }

  updateBenefits(benefits) {
    const { isAdditionalBenefits, updateFixedBenefits, updateAdditionalBenefits } = { ...this.props };
    isAdditionalBenefits ? updateAdditionalBenefits(benefits) : updateFixedBenefits(benefits);
  }

  onAddNewBenefitClick() {
    let newBenefit = tierService.getFixedBenefitDefaultData();
    let tiersMap = {};
    this.props.tiers.forEach((tier) => {
      tiersMap[tier.id] = {
        enabled: false
      };
    });

    newBenefit.id = xid.next();
    newBenefit.tiers = tiersMap;

    this.setState({
      benefitToSetup: newBenefit,
      showBenefitSetupPopup: true,
      isNewBenefit: true
    });
  }

  onEditBenefitClick(benefitId) {
    let benefits = [...this.props.benefits];
    const benefit = benefits.find((benefit) => benefit.id === benefitId);

    this.props.tiers.forEach((tier) => {
      if (!this.props.isAdditionalBenefits && !benefit.tiers[tier.id]) {
        benefit.tiers[tier.id] = { enabled: false };
      }
    });

    this.setState({
      benefitToSetup: benefit,
      showBenefitSetupPopup: true,
      isNewBenefit: false
    });
  }

  onDeleteBenefitClick(benefitId) {
    const deleteBenefit = () => {
      let benefits = [...this.props.benefits];
      const index = benefits.findIndex((b) => b.id === benefitId);
      benefits.splice(index, 1);
      this.updateBenefits(benefits);
    };

    const openDialog = () => {
      openDeleteDialog(
        getMessage("benefits.deleteBenefit.deleteDialog.header"),
        getMessage("benefits.deleteBenefit.deleteDialog.content"),
        deleteBenefit
      );
    };

    const openAlertDialog = () => {
      openConfirmationDialog({
        headline: getMessage("benefits.deleteBenefit.alertDialog.header"),
        content: getMessage("benefits.deleteBenefit.alertDialog.content")
      });
    };

    if (this.props.isAdditionalBenefits) {
      this.setState({ loading: true });
      benefitApi
        .getTotalActiveAdditionalBenefits(benefitId)
        .then((data) => {
          deleteBenefit;
          data.total > 0 ? openAlertDialog() : openDialog();
        })
        .catch((e) => {
          this.props.showErrorAlert(getMessage("benefits.deleteBenefit.failureAlert"));
          throw e;
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    } else {
      openDialog();
    }
  }

  onUpdateBenefit(benefit) {
    const isNewBenefit = this.state.isNewBenefit;
    let benefits = [...this.props.benefits];
    if (isNewBenefit) {
      benefits.push(benefit);
    } else {
      const index = benefits.findIndex((b) => b.id === benefit.id);
      benefits[index] = benefit;
    }

    this.updateBenefits(benefits);
  }

  onBenefitSetupClose() {
    this.setState({
      showBenefitSetupPopup: false
    });
  }

  onTargetMarketChange(event) {
    const value = event.target.value;
    this.setState({
      targetMarket: value
    });
  }

  getActionRewardsText(actionId, tierId) {
    const action = this.props.actionsById[actionId];
    return actionService.getRewardsText(action, tierId);
  }

  getRewardVoucherName(rewardId) {
    const reward = this.props.rewardsById[rewardId];
    return rewardService.getRewardVoucherName(reward);
  }

  getExclusiveAccessNames(exclusiveAccessIds) {
    const names = exclusiveAccessIds.map((id) => {
      const exclusiveAccess = this.props.exclusivesById[id];
      return exclusiveAccessService.getAccessName(exclusiveAccess);
    });

    return names.join("\n");
  }

  getBenefitRewardText(benefit, tierId) {
    const { isAdditionalBenefits } = { ...this.props };
    switch (benefit.type) {
      case BENEFIT_TYPE_ENUM.ONE_TIME_VOUCHER:
      case BENEFIT_TYPE_ENUM.FIXED_YEARLY_VOUCHER:
        const rewardId = isAdditionalBenefits ? benefit.rewardId : benefit.tiers[tierId].rewardId;
        return this.getRewardVoucherName(rewardId);
      case BENEFIT_TYPE_ENUM.EVENT_ACTION:
        return this.getActionRewardsText(benefit.eventActionId, tierId);
      case BENEFIT_TYPE_ENUM.CUSTOM:
        return benefit.custom;
      case BENEFIT_TYPE_ENUM.EXCLUSIVE_ACCESS:
        const exclusiveAccessIds = isAdditionalBenefits
          ? benefit.exclusiveAccessIds
          : benefit.tiers[tierId].exclusiveAccessIds;
        return this.getExclusiveAccessNames(exclusiveAccessIds);
      default:
        return "";
    }
  }

  getColumnsHeaders(tiers) {
    const { hasMultiMarket, isAdditionalBenefits } = { ...this.props };

    let columns = [
      {
        Header: <div className="text-size-subheader font-weight-bold">Benefit</div>,
        accessor: "benefitName",
        fixed: "left",
        width: 300
      }
    ];

    if (isAdditionalBenefits) {
      columns.push({
        Header: "",
        accessor: "benefitValue"
      });
    } else {
      tiers.forEach((tier, index) => {
        columns.push({
          Header: this.renderTierHeader(tier, tiers[index + 1]),
          accessor: tier.id,
          minWidth: 240
        });
      });
    }

    columns.push({
      Header: hasMultiMarket ? (
        <div>
          <MultiMarketSelect value={this.state.targetMarket} onChange={this.onTargetMarketChange.bind(this)} />
          <br />
        </div>
      ) : null,
      accessor: "benefitId",
      fixed: "right",
      width: hasMultiMarket ? 200 : 100,
      Cell: (props) => {
        const benefitId = props.value;

        if (!benefitId) {
          return null;
        }

        const { showCurrentVersion } = this.props;

        const { disabled, linkClassName, onClickEdit, onClickDelete } = showCurrentVersion
          ? {
              disabled: true,
              linkClassName: "e-svgclickfix e-btn-onlyicon e-btn-disabled",
              onClickEdit: () => {},
              onClickDelete: () => {}
            }
          : {
              disabled: false,
              linkClassName: "e-svgclickfix e-btn-onlyicon",
              onClickEdit: () => this.onEditBenefitClick(benefitId),
              onClickDelete: () => this.onDeleteBenefitClick(benefitId)
            };

        return (
          <Fragment>
            <e-tooltip valign="baseline" content={getMessage("edit")} disabled={disabled}>
              <a className={linkClassName} onClick={onClickEdit}>
                <e-icon icon="pencil" type="table" />
              </a>
            </e-tooltip>
            <e-tooltip valign="baseline" content={getMessage("delete")} disabled={disabled}>
              <a className={linkClassName} onClick={onClickDelete}>
                <e-icon icon="trash-o" type="table" />
              </a>
            </e-tooltip>
          </Fragment>
        );
      }
    });
    return columns;
  }

  getFixedBenefits(tiers, benefits) {
    let data = [];
    const { targetMarket } = { ...this.state };

    benefits.map((benefit) => {
      if (!!targetMarket && !benefit.targetMarkets.includes(targetMarket)) {
        return false;
      }

      let benefitData = {
        benefitName: benefit.name,
        benefitId: benefit.id
      };

      tiers.forEach((tier) => {
        const isEnabled = benefit.tiers[tier.id] && benefit.tiers[tier.id].enabled;
        const text = isEnabled ? this.getBenefitRewardText(benefit, tier.id) : "-";
        benefitData[tier.id] = text;
      });

      data.push(benefitData);
    });

    return data;
  }

  getAdditionalBenefits(benefits) {
    if (!benefits) {
      return [];
    }

    let data = [];
    const { targetMarket } = { ...this.state };

    benefits.map((benefit) => {
      if (!!targetMarket && !benefit.targetMarkets.includes(targetMarket)) {
        return false;
      }

      let benefitData = {
        benefitName: benefit.name,
        benefitValue: this.getBenefitRewardText(benefit),
        benefitId: benefit.id
      };

      data.push(benefitData);
    });

    return data;
  }

  getPurchaseBenefits(tiers) {
    const getPurchaseBenefitText = (purchaseBenefit) => {
      const primaryCurrency = this.props.primaryCurrency;
      const pointsRate = tierService.getPointsRate({
        purchaseBenefit,
        currencyCode: primaryCurrency
      });

      if (!pointsRate) {
        return null;
      }

      const pointsText = this.getPointsText(`${pointsRate.points} ${POINTS_PLACEHOLDER}`, pointsRate.points);

      return getMessage("benefits.purchaseBenefits.text", {
        pointsText,
        currencySymbol: getCurrencySymbol(primaryCurrency),
        pointsSpent: pointsRate.spent
      });
    };

    let data = [];

    let pointsBenefitData = {
      benefitName: this.getPointsText(
        getMessage("benefits.pointsFromPurchases", { pointsPlaceHolder: POINTS_PLACEHOLDER })
      )
    };

    tiers.forEach((tier) => {
      let text;
      if (!!tier.purchaseBenefits) {
        text = getPurchaseBenefitText(tier.purchaseBenefits.pointsBenefit);
      }
      pointsBenefitData[tier.id] = text || "-";
    });
    data.push(pointsBenefitData);

    //pointsCatalogBenefit
    let pointsCatalogBenefitData = {
      benefitName: this.getPointsText(
        getMessage("benefits.pointsCatalogBenefitData", { pointsPlaceHolder: POINTS_PLACEHOLDER })
      )
    };
    tiers.forEach((tier) => {
      let texts = [];
      let text;
      if (!!tier.purchaseBenefits) {
        tier.purchaseBenefits.pointsCatalogBenefits.forEach((catalogBenefit) => {
          if (catalogBenefit.pointsRates) {
            texts.push(getPurchaseBenefitText(catalogBenefit));
          }

          if (texts.length > 0) {
            text = texts.join("<br />");
          }

          pointsCatalogBenefitData[tier.id] = text ? (
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(text)
              }}
            ></div>
          ) : (
            "-"
          );
        });
      } else {
        pointsCatalogBenefitData[tier.id] = "-";
      }
    });
    data.push(pointsCatalogBenefitData);

    return data;
  }

  getEventActionOptions(currBenefitId) {
    const actionsForOptions = this.props.eventActions.filter((action) => {
      const isActionConnected = this.props.benefits.some(
        (benefit) => benefit.id !== currBenefitId && benefit.eventActionId === action.id
      );
      return !isActionConnected;
    });

    return convertArrayToOptionsArray(actionsForOptions, "id", "name");
  }

  renderTierHeader(tier, nextTier) {
    const points = tier.settings.points;
    const maxPoints = nextTier ? nextTier.settings.points - 1 : null;
    const pointsText = maxPoints ? `${points} - ${maxPoints}` : `${points} +`;

    const showPoints = points !== undefined;

    const purchasesText = getPluralCardinalMessage("tiers.numberOfPurchases", tier.settings.purchases, {
      number: tier.settings.purchases
    });
    let purchasesStyle = {};
    if (!tier.settings.purchases) {
      purchasesStyle.visibility = "hidden";
    }

    return (
      <div>
        <div className="text-size-subheader font-weight-bold">{tier.name}</div>
        {/* <div>{pointsText} Loyalty Points</div> */}
        <div>{showPoints ? this.getPointsText(`${pointsText} ${POINTS_PLACEHOLDER}`) : ""}</div>
        <div style={purchasesStyle}>{purchasesText}</div>
      </div>
    );
  }

  renderTable() {
    const ReactTableFixedColumns = withFixedColumns(ReactTable);
    const { isAdditionalBenefits, benefits } = { ...this.props };
    let data = [];
    if (isAdditionalBenefits) {
      data = this.getAdditionalBenefits(benefits);
    } else {
      const purchasesData = this.getPurchaseBenefits(this.props.tiers);
      const benefitsData = this.getFixedBenefits(this.props.tiers, this.props.benefits);
      data = [...purchasesData, ...benefitsData];
    }

    return (
      <ReactTableFixedColumns
        data={data}
        columns={this.getColumnsHeaders(this.props.tiers)}
        showPaginationBottom={false}
        sortable={false}
        pageSize={data.length}
      />
    );
  }

  renderCreateNewBenefitButton() {
    const button = (
      <button
        className="e-btn e-btn-primary add-new-benefit-btn"
        disabled={this.props.showCurrentVersion}
        onClick={this.onAddNewBenefitClick.bind(this)}
      >
        {getMessage("benefits.createNew.button.label")}
      </button>
    );

    if (this.props.showCurrentVersion) {
      return <e-tooltip content={getMessage("benefits.createNew.button.tooltip")}>{button}</e-tooltip>;
    }
    return button;
  }

  render() {
    return (
      <TiersNavigation>
        <div className="benefits-overview">
          <LoaderOverlay show={this.state.loading} />
          <div className="e-header m-15">
            <div className="e-header__title">
              {this.props.isAdditionalBenefits
                ? getMessage("benefits.header.title.additionalBenefits", {
                    learnMoreLink: (
                      <a target="_blank" href={ADDITIONAL_BENEFITS_HELP_LINK}>
                        {getMessage("benefits.header.title.additionalBenefits.learnMoreLinkText")}
                      </a>
                    )
                  })
                : getMessage("benefits.header.title.fixedBenefits", {
                    learnMoreLink: (
                      <a target="_blank" href={FIXED_BENEFITS_HELP_LINK}>
                        {getMessage("benefits.header.title.fixedBenefits.learnMoreLinkText")}
                      </a>
                    )
                  })}
            </div>
            <div className="e-header__button">
              <div className="e-buttongroup">{this.renderCreateNewBenefitButton()}</div>
            </div>
          </div>
          <div className="benefits-overview-table">{this.renderTable()}</div>

          <BenefitSetup
            isShow={this.state.showBenefitSetupPopup}
            isNewBenefit={this.state.isNewBenefit}
            benefit={this.state.benefitToSetup}
            onClose={this.onBenefitSetupClose.bind(this)}
            rewardsById={this.props.rewardsById}
            exclusivesById={this.props.exclusivesById}
            allRewardOptions={this.props.allRewardOptions}
            rewardOneTimeOptions={this.props.rewardOneTimeOptions}
            rewardFixedYearlyOptions={this.props.rewardFixedYearlyOptions}
            getEventActionOptions={this.getEventActionOptions.bind(this)}
            getActionRewardsTextFunc={this.getActionRewardsText.bind(this)}
            onUpdateBenefit={this.onUpdateBenefit.bind(this)}
            tierList={this.props.tiers}
            languages={this.props.languages}
            primaryLanguage={this.props.primaryLanguage}
            hasMultiLang={this.props.hasMultiLang}
            hasMultiMarket={this.props.hasMultiMarket}
            isAdditionalBenefit={this.props.isAdditionalBenefits}
          />
        </div>
      </TiersNavigation>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const showCurrentVersion = selectShowCurrentPlan(state);
  const rewardsById = selectRewardsById(state);
  const exclusivesById = selectExclusivesById(state);
  let benefits = [];

  if (ownProps.isAdditionalBenefits) {
    benefits = showCurrentVersion
      ? selectCurrentPlanVersion(state).additionalBenefits
      : selectAdditionalBenefits(state);
  } else {
    benefits = showCurrentVersion ? selectCurrentPlanVersion(state).fixedBenefits : selectFixedBenefits(state);
  }

  const tiers = selectTiers(state);

  return {
    showCurrentVersion,
    benefits,
    tiers: tierService.sortTiers(tiers),
    actionsById: selectActionsById(state),
    rewardsById,
    exclusivesById,
    allRewardOptions: convertObjectToOptionsArray(rewardsById, "name"),
    rewardOneTimeOptions: rewardService.getRewardOptionsForOneTimeBenefit(rewardsById),
    rewardFixedYearlyOptions: rewardService.getRewardOptionsForFixedYearlyBenefit(rewardsById),
    eventActions: actionService.getEventActionsForBenefits(selectActions(state)),
    primaryCurrency: selectPlanPrimaryCurrency(state),
    languages: selectPlanLanguages(state),
    primaryLanguage: selectPlanPrimaryLanguage(state),
    hasMultiLang: selectPlanHasMultiLang(state),
    pointsNamesObj: selectPlanPointsNames(state),
    hasMultiMarket: selectPlanHasMultiMarket(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFixedBenefits: (benefits) => {
      dispatch(updateFixedBenefits(benefits));
    },
    updateAdditionalBenefits: (benefits) => {
      dispatch(updateAdditionalBenefits(benefits));
    },
    showErrorAlert: (message) => {
      dispatch(showErrorAlert(message));
    }
  };
};

Benefits.propTypes = {
  isAdditionalBenefits: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(Benefits);
