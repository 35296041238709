import React, { Component, Fragment } from "react";
import _values from "lodash/values";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import deepEqual from "deep-equal";
import moment from "moment";
import classNames from "classnames";

import MultiLangPopup from "../components/MultiLangPopup";

import { updateStateOnInputChange, getInputNameAndValue } from "../helpers/formHelper";
import { updateObjectByNameAndValue, filterObjectByKeys } from "../helpers/objectHelper";
import { getNavigationCanceled } from "../helpers/navHelper";
import { multiLangUpdateState, initTranslationsObj, setTranslationsOnInputChange } from "../helpers/multiLangHelper";

import { getMessage } from "../messages";

import FormStepsContainer from "../components/FormStepsContainer";
import Notification from "../components/Notification";

import RewardSetupPoolSettings from "../views/RewardSetupPoolSettings";
import RewardSetupRedemptionValue from "../views/RewardSetupRedemptionValue";
import RewardSetupValidity from "../views/RewardSetupValidity";
import RewardSetupUpload from "../views/RewardSetupUpload";
import MultiLangVoucherNameField from "../views/RewardSetup/MultiLangVoucherNameField";
import MultiLangDefaultTextField from "../views/RewardSetup/MultiLangDefaultTextField";
import MultiLangRedemptionMessageFields from "../views/RewardSetup/MultiLangRedemptionMessageFields";
import MultiLangExternalBlockLinkFields from "../views/Shared/MultiLangExternalBlockLinkFields";
import { validateSingleValue, validateSingleMultiLangValue } from "../validators";
import rewardValidator from "../validators/rewardValidator";

import {
  selectTiersMapById,
  selectRewardById,
  isRewardPostSuccess,
  selectRewardNames,
  selectActions,
  selectTiers,
  selectFixedBenefits,
  selectRewardEditState,
  selectNewRewardId,
  selectRewardsAllocationDelay,
  selectIsCurrentPlanVersionExists,
  selectCurrentPlanVersion,
  selectCurrentPlanTiersMapById,
  selectPlanLanguages,
  selectPlanPrimaryLanguage,
  selectPlanHasMultiLang,
  selectPlanHasMultiMarket,
  selectPlan,
  selectAdditionalBenefits,
  selectPlanCurrencies,
  isAccountViewOnly
} from "../reducers";

import {
  updateReward,
  createRewardFromExisting,
  createReward,
  setRewardEditState,
  resetNewRewardId,
  resetRewardEditState,
  addUploadToRewardPool,
  deleteRewardPool
} from "../actions/rewardActions";

import {
  FORM_STEPS_FREE,
  FORM_STEPS_POINTS,
  SPECIAL_TIERS_REDEMPTION_SETTINGS,
  MASTER_REWARDS,
  SPECIAL_TIER_ENUM,
  FORM_STEP_ENUM,
  REDEMPTION_VALUE_TOKEN,
  POOL_DEACTIVATION_ALERT_DAYS,
  VOUCHER_TYPE_OPTIONS,
  VOUCHER_TYPE_ENUM,
  REDEMPTION_LIMIT_TYPE_ENUM,
  MULTI_LANG_PROPS_MAP,
  VALIDITY_TYPE_ENUM,
  MASTER_REWARD_ENUM,
  REWARD_CREATE_OPTIONS_ENUM
} from "../constants/rewardConstants";

import rewardService from "../services/rewardService";
import planService from "../services/planService";

class RewardSetup extends Component {
  constructor(props) {
    super(props);

    this.viewMode = this.props.isAccountViewOnly;

    this.isNavigationCanceled = getNavigationCanceled();

    let tiersForAutoPromotion = {
      ...this.props.tiersById,
      ...SPECIAL_TIERS_REDEMPTION_SETTINGS
    };

    this.masterReward = props.editRewardId ? props.reward.masterReward : props.match.params.masterReward;
    this.statusObj = rewardService.getRewardStatus(
      props.reward,
      props.actions,
      props.fixedBenefits,
      props.additionalBenefits,
      props.tiers
    );

    let rewardDetails = {};
    if (props.dupRewardId) {
      rewardDetails = this.getDuplicatedData();
    } else if (props.editRewardId) {
      rewardDetails = this.getEditRewardData(tiersForAutoPromotion);
    } else {
      rewardDetails = this.getDefaultData(this.masterReward, tiersForAutoPromotion);
    }

    const firstStep = this.getStartStepId(rewardDetails);
    this.startStepId = firstStep.id;

    this.state = {
      rewardDetails,
      redirectToOverview: false,
      triggerSubmit: false,
      currentStep: firstStep,
      errors: {},
      tiersListAccordion: this.initTiersListValueSetup(rewardDetails, tiersForAutoPromotion),
      showMultiLangPopup: false,
      translations: {},
      translationErrors: undefined,
      mlUrlsConfirmShown: false,
      isUploadNotAddedToPool: false
    };

    this.validator = new rewardValidator({
      rewardNames: props.rewardNames.filter((name) => name !== rewardDetails.name),
      hasMultiMarket: props.hasMultiMarket,
      languages: props.languages
    });

    let masterRewardSection;
    Object.keys(MASTER_REWARDS).forEach((key) => {
      const masterRewardsOfSection = MASTER_REWARDS[key].masterRewardsOfSection;
      if (masterRewardsOfSection.includes(this.masterReward)) {
        masterRewardSection = key;
      }
    });

    this.overviewLink = `/vouchers/${masterRewardSection}`;
  }

  get isMultiLangUrlField() {
    return (
      !!this.state.translations["blocking.exactAccessUrlML"] ||
      !!this.state.translations["blocking.startsWithAccessUrlML"]
    );
  }
  //save the current edited reward in case of back in browser
  componentDidMount() {
    window.onpopstate = () => {
      if (this.shouldBlockNavigation()) {
        this.props.setRewardEditState(this.state.rewardDetails, this.state.currentStep.id);
      }
    };
  }

  componentWillUnmount() {
    this.props.resetRewardEditState();
    this.props.resetNewRewardId();
    window.onpopstate = () => {};
  }

  componentDidUpdate(prevProps) {
    //if reward was saved init the rewrad state with the saved reward data
    if (!prevProps.postSuccess && this.props.postSuccess) {
      this.initEditReward();
    }

    if (this.props.postSuccess && this.state.redirectToOverview) {
      this.props.history.push(this.overviewLink);
    }
    if (this.props?.reward?.pool && !deepEqual(this.state.rewardDetails.pool, this.props.reward.pool)) {
      const { rewardDetails } = { ...this.state };
      rewardDetails.pool = cloneDeep(this.props.reward.pool);
      this.setState({ rewardDetails });
    }
  }

  getStartStepId(rewardDetails) {
    let firstStep = this.getFormSteps(rewardDetails)[0];
    if (this.viewMode) {
      const firstStepId = FORM_STEP_ENUM.UPLOAD_FILE;
      firstStep = this.getFormSteps(rewardDetails).find((s) => s.id === firstStepId);
    }

    return firstStep;
  }

  getEditRewardData(tiersForAutoPromotion) {
    if (this.isNavigationCanceled) {
      return this.props.rewardEditState.reward;
    } else {
      let reward = cloneDeep(this.props.reward);
      let doUpdate = false;

      if (!reward.icon) {
        reward.icon = rewardService.getDefaultIcon();
        doUpdate = true;
      }

      if (this.updateRedemptionTiersState(reward, tiersForAutoPromotion)) {
        doUpdate = true;
      }

      //handle redemption limit backward compatibility
      if (!reward.redemption.limitType) {
        reward.redemption.limitType = REDEMPTION_LIMIT_TYPE_ENUM.NO_LIMIT;
        doUpdate = true;
      }

      if (!reward.validity.voucherValidity) {
        reward.validity.voucherValidity = {
          type: "days",
          duration: undefined
        };
        doUpdate = true;
      }

      if (!reward.blockExternalLink) {
        reward.blockExternalLink = {};
        doUpdate = true;
      }

      if (doUpdate) {
        this.saveRewardChanges(reward, reward.id, false);
      }

      return reward;
    }
  }

  initEditReward() {
    const rewardDetails = cloneDeep(this.props.reward);
    this.setState({
      rewardDetails
    });
  }

  getDefaultData(masterReward, tiersForAutoPromotion) {
    const defaultValues = {
      masterReward,
      name: "",
      voucherType: VOUCHER_TYPE_ENUM.ONE_TIME,
      isRemoveOnUse: false,
      voucherName: "",
      voucherValue: undefined,
      icon: rewardService.getDefaultIcon(),
      blockExternalLink: {},
      redemption: {
        message: {
          title: "",
          text: rewardService.isRewardForFree(this.masterReward)
            ? ""
            : !rewardService.isCashback(masterReward)
            ? REDEMPTION_VALUE_TOKEN.token
            : getMessage("voucher.redemption.message.text.vouchersAsCashback", { text: REDEMPTION_VALUE_TOKEN.token }),
          valueToken: rewardService.isRewardForFree(this.masterReward) ? "" : REDEMPTION_VALUE_TOKEN.token
        },
        value: this.initPromotionTiersDefaultValues(tiersForAutoPromotion, masterReward),
        limitType: rewardService.isRewardForFree(this.masterReward)
          ? REDEMPTION_LIMIT_TYPE_ENUM.LIMIT_MEMBER
          : REDEMPTION_LIMIT_TYPE_ENUM.NO_LIMIT
      },
      validity: {
        poolValidity: undefined,
        voucherValidityType: VALIDITY_TYPE_ENUM.LIMITED_FROM_EARN,
        voucherValidity: {
          type: "days",
          duration: undefined
        },
        voucherExpiryDate: undefined,
        refillReminder: undefined,
        deactivationDate: undefined,
        deactivationAlertDate: undefined,
        defaultMessage: getMessage("voucher.validity.defaultMessage"),
        offerValidity: {
          start: null,
          end: null
        },
        rewardModifyOrNew: REWARD_CREATE_OPTIONS_ENUM.REWARD_MODIFY
      },
      pool: {
        uploads: []
      }
    };
    // merge between default data and existing reward data
    return defaultValues;
  }

  getDuplicatedData() {
    let reward = cloneDeep(this.props.reward);
    reward.name = getMessage("voucher.duplicate.voucher.namePrefix") + reward.name;

    reward.pool = {
      upload: []
    };

    return reward;
  }

  //update redemption tiers according to tiers set for plan
  updateRedemptionTiersState(rewardDetails, tiersForAutoPromotion) {
    let didUpdate = false;
    Object.keys(tiersForAutoPromotion).forEach((tierId) => {
      if (!rewardDetails.redemption.value[tierId]) {
        rewardDetails.redemption.value[tierId] = {};
        didUpdate = true;
      }
    });

    // delete from action reward old tiers that been deleted
    const redemptionTiers = rewardDetails.redemption.value;
    Object.keys(redemptionTiers).forEach((tierId) => {
      if (!tiersForAutoPromotion[tierId]) {
        delete redemptionTiers[tierId];
        didUpdate = true;
      }
    });

    return didUpdate;
  }

  // init tier default values object
  initPromotionTiersDefaultValues(tiersForAutoPromotion, masterReward) {
    const tiersDefaultValues = {};
    Object.keys(tiersForAutoPromotion).forEach((tierId) => {
      tiersDefaultValues[tierId] = {
        pointsEnabled: false,
        points: undefined
      };
    });

    return tiersDefaultValues;
  }

  clearPromotionTiersByALL(tiersValues) {
    const nodifiedTiersValues = {};
    for (const tierId in tiersValues) {
      if (tierId != "all") {
        nodifiedTiersValues[tierId] = { pointsEnabled: false };
      } else {
        nodifiedTiersValues[tierId] = tiersValues[tierId];
      }
    }

    return nodifiedTiersValues;
  }

  initTiersListValueSetup(rewardDetails, tiersForAutoPromotion) {
    let tiers = {};

    Object.keys(tiersForAutoPromotion).forEach((tierId) => {
      const tier = tiersForAutoPromotion[tierId];

      if (tier.settings && tier.settings.subscr) {
        delete tiers[tierId];
        return;
      }

      let promotion;
      if (!!rewardDetails.redemption.value[tierId]) {
        promotion = cloneDeep(rewardDetails.redemption.value[tierId]);
      } else {
        const tierForPromotion = {};
        tierForPromotion[tierId] = tier;
        const tierPromotion = this.initPromotionTiersDefaultValues(tierForPromotion);
        promotion = tierPromotion[tierId];
      }

      tiers[tierId] = {
        tierId,
        name: tier.name,
        promotion
      };
    });

    //sort tiers
    let sortedTiers = [];
    for (const tierId in tiers) {
      if (tierId != SPECIAL_TIER_ENUM.ALL) {
        sortedTiers.push(tiers[tierId]);
      }
    }

    if (tiers[SPECIAL_TIER_ENUM.ALL]) {
      sortedTiers.push(tiers[SPECIAL_TIER_ENUM.ALL]);
    }

    sortedTiers[0].accordionOpenState = true;
    return sortedTiers;
  }

  hasRedemptionValueEnabled() {
    const redemptionValue = this.state.rewardDetails.redemption.value;
    return Object.keys(redemptionValue).some((key) => redemptionValue[key].pointsEnabled);
  }

  validate(rewardDetails) {
    const stepId = this.state.currentStep.id;
    return this.validator.validateStep(stepId, rewardDetails);
  }

  onStepChange(step) {
    this.setState({
      currentStep: step,
      triggerSubmit: false,
      errors: undefined,
      isUploadNotAddedToPool: false
    });
  }

  getFormSteps(rewardDetails) {
    let steps;
    switch (rewardDetails.masterReward) {
      case MASTER_REWARD_ENUM.VOUCHERS_FOR_FREE:
        steps = FORM_STEPS_FREE;
        break;
      case MASTER_REWARD_ENUM.VOUCHERS_FOR_POINTS:
        steps = FORM_STEPS_POINTS;
        break;
      case MASTER_REWARD_ENUM.VOUCHERS_AS_CASHBACK:
        steps = FORM_STEPS_POINTS;
        break;
      case MASTER_REWARD_ENUM.VOUCHERS_FOR_REWARDS:
        steps = rewardService.getVoucherForRewardSteps(rewardDetails); //// TBD filter out the redemption setting if no redemption for this reward - for old rewards
        break;
    }
    if (!this.props.reward.name) {
      return steps;
    }

    return steps.map((step) => {
      return { ...step, disabled: false };
    });
  }

  validateStep() {
    const { rewardDetails } = this.state;
    const errors = this.validate(rewardDetails);

    this.setState({
      errors
    });

    return errors === undefined;
  }

  onInputChange(event, customValue) {
    const rewardDetails = { ...this.state.rewardDetails };
    const inputName = updateStateOnInputChange({
      stateObj: rewardDetails,
      event,
      customValue,
      hasMultiLanguage: this.props.hasMultiLanguage,
      primaryLanguage: this.props.primaryLanguage,
      multiLangPropsMap: MULTI_LANG_PROPS_MAP
    });

    //redemption value change - special validation
    let inputNameValidate = inputName;
    if (inputName.indexOf("redemption.value") === 0 && inputName.endsWith("Enabled")) {
      inputNameValidate = inputName.replace("Enabled", "");
    }

    let errors = validateSingleValue(() => this.validate(rewardDetails), this.state.errors, inputNameValidate);

    if (inputName === "validity.poolValidity") {
      errors = validateSingleValue(() => this.validate(rewardDetails), errors, "validity.voucherExpiryDate");
    }

    this.setState({
      rewardDetails,
      errors
    });

    return {
      rewardDetails,
      errors
    };
  }

  onVoucherTypeChange(event, customValue) {
    const { rewardDetails } = this.onInputChange(event, customValue);
    rewardDetails.isRemoveOnUse = false;

    this.setState({
      rewardDetails
    });
  }

  onPoolValidityChange(event, customValue) {
    const { rewardDetails } = this.onInputChange(event, customValue);
    this.updateValidityDeactivation(rewardDetails);
  }

  onValidityTypeChange(event) {
    const { rewardDetails } = this.onInputChange(event);
    let validity, validityType, validityName, expiryDateName, validityDurationName;

    validity = rewardDetails.validity;
    validityType = validity.voucherValidityType;
    validityName = "validity";
    expiryDateName = "voucherExpiryDate";
    validityDurationName = "voucherValidity";

    let errors;
    if (validityType === VALIDITY_TYPE_ENUM.LIMITED_FROM_EARN) {
      rewardDetails[validityName][expiryDateName] = undefined;
      errors = validateSingleValue(
        () => this.validate(rewardDetails),
        this.state.errors,
        `${validityName}.${expiryDateName}`
      );
    } else if (validityType === VALIDITY_TYPE_ENUM.FIXED_DATE) {
      rewardDetails[validityName][validityDurationName].duration = undefined;
      errors = validateSingleValue(
        () => this.validate(rewardDetails),
        this.state.errors,
        `${validityName}.${validityDurationName}.duration`
      );
    } else if (validityType === VALIDITY_TYPE_ENUM.UNLIMITED) {
      rewardDetails[validityName][expiryDateName] = undefined;
      rewardDetails[validityName][validityDurationName].duration = undefined;
      errors = validateSingleValue(
        () => this.validate(rewardDetails),
        this.state.errors,
        `${validityName}.${expiryDateName}`
      );
      errors = validateSingleValue(
        () => this.validate(rewardDetails),
        errors,
        `${validityName}.${validityDurationName}.duration`
      );
    }

    this.setState({
      rewardDetails,
      errors
    });

    this.updateValidityDeactivation(rewardDetails);
  }

  updateValidityDeactivation(rewardDetails) {
    if (rewardDetails.validity) {
      const { poolValidity, voucherValidity, voucherValidityType } = rewardDetails.validity;

      if (!poolValidity) {
        return;
      }

      let deactivationDateMoment = moment(poolValidity);

      //in action pools the deactivation date is the chosen dates minus points allocation minus voucher validity
      if (voucherValidityType === VALIDITY_TYPE_ENUM.LIMITED_FROM_EARN) {
        deactivationDateMoment = deactivationDateMoment.subtract(voucherValidity.duration, voucherValidity.type);
        deactivationDateMoment = deactivationDateMoment.subtract(
          this.props.planRewardsAllocationDelayValue.duration,
          this.props.planRewardsAllocationDelayValue.type
        );
      }
      const deactivationAlertDateMoment = deactivationDateMoment.clone().subtract(POOL_DEACTIVATION_ALERT_DAYS, "days");

      rewardDetails.validity.deactivationDate = deactivationDateMoment.format();
      rewardDetails.validity.deactivationAlertDate = deactivationAlertDateMoment.format();

      this.setState({
        rewardDetails
      });
    }
  }

  isPoolValidityDateValid() {
    return rewardService.isPoolValidityDateValid(this.state.rewardDetails);
  }

  isPoolValidityAmountValid() {
    if (this.props.reward) return rewardService.isPoolValidityAmountValid(this.props.reward);
    return false;
  }

  onRedemptionValueChange(event, customValue, tierId, property) {
    const rewardDetails = { ...this.state.rewardDetails };
    let errors = { ...this.state.errors };
    const { value, name } = getInputNameAndValue(event);
    let redemptionValue = { ...rewardDetails.redemption.value };

    if (property === "points" && !redemptionValue[tierId].points && !!value) {
      redemptionValue[tierId].pointsEnabled = true;
      if (tierId === "all") {
        redemptionValue = this.clearPromotionTiersByALL(redemptionValue);
      }
    }

    if (property == "pointsEnabled") {
      if (event.target.checked === true) {
        if (tierId === "all") {
          redemptionValue = this.clearPromotionTiersByALL(redemptionValue);
        }
      } else {
        delete redemptionValue[tierId].points;
        delete redemptionValue[tierId].pointsEnabled;
      }
    }

    //update the points field value
    updateStateOnInputChange({
      stateObj: rewardDetails,
      event,
      customValue
    });

    rewardDetails.redemption.value = redemptionValue;

    const inputNameValidate = name.endsWith(".pointsEnabled") ? name.replace(".pointsEnabled", ".points") : name;
    errors = validateSingleValue(() => this.validate(rewardDetails), this.state.errors, inputNameValidate);

    if (!this.hasRedemptionValueEnabled() && errors) {
      errors = undefined;
    }

    this.setState({
      rewardDetails,
      errors
    });

    if (tierId === "all") {
      var undefinedTier = false;
      this.setTiersListAccordionRewards(undefinedTier, rewardDetails);
    } else {
      this.setTiersListAccordionRewards(tierId, rewardDetails);
    }
  }

  onIconInputChange(icon) {
    let { rewardDetails } = { ...this.state };

    rewardDetails.icon = icon;
    this.setState({
      rewardDetails
    });
  }

  setTiersListAccordionRewards(tierId, rewardDetails) {
    if (tierId) {
      this.setTierListAccordionState(tierId, "promotion", {
        ...rewardDetails.redemption.value[tierId]
      });
    } else {
      const tiersListAccordion = [...this.state.tiersListAccordion];

      tiersListAccordion.forEach((tier, index) => {
        tiersListAccordion[index].promotion = {
          ...rewardDetails.redemption.value[tier.tierId]
        };
      });

      this.setState({
        tiersListAccordion
      });
    }
  }

  updateState(propertyPath, value) {
    const rewardDetails = { ...this.state.rewardDetails };
    updateObjectByNameAndValue(rewardDetails, propertyPath, value);
  }

  setTiersListAccordionOpenState(tierId, newState) {
    this.setTierListAccordionState(tierId, "accordionOpenState", newState);
  }

  setTierListAccordionState(tierId, property, value) {
    const tiersListAccordion = [...this.state.tiersListAccordion];
    const index = tiersListAccordion.findIndex((tier) => tier.tierId === tierId);
    tiersListAccordion[index][property] = value;
  }

  onAddToPool(upload) {
    const { props } = this;
    props.addUploadToRewardPool(props.editRewardId, upload);
    this.setState({
      isUploadNotAddedToPool: false
    });
  }

  onUploadPool() {
    this.setState({
      isUploadNotAddedToPool: true
    });
  }

  onDeletePool() {
    this.props.deleteRewardPool(this.props.editRewardId);
    this.setState({
      isUploadNotAddedToPool: false
    });
  }

  onDeleteCurrentUpload() {
    this.setState({
      isUploadNotAddedToPool: false
    });
  }

  onSubmit() {
    if (this.props.isAccountViewOnly) {
      return;
    }

    const { rewardDetails } = this.state;

    let reward = { ...this.props.reward, ...rewardDetails };
    const rewardId = this.props.editRewardId;
    if (rewardService.isCashback(reward.masterReward)) {
      reward.name = reward.voucherName;
      reward.validity.offerValidity = null;
      reward.redemption.limitType = REDEMPTION_LIMIT_TYPE_ENUM.NO_LIMIT;
      reward.redemption.limitValue = undefined;
    }

    let copyPool =
      this.state.currentStep.id === FORM_STEP_ENUM.VALIDITY && rewardDetails.validity.rewardModifyOrNew === "create";

    const steps = this.getFormSteps(rewardDetails);
    this.saveRewardChanges(reward, rewardId, copyPool);
    if (this.state.currentStep.id === steps[steps.length - 1].id) {
      this.setState({
        redirectToOverview: true,
        copyPool: true
      });
    }
  }

  shouldBlockNavigation() {
    if (this.state.isUploadNotAddedToPool) {
      return true;
    }

    if (this.props.editRewardId) {
      let rewardDetails = { ...this.state.rewardDetails };
      let reward = { ...this.props.reward };

      return !deepEqual(rewardDetails, reward);
    }

    return false;
  }

  onCustomConfirmNavigation() {
    this.initEditReward();
  }

  onCustomConfirmNavigationSubmit(callback) {
    this.setState(
      {
        isUploadNotAddedToPool: false
      },
      callback
    );
  }

  onLangInputChange(event, lang, customValue) {
    let translationErrors;
    const translations = { ...this.state.translations };
    const { name } = getInputNameAndValue(event);

    setTranslationsOnInputChange({ translations, event, lang, customValue });

    const hasValue = _values(translations[name]).some((v) => !!v);
    const validateFunc = this.validator.validateMultiLangPopup;

    if (hasValue) {
      translationErrors = validateSingleMultiLangValue(
        () => validateFunc(translations),
        this.state.translationErrors,
        name,
        lang
      );
    } else {
      translationErrors = validateFunc(translations);
    }

    this.setState({
      translations,
      translationErrors
    });
  }

  onMultiLangPopupSave() {
    let { rewardDetails } = { ...this.state };
    const translations = { ...this.state.translations };

    const translationErrors = this.validator.validateMultiLangPopup(translations);

    if (!translationErrors) {
      multiLangUpdateState({
        stateObj: rewardDetails,
        translations,
        propsMap: MULTI_LANG_PROPS_MAP,
        primaryLanguage: this.props.primaryLanguage
      });

      this.setState({
        rewardDetails,
        showMultiLangPopup: false
      });
    }

    this.setState({
      translationErrors
    });
  }

  onShowMultiLangPopup(propList) {
    let { rewardDetails } = { ...this.state };
    const propsMap = filterObjectByKeys(MULTI_LANG_PROPS_MAP, propList);
    const translations = initTranslationsObj({
      stateObj: rewardDetails,
      propsMap,
      primaryLanguage: this.props.primaryLanguage
    });

    this.setState({
      translations,
      translationErrors: undefined,
      showMultiLangPopup: true
    });
  }

  getMultiLangPopupFields() {
    const translations = { ...this.state.translations };

    if (!!translations.voucherNameML) {
      return <MultiLangVoucherNameField disabled={this.viewMode} />;
    } else if (!!translations["redemption.message.textML"]) {
      return <MultiLangRedemptionMessageFields masterReward={this.masterReward} disabled={this.viewMode} />;
    } else if (!!translations["validity.defaultMessageML"]) {
      return <MultiLangDefaultTextField disabled={this.viewMode} />;
    } else if (!!translations["blockExternalLink.urlML"]) {
      return <MultiLangExternalBlockLinkFields disabled={this.viewMode} errors={this.state.translationErrors} />;
    }

    return null;
  }

  getNotificationMessage() {
    const masterReward = this.masterReward;
    if (rewardService.isRewardForFree(masterReward))
      return <Notification content={getMessage("rewardSetupRedemption.notification.vouchersForFree")} />;
    if (rewardService.isRewardForPoints(masterReward))
      return <Notification content={getMessage("rewardSetupRedemption.notification.vouchersForPoints")} />;
    if (rewardService.isCashback(masterReward))
      return <Notification content={getMessage("rewardSetupRedemption.notification.vouchersAsCashback")} />;
    return <Notification content="" />;
  }

  renderFormStepsComponents(reward) {
    let result = [];

    const steps = this.getFormSteps(reward);
    steps.forEach((step) => {
      if (step.id === FORM_STEP_ENUM.POOL_SETTINGS) {
        result.push(
          <RewardSetupPoolSettings
            key={step.id}
            reward={reward}
            onInputChange={this.onInputChange.bind(this)}
            onIconInputChange={this.onIconInputChange.bind(this)}
            onVoucherTypeChange={this.onVoucherTypeChange.bind(this)}
            onShowVoucherNameMultiLang={() => this.onShowMultiLangPopup(["voucherNameML"])}
            onBlockExternalLinkMultiLang={() =>
              this.onShowMultiLangPopup(["blockExternalLink.urlML", "blockExternalLink.textML"])
            }
            voucherTypeOptions={VOUCHER_TYPE_OPTIONS}
            hasMultiLanguage={this.props.hasMultiLanguage}
            errors={this.state.errors}
            viewMode={this.viewMode}
          />
        );
      }
      if (step.id === FORM_STEP_ENUM.REDEMPTION_VALUE || step.id === FORM_STEP_ENUM.VOUCHERS_FOR_POINTS)
        result.push(
          <RewardSetupRedemptionValue
            key={step.id}
            reward={reward}
            onInputChange={this.onInputChange.bind(this)}
            onRedemptionValueChange={this.onRedemptionValueChange.bind(this)}
            tiers={this.state.tiersListAccordion}
            viewMode={this.viewMode}
            hasRedemptionValueEnabled={this.hasRedemptionValueEnabled()}
            onAccordionStateChange={this.setTiersListAccordionOpenState.bind(this)}
            onShowRedemptionMessageMultiLang={() =>
              this.onShowMultiLangPopup(["redemption.message.titleML", "redemption.message.textML"])
            }
            updateState={this.updateState.bind(this)}
            notificationElem={this.getNotificationMessage()}
            hasMultiLanguage={this.props.hasMultiLanguage}
            errors={this.state.errors}
            onBlockExternalLinkMultiLang={() =>
              this.onShowMultiLangPopup(["blockExternalLink.urlML", "blockExternalLink.textML"])
            }
            currency={this.props.currencies[0]}
          />
        );
      if (step.id === FORM_STEP_ENUM.UPLOAD_FILE)
        result.push(
          <RewardSetupUpload
            key={step.id}
            reward={reward}
            rewardId={this.props.editRewardId}
            statusObj={this.statusObj}
            onInputChange={this.onInputChange.bind(this)}
            onAddToPool={this.onAddToPool.bind(this)}
            onDeletePool={this.onDeletePool.bind(this)}
            onDeleteCurrentUpload={this.onDeleteCurrentUpload.bind(this)}
            onUploadPool={this.onUploadPool.bind(this)}
            errors={this.state.errors}
            viewMode={this.viewMode}
            refillReminder={this.state.rewardDetails.validity.refillReminder}
          />
        );
      if (step.id === FORM_STEP_ENUM.VALIDITY)
        result.push(
          <RewardSetupValidity
            key={step.id}
            reward={reward}
            isPoolValidityDateValid={this.isPoolValidityDateValid()}
            isPoolValidityAmountValid={this.isPoolValidityAmountValid()}
            onInputChange={this.onInputChange.bind(this)}
            onPoolValidityChange={this.onPoolValidityChange.bind(this)}
            onValidityTypeChange={this.onValidityTypeChange.bind(this)}
            onShowDefaultTextMultiLang={() => this.onShowMultiLangPopup(["validity.defaultMessageML"])}
            updateState={this.updateState.bind(this)}
            errors={this.state.errors}
            viewMode={this.viewMode}
            rewardStatus={this.statusObj.status}
          />
        );
    });

    return result;
  }

  saveRewardChanges(reward, id, copyPool) {
    if (copyPool) this.props.createFromExisting(reward, id, false);
    else if (!id) this.props.createReward(reward, false);
    else this.props.updateReward(reward, id, false);
  }

  render() {
    const reward = this.state.rewardDetails;

    if (!reward || !this.props.reward) {
      return null;
    }

    let title;
    if (this.props.isNewReward) {
      title = getMessage("rewardSetup.title.new");
    } else {
      title = `${this.props.reward.name}`;
    }

    const formClassName = classNames("reward-setup-form");

    const multiLangPopupTitle = this.isMultiLangUrlField ? getMessage("rewardSetup.multiLangPopupTitle") : "";

    return (
      <Fragment>
        <FormStepsContainer
          id={this.props.editRewardId}
          title={title}
          steps={this.getFormSteps(reward)}
          onSubmit={this.onSubmit.bind(this)}
          onStepChange={this.onStepChange.bind(this)}
          nextStepOnSubmit={true}
          applySubmitOnNextStep={true}
          overviewLink={this.overviewLink}
          className={formClassName}
          triggerSubmit={this.state.triggerSubmit}
          validateStepFunc={this.validateStep.bind(this)}
          shouldBlockNavigationFunc={this.shouldBlockNavigation.bind(this)}
          onCustomConfirmNavigation={this.onCustomConfirmNavigation.bind(this)}
          onCustomConfirmNavigationSubmit={this.onCustomConfirmNavigationSubmit.bind(this)}
          disableSubmitButton={this.viewMode}
          startStepId={this.startStepId}
          hasErrors={!!this.state.errors}
          shouldBlockNavigationOnSubmit={this.state.isUploadNotAddedToPool}
          confirmSubmitMessage={this.state.isUploadNotAddedToPool ? getMessage("rewardSetup.confirmSubmitMessage") : ""}
        >
          {this.renderFormStepsComponents(reward)}
        </FormStepsContainer>
        <MultiLangPopup
          id={"reward_setup_multi_lang"}
          show={this.state.showMultiLangPopup}
          languages={this.props.languages}
          translations={this.state.translations}
          hasErrors={!!this.state.translationErrors}
          element={this.getMultiLangPopupFields()}
          showNoticeForDefault={!this.isMultiLangUrlField}
          onChange={this.onLangInputChange.bind(this)}
          onSave={this.onMultiLangPopupSave.bind(this)}
          disableSave={this.viewMode}
          onClose={() => {
            this.setState({
              showMultiLangPopup: false
            });
          }}
          title={multiLangPopupTitle}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const newRewardId = selectNewRewardId(state);
  const editRewardId = ownProps.match.params.id || newRewardId;
  const dupRewardId = ownProps.match.params.dupId;

  const rewardId = editRewardId || dupRewardId;

  const isCurrentPlanExists = selectIsCurrentPlanVersionExists(state);
  const plan = isCurrentPlanExists ? selectCurrentPlanVersion(state) : selectPlan(state);

  return {
    planHasRedemption: planService.hasRedemption(plan),
    tiersById: isCurrentPlanExists ? selectCurrentPlanTiersMapById(state) : selectTiersMapById(state),
    tiers: selectTiers(state),
    reward: rewardId ? selectRewardById(state, rewardId) : {},
    editRewardId,
    dupRewardId,
    newRewardId,
    isNewReward: !ownProps.match.params.id,
    postSuccess: isRewardPostSuccess(state),
    rewardNames: selectRewardNames(state),
    actions: selectActions(state),
    fixedBenefits: selectFixedBenefits(state),
    additionalBenefits: selectAdditionalBenefits(state),
    rewardEditState: selectRewardEditState(state),
    planRewardsAllocationDelayValue: selectRewardsAllocationDelay(state),
    languages: selectPlanLanguages(state),
    primaryLanguage: selectPlanPrimaryLanguage(state),
    hasMultiLanguage: selectPlanHasMultiLang(state),
    hasMultiMarket: selectPlanHasMultiMarket(state),
    currencies: selectPlanCurrencies(state),
    plan,
    isAccountViewOnly: isAccountViewOnly(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createFromExisting: (reward, id, disableAlert) => {
      dispatch(createRewardFromExisting(reward, id, disableAlert));
    },
    createReward: (reward, disableAlert) => {
      dispatch(createReward(reward, disableAlert));
    },
    updateReward: (reward, id, disableAlert) => {
      dispatch(updateReward(reward, id, disableAlert));
    },
    setRewardEditState: (reward, stepId) => {
      dispatch(setRewardEditState(reward, stepId));
    },
    resetRewardEditState: () => {
      dispatch(resetRewardEditState());
    },
    resetNewRewardId: () => {
      dispatch(resetNewRewardId());
    },
    addUploadToRewardPool: (rewardId, upload) => {
      dispatch(addUploadToRewardPool(rewardId, upload));
    },
    deleteRewardPool: (rewardId) => {
      dispatch(deleteRewardPool(rewardId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RewardSetup);
