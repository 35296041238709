import axios from "axios";
import sessionHelper from "../helpers/sessionHelper";

export default class currentPlanVersionApi {
  static async getCurrentPlanVersion(planId) {
    const planIdParam = planId || sessionHelper.getPlanId();
    const response = await axios.get(`/plan/${planIdParam}/current-version`);
    return response.data;
  }

  static async getPlanLaunchStatus(planVersionId) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.get(`/plan/${planId}/plan-launch-status/${planVersionId}`);
    return response.data;
  }
}
