import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Prompt } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

import SubmitFormHeader from "../components/SubmitFormHeader";

import { showErrorAlert, hideErrorAlert } from "../actions/alertActions";

import { renderConfirmNavDialog } from "../helpers/navHelper";
import { getMessage } from "../messages";

class FormStepsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStepIndex: 0,
      highestStepReached: 0,
      whenPromptOnLeave: false,
      confirmStepIndex: null
    };
  }

  componentDidMount() {
    this.setState({
      whenPromptOnLeave: true
    });

    if (this.props.startStepId) {
      this.setState({
        activeStepIndex: this.props.steps.findIndex((step) => step.id === this.props.startStepId)
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.triggerSubmit && this.props.triggerSubmit) {
      this.onSubmit();
    }

    //if goToStep defined in props - change step.
    //the container component side should set goToSetp to null on step change
    if (this.props.goToStep !== undefined && this.props.goToStep != prevState.activeStepIndex) {
      this.changeStep(this.props.goToStep);
    }

    if (prevProps.hasErrors && !this.props.hasErrors) {
      this.props.hideErrorAlert();
    }
  }

  showValidationErrorAlert() {
    const message = this.props.customErrorAlertMessage || getMessage("form.missingSettingsAlert");

    this.props.showErrorAlert(message);
  }

  changeStep(stepIndex) {
    const { props } = this;

    this.setState({
      activeStepIndex: stepIndex,
      whenPromptOnLeave: true
    });

    this.props.hideErrorAlert();

    props.onStepChange && props.onStepChange(props.steps[stepIndex]);
  }

  onStepChange(stepIndex) {
    const { props } = this;

    if (stepIndex === this.state.activeStepIndex) {
      return;
    }

    if (this.isStepDisabled(stepIndex)) {
      return;
    }

    //if props.nextStepOnSubmit check if step change is allowd according to shouldBlockNavigationFunc
    if (
      props.nextStepOnSubmit &&
      props.applySubmitOnNextStep &&
      props.shouldBlockNavigationFunc &&
      props.shouldBlockNavigationFunc()
    ) {
      this.setState({
        confirmStepIndex: stepIndex
      });
      renderConfirmNavDialog(this.onCustomConfirmNavigation.bind(this), null, props.confirmSubmitMessage);
    } else {
      this.changeStep(stepIndex);
    }
  }

  onCustomConfirmNavigation() {
    const { confirmStepIndex } = this.state;

    if (confirmStepIndex !== null) {
      this.changeStep(confirmStepIndex);
      this.props.onCustomConfirmNavigation();
    }
  }

  onCustomConfirmNavigationSubmit() {
    this.props.onCustomConfirmNavigationSubmit(this.onSubmit.bind(this));
  }

  isStepDisabled(stepIndex) {
    const { steps } = this.props;

    if (steps[stepIndex].disabled !== undefined) {
      return steps[stepIndex].disabled;
    }

    return (
      (this.props.nextStepOnSubmit && stepIndex > this.state.highestStepReached) ||
      stepIndex < 0 ||
      stepIndex >= steps.length
    );
  }

  isPrevDisabled() {
    return this.state.activeStepIndex == 0;
  }

  isNextDisabled() {
    const { activeStepIndex } = this.state;
    const { steps } = this.props;

    return activeStepIndex == steps.length - 1 || this.isStepDisabled(activeStepIndex + 1);
  }

  getStepStatus(stepIndex) {
    if (this.state.activeStepIndex == stepIndex) {
      return "current";
    }

    if (this.isStepDisabled(stepIndex)) {
      return "disabled";
    }

    return "action";
  }

  onSubmit(event) {
    const { props } = this;

    if (event) {
      event.preventDefault();
    }

    if (props.shouldBlockNavigationOnSubmit) {
      renderConfirmNavDialog(this.onCustomConfirmNavigationSubmit.bind(this), null, props.confirmSubmitMessage);
      return;
    }

    // if validate function provided, execute and return if not valid
    // if validate function provided, execute and return if not valid
    if (props.validateStepFunc && !props.validateStepFunc()) {
      this.showValidationErrorAlert();
      return;
    }

    if (props.nextStepOnSubmit) {
      const { activeStepIndex } = this.state;

      const step = this.props.steps[activeStepIndex];
      const nextStepIndex = step.nextStepIndex === undefined ? activeStepIndex + 1 : step.nextStepIndex;

      if (props.applySubmitOnNextStep) {
        this.callSubmit();
        if (nextStepIndex < props.steps.length) {
          this.changeStep(nextStepIndex);
        }
      } else {
        //if last tab - submit
        nextStepIndex === props.steps.length ? this.callSubmit() : this.changeStep(nextStepIndex);

        this.setState({
          highestStepReached: nextStepIndex
        });
      }
    } else {
      this.callSubmit();
    }
  }

  isHideSubmitInStep() {
    return (
      Array.isArray(this.props.hideSubmitInSteps) && this.props.hideSubmitInSteps.includes(this.state.activeStepIndex)
    );
  }

  callSubmit() {
    this.setState({
      whenPromptOnLeave: false
    });
    this.props.onSubmit();
  }

  renderSteps() {
    return this.props.steps.map((step, index) => {
      return (
        <span
          key={`step${index}`}
          onClick={() => this.onStepChange(index)}
          className={`e-steps__item e-steps__item-${this.getStepStatus(index)}`}
        >
          {step.title || step}
        </span>
      );
    });
  }

  renderStepsContainer() {
    const prevClassNames = classNames("e-steps__nav e-steps__nav-prev", {
      "e-steps__nav-disabled": this.isPrevDisabled()
    });
    const nextClassNames = classNames("e-steps__nav e-steps__nav-next", {
      "e-steps__nav-disabled": this.isNextDisabled()
    });
    return (
      <div className="e-steps">
        <span
          onClick={() => !this.isPrevDisabled() && this.onStepChange(this.state.activeStepIndex - 1)}
          className={prevClassNames}
        >
          <e-icon icon="e-caret-left" />
          <span>{getMessage("formStepsContainer.prevStep.text")}</span>
        </span>
        <span
          onClick={() => !this.isNextDisabled() && this.onStepChange(this.state.activeStepIndex + 1)}
          className={nextClassNames}
        >
          <span>{getMessage("formStepsContainer.nextStep.text")}</span>
          <e-icon icon="e-caret-right" />
        </span>
        <div className="e-steps__progress">{this.renderSteps()}</div>
      </div>
    );
  }

  renderContent() {
    return this.props.children[this.state.activeStepIndex];
  }

  renderSubmitHeader() {
    return (
      <SubmitFormHeader
        title={this.props.title}
        overviewLink={this.props.overviewLink}
        alertElement={this.props.alertElement}
        disableSubmitButton={this.props.disableSubmitButton}
        hideSubmitButton={this.props.hideSubmitButton || this.isHideSubmitInStep()}
        showMultiPlanSelect={this.props.showMultiPlanSelect}
      />
    );
  }

  render() {
    return (
      <Fragment>
        <form
          className={classNames("e-layout e-layout-without_navigation", this.props.className)}
          onSubmit={this.onSubmit.bind(this)}
          id={this.props.id || ""}
        >
          {this.renderSubmitHeader()}
          {this.renderStepsContainer()}
          {this.renderContent()}
        </form>
        {this.props.shouldBlockNavigationFunc ? (
          <Prompt
            when={this.state.whenPromptOnLeave && this.props.shouldBlockNavigationFunc()}
            message={this.props.confirmSubmitMessage || ""}
          />
        ) : null}
      </Fragment>
    );
  }
}

FormStepsContainer.propTypes = {
  id: PropTypes.string,
  steps: PropTypes.array.isRequired,
  children: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disableSubmitButton: PropTypes.bool,
  hideSubmitButton: PropTypes.bool,
  onStepChange: PropTypes.func,
  nextStepOnSubmit: PropTypes.bool,
  applySubmitOnNextStep: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  overviewLink: PropTypes.string,
  className: PropTypes.string,
  hideSubmitInSteps: PropTypes.array,
  triggerSubmit: PropTypes.bool,
  validateStepFunc: PropTypes.func,
  shouldBlockNavigationFunc: PropTypes.func,
  viewMode: PropTypes.bool,
  alertElement: PropTypes.element,
  goToStep: PropTypes.number,
  onCustomConfirmNavigation: PropTypes.func,
  onCustomConfirmNavigationSubmit: PropTypes.func,
  startStepId: PropTypes.string,
  hasErrors: PropTypes.bool,
  customErrorAlertMessage: PropTypes.string,
  showMultiPlanSelect: PropTypes.bool,
  shouldBlockNavigationOnSubmit: PropTypes.bool,
  confirmSubmitMessage: PropTypes.string
};

const mapDispatchToProps = (dispatch) => {
  return {
    showErrorAlert: (message) => {
      dispatch(showErrorAlert(message, undefined, true));
    },
    hideErrorAlert: () => {
      dispatch(hideErrorAlert());
    }
  };
};

export default connect(null, mapDispatchToProps)(FormStepsContainer);
