import axios from "axios";
import sessionHelper from "../helpers/sessionHelper";

export default class benefitApi {
  static async getFixedBenefits() {
    const planId = sessionHelper.getPlanId();
    const response = await axios.get(`/plan/${planId}/fixed-benefits`);
    return response.data;
  }

  static async updateFixedBenefits(benefits) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.post(`/plan/${planId}/fixed-benefits`, benefits);
    return response.data;
  }

  static async getAdditionalBenefits() {
    const planId = sessionHelper.getPlanId();
    const response = await axios.get(`/plan/${planId}/additional-benefits`);
    return response.data;
  }

  static async updateAdditionalBenefits(benefits) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.post(`/plan/${planId}/additional-benefits`, benefits);
    return response.data;
  }

  static async getTotalActiveAdditionalBenefits(benefitId) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.get(`/plan/${planId}/additional-benefit/${benefitId}/total-active`);
    return response.data;
  }
}
