import React from "react";
import PropTypes from "prop-types";

const HeaderWithMessageTooltip = (props) => {
  return (
    <div className="e-grid e-grid-between m-b-15" style={{ alignItems: "center" }}>
      <div className="e-cell">
        <h2 className="no-margin">
          {props.headerText} <e-tooltip content={props.headerTooltip} type="helper" />
        </h2>
      </div>
      <div className="e-cell">
        <e-tooltip content={props.messageTooltipContent}>
          <a>{props.messageTooltipText}</a>
        </e-tooltip>
      </div>
    </div>
  );
};

HeaderWithMessageTooltip.propTypes = {
  headerText: PropTypes.string,
  headerTooltip: PropTypes.string,
  messageTooltipText: PropTypes.string,
  messageTooltipContent: PropTypes.string
};

export default HeaderWithMessageTooltip;
