export const CURRENCY_SYMBOL_MAP = {
  AED: "د.إ",
  AFN: "؋",
  ALL: "L",
  AMD: "֏",
  ANG: "ƒ",
  AOA: "Kz",
  ARS: "$",
  AUD: "$",
  AWG: "ƒ",
  AZN: "₼",
  BAM: "KM",
  BBD: "$",
  BDT: "৳",
  BGN: "лв",
  BHD: ".د.ب",
  BIF: "FBu",
  BMD: "$",
  BND: "$",
  BOB: "$b",
  BRL: "R$",
  BSD: "$",
  BTC: "฿",
  BTN: "Nu.",
  BWP: "P",
  BYR: "Br",
  BYN: "Br",
  BZD: "BZ$",
  CAD: "$",
  CDF: "FC",
  CHF: "CHF",
  CLP: "$",
  CNY: "¥",
  COP: "$",
  CRC: "₡",
  CUC: "$",
  CUP: "₱",
  CVE: "$",
  CZK: "Kč",
  DJF: "Fdj",
  DKK: "kr",
  DOP: "RD$",
  DZD: "دج",
  EEK: "kr",
  EGP: "£",
  ERN: "Nfk",
  ETB: "Br",
  ETH: "Ξ",
  EUR: "€",
  FJD: "$",
  FKP: "£",
  GBP: "£",
  GEL: "₾",
  GGP: "£",
  GHC: "₵",
  GHS: "GH₵",
  GIP: "£",
  GMD: "D",
  GNF: "FG",
  GTQ: "Q",
  GYD: "$",
  HKD: "$",
  HNL: "L",
  HRK: "kn",
  HTG: "G",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  IMP: "£",
  INR: "₹",
  IQD: "ع.د",
  IRR: "﷼",
  ISK: "kr",
  JEP: "£",
  JMD: "J$",
  JOD: "JD",
  JPY: "¥",
  KES: "KSh",
  KGS: "лв",
  KHR: "៛",
  KMF: "CF",
  KPW: "₩",
  KRW: "₩",
  KWD: "KD",
  KYD: "$",
  KZT: "лв",
  LAK: "₭",
  LBP: "£",
  LKR: "₨",
  LRD: "$",
  LSL: "M",
  LTC: "Ł",
  LTL: "Lt",
  LVL: "Ls",
  LYD: "LD",
  MAD: "MAD",
  MDL: "lei",
  MGA: "Ar",
  MKD: "ден",
  MMK: "K",
  MNT: "₮",
  MOP: "MOP$",
  MRO: "UM",
  MRU: "UM",
  MUR: "₨",
  MVR: "Rf",
  MWK: "MK",
  MXN: "$",
  MYR: "RM",
  MZN: "MT",
  NAD: "$",
  NGN: "₦",
  NIO: "C$",
  NOK: "kr",
  NPR: "₨",
  NZD: "$",
  OMR: "﷼",
  PAB: "B/.",
  PEN: "S/.",
  PGK: "K",
  PHP: "₱",
  PKR: "₨",
  PLN: "zł",
  PYG: "Gs",
  QAR: "﷼",
  RMB: "￥",
  RON: "lei",
  RSD: "Дин.",
  RUB: "₽",
  RWF: "R₣",
  SAR: "﷼",
  SBD: "$",
  SCR: "₨",
  SDG: "ج.س.",
  SEK: "kr",
  SGD: "$",
  SHP: "£",
  SLL: "Le",
  SOS: "S",
  SRD: "$",
  SSP: "£",
  STD: "Db",
  STN: "Db",
  SVC: "$",
  SYP: "£",
  SZL: "E",
  THB: "฿",
  TJS: "SM",
  TMT: "T",
  TND: "د.ت",
  TOP: "T$",
  TRL: "₤",
  TRY: "₺",
  TTD: "TT$",
  TVD: "$",
  TWD: "NT$",
  TZS: "TSh",
  UAH: "₴",
  UGX: "USh",
  USD: "$",
  UYU: "$U",
  UZS: "лв",
  VEF: "Bs",
  VND: "₫",
  VUV: "VT",
  WST: "WS$",
  XAF: "FCFA",
  XBT: "Ƀ",
  XCD: "$",
  XOF: "CFA",
  XPF: "₣",
  YER: "﷼",
  ZAR: "R",
  ZWD: "Z$"
};

export const CURRENCY_TITLE_MAP = {
  AED: "United Arab Emirates Dirham",
  AFN: "Afghanistan Afghani",
  ALL: "Albania Lek",
  AMD: "Armenia Dram",
  ANG: "Netherlands Antilles Guilder",
  AOA: "Angola Kwanza",
  ARS: "Argentina Peso",
  AUD: "Australia Dollar",
  AWG: "Aruba Guilder",
  AZN: "Azerbaijan Manat",
  BAM: "Bosnia and Herzegovina Convertible Marka",
  BBD: "Barbados Dollar",
  BDT: "Bangladesh Taka",
  BGN: "Bulgaria Lev",
  BHD: "Bahrain Dinar",
  BIF: "Burundi Franc",
  BMD: "Bermuda Dollar",
  BND: "Brunei Darussalam Dollar",
  BOB: "Bolivia Bolíviano",
  BRL: "Brazil Real",
  BSD: "Bahamas Dollar",
  BTN: "Bhutan Ngultrum",
  BWP: "Botswana Pula",
  BYN: "Belarus Ruble",
  BZD: "Belize Dollar",
  CAD: "Canada Dollar",
  CDF: "Congo/Kinshasa Franc",
  CHF: "Switzerland Franc",
  CLP: "Chile Peso",
  CNY: "China Yuan Renminbi",
  COP: "Colombia Peso",
  CRC: "Costa Rica Colon",
  CUC: "Cuba Convertible Peso",
  CUP: "Cuba Peso",
  CVE: "Cape Verde Escudo",
  CZK: "Czech Republic Koruna",
  DJF: "Djibouti Franc",
  DKK: "Denmark Krone",
  DOP: "Dominican Republic Peso",
  DZD: "Algeria Dinar",
  EGP: "Egypt Pound",
  ERN: "Eritrea Nakfa",
  ETB: "Ethiopia Birr",
  EUR: "Euro Member Countries",
  FJD: "Fiji Dollar",
  FKP: "Falkland Islands (Malvinas) Pound",
  GBP: "United Kingdom Pound",
  GEL: "Georgia Lari",
  GGP: "Guernsey Pound",
  GHS: "Ghana Cedi",
  GIP: "Gibraltar Pound",
  GMD: "Gambia Dalasi",
  GNF: "Guinea Franc",
  GTQ: "Guatemala Quetzal",
  GYD: "Guyana Dollar",
  HKD: "Hong Kong Dollar",
  HNL: "Honduras Lempira",
  HRK: "Croatia Kuna",
  HTG: "Haiti Gourde",
  HUF: "Hungary Forint",
  IDR: "Indonesia Rupiah",
  ILS: "Israel Shekel",
  IMP: "Isle of Man Pound",
  INR: "India Rupee",
  IQD: "Iraq Dinar",
  IRR: "Iran Rial",
  ISK: "Iceland Krona",
  JEP: "Jersey Pound",
  JMD: "Jamaica Dollar",
  JOD: "Jordan Dinar",
  JPY: "Japan Yen",
  KES: "Kenya Shilling",
  KGS: "Kyrgyzstan Som",
  KHR: "Cambodia Riel",
  KMF: "Comorian Franc",
  KPW: "Korea (North) Won",
  KRW: "Korea (South) Won",
  KWD: "Kuwait Dinar",
  KYD: "Cayman Islands Dollar",
  KZT: "Kazakhstan Tenge",
  LAK: "Laos Kip",
  LBP: "Lebanon Pound",
  LKR: "Sri Lanka Rupee",
  LRD: "Liberia Dollar",
  LSL: "Lesotho Loti",
  LYD: "Libya Dinar",
  MAD: "Morocco Dirham",
  MDL: "Moldova Leu",
  MGA: "Madagascar Ariary",
  MKD: "Macedonia Denar",
  MMK: "Myanmar (Burma) Kyat",
  MNT: "Mongolia Tughrik",
  MOP: "Macau Pataca",
  MRU: "Mauritania Ouguiya",
  MUR: "Mauritius Rupee",
  MVR: "Maldives (Maldive Islands) Rufiyaa",
  MWK: "Malawi Kwacha",
  MXN: "Mexico Peso",
  MYR: "Malaysia Ringgit",
  MZN: "Mozambique Metical",
  NAD: "Namibia Dollar",
  NGN: "Nigeria Naira",
  NIO: "Nicaragua Cordoba",
  NOK: "Norway Krone",
  NPR: "Nepal Rupee",
  NZD: "New Zealand Dollar",
  OMR: "Oman Rial",
  PAB: "Panama Balboa",
  PEN: "Peru Sol",
  PGK: "Papua New Guinea Kina",
  PHP: "Philippines Piso",
  PKR: "Pakistan Rupee",
  PLN: "Poland Zloty",
  PYG: "Paraguay Guarani",
  QAR: "Qatar Riyal",
  RON: "Romania Leu",
  RSD: "Serbia Dinar",
  RUB: "Russia Ruble",
  RWF: "Rwanda Franc",
  SAR: "Saudi Arabia Riyal",
  SBD: "Solomon Islands Dollar",
  SCR: "Seychelles Rupee",
  SDG: "Sudan Pound",
  SEK: "Sweden Krona",
  SGD: "Singapore Dollar",
  SHP: "Saint Helena Pound",
  SLL: "Sierra Leone Leone",
  SOS: "Somalia Shilling",
  SPL: "Seborga Luigino",
  SRD: "Suriname Dollar",
  STN: "São Tomé and Príncipe Dobra",
  SVC: "El Salvador Colon",
  SYP: "Syria Pound",
  SZL: "Swaziland Lilangeni",
  THB: "Thailand Baht",
  TJS: "Tajikistan Somoni",
  TMT: "Turkmenistan Manat",
  TND: "Tunisia Dinar",
  TOP: "Tonga Pa'anga",
  TRY: "Turkey Lira",
  TTD: "Trinidad and Tobago Dollar",
  TVD: "Tuvalu Dollar",
  TWD: "Taiwan New Dollar",
  TZS: "Tanzania Shilling",
  UAH: "Ukraine Hryvnia",
  UGX: "Uganda Shilling",
  USD: "United States Dollar",
  UYU: "Uruguay Peso",
  UZS: "Uzbekistan Som",
  VEF: "Venezuela Bolívar",
  VND: "Viet Nam Dong",
  VUV: "Vanuatu Vatu",
  WST: "Samoa Tala",
  XAF: "Communauté Financière Africaine (BEAC) CFA Franc BEAC",
  XCD: "East Caribbean Dollar",
  XDR: "International Monetary Fund (IMF) Special Drawing Rights",
  XOF: "Communauté Financière Africaine (BCEAO) Franc",
  XPF: "Comptoirs Français du Pacifique (CFP) Franc",
  YER: "Yemen Rial",
  ZAR: "South Africa Rand",
  ZMW: "Zambia Kwacha",
  ZWD: "Zimbabwe Dollar"
};

export const CURRENCY_OPTIONS = Object.keys(CURRENCY_TITLE_MAP).map((key) => {
  return {
    value: key,
    name: CURRENCY_TITLE_MAP[key]
  };
});
