import axios from "axios";

export default class memberProfileApi {
  static getMemberProfilesOverviewUrl() {
    return `${axios.defaults.baseURL}/member-profiles-overview`;
  }

  static async searchMember({ planId, searchValue }) {
    const response = await axios.get(`/plan/${planId}/member-search`, {
      params: {
        q: searchValue
      }
    });
    return response.data;
  }

  static async searchMemberProfilesOverview({ searchValue, searchGroup = false }) {
    const response = await axios.get(`/member-profiles-overview-search`, {
      params: {
        q: searchValue,
        searchGroup
      }
    });
    return response.data;
  }

  static async getMemberProfileDetails(contactId) {
    const response = await axios.get(`/contact/${contactId}/member-profile-details`);
    return response.data;
  }

  static async getMemberProfilePersonalInfo(contactId) {
    const response = await axios.get(`/contact/${contactId}/member-profile-personal-info`);
    return response.data;
  }

  static async getMemberProfileActivities(contactId) {
    const response = await axios.get(`/contact/${contactId}/member-profile-activities`);
    return response.data;
  }

  static async updateMemberPoints(contactId, { pointsType, pointsUpdateType, pointsValue, reason, description }) {
    const response = await axios.post(`/contact/${contactId}/member-update-points`, {
      pointsType,
      pointsUpdateType,
      pointsValue,
      reason,
      description
    });
    return response;
  }

  static async updateMemberTier(
    contactId,
    { tierUpdateType, selectedTierId, reason, activityName, currentTierId, tierDowngradeType }
  ) {
    const response = await axios.post(`/contact/${contactId}/member-update-tier`, {
      tierUpdateType,
      selectedTierId,
      reason,
      activityName,
      currentTierId,
      tierDowngradeType
    });
    return response;
  }

  static async changeMemberPlan(
    contactId,
    { planId, balancePointsMigrate, statusPointsMigrate, pendingPointsMigrate, redeemedVouchersMigrate }
  ) {
    const response = await axios.post(`/contact/${contactId}/member-change-plan`, {
      planId,
      balancePointsMigrate,
      statusPointsMigrate,
      pendingPointsMigrate,
      redeemedVouchersMigrate
    });
    return response;
  }

  static async getMemberAdditionalBenefits(contactId) {
    const response = await axios.get(`/contact/${contactId}/member-additional-benefits`);
    return response.data;
  }

  static async addMemberAdditionalBenefits(contactId, { planId, benefitIds, reason }) {
    const response = await axios.post(`/contact/${contactId}/add-additional-benefits`, {
      planId,
      benefitIds,
      reason
    });
    return response;
  }

  static async removeMemberAdditionalBenefits(contactId, { planId, benefitsWithTrackingToRemove, reason }) {
    const response = await axios.post(`/contact/${contactId}/remove-additional-benefits`, {
      planId,
      benefitsWithTracking: benefitsWithTrackingToRemove,
      reason
    });
    return response;
  }

  static async createMember({ planId, externalId }) {
    const response = await axios.post(`/contact`, { planId, externalId });
    return response.data;
  }

  static async deleteMember(contactId, isFullDelete) {
    const response = await axios.delete(`/contact/${contactId}?isFullDelete=${isFullDelete}`);
    return response;
  }

  static async createGroup({ name, contactIds }) {
    const response = await axios.post(`/member-group`, { name, contactIds });
    return response.data;
  }

  static async deleteGroup(groupId) {
    const response = await axios.delete(`/member-group/${groupId}`);
    return response;
  }

  static async getGroup(groupId) {
    const response = await axios.get(`/member-group/${groupId}`);
    return response.data;
  }

  static async getGroupMembers(groupId) {
    const response = await axios.get(`/member-group/${groupId}/members`);
    return response.data;
  }

  static async getGroupActivities(groupId) {
    const response = await axios.get(`/member-group/${groupId}/activities`);
    return response.data;
  }

  static async addContactsToGroup({ groupId, contactIds }) {
    const response = await axios.put(`/member-group/${groupId}/add-members`, { contactIds });
    return response.data;
  }

  static async removeContactFromGroup(groupId, contactId) {
    const response = await axios.delete(`/member-group/${groupId}/contact/${contactId}`);
    return response.data;
  }

  static async updateGroupPoints(groupId, data) {
    const response = await axios.post(`/member-group/${groupId}/update-points`, data);
    return response;
  }

  static async updateGroupTier(groupId, data) {
    const response = await axios.post(`/member-group/${groupId}/update-tier`, data);
    return response;
  }

  static async resolveContactIds(contactIds) {
    const response = await axios.get(`/resolve-contact-ids`, {
      params: {
        contactIds
      }
    });
    return response.data;
  }

  static async getMemberVouchersCsv({ contactId }) {
    const response = await axios.get(`/contact/${contactId}/member-vouchers-csv`, {
      responseType: "blob"
    });
    return response.data;
  }
}
