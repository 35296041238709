import * as TYPES from "./TYPES";
import benefitApi from "../api/benefitApi";
import { invalidateJwt } from "./sessionActions";

export const getFixedBenefits = () => {
  return (dispatch) => {
    dispatch({
      type: TYPES.GET_FIXED_BENEFITS_REQUEST
    });
    return benefitApi
      .getFixedBenefits()
      .then((data) => {
        dispatch({
          type: TYPES.GET_FIXED_BENEFITS_SUCCESS,
          payload: {
            data
          }
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.GET_FIXED_BENEFITS_FAILURE
        });
      });
  };
};

export const updateFixedBenefits = (benefits) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.UPDATE_FIXED_BENEFITS_REQUEST
    });

    return benefitApi
      .updateFixedBenefits(benefits)
      .then((data) => {
        dispatch({
          type: TYPES.UPDATE_FIXED_BENEFITS_SUCCESS,
          payload: {
            data
          }
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.UPDATE_FIXED_BENEFITS_FAILURE
        });
      });
  };
};

export const getAdditionalBenefits = () => {
  return (dispatch) => {
    dispatch({
      type: TYPES.GET_ADDITIONAL_BENEFITS_FAILURE
    });
    return benefitApi
      .getAdditionalBenefits()
      .then((data) => {
        dispatch({
          type: TYPES.GET_ADDITIONAL_BENEFITS_SUCCESS,
          payload: {
            data
          }
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.GET_ADDITIONAL_BENEFITS_FAILURE
        });
      });
  };
};

export const updateAdditionalBenefits = (benefits) => {
  return function (dispatch) {
    dispatch({
      type: TYPES.UPDATE_ADDITIONAL_BENEFITS_REQUEST
    });

    return benefitApi
      .updateAdditionalBenefits(benefits)
      .then((data) => {
        dispatch({
          type: TYPES.UPDATE_ADDITIONAL_BENEFITS_SUCCESS,
          payload: {
            data
          }
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(invalidateJwt(err.response));
        }
        dispatch({
          type: TYPES.UPDATE_ADDITIONAL_BENEFITS_FAILURE
        });
      });
  };
};
