import validate from "validate.js";
import _isEmpty from "lodash/isEmpty";
import moment from "moment";
import { isEmptyObject } from "../helpers/objectHelper";
import rewardService from "../services/rewardService";
import { getMessage } from "../messages";

export const initValidators = () => {
  validate.options = {
    fullMessages: false
  };

  validate.validators.exclusionIgnoreCase = (value, options) => {
    const { within, message } = options;
    if (within === undefined || within.length === 0) return undefined;
    const withinLower = within.map((str) => {
      return str.toLowerCase();
    });

    return withinLower.includes(value.toLowerCase()) ? message : undefined;
  };

  validate.validators.exclusionOrStartWithIgnoreCase = (value, options) => {
    const { within, message, startsWith } = options;

    if (within === undefined || within.length === 0) return undefined;
    const withinLower = within.map((str) => {
      return str.toLowerCase();
    });

    if (withinLower.includes(value.toLowerCase())) return message;

    if (!!startsWith && startsWith.length > 0) {
      const startsWithLower = startsWith.map((str) => {
        return str.toLowerCase();
      });
      if (startsWithLower.reduce((acc, curr) => value.toLowerCase().startsWith(curr) || acc, false)) return message;
    }

    return undefined;
  };

  validate.validators.dateEnd = (value, options) => {
    const { start, messageEmpty, messageNoStart, messageRangeNotValid } = options;
    const minRangeMinutes = options.minRangeMinutes || 0;

    if (!value) {
      return messageEmpty;
    }

    const end = value;

    if (!start) {
      return messageNoStart;
    }

    if (moment(end).diff(moment(start), "minutes") <= minRangeMinutes) {
      return messageRangeNotValid;
    }

    return undefined;
  };

  validate.validators.dateRange = (value, options) => {
    const { messageEmpty, messageRangeNotValid } = options;
    const minRangeMinutes = options.minRangeMinutes || 0;

    if (!value) {
      return messageEmpty;
    }

    const { start, end } = value;

    if (!start || !end) {
      return messageEmpty;
    }

    if (moment(end).diff(moment(start), "minutes") <= minRangeMinutes) {
      return messageRangeNotValid;
    }

    return undefined;
  };

  validate.validators.timeRange = (value, options) => {
    const { start, messageRangeNotValid } = options;

    if (start >= value) {
      return messageRangeNotValid;
    }

    return undefined;
  };

  validate.validators.isTrue = function (value, options) {
    const { message } = options;

    if (value !== true) {
      return message;
    }

    return undefined;
  };

  validate.validators.urlAllowEmpty = function (value, options, attribute, attributes) {
    if (validate.isEmpty(value)) {
      return;
    }
    return validate.validators.url(value, options, attribute, attributes);
  };

  validate.validators.multiLangField = function (value, options) {
    const { languages, message } = { ...options };
    if (languages.length === 1) {
      return undefined;
    }

    if (!value) {
      return message;
    }

    if (languages.some((lang) => validate.isEmpty(value[lang]))) {
      return message;
    }

    return undefined;
  };

  validate.validators.singleRedemptionValue = function (value, options, key, reward) {
    const { tierId, enabledProp } = options;
    const redemptionValue = reward.redemption.value;

    if (redemptionValue[tierId][enabledProp] && !value) {
      return getMessage("singelRedemtionValue.presence");
    }

    return undefined;
  };

  validate.validators.redemptionMessage = function (value, options, key, reward) {
    const { message, token } = options;

    if (token) {
      value = value.replace(token, "").trim();
    }

    if (rewardService.hasRedemptionValue(reward) && !value) {
      return message;
    }

    return undefined;
  };

  validate.validators.atLeastOneSelected = function (value, options) {
    const { message } = options;

    if (Object.keys(value).some((key) => !!value[key])) {
      return undefined;
    }

    return message;
  };

  validate.validators.array = (arrayItems, itemConstraints) => {
    const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
      const error = validate(item, itemConstraints);
      if (error) errors[index] = { error: error };
      return errors;
    }, {});

    return _isEmpty(arrayItemErrors) ? null : { errors: arrayItemErrors };
  };
};

export const hasError = (errorsObj, field) => {
  return errorsObj && errorsObj[field] !== undefined;
};

export const getErrorMessage = (errorsObj, field) => {
  return errorsObj && errorsObj[field] !== undefined ? errorsObj[field][0] : "";
};

export const getErrorMessageFromArray = (errorsObj, arrFieldName, objectFieldName, index) => {
  if (!errorsObj || !errorsObj[arrFieldName]) {
    return "";
  }

  return errorsObj[arrFieldName][0]?.errors?.[index]?.error?.[objectFieldName];
};

export const extractErrorByName = (errors, name) => {
  if (!errors) {
    return undefined;
  }

  if (errors[name]) {
    let err = {};
    err[name] = errors[name];
    return err;
  }

  return undefined;
};

export const validateSingleValue = (validateFunc, currErrors, name) => {
  let result = { ...currErrors };
  const errors = validateFunc();
  const error = extractErrorByName(errors, name);

  if (error) {
    result = { ...result, ...error };
  } else {
    delete result[name];
  }

  if (isEmptyObject(result)) {
    return undefined;
  }

  return result;
};

export const validateSingleMultiLangValue = (validateFunc, currErrors, name, lang) => {
  const nameToValidate = `${name.replace(".", "\\.")}.${lang}`;
  return validateSingleValue(validateFunc, currErrors, nameToValidate);
};

export const convertPathStrName = (name) => name.replace(/\./g, "\\.");
