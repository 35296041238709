import axios from "axios";
import sessionHelper from "../helpers/sessionHelper";

const getDateParams = ({ startDateStr, endDateStr }) => {
  return {
    startDate: startDateStr,
    endDate: endDateStr
  };
};

async function getReport({ name, startDateStr, endDateStr }) {
  const planId = sessionHelper.getPlanId();
  const { startDate, endDate } = getDateParams({ startDateStr, endDateStr });
  const response = await axios.get(`/plan/${planId}/report/${name}`, {
    params: {
      startDate,
      endDate
    }
  });
  return response.data;
}

export default class reportApi {
  static async getGeneratedRevenueData({ startDateStr, endDateStr }) {
    return getReport({ name: "generated-revenue-data", startDateStr, endDateStr });
  }

  static async getGeneratedRevenueTotals({ startDateStr, endDateStr }) {
    return getReport({ name: "generated-revenue-totals", startDateStr, endDateStr });
  }

  static async getAverageOrderValueData({ startDateStr, endDateStr }) {
    return getReport({ name: "aov-data", startDateStr, endDateStr });
  }

  static async getAverageOrderValueTotals({ startDateStr, endDateStr }) {
    return getReport({ name: "aov-totals", startDateStr, endDateStr });
  }

  static async getPurchaseFrequencyData({ startDateStr, endDateStr }) {
    return getReport({ name: "purchase-freq-data", startDateStr, endDateStr });
  }

  static async getPurchaseFrequencyTotals({ startDateStr, endDateStr }) {
    return getReport({ name: "purchase-freq-totals", startDateStr, endDateStr });
  }

  static async getNumberOfMembersData({ startDateStr, endDateStr }) {
    return getReport({ name: "num-of-members-data", startDateStr, endDateStr });
  }

  static async getNumberOfMembersTotals({ startDateStr, endDateStr }) {
    return getReport({ name: "num-of-members-totals", startDateStr, endDateStr });
  }

  static async getEnrollmentsData({ startDateStr, endDateStr }) {
    return getReport({ name: "enrollments-data", startDateStr, endDateStr });
  }

  static async getEnrollmentsTotals({ startDateStr, endDateStr }) {
    return getReport({ name: "enrollments-totals", startDateStr, endDateStr });
  }

  static async getEngagementRatesData({ startDateStr, endDateStr }) {
    return getReport({ name: "engagement-rates-data", startDateStr, endDateStr });
  }

  static async getEngagementRatesTotals({ startDateStr, endDateStr }) {
    return getReport({ name: "engagement-rates-totals", startDateStr, endDateStr });
  }

  static async getRedemptionRatesData({ startDateStr, endDateStr }) {
    return getReport({ name: "redemption-rates-data", startDateStr, endDateStr });
  }

  static async getRedemptionRatesTotals({ startDateStr, endDateStr }) {
    return getReport({ name: "redemption-rates-totals", startDateStr, endDateStr });
  }

  static async getPointsChartData({ startDateStr, endDateStr }) {
    return getReport({ name: "points-chart-data", startDateStr, endDateStr });
  }

  static async getPointsChartTotals({ startDateStr, endDateStr }) {
    return getReport({ name: "points-chart-totals", startDateStr, endDateStr });
  }

  static async getVouchersChartData({ startDateStr, endDateStr }) {
    return getReport({ name: "vouchers-chart-data", startDateStr, endDateStr });
  }

  static async getVouchersChartTotals({ startDateStr, endDateStr }) {
    return getReport({ name: "vouchers-chart-totals", startDateStr, endDateStr });
  }

  static async getMembersReportCsv() {
    const response = await axios.get(`/report/members-csv`, { responseType: "blob" });
    return response.data;
  }
}
