import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import merge from "deepmerge";
import classNames from "classnames";
import { NavLink, Redirect } from "react-router-dom";

import InputNumberField from "../components/InputNumberField";
import SelectField from "../components/SelectFieldV2";
import LoaderOverlay from "../components/LoaderOverlay";
import Notification from "../components/Notification";

import { selectPlanDistribution, selectIsBeforeFirstPlanLaunch } from "../reducers";
import { showErrorAlert, hideErrorAlert } from "../actions/alertActions";

import { updateStateOnInputChange, getValueByType } from "../helpers/formHelper";

import { getElementInnerHeight } from "../helpers/domHelper";

import planApi from "../api/planApi";

import tiersCreationValidator from "../validators/tiersCreationValidator";
import { getErrorMessage } from "../validators";
import { getMessage } from "../messages";

import { CUSTOMER_CALC_PERIOD_OPTIONS, MAX_NUMBER_OF_TIERS } from "../constants/onBoardingConstants";
import { isEmptyObject } from "../helpers/objectHelper";
import { openConfirmationDialog } from "../helpers/dialogHelper";

import "../assets/styles/Distribution.scss";

class Distribution extends Component {
  constructor(props) {
    super(props);

    this.validator = new tiersCreationValidator();

    const defaultValues = {
      calcPeriod: CUSTOMER_CALC_PERIOD_OPTIONS[3].value,
      tiers: []
    };

    let settings = merge.all([defaultValues, props.distribution]);

    if (settings.tiers.length === 0) {
      for (let i = 0; i < 3; i++) {
        settings.tiers.push({
          spend: 0,
          purchases: 0
        });
      }
    }

    if (settings.tiers.length === 1) {
      settings.tiers.push({
        spend: 0,
        purchases: 0
      });
    }

    this.state = {
      settings,
      chartData: {
        totals: [],
        pcts: []
      },
      loading: false,
      numberOfTiers: settings.tiers.length,
      errors: {},
      tierSettingsErrors: {},
      redirectToTiers: !props.isBeforeFirstLaunch
    };
  }

  componentDidMount() {
    if (this.state.redirectToTiers) {
      return;
    }

    if (this.state.settings.tiers.length > 0) {
      this.updateChart();
    }

    const chartHeight = getElementInnerHeight("tiersCreationContent");
    this.setState({
      chartHeight
    });
  }

  componentWillUnmount() {
    if (this.state.redirectToTiers) {
      return;
    }
    this.props.registerSubmitFunc && this.props.registerSubmitFunc(null);
    this.props.registerValidateFunc && this.props.registerValidateFunc(null);
  }

  updateTierSettingsState(numberToUpdate) {
    let { settings } = { ...this.state };

    if (numberToUpdate > 0) {
      for (let i = 0; i < numberToUpdate; i++) {
        settings.tiers.push({
          spend: 0,
          purchases: 0
        });
      }
    } else {
      settings.tiers.splice(settings.tiers.length - Math.abs(numberToUpdate));
    }

    this.setState({
      settings
    });
  }

  updateChart() {
    const { settings } = { ...this.state };

    const isValid = this.validateAll();

    if (!isValid || settings.tiers.length <= 1) {
      return;
    }

    let data = {
      calcPeriod: settings.calcPeriod,
      tiers: settings.tiers
    };

    let chartData = {
      totals: [],
      pcts: []
    };

    this.setState({
      loading: true
    });

    planApi
      .getDistributionPreview(data)
      .then((res) => {
        if (!res) {
          return;
        }
        for (let i = 0; i < res.totals.length; i++) {
          const xLabel = `Tier ${i + 1}`;
          chartData.totals.push({
            x: xLabel,
            y: res.totals[i]
          });
          chartData.pcts.push({
            x: xLabel,
            y: res.pcts[i]
          });
        }

        this.setState({
          chartData
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  onInputChange(event, customValue) {
    const settings = { ...this.state.settings };

    updateStateOnInputChange({ stateObj: settings, event, customValue });

    this.setState({
      settings
    });
  }

  onNumberOfTiersChange(event, customValue) {
    const value = customValue;

    const errors = this.validator.validateForm({ numberOfTiers: value });

    if (!errors) {
      const numberToUpdate = value - this.state.settings.tiers.length;
      this.updateTierSettingsState(numberToUpdate);
      this.props.hideErrorAlert();
    }

    this.setState({
      numberOfTiers: value,
      errors,
      tierSettingsErrors: {}
    });
  }

  onTierSettingsInputChange(event, index, customValue) {
    const { settings, tierSettingsErrors } = { ...this.state };

    updateStateOnInputChange({ stateObj: settings.tiers[index], event, customValue });

    const errors = this.validator.validateTierSettings(settings.tiers, index);
    if (errors) {
      tierSettingsErrors[index] = errors;
    } else {
      delete tierSettingsErrors[index];
    }

    if (isEmptyObject(tierSettingsErrors)) {
      this.props.hideErrorAlert();
    }

    this.setState({
      settings,
      tierSettingsErrors
    });
  }

  renderSingleTierSettings(tier, index) {
    const firstTooltip = getMessage("distribution.contentBox.singleTierSettings.tooltip.first");

    let spendTooltip,
      purchasesTooltip = undefined;
    if (index === 0) {
      spendTooltip = purchasesTooltip = firstTooltip;
    } else if (index === 1) {
      spendTooltip = getMessage("distribution.contentBox.singleTierSettings.tooltip.spend");
      purchasesTooltip = getMessage("distribution.contentBox.singleTierSettings.tooltip.purchases");
    }

    const errors = this.state.tierSettingsErrors[index];

    return (
      <div key={`tierSettings${index}`}>
        <div>
          <label className="e-field__label">{`Tier ${index + 1}`}</label>
        </div>
        <div className="e-grid e-grid-between l-grid-distribution">
          <div className="e-cell">
            <InputNumberField
              label={getMessage("distribution.contentBox.singleTierSettings.spend.label")}
              name="spend"
              value={tier.spend}
              onChange={(event, customValue) => this.onTierSettingsInputChange(event, index, customValue)}
              tooltip={spendTooltip}
              min={0}
              disabled={index === 0}
              errorMsg={getErrorMessage(errors, "spend")}
            />
          </div>
          <span className="e-cell">{`${index > 0 ? "And" : ""}`}</span>
          <div className="e-cell">
            <InputNumberField
              label={getMessage("distribution.contentBox.singleTierSettings.purchases.label")}
              name="purchases"
              value={tier.purchases}
              onChange={(event, customValue) => this.onTierSettingsInputChange(event, index, customValue)}
              tooltip={purchasesTooltip}
              min={0}
              disabled={index === 0}
              errorMsg={getErrorMessage(errors, "purchases")}
            />
          </div>
        </div>
      </div>
    );
  }

  renderAllTierSettings(tiers) {
    return tiers.map((tier, index) => {
      return this.renderSingleTierSettings(tier, index);
    });
  }

  validateAll() {
    const { settings, tierSettingsErrors } = { ...this.state };

    const errors = this.validator.validateForm(this.state);

    const tiers = settings.tiers;

    for (let index = 0; index < tiers.length; index++) {
      const tierErrors = this.validator.validateTierSettings(tiers, index);

      if (tierErrors) {
        tierSettingsErrors[index] = tierErrors;
      } else {
        delete tierSettingsErrors[index];
      }
    }

    const isValid = errors === undefined && isEmptyObject(tierSettingsErrors);

    if (isValid) {
      this.props.hideErrorAlert();
    } else {
      this.props.showErrorAlert(getMessage("form.missingSettingsAlert"));
    }

    this.setState({
      tierSettingsErrors,
      errors
    });

    return isValid;
  }

  renderUpdateChartButton() {
    const { settings } = this.state;

    let attr = {};
    if (settings.tiers.length <= 1) {
      attr["disabled"] = true;
    }

    return (
      <button type="button" className="e-btn" onClick={this.updateChart.bind(this)} {...attr}>
        {getMessage("distribution.contentBox.updateChartButton.label")}
      </button>
    );
  }

  renderChart() {
    const { chartData, loading } = this.state;

    if (this.props.disabledMode) {
      return null;
    }

    return (
      <Fragment>
        <LoaderOverlay show={loading} />
        <ec-chart
          domain-type="discrete"
          height={this.state.chartHeight}
          style={chartData.totals.length === 0 ? { visibility: "hidden" } : {}}
        >
          <ec-series-column
            name={getMessage("distribution.contentBox.chart.column.label")}
            padding="0.5"
            data={JSON.stringify(chartData.totals)}
            color={"#94acc5"}
          />

          <ec-series
            name={getMessage("distribution.contentBox.chart.series.name")}
            data={JSON.stringify(chartData.pcts)}
            format-y=".2%"
            color={"#94acc5"}
          />
        </ec-chart>
      </Fragment>
    );
  }

  renderContentBox() {
    const { settings } = this.state;

    const containerClassNames = classNames("e-box l-distribution", "e-fullheight", {
      "l-overlay-disabled": this.props.disabledMode
    });

    return (
      <div className={containerClassNames}>
        <Notification content={getMessage("distribution.contentBox.notification")} />
        <div className="e-row">
          <div className="e-col-6">
            <h2>{getMessage("distribution.contentBox.title")}</h2>
            <div id="tiersCreationContent" className="e-fullheight__body m-n-15">
              <InputNumberField
                label={getMessage("distribution.contentBox.numberOfTiers.label")}
                name="numberOfTiers"
                value={this.state.numberOfTiers}
                onChange={this.onNumberOfTiersChange.bind(this)}
                min={2}
                max={MAX_NUMBER_OF_TIERS}
                errorMsg={getErrorMessage(this.state.errors, "numberOfTiers")}
              />

              <div>
                <SelectField
                  label={getMessage("distribution.contentBox.calcPeriod.label")}
                  name="calcPeriod"
                  value={settings.calcPeriod || CUSTOMER_CALC_PERIOD_OPTIONS[0].value}
                  options={CUSTOMER_CALC_PERIOD_OPTIONS}
                  onChange={this.onInputChange.bind(this)}
                  tooltip={getMessage("distribution.contentBox.calcPeriod.tooltip")}
                />
              </div>

              <div className="onboarding-tiers-settings">{this.renderAllTierSettings(this.state.settings.tiers)}</div>
            </div>
          </div>
          <div className="e-col-6 onboarding-distribution-preview">
            <div className="e-header e-header-flex">
              <h2>
                {getMessage("distribution.contentBox.preview.title")}
                <e-tooltip content={getMessage("distribution.contentBox.preview.tooltip")} type="helper" />
              </h2>
              <div className="e-header__button">
                <div className="e-buttongroup">{this.renderUpdateChartButton()}</div>
              </div>
            </div>

            <div className="onboarding-tiers-chart">{this.renderChart()}</div>
          </div>
          <div className="e-col e-col__separator" />
        </div>
      </div>
    );
  }

  render() {
    if (this.state.redirectToTiers) {
      return <Redirect to="/tiers" />;
    }

    if (this.props.fromOnboarding) {
      return this.renderContentBox();
    }

    return (
      <div className="distribution e-fullheight">
        <div className="e-header e-header-flex">
          <h1>{getMessage("tierTabs.contactDistribution.title")}</h1>
          <div className="e-header__button">
            <div className="e-buttongroup">
              <NavLink to="/tiers" className="e-btn e-btn-link">
                {getMessage("toOverviewBtn.label")}
              </NavLink>
            </div>
          </div>
        </div>
        {this.renderContentBox()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  distribution: selectPlanDistribution(state),
  isBeforeFirstLaunch: selectIsBeforeFirstPlanLaunch(state)
});

const mapDispatchToProps = (dispatch) => ({
  updateDistribution: ({ data, refreshTiers, disableAlert }) => {
    dispatch(updateDistribution({ data, refreshTiers, disableAlert }));
  },
  showErrorAlert: (message) => {
    dispatch(showErrorAlert(message, undefined, true));
  },
  hideErrorAlert: () => {
    dispatch(hideErrorAlert());
  }
});

Distribution.propTypes = {
  registerSubmitFunc: PropTypes.func,
  registerValidateFunc: PropTypes.func,
  disabledMode: PropTypes.bool,
  fromOnboarding: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(Distribution);
