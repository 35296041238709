import React, { Fragment } from "react";
import PropTypes from "prop-types";

import InputField from "../../components/InputField";

import { getMultiLangFieldLabel } from "../../helpers/multiLangHelper";
import { getErrorMessage } from "../../validators";
import { getMessage } from "../../messages";

const MultiLangStartsWithAccessUrlFields = ({ onChange, lang, translations, errors }) => {
  return (
    <Fragment>
      <InputField
        label={getMultiLangFieldLabel({
          label: getMessage("excluxiveAccessSetupSettings.blocking.startsWithAccessUrl.label"),
          lang
        })}
        type="text"
        name="blocking.startsWithAccessUrlML"
        value={translations["blocking.startsWithAccessUrlML"][lang] || "http://"}
        onChange={(event) => onChange(event, lang)}
        errorMsg={getErrorMessage(errors, `blocking\\.startsWithAccessUrlML.${lang}`)}
      />

      <InputField
        label={getMultiLangFieldLabel({
          label: getMessage("excluxiveAccessSetupSettings.blocking.landingPageUrl.label"),
          lang
        })}
        type="text"
        name="blocking.landingPageUrlML"
        value={translations["blocking.landingPageUrlML"][lang] || "http://"}
        onChange={(event) => onChange(event, lang)}
        errorMsg={getErrorMessage(errors, `blocking\\.landingPageUrlML.${lang}`)}
      />
    </Fragment>
  );
};

MultiLangStartsWithAccessUrlFields.propTypes = {
  onChange: PropTypes.func,
  lang: PropTypes.string,
  translations: PropTypes.object
};

export default MultiLangStartsWithAccessUrlFields;
