import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "../assets/styles/Switch.scss";

const Switch = (props) => {
  let attr = {};
  if (props.disabled) {
    attr["disabled"] = true;
  }

  return (
    <Fragment>
      <div className={classNames("l-switch e-switch", props.className)}>
        <input
          className="e-switch__input"
          type="checkbox"
          id={`switch-${props.name}`}
          name={props.name}
          checked={props.enabled}
          onChange={props.onChange}
          {...attr}
        />
        <label className="e-switch__toggle" htmlFor={`switch-${props.name}`} />
      </div>
      <label className="e-field__label  e-field__label-inline">{props.label}</label>
    </Fragment>
  );
};

Switch.propTypes = {
  label: PropTypes.string,
  enabled: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default Switch;
