import React, { Component } from "react";
import PropTypes from "prop-types";
import { getMessage } from "../messages";

class PageNotFound extends Component {
  render() {
    return <div>{getMessage("pageNotFound")}</div>;
  }
}

export default PageNotFound;
