export default class benefitService {
  static getAdditionalBenefitsConnectedToReward = (rewardId, benefits) => {
    let result = [];

    benefits.forEach((benefit) => {
      if (
        benefit.rewardId === rewardId ||
        (benefit.exclusiveAccessIds && benefit.exclusiveAccessIds.includes(rewardId))
      ) {
        result.push(benefit.name);
      }
    });

    return result;
  };
}
