import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";

import { selectPlanLanguages } from "../reducers";

class MultiLangPopupOpener extends Component {
  render() {
    if (!this.props.languages || this.props.languages.length == 1) {
      return null;
    }

    const className = classNames("e-btn e-btn-onlyicon", {
      "e-btn-disabled": this.props.disabled
    });
    return (
      <a className={className} onClick={() => !this.props.disabled && this.props.onClick()}>
        <e-icon icon="e-language" />
      </a>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    languages: selectPlanLanguages(state)
  };
};

MultiLangPopupOpener.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default connect(mapStateToProps, null)(MultiLangPopupOpener);
