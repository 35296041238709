import customerApi from "../api/customerApi";
import { updateObjectByNameAndValue, getObjectByNamePath, flipObject } from "../helpers/objectHelper";

export const getValueByType = (target) => {
  const type = target.getAttribute("type");
  switch (type) {
    case "checkbox":
      return target.checked;
    // case "custom-number":
    //   const { value } = { ...target };
    //   const setAsString = value.endsWith(".") || (value.indexOf(".") > 0 && Number(value) === 0);
    //   return value !== "" ? (setAsString ? value : Number(value)) : undefined;
    case "bool":
      return convertValueToBool(target.value);
    default:
      return target.value;
  }
};

export const convertValueToBool = (value) => {
  if (value && typeof value === "string") {
    if (value.toLowerCase() === "true") return true;
    if (value.toLowerCase() === "false") return false;
  }
  return value;
};

/**
 * Update given state object by on change event, if customer has multi language update,
 *   also the equivalent multi language map field with the primary language
 * @stateObj - the state object to be updated - tier/reward/action
 * @event - the on change event object
 * @customValue - a custom value to override the value from the event
 * @hasMultiLanguage - if customer has multi language, if not - skip the multi language support
 * @primaryLanguage - the default language
 * @multiLangPropsMap - a map of property name to the equivalent property that holds the multiLang values - on stateObj, example: {"nameML": "name"}
 */
export const updateStateOnInputChange = ({
  stateObj,
  event,
  customValue,
  hasMultiLanguage,
  primaryLanguage,
  multiLangPropsMap,
  hasMultiCurrency,
  primaryCurrency,
  multiCurrencyPropsMap,
  customName
}) => {
  const target = event.target;
  const name = customName === undefined ? target.getAttribute("name") : customName;
  let value = customValue === undefined ? getValueByType(target) : customValue;
  updateObjectByNameAndValue(stateObj, name, value);

  const updateMulti = ({ stateObj, value, hasMulti, propsMap, primaryMulti }) => {
    if (hasMulti && !!propsMap) {
      const propName = flipObject(propsMap)[name];
      if (!!propName) {
        if (!getObjectByNamePath(stateObj, propName)) {
          updateObjectByNameAndValue(stateObj, propName, {});
        }
        updateObjectByNameAndValue(stateObj, `${propName}.${primaryMulti}`, value);
      }
    }
  };

  updateMulti({
    stateObj,
    value,
    hasMulti: hasMultiLanguage,
    propsMap: multiLangPropsMap,
    primaryMulti: primaryLanguage
  });
  updateMulti({
    stateObj,
    value,
    hasMulti: hasMultiCurrency,
    propsMap: multiCurrencyPropsMap,
    primaryMulti: primaryCurrency
  });

  return name;
};

export const updateSpecificLanguageInput = ({ stateObj, event, language, customValue, propsMap }) => {
  const target = event.target;
  const name = target.getAttribute("name");
  let value = customValue === undefined ? getValueByType(target) : customValue;

  const propName = flipObject(propsMap)[name];
  if (!getObjectByNamePath(stateObj, propName)) {
    updateObjectByNameAndValue(stateObj, propName, {});
  }
  updateObjectByNameAndValue(stateObj, `${propName}.${language}`, value);
};

export const getInputNameAndValue = (event) => {
  const target = event.target;
  const name = target.getAttribute("name");
  const value = getValueByType(target);
  return {
    name,
    value
  };
};

export const convertObjectToOptionsArray = (obj, nameProp) => {
  const optionsArr = [];
  Object.keys(obj).forEach((key) => {
    const name = typeof nameProp === "function" ? nameProp(key) : obj[key][nameProp];

    optionsArr.push({
      value: key,
      name: name
    });
  });

  return optionsArr;
};

export const convertArrayToOptionsArray = (arr, valueProp, nameProp) => {
  return arr.map((element) => {
    return {
      value: element[valueProp],
      name: element[nameProp]
    };
  });
};

export const getNameByValueFromOptions = (options, value, nameProp) => {
  const res = options.find((opt) => {
    return opt.value === value;
  });

  if (!res) {
    return undefined;
  }

  return res[nameProp];
};
