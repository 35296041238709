import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import PurchasesWidgetContent from "./PurchasesWidgetContent";
import WidgetHelpLink from "./WidgetHelpLink";
import Widget from "../../components/Widget";
import WidgetDateRange from "../../components/WidgetDateRange";

import { convertDateForChart, transformDataDateForDemo, getDaysDiff } from "./dashboardHelper";

import reportApi from "../../api/reportApi";

import { NUMBER_OF_PURCHASES_HELP_LINK, TIER_COLORS_ARRAY } from "../../constants/dashboardConstants";

import { getMessage } from "../../messages";

const DATA_KEYS = {
  LOYALTY: "loyalty",
  NON_LOYALTY: "nonLoyalty"
};

const CHART_COLORS = {
  LOYALTY: "#424E88",
  NON_LOYALTY: "#D86761",
  UPLIFT: "#3B855A"
};

const totalsMetaData = {
  loyalty: { title: getMessage("purchaseFrequencyWidget.totalsMetaData.loyalty.title"), color: CHART_COLORS.LOYALTY },
  nonLoyalty: {
    title: getMessage("purchaseFrequencyWidget.totalsMetaData.nonLoyalty.title"),
    color: CHART_COLORS.NON_LOYALTY
  },
  uplift: { title: getMessage("purchaseFrequencyWidget.totalsMetaData.uplift.title"), color: CHART_COLORS.UPLIFT }
};

const upliftChartMetaData = {
  uplift: { displayName: getMessage("purchaseFrequencyWidget.totalsMetaData.uplift.title"), color: CHART_COLORS.UPLIFT }
};

class PurchaseFrequencyWidget extends Component {
  constructor(props) {
    super(props);

    const { tiers } = { ...props };

    let legendSelection = {};

    legendSelection[DATA_KEYS.LOYALTY] = true;
    legendSelection[DATA_KEYS.NON_LOYALTY] = true;

    this.chartMetaData = this.getChartMetaData(tiers);

    this.state = {
      loading: true,
      selectedDaysRange: getDaysDiff(props.initialStartDate, props.initialEndDate),
      dateRange: {
        startDateStr: props.initialStartDate,
        endDateStr: props.initialEndDate
      },
      legendSelection,
      chartData: {},
      chartDataUplift: [],
      chartTotals: {},
      showEmptyState: false
    };
  }

  getChartMetaData(tiers) {
    let chartMetaData = [
      {
        id: DATA_KEYS.LOYALTY,
        displayName: getMessage("purchaseFrequencyWidget.totalsMetaData.loyalty.title"),
        className: "opened",
        color: CHART_COLORS.LOYALTY
      },
      {
        id: DATA_KEYS.NON_LOYALTY,
        displayName: getMessage("purchaseFrequencyWidget.totalsMetaData.nonLoyalty.title"),
        className: "clicked",
        color: CHART_COLORS.NON_LOYALTY
      }
    ];

    if (tiers.length > 0) {
      tiers.forEach((tier, index) => {
        chartMetaData.push({
          id: tier.id,
          displayName: tier.name,
          className: "primary",
          isTier: true,
          color: TIER_COLORS_ARRAY[index]
        });

        //legendSelection[tier.id] = false;
      });
    }

    return chartMetaData;
  }

  getTotalsMetaData() {
    return {
      loyalty: { title: getMessage("purchaseFrequencyWidget.totalsMetaData.loyalty.title") },
      nonLoyalty: { title: getMessage("purchaseFrequencyWidget.totalsMetaData.nonLoyalty.title") },
      uplift: { title: getMessage("purchaseFrequencyWidget.totalsMetaData.uplift.title") }
    };
  }

  componentDidMount() {
    this.loadData(this.state.dateRange);
  }

  loadData(dateRange) {
    const promises = [reportApi.getPurchaseFrequencyData(dateRange), reportApi.getPurchaseFrequencyTotals(dateRange)];

    this.setState({ loading: true, showEmptyState: false });

    Promise.all(promises)
      .then((response) => {
        this.setChartDataState(response[0]);
        this.setTotalsState(response[1]);
      })
      .catch((error) => {
        this.setState({ showEmptyState: true });
        throw error;
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  onTimeRangeChange(value) {
    const { start, end } = value;
    let dateRange = {
      startDateStr: start,
      endDateStr: end
    };
    this.setState({ dateRange, selectedDaysRange: getDaysDiff(start, end) });
    this.loadData(dateRange);
  }

  setChartDataState(dataRows) {
    if (!dataRows) {
      this.setState({ showEmptyState: true });
      return;
    }

    dataRows = transformDataDateForDemo(dataRows, this.state.selectedDaysRange);

    const chartData = {};
    const chartDataUplift = [];
    const chartMetaData = this.chartMetaData;

    dataRows.forEach((row) => {
      const date = convertDateForChart(row.date);

      chartMetaData.forEach((item) => {
        const { id, isTier } = { ...item };

        if (!chartData[id]) {
          chartData[id] = [];
        }

        chartData[id].push({
          x: date,
          y: isTier ? (row.tiers[id] ? row.tiers[id].value || 0 : 0) : row[id] || 0
        });
      });

      chartDataUplift.push({
        x: date,
        y: row.upliftPct || 0
      });
    });

    this.setState({ chartData, chartDataUplift });
  }

  setTotalsState({ loyalty, loyaltyPrevDiff, nonLoyalty, nonLoyaltyPrevDiff, upliftPct, upliftPctPrevDiff }) {
    const chartTotals = {
      loyalty: loyalty || 0,
      loyaltyPrevDiff: loyaltyPrevDiff || 0,
      nonLoyalty: nonLoyalty || 0,
      nonLoyaltyPrevDiff: nonLoyaltyPrevDiff || 0,
      upliftPct: upliftPct || 0,
      upliftPctPrevDiff: upliftPctPrevDiff || 0
    };

    this.setState({ chartTotals });
  }

  render() {
    const { chartData, chartDataUplift, chartTotals, selectedDaysRange, loading, showEmptyState } = {
      ...this.state
    };
    return (
      <Widget
        className="purchase-freq-widget"
        title={
          <Fragment>
            {getMessage("purchaseFrequencyWidget.title")}{" "}
            <e-tooltip content={getMessage("purchaseFrequencyWidget.tooltip")} type="helper" />
          </Fragment>
        }
        headerActions={
          <WidgetDateRange
            onChange={this.onTimeRangeChange.bind(this)}
            isDemoMode={false}
            dateRange={{ start: this.state.dateRange.startDateStr, end: this.state.dateRange.endDateStr }}
          />
        }
        footerActions={<WidgetHelpLink linkUrl={NUMBER_OF_PURCHASES_HELP_LINK} />}
        content={
          <PurchasesWidgetContent
            chartKeyPrefix="chart_purchase_freq"
            chartMetaData={this.chartMetaData}
            upliftChartMetaData={upliftChartMetaData}
            totalsMetaData={totalsMetaData}
            chartData={chartData}
            chartDataUplift={chartDataUplift}
            chartTotals={chartTotals}
            daysRange={selectedDaysRange}
            showEmptyState={showEmptyState}
          />
        }
        loading={loading}
        isEmptyState={showEmptyState}
      />
    );
  }
}

PurchaseFrequencyWidget.propTypes = {
  tiers: PropTypes.array,
  initialStartDate: PropTypes.string,
  initialEndDate: PropTypes.string
};

export default PurchaseFrequencyWidget;
