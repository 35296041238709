import axios from "axios";
import sessionHelper from "../helpers/sessionHelper";

export default class actionApi {
  static async getAllActions() {
    const planId = sessionHelper.getPlanId();
    const response = await axios.get(`/plan/${planId}/action`);
    return response.data;
  }

  static async createAction(data) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.post(`/plan/${planId}/action`, data);
    return response.data;
  }

  static async updateAction(data, id) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.put(`/plan/${planId}/action/${id}`, data);
    return response.data;
  }

  static async deleteAction(id) {
    const planId = sessionHelper.getPlanId();
    const response = await axios.delete(`/plan/${planId}/action/${id}`);
    return response;
  }
}
