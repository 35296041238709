export default class sessionHelper {
  static getCustomerId = () => sessionStorage.getItem("customerId");
  static setCustomerId = (customerId) => sessionStorage.setItem("customerId", customerId);

  static getPlanId = () => {
    const customerId = sessionHelper.getCustomerId();
    return sessionStorage.getItem(`${customerId}_planId`);
  };
  static setPlanId = (planId) => {
    const customerId = sessionHelper.getCustomerId();
    sessionStorage.setItem(`${customerId}_planId`, planId);
  };

  static getShowCurrentVersion = () => {
    const planId = sessionHelper.getPlanId();
    return sessionStorage.getItem(`${planId}_showCurrentVersion`);
  };

  static setShowCurrentVersion = (show) => {
    const planId = sessionHelper.getPlanId();
    sessionStorage.setItem(`${planId}_showCurrentVersion`, show);
  };
}
