import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import DashboardEmptyState from "./DashboardEmptyState";
import LegendWithSelection from "./LegendWithSelection";
import WidgetTotalsItem from "./WidgetTotalsItem";

import { formatTrendText } from "./dashboardHelper";
import { get } from "object-path";
import { getMessage } from "../../messages";

const DATA_KEYS = {
  LOYALTY: "loyalty",
  NON_LOYALTY: "nonLoyalty"
};

const CHART_MODE = {
  TOTAL: getMessage("total"),
  UPLIFT: getMessage("uplift")
};

const CHART_HEIGHT = 164;

class PurchasesWidgetContent extends Component {
  constructor(params) {
    super(params);

    let legendSelection = {};

    legendSelection[DATA_KEYS.LOYALTY] = true;
    legendSelection[DATA_KEYS.NON_LOYALTY] = true;

    this.state = {
      chartMode: CHART_MODE.TOTAL,
      legendSelection
    };
  }

  onLegendSelectionChange(selectionId) {
    let { legendSelection } = { ...this.state };

    legendSelection[selectionId] = !legendSelection[selectionId];

    this.setState({ legendSelection });
  }

  renderTotals() {
    const { chartTotals, daysRange, totalsMetaData } = { ...this.props };

    const { loyalty, loyaltyPrevDiff, nonLoyalty, nonLoyaltyPrevDiff, upliftPct, upliftPctPrevDiff } = {
      ...chartTotals
    };

    return (
      <div className="e-grid">
        <div className="e-cell e-cell-4 e-cell-small">
          <WidgetTotalsItem
            title={totalsMetaData.loyalty.title}
            value={loyalty}
            valueNumericAttr={this.getMainValueNumericAttr()}
            valueColor={totalsMetaData.loyalty.color}
            trendValue={loyaltyPrevDiff}
            trendValueNumericAttr={this.getMainValueNumericAttr()}
            trendText={this.getTrendText(loyaltyPrevDiff, daysRange, true)}
          />
        </div>
        <div className="e-cell e-cell-4 e-cell-small">
          <WidgetTotalsItem
            title={totalsMetaData.nonLoyalty.title}
            value={nonLoyalty}
            valueNumericAttr={this.getMainValueNumericAttr()}
            valueColor={totalsMetaData.nonLoyalty.color}
            trendValue={nonLoyaltyPrevDiff}
            trendValueNumericAttr={this.getMainValueNumericAttr()}
            trendText={this.getTrendText(nonLoyaltyPrevDiff, daysRange, false)}
          />
        </div>
        <div className="e-cell e-cell-4 e-cell-small">
          <WidgetTotalsItem
            title={totalsMetaData.uplift.title}
            value={upliftPct}
            valueNumericAttr={this.getPctValueAttr()}
            valueColor={totalsMetaData.uplift.color}
            trendValue={upliftPctPrevDiff}
            trendText={this.getTrendText(upliftPctPrevDiff, daysRange, true)}
          />
        </div>
      </div>
    );
  }

  renderChart() {
    const { legendSelection, chartMode } = { ...this.state };
    const { chartMetaData, chartData, upliftChartMetaData, chartDataUplift, chartKeyPrefix, currency } = {
      ...this.props
    };

    const mainChartAttr = {};
    const mainSeriesFormatY = currency ? "$,.2f" : ".1f";
    if (currency) {
      mainChartAttr.currency = currency;
    }

    return (
      <Fragment>
        <ec-chart
          key={`${chartKeyPrefix}_uplift`}
          domain-type="time"
          height={CHART_HEIGHT}
          class={classNames({ "e-hidden": chartMode === CHART_MODE.TOTAL })}
        >
          <ec-series-line
            format-y=".2%"
            name={upliftChartMetaData.uplift.displayName}
            interpolate={false}
            data={JSON.stringify(chartDataUplift)}
            color={upliftChartMetaData.uplift.color}
          ></ec-series-line>
        </ec-chart>
        <ec-chart
          key={`${chartKeyPrefix}`}
          domain-type="time"
          height={CHART_HEIGHT}
          class={classNames({ "e-hidden": chartMode === CHART_MODE.UPLIFT })}
          {...mainChartAttr}
        >
          {chartMetaData.map((item) => {
            const { id, displayName, color } = { ...item };

            let attr = {};
            if (color) {
              attr.color = color;
            }

            if (!legendSelection[id]) {
              attr["visible"] = false;
              attr["hidden-in-tooltip"] = true;
            }

            return (
              <ec-series-line
                key={`${chartKeyPrefix}_line_${id}`}
                format-y={mainSeriesFormatY}
                name={displayName}
                interpolate={false}
                data={JSON.stringify(chartData[id])}
                {...attr}
              ></ec-series-line>
            );
          })}
        </ec-chart>
      </Fragment>
    );
  }

  renderChartModeToggle() {
    const { chartMode } = { ...this.state };
    const { chartKeyPrefix } = { ...this.props };
    return (
      <span className="e-buttongroup e-buttongroup-sticky inline-block">
        <input
          className="e-buttonradio"
          type="radio"
          value="total"
          name={`chartMode_${chartKeyPrefix}`}
          id={`rb_${chartKeyPrefix}`}
          checked={chartMode === CHART_MODE.TOTAL}
          onChange={() => {
            this.setState({ chartMode: CHART_MODE.TOTAL });
          }}
        />
        <label className="e-btn e-btn-small" htmlFor={`rb_${chartKeyPrefix}`}>
          Total #
        </label>
        <input
          className="e-buttonradio"
          type="radio"
          value="uplift"
          name={`chartMode_${chartKeyPrefix}`}
          id={`rb_${chartKeyPrefix}_uplift`}
          checked={chartMode === CHART_MODE.UPLIFT}
          onChange={() => {
            this.setState({ chartMode: CHART_MODE.UPLIFT });
          }}
        />
        <label className="e-btn e-btn-small" htmlFor={`rb_${chartKeyPrefix}_uplift`}>
          Loyalty uplift %
        </label>
      </span>
    );
  }

  getItemsForLegend() {
    return this.props.chartMetaData.map((item) => {
      return {
        id: item.id,
        displayName: item.displayName,
        color: item.color,
        isSelected: this.state.legendSelection[item.id]
      };
    });
  }

  render() {
    const { showEmptyState } = { ...this.props };

    if (showEmptyState) {
      return <DashboardEmptyState />;
    }

    return (
      <Fragment>
        {this.renderTotals()}
        <div className="e-margin-top-l e-margin-right-s text-align-right">{this.renderChartModeToggle()}</div>
        <div className="e-margin-top-xs">{this.renderChart()}</div>
        <div className="e-margin-top-m">
          <LegendWithSelection
            items={this.getItemsForLegend()}
            onChange={this.onLegendSelectionChange.bind(this)}
            visible={this.state.chartMode === CHART_MODE.TOTAL}
          />
        </div>
      </Fragment>
    );
  }

  getTrendText(value, daysRange, isLoyalty) {
    if (isLoyalty) {
      return formatTrendText`${value} for Loyalty members <br /> than previous ${daysRange} days`;
    }
    return formatTrendText`${value} for Non-Loyalty members <br /> than previous ${daysRange} days`;
  }

  getMainValueNumericAttr() {
    const { currency } = { ...this.props };
    return currency
      ? { humanize: "auto", precision: "2", "trim-fraction-zeros": "true", type: "currency", currency }
      : { humanize: "auto", precision: "2" };
  }

  getPctValueAttr() {
    return { humanize: "auto", precision: "2", type: "percent", "trim-fraction-zeros": "true" };
  }
}

PurchasesWidgetContent.propTypes = {
  chartKeyPrefix: PropTypes.string,
  chartMetaData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      displayName: PropTypes.string,
      color: PropTypes.string,
      isTier: PropTypes.bool
    })
  ),
  upliftChartMetaData: PropTypes.shape({
    loyalty: PropTypes.shape({
      displayName: PropTypes.string,
      color: PropTypes.string
    })
  }),
  totalsMetaData: PropTypes.shape({
    loyalty: PropTypes.shape({
      title: PropTypes.string,
      color: PropTypes.string
    }),
    nonLoyalty: PropTypes.shape({
      title: PropTypes.string,
      color: PropTypes.string
    }),
    uplift: PropTypes.shape({
      title: PropTypes.string,
      color: PropTypes.string
    })
  }),
  chartData: PropTypes.object,
  chartDataUplift: PropTypes.array,
  chartTotals: PropTypes.object,
  daysRange: PropTypes.number,
  showEmptyState: PropTypes.bool
};

export default PurchasesWidgetContent;
