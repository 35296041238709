import React from "react";
import PropTypes from "prop-types";

import "../assets/styles/OnBoardingActivation.scss";
import { getMessage } from "../messages";

const OnBoardingActivation = (props) => {
  return (
    <div className="l-onboarding-activation text-align-center e-box p-b-100 p-t-60">
      <div className="trophy-logo">
        <e-icon icon="e-trophy" size="middle" color="inherit" />
      </div>
      <h2 className="m-t-15">{getMessage("onBoardingActivation.header")}</h2>
      <div>{getMessage("onBoardingActivation.content")}</div>
      <div className="m-t-15">
        <a className="e-btn e-btn-primary" onClick={props.onActivateLoyalty}>
          {getMessage("onBoardingActivation.activateBtn.label")}
        </a>
      </div>
      <div className="text-color-shade m-t-75">
        <div className="text-size-subheader">{getMessage("onBoardingActivation.content.title")}</div>
        <div className="m-t-15">
          <span>{getMessage("onBoardingActivation.content.line1")}</span>
          <span className="oval" />
          <span>{getMessage("onBoardingActivation.content.line2")}</span>
          <span className="oval" />
          <span>{getMessage("onBoardingActivation.content.line3")}</span>
          <span className="oval" />
          <span>{getMessage("onBoardingActivation.content.line14")}</span>
        </div>
      </div>
    </div>
  );
};

OnBoardingActivation.propTypes = {
  onActivateLoyalty: PropTypes.func
};

export default OnBoardingActivation;
