import React, { useState, useEffect, Fragment } from "react";
import { renderToString } from "react-dom/server";
import actionReportApi from "../../api/actionReportApi";
import { convertDateForChart, formatTrendText, datesDiffStr } from "../Dashboard/dashboardHelper";
import Widget from "../../components/Widget";
import Time from "../../components/Time";
import WidgetTotalsItem from "../Dashboard/WidgetTotalsItem";
import WidgetHelpLink from "../Dashboard/WidgetHelpLink";
import LegendWithSelection from "../Dashboard/LegendWithSelection";
import DashboardEmptyState from "../Dashboard/DashboardEmptyState";
import PropTypes from "prop-types";
import cloneDeep from "lodash.clonedeep";
import { getMessage } from "../../messages";

const VOUCHER_TYPE = {
  ISSUED: "issuedVouchers",
  REDEEMED: "redeemedVouchers"
};

const CHART_COLORS = {
  ISSUED: "#3AAAD3",
  REDEEMED: "#B2D1F2"
};

const CHART_HEIGHT = 164;

const ReferralVouchersWidget = (props) => {
  const { actionId, startDateStr, endDateStr, dateAggStr } = props;

  const chartMetaData = [
    {
      id: VOUCHER_TYPE.ISSUED,
      displayName: getMessage("referralVouchersWidget.chartMetaData.issuedVouchers.displayName"),
      color: CHART_COLORS.ISSUED,
      data: []
    },
    {
      id: VOUCHER_TYPE.REDEEMED,
      displayName: getMessage("referralVouchersWidget.chartMetaData.redeemedVouchers.displayName"),
      color: CHART_COLORS.REDEEMED,
      data: []
    }
  ];

  let initialLegendSelected = { issuedVouchers: true, redeemedVouchers: true };
  const [loading, setLoading] = useState(true);
  const [chartSeriesArr, setChartSeriesArr] = useState(chartMetaData);
  const [legendSelection, setLegendSelection] = useState(initialLegendSelected);
  const [totals, setTotals] = useState({});
  const [chartCustomTooltip, setChartCustomTooltip] = useState("Blah Blah");
  const [showEmptyState, setShowEmptyState] = useState(false);

  const getChartTooltipContent = (date, row) => {
    let content = [];

    const dateElm = renderToString(<Time value={date} onlyDate={true} />);

    content.push({ label: dateElm, type: "header" });

    chartSeriesArr.forEach((metaData) => {
      content.push({
        value: renderToString(
          <Fragment>
            <e-numeric value={row[metaData.id] || 0}></e-numeric>
          </Fragment>
        ),
        label: metaData.displayName,
        color: metaData.color
      });
    });

    return content;
  };

  const setChartSeriesArrState = (dataRows) => {
    if (!dataRows) {
      setShowEmptyState(true);
      return;
    }

    dataRows = dataRows.data;
    //dataRows = transformDataDateForDemo(dataRows, this.state.selectedDaysRange);

    let newChartSeriesArr = cloneDeep(chartSeriesArr);

    let chartCustomTooltip = {};

    //reset data on each series
    newChartSeriesArr.forEach((series) => {
      series.data = [];
    });

    dataRows.forEach((row) => {
      const date = convertDateForChart(row.date);
      newChartSeriesArr.forEach((series) => {
        series.data.push({
          x: date,
          y: row[series.id] || 0
        });
      });
      chartCustomTooltip[date] = { rows: getChartTooltipContent(date, row) };
    });

    setChartSeriesArr(newChartSeriesArr);
    setChartCustomTooltip(chartCustomTooltip);
  };

  const setTotalState = (data) => {
    if (!data) {
      setShowEmptyState(true);
      return;
    }

    const issuedTotals = data[VOUCHER_TYPE.ISSUED];
    const redeemedTotals = data[VOUCHER_TYPE.REDEEMED];
    let totals = {
      issuedTotal: issuedTotals.value || 0,
      issuedTotalPrevDiffPct: issuedTotals.prevDiffPct || 0,
      redeemedTotal: redeemedTotals.value || 0,
      redeemedTotalPrevDiffPct: redeemedTotals.prevDiffPct || 0
    };
    setTotals(totals);
  };

  const loadData = () => {
    const promises = [
      actionReportApi.getActionReferralVouchersChartData({ actionId, startDateStr, endDateStr, dateAggStr }),
      actionReportApi.getActionReferralVouchersChartTotals({ actionId, startDateStr, endDateStr, dateAggStr })
    ];
    setLoading(true);
    setShowEmptyState(false);
    Promise.all(promises)
      .then((response) => {
        setChartSeriesArrState(response[0]);
        setTotalState(response[1]);
      })
      .catch((error) => {
        setShowEmptyState(true);
        throw error;
      })
      .finally(() => setLoading(false));
  };
  const getNumberValueAttr = () => {
    return { humanize: "auto", precision: "2", "trim-fraction-zeros": "true" };
  };

  const getTrendText = (value) => {
    return formatTrendText`${value} than previous ${datesDiffStr(startDateStr, endDateStr, dateAggStr)}`;
  };

  const getItemsForLegend = () => {
    return chartMetaData.map((item) => {
      return {
        id: item.id,
        displayName: item.displayName,
        color: item.color,
        isSelected: legendSelection[item.id]
      };
    });
  };

  useEffect(() => {
    loadData();
  }, [startDateStr, endDateStr, dateAggStr]);

  const onLegendSelectionChange = (selectionId) => {
    let newLegendSelection = cloneDeep(legendSelection);
    newLegendSelection[selectionId] = !newLegendSelection[selectionId];
    setLegendSelection(newLegendSelection);
  };

  const renderTotals = () => {
    let { issuedTotal, issuedTotalPrevDiffPct, redeemedTotal, redeemedTotalsPrevDiffPct } = {
      ...totals
    };

    redeemedTotalsPrevDiffPct = redeemedTotalsPrevDiffPct || 0;

    return (
      <div className="e-grid">
        <div className="e-cell e-cell-4">
          <WidgetTotalsItem
            title={getMessage("referralVouchersWidget.issuedTotal.title")}
            titleTooltip={getMessage("referralVouchersWidget.issuedTotal.titleTooltip")}
            value={issuedTotal}
            valueNumericAttr={getNumberValueAttr()}
            valueColor={CHART_COLORS.ISSUED}
            trendValue={issuedTotalPrevDiffPct}
            trendText={getTrendText(issuedTotalPrevDiffPct)}
          />
        </div>
        <div className="e-cell e-cell-4">
          <WidgetTotalsItem
            title={getMessage("referralVouchersWidget.redeemedTotal.title")}
            titleTooltip={getMessage("referralVouchersWidget.redeemedTotal.titleTooltip")}
            value={redeemedTotal}
            valueNumericAttr={getNumberValueAttr()}
            valueColor={CHART_COLORS.REDEEMED}
            trendValue={redeemedTotalsPrevDiffPct}
            trendText={getTrendText(redeemedTotalsPrevDiffPct)}
          />
        </div>
      </div>
    );
  };

  const renderChart = () => {
    return (
      <ec-chart height={CHART_HEIGHT} domain-type="time" custom-tooltip={JSON.stringify(chartCustomTooltip)}>
        {chartSeriesArr.map((series, index) => {
          if (legendSelection[series.id]) {
            return (
              <ec-series-line
                key={series.id}
                order={index}
                name={series.name}
                data={JSON.stringify(series.data)}
                color={series.color}
              ></ec-series-line>
            );
          }
        })}
      </ec-chart>
    );
  };

  const renderContent = () => {
    if (showEmptyState) {
      return <DashboardEmptyState />;
    }

    return (
      <Fragment>
        <div>{renderTotals()}</div>
        <div className="e-margin-top-m">{renderChart()}</div>
        <div className="e-margin-top-m">
          <LegendWithSelection items={getItemsForLegend()} onChange={onLegendSelectionChange} visible={true} />
        </div>
      </Fragment>
    );
  };

  return (
    <Widget
      title={getMessage("referralVouchersWidget.title")}
      className="referral-vouchers-widget"
      footerActions={<WidgetHelpLink />}
      content={renderContent()}
      loading={loading}
      isEmptyState={showEmptyState}
    />
  );
};

ReferralVouchersWidget.propTypes = {
  actionId: PropTypes.string,
  startDateStr: PropTypes.string,
  endDateStr: PropTypes.string,
  dateAggStr: PropTypes.string
};

export default ReferralVouchersWidget;
