import { getMessage } from "../messages";

export const openDestructiveDialog = ({ headline, content, confirmLabel, confirmFunc, cancelLabel, cancelFunc }) => {
  window.e.utils.openDestructiveConfirmationDialog({
    headline,
    content,
    confirm: {
      label: confirmLabel,
      callback() {
        confirmFunc && confirmFunc();
      }
    },
    cancel: {
      label: cancelLabel || getMessage("cancelBtn.label"),
      callback() {
        cancelFunc && cancelFunc();
      }
    }
  });
};

export const openDeleteDialog = (headline, content, deleteFunc) => {
  openDestructiveDialog({ headline, content, confirmLabel: getMessage("delete"), confirmFunc: deleteFunc });
};

export const openConfirmationDialog = ({ headline, content, confirmFunc, confirmLabel }) => {
  window.e.utils.openConsequentialConfirmationDialog({
    headline,
    content,
    confirm: {
      label: confirmLabel || getMessage("approveBtn.label"),
      callback() {
        confirmFunc && confirmFunc();
      }
    }
  });
};
