import validate from "validate.js";
import _values from "lodash/values";

import actionService from "../services/actionService";
import {
  FORM_STEP_ENUM,
  REWARD_TOKEN,
  VALIDITY_TYPE_ENUM,
  REFERRAL_LIMIT_TYPE_ENUM,
  SPECIAL_TIER_ENUM,
  SCHEDULING_RECURRENCE_FREQUENCY_ENUM,
  SCHEDULING_RECURRENCE_MONTHLY_OPTIONS_ENUM,
  SCHEDULING_RECURRENCE_TYPE_ENUM
} from "../constants/actionConstants";
import { getMessage } from "../messages";

validate.validators.actionSingleReward = function (value, options, key, action) {
  const { tierId, enabledProp, message } = options;
  const rewardTiers = action.reward.value;

  if (rewardTiers[tierId][enabledProp] && !value) {
    return message;
  }

  return undefined;
};

validate.validators.actionReward = function (value, options) {
  const { message, validateNonMember } = options;

  const someFunc = (rewardValue, key) => {
    if (!rewardValue[key]) {
      return false;
    }
    return validateNonMember ? !!rewardValue[key].rewardsText : !!rewardValue[key].rewardsText;
  };

  const hasReward = Object.keys(value).some((key) => someFunc(value, key));

  return hasReward ? undefined : message;
};

validate.validators.actionRewardSpecificTier = function (value, options) {
  const { message, tierId } = options;

  const hasReward = !!value[tierId].rewardsText;

  return hasReward ? undefined : message;
};

validate.validators.blockMessageEmpty = function (value, options) {
  const { message } = options;

  if (value === REWARD_TOKEN.token || validate.isEmpty(value)) {
    return message;
  }

  return undefined;
};

validate.validators.emptyTokenMessage = function (value, options) {
  const { token, message } = options;

  if (value === token || validate.isEmpty(value)) {
    return message;
  }

  return undefined;
};

validate.validators.actionCatalogSelection = function (value, options, key, action) {
  const { message } = options;

  if (
    actionService.hasCatalogSelection(action) &&
    value.categories &&
    value.categories.length === 0 &&
    value.items &&
    value.items.length === 0 &&
    value.brands &&
    value.brands.length === 0
  ) {
    return message;
  }

  return undefined;
};

export default class actionValidator {
  constructor({ action, actionInternalNames, currencies, languages, hasMultiMarket }) {
    this.isPurchaseAction = actionService.isPurchasesAction(action.masterAction);
    this.isEngagementAction = actionService.isEngagementAction(action.masterAction);
    this.isEventAction = actionService.isEventAction(action.masterAction);
    this.isJoinAction = actionService.isJoinAction(action.masterAction);
    this.isPromotionAction = actionService.isPromotionAction(action.masterAction);
    this.isReferralAction = actionService.isReferralAction(action.masterAction);
    this.isDefault = action.isDefault;
    this.actionInternalNames = actionInternalNames;
    this.currencies = currencies;
    this.languages = languages;
    this.hasMultiMarket = hasMultiMarket;
  }

  createSettingsStepConstraints(action) {
    let constraints = {
      name: {
        presence: {
          allowEmpty: false,
          message: this.isReferralAction
            ? getMessage("actionValidator.name.presence.isReferralAction")
            : getMessage("actionValidator.name.presence.notReferralAction")
        }
      },
      "promotion.catalogSelection": {
        actionCatalogSelection: {
          message: getMessage("actionValidator.promotion.catalogSelection.actionCatalogSelection")
        }
      }
    };

    if (this.isPurchaseAction || this.isPromotionAction || this.isReferralAction) {
      if (!!action.minOrderDisplay) {
        constraints.minOrder = {
          presence: {
            allowEmpty: false,
            message: getMessage("actionValidator.constraints.minOrder.presence")
          }
        };
      }

      if (!isNaN(parseFloat(action.minOrder)) || _values(action.minOrderMC).some((v) => !isNaN(parseFloat(v)))) {
        constraints.minOrderMC = {
          multiLangField: {
            languages: this.currencies,
            message: getMessage("actionValidator.constraints.minOrderMC.multiLangField")
          }
        };
      }
    }

    if (action.blockExternalLink.url) {
      constraints["blockExternalLink.text"] = {
        presence: {
          allowEmpty: false,
          message: getMessage("actionValidator.constraints.blockExternalLink.text.presence")
        }
      };
    }

    if (action.blockExternalLink.text) {
      constraints["blockExternalLink.url"] = {
        presence: {
          allowEmpty: false,
          message: getMessage("actionValidator.constraints.blockExternalLink.url.presence")
        },
        url: {
          message: getMessage("actionValidator.constraints.blockExternalLink.url.url")
        }
      };
    }

    if (this.isReferralAction) {
      if (this.hasMultiMarket) {
        constraints.market = {
          presence: {
            allowEmpty: false,
            message: getMessage("actionValidator.constraints.market.presence")
          }
        };
      }
    }

    if (!action.isDefault) {
      constraints["internalName"] = {
        presence: {
          allowEmpty: false,
          message: this.isReferralAction
            ? getMessage("actionValidator.internalName.presence.isReferralAction")
            : getMessage("actionValidator.internalName.presence.notReferralAction")
        },
        exclusionIgnoreCase: {
          within: this.actionInternalNames,
          message: getMessage("actionValidator.name.exclusionIgnoreCase")
        }
      };
    }

    return constraints;
  }

  createRewardStepConstraints(action) {
    const rewardByTiers = action.reward.value;
    let constraints = {};

    const createSingleRule = (rewardTier, tierId, prop, enabledProp) => {
      if (rewardTier[enabledProp] !== undefined) {
        constraints[`reward.value.${tierId}.${prop}`] = {
          actionSingleReward: {
            tierId,
            enabledProp: enabledProp,
            message: getMessage("actionValidator.reward.value.actionSingleReward")
          }
        };
      }
    };

    for (const tierId in rewardByTiers) {
      if (!rewardByTiers.hasOwnProperty(tierId)) continue;

      const rewardTier = rewardByTiers[tierId];

      if (!rewardTier) {
        continue;
      }

      createSingleRule(rewardTier, tierId, "points", "pointsEnabled");
      createSingleRule(rewardTier, tierId, "multiplyPoints", "multiplyPointsEnabled");
      createSingleRule(rewardTier, tierId, "rewardPool", "rewardPoolEnabled");
      createSingleRule(rewardTier, tierId, "exclusiveAccess", "exclusiveAccessEnabled");
      createSingleRule(rewardTier, tierId, "statusUpgrade", "statusUpgradeEnabled");
    }

    if (action?.referralLimitations?.codes.type === REFERRAL_LIMIT_TYPE_ENUM.BEGINNING_OF_YEAR) {
      constraints["referralLimitations.codes.amount"] = {
        presence: {
          allowEmpty: false,
          message: getMessage("actionValidator.constraints.referralLimitations.codes.amount.presence")
        }
      };
    }

    if (action?.referralLimitations?.rewards.type === REFERRAL_LIMIT_TYPE_ENUM.BEGINNING_OF_YEAR) {
      constraints["referralLimitations.rewards.amount"] = {
        presence: {
          allowEmpty: false,
          message: getMessage("actionValidator.constraints.referralLimitations.codes.amount.presence")
        }
      };
    }

    return constraints;
  }

  createContentStepConstraints(action) {
    let constraints = {};
    const { content } = action;

    const createContentRule = (attribute, fieldName, isBlockMessage) => {
      const constraint = isBlockMessage
        ? {
            emptyTokenMessage: {
              token: REWARD_TOKEN.token,
              message: getMessage("actionValidator.constraints.isBlockMessage.isBlockMessage.emptyTokenMessage", {
                fieldName
              })
            }
          }
        : {
            presence: {
              allowEmpty: false,
              message: getMessage("actionValidator.constraints.isBlockMessage.isBlockMessage.emptyTokenMessage", {
                fieldName
              })
            }
          };

      constraints[attribute] = constraint;
    };

    const createContentUrlRule = (attribute, fieldName) => {
      constraints[attribute] = {
        presence: {
          allowEmpty: false,
          message: getMessage("actionValidator.constraints.isBlockMessage.isBlockMessage.emptyTokenMessage", {
            fieldName
          })
        },
        url: {
          message: getMessage("actionValidator.constraints.url", { fieldName })
        }
      };
    };

    if (content.member) {
      createContentRule("content.member.blockMessage", "Block message", true);
      createContentRule("content.member.ctaButton", "CTA Text");
      createContentUrlRule("content.member.ctaUrl", "CTA URL");
    }

    if (content.nonMember && (this.isJoinAction || actionService.hasRewardForNonMember(action))) {
      createContentRule("content.nonMember.blockMessage", "Block message", true);
      createContentRule("content.nonMember.ctaButton", "CTA Text");
      createContentUrlRule("content.nonMember.ctaUrl", "CTA URL");
    }

    return constraints;
  }

  createReferralContentStepConstraints(action) {
    let constraints = {};
    const referrerPath = "referralContent.referrer";
    const refereePath = "referralContent.referee";
    constraints[`${referrerPath}.title`] = {
      presence: {
        allowEmpty: false,
        message: getMessage("actionValidator.constraints.referralContent.referrer.title.presence")
      }
    };
    constraints[`${referrerPath}.text`] = {
      emptyTokenMessage: {
        token: REWARD_TOKEN.token,
        message: getMessage("actionValidator.constraints.referralContent.referrer.text.emptyTokenMessage")
      }
    };
    constraints[`${referrerPath}.subtitle`] = {
      presence: {
        allowEmpty: false,
        message: getMessage("actionValidator.constraints.referralContent.referrer.subtitle.presence")
      }
    };
    constraints[`${referrerPath}.landingPageUrl`] = {
      presence: {
        allowEmpty: false,
        message: getMessage("actionValidator.constraints.referralContent.referrer.landingPageUrl.presence")
      },
      url: {
        message: getMessage("actionValidator.constraints.referralContent.referrer.landingPageUrl.url")
      }
    };
    constraints[`${referrerPath}.consentText`] = {
      presence: {
        allowEmpty: false,
        message: getMessage("actionValidator.constraints.referralContent.referrer.consentText.presence")
      }
    };
    constraints[`${referrerPath}.consentLinkText`] = {
      presence: {
        allowEmpty: false,
        message: getMessage("actionValidator.constraints.referralContent.referrer.consentLinkText.presence")
      }
    };
    constraints[`${referrerPath}.consentLinkUrl`] = {
      presence: {
        allowEmpty: false,
        message: getMessage("actionValidator.constraints.referralContent.referrer.consentLinkUrl.presence")
      },
      url: {
        message: getMessage("actionValidator.constraints.referralContent.referrer.consentLinkUrl.url")
      }
    };

    constraints[`${refereePath}.title`] = {
      presence: {
        allowEmpty: false,
        message: getMessage("actionValidator.constraints.referralContent.referee.title.presence")
      }
    };
    constraints[`${refereePath}.text`] = {
      presence: {
        allowEmpty: false,
        message: getMessage("actionValidator.constraints.referralContent.referee.text.presence")
      }
    };
    constraints[`${refereePath}.secondaryTitle`] = {
      presence: {
        allowEmpty: false,
        message: getMessage("actionValidator.constraints.referralContent.referee.secondaryTitle.presence")
      }
    };
    constraints[`${refereePath}.inputFieldPlaceholder`] = {
      presence: {
        allowEmpty: false,
        message: getMessage("actionValidator.constraints.referralContent.referee.inputFieldPlaceholder.presence")
      }
    };
    constraints[`${refereePath}.secondaryText`] = {
      presence: {
        allowEmpty: false,
        message: getMessage("actionValidator.constraints.referralContent.referee.secondaryText.presence")
      }
    };
    constraints[`${refereePath}.consentText`] = {
      presence: {
        allowEmpty: false,
        message: getMessage("actionValidator.constraints.referralContent.referee.consentText.presence")
      }
    };
    constraints[`${refereePath}.consentLinkText`] = {
      presence: {
        allowEmpty: false,
        message: getMessage("actionValidator.constraints.referralContent.referee.consentLinkText.presence")
      }
    };
    constraints[`${refereePath}.consentLinkUrl`] = {
      presence: {
        allowEmpty: false,
        message: getMessage("actionValidator.constraints.referralContent.referee.consentLinkUrl.presence")
      },
      url: {
        message: getMessage("actionValidator.constraints.referralContent.referee.consentLinkUrl.url")
      }
    };
    constraints[`${refereePath}.ctaText`] = {
      presence: {
        allowEmpty: false,
        message: getMessage("actionValidator.constraints.referralContent.referee.ctaText.presence")
      }
    };

    if (action.referralContent.referee.secondaryConsentEnabled) {
      constraints[`${refereePath}.secondaryConsentText`] = {
        presence: {
          allowEmpty: false,
          message: getMessage("actionValidator.constraints.referralContent.referee.secondaryConsentText.presence")
        }
      };
      constraints[`${refereePath}.secondaryConsentLinkText`] = {
        presence: {
          allowEmpty: false,
          message: getMessage("actionValidator.constraints.referralContent.referee.secondaryConsentLinkText.presence")
        }
      };
      constraints[`${refereePath}.secondaryConsentLinkUrl`] = {
        presence: {
          allowEmpty: false,
          message: getMessage("actionValidator.constraints.referralContent.referee.secondaryConsentLinkUrl.presence")
        },
        url: {
          message: getMessage("actionValidator.constraints.referralContent.referee.secondaryConsentLinkUrl.url")
        }
      };
    }

    return constraints;
  }

  createSchedulingStepConstraints(action) {
    let constraints = {};
    if (this.isEventAction) {
      return {};
    }

    if (this.isPromotionAction) {
      if (!actionService.isRecurring(action)) {
        constraints["scheduling.promoValidity"] = {
          dateRange: {
            messageEmpty: getMessage("actionValidator.constraints.rcheduling.promoValidity.dateRange.messageEmpty"),
            messageRangeNotValid: getMessage(
              "actionValidator.constraints.rcheduling.promoValidity.dateRange.messageRangeNotValid"
            ),
            minRangeMinutes: 10 //10 minutes
          }
        };
      } else {
        constraints["scheduling.recurring.startDate"] = {
          presence: {
            allowEmpty: false,
            message: getMessage("actionValidator.constraints.scheduling.recurring.startDate.presence")
          }
        };
        constraints["scheduling.recurring.endDate"] = {
          presence: {
            allowEmpty: false,
            message: getMessage("actionValidator.constraints.scheduling.recurring.endDate.presence")
          },
          dateEnd: {
            start: action.scheduling.recurring.startDate,
            messageEmpty: getMessage("actionValidator.constraints.scheduling.recurring.endDate.presence"),
            messageNoStart: getMessage("actionValidator.constraints.scheduling.recurring.startDate.presence"),
            messageRangeNotValid: getMessage("actionValidator.constraints.scheduling.recurring.endDate.rangeInvalid")
          }
        };

        if (!action.scheduling.recurring.isWholeDay) {
          constraints["scheduling.recurring.endHour"] = {
            timeRange: {
              start: action.scheduling.recurring.startHour,
              messageRangeNotValid: getMessage("actionValidator.constraints.scheduling.recurring.endHour.rangeInvalid")
            }
          };
        }

        if (action.scheduling.recurring.recurrenceRepeatInterval == SCHEDULING_RECURRENCE_FREQUENCY_ENUM.MONTHLY) {
          if (action.scheduling.recurring.monthlySelectionType == SCHEDULING_RECURRENCE_MONTHLY_OPTIONS_ENUM.MONTH) {
            if (!action.scheduling.recurring.isLastMonthDay)
              constraints["scheduling.recurring.monthdays"] = {
                presence: {
                  allowEmpty: false,
                  message: getMessage("actionValidator.constraints.scheduling.recurring.monthDays.presence")
                }
              };
          } else {
            constraints["scheduling.recurring.weekdays"] = {
              presence: {
                allowEmpty: false,
                message: getMessage("actionValidator.constraints.scheduling.recurring.weekDays.presence")
              }
            };
          }
        }
        if (action.scheduling.recurring.recurrenceRepeatInterval == SCHEDULING_RECURRENCE_FREQUENCY_ENUM.WEEKLY) {
          constraints["scheduling.recurring.weekdays"] = {
            presence: {
              allowEmpty: false,
              message: getMessage("actionValidator.constraints.scheduling.recurring.weekDays.presence")
            }
          };
        }
      }
    } else {
      const { validityType } = { ...action.scheduling };
      if (validityType === VALIDITY_TYPE_ENUM.LIMITED_FROM_EARN) {
        constraints["scheduling.validity.duration"] = {
          presence: {
            allowEmpty: false,
            message: getMessage("actionValidator.constraints.rscheduling.validity.duration.presence")
          },
          numericality: {
            onlyInteger: true,
            notInteger: getMessage("actionValidator.constraints.rscheduling.validity.duration.numericality.notInteger"),
            greaterThan: 0,
            notGreaterThan: getMessage(
              "actionValidator.constraints.rscheduling.validity.duration.numericality.notGreaterThan"
            )
          }
        };
      } else if (validityType === VALIDITY_TYPE_ENUM.FIXED_DATE) {
        constraints["scheduling.validityFixedDate"] = {
          presence: {
            allowEmpty: false,
            message: getMessage("actionValidator.constraints.scheduling.validityFixedDate.presence")
          }
        };
      }
    }

    if (this.isReferralAction) {
      constraints["scheduling.referralCta"] = {
        atLeastOneSelected: {
          message: getMessage("actionValidator.constraints.scheduling.referralCta.atLeastOneSelected")
        }
      };

      if (action.scheduling.referralCta.sendViaEmail) {
        constraints.emailCampaignId = {
          presence: {
            allowEmpty: false,
            message: getMessage("actionValidator.constraints.emailCampaignId.presence")
          }
        };
      }
    }

    return constraints;
  }

  createRewardConstraints(validateNonMember, message) {
    return {
      "reward.value": {
        actionReward: {
          message,
          validateNonMember
        }
      }
    };
  }

  validateStep = (stepId, action) => {
    let constraints = {};

    switch (stepId) {
      case FORM_STEP_ENUM.ACTION_SETTINGS:
        constraints = this.createSettingsStepConstraints(action);
        break;
      case FORM_STEP_ENUM.REWARD_SETTINGS:
        constraints = this.createRewardStepConstraints(action);
        break;
      case FORM_STEP_ENUM.CONTENT_CREATION:
        constraints = this.isReferralAction
          ? this.createReferralContentStepConstraints(action)
          : this.createContentStepConstraints(action);
        break;
      case FORM_STEP_ENUM.SCHEDULING:
        constraints = this.createSchedulingStepConstraints(action);
        break;
    }

    return constraints ? validate(action, constraints) : undefined;
  };

  validateReward = (action, validateNonMember) => {
    let constraints = {};

    if (!this.isDefault) {
      constraints = this.createRewardConstraints(
        validateNonMember,
        validateNonMember
          ? getMessage("actionValidator.constraints.notDefault.validateNonMember")
          : getMessage("actionValidator.constraints.notDefault.notValidateNonMember")
      );

      if (this.isReferralAction) {
        constraints["reward.value"].actionRewardSpecificTier = {
          tierId: SPECIAL_TIER_ENUM.GUEST,
          message: getMessage("actionValidator.constraints.notDefault.reward.value.actionRewardSpecificTier")
        };
      }
    }

    return validate(action, constraints);
  };

  validateMultiCurrencyPopup = (fields) => {
    let constraints = {};

    const hasValues =
      _values(fields.minOrderMC).some((v) => !isNaN(parseFloat(v))) ||
      _values(fields.minOrderDisplayMC).some((v) => !isNaN(parseFloat(v)));

    if (hasValues)
      this.currencies.forEach((currency) => {
        const key = `minOrderMC.${currency}`;
        constraints[key] = {
          presence: {
            allowEmpty: false,
            message: getMessage("actionValidator.constraints.currencies.minOrderMC.currency.presence")
          }
        };
      });

    return validate(fields, constraints);
  };

  validateMultiLangPopup = (fields) => {
    if (fields["blockExternalLink.urlML"]) {
      return this.validateMultiExternalLinkPopup(fields);
    }

    return;
  };

  validateMultiExternalLinkPopup = (fields) => {
    let constraints = {};

    const urlML = fields["blockExternalLink.urlML"];
    const textML = fields["blockExternalLink.textML"];
    const hasValues = _values(urlML).some((v) => !!v) || _values(textML).some((v) => !!v);

    if (hasValues) {
      this.languages.forEach((lang) => {
        if (urlML[lang]) {
          constraints[`blockExternalLink\\.textML.${lang}`] = {
            presence: {
              allowEmpty: false,
              message: getMessage("actionValidator.constraints.blockExternalLink.text.presence")
            }
          };
        }

        if (textML[lang]) {
          constraints[`blockExternalLink\\.urlML.${lang}`] = {
            presence: {
              allowEmpty: false,
              message: getMessage("actionValidator.constraints.blockExternalLink.url.presence")
            },
            url: {
              message: getMessage("actionValidator.constraints.blockExternalLink.url.url")
            }
          };
        }
      });
    }

    return validate(fields, constraints);
  };
}
