import { getMessage } from "../messages";

export const CATALOG_ITEM_TYPE_ENUM = {
  CATEGORY: "category",
  BRAND: "brand",
  ITEM: "item",
  ITEM_ID: "itemId"
};

export const CATALOG_ITEM_TYPE_OPTIONS_NO_PRODUCT = [
  {
    name: getMessage("catalog.item.type.category.name"),
    value: CATALOG_ITEM_TYPE_ENUM.CATEGORY
  },
  {
    name: getMessage("catalog.item.type.brand.name"),
    value: CATALOG_ITEM_TYPE_ENUM.BRAND
  }
];

export const CATALOG_ITEM_TYPE_OPTIONS = [
  ...CATALOG_ITEM_TYPE_OPTIONS_NO_PRODUCT,
  {
    name: getMessage("catalog.item.type.item.name"),
    value: CATALOG_ITEM_TYPE_ENUM.ITEM
  },
  {
    name: getMessage("catalog.item.type.itemId.name"),
    value: CATALOG_ITEM_TYPE_ENUM.ITEM_ID
  }
];
