import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "../assets/styles/InputField.scss";

const InputField = (props) => {
  if (props.dontRender) {
    return null;
  }

  let attr = {};
  if (props.disabled) {
    attr["disabled"] = true;
  }

  if (props.readOnly) {
    attr["readOnly"] = true;
  }

  if (props.type) {
    attr["type"] = props.type;
  }

  if (props.pattern) {
    attr["pattern"] = props.pattern;
  }

  if (props.autoFocus) {
    attr["data-autofocus"] = true;
  }

  if (props.testId) {
    attr["data-testid"] = props.testId;
  }

  const renderInput = () => {
    const inputClassNames = classNames("e-input", props.className, {
      "l-prefix-input": props.addPadding,
      "e-input-error": !!props.errorMsg,
      "e-input-large": props.size === "large",
      "e-input-medium": props.size === "medium",
      "e-input-small": props.size === "small"
    });

    return (
      <input
        className={inputClassNames}
        name={props.name}
        value={props.value === undefined ? "" : props.value}
        maxLength={props.maxLength || 255}
        onChange={props.onChange}
        onClick={props.onClick}
        onBlur={props.onBlur}
        onKeyDown={props.onKeyDown}
        placeholder={props.placeholder}
        autoComplete={"off"}
        data-lpignore="true" //last pass ignore input
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        {...attr}
      />
    );
  };

  const renderInputWithPrefix = () => {
    const postfixButtonElm = props.postfixButtons ? (
      <span className="e-field__postfix__cell e-field__postfix__cell-short e-field__postfix__cell-shortsticky">
        {props.postfixButtons}
      </span>
    ) : null;

    return (
      <div className="e-field__postfix e-field__postfix-reverse">
        <span className="e-field__postfix__cell e-field__postfix__cell-short">
          <span className="e-field__postfix__text">{props.prefixLabel}</span>
        </span>
        <span className="e-field__postfix__cell">{renderInput()}</span>
        {postfixButtonElm}
      </div>
    );
  };

  const renderInputWithPostfix = () => {
    return (
      <div className="e-field__postfix">
        <span className="e-field__postfix__cell">{renderInput()}</span>
        <span className="e-field__postfix__cell e-field__postfix__cell-short">
          <span className="e-field__postfix__text">{props.postfixLabel}</span>
        </span>
      </div>
    );
  };

  const renderInputWithPostfixButtons = () => {
    return (
      <div className="e-field__postfix">
        <span className="e-field__postfix__cell">{renderInput()}</span>
        <span className="e-field__postfix__cell e-field__postfix__cell-short e-field__postfix__cell-shortsticky">
          {props.postfixButtons}
        </span>
      </div>
    );
  };

  const renderPrefixIcon = () => {
    let iconElm = <e-icon icon={props.prefixIconName} />;

    if (props.prefixIconTooltip) {
      iconElm = <e-tooltip content={props.prefixIconTooltip}>{iconElm}</e-tooltip>;
    }

    return <span className="e-field__prefix__icon l-prefix-icon-bordered">{iconElm}</span>;
  };

  const fieldClassNames = classNames("e-field", {
    "e-hidden": props.hidden,
    "no-margin": props.noMargin,
    "e-field-fixwidth": props.fixedWidth,
    "l-currency": props.isCurrency
  });

  let inputElm = renderInput();
  if (props.prefixLabel) {
    inputElm = renderInputWithPrefix();
  } else if (props.postfixLabel) {
    inputElm = renderInputWithPostfix();
  } else if (props.postfixButtons) {
    inputElm = renderInputWithPostfixButtons();
  }

  const labelClassNames = classNames("e-field__label", props.labelClassName, {
    "e-field__label-inline": props.inline,
    "e-field__label-required": props.isRequired
  });

  let tooltipAttr = {};
  if (props.tooltipHasAction) {
    tooltipAttr["has-action"] = true;
  }

  return (
    <div className={fieldClassNames}>
      {props.label && (
        <label className={labelClassNames}>
          {props.label}
          {props.tooltip ? <e-tooltip content={props.tooltip} type="helper" {...tooltipAttr} /> : null}
        </label>
      )}

      {props.prefixIconName ? (
        <div className="e-field__prefix">
          {renderPrefixIcon()}
          {inputElm}
        </div>
      ) : (
        inputElm
      )}

      {props.errorMsg ? <span className="e-field__message e-field__message-error">{props.errorMsg}</span> : null}
    </div>
  );
};

InputField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  inline: PropTypes.bool,
  postfixLabel: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipHasAction: PropTypes.bool,
  errorMsg: PropTypes.string,
  disabled: PropTypes.bool,
  postfixButtons: PropTypes.object,
  readOnly: PropTypes.bool,
  prefixIconName: PropTypes.string,
  prefixIconTooltip: PropTypes.string,
  hidden: PropTypes.bool,
  noMargin: PropTypes.bool,
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
  dontRender: PropTypes.bool,
  fixedWidth: PropTypes.bool,
  addPadding: PropTypes.bool,
  size: PropTypes.string,
  ref: PropTypes.any,
  isRequired: PropTypes.bool
};

export default InputField;
