import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

const DATE_FIELD_TYPE = {
  DATE_TIME: "datetime",
  DATE_RANGE: "daterange",
  DATE_TIME_RANGE: "datetimerange"
};

const Instance = ({
  type,
  name,
  label,
  value,
  minDate,
  onChange,
  onError,
  tooltip,
  tooltipHasAction,
  disabled,
  errorMsg,
  maxDate,
  message,
  noClear,
  className,
  isRequired,
  updateOnClose
}) => {
  const element = useRef(null);

  useEffect(() => {
    element.current.addEventListener("change", onValueChange);
    element.current.addEventListener("close", onClose);
    element.current.addEventListener("error", handleError);

    const buttons = element.current.getElementsByTagName("button");
    for (var i = 0; i < buttons.length; i++) {
      buttons[i].setAttribute("type", "button");
    }
  }, []);

  useEffect(() => {
    const initialValue = getInitialValue();
    if (!!initialValue) {
      element.current.setAttribute("value", initialValue);
    }
  }, [value]);

  function isWithTime() {
    return type === DATE_FIELD_TYPE.DATE_TIME || type === DATE_FIELD_TYPE.DATE_TIME_RANGE;
  }

  function getInitialValue() {
    if (!value) {
      return null;
    }
    if (typeof value === "string") {
      return formatDateTime(value);
    } else if (!!value.start && !!value.end) {
      let result = {
        start: formatDateTime(value.start),
        end: formatDateTime(value.end)
      };

      return JSON.stringify(result);
    }

    return null;
  }

  function onValueChange(event) {
    const value = getValueFromEvent(event);
    if (!updateOnClose) {
      onChange(event, value);
    }
  }

  function onClose(event) {
    const value = getValueFromEvent(event);
    if (updateOnClose) {
      onChange(event, value);
    }
  }

  function getValueFromEvent(event) {
    let result = null;
    const value = event.target.value;

    if (typeof value === "string") {
      result = getDateTime(value);
    } else {
      result = {
        start: value.start ? getDateTime(value.start) : "",
        end: value.end ? getDateTime(value.end) : ""
      };
    }

    return result;
  }

  function handleError() {
    onError && onError();
  }

  function getDateTime(str) {
    const mDateTime = moment(str);
    if (!mDateTime.isValid()) {
      return null;
    }
    return mDateTime.format();
  }

  function formatDateTime(dateTime) {
    if (!dateTime) {
      return "";
    }
    const mDateTime = moment(dateTime);
    const strFormat = isWithTime() ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD";
    return mDateTime.format(strFormat);
  }

  function getAttrs() {
    let attr = {
      name: name,
      ref: element,
      error: !!errorMsg
    };

    attr.error = !!errorMsg;
    if (minDate) {
      attr["min-date"] = formatDateTime(minDate);
    }

    if (maxDate) {
      attr["max-date"] = formatDateTime(maxDate);
    }

    if (disabled) {
      attr.disabled = true;
    }

    if (type) {
      attr["type"] = type;
    }

    return attr;
  }

  let clearHidden = !!noClear ? { "clear-hidden": "" } : {};

  return (
    <div className={`e-field ${!!className ? className : ""}`}>
      <label className={`e-field__label${!!isRequired ? " e-field__label-required" : ""}`}>
        {label}
        {tooltip ? <e-tooltip content={tooltip} type="helper" has-action={!!tooltipHasAction} /> : null}
      </label>
      <e-datetime {...getAttrs()} {...clearHidden} />
      {errorMsg ? <span className="e-field__message e-field__message-error">{errorMsg}</span> : null}
      {message && <span className="e-field__message">{message}</span>}
    </div>
  );
};

Instance.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  tooltip: PropTypes.string,
  tooltipHasAction: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMsg: PropTypes.string,
  message: PropTypes.element,
  updateOnClose: PropTypes.bool,
  noClear: PropTypes.bool
};

export const DateField = (props) => {
  return <Instance {...props} />;
};

export const DateTimeField = (props) => {
  return <Instance {...props} type={DATE_FIELD_TYPE.DATE_TIME} />;
};

export const DateTimeRangeField = (props) => {
  return <Instance {...props} type={DATE_FIELD_TYPE.DATE_TIME_RANGE} />;
};

export const DateRangeField = (props) => {
  return <Instance {...props} type={DATE_FIELD_TYPE.DATE_RANGE} />;
};
