import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import customerApi from "../api/customerApi";
import LoaderOverlay from "../components/LoaderOverlay";
import EmptyState from "../components/EmptyState";
import { getMessage } from "../messages";

import Notification from "../components/Notification";
import { formatIsoTime } from "../helpers/timeHelper";
import { renderToString } from "react-dom/server";
import { DATA_LOAD_LINK } from "../constants/linkConstants";

const TASK_TYPE_ENUM = {
  DAILY_PURCHASE_IMPORT: "dailyPurchaseImport",
  DAILY_CATALOG_IMPORT: "dailyCatalogImport",
  DAILY_PURCHASE_COPY: "dailyPurchaseCopy"
};

export default function TasksStats() {
  const [loading, setLoading] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [data, setData] = useState([]);
  const refreshButtonRef = useRef(null);

  useEffect(() => {
    refreshButtonRef.current.addEventListener("trigger", loadData);
    loadData();
  }, []);

  function getTaskMessage(task) {
    if (task.status === "failed") {
      if (task.type === TASK_TYPE_ENUM.DAILY_PURCHASE_COPY) {
        return getMessage("securitySettings.dataLoad.table.taskMessage.failedCopyPurchase");
      }
      return task.message;
    } else if (task.status === "done") {
      switch (task.type) {
        case TASK_TYPE_ENUM.DAILY_PURCHASE_IMPORT:
        case TASK_TYPE_ENUM.DAILY_CATALOG_IMPORT:
          return getMessage(
            task.hasResults
              ? "securitySettings.dataLoad.table.taskMessage.doneHasResults"
              : "securitySettings.dataLoad.table.taskMessage.doneNoResults"
          );
        case TASK_TYPE_ENUM.DAILY_PURCHASE_COPY:
          return getMessage("securitySettings.dataLoad.table.taskMessage.doneCopyPurchase");
        default:
          return "";
      }
    }

    return "";
  }
  async function loadData() {
    setLoading(true);

    try {
      const endDate = moment();
      const startDate = moment().subtract(48, "hours");
      let originalTasksData = await customerApi.getTasksStats(startDate.toDate(), endDate.toDate());
      let tasksData = [];
      originalTasksData.forEach((task) => {
        tasksData.push({
          taskTypeText: getMessage(`securitySettings.dataLoad.table.taskType.${task.type}`),
          statusText: getMessage(`securitySettings.dataLoad.table.taskStatus.${task.status}`),
          message: getTaskMessage(task),
          updatedAt: formatIsoTime(task.updatedAtUnix)
        });
      });
      setData(tasksData);
    } catch (error) {
      console.error(error);
      setLoadError(true);
    }

    setLoading(false);
  }

  if (loadError) {
    return (
      <EmptyState
        icon="exclamation"
        title={getMessage("securitySettings.error.general.title")}
        text={getMessage("securitySettings.error.general.text")}
      />
    );
  }

  return (
    <div className="box-padding">
      <Notification
        content={getMessage("securitySettings.dataLoad.topNotification", {
          link: renderToString(
            <a target="_blank" href={DATA_LOAD_LINK}>
              {getMessage("securitySettings.dataLoad.topNotification.linkText")}
            </a>
          )
        })}
      />
      <e-datagrid data={JSON.stringify(data)}>
        <e-datagrid-action
          ref={refreshButtonRef}
          label={getMessage("securitySettings.dataLoad.table.header.refreshButton")}
        ></e-datagrid-action>
        <e-datagrid-column
          head={getMessage("securitySettings.dataLoad.table.header.taskType")}
          content-key="taskTypeText"
        ></e-datagrid-column>
        <e-datagrid-column
          head={getMessage("securitySettings.dataLoad.table.header.status")}
          content-key="statusText"
        ></e-datagrid-column>
        <e-datagrid-column
          head={getMessage("securitySettings.dataLoad.table.header.messages")}
          content-key="message"
        ></e-datagrid-column>
        <e-datagrid-column-time
          head={getMessage("securitySettings.dataLoad.table.header.date")}
          content-key="updatedAt"
          tooltip={getMessage("securitySettings.dataLoad.table.header.date.tooltip")}
        ></e-datagrid-column-time>
      </e-datagrid>
      <LoaderOverlay show={loading} />
    </div>
  );
}
