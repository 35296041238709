import React from "react";
import PropTypes from "prop-types";

import PopupContainer from "../../../components/PopupContainer";
import InputField from "../../../components/InputField";
import RadioButtonList from "../../../components/RadioButtonList";

import { getErrorMessage } from "../../../validators";

import { LINK_BUTTON_OPTIONS, LINK_BUTTON_ENUM } from "../../../constants/rewardConstants";
import { getMessage } from "../../../messages";

const LinkDialog = (props) => {
  const { showLinkDialog, redirectOption, redirectUrl, lsOpenWallet, onLinkDialogChange, onApply, onCancel, errors } =
    props;

  const onCheckboxChange = (event) => {
    onLinkDialogChange(event, !lsOpenWallet);
  };

  const applyButtonDisabled =
    (errors && Object.keys(errors).length !== 0) || (redirectOption === LINK_BUTTON_ENUM.REDIRECT && redirectUrl === "")
      ? true
      : false;

  return (
    <PopupContainer
      id="LinkDialogPopup"
      title={getMessage("linkDialog.linkDialogPopup.title")}
      show={showLinkDialog}
      onClose={onCancel}
      buttons={[
        {
          text: getMessage("cancelBtn.label"),
          isClose: true,
          onClick: onCancel
        },
        {
          text: getMessage("applyBtn.label"),
          className: "e-btn-secondary",
          isClose: true,
          onClick: onApply,
          disabled: applyButtonDisabled
        }
      ]}
    >
      <div className="e-field">
        <RadioButtonList
          id={"redirectOption"}
          name={"redirectOption"}
          value={redirectOption}
          options={LINK_BUTTON_OPTIONS}
          onChange={onLinkDialogChange}
        />
        <InputField
          value={redirectUrl || "http://"}
          name={"redirectUrl"}
          onChange={onLinkDialogChange}
          disabled={redirectOption === LINK_BUTTON_ENUM.REDIRECT ? false : true}
          errorMsg={getErrorMessage(errors, "redirectUrl")}
        />
        <div className="e-field">
          <input
            name={"lsOpenWallet"}
            className="e-checkbox"
            type="checkbox"
            id="chb0"
            checked={lsOpenWallet}
            disabled={redirectOption === LINK_BUTTON_ENUM.REDIRECT ? false : true}
            onChange={onCheckboxChange}
          />
          <label htmlFor="chb0">{getMessage("linkDialog.lsOpenWalletCb.label")}</label>
        </div>
      </div>
    </PopupContainer>
  );
};

LinkDialog.propTypes = {
  showLinkDialog: PropTypes.bool,
  redirectOption: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  lsOpenWallet: PropTypes.bool,
  onLinkDialogChange: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default LinkDialog;
