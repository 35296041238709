import axios from "axios";

export default class catalogApi {
  static async searchCatalog(query, type) {
    let params = {
      q: query
    };

    if (type) {
      params["type"] = type;
    }

    const response = await axios.get("/catalog-search", { params });
    return response.data;
  }

  static async getCategoryItems(categoryId) {
    const response = await axios.get(`/catalog-category-items/${categoryId}`);
    return response.data;
  }

  static async getBrandItems(brandId) {
    const response = await axios.get(`/catalog-brand-items/${brandId}`);
    return response.data;
  }

  static async getCatalogRecordsById({ brands, categories, items }) {
    const params = {
      brandIds: brands.join(","),
      categoryIds: categories.join(","),
      itemIds: items.join(",")
    };
    const response = await axios.get("/catalog-records-by-ids", {
      params
    });
    return response.data;
  }
}
